import { useScroll, motion, useMotionValueEvent } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import FlexColumn from "../../../../components/FlexColumn";
import FlexRow from "../../../../components/FlexRow";
import { MOBILE } from "../../../../Constants";
import ScaleButton from "./ScaleButton";

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* width: 100%;
  padding: 2.81rem 1.88rem;
  border: 1px solid
    ${({ theme, isActive }) =>
    isActive ? theme.primary.normal : theme.grayScaleColor.gray20};
  border-radius: 1.25rem;
  opacity: ${({ isActive, done }) => (isActive || done ? 1 : 0.5)};
  ${({ isActive, done }) =>
    isActive &&
    "box-shadow: 0px 10px 80px rgba(0, 0, 0, 0.1);"} //   margin: 30px 0;
    transition: all 0.3s;
  margin: 30px 0; */
`;

const QuestionText = styled.span`
  font-size: 1.38rem;
  font-weight: 500;
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 1.63rem;
  letter-spacing: -0.04em;
  margin-bottom: 2.81rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.63rem;
  //   border: 1px solid black;
  @media (max-width: 425px) {
    width: 100%;
    gap: 0.6rem;
    justify-content: center;
  }
`;

const HelpContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1.13rem;
`;

const HelpText = styled.span`
  fon-size: 0.94rem;
  line-height: 1.44rem;
  letter-spacing: -0.04em;
  font-weight: 400;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
`;

const Symbol = styled.img`
  width: 1.56rem;
  height: 1.56rem;
  margin-bottom: 0.81rem;
`;

const ScaleQuestion = ({
  question,
  scales,
  questionIndex,
  scaleChoices,
  setScaleChoices,
  setNum,
  style,
}) => {
  const ref = useRef(null);
  const answers = Object.values(scaleChoices).length;
  const isMobile = useMediaQuery({ maxWidth: MOBILE });

  const handleMouseLeave = () => {
    if (scaleChoices[questionIndex]) {
      setNum((prev) => {
        let n;
        switch (scaleChoices[questionIndex]) {
          case 1:
            n = 1;
            break;
          case 2:
            n = 1;
            break;
          case 3:
            n = 2;
            break;
          case 4:
            n = 3;
            break;
          case 5:
            n = 3;
            break;
          case 6:
            n = 4;
            break;
          case 7:
            n = 5;
            break;
          default:
            n = 0;
            break;
        }
        return n;
      });
    }
  };
  const handleHover = (e) => {
    const val = Number(e.target.innerHTML);

    setNum((prev) => {
      let n;
      switch (val) {
        case 1:
          n = 1;
          break;
        case 2:
          n = 1;
          break;
        case 3:
          n = 2;
          break;
        case 4:
          n = 3;
          break;
        case 5:
          n = 3;
          break;
        case 6:
          n = 4;
          break;
        case 7:
          n = 5;
          break;
        default:
          n = 0;
          break;
      }
      return n;
    });
  };

  return (
    <Container ref={ref} done={answers === 9}>
      {isMobile ? (
        // <FlexColumn style={{ gap: "1.25rem" }}>
        <>
          <ButtonContainer>
            {Array(scales)
              .slice(0, 4)
              .fill(0)
              .map((i, index) => (
                <ScaleButton
                  key={index}
                  onHover={handleHover}
                  onMouseLeave={handleMouseLeave}
                  questionIndex={questionIndex}
                  scaleChoices={scaleChoices}
                  setScaleChoices={setScaleChoices}
                  setNum={setNum}
                >
                  {index + 1}
                </ScaleButton>
              ))}
          </ButtonContainer>
          <ButtonContainer style={{ padding: "0 2.75rem" }}>
            {Array(scales)
              .slice(4, 7)
              .fill(0)
              .map((i, index) => (
                <ScaleButton
                  key={index}
                  onHover={handleHover}
                  onMouseLeave={handleMouseLeave}
                  questionIndex={questionIndex}
                  scaleChoices={scaleChoices}
                  setScaleChoices={setScaleChoices}
                  setNum={setNum}
                >
                  {index + 5}
                </ScaleButton>
              ))}
          </ButtonContainer>
        </>
      ) : (
        <ButtonContainer>
          {Array(scales)
            .fill(0)
            .map((i, index) => (
              <ScaleButton
                key={index}
                onHover={handleHover}
                onMouseLeave={handleMouseLeave}
                questionIndex={questionIndex}
                scaleChoices={scaleChoices}
                setScaleChoices={setScaleChoices}
                setNum={setNum}
              >
                {index + 1}
              </ScaleButton>
            ))}
        </ButtonContainer>
      )}
      {!isMobile && (
        <HelpContainer>
          <HelpText>전혀 아니다</HelpText>
          <HelpText>보통이다</HelpText>
          <HelpText>매우 그렇다</HelpText>
        </HelpContainer>
      )}
    </Container>
  );
};

export default ScaleQuestion;
