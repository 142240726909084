import styled from "styled-components";

const Container = styled.div`
  padding: 0.25rem 0.75rem;
  background: ${({ theme }) => theme.primary.light};
  color: ${({ theme }) => theme.primary.normal};
  border-radius: 0.4375rem;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 150%; /* 1.21875rem */
  letter-spacing: -0.03125rem;
  text-align: center;
`;

const Badge = ({ children, style }) => {
  return <Container style={style}>{children}</Container>;
};

export default Badge;
