import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "../api/axios";

const Container = styled.div``;

const Heading = styled.h2`
  font-size: 1.25rem;
  line-height: 1.88rem;
  font-weight: 500;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 4.38rem;
`;

const SectionImage = styled.img`
  width: 100%;
  margin-bottom: 4.63rem;
`;

const Recommend = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get("/home/recommend");
      setImages(response.data.recommends);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) return null;

  return (
    <Container>
      <Heading>
        지금 나에게 필요한 트레이닝이 무엇인지 고민 되나요? 🤔
        <br />
        우선 가볍게 맘핏 추천 코스로 시작해 보세요.
      </Heading>
      {images.map((image) => {
        return <SectionImage key={image._id} src={image.url} />;
      })}
    </Container>
  );
};

export default Recommend;
