import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import axios from "../../../api/axios";
import FlexColumn from "../../../components/FlexColumn";
import { MOBILE } from "../../../Constants";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: calc(100vh - var(--navbar-height));
  @media (max-width: ${MOBILE}) {
    padding: 0 1.87rem;
  }
`;

const fadeUpHeading = keyframes`
// from {
//     transform: 
//   }
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    
  }
`;
const Heading1 = styled.p`
  font-size: 1.375rem;
  font-weight: 300;
  line-height: 160%;
  text-align: center;
  opacity: 0;
  color: ${({ theme }) => theme.grayScaleColor.black};
  animation: ${fadeUpHeading} 1s linear forwards;
  animation-delay: 1s;
`;

const GIF = styled.img`
  width: 20rem;
  @media (max-width: ${MOBILE}) {
    width: 12rem;
  }
`;

const fadeUp = keyframes`
// from {
//     transform: 
//   }
  from {
    opacity:0;
    // transform: translateY(200px);
  }
  to {
    opacity: 1;
    
  }
`;

const Button = styled.button`
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
  padding: 0.6rem 4.2rem;
  border: 1px solid ${({ theme }) => theme.primary.normal};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.875rem;
  opacity: 0;
  &:disabled {
    opacity: 0;
  }
  &:hover:not([disabled]) {
    background: ${({ theme }) => theme.primary.hover};
    border: 1px solid ${({ theme }) => theme.primary.hover};
  }
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 19.875rem;
  height: 3.8125rem;
  @media (max-width: ${MOBILE}) {
    width: 100%;
  }
  transition: all 0.3s;
  animation: ${fadeUp} 0.6s linear forwards;
  animation-delay: 3s;
`;

const countdown = keyframes`
from {
    stroke-dashoffset: 113px;
  }
  to {
    stroke-dashoffset: 0px;
  }
`;

const countdownFadeOut = keyframes`
from {
    display: inline;
  }
  to {
    display: none;
  }
`;

const CountdownContainer = styled.div`
  position: relative;
  //   border: 1px solid black;
  height: 2.64156rem;
  width: 2.64156rem;
  display: flex;
  justify-content: center;
  align-items: center;
  //   animation: ${countdownFadeOut} 0s forwards;
  //   animation-delay: 3s;
`;

const CountdownSvg = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  width: 2.64156rem;
  height: 2.64156rem;
  transform: rotateZ(270deg);
  circle {
    stroke-dasharray: 113px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 2px;
    stroke: #fff;
    fill: none;
    animation: ${countdown} 3s linear infinite forwards;
  }
`;
const Countdown = ({ time }) => {
  return (
    <CountdownContainer>
      <div id="countdown-number" style={{ fontSize: "1rem" }}>
        {time}
      </div>
      <CountdownSvg style={{ width: 40, height: 40 }}>
        <circle r="18" cx="20" cy="20"></circle>
      </CountdownSvg>
    </CountdownContainer>
  );
};

const RemindForSale = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const [timer, setTimer] = useState(3);
  const [surveyId, setSurveyId] = useState("");
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE})` });

  const handleButtonClick = () => {
    location.pathname.includes("test")
      ? navigate("/checkup/v2/test/survey", {
          state: { id: location.state?.id ? location.state.id : surveyId },
        })
      : location.pathname.includes("demo")
      ? navigate("/checkup/v2/demo/survey", {
          state: { id: location.state?.id ? location.state.id : surveyId },
        })
      : navigate("/checkup/v2/survey", {
          state: { id: location.state?.id ? location.state.id : surveyId },
        });
  };
  useEffect(() => {
    const fetch = async () => {
      const response =
        location.pathname.includes("test") || location.pathname.includes("demo")
          ? await axios.get("checkup-v2/survey/test?type=test")
          : await axiosPrivate.get(`checkup-v2/survey`);
      if (response.data.ok && !response.data.msg) {
        setSurveyId(response.data.survey._id);
      } else {
        navigate("/checkup/v2");
      }
    };

    let interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    fetch();

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container>
      <Helmet>
        <title>맘핏 | 리마인드</title>
      </Helmet>
      <Heading1>
        잠시 호흡을 가다듬고,
        <br />
        {isMobile ? (
          <span style={{ fontWeight: 600 }}>
            요즘 어떤 일들이 있었는지
            <br />
            떠올려 보세요.
          </span>
        ) : (
          <span style={{ fontWeight: 600 }}>
            요즘 어떤 일들이 있었는지 <br /> 떠올려 보세요.
          </span>
        )}
      </Heading1>
      <GIF
        src={`${process.env.PUBLIC_URL}/assets/checkup/maumfit_remind.gif`}
      />
      <Button disabled={timer >= 1} onClick={handleButtonClick}>
        {/* {timer >= 1 && <Countdown time={timer} />}  */}
        <span>준비 됐어요!</span>
      </Button>
    </Container>
  );
};

export default RemindForSale;
