import styled from "styled-components";
import { MOBILE } from "../../../../Constants";

const Text = styled.h1`
  font-size: 1.5rem;
  line-height: 2.38rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 600;
  @media (max-width: ${MOBILE}) {
    font-size: 0.9375rem;
    line-height: 160%;
  }
`;

const ReportHeading1 = ({ children, style }) => {
  return <Text style={style}>{children}</Text>;
};

export default ReportHeading1;
