import styled from "styled-components";
import FlexColumn from "../../../../components/FlexColumn";
import FlexRow from "../../../../components/FlexRow";
import { getDate } from "../../../../utils/date";

const List = styled.ul`
  display: flex;
`;

const Item = styled.li``;

const TextDate = styled.span`
  flex: 1;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const TextHighlight = styled.span`
  flex: 4;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-left: 1rem;
`;

const HighlightTable = ({ data }) => {
  return (
    <FlexColumn>
      <FlexRow
        style={{ borderBottom: "1px solid #222", paddingBottom: "0.87rem" }}
      >
        <TextDate style={{ fontWeight: 500, color: "#111" }}>날짜</TextDate>
        <TextHighlight style={{ fontWeight: 500 }}>하이라이트</TextHighlight>
      </FlexRow>
      {data.reverse().map((item) => (
        <FlexRow
          style={{ borderBottom: "1px solid #D9D9D9", padding: "0.87rem 0" }}
        >
          <TextDate>{`${getDate(item.startDate, "YY/M/D")}~${getDate(
            item.endDate,
            "YY/M/D"
          )}`}</TextDate>
          <TextHighlight style={{ color: !item.highlight ? "#BBB" : "#111" }}>
            {!item.highlight ? "하이라이트 기록이 없어요" : item.highlight}
          </TextHighlight>
        </FlexRow>
      ))}
    </FlexColumn>
  );
};

export default HighlightTable;
