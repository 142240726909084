import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import { MONTH_PRICE, PAYMENT_TYPE, TAX } from "../../../constants/payments";
import { getDate, lastMonthDay } from "../../../utils/date";
import CloseXButton from "../components/CloseXButton";
import Divider from "../components/Divider";
import ModalHeading2 from "../components/ModalHeading2";
import { useRef, useState } from "react";

const Container = styled.div`
  padding: 1rem;
`;
const DescriptionText = styled.p`
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 170%; /* 1.59375rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 1.25rem;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  padding: 1rem 1.87rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 170%; /* 1.59375rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 1.81rem;
`;

const KeyText = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 180%; /* 1.6875rem */
  letter-spacing: -0.03125rem;
  flex: 1;
`;

const ValueText = styled(KeyText)`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  flex: 2;
`;

const DetailText = styled.p`
  font-size: 0.875rem;
  line-height: 180%;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
`;

const ListText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
`;

const DetailContainer = styled.ul`
  padding-left: 1em;
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
`;

const DetailText2 = styled(ListText).attrs({ as: "li" })`
  font-size: 0.9375rem;
  list-style: disc;
`;

const ModalButton = styled.button`
  border-radius: 0.4375rem;
  padding: 0.78rem 0;
  flex: 1;
  // max-width: 11rem;
  border: none;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem; /* 153.333% */
  letter-spacing: -0.0375rem;
  cursor: pointer;
`;

const Bold = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;
const CancelButton = styled(ModalButton)`
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const OkButton = styled(ModalButton)`
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
`;

const MonthPlanInfoModal = ({ userCount, closeHandler }) => {
  const socChoiceRef = useRef();
  const TOTAL_PRICE = MONTH_PRICE * userCount * (1 + TAX / 100);
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();
  const NOW = getDate(now, "YYYY년 M월 D일");
  const nextMonth = month + 1;
  const nextDay =
    day === lastMonthDay(month, year) ? lastMonthDay(nextMonth, year) : day;

  const nextPayDate = new Date(year, nextMonth, nextDay);
  const NEXT_PAY_DATE_FORMAT = getDate(nextPayDate, "YYYY년 M월 D일");
  const untilDate = new Date(year, nextMonth, nextDay - 1);
  const UNTIL_FORMAT = getDate(untilDate, "YYYY년 M월 D일");

  function jsf__pay(form) {
    try {
      window.KCP_Pay_Execute(form);
    } catch (e) {
      /* IE 에서 결제 정상종료시 throw로 스크립트 종료 */
    }
  }

  // 주문번호 생성 예제
  function init_orderid() {
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    var date = today.getDate();
    var time = today.getTime();

    if (parseInt(month) < 10) {
      month = "0" + month;
    }

    var vOrderID = year + "" + month + "" + date + "" + time;

    return vOrderID;
  }
  const handleButtonClick = async (event, type) => {
    // paymentTypeRef.current.value = "2:1m";
    socChoiceRef.current.value = type;
    // setPlanType(type);
    console.log(document.order_info);
    jsf__pay(document.order_info);
  };

  return (
    <Container>
      <FlexRow
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <ModalHeading2 style={{ textAlign: "center" }}>
          월 구독 플랜 정보
        </ModalHeading2>
        <CloseXButton onClick={closeHandler} />
      </FlexRow>
      <FlexColumn style={{ marginTop: "2.25rem", gap: "0.75rem" }}>
        <FlexRow style={{ alignItems: "flex-start" }}>
          <KeyText>결제 플랜</KeyText>
          <ValueText>[월 구독] 1개월</ValueText>
        </FlexRow>
        <FlexRow style={{ alignItems: "flex-start" }}>
          <KeyText>구독기간</KeyText>
          <ValueText>
            {NOW} ~ {UNTIL_FORMAT}
          </ValueText>
        </FlexRow>
        <FlexRow style={{ alignItems: "flex-start" }}>
          <KeyText>다음 결제일</KeyText>
          <ValueText>{NEXT_PAY_DATE_FORMAT}</ValueText>
        </FlexRow>
        <Divider style={{ margin: "1.56rem 0" }} />
        <FlexRow style={{ alignItems: "flex-start" }}>
          <KeyText>1인 가격</KeyText>
          <FlexRow style={{ flex: 2, gap: "0.5rem" }}>
            <ValueText style={{ flex: "none" }}>
              {(MONTH_PRICE * (1 + TAX / 100)).toLocaleString()}원 / 월
            </ValueText>
            <DetailText>(VAT 포함)</DetailText>
          </FlexRow>
        </FlexRow>
        <FlexRow style={{ alignItems: "flex-start" }}>
          <KeyText>구성원 수</KeyText>
          <ValueText>{userCount}명</ValueText>
        </FlexRow>
        <FlexRow style={{ alignItems: "flex-start" }}>
          <KeyText>결제 금액</KeyText>
          <FlexRow style={{ flex: 2, gap: "0.5rem" }}>
            <ValueText style={{ flex: "none" }}>
              {TOTAL_PRICE.toLocaleString()}원 / 월
            </ValueText>
            <DetailText>(VAT 포함)</DetailText>
          </FlexRow>
        </FlexRow>
        <Divider style={{ margin: "1.56rem 0" }} />
        <DetailContainer style={{ gap: "0.25rem" }}>
          <DetailText2>
            구성원 수는 관리자를 포함한 전체 인원을 의미합니다.
          </DetailText2>
          <DetailText2>
            얼리버드 기간 중 결제를 예약하시면, 얼리버드 기간 마지막 날
            결제됩니다.
          </DetailText2>
          <DetailText2>
            얼리버드 기간 종료 후 결제하시면, 즉시 결제가 진행됩니다.
          </DetailText2>
        </DetailContainer>
      </FlexColumn>
      <FlexRow
        style={{
          marginTop: "2.44rem",
          justifyContent: "space-between",
          gap: "0.69rem",
        }}
      >
        <OkButton
          style={{
            alignSelf: "initial",
            color: "#00DBB1",
            background: "white",
            border: "1px solid #00DBB1",
          }}
          onClick={(e) => handleButtonClick(e, "S")}
        >
          개인카드로 결제하기
        </OkButton>
        <OkButton
          style={{ alignSelf: "initial" }}
          onClick={(e) => handleButtonClick(e, "C")}
        >
          법인카드로 결제하기
        </OkButton>
      </FlexRow>
      <form
        name="order_info"
        // method="post"
        action="/payments/subscribe/success"
      >
        <div>
          {/* <!-- header --> */}
          {/* <div class="header">
            <a href="../index.html" class="btn-back">
              <span>뒤로가기</span>
            </a>
            <h1 class="title">배치키 발급 SAMPLE</h1>
          </div> */}
          {/* <!-- //header -->
        <!-- contents --> */}
          {/* <div id="skipCont" class="contents">
            <p class="txt-type-1">
              이 페이지는 결제를 배치키 발급 요청하는 샘플 페이지입니다.
            </p>
            <p class="txt-type-2">
              소수 수정 시 [※ 필수] 또는 [※ 옵션] 표시가 포함된 문장은 가맹점의
              상황에 맞게 적절히 수정 적용하시기 바랍니다.
            </p>
            
            <div Class="Line-Type-1"></div>
            <ul class="list-btn-2">
              <li>
                <a
                  href="#none"
                  // onclick="jsf__pay(document.order_info);"
                  onClick={() => jsf__pay(document.order_info)}
                  class="btn-type-2 pc-wd-3"
                >
                  결제하기
                </a>
              </li>
            </ul>
          </div> */}
          {/* <!-- //contents --> */}

          <input type="hidden" name="users" value={userCount} />
          <input type="hidden" name="planType" value={"month"} />
          <input type="hidden" name="nextPayDate" value={nextPayDate} />
          <input type="hidden" name="untilDate" value={untilDate} />
          {/* <!-- 주문번호(ordr_idxx) --> */}
          <input
            type="hidden"
            name="ordr_idxx"
            value={init_orderid()}
            maxLength="40"
          />
          {/* <!-- 가맹점 정보 설정--> */}
          <input type="hidden" name="site_cd" value="BA001" />
          {/* <!-- 그룹아이디(kcpgroup_id) --> */}
          {/* TODO: 그룹아이디는 KCP관리자 페이지에서 생성해야 함. 고정값인듯 */}
          <input type="hidden" name="kcpgroup_id" value="BA0011000348" />
          <input type="hidden" name="site_name" value="TEST SITE" />
          {/* <!-- 상품제공기간 설정 --> */}
          {/* <input ref={paymentTypeRef} type="hidden" name="good_expr" value="" /> */}
          {/* <!-- 결제 방법 : 인증키 요청--> */}
          <input type="hidden" name="pay_method" value="AUTH:CARD" />
          {/* <!-- 인증 방식 : 공동인증--> */}
          <input type="hidden" name="card_cert_type" value="BATCH" />
          {/* <!-- 배치키 발급시 주민번호 입력을 결제창 안에서 진행 --> */}
          <input type="hidden" name="batch_soc" value="Y" />
          {/* <!-- 
			※필수 항목
			표준웹에서 값을 설정하는 부분으로 반드시 포함되어야 합니다값을 설정하지 마십시오
	    --> */}
          <input type="hidden" name="module_type" value="01" />
          <input type="hidden" name="res_cd" value="" />
          <input type="hidden" name="res_msg" value="" />
          <input type="hidden" name="enc_info" value="" />
          <input type="hidden" name="enc_data" value="" />
          <input type="hidden" name="tran_cd" value="" />

          {/* <!-- 주민번호 S / 사업자번호 C 픽스 여부 --> */}
          <input ref={socChoiceRef} type="hidden" name="batch_soc_choice" />

          {/* <!-- 배치키 발급시 카드번호 리턴 여부 설정 --> */}
          {/* <!-- Y : 1234-4567-****-8910 형식, L : 8910 형식(카드번호 끝 4자리) --> */}
          <input type="hidden" name="batch_cardno_return_yn" value="Y" />

          {/* <!-- batch_cardno_return_yn 설정시 결제창에서 리턴 --> */}
          {/* <!-- <input type='hidden' name='card_mask_no'			  value=''>    --> */}
        </div>
      </form>
    </Container>
  );
};

export default MonthPlanInfoModal;
