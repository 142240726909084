import styled from "styled-components";

const Container = styled.div`
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 0.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid
    ${({ theme, active }) => (active ? theme.primary.normal : "#D9D9D9")};
  ${({ active, theme }) =>
    active ? `background: ${theme.primary.normal}` : `background: #D9D9D9`};
`;

const Check = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 3.5L5 7L11 1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const UnCheck = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 3.5L5 7L11 1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Checkbox = ({ active, onClick }) => {
  return (
    <Container active={active} onClick={onClick}>
      <Check />
    </Container>
  );
};

export default Checkbox;
