const colors = {
  accent: "#017FC6",
  action: "#00D2C1",
  success: "#017FC6",
  warning: "#FBBC24",
  error: "#D60606",
};

const primary = {
  normal: "#00DBB1",
  light: "#E8FBF7",
  dark: "#00CA99",
  hover: "#00F2C4",
  superLight: "#F4FAF9",
};

const secondary = {
  normal: "#FF7E21",
  light: "#FFD3B4",
  dark: "#E05E00",
};

const accent = {
  normal: "#017FC6",
  light: "#D4ECF9",
  dark: "#005788",
};

const backgroundColor = {
  darkgray10: "#F8FBFB",
  darkgray20: "#EDF3F3",
  darkgray40: "#7C8585",
  darkgray70: "#3A3F3F",
  darkgray80: "#282C2C",
  darkgray90: "#1A1D1D",
};

const grayScaleColor = {
  white: "#FFFFFF",
  gray5: "#F7F7F7",
  gray10: "#EEEEEE",
  gray20: "#D9D9D9",
  gray30: "#BBBBBB",
  gray50: "#777777",
  gray70: "#777777",
  gray80: "#222222",
  black: "#111111",
};

const theme = {
  primary,
  secondary,
  accent,
  backgroundColor,
  grayScaleColor,
  colors,
};

export default theme;
