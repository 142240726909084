import { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import html2canvas from "html2canvas";
import SocialShareButton from "./SocialButton";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const Container = styled.div`
  //   border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  width: 100%;
  background-color: #fcfcfc;
  padding: 30px;
  // height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 50px;
  width: 100%;
  height: 100%;
  max-width: 425px;
  // margin-top: 50px;
  border: 1px solid #fcfcfc;
`;

const TopBar = styled.div`
  width: 100%;
  height: 50px;
  background: #ffc842;
  display: flex;
  align-items: center;
`;

const Logo = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin-left: 20px;
`;

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fcfcfc;
  position: relative;
  height: 100%;
`;

const SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  margin-top: 12px;
`;

const SubTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #ffc842;
  font-family: "Montserrat";
  // text-align: center;
`;

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
`;
const BackButton = styled.span`
  font-size: 12px;
  color: #bbb;
  font-weight: 500;
  cursor: pointer;
`;

const StepBarContainer = styled.div`
  width: 52px;
  height: 8px;
  background-color: #eee;
  margin-left: auto;
  margin-right: 0;
  display: flex;
  border-radius: 50px;
`;
const StepBar = styled.div`
  width: 52px;
  height: 8px;
  background: #ffc842;
  // background-color: ${({ active }) => (active ? "#ffc842" : "#eee")};
  border-radius: 50px;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 500;
  font-size: 20px;
  color: #010101;
  line-height: 26px;
  letter-spacing: -0.5px;
`;

const NextButton = styled.button`
  width: 100%;
  height: 60px;
  border-radius: 7px;
  border: solid 1px ${({ enable }) => (enable ? "#ffc842" : "#d9d9d9")};
  background-color: ${({ enable }) => (enable ? "#ffc842" : "#d9d9d9")};
  color: white;
  font-size: 18px;
  margin-bottom: 70px;
  cursor: ${({ enable }) => (enable ? "pointer" : "not-allowed")};
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 375px;
  // border-radius: 9px;
  overflow: hidden;
  display: none;
  // border: 1px solid black;
`;

const TreeContainer = styled.div`
  position: relative;
  width: 375px;
  height: 562px;
  margin-top: 25px;
  position: relative;
  // display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // border-radius: 9px;
  // border: solid 1px #d9d9d9;
  background-color: #fcfcfc;
  // display: ${({ show }) => (show ? "flex" : "none")};
  display: flex;
`;

const TreeName = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #010101;
  margin-top: 60px;
  margin-bottom: 70px;
  position: relative;
  z-index: 1;
  &:after {
    position: absolute;
    content: "";
    border-bottom: 12px solid #ecee33;
    width: 120%;
    transform: translateX(-50%);
    bottom: -2px;
    left: 50%;
    z-index: -1;
  }
`;

const TreeImage = styled.img`
  height: 315px;
  // border: 1px solid black;
  position: absolute;
  top: 23px;
`;

const LeafGroup = styled.div`
  & > div:nth-child(1) {
    transform: rotate(17deg);
    top: 40px;
    left: 50px;
  }
  & > div:nth-child(2) {
    transform: rotate(-20deg);
    top: 50px;
    left: 170px;
  }
  & > div:nth-child(3) {
    transform: rotate(12deg);
    top: 100px;
    left: 40px;
  }
  & > div:nth-child(4) {
    transform: rotate(-16deg);
    top: 110px;
    left: 180px;
  }
  & > div:nth-child(5) {
    transform: rotate(11deg);
    top: 170px;
    left: 30px;
  }
  & > div:nth-child(6) {
    transform: rotate(-11deg);
    top: 180px;
    left: 185px;
  }
  & > div:nth-child(7) {
    transform: rotate(350deg);
    top: 230px;
    left: 30px;
  }
  & > div:nth-child(8) {
    transform: rotate(-356deg);
    top: 240px;
    left: 190px;
  }
`;

const LeafContainer = styled.div`
  position: absolute;
  width: 150px;
  &:nth-child(odd) {
    // color: red;
    // transform: rotateY(0deg) rotate(-25deg);
    // left: 25px;
  }
`;

const LeafBox = styled.div`
  position: relative;
  text-align: center;
  // border: 1px solid black;
`;

const LeafImage = styled.img`
  // position: absolute;
  // top: 50px;
  // left: 55%;
  width: 120px;
`;

const LeafText = styled.span`
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  transform: translate(-50%, -50%);
  ${(props) => (props.isEven ? "rotate(40deg)" : "rotate(25deg)")};
`;

const Name = styled.span`
  position: absolute;
  font-size: 10px;
  font-weight: bold;
  color: #826a55;
  bottom: 250px;
  margin: 0 auto;
`;

const SmallActionContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-start;
  bottom: 30px;
  width: 90%;
  gap: 14px;
  margin-top: 20px;
  margin-bottom: 16px;
`;

const SmallActionList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const SmallActionItem = styled.p`
  background: #f5f5f5;
  border-radius: 0.44rem;
  padding: 0.88rem 1.13rem;
  font-size: 0.94rem;
  color: #111111;
`;

const WatermarkContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #aaaaaa;
  position: absolute;
  bottom: 9px;
  margin: 0 auto;
  // border: 1px solid black;
`;

const WatermarkText = styled.span`
  font-size: 9px;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 10px;
  font-family: "Montserrat";
`;

const WatermarImg = styled.img`
  // border: 1px solid black;
  width: 13px;
`;

const Image = styled.img`
  margin-top: 12px;
  width: 100%;
  height: auto;
  border-radius: 
  // border: 1px solid red;
  display: flex;
  align-items: center;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  border-radius: 7px;
  border: none;
  background-color: #ffc842;
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 100px;
`;

const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  border-radius: 7px;
  border: none;
  text-decoration: none;
  background-color: #ffc842;
  color: white;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 100px;
  cursor: pointer;
`;

const GuidContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  margin-top: 26px;
  background: #f5f5f5;
  border-radius: 4px;
`;
const GuideText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;

  letter-spacing: -0.5px;

  color: #444444;
`;

const skeletonKeyframe = keyframes`
  0% {
    background-color: white;
  }
  50% {
    background-color: #fcfcfc;
  }
  100% {
    background-color: white;
  }
`;

const fadeOutKeyframe = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: white;
  // animation: ${skeletonKeyframe} 1.5s ease-in-out;
  // animation: ${fadeOutKeyframe} 1.5s;
  // opacity: ${({ show }) => (show ? "1" : "0")};
  display: ${({ show }) => (show ? "flex" : "none")};
`;

function ItreeResult({ currentStep, handlePrev }) {
  const {
    state: { leafs, name, smallActions },
  } = useLocation();
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [isDownloadClicked, setIsDownloadClicked] = useState(false);
  const [image, setImage] = useState();
  const [isImageDone, setIsImageDone] = useState(false);
  const [count, setCount] = useState(0);

  const canvasRef = useRef();
  const iTreeRef = useRef();
  const linkRef = useRef();

  const handleBackButtonClick = () => {
    handlePrev(currentStep - 1);
  };

  const makeImage = async () => {
    const canvas = await html2canvas(iTreeRef.current, { scale: 4 });
    const file = canvas.toDataURL("image/png", 1);
    // imageRef.current.src = image;
    setFile(file);
    if (count === 9) {
      console.log("Images are all loaded!");
      const blob = await (await fetch(file)).blob();
      const image = new File([blob], `itree-${Date.now()}.png`, {
        type: blob.type,
      });
      setImage(image);
      setIsImageDone(true);
    }
  };

  const downloadImage = async () => {
    // const data = await makeImage();
    // console.log(data);
    // window.location.reload();
    linkRef.current.href = file;
  };

  const handleDownloadClick = () => {
    setIsDownloadClicked(true);
  };

  const handleShare = async () => {
    if (navigator.share && navigator.canShare({ files: [image] })) {
      try {
        await navigator.share({
          // title: "나만의 아이트리 만들기",
          // text: "Hello World",
          files: [image],
          // url: "https://maum.fit/itree",
        });
        // alert("finish!");
      } catch (err) {
        // console.log(err);
        // alert(err);
        window.location.reload();
      }
    } else {
      console.log("not supported");
    }
  };

  const handleImagesLoaded = (index) => {
    // console.log(index);

    // const arr = loads;
    // console.log(JSON.stringify(loads));
    // arr[index] = true;
    // setLoads(arr);
    setCount((prev) => prev + 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (count === 9) {
      makeImage();
    }
  }, [count]);
  // console.log(leafs);

  // useEffect(() => {
  //   if (window.Kakao) {
  //     const kakao = window.Kakao;
  //     if (!kakao.isInitialized())
  //       kakao.init(process.env.REACT_APP_KAKAO_API_KEY);
  //     // 나는 Vite를 써서 import.meta.env이며, React는 process.env로 조회한다.
  //   }
  // }, []);

  return (
    <Container>
      <Helmet>
        <title>아이트리</title>
        <meta name="theme-color" content="#FCFCFC" />
      </Helmet>
      <Wrapper>
        {/* <TopBar>
          <Logo>I-TREE</Logo>
        </TopBar> */}
        <Screen>
          <svg
            onClick={() =>
              navigate("/itree/3", { state: { leafs, name, smallActions } })
            }
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" fill="#FCFCFC" />
            <path
              d="M4.29289 12.2929C3.90237 12.6834 3.90237 13.3166 4.29289 13.7071L10.6569 20.0711C11.0474 20.4616 11.6805 20.4616 12.0711 20.0711C12.4616 19.6805 12.4616 19.0474 12.0711 18.6569L6.41421 13L12.0711 7.34315C12.4616 6.95262 12.4616 6.31946 12.0711 5.92893C11.6805 5.53841 11.0474 5.53841 10.6569 5.92893L4.29289 12.2929ZM23 14C23.5523 14 24 13.5523 24 13C24 12.4477 23.5523 12 23 12V14ZM5 14H23V12H5V14Z"
              fill="black"
            />
          </svg>
          <SubTitleContainer>
            <SubTitle>STEP 4</SubTitle>
            <StepBarContainer>
              <StepBar />
            </StepBarContainer>
          </SubTitleContainer>

          <Title>
            <span style={{ fontWeight: 700 }}>{name}</span>
            <br />
            나무 완성!
          </Title>
          <GuidContainer>
            <GuideText>
              💡 이미지를 꾹 누르면 사진 앱에 저장할 수 있어요
            </GuideText>
          </GuidContainer>
          {isDownloadClicked ? (
            <>{/* <img src={file} /> */}</>
          ) : (
            <>
              {isImageDone ? (
                <Image src={file} />
              ) : (
                <TreeContainer ref={iTreeRef}>
                  {/* <TreeName>{name}</TreeName> */}
                  <TreeImage
                    src={`${process.env.PUBLIC_URL}/assets/tree.png`}
                    onLoad={() => handleImagesLoaded(8)}
                  />
                  <LeafGroup>
                    {leafs.map((leaf, index) => (
                      <LeafContainer
                        key={index}
                        // style={{
                        //   top: `${155 + 32 * index}px`,
                        //   left: index % 2 !== 1 && `${50 - index * 1.2}px`,
                        // }}
                      >
                        <LeafBox>
                          <LeafImage
                            src={`${process.env.PUBLIC_URL}/assets/leaf_${
                              (index % 4) + 1
                            }.png`}
                            onLoad={() => handleImagesLoaded(index)}
                          />
                          <LeafText>{leaf}</LeafText>
                        </LeafBox>

                        {/* <LeafText isEven={index % 2 === 0}>{leaf}</LeafText> */}
                      </LeafContainer>
                    ))}
                  </LeafGroup>
                  <Name>{name}</Name>
                  <SmallActionContainer>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/itree/itree_small_action.png`}
                      width={45}
                      height={45}
                      alt="small action logo"
                    />
                    <SmallActionList>
                      {smallActions.map((item) => (
                        <SmallActionItem>{item}</SmallActionItem>
                      ))}
                    </SmallActionList>
                  </SmallActionContainer>
                  <WatermarkContainer>
                    <WatermarkText>ⓒBLOOM COMPANY</WatermarkText>
                    <WatermarImg
                      src={`${process.env.PUBLIC_URL}/assets/itree/itree_bloom_logo.png`}
                    />
                  </WatermarkContainer>
                </TreeContainer>
              )}

              {/* <Button onClick={handleDownloadClick}>이미지 저장하기 2</Button> */}
              {/* <Button onClick={handleShare}>공유하기</Button> */}
              {navigator.share ? (
                <Button onClick={handleShare}>나무 공유하기</Button>
              ) : (
                <DownloadButton
                  ref={linkRef}
                  download={`${name} 나무.png`}
                  onClick={downloadImage}
                >
                  이미지 저장하기
                </DownloadButton>
              )}
              <Canvas ref={canvasRef}></Canvas>
            </>
          )}
          <Footer />
        </Screen>
      </Wrapper>
      <Modal show={!isImageDone} />
    </Container>
  );
}

export default ItreeResult;
