import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  height: 3.13rem;
  border-radius: 0.31rem;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.error : theme.grayScaleColor.gray30};
  font-size: 0.94rem;
  &:focus {
    outline: none;
    border: 1px solid
      ${({ theme, error }) =>
        error ? theme.colors.error : theme.primary.normal};
  }
  font: small-caption;
  // font-size: 1.5rem;
  margin-top: 0.5rem;
  padding-left: 1.13rem;
  padding-right: 3rem;
`;

const Label = styled.label`
  width: 4.13rem;
  height: 1.44rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 0.94rem;
`;

const EyeIcon = styled.img`
  position: absolute;
  right: 0.5rem;
  bottom: 0.94rem;
`;

const LabelInput = ({
  htmlFor,
  type,
  style,
  error,
  isShowPassword,
  setIsShowPassword,
  onChange,
  placeholder,
  ...props
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <Container style={style}>
      <Label htmlFor={htmlFor}>{htmlFor}</Label>
      <Input
        type={type}
        error={error}
        placeholder={placeholder}
        onChange={handleChange}
        {...props}
      />
      {isShowPassword !== undefined && (
        <EyeIcon
          src={
            isShowPassword
              ? `${process.env.PUBLIC_URL}/svg/password_show.svg`
              : `${process.env.PUBLIC_URL}/svg/password_hide.svg`
          }
          onClick={() => {
            setIsShowPassword((prev) => !prev);
          }}
        />
      )}
    </Container>
  );
};

export default LabelInput;
