import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import CloseXButton from "../components/CloseXButton";
import Divider from "../components/Divider";
import ModalHeading2 from "../components/ModalHeading2";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const DescriptionText = styled.p`
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 170%; /* 1.59375rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 1.25rem;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  padding: 1rem 1.87rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 170%; /* 1.59375rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 1.81rem;
`;

const ModalButton = styled.button`
  border-radius: 0.4375rem;
  padding: 0.78rem 0;
  flex: 1;
  // max-width: 11rem;
  border: none;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem; /* 153.333% */
  letter-spacing: -0.0375rem;
  cursor: pointer;
`;

const Bold = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;
const CancelButton = styled(ModalButton)`
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const OkButton = styled(ModalButton)`
  background: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.grayScaleColor.white};
`;

const DeleteGroupConfirmModal = ({
  closeHandler,
  department,
  setDepartments,
}) => {
  const axiosPrivate = useAxiosPrivate();

  const onConfirmClick = async () => {
    const res = await axiosPrivate.delete(`/department`, {
      data: {
        departmentId: department._id,
      },
    });
    setDepartments((prev) => {
      const temp = [...prev];
      const index = temp.findIndex((val) => val._id === department._id);
      temp.splice(index, 1);
      return temp;
    });
    closeHandler();
  };
  return (
    <>
      <FlexRow style={{ justifyContent: "center", alignItems: "center" }}>
        <ModalHeading2 style={{ textAlign: "center" }}>
          {department.name}
          <br />
          해당 그룹을 삭제하시겠어요?
        </ModalHeading2>
      </FlexRow>
      <DescriptionText>
        삭제할 그룹에 속한 구성원이 있다면
        <br />
        <Bold>[구성원 관리]</Bold> 탭에서 해당 구성원의 그룹 변경을 진행해
        주세요.
        <br />
        <br />
        그룹 변경을 하지 않고 조직을 삭제하실 경우,
        <br /> 해당 그룹에 속한 조직원들을 모두 <Bold>재등록</Bold> 해야 합니다.
      </DescriptionText>
      <FlexRow
        style={{
          marginTop: "2.44rem",
          justifyContent: "space-between",
          gap: "0.69rem",
        }}
      >
        <CancelButton onClick={closeHandler}>돌아가기</CancelButton>
        <OkButton onClick={onConfirmClick}>삭제하기</OkButton>
      </FlexRow>
    </>
  );
};

export default DeleteGroupConfirmModal;
