export const converDate = (date, type = 0) => {
  const day = ["일", "월", "화", "수", "목", "금", "토"];
  let newDate = `${new Date(date).getFullYear()}.${
    new Date(date).getMonth() + 1
  }.${new Date(date).getDate()}`;
  if (type === 1) {
    newDate += ` (${day[new Date(date).getDay()]})`;
  }

  return newDate;
};

export const converToLocaleDate = (date) => {
  // return 2023. 1. 3
  return new Date(date).toLocaleDateString().slice(0, -1);
};
