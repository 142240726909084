import styled from "styled-components";

const Text = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 150%; /* 2.25rem */
  letter-spacing: -0.03125rem;
`;

const Heading1 = ({ text, style }) => {
  return <Text style={style}>{text}</Text>;
};

export default Heading1;
