import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MOBILE, TYPE_LEADER } from "../Constants";
import FlexRow from "./FlexRow";

const Container = styled.div`
  width: 100%;
  padding-left: calc(2.5rem + var(--sidebar-manager-width));
  height: var(--navbar-height);
  background: ${({ theme, type }) => theme.grayScaleColor.white};
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Wrapper = styled.div`
  width: 100%;
  // max-width: 62.5rem;
  padding: 0 2.5rem;
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? "center" : "space-between")};
  align-items: center;
`;

const Logo = styled.img`
  cursor: pointer;
`;

const CompanyText = styled.p`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.40625rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;
const InfoContainer = styled.div`
  color: ${({ theme }) => theme.grayScaleColor.white};
  display: flex;
  cursor: pointer;
`;
const Avatar = styled.div`
  width: 2.31rem;
  height: 2.31rem;
  margin-right: 0.56rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvatarImage = styled.img`
  width: 2.3125rem;
  height: 2.3125rem;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Name = styled.span`
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Company = styled.span`
  font-size: 0.75rem;
  font-weight: 300;
`;

const ManagerDashboardTopBar = ({ type, name, company, sticky }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE})`,
  });

  const handleLogoClick = () => {
    window.open("https://zep.us/play/8ZwAV4", "_blank");
  };

  const handleInfoClick = () => {
    navigate("/dashboard");
  };

  return (
    <Container sticky={sticky} type={type}>
      <Wrapper isMobile={isMobile}>
        <FlexRow>
          <Logo type={type} onClick={handleLogoClick} src={""} />
          <CompanyText>{company}</CompanyText>
        </FlexRow>

        {!isMobile && (
          <InfoContainer onClick={handleInfoClick}>
            <Avatar>
              <AvatarImage
                src={`${process.env.PUBLIC_URL}/svg/notification_default.svg`}
              />
            </Avatar>
            <NameContainer>
              <Name>{name} (Admin)</Name>
            </NameContainer>
          </InfoContainer>
        )}
      </Wrapper>
    </Container>
  );
};

export default ManagerDashboardTopBar;
