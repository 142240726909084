import styled from "styled-components";
import FlexRow from "../../../components/FlexRow";
import FlexColumn from "../../../components/FlexColumn";
import useAuth from "../../../hooks/useAuth";
import { useMediaQuery } from "react-responsive";

const Container = styled.div`
  border-radius: 1.25rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 1.88rem;
  position: relative;
  width: 18.75rem;
  max-width: 18.75rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 50;
`;

const Heading1 = styled.p`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 600;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Heading2 = styled.p`
  text-align: center;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};

  line-height: 150%;
  margin-top: 1rem;
  margin-bottom: 1.56rem;
`;

const Heading3 = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.3rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.primary.normal};
`;

const Button = styled.button`
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.backgroundColor.darkgray20};
  background: #f8fbfb;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem; /* 176.923% */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  padding: 0.75rem 1.75rem;
  cursor: pointer;
`;

const HelpSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M15.2753 17.5833H6.50926V7.58334L12.0382 2.08667L12.7432 2.79167C12.8297 2.8782 12.9016 2.99278 12.9588 3.13542C13.0159 3.27806 13.0445 3.41243 13.0445 3.53854V3.75L12.1599 7.58334H17.7432C18.1396 7.58334 18.4897 7.73535 18.7936 8.03938C19.0977 8.34327 19.2497 8.69341 19.2497 9.0898V10.4358C19.2497 10.5224 19.2384 10.6158 19.2159 10.7163C19.1936 10.8167 19.1685 10.9102 19.1407 10.9969L16.7528 16.6315C16.6332 16.8985 16.4329 17.124 16.152 17.3077C15.871 17.4915 15.5788 17.5833 15.2753 17.5833ZM7.75926 16.3333H15.2753C15.334 16.3333 15.3941 16.3173 15.4555 16.2852C15.517 16.2531 15.5638 16.1997 15.5959 16.125L17.9997 10.5V9.0898C17.9997 9.01493 17.9756 8.95348 17.9276 8.90542C17.8795 8.85737 17.8181 8.83334 17.7432 8.83334H10.5799L11.6247 4.26605L7.75926 8.11542V16.3333ZM6.50926 7.58334V8.83334H3.83301V16.3333H6.50926V17.5833H2.58301V7.58334H6.50926Z"
        fill="#00DBB1"
      />
    </svg>
  );
};

const NotHelpSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M6.55771 4.08666H15.3238V14.0867L9.79479 19.5833L9.08979 18.8783C9.00326 18.7918 8.93139 18.6772 8.87417 18.5346C8.81708 18.3919 8.78854 18.2576 8.78854 18.1315V17.92L9.67313 14.0867H4.08979C3.6934 14.0867 3.34327 13.9346 3.03938 13.6306C2.73535 13.3267 2.58333 12.9766 2.58333 12.5802V11.2342C2.58333 11.1476 2.59458 11.0542 2.61708 10.9537C2.63945 10.8533 2.66451 10.7598 2.69229 10.6731L5.08021 5.03854C5.19979 4.77145 5.40007 4.54604 5.68104 4.36229C5.96202 4.17854 6.25424 4.08666 6.55771 4.08666ZM14.0738 5.33666H6.55771C6.49896 5.33666 6.43889 5.3527 6.3775 5.38479C6.31597 5.41687 6.26917 5.47027 6.23708 5.545L3.83333 11.17V12.5802C3.83333 12.6551 3.85736 12.7165 3.90542 12.7646C3.95347 12.8126 4.01493 12.8367 4.08979 12.8367H11.2531L10.2083 17.404L14.0738 13.5546V5.33666ZM15.3238 14.0867V12.8367H18V5.33666H15.3238V4.08666H19.25V14.0867H15.3238Z"
        fill="#00DBB1"
      />
    </svg>
  );
};

const HelpModal = ({ onClick, block }) => {
  const { auth } = useAuth();

  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });

  return (
    <Container>
      <Heading3>{block.title}</Heading3>
      <Heading1>
        <span>트레이닝은 어땠나요?</span>
      </Heading1>

      <FlexRow style={{ gap: "0.62rem", marginTop: "1.44rem" }}>
        <Button onClick={(e) => onClick(e, true)}>
          <FlexColumn style={{ alignItems: "center", gap: "0.25rem" }}>
            <HelpSvg />
            <span>도움 됐어요</span>
          </FlexColumn>
        </Button>
        <Button onClick={(e) => onClick(e, false)}>
          <FlexColumn style={{ alignItems: "center", gap: "0.25rem" }}>
            <NotHelpSvg />
            <span>아쉬웠어요</span>
          </FlexColumn>
        </Button>
      </FlexRow>
    </Container>
  );
};

export default HelpModal;
