import { useState } from "react";
import styled from "styled-components";
import { MOBILE } from "../../../../Constants";
import ReportTooltip from "./ReportTooltip";

const List = styled.div`
  border-top: 2px solid ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  //   padding: 0 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 160%;
  margin-top: 2px;
  @media (max-width: ${MOBILE}) {
    font-size: 0.8125rem;
  }
`;

const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 0.81rem 2rem;
  font-weight: 600;
  display: flex;

  width: 100%;
`;

const Col = styled.p`
  // border: 1px solid black;
  // display: flex;
  flex: 1;
  min-width: 0;
  word-break: keep-all;
  word-wrap: break-word;
  // overflow: hidden;
`;
const Row = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 0.81rem 2rem;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  position: relative;
`;

const ReportListTable = ({ colHeader, rows, rowData, flex, hoverAt }) => {
  const [hoveringAt, setHoveringAt] = useState(-1);
  const handleHover = (e, index) => {
    setHoveringAt(index);
  };
  const handleMouseOut = () => {
    setHoveringAt(-1);
  };

  return (
    <List>
      <Header>
        {colHeader.map((col, index) => (
          <Col key={index} style={{ flex: flex[index] }}>
            {col}
          </Col>
        ))}
      </Header>
      {rows.map((row, rowIndex) => (
        <Row key={rowIndex}>
          {row.map((item, index) => {
            // console.log(rowData[rowIndex][2]);
            if (hoverAt === index) {
              return (
                <Col
                  key={index}
                  style={{
                    flex: flex[index],

                    cursor: "default",
                  }}
                  onMouseOver={(e) => handleHover(e, rowIndex)}
                  onMouseOut={handleMouseOut}
                >
                  {hoveringAt === rowIndex && (
                    <ReportTooltip
                      style={{
                        top: "-35px",
                        left: "20%",
                      }}
                      hover={hoveringAt >= 0}
                      hoveringAt={hoveringAt}
                    >
                      {rowData[rowIndex][2]}
                    </ReportTooltip>
                  )}
                  {item}
                </Col>
              );
            }
            return (
              <Col key={index} style={{ flex: flex[index] }}>
                {item}
              </Col>
            );
          })}
        </Row>
      ))}
    </List>
  );
};

export default ReportListTable;
