import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import { USER_LEADER, USER_NORMAL } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import validateEmail from "../../../utils/validateEmail";
import CloseXButton from "../components/CloseXButton";
import SDropdown from "../components/Dropdown";
import ModalHeading2 from "../components/ModalHeading2";
import InviteModal from "./InviteModal";
import InviteSuccessModal from "./InviteSuccessModal";
import InviteUsersSuccessModal from "./InviteUsersSuccessModal";

const DownloadButton = styled.button`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  border-radius: 0.3125rem;
  display: flex;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  background: ${({ theme }) => theme.grayScaleColor.white};
  cursor: pointer;
  align-self: flex-end;
  margin-top: 2.37rem;
  margin-bottom: 0.88rem;
`;

const SmallText = styled.p`
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  margin-left: 0.5rem;
`;

const Box = styled.div`
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36.25rem;
  height: 17rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const Callout = styled.div`
  padding: 0.65rem 1rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 1rem;
  margin-bottom: 0.63rem;
`;

const ErrorText = styled.p`
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 150%; /* 1.21875rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.colors.error};
  padding-left: 1.44rem;
`;

const ErrorBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  height: 11rem;
  margin-top: 1.22rem;
`;

const HR = styled.div`
  display: flex;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  padding: 0.5rem 0;
`;

const LR = styled(HR)`
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
`;

const HeaderItem = styled.p`
  text-align: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 170%;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  flex: 1;
`;

const Item = styled(HeaderItem)``;

const ModalButton = styled.button`
  border-radius: 0.4375rem;
  padding: 0.78rem 3.7rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.03125rem;
  cursor: pointer;
`;

const OkButton = styled(ModalButton)`
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
  margin: 0 auto;
  margin-top: 3.81rem;
  &:disabled {
    opacity: 0.3;
  }
`;

const AddButton = ({ style, onClick }) => (
  <svg
    style={style}
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <rect width="46" height="46" rx="10" fill="#F7F7F7" />
    <path
      d="M22.7997 30.7431V16H23.9185V30.7431H22.7997ZM16 23.9309V22.8122H30.7307V23.9309H16Z"
      fill="#BBBBBB"
    />
  </svg>
);

const AddUsersModal = ({ closeHandler, companyId }) => {
  const { auth } = useAuth();
  const ci = companyId || auth.user.companyId._id;
  const axiosPrivate = useAxiosPrivate();
  const [openInviteSuccessModal, setOpenInviteSuccessModal] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [errors, setErros] = useState([]);
  const dragRef = useRef();
  const inputRef = useRef();
  const fileId = useRef(0);

  const onUploadClick = () => {
    inputRef.current.click();
  };
  const onChangeFiles = useCallback(
    (e) => {
      let selectFiles = [];
      let tempFiles = files;
      // temp 변수를 이용하여 선택했던 파일들을 담습니다.

      // 드래그 했을 때와 안했을 때 가리키는 파일 배열을 다르게 해줍니다.
      if (e.type === "drop") {
        // 드래그 앤 드롭 했을때
        selectFiles = e.dataTransfer.files;
      } else {
        // "파일 첨부" 버튼을 눌러서 이미지를 선택했을때
        selectFiles = e.target.files;
      }
      console.log(selectFiles);
      for (const file of selectFiles) {
        // 스프레드 연산자를 이용하여 기존에 있던 파일들을 복사하고, 선택했던 파일들을 append 해줍니다.
        tempFiles = [
          ...tempFiles,
          {
            id: fileId.current++, // fileId의 값을 1씩 늘려주면서 각 파일의 고유값으로 사용합니다.
            object: file, // object 객체안에 선택했던 파일들의 정보가 담겨있습니다.
          },
        ];
      }
      setErros([]);
      setFiles(tempFiles);
      const file = selectFiles[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const file = new Uint8Array(e.target.result);
        const workbook = XLSX.read(file, { type: "array" });

        // 첫 번째 시트를 가져옴
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        // 셀 데이터를 파싱하여 출력
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const data = jsonData.slice(1).filter((item) => item.length > 0);
        const payload = [];
        const errorMessage = [];

        for (let i = 0; i < data.length; i++) {
          let hasError = false;
          // && validateEmail(users[i][0])  && users[i][2].length > 0
          if (data[i][0] === undefined || data[i][0].length === 0) {
            errorMessage.push([
              `${i + 2}행`,
              "1열",
              data[i][0],
              "이름이 누락됐습니다.",
            ]);
            hasError = true;
          }
          if (!validateEmail(data[i][1])) {
            errorMessage.push([
              `${i + 2}행`,
              "2열",
              data[i][1],
              "이메일 값이 잘못됐습니다.",
            ]);
            hasError = true;
          }
          if (data[i][2] === undefined || data[i][2].length === 0) {
            errorMessage.push([
              `${i + 2}행`,
              "3열",
              data[i][2],
              "그룹명이 누락됐습니다.",
            ]);
            hasError = true;
          }

          if (hasError) {
            continue;
          }

          payload.push({
            name: data[i][0],
            email: data[i][1],
            departmentName: data[i][2],
            companyId: ci,
            role:
              data[i][3] === "Y" || data[i][3] === "y"
                ? USER_LEADER
                : USER_NORMAL,
          });
        }
        setUsers(payload);
        setErros(errorMessage);

        console.log(payload);
      };

      reader.readAsArrayBuffer(file);
    },
    [files]
  );

  const handleDragIn = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragOut = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer?.files) {
      setIsDragging(true);
    }
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onChangeFiles(e);
      setIsDragging(false);
    },
    [onChangeFiles]
  );

  const initDragEvents = useCallback(() => {
    // 앞서 말했던 4개의 이벤트에 Listener를 등록합니다. (마운트 될때)

    if (dragRef.current !== null) {
      dragRef.current.addEventListener("dragenter", handleDragIn);
      dragRef.current.addEventListener("dragleave", handleDragOut);
      dragRef.current.addEventListener("dragover", handleDragOver);
      dragRef.current.addEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  const resetDragEvents = useCallback(() => {
    // 앞서 말했던 4개의 이벤트에 Listener를 삭제합니다. (언마운트 될때)

    if (dragRef.current !== null) {
      dragRef.current.removeEventListener("dragenter", handleDragIn);
      dragRef.current.removeEventListener("dragleave", handleDragOut);
      dragRef.current.removeEventListener("dragover", handleDragOver);
      dragRef.current.removeEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  useEffect(() => {
    initDragEvents();

    return () => resetDragEvents();
  }, [initDragEvents, resetDragEvents]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // 첫 번째 시트를 가져옴
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // 셀 데이터를 파싱하여 출력
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      console.log(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  const onSubmitClick = async () => {
    const res = await axiosPrivate.post("/users/bulks", {
      users,
      companyId: ci,
    });
    if (!res.data.ok) {
      return alert(`${res.data.msg} ${res.data.data}`);
    }

    setOpenInviteSuccessModal(true);
  };

  if (openInviteSuccessModal) {
    return <InviteUsersSuccessModal closeHandler={closeHandler} />;
  }

  return (
    <FlexColumn>
      <FlexRow
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <ModalHeading2 text="엑셀 파일로 구성원 추가하기" />
        <CloseXButton onClick={closeHandler} />
      </FlexRow>
      <DownloadButton>
        <FlexRow style={{ justifyContent: "flex-end" }}>
          <a
            href={`${process.env.PUBLIC_URL}/xlsx/maumfit_user_list.xlsx`}
            download={`maumfit_user_list.xlsx`}
            target="_blank"
            rel="noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/svg/dashboard/button_download.svg`}
            />
            엑셀 양식 다운로드
          </a>
        </FlexRow>
      </DownloadButton>
      <Box ref={dragRef}>
        <FlexColumn style={{ alignItems: "center", gap: "1.31rem" }}>
          <input
            ref={inputRef}
            type="file"
            id="fileUpload"
            accept=".xlsx"
            style={{ display: "none" }} // label을 이용하여 구현하기에 없애줌
            onChange={onChangeFiles}
          />
          <AddButton
            style={{ cursor: "pointer" }}
            onClick={onUploadClick}
          ></AddButton>
          <label
            className={isDragging ? "DragDrop-File-Dragging" : "DragDrop-File"}
            // 드래그 중일때와 아닐때의 클래스 이름을 다르게 주어 스타일 차이

            htmlFor="fileUpload"
          >
            <div>
              이용자 CSV 파일을 드래그 또는
              <br />
              버튼을 클릭하여 업로드 해주세요
            </div>
          </label>
        </FlexColumn>
      </Box>
      {files.length > 0 && (
        <Callout>
          <FlexRow>
            <img src={`${process.env.PUBLIC_URL}/svg/dashboard/file.svg`} />{" "}
            {files[0].object.name}
          </FlexRow>
        </Callout>
      )}
      {errors.length > 0 && (
        <>
          <ErrorText>
            ! {errors.length}개의 오류가 발생했어요! 파일을 수정한 후 다시
            업로드 해주세요
          </ErrorText>
          <ErrorBox>
            <HR>
              <HeaderItem>행</HeaderItem>
              <HeaderItem>열</HeaderItem>
              <HeaderItem style={{ flex: 2 }}>입력값</HeaderItem>
              <HeaderItem style={{ flex: 2 }}>내용</HeaderItem>
            </HR>
            {errors.map((error, index) => (
              <LR>
                <Item>{error[0]}</Item>
                <Item>{error[1]}</Item>
                <Item style={{ flex: 2 }}>{error[2]}</Item>
                <Item style={{ flex: 2 }}>{error[3]}</Item>
              </LR>
            ))}
          </ErrorBox>
        </>
      )}
      <OkButton
        disabled={files.length === 0 || errors.length > 0}
        onClick={onSubmitClick}
      >
        추가 완료하기
      </OkButton>
    </FlexColumn>
  );
};

export default AddUsersModal;
