import { useRef, useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styled, { keyframes } from "styled-components";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import ModalBackground from "../../components/ModalBackground";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { converDate } from "../../utils/convertDate";
import { isAuthEmpty } from "../../utils/isAuthEmpty";
import lineBreaker from "../../utils/lineBreaker";
import Modal from "./components/Modal";
import Toggle from "./Toggle";
import Tooltip from "./Tooltip";
import { MOBILE } from "../../Constants";
import convertPermavac from "../../utils/convertPermavac";
import StartModal from "./components/StartModal";
import TimerModal from "./components/TimerModal";
import { useNavigate } from "react-router-dom";
import HelpModal from "./components/HelpModal";

const Container = styled.div`
  width: 100%;
`;
const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  //   border: 1px solid black;
`;
const TopContainer = styled.section`
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  height: 36.25rem;
  display: flex;
  justify-content: center;
  width: 100%;
  color: #000;
  padding-top: 7.31rem;
  @media (max-width: ${MOBILE}) {
    padding: 0 2rem;
    padding-top: 3rem;
  }
`;

const BlockNumber = styled.p`
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.primary.normal};
  @media (max-width: ${MOBILE}) {
    font-size: 0.875rem;
  }
`;

const BlockTitle = styled.h1`
  text-align: center;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.0625rem;
  margin-top: 1rem;
  @media (max-width: ${MOBILE}) {
    margin-top: 0.75rem;
    font-size: 2rem;
    text-align: left;
  }
`;

const BlockSummary = styled.p`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.0625rem;
  margin-top: 1.75rem;
  @media (max-width: ${MOBILE}) {
    margin-top: 1.25rem;
    font-size: 1rem;

    line-height: 150%; /* 1.5rem */
  }
`;

const BlockInfo = styled.div`
  display: flex;
  color: #333;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.0625rem;
  @media (max-width: ${MOBILE}) {
    font-size: 0.9375rem;
  }
`;

const GuideContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  color: #000;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 2.125rem */
  letter-spacing: -0.03125rem;
  padding-top: 6.06rem;
  padding-bottom: 10rem;
  @media (max-width: ${MOBILE}) {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 3rem;
    padding-bottom: 5rem;
    font-size: 0.975rem;
  }
`;

const Text = styled.p``;
const TextBold = styled.span`
  font-weight: 700;
`;

const CommentContainer = styled.div`
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 6.38rem;
  padding-bottom: 8.87rem;
  @media (max-width: ${MOBILE}) {
    padding-left: 2rem;
    padding-right: 2rem;
    /* font-size: 0.975rem; */
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
`;

const Question = styled.p`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: -0.03125rem;
  margin-bottom: 3.19rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  @media (max-width: ${MOBILE}) {
    font-size: 1.3rem;
    line-height: 1.95rem; /* 150% */
    letter-spacing: -0.0455rem;
    margin-bottom: 1.8rem;
  }
`;
const QuestionHighlight = styled.span`
  color: ${({ theme }) => theme.primary.normal};
`;

const Button = styled.div`
  border: 1px solid white;
  background: ${({ theme }) => theme.primary.normal};
  display: flex;
  justify-content: center;
  border-radius: 3.13rem;
  color: white;
  font-size: 0.94rem;
  line-height: 1.44rem;
  letter-spacing: -0.04em;
  padding: 0.63rem 1.24rem;
  margin-bottom: 1.19rem;
  @media (max-width: 425px) {
  }
`;
const TextAreaContainer = styled.div`
  position: relative;
  margin-bottom: 0.5rem;
  width: 100%;
  ${({ isAuthEmpty }) => isAuthEmpty && "margin-top: 2.5em"};
  @media (max-width: 425px) {
    margin-top: 1rem;
  }
`;
const Textarea = styled.textarea`
  width: 100%;
  height: 7.25rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 0.94rem;
  border: 2px solid ${({ theme }) => theme.grayScaleColor.white};
  padding: 1.06rem 1.88rem;
  font-size: 1rem;
  line-height: 1.5rem;
  resize: none;
  // outline-color: ${({ theme }) => theme.primary.normal};
  &::placeholder {
    color: #888888;
  }
  &:focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.primary.normal};
  }
`;

const TextareaTriangle = styled.div`
  // width: 0;
  // height: 0;
  // border-left: 1.31rem solid transparent;
  // border-right: 1.31rem solid transparent;
  // border-bottom: 1.31rem solid ${({ theme }) => theme.primary.superLight};
  // border-top-left-radius: 0.31rem;
  // border-top-right-radius: 0.31rem;
  // margin-left: 2.06rem;
  // top: -20px;
  // position: absolute;
  // z-index: 0;
  display: block;
  height: 2.63rem;
  width: 2.63rem;
  background-color: ${({ theme }) => theme.primary.superLight};
  // border: inherit;
  border: 2px solid
    ${({ theme, clicked }) =>
      clicked ? theme.primary.normal : theme.primary.superLight};
  position: absolute;
  top: -1.2rem;
  left: 2.06rem;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(135deg);
  border-radius: 0 0 0 0.31rem;
`;

const TextLengthCheck = styled.p`
  font-size: 0.75rem;
  line-height: 1.19rem;
  font-weight: 300;
  text-align: right;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  font-family: Montserrat;
  letter-spacing: -1px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0.19rem;
  margin-bottom: 6.56rem;
  // border: 1px solid black;
  position: relative;
  @media (max-width: 425px) {
    margin-top: 1.69rem;
    margin-bottom: 3.56rem;
  }
`;
const Input = styled.input`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 1rem;
  width: 100%;
`;
const SubmitButton = styled.button`
  border: none;
  background: ${({ theme }) => theme.primary.normal};
  margin-top: 0.5rem;
  padding: 0.75rem 2.19rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.03125rem;
  border-radius: 0.75rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  cursor: pointer;
  &:disabled {
    background: ${({ theme }) => theme.grayScaleColor.gray10};
    color: ${({ theme }) => theme.grayScaleColor.gray30};
    cursor: not-allowed;
  }
  ${({ isAuthEmpty }) => isAuthEmpty && "margin-left: auto; margin-right: 0;"}
  @media (max-width: 425px) {
    width: 100%;
    padding: 1.25rem 0;
    ${({ isAuthEmpty }) => isAuthEmpty && "width: 100%;"}
  }
`;

const ReplyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 27rem;
  // border: 1px solid black;

  overflow-y: auto;
`;

const ReplyHeader = styled.h3`
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 1.31rem;
  letter-spacing: -1px;
`;

const ReplyItem = styled.div`
  width: 100%;
  padding: 1rem 1.38rem;
  background: ${({ theme, isPublic }) =>
    isPublic ? theme.grayScaleColor.white : theme.grayScaleColor.gray5};
  border-radius: 0.63rem;
  margin-bottom: 0.63rem;
`;

const ReplyItemTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.19rem;
`;

const ReplyItemInfo = styled.div`
  display: flex;
  align-items: center;
`;
const ReplyName = styled.span`
  font-size: 0.94rem;
  line-height: 1.44rem;
  color: #888;
  // ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 400;
`;

const ReplyDate = styled.span`
  font-size: 0.69rem;
  line-height: 1.44rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  font-family: Montserrat;
  margin-left: 0.5rem;
`;

const ReplyItemPublicButton = styled.button`
  border-radius: 6.25rem;
  width: 3.94rem;
  height: 1.44rem;
  border: 1px solid
    ${({ theme, isPublic }) =>
      isPublic ? theme.primary.normal : theme.grayScaleColor.gray30};
  color: ${({ theme, isPublic }) =>
    isPublic ? theme.primary.normal : theme.grayScaleColor.gray30};
  font-size: 0.63rem;
  line-height: 1.19rem;
  // padding: 0.13rem 0.63rem;
  background: none;
  letter-spacing: -0.04em;
  cursor: pointer;
`;

const ReplyComment = styled.p`
  font-size: 1rem;
  line-height: 1.44rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  font-weight: 500;
  // letter-spacing: -1px;
  margin-bottom: 0.38rem;
  word-break: keep-all;
  word-wrap: break-word;
`;

const CommentLikeButton = styled.button`
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 3.13rem;
  background: ${({ theme, isPublic }) =>
    isPublic ? theme.grayScaleColor.white : theme.grayScaleColor.gray10};
  border: none;
  color: ${({ theme, likeComment }) =>
    likeComment ? theme.primary.normal : theme.grayScaleColor.gray30};
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

const CommentLikeCount = styled.span`
  margin-left: 0.38rem;
  font-size: 0.69rem;
  line-height: 0.84rem;
  font-weight: 400;
  font-family: Montserrat;
  margin-top: 0.13rem;
`;

const NicknameInput = styled.input`
  margin-bottom: 1rem;
  border-radius: 0.31rem;
  // border: none;
  border: 1px solid
    ${({ theme, nicknameFocus, nickname }) =>
      !nicknameFocus && nickname.length > 0
        ? "#f4faf9"
        : theme.grayScaleColor.gray20};
  padding-left: 1.44rem;
  font-size: 0.94rem;
  // height: 2.94rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.primary.normal};
  }
  background: ${({ nickname, nicknameFocus }) =>
    nicknameFocus ? "none" : nickname.length > 0 ? "#f4faf9" : "none"};
`;

const MoreTrainingButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  background: ${({ theme }) => theme.grayScaleColor.white};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  font-size: 0.88rem;
  line-height: 1.75rem;
  padding: 0.25rem 1rem;
  cursor: pointer;
`;

const pulse = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0.2;
  }
  50% {
    transform: scale(1.4);
    opacity: 0.1;
  }
  100% {
    transform: scale(1.8);
    opacity: 0.05;
  }
`;
const Circle = styled.div`
  position: absolute;
  left: 25%;
  top: 20%;
  // transform: translateX(-50%);
  max-width: 220px;
  max-height: 220px;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background: ${({ theme }) => theme.primary.normal};

  border-radius: 50%;
  animation: ${pulse} 6s -4s infinite linear;
  @media (max-width: ${MOBILE}) {
    width: 10rem;
    height: 10rem;
  }
`;

const InnerCicle = styled(Circle)`
  animation: ${pulse} 6s -2s infinite linear;
`;

const CenterCicle = styled(Circle)`
  animation: ${pulse} 6s infinite linear;
`;

const fade = keyframes`
0% {
  opacity: 0;
}

100% {
  opacity: 1;
}
`;

const SymbolIcon = styled.img`
  opacity: 1;
  animation: ${fade} 2s;
`;

const WaveAnimation = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });
  return (
    <div
      style={{
        position: "relative",
        maxWidth: "27rem",
        width: isMobile ? "12rem" : "50%",
        height: "14rem",
        marginTop: isMobile && "2rem",
        marginRight: isMobile && "2rem",
        alignSelf: isMobile && "flex-end",
      }}
    >
      <CenterCicle />
      <InnerCicle />
      <Circle />
      <SymbolIcon
        src={`${process.env.PUBLIC_URL}/assets/block/block48_symbol.png`}
        style={{
          position: "absolute",
          left: isMobile ? "20%" : "24%",
          top: isMobile ? "20%" : "20%",
          maxWidth: "14rem",
          width: isMobile ? "10rem" : "80%",
        }}
      />
    </div>
  );
};

const NewBlock48 = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(true);
  const [block, setBlock] = useState();
  const [comment, setComment] = useState("");
  const [commentFocus, setCommentFocus] = useState(false);
  const [nickname, setNickname] = useState("");
  const [nicknameFocus, setNicknameFocus] = useState(false);
  //   const [updatedComment, setUpdatedComment] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [isLike, setIsLike] = useState(false);
  const [isLikeClicked, setIsLikeClicked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [comments, setComments] = useState([]);
  const [visible, setVisible] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showStartModal, setShowStartModal] = useState(true);
  const [showConfettiModal, setShowConfettiModal] = useState(false);
  const [showTimerModal, setShowTimerModal] = useState(false);
  const [confettiType, setConfettiType] = useState(0);
  const [cash, setCash] = useState(100);
  const actionRef = useRef();
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });

  const handleNicknameChange = (e) => {
    setNicknameFocus(true);
    setNickname(e.target.value);
  };
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };
  const handlePublicClick = async (isPublic, commentId) => {
    const response = await axiosPrivate.patch(`/block/comment`, {
      commentId,
      isPublic,
    });

    setComments(response.data);
    // console.log(response.data);
    // window.location.reload();
  };

  const handleCommentLikeClick = async (commentId, likeIds) => {
    const response = await axiosPrivate.patch(`/block/comment/like`, {
      commentId,
      userId: auth.user.id,
      isLike: likeIds.indexOf(auth.user.id) === -1 ? true : false,
    });

    const copyComments = comments.map((item) => {
      if (item._id === response.data.data._id) {
        return response.data.data;
      } else {
        return item;
      }
    });

    setComments(copyComments);
  };
  const handleMoreTrainingClick = () => {
    window.open(
      "https://zep.us/play/8ZwAV4?customData={%22blockLocation%22:%22tzone%22}",
      "_blank"
    );
  };

  const onTimerClick = () => {
    setShowTimerModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let response;
    if (!isAuthEmpty(auth)) {
      response = await axiosPrivate.post("/block/comment", {
        userId: auth.user.id,
        cid: auth.user.companyId._id,
        did: auth.user.departmentId._id,
        blockId: block._id,
        comment: comment.replaceAll("<br>", "\r\n"),
        isMobile,
        isPublic,
      });
    } else {
      response = await axiosPrivate.post("/training/comment", {
        blockId: block._id,
        comment: comment.replaceAll("<br>", "\r\n"),
        nickname,
      });
    }

    setComments(response.data.comments);
    setComment("");
    setVisible(true);
    if (response.data.isFirstBlockInToday && response.data.isWeekday) {
      setConfettiType(1);
      // response.data.cash: 블록 참여시 주는 금액
      setCash(response.data.cash);
      setAuth({
        accessToken: auth.accessToken,
        user: {
          ...auth.user,
          point: auth.user.point + response.data.cash,
          blockInWeek: response.data.blockInWeek,
          todayEarnedPointFromBlock: response.data.todayEarnedPointFromBlock,
          experiencePoint: response.data.experiencePoint,
        },
      });
    } else {
      setConfettiType(2);
      setAuth({
        accessToken: auth.accessToken,
        user: {
          ...auth.user,
          experiencePoint: response.data.experiencePoint,
        },
      });
    }

    if (!response.data.didClick) {
      setShowHelpModal(true);
    } else {
      setShowConfettiModal(true);
    }

    setTimeout(() => {
      setVisible(false);
    }, 2100);
  };

  const onHelpClick = async (e, type) => {
    // API 호출
    const res = await axiosPrivate.post(`/block/help`, {
      isHelping: type,
      blockId: block._id,
    });

    if (!res.data.ok) {
      return setShowHelpModal(false);
    }

    // 다음 팝업 노출
    setShowHelpModal(false);
    setShowConfettiModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const blockResponse = await axiosPrivate.get(`/block?number=48`);
      const response = await axiosPrivate.get(
        `/block/comment?id=${blockResponse.data._id}&userId=${auth.user.id}`
      );

      const likeResponse = await axiosPrivate.get(
        `/block/like?id=${blockResponse.data._id}&userId=${auth.user.id}`
      );
      setBlock(blockResponse.data);
      //   setLike(likeResponse?.data?.like || false);
      setLikes(blockResponse.data.likes);
      setIsLike(likeResponse?.data?.like || false);
      setComments(response.data);
      setIsLoading(false);
    };

    const fetchPublicData = async () => {
      const blockResponse = await axiosPrivate.get(`/block?number=48`);
      const commentResponse = await axiosPrivate.get(
        `/training/comment?id=${blockResponse.data._id}`
      );
      setBlock(blockResponse.data);
      setComments(commentResponse.data);
      setIsLoading(false);
    };

    if (!isAuthEmpty(auth)) {
      fetchData();
    } else {
      fetchPublicData();
    }
  }, []);

  if (isLoading) {
    return <div />;
  }
  const permavacPoint = [];
  if (block) {
    for (const key in block.permavacPoint) {
      const point = block.permavacPoint[key];
      if (point > 0) {
        permavacPoint.push([convertPermavac(key), point]);
      }
    }
  }
  return (
    <Container>
      <TopContainer>
        <Wrapper>
          {isMobile ? (
            <FlexColumn style={{ alignItems: "flex-start" }}>
              <BlockNumber>Training {block.number}</BlockNumber>
              <BlockTitle>{block.title}</BlockTitle>
              <BlockSummary>{block.summary}</BlockSummary>
              <FlexColumn
                style={{
                  alignItems: "flex-start",
                  gap: "0.75rem",
                  marginTop: isMobile ? "3rem" : "6.94rem",
                }}
              >
                <FlexRow style={{ alignItems: "center", gap: "0.5rem" }}>
                  <svg
                    style={{ width: "24px" }}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8949 13.7724L13.773 12.8943L10.6256 9.74663V5.83335H9.37565V10.2532L12.8949 13.7724ZM10.002 17.9167C8.90708 17.9167 7.87789 17.7089 6.91444 17.2933C5.95098 16.8778 5.11292 16.3138 4.40023 15.6015C3.68754 14.8891 3.12332 14.0514 2.70759 13.0884C2.29185 12.1253 2.08398 11.0964 2.08398 10.0014C2.08398 8.90647 2.29176 7.87728 2.70732 6.91383C3.12287 5.95037 3.68683 5.11231 4.39919 4.39963C5.11157 3.68693 5.94927 3.12272 6.9123 2.70698C7.87531 2.29124 8.90429 2.08337 9.99924 2.08337C11.0942 2.08337 12.1234 2.29115 13.0868 2.70671C14.0503 3.12226 14.8883 3.68622 15.601 4.39858C16.3137 5.11096 16.8779 5.94866 17.2937 6.91169C17.7094 7.8747 17.9173 8.90368 17.9173 9.99863C17.9173 11.0936 17.7095 12.1228 17.2939 13.0862C16.8784 14.0497 16.3144 14.8877 15.6021 15.6004C14.8897 16.3131 14.052 16.8773 13.089 17.2931C12.126 17.7088 11.097 17.9167 10.002 17.9167ZM10.0006 16.6667C11.8479 16.6667 13.4208 16.0174 14.7194 14.7188C16.018 13.4202 16.6673 11.8472 16.6673 10C16.6673 8.1528 16.018 6.57988 14.7194 5.28127C13.4208 3.98266 11.8479 3.33335 10.0006 3.33335C8.15341 3.33335 6.58049 3.98266 5.28188 5.28127C3.98327 6.57988 3.33396 8.1528 3.33396 10C3.33396 11.8472 3.98327 13.4202 5.28188 14.7188C6.58049 16.0174 8.15341 16.6667 10.0006 16.6667Z"
                      fill="#333333"
                    />
                  </svg>

                  <BlockInfo>
                    소요 시간:{" "}
                    <span style={{ marginLeft: "0.25rem" }}>10초</span>
                  </BlockInfo>
                </FlexRow>
                <FlexRow style={{ alignItems: "center", gap: "0.5rem" }}>
                  <svg
                    style={{ width: "24px" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.23047 12.5V11.5H7.23049V12.5H2.23047ZM8.24972 8.95769L6.45739 7.16537L7.16509 6.45767L8.95742 8.24999L8.24972 8.95769ZM11.4997 7.23077V2.23074H12.4997V7.23077H11.4997ZM15.7497 8.95769L15.042 8.24999L16.8343 6.45767L17.542 7.16537L15.7497 8.95769ZM16.7689 12.5V11.5H21.769V12.5H16.7689ZM11.9997 14C11.4356 14 10.9613 13.8077 10.5766 13.4231C10.192 13.0385 9.99972 12.5641 9.99972 12C9.99972 11.4359 10.192 10.9615 10.5766 10.5769C10.9613 10.1923 11.4356 9.99999 11.9997 9.99999C12.5638 9.99999 13.0382 10.1923 13.4228 10.5769C13.8074 10.9615 13.9997 11.4359 13.9997 12C13.9997 12.5641 13.8074 13.0385 13.4228 13.4231C13.0382 13.8077 12.5638 14 11.9997 14ZM16.8343 17.5423L15.042 15.75L15.7497 15.0423L17.542 16.8346L16.8343 17.5423ZM7.16509 17.5423L6.45739 16.8346L8.24972 15.0423L8.95742 15.75L7.16509 17.5423ZM11.4997 21.7692V16.7692H12.4997V21.7692H11.4997V21.7692Z"
                      fill="black"
                    />
                  </svg>

                  <BlockInfo>
                    기대 효과:{" "}
                    <FlexRow style={{ gap: "0.5rem", marginLeft: "0.25rem" }}>
                      {permavacPoint.map((item, index) => (
                        <FlexRow key={index}>
                          {item[0]}{" "}
                          <span
                            style={{ color: "#00DBB1", marginLeft: "0.25rem" }}
                          >
                            +{item[1]}
                          </span>
                        </FlexRow>
                      ))}
                    </FlexRow>
                  </BlockInfo>
                </FlexRow>
              </FlexColumn>
              <WaveAnimation />
            </FlexColumn>
          ) : (
            <FlexRow
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <FlexColumn style={{ alignItems: "flex-start" }}>
                <BlockNumber>Training {block.number}</BlockNumber>
                <BlockTitle>{block.title}</BlockTitle>
                <BlockSummary>{block.summary}</BlockSummary>
                <FlexColumn
                  style={{
                    alignItems: "flex-start",
                    gap: "0.75rem",
                    marginTop: isMobile ? "3rem" : "6.94rem",
                  }}
                >
                  <FlexRow style={{ alignItems: "center", gap: "0.5rem" }}>
                    <svg
                      style={{ width: "24px" }}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.8949 13.7724L13.773 12.8943L10.6256 9.74663V5.83335H9.37565V10.2532L12.8949 13.7724ZM10.002 17.9167C8.90708 17.9167 7.87789 17.7089 6.91444 17.2933C5.95098 16.8778 5.11292 16.3138 4.40023 15.6015C3.68754 14.8891 3.12332 14.0514 2.70759 13.0884C2.29185 12.1253 2.08398 11.0964 2.08398 10.0014C2.08398 8.90647 2.29176 7.87728 2.70732 6.91383C3.12287 5.95037 3.68683 5.11231 4.39919 4.39963C5.11157 3.68693 5.94927 3.12272 6.9123 2.70698C7.87531 2.29124 8.90429 2.08337 9.99924 2.08337C11.0942 2.08337 12.1234 2.29115 13.0868 2.70671C14.0503 3.12226 14.8883 3.68622 15.601 4.39858C16.3137 5.11096 16.8779 5.94866 17.2937 6.91169C17.7094 7.8747 17.9173 8.90368 17.9173 9.99863C17.9173 11.0936 17.7095 12.1228 17.2939 13.0862C16.8784 14.0497 16.3144 14.8877 15.6021 15.6004C14.8897 16.3131 14.052 16.8773 13.089 17.2931C12.126 17.7088 11.097 17.9167 10.002 17.9167ZM10.0006 16.6667C11.8479 16.6667 13.4208 16.0174 14.7194 14.7188C16.018 13.4202 16.6673 11.8472 16.6673 10C16.6673 8.1528 16.018 6.57988 14.7194 5.28127C13.4208 3.98266 11.8479 3.33335 10.0006 3.33335C8.15341 3.33335 6.58049 3.98266 5.28188 5.28127C3.98327 6.57988 3.33396 8.1528 3.33396 10C3.33396 11.8472 3.98327 13.4202 5.28188 14.7188C6.58049 16.0174 8.15341 16.6667 10.0006 16.6667Z"
                        fill="#333333"
                      />
                    </svg>

                    <BlockInfo>
                      소요 시간:{" "}
                      <span style={{ marginLeft: "0.25rem" }}>10초</span>
                    </BlockInfo>
                  </FlexRow>
                  <FlexRow style={{ alignItems: "center", gap: "0.5rem" }}>
                    <svg
                      style={{ width: "24px" }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.23047 12.5V11.5H7.23049V12.5H2.23047ZM8.24972 8.95769L6.45739 7.16537L7.16509 6.45767L8.95742 8.24999L8.24972 8.95769ZM11.4997 7.23077V2.23074H12.4997V7.23077H11.4997ZM15.7497 8.95769L15.042 8.24999L16.8343 6.45767L17.542 7.16537L15.7497 8.95769ZM16.7689 12.5V11.5H21.769V12.5H16.7689ZM11.9997 14C11.4356 14 10.9613 13.8077 10.5766 13.4231C10.192 13.0385 9.99972 12.5641 9.99972 12C9.99972 11.4359 10.192 10.9615 10.5766 10.5769C10.9613 10.1923 11.4356 9.99999 11.9997 9.99999C12.5638 9.99999 13.0382 10.1923 13.4228 10.5769C13.8074 10.9615 13.9997 11.4359 13.9997 12C13.9997 12.5641 13.8074 13.0385 13.4228 13.4231C13.0382 13.8077 12.5638 14 11.9997 14ZM16.8343 17.5423L15.042 15.75L15.7497 15.0423L17.542 16.8346L16.8343 17.5423ZM7.16509 17.5423L6.45739 16.8346L8.24972 15.0423L8.95742 15.75L7.16509 17.5423ZM11.4997 21.7692V16.7692H12.4997V21.7692H11.4997V21.7692Z"
                        fill="black"
                      />
                    </svg>

                    <BlockInfo>
                      기대 효과:{" "}
                      <FlexRow style={{ gap: "0.5rem", marginLeft: "0.25rem" }}>
                        {permavacPoint.map((item, index) => (
                          <FlexRow key={index}>
                            {item[0]}{" "}
                            <span
                              style={{
                                color: "#00DBB1",
                                marginLeft: "0.25rem",
                              }}
                            >
                              +{item[1]}
                            </span>
                          </FlexRow>
                        ))}
                      </FlexRow>
                    </BlockInfo>
                  </FlexRow>
                </FlexColumn>
              </FlexColumn>
              <WaveAnimation />
            </FlexRow>
          )}
        </Wrapper>
      </TopContainer>
      <GuideContainer>
        <Wrapper>
          <FlexColumn style={{ alignItems: "center" }}>
            <Text>
              쏟아지는 업무, 연속되는 회의로 바쁘게 돌아가는 일터에서
              <br />
              생각은 자꾸 많아지고, 한 숨 돌릴 틈도 없지요.
              <br />
              <br />
              당 떨어진다며, 달달한 간식을 챙기지만
              <br />
              지쳐가는 내 마음은 눈치채지 못하고 그대로 둡니다.
              <br />
              <br />
              이럴 때, 내 마음을 위한 10초를 위한 시간을 만들어 주세요.
              <br />
              회의 시작 전 10초, 업무 시작 전 10초, 점심 식사 후 10초,
              <br />
              10초 마음챙김 호흡을 하며 내 마음을 정리합니다.
              <br />
              <br />
              10초의 마음챙김 호흡이 습관화되면 호흡하는 시간도 조금씩
              늘려보세요.
              <br />
              <TextBold>
                마음챙김 호흡을 꾸준히 하면 불안함과 초조함이 70% 감소된다고
                합니다.
              </TextBold>
              <br />
              <br />
              지금 잠시 10초만 숨을 들이쉬고 내쉬어 보세요.
            </Text>
            <br />
            <br />
            <div
              style={{
                width: "9.5rem",
                height: "9.5rem",
                background: "rgba(0, 219, 177, 0.15)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <div
                style={{
                  width: "7.625rem",
                  height: "7.625rem",
                  borderRadius: "50%",
                  background: "#00DBB1",
                  boxShadow: "3px 4px 4px 0px #A3F9E9 inset",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={onTimerClick}
              >
                <p
                  style={{
                    color: "white",
                    fontSize: "1.375rem",
                    fontWeight: 700,
                    fontFamily: "Montserrat",
                  }}
                >
                  START
                </p>
              </div>
            </div>
            <br />
            <br />
            🫧 10초 마음챙김 호흡을 더 길게 하고 싶다면
            <span
              style={{
                color: "#00DBB1",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/blocks/42`)}
            >
              3분 마음챙김 호흡 해보기
            </span>
          </FlexColumn>
        </Wrapper>
      </GuideContainer>
      <CommentContainer ref={actionRef}>
        <Wrapper>
          <Question>
            <QuestionHighlight>Q.</QuestionHighlight> {block.question}
          </Question>
          {isAuthEmpty(auth) && (
            <NicknameInput
              placeholder="닉네임을 알려주세요 🙏"
              onChange={handleNicknameChange}
              nickname={nickname}
              nicknameFocus={nicknameFocus}
              onFocus={() => {
                setNicknameFocus(true);
              }}
              onBlur={() => {
                setNicknameFocus(false);
              }}
              value={nickname}
            />
          )}
          <TextAreaContainer isAuthEmpty={isAuthEmpty(auth)}>
            <Textarea
              placeholder="지금 당신의 생각이 옳습니다☺️"
              value={comment}
              onChange={handleCommentChange}
              onFocus={() => setCommentFocus(true)}
              onBlur={() => setCommentFocus(false)}
            ></Textarea>
          </TextAreaContainer>
          <FlexRow
            style={{
              justifyContent: "space-between",
            }}
          >
            {!isAuthEmpty(auth) && (
              <Toggle toggle={isPublic} setToggle={setIsPublic}></Toggle>
            )}
            <TextLengthCheck>{comment.length}자</TextLengthCheck>
          </FlexRow>
          <ButtonContainer>
            <SubmitButton
              disabled={
                isAuthEmpty(auth)
                  ? comment.length === 0 || nickname.length === 0
                  : comment.length === 0
              }
              onClick={handleSubmit}
              isAuthEmpty={isAuthEmpty(auth)}
            >
              트레이닝 완료 ✍️
            </SubmitButton>
            {!isAuthEmpty(auth) && (
              <Tooltip
                text={`${auth.user.nickname}님의 마음을 잘 기록했어요!`}
                visible={visible}
              />
            )}
          </ButtonContainer>
          <FlexRow
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <ReplyHeader>마음 함께 보기 👀</ReplyHeader>
            {/* <MoreTrainingButton onClick={handleMoreTrainingClick}>
              + 트레이닝 더하기
            </MoreTrainingButton> */}
          </FlexRow>
          <ReplyContainer>
            {comments.map((item) => {
              // console.log(item);
              return (
                <ReplyItem
                  key={item._id}
                  isPublic={!isAuthEmpty(auth) ? item.isPublic : true}
                >
                  <ReplyItemTop>
                    <ReplyItemInfo>
                      <ReplyName>
                        {isAuthEmpty(auth)
                          ? item.nickname
                          : item.userId.nickname}
                      </ReplyName>
                      {!isAuthEmpty(auth) && !item.isPublic && (
                        <svg
                          style={{ marginLeft: "0.25rem" }}
                          width="8"
                          height="11"
                          viewBox="0 0 8 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_659_4107)">
                            <path
                              d="M6.90914 4.46919V2.74977C6.90914 1.234 5.60384 0 3.99951 0C2.39518 0 1.09086 1.234 1.09086 2.74977V4.46826C0.488873 4.46826 0 4.93043 0 5.49954V9.96872C0 10.5378 0.488873 11 1.09086 11H6.90914C7.51113 11 8 10.5378 8 9.96872V5.50046C8 4.93136 7.51113 4.46919 6.90914 4.46919ZM1.81778 2.74977C1.81778 1.61248 2.79651 0.687209 3.99951 0.687209C5.20251 0.687209 6.18124 1.61248 6.18124 2.74977V4.46826H1.81778V2.74977ZM7.2721 9.96872C7.2721 10.1584 7.10882 10.3128 6.90815 10.3128H1.09086C0.8902 10.3128 0.726915 10.1584 0.726915 9.96872V5.50046C0.726915 5.31076 0.8902 5.1564 1.09086 5.1564H6.90914C7.1098 5.1564 7.27309 5.31076 7.27309 5.50046V9.96965L7.2721 9.96872Z"
                              fill="#222222"
                            />
                            <path
                              d="M3.99951 6.53174C3.79884 6.53174 3.63556 6.68611 3.63556 6.87581V8.5943C3.63556 8.784 3.79786 8.93837 3.99951 8.93837C4.20116 8.93837 4.36346 8.78493 4.36346 8.5943V6.87581C4.36346 6.68611 4.20017 6.53174 3.99951 6.53174Z"
                              fill="#222222"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_659_4107">
                              <rect width="8" height="11" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      <ReplyDate>
                        {converDate(new Date(item.createdAt))}
                      </ReplyDate>
                    </ReplyItemInfo>
                    {!isAuthEmpty(auth) && item.userId._id === auth.user.id && (
                      <ReplyItemPublicButton
                        isPublic={item.isPublic}
                        onClick={() =>
                          handlePublicClick(!item.isPublic, item._id)
                        }
                      >
                        {item.isPublic ? "마음 비공개" : "마음 공개"}
                      </ReplyItemPublicButton>
                    )}
                  </ReplyItemTop>
                  <ReplyComment>{lineBreaker(item.comment)}</ReplyComment>
                  {!isAuthEmpty(auth) && (
                    <CommentLikeButton
                      isPublic={item.isPublic}
                      likeComment={item.likeIds.indexOf(auth.user.id) !== -1}
                      onClick={() =>
                        handleCommentLikeClick(item._id, item.likeIds)
                      }
                      disabled={!item.isPublic}
                    >
                      <svg
                        width="13"
                        height="15"
                        viewBox="0 0 13 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 14.3731C1.79429 14.3731 3.0339 14.3731 3.0339 14.3731V6.01489C3.0339 6.01489 1.79429 6.01489 1 6.01489"
                          stroke={
                            item.likeIds.indexOf(auth.user.id) !== -1
                              ? "#00DBB1"
                              : "#BBBBBB"
                          }
                          strokeWidth="0.8"
                          strokeliterlimit="10"
                          strokeLinecap="round"
                        />
                        <path
                          d="M1 6V14.5"
                          stroke={
                            item.likeIds.indexOf(auth.user.id) !== -1
                              ? "#00DBB1"
                              : "#BBBBBB"
                          }
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.32202 6.01489H10.3152H11.5966C12.0359 6.01489 12.3898 6.39101 12.3898 6.85907V7.26027C12.3898 7.72415 12.0359 8.10444 11.5966 8.10444H11.5763"
                          stroke={
                            item.likeIds.indexOf(auth.user.id) !== -1
                              ? "#00DBB1"
                              : "#BBBBBB"
                          }
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.03387 6.85075C3.84743 6.38687 5.47455 5.1791 6.00743 4.02149C6.19862 3.60776 6.26371 2.55881 6.24337 1.80239C6.23116 1.36358 6.54032 1 6.92676 1H6.98777C7.94777 1 8.72879 1.89015 8.72879 2.98507V3.05612C8.72879 3.43224 8.32201 6.01493 8.32201 6.01493"
                          stroke={
                            item.likeIds.indexOf(auth.user.id) !== -1
                              ? "#00DBB1"
                              : "#BBBBBB"
                          }
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.5762 8.10449H11.5966C12.0359 8.10449 12.3898 8.47225 12.3898 8.92778V9.37494C12.3898 9.83046 12.0359 10.1982 11.5966 10.1982H11.1694"
                          stroke={
                            item.likeIds.indexOf(auth.user.id) !== -1
                              ? "#00DBB1"
                              : "#BBBBBB"
                          }
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.1695 10.194H11.1898C11.6291 10.194 11.983 10.5617 11.983 11.0173V11.4644C11.983 11.9199 11.6291 12.2877 11.1898 12.2877H10.7627"
                          stroke={
                            item.likeIds.indexOf(auth.user.id) !== -1
                              ? "#00DBB1"
                              : "#BBBBBB"
                          }
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.783 12.2836C11.2223 12.2836 11.5762 12.6597 11.5762 13.1277V13.5289C11.5762 13.9928 11.2223 14.3731 10.783 14.3731H4.75858C4.17689 14.3731 3.6196 14.2394 3.11926 14.0054"
                          stroke={
                            item.likeIds.indexOf(auth.user.id) !== -1
                              ? "#00DBB1"
                              : "#BBBBBB"
                          }
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <CommentLikeCount>{item.likeNums}</CommentLikeCount>
                    </CommentLikeButton>
                  )}
                </ReplyItem>
              );
            })}
          </ReplyContainer>
        </Wrapper>
      </CommentContainer>
      {showTimerModal && (
        <ModalBackground style={{ zIndex: 50 }}>
          <TimerModal
            onConfirm={() => setShowTimerModal(false)}
            onCancel={() => {
              setShowTimerModal(false);
            }}
            block={block}
            type={confettiType}
          ></TimerModal>
        </ModalBackground>
      )}
      {showStartModal && (
        <ModalBackground style={{ zIndex: 50 }}>
          <StartModal
            onConfirm={() => setShowStartModal(false)}
            onCancel={() => {
              actionRef?.current?.scrollIntoView({
                behavior: "instant",
                block: "nearest",
                inline: "nearest",
              });
              setShowStartModal(false);
            }}
            block={block}
            type={confettiType}
          ></StartModal>
        </ModalBackground>
      )}
      {showHelpModal && (
        <ModalBackground style={{ zIndex: 50 }}>
          <HelpModal
            onClick={(e, type) => onHelpClick(e, type)}
            block={block}
          ></HelpModal>
        </ModalBackground>
      )}
      {showConfettiModal && (
        <ModalBackground style={{ zIndex: 50 }}>
          <Modal
            close={() => setShowConfettiModal(false)}
            block={block}
            type={confettiType}
            cash={cash}
          ></Modal>
        </ModalBackground>
      )}
    </Container>
  );
};

export default NewBlock48;
