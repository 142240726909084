import React, { useState } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 24.81rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 0.63rem;
  display: flex;
  justify-content: center;
  @media (max-width: 425px) {
    margin-top: 21px;
    width: 100%;
    height: 300px;
    margin-bottom: 0;
  }
`;

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ChartDataLabels
);

const ReportRadarChart = ({ company, me, legend, compare }) => {
  //   console.log("Radar Chart =>", companyAverage, myAverage);

  const data = {
    labels: [
      "몰입",
      "자율성",
      "유능감",
      "성취",
      "관계",
      "긍정정서",
      "의미",
      "활력",
      "행복",
    ],
    datasets: [
      {
        label: legend,
        backgroundColor: "rgba(232, 251, 247, 0.4)",
        borderColor: "#00D2C1",
        borderWidth: 1,
        data: me,
      },
      {
        label: `${compare}`,
        backgroundColor: "rgba(241, 241, 241, 0.4)",
        borderColor: "rgba(187, 187, 187, 1)",
        borderWidth: 1,
        data: company,
        // datalabels: {
        //   align: "end",
        //   anchor: "end",
        // },
      },
      //   {
      //     label: "지난 주 평균, 맘핏",
      //     backgroundColor: "rgba(1, 127, 198, 0.2)",
      //     borderColor: "rgba(1, 127, 198, 1)",
      //     data: maumfit,
      //     // datalabels: {
      //     //   align: "start",
      //     //   anchor: "start",
      //     // },
      //   },
    ],
  };
  return (
    <Container>
      <Radar
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "top",
              align: "end",
              labels: {
                padding: 10,
                usePointStyle: false,
                pointStyle: "circle",
                pointStyleWidth: 32,
                font: {
                  size: 12,
                  weight: 300,
                  family: "PretendardVariable",
                },
              },
              title: {
                padding: 10,
              },
            },
            datalabels: {
              display: false,
              backgroundColor: function (context) {
                return context.dataset.borderColor;
              },
              color: "white",
              font: {
                weight: "bold",
              },

              align: function (context) {
                // console.log(context);
                // console.log(department);
                if (context.dataset.label === "지난 주 평균, 나") {
                  // console.log("hello me");
                  if (
                    context.dataset.data[context.dataIndex] >
                    company[context.dataIndex]
                  ) {
                    // console.log("greater than");
                    return "end";
                  } else {
                    // console.log("less than");
                    return "start";
                  }
                }
                // else {
                //   console.log("hi");
                //   if (
                //     context.dataset.data[context.dataIndex] >
                //     department[context.dataIndex]
                //   ) {
                //     return "center";
                //   } else {
                //     return "start";
                //   }
                // }
              },
              anchor: "start",
              //   formatter: Math.round,
              padding: 4,
              // display: true,
            },
          },
          scales: {
            r: {
              angleLines: {
                display: true,
              },
              beginAtZero: false,
              suggestedMax: 7,
              suggestedMin: 0,
              ticks: {
                stepSize: 1,
              },
              pointLabels: {
                color: "black",
              },
            },
          },
          // Core options
          aspectRatio: 4 / 3,
          elements: {
            point: {
              hoverRadius: 7,
              radius: 3,
            },
          },
        }}
      />
    </Container>
  );
};

export default ReportRadarChart;
