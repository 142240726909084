import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import { TABLET_LANDSCAPE, TYPE_LEADER, USER_PM } from "../../Constants";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import SwitchButton from "./SwitchButton";
import ModalBackground from "../../components/ModalBackground";
import SwitchDashboardModal from "./modals/SwitchDashboardModa";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: var(--sidebar-manager-width);
  // height: auto;
  height: 100vh;
  position: fixed;
  overflow: auto;
  background: ${({ theme }) => theme.grayScaleColor.white};
  box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.05);
  @media (max-width: ${TABLET_LANDSCAPE}) {
    width: var(--sidebar-tablet-landscape-width);
  }
  z-index: 1;
  // transition: all 0.3s;
`;

const Header = styled.div`
  @media (max-width: ${TABLET_LANDSCAPE}) {
    // width: var(--sidebar-tablet-landscape-width);
    padding: 2.62rem 1.12rem;
  }
  border: 1px solid black;
`;

const SidebarLogo = styled.img`
  width: 6.4375rem;
  height: 1.1875rem;
  margin-bottom: 2.25rem;
`;

const Wrapper = styled.div`
  padding: 1.88rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-bottom: 4.37rem;

  @media (max-width: ${TABLET_LANDSCAPE}) {
    padding: 1.88rem 0.56rem;
    padding-bottom: 4.37rem;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const Item = styled.li`
  width: 100%;
  padding: 0.75rem 0.94rem;
  border-radius: 0.625rem;
  // border: 1px solid black;
  font-size: 0.9375rem;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: ${({ theme, isMatch }) => (isMatch ? 600 : 400)};
  color: ${({ theme, isMatch }) =>
    isMatch ? theme.primary.normal : theme.grayScaleColor.gray80};
  background: ${({ theme, isMatch }) =>
    isMatch ? theme.primary.light : theme.grayScaleColor.white};
  &:hover {
    color: ${({ theme }) => theme.primary.normal};
    font-weight: 600;
    background: ${({ theme }) => theme.primary.light};
  }
  // transition: all 0.3s;
  cursor: pointer;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    padding: 0.75rem;
  }
`;

const SubItem = styled.li`
  font-weight: 500;
  font-size: 0.9375rem;
  color: ${({ theme, isMatch }) =>
    isMatch ? theme.grayScaleColor.gray80 : theme.grayScaleColor.gray70};
  cursor: pointer;
  line-height: normal;
`;

const BottomItem = styled.li`
  width: 100%;
  border-radius: 0.625rem;
  // border: 1px solid black;
  font-size: 0.9375rem;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: ${({ theme, isMatch }) =>
    isMatch ? theme.primary.normal : theme.grayScaleColor.gray80};

  &:hover {
    color: ${({ theme }) => theme.primary.normal};
  }
  // transition: all 0.3s;
  cursor: pointer;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    padding: 0.75rem;
  }
`;

const SLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const LogoutContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  padding: 0.75rem 1.25rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  font-size: 0.9375rem;
  line-height: normal;
  letter-spacing: -0.03125rem;
  align-items: center;
  color: ${({ theme }) => theme.backgroundColor.darkgray80};
  border-radius: 0.625rem;
  cursor: pointer;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    ${({ expandSidebar }) => !expandSidebar && "justify-content: center;"}
  }
`;

const Portal = ({ children }) => {
  const el = document.getElementById("portal");

  return ReactDOM.createPortal(children, el);
};

const ManagerIndexSidebar = ({ items, type, companyId }) => {
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const logout = useLogout();

  const isTablet = useMediaQuery({
    query: `(max-width: ${TABLET_LANDSCAPE})`,
  });

  const [isHover, setIsHover] = useState(false);
  const [expandSidebar, setExpandeSidebar] = useState(true);
  const [goDashboardClick, setGoDashboardClick] = useState(false);

  const sidebarRef = useRef();
  const menuRef = useRef([]);
  const handleExpandItem = (e, index) => {
    e.preventDefault();
    if (menuRef.current[index].style.display === "none") {
      menuRef.current[index].style.display = "flex";
      e.currentTarget.querySelector(".arrow").src = "/svg/sidebar_arrow_up.svg";
    } else {
      menuRef.current[index].style.display = "none";
      e.currentTarget.querySelector(".arrow").src =
        "/svg/sidebar_arrow_down.svg";
    }
  };
  const handleSidebarEnter = () => {
    if (isTablet) {
      setIsHover(true);
      setExpandeSidebar(true);
      sidebarRef.current.style =
        "width: var(--sidebar-width); filter: drop-shadow(4px 0px 10px rgba(0, 0, 0, 0.06));";
    }
    // console.log("hover!");
  };
  const handleSidebarLeave = () => {
    if (isTablet) {
      setIsHover(false);
      setExpandeSidebar(false);
      sidebarRef.current.style =
        "width: var(--sidebar-tablet-landscape-width); filter: none; transition: all 0.05s;";
    }
    // console.log("leave!");
  };

  const handleSwitchClick = () => {
    if (type === TYPE_LEADER) {
      navigate("/dashboard");
    } else {
      navigate("/leader/dashboard");
    }
  };

  const handleLogoutClick = async () => {
    // await logout();
    if (auth.user.role === USER_PM) {
      navigate("/dashboard");
      return;
    }
    setGoDashboardClick(true);
  };

  const handleHover = (e, src) => {
    e.currentTarget.querySelector(".icon").src = src;
    // if (e.target.tagName === "IMG") {
    //   console.log("hi");
    //   e.target.src = src;
    // } else {
    //   e.currentTarget.querySelector(".icon").src = src;
    // }
  };

  useEffect(() => {
    if (!isTablet) {
      setExpandeSidebar(true);
      sidebarRef.current.style = "width: var(--sidebar-width);";
    } else {
      setExpandeSidebar(false);
      sidebarRef.current.style =
        "width: var(--sidebar-tablet-landscape-width); filter: none; transition: all 0.05s;";
    }
  }, [isTablet]);
  return (
    <Container
      ref={sidebarRef}
      onMouseEnter={handleSidebarEnter}
      onMouseLeave={handleSidebarLeave}
    >
      <Wrapper>
        <FlexColumn>
          <SidebarLogo
            src={`${process.env.PUBLIC_URL}/assets/logo/maumfit_logo.png`}
          />

          <List>
            {items.map((item, index) => {
              let isMatch = location.pathname === item.link;
              if (item.role > auth.user.role) {
                return;
              }
              if (item.children) {
                isMatch = location.pathname.includes(item.link);

                return (
                  <FlexColumn key={index}>
                    <Item
                      key={index}
                      isMatch={isMatch}
                      onClick={(e) => handleExpandItem(e, index)}
                      onMouseEnter={(e) =>
                        handleHover(
                          e,
                          `${process.env.PUBLIC_URL}/svg/${item.srcActive}`
                        )
                      }
                      onMouseLeave={
                        !isMatch
                          ? (e) =>
                              handleHover(
                                e,
                                `${process.env.PUBLIC_URL}/svg/${
                                  isMatch ? item.srcActive : item.src
                                }`
                              )
                          : () => {}
                      }
                    >
                      <FlexRow
                        style={{
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <FlexRow style={{ alignItems: "center" }}>
                          {item.src && (
                            <img
                              className="icon"
                              id="icon"
                              style={{
                                marginRight: "0.75rem",
                                width: "1.5rem",
                                height: "1.5rem",
                              }}
                              src={`${process.env.PUBLIC_URL}/svg/${
                                isMatch ? item.srcActive : item.src
                              }`}
                              alt=""
                            />
                          )}
                          {expandSidebar && item.title}
                        </FlexRow>
                        {expandSidebar && (
                          <img
                            className="arrow"
                            src={`${process.env.PUBLIC_URL}/svg/sidebar_arrow_down.svg`}
                            alt=""
                          />
                        )}
                      </FlexRow>
                    </Item>

                    {expandSidebar && (
                      <div
                        ref={(el) => (menuRef.current[index] = el)}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                          marginLeft: "3rem",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        {item.children.map((child, cidx) => {
                          isMatch = location.pathname === child.link;
                          return (
                            <SubItem key={cidx} isMatch={isMatch}>
                              <SLink to={`${child.link}?id=${companyId}`}>
                                {child.title}
                              </SLink>
                            </SubItem>
                          );
                        })}
                      </div>
                    )}
                  </FlexColumn>
                );
              }
              return (
                <SLink key={index} to={`${item.link}?id=${companyId}` || ""}>
                  <Item
                    isMatch={isMatch}
                    onMouseEnter={(e) =>
                      handleHover(
                        e,
                        `${process.env.PUBLIC_URL}/svg/${item.srcActive}`
                      )
                    }
                    onMouseLeave={(e) =>
                      handleHover(
                        e,
                        `${process.env.PUBLIC_URL}/svg/${
                          isMatch ? item.srcActive : item.src
                        }`
                      )
                    }
                    src={`${process.env.PUBLIC_URL}/svg/${item.srcActive}`}
                  >
                    {item.src && (
                      <img
                        style={{ width: "1.5rem", height: "1.5rem" }}
                        className="icon"
                        src={`${process.env.PUBLIC_URL}/svg/${
                          isMatch ? item.srcActive : item.src
                        }`}
                        alt=""
                      />
                    )}
                    {expandSidebar && item.title}
                  </Item>
                </SLink>
              );
            })}
          </List>
        </FlexColumn>
        <FlexColumn style={{ gap: "1.5rem" }}>
          <LogoutContainer
            expandSidebar={expandSidebar}
            onClick={handleLogoutClick}
            style={{}}
          >
            <img
              src={`${process.env.PUBLIC_URL}/svg/sidebar_logout_gray80.svg`}
              alt=""
            />

            {expandSidebar && <span>내 대시보드</span>}
          </LogoutContainer>
        </FlexColumn>
      </Wrapper>
      {goDashboardClick && (
        <Portal>
          <ModalBackground>
            <SwitchDashboardModal onClose={() => setGoDashboardClick(false)} />
          </ModalBackground>
        </Portal>
      )}
    </Container>
  );
};

export default ManagerIndexSidebar;
