import styled from "styled-components";

const Text = styled.p`
  font-size: 0.875rem;
  line-height: 160%;
  text-align: right;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const ReportComment = ({ children, style }) => {
  return <Text style={style}>{children}</Text>;
};

export default ReportComment;
