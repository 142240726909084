import styled from "styled-components";

const Text = styled.span`
  width: 100%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.error};
  line-height: 1.19rem;
`;

const ErrorText = ({ children, style }) => {
  return <Text style={style}>{children}</Text>;
};

export default ErrorText;
