import { useState } from "react";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import { USER_ADMIN, USER_LEADER, USER_NORMAL } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import validateEmail from "../../../utils/validateEmail";
import CloseXButton from "../components/CloseXButton";
import SDropdown from "../components/Dropdown";
import ModalHeading2 from "../components/ModalHeading2";
import InviteModal from "./InviteModal";
import InviteSuccessModal from "./InviteSuccessModal";

const Label = styled.p`
  font-weight: 500;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: #000;
  // flex: 1;
`;

const Input = styled.input`
  border: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  border-radius: 0.4375rem;
  padding: 0.75rem;
  font-size: 0.9375rem;
  line-height: 140%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  outline: none;
  &:placeholder {
    color: ${({ theme }) => theme.grayScaleColor.gray30};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.grayScaleColor.black};
  }
  // flex: 3;
  width: 17.75rem;
`;

const SmallText = styled.p`
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  margin-left: 0.5rem;
`;

const ModalButton = styled.button`
  border-radius: 0.4375rem;
  padding: 0.78rem 3.7rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.03125rem;
  cursor: pointer;
`;

const Button = styled.button`
  display: flex;
  padding: 0.625rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: -0.5rem;
  border-radius: 0.4375rem;
  border: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 140%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme, isActive }) =>
    isActive ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  background: white;
  width: 6.375rem;
  cursor: pointer;
  // transition: 0.3s all;
`;

const CancelButton = styled(ModalButton)`
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const OkButton = styled(ModalButton)`
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
  margin: 0 auto;
  margin-top: 3.81rem;
  &:disabled {
    opacity: 0.3;
  }
`;

const UnCheckmark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.99941 15.6879L6.71094 12.3994L7.39941 11.7109L9.99941 14.3109L16.5994 7.71094L17.2879 8.39941L9.99941 15.6879Z"
      fill="#BBBBBB"
    />
  </svg>
);

const Checkmark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.99941 15.6879L6.71094 12.3994L7.39941 11.7109L9.99941 14.3109L16.5994 7.71094L17.2879 8.39941L9.99941 15.6879Z"
      fill="#111111"
    />
  </svg>
);

const RequiredDot = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="7"
    viewBox="0 0 6 7"
    fill="none"
  >
    <circle cx="3" cy="3.5" r="3" fill="#00DBB1" />
  </svg>
);

const AddUserModal = ({
  domain,
  companyId,
  departments,
  setDepartments,
  setUsers,
  closeHandler,
}) => {
  const years = [];
  const validYear = new Date().getFullYear() - 14;
  for (let i = validYear; i >= validYear - 100; i--) {
    years.push(i);
  }
  const workExperiences = [];
  for (let i = 1; i < 30; i++) {
    workExperiences.push(i);
  }
  workExperiences.push("30년 이상");
  const positions = [
    "기획/전략",
    "마케팅, 광고, 홍보, 리서치",
    "회계, 세무, 재무",
    "인사, 노무, HRD",
    "총무",
    "법무",
    "IT개발, 데이터",
    "PM, PO, 서비스기획",
    "디자인",
    "영업, 판매",
    "무역, 수입/수출",
    "고객상담, TM(텔레마케팅)",
    "구매, 자재, 물류",
    "상품기획, MD",
    "운전, 운송, 배송",
    "서비스",
    "생산",
    "건설, 건축",
    "의료, 보건",
    "연구, R&D",
    "교육",
    "기타",
  ];
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [birthYear, setBirthYear] = useState();
  const [workExperience, setWorkExperience] = useState();
  const [position, setPosition] = useState();
  const [openBirthYear, setOpenBirthYear] = useState(false);
  const [openWorkExperience, setOpenWorkExperience] = useState(false);
  const [openPosition, setOpenPosition] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [department, setDepartment] = useState("");
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openInviteSuccessModal, setOpenInviteSuccessModal] = useState(false);
  const [role, setRole] = useState();
  const [gender, setGender] = useState();
  const [newUser, setNewUser] = useState();

  const handleSignupClick = async () => {
    if (!validateEmail(formData.email)) {
      return alert("이메일 형식이 올바르지 않습니다.");
    }
    const res = await axiosPrivate.post("/signup", {
      email: formData.email,
      name: formData.name,
      companyId,
      departmentId: department._id,
      gender,
      role,
      birthYear,
      position,
      workExperience,
    });

    if (!res.data.ok) {
      return alert(res.data.msg);
    }

    setNewUser(res.data.data);
    setUsers((prev) => {
      return [...prev, res.data.data];
    });
    setDepartments(res.data.departments);
    setAuth({
      accessToken: auth.accessToken,
      user: {
        ...auth.user,
        companyId: res.data.company,
      },
    });

    setOpenInviteModal(true);
  };

  const handleRoleClick = (data) => {
    setRole(data);
  };

  const handleGenderClick = (data) => {
    setGender(data);
  };
  const handleNameChange = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        name: e.target.value,
      };
    });
  };

  const handleEmailChange = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        email: e.target.value,
      };
    });
  };

  const confirmHandler = async () => {
    await axiosPrivate.post("/users/invite", {
      users: [newUser],
    });
    setOpenInviteSuccessModal(true);
  };

  if (openInviteSuccessModal) {
    return <InviteSuccessModal closeHandler={closeHandler} users={[newUser]} />;
  }
  if (openInviteModal) {
    return (
      <InviteModal
        name={formData.name}
        email={`${formData.email}`}
        closeHandler={closeHandler}
        confirmHandler={confirmHandler}
      />
    );
  }

  return (
    <FlexColumn>
      <FlexRow
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <FlexRow style={{ gap: "0.5rem" }}>
          <ModalHeading2 text="구성원 추가하기" />
          <img
            src={`${process.env.PUBLIC_URL}/svg/dashboard/arrow_right.svg`}
          />
          <ModalHeading2 text="초대하기" style={{ color: "#D9D9D9" }} />
        </FlexRow>
        <CloseXButton onClick={closeHandler} />
      </FlexRow>
      <FlexColumn style={{ marginTop: "2.69rem", gap: "1rem" }}>
        <FlexRow style={{ alignItems: "center" }}>
          <FlexRow
            style={{
              alignItems: "center",
              gap: "0.5rem",
              width: "5.5rem",
            }}
          >
            <Label>이름</Label>
            <RequiredDot />
          </FlexRow>
          <Input
            placeholder="이름 입력"
            onChange={handleNameChange}
            isActive={formData["name"].length > 0}
          />
        </FlexRow>
        {/* <FlexRow style={{ alignItems: "center" }}>
          <FlexRow style={{ width: "5.5rem" }}>
            <Label>닉네임</Label>
          </FlexRow>
          <Input placeholder="소속/팀 입력" />
        </FlexRow> */}
        <FlexRow style={{ alignItems: "center" }}>
          <FlexRow
            style={{
              alignItems: "center",
              gap: "0.5rem",
              width: "5.5rem",
            }}
          >
            <Label>이메일</Label>
            <RequiredDot />
          </FlexRow>
          <FlexRow style={{ alignItems: "center" }}>
            <Input
              placeholder="이메일 주소 입력"
              isActive={formData["email"].length > 0}
              // style={{ width: "12rem" }}
              onChange={handleEmailChange}
            />
            {/* <SmallText>@{domain}</SmallText> */}
          </FlexRow>
        </FlexRow>
        <FlexRow style={{ alignItems: "center" }}>
          <FlexRow
            style={{
              alignItems: "center",
              gap: "0.5rem",
              width: "5.5rem",
            }}
          >
            <Label>그룹</Label>
            <RequiredDot />
          </FlexRow>
          <SDropdown
            items={departments}
            close={(toggle) => setOpenDropdown(toggle)}
            isOpen={openDropdown}
            setItem={setDepartment}
            item={department}
            label="소속 그룹 선택"
          />
        </FlexRow>
        <FlexRow style={{ alignItems: "center" }}>
          <FlexRow
            style={{
              alignItems: "center",
              gap: "0.5rem",
              width: "5.5rem",
            }}
          >
            <Label>역할</Label>
            <RequiredDot />
          </FlexRow>
          <FlexRow style={{ gap: "0.75rem" }}>
            <Button
              isActive={role === USER_NORMAL}
              onClick={() => handleRoleClick(USER_NORMAL)}
            >
              <FlexRow style={{ gap: "0.5rem" }}>
                {role === USER_NORMAL ? <Checkmark /> : <UnCheckmark />}
                <span>구성원</span>
              </FlexRow>
            </Button>
            <Button
              isActive={role === USER_LEADER}
              onClick={() => handleRoleClick(USER_LEADER)}
            >
              <FlexRow style={{ gap: "0.5rem" }}>
                {role === USER_LEADER ? <Checkmark /> : <UnCheckmark />}
                <span>리더</span>
              </FlexRow>
            </Button>
            <Button
              isActive={role === USER_ADMIN}
              onClick={() => handleRoleClick(USER_ADMIN)}
            >
              <FlexRow style={{ gap: "0.5rem" }}>
                {role === USER_ADMIN ? <Checkmark /> : <UnCheckmark />}
                <span>관리자</span>
              </FlexRow>
            </Button>
          </FlexRow>
        </FlexRow>
        {/* <FlexRow style={{ alignItems: "center" }}>
          <FlexRow
            style={{
              alignItems: "center",
              gap: "0.5rem",
              width: "5.5rem",
            }}
          >
            <Label>성별</Label>
          </FlexRow>
          <FlexRow style={{ gap: "0.75rem" }}>
            <Button
              isActive={gender === "남자"}
              onClick={() => handleGenderClick("남자")}
            >
              <FlexRow style={{ gap: "0.5rem" }}>
                {gender === "남자" ? <Checkmark /> : <UnCheckmark />}
                <span>남</span>
              </FlexRow>
            </Button>
            <Button
              isActive={gender === "여자"}
              onClick={() => handleGenderClick("여자")}
            >
              <FlexRow style={{ gap: "0.5rem" }}>
                {gender === "여자" ? <Checkmark /> : <UnCheckmark />}
                <span>여</span>
              </FlexRow>
            </Button>
          </FlexRow>
        </FlexRow>
        <FlexRow style={{ alignItems: "center" }}>
          <FlexRow
            style={{
              alignItems: "center",
              gap: "0.5rem",
              width: "5.5rem",
            }}
          >
            <Label>출생연도</Label>
          </FlexRow>
          <SDropdown
            items={years}
            close={(toggle) => setOpenBirthYear(toggle)}
            isOpen={openBirthYear}
            setItem={setBirthYear}
            item={birthYear}
            label="출생연도를 선택해 주세요"
          />
        </FlexRow>
        <FlexRow style={{ alignItems: "center" }}>
          <FlexRow
            style={{
              alignItems: "center",
              gap: "0.5rem",
              width: "5.5rem",
            }}
          >
            <Label>연차</Label>
          </FlexRow>
          <SDropdown
            items={workExperiences}
            close={(toggle) => setOpenWorkExperience(toggle)}
            isOpen={openWorkExperience}
            setItem={setWorkExperience}
            item={workExperience}
            label="해당되는 연차를 선택해 주세요"
          />
        </FlexRow>
        <FlexRow style={{ alignItems: "center" }}>
          <FlexRow
            style={{
              alignItems: "center",
              gap: "0.5rem",
              width: "5.5rem",
            }}
          >
            <Label>직무</Label>
          </FlexRow>
          <SDropdown
            items={positions}
            close={(toggle) => setOpenPosition(toggle)}
            isOpen={openPosition}
            setItem={setPosition}
            item={position}
            label="해당되는 직무를 선택해 주세요"
          />
        </FlexRow> */}
        {/* <FlexRow style={{ alignItems: "center" }}>
          <FlexRow
            style={{
              alignItems: "center",
              gap: "0.5rem",
              width: "5.5rem",
            }}
          >
            <Label>권한</Label>
            <RequiredDot />
          </FlexRow>
          <Input placeholder="직책 입력" />
        </FlexRow> */}
      </FlexColumn>

      <OkButton
        disabled={
          formData.name.length <= 0 ||
          formData.email.length <= 0 ||
          !department._id ||
          !role
        }
        onClick={handleSignupClick}
      >
        추가 완료하기
      </OkButton>
    </FlexColumn>
  );
};

export default AddUserModal;
