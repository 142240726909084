import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getDate } from "../../../utils/date";
import CardLayout from "../CardLayout";
import Badge from "../components/Badge";
import CloseXButton from "../components/CloseXButton";
import Divider from "../components/Divider";
import Heading1 from "../components/Heading1";
import Heading2 from "../components/Heading2";
import Modal from "../components/Modal";
import ModalHeading2 from "../components/ModalHeading2";
import Text1 from "../components/Text1";
import TextLink from "../components/TextLink";
import AddManagerModal from "../modals/AddManagerModal";
import DeleteManagerModal from "../modals/DeleteManagerModal";
import EditManagerModal from "../modals/EditManagerModal";
import SelectPlanModal from "../modals/SelectPlanModal";
import { useNavigate } from "react-router-dom";
import {
  MONTH_PRICE,
  PAYMENT_TYPE,
  TAX,
  YEAR_PRICE,
} from "../../../constants/payments";

const Container = styled.div`
  padding: 2.75rem 2.75rem;
  padding-left: calc(2.75rem + var(--sidebar-width));
  width: 100%;
  position: relative;
`;

const KeyText = styled.p`
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  flex: 1;
`;
const ValueText = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  flex: 4;
`;

const SubText = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const Ul = styled.ul`
  flex: 4;
`;

const Li = styled.li`
  display: flex;
  align-items: center;
  padding: 0.94rem 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
`;

const HeaderItem = styled.p`
  flex: 1;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Item = styled.p`
  flex: 1;
  font-size: 0.9375rem;
  line-height: 150%; /* 1.40625rem */
  letter-spacing: -0.03125rem;
`;

const Button = styled.button`
  border: none;
  border-radius: 0.3125rem;
  display: flex;
  padding: 0.25rem 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
`;

const EditButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  color: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const DeleteButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.error};
`;

const AddButton = ({ style, onClick }) => (
  <svg
    style={style}
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <rect width="46" height="46" rx="10" fill="#F7F7F7" />
    <path
      d="M22.7997 30.7431V16H23.9185V30.7431H22.7997ZM16 23.9309V22.8122H30.7307V23.9309H16Z"
      fill="#BBBBBB"
    />
  </svg>
);

const ManagerHome = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [showSelectPlanModal, setShowSelectPlanModal] = useState(
    !auth.user.companyId.isFirstTimeSubscribeFinished
  );
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [managers, setManagers] = useState([]);
  const [company, setCompany] = useState("");
  const [subscribeInfo, setSubscribeInfo] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const now = new Date();

  const onManageClick = () => {
    navigate("users");
  };

  useEffect(() => {
    (async () => {
      const managersResponse = await axiosPrivate.get("/managers");
      const companyResponse = await axiosPrivate.get(
        `/company/${auth.user.companyId._id}`
      );
      const subscribeResponse = await axiosPrivate.get("/payments/subscribe");
      if (managersResponse.data.ok) {
        setManagers(managersResponse.data.data.managers);
      }

      if (companyResponse.data.ok) {
        setCompany(companyResponse.data.data.company);
      }

      if (subscribeResponse.data.ok) {
        setSubscribeInfo(subscribeResponse.data.data);
      }

      setIsLoading(false);
    })();
  }, []);
  if (!auth.user.companyId.isFirstTimeSubscribeFinished) {
    return (
      <Modal
        showing={showSelectPlanModal}
        setShowing={setShowSelectPlanModal}
        width={"auto"}
      >
        <SelectPlanModal closeHandler={() => setShowSelectPlanModal(false)} />
      </Modal>
    );
  }
  if (isLoading) {
    return <div></div>;
  }
  console.log(subscribeInfo);
  return (
    <Container>
      <Heading1 text={`${company.name} 관리자님`} />
      <Heading2
        text="1일 째 이용 중인 맘핏 정보를 알려드립니다"
        style={{ marginBottom: "2.44rem" }}
      />
      <FlexColumn style={{ gap: "1.88rem" }}>
        <CardLayout style={{ padding: "1.88rem 2.5rem" }}>
          <FlexRow
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <FlexRow style={{ alignItems: "center", gap: "1rem" }}>
              <Text1 text="구독 정보" />
              <span
                style={{
                  color: "#777",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/manager/dashboard/payment")}
              >
                상세보기
              </span>
            </FlexRow>
            <SubText>
              다음 결제일은{" "}
              <Bold>
                {getDate(subscribeInfo?.nextPayDate, "YYYY년 M월 D일")}
              </Bold>{" "}
              입니다
            </SubText>
          </FlexRow>
          <Divider />
          <FlexColumn style={{ gap: "1.25rem" }}>
            <FlexRow>
              <KeyText>회사명</KeyText>
              <ValueText>{company.name}</ValueText>
            </FlexRow>
            <FlexRow>
              <KeyText>서비스 이용 시작일</KeyText>
              <ValueText>
                {getDate(company.createdAt, "YYYY년 M월 D일")}
              </ValueText>
            </FlexRow>
            <FlexRow>
              <KeyText>구독 형태</KeyText>
              <FlexRow
                style={{
                  alignItems: "center",
                  gap: "0.63rem",
                  flex: 4,
                }}
              >
                <ValueText style={{ flex: "none" }}>
                  {subscribeInfo?.nextPlanType === PAYMENT_TYPE.MONTH
                    ? "월"
                    : "연"}{" "}
                  구독
                </ValueText>
                {subscribeInfo?.nextPlanType === PAYMENT_TYPE.MONTH && (
                  <Badge>연 구독 시 25% 할인</Badge>
                )}
              </FlexRow>
            </FlexRow>
            <FlexRow>
              <KeyText>사용 인원</KeyText>
              <ValueText>{company.numUsers}명</ValueText>
            </FlexRow>
            <FlexRow>
              <KeyText>결제 금액</KeyText>
              <ValueText>
                {subscribeInfo?.nextPlanType === PAYMENT_TYPE.MONTH
                  ? (
                      company.numUsers * MONTH_PRICE * (1 + TAX / 100) +
                      subscribeInfo?.extraAmount
                    ).toLocaleString()
                  : (
                      company.numUsers * YEAR_PRICE * (1 + TAX / 100) +
                      subscribeInfo?.extraAmount
                    ).toLocaleString()}
                원{" "}
                <span style={{ color: "#777", fontSize: "0.75rem" }}>
                  (VAT 포함)
                </span>
              </ValueText>
            </FlexRow>
          </FlexColumn>
        </CardLayout>
        <CardLayout style={{ padding: "1.88rem 2.5rem" }}>
          <FlexRow
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Text1 text="관리자 정보" />
          </FlexRow>
          <Divider />
          <FlexColumn style={{ gap: "1.25rem" }}>
            <FlexRow style={{ alignItems: "flex-start" }}>
              <KeyText>
                관리자 계정{" "}
                <span style={{ color: "#00DBB1" }}>{managers.length}</span>
              </KeyText>
              <Ul>
                <Li style={{ background: "#F7F7F7" }}>
                  <HeaderItem>이름</HeaderItem>
                  <HeaderItem style={{ flex: 1.5 }}>소속</HeaderItem>
                  <HeaderItem>역할</HeaderItem>
                  <HeaderItem style={{ flex: 3 }}>이메일</HeaderItem>
                  <HeaderItem style={{ marginLeft: "auto" }}></HeaderItem>
                </Li>
                {managers.map((manager, index) => (
                  <Li>
                    <Item>{manager.name}</Item>
                    <Item style={{ flex: 1.5 }}>{manager.department}</Item>
                    <Item>Manager</Item>
                    <Item style={{ flex: 3 }}>{manager.email}</Item>
                    <Item>
                      <FlexRow
                        style={{
                          marginLeft: "auto",
                          gap: "0.62rem",
                        }}
                      >
                        <EditButton onClick={onManageClick}>관리</EditButton>
                      </FlexRow>
                    </Item>
                  </Li>
                ))}
              </Ul>
            </FlexRow>
          </FlexColumn>
          {/* <FlexRow style={{ justifyContent: "center", marginTop: "1.69rem" }}>
            <AddButton
              style={{ cursor: "pointer" }}
              onClick={() => setShowAddModal(true)}
            >
              +
            </AddButton>
          </FlexRow> */}
        </CardLayout>

        <Modal showing={showAddModal} setShowing={setShowAddModal}>
          <AddManagerModal closeHandler={() => setShowAddModal(false)} />
        </Modal>
        <Modal showing={showEditModal} setShowing={setShowAddModal}>
          <EditManagerModal closeHandler={() => setShowEditModal(false)} />
        </Modal>
        <Modal showing={showDeleteModal} setShowing={setShowDeleteModal}>
          <DeleteManagerModal closeHandler={() => setShowDeleteModal(false)} />
        </Modal>
      </FlexColumn>
    </Container>
  );
};

export default ManagerHome;
