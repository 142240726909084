import styled from "styled-components";

const Container = styled.button`
  color: ${({ theme }) => theme.grayScaleColor.white};
  background: ${({ theme }) => theme.primary.normal};
  //   padding: 0.69rem 3.31rem;
  border-radius: 0.31rem;
  border: none;
  width: 20.56rem;
  height: 3.75rem;
  margin: 0 auto;
  margin-bottom: 3.13rem;
  cursor: pointer;
  font-size: 1.13rem;
  line-height: 1.63rem;
  letter-spacing: -0.04em;
  font-weight: 500;
  @media (max-width: 425px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // padding: 0.69rem 5.88rem;

    margin-bottom: 2.63rem;
  }
`;

const Button = ({ text, handler }) => (
  <Container onClick={handler}>{text}</Container>
);

export default Button;
