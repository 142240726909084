import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const PRIMARY_NORMAL = "#00DBB1";

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    marginBottom: 20,
    marginTop: 40,
  },
  tableHead: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#F7F7F7",
    borderStyle: "solid",
    borderColor: "#D9D9D9",
    borderBottomWidth: 1,
    borderTopColor: "#3A3F3F",
    borderTopWidth: 1,
  },
  tableHeadCol: {
    flexDirection: "row",
    width: "25%",
  },
  tableHeadCell: {
    margin: "auto",
    marginTop: 8,
    marginBottom: 8,
    fontSize: 10,
    color: "#111111",
    fontWeight: 600,
  },
  tableRow: {
    // margin: "auto",
    alignItems: "center",
    flexDirection: "row",
    // padding: "8px 0",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderColor: "#D9D9D9",
    borderBottomWidth: 1,
  },
  tableCell: {
    margin: "auto",
    marginTop: 10,
    marginBottom: 8,
    fontSize: 10,
  },
});

const TablePdf = ({ results }) => (
  <View style={styles.table}>
    <View style={styles.tableHead}>
      <View style={styles.tableHeadCol}>
        <Text style={styles.tableHeadCell}></Text>
      </View>
      <View style={styles.tableHeadCol}>
        <Text style={styles.tableHeadCell}>Q1</Text>
      </View>
      <View style={styles.tableHeadCol}>
        <Text style={styles.tableHeadCell}>Q2</Text>
      </View>
      <View style={styles.tableHeadCol}>
        <Text style={styles.tableHeadCell}>Q3</Text>
      </View>
      <View style={styles.tableHeadCol}>
        <Text style={styles.tableHeadCell}>Q4</Text>
      </View>
    </View>
    {/* <View style={styles.tableRow}>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>1차 체크업</Text>
      </View>
      {results.map((item, index) => (
        <View key={index} style={styles.tableCol}>
          <Text style={styles.tableCell}>{item[0].score}</Text>
        </View>
      ))}
    </View>
    <View style={styles.tableRow}>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>2차 체크업</Text>
      </View>
      {results.map((item, index) => (
        <View key={index} style={styles.tableCol}>
          <Text style={styles.tableCell}>{item[1].score}</Text>
        </View>
      ))}
    </View>
    <View style={styles.tableRow}>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>3차 체크업</Text>
      </View>
      {results.map((item, index) => (
        <View key={index} style={styles.tableCol}>
          <Text style={styles.tableCell}>{item[2].score}</Text>
        </View>
      ))}
    </View> */}
    <View style={styles.tableRow}>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>워크숍 후 변화</Text>
      </View>
      {results.map((item, index) => (
        <View key={index} style={styles.tableCol}>
          <Text
            style={{
              ...styles.tableCell,
              fontWeight: 600,
              color: item[1].score - item[0].score >= 0 ? "#00DBB1" : "#7C8585",
            }}
          >
            {item[1].score - item[0].score >= 0
              ? `+${(item[1].score - item[0].score).toFixed(1)}`
              : `${(item[1].score - item[0].score).toFixed(1)}`}
          </Text>
        </View>
      ))}
    </View>
    <View style={styles.tableRow}>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>4주 루틴 후 변화</Text>
      </View>
      {results.map((item, index) => (
        <View key={index} style={styles.tableCol}>
          <Text
            style={{
              ...styles.tableCell,
              fontWeight: 600,
              color: item[2].score - item[0].score >= 0 ? "#00DBB1" : "#7C8585",
            }}
          >
            {item[2].score - item[0].score >= 0
              ? `+${(item[2].score - item[0].score).toFixed(1)}`
              : `${(item[2].score - item[0].score).toFixed(1)}`}
          </Text>
        </View>
      ))}
    </View>
  </View>
);

export default TablePdf;
