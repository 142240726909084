import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import { TABLET_LANDSCAPE } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getDate } from "../../../utils/date";
import MyPage from "../../mypage/MyPage";
import CardLayout from "../CardLayout";
import Heading1 from "../Heading1";
import Heading2 from "../Heading2";

const Container = styled.div`
  margin-left: var(--sidebar-width);
  // height: calc(100% - 4.06rem);
  padding: 2.25rem 2.5rem;
  width: 100%;
  // border: 1px solid black;
  display: flex;
  flex-direction: column;

  @media (max-width: ${TABLET_LANDSCAPE}) {
    margin-left: var(--sidebar-tablet-landscape-width);
  }
`;

const Section = styled.section`
  margin-top: 2rem;
  //   display: grid;
  //   grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.5rem;
  width: 100%;
`;

const SectionHeading1 = styled.p`
  font-weight: 600;
  line-height: 140%;
  font-size: 1.25rem;
`;

const Avatar = styled.div`
  width: 6.25rem;
  height: 6.25rem;
  background: ${({ theme }) => theme.primary.light};
  border-radius: 50%;
  margin-top: 1.87rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NameText = styled.span`
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 0.25rem;
`;

const NicknameText = styled.span`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 140%;
  color: ${({ theme }) => theme.primary.normal};
  margin-bottom: 1rem;
`;

const CompanyText = styled.span`
  line-height: 160%;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Badge = styled.span`
  border-radius: 3.125rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  font-size: 0.9375rem;
  line-height: 160%;
  text-align: center;
  padding: 0.5rem 1rem;
`;

const Box = styled.div`
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  border-radius: 0.75rem;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 0.9375rem;
  font-weight: 500;
  //   line-height: 140%;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    padding: 2rem 1.5rem;
  }
`;

const BoxCashText = styled.span`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 140%;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const TableTitleText = styled.span`
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 2.12rem;
`;

const ListContainer = styled.ul`
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const ColumnHeader = styled.li`
  display: flex;
  justify-content: space-evenly;
  font-weight: 600;
  padding: 1rem 0;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  border-top: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding-left: 2.31rem;
`;

const ColumnItem = styled.div``;

const Row = styled.li`
  display: flex;
  justify-content: space-evenly;
  font-weight: 400;
  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding-left: 2.31rem;
`;
const RowItem = styled.div`
  text-align: center;
`;

const EditButton = styled.button`
  border-radius: 0.3125rem;
  background: ${({ theme }) => theme.primary.normal};
  padding: 0.38rem 0.69rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4375rem;
  border: none;
  cursor: pointer;
`;

const WithdrawButton = styled.button`
  padding: 0.5rem 0.625rem;
  border-radius: 0.4375rem;
  border: 1px solid ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.primary.normal};
  background: ${({ theme }) => theme.grayScaleColor.white};
  margin-left: 0.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const BackSvg = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM19 9C19.5523 9 20 8.55228 20 8C20 7.44772 19.5523 7 19 7V9ZM1 9H19V7H1V9Z"
        fill="#222222"
      />
    </svg>
  );
};

const PersonalMypage = () => {
  const CHART_TYPES = ["마음 웰빙 지수"];
  const { auth } = useAuth();

  const name = auth.user.name;
  const nickname = auth.user.nickname;
  const company = auth.user.companyId.name;
  const department = auth.user.departmentId.name;
  const interests = auth.user.interests;

  const isTablet = useMediaQuery({ query: `(max-width: ${TABLET_LANDSCAPE})` });
  const axiosPrivate = useAxiosPrivate();

  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [update, setUpdate] = useState(false);
  const [cash, setCash] = useState(10);
  const [usedCash, setUsedCash] = useState(0);
  const [cashHistory, setCashHistory] = useState([]);

  const navigate = useNavigate();

  const handleEditButton = () => {
    if (isEditMode) {
      setUpdate(true);
    } else {
      setIsEditMode(true);
    }
  };
  const handleCancelEditButton = () => {
    setUpdate(false);
    setIsEditMode(false);
  };

  const handleWithdrawRequestClick = () => {
    navigate("reward");
  };

  const handleUpdatePasswordClick = () => {
    navigate("/password");
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await axiosPrivate.get("/cash");

      setCash(response.data.data.currentCash);
      setUsedCash(response.data.data.usedCash);
      setCashHistory(response.data.data.cashHistory);
      setIsLoading(false);
    };

    fetch();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <Heading1>마이 페이지</Heading1>
      <Heading2>맘핏 멤버십 정보를 확인할 수 있어요</Heading2>
      <FlexRow style={{ alignSelf: "flex-end", gap: "0.69rem" }}>
        <EditButton onClick={handleUpdatePasswordClick}>
          비밀번호 변경하기
        </EditButton>
        <EditButton onClick={handleEditButton}>
          {isEditMode ? "멤버십 정보 수정 완료" : "멤버십 정보 수정하기"}
        </EditButton>
      </FlexRow>
      <Section>
        <CardLayout style={{ width: "100%", padding: 0 }}>
          <FlexRow>
            <FlexColumn
              style={{
                flex: 1,
                alignItems: "center",
                padding: "2.25rem",
                paddingRight: "1.37rem",
                borderRight: "1px solid #D9D9D9",
              }}
            >
              <SectionHeading1 style={{ alignSelf: "flex-start" }}>
                멤버십 카드
              </SectionHeading1>
              <Avatar>
                <img
                  style={{ opacity: 0.2 }}
                  src={`${process.env.PUBLIC_URL}/assets/symbol/symbol.png`}
                />
              </Avatar>
              <NameText>{name}</NameText>
              <NicknameText>{nickname}</NicknameText>
              <CompanyText>{company}</CompanyText>
              <CompanyText>{department}</CompanyText>
              {/* <FlexRow
                style={{ marginTop: "3rem", flexWrap: "wrap", gap: "0.75rem" }}
              >
                {interests.length > 0 ? (
                  interests.map((item, index) => (
                    <Badge key={index}>{item}</Badge>
                  ))
                ) : (
                  <Badge style={{ color: "#7C8585" }}>
                    설정한 관심 분야가 없습니다
                  </Badge>
                )}
              </FlexRow> */}
            </FlexColumn>
            {isEditMode ? (
              <FlexColumn style={{ flex: 4.5, padding: "2.25rem" }}>
                <BackSvg onClick={handleCancelEditButton} />
                <SectionHeading1
                  style={{ marginTop: "1.56rem", marginBottom: "1.56rem" }}
                >
                  멤버십 정보 수정
                </SectionHeading1>
                <MyPage
                  update={update}
                  setUpdate={setUpdate}
                  setIsEditMode={setIsEditMode}
                />
              </FlexColumn>
            ) : (
              <FlexColumn style={{ flex: 4.5, padding: "2.25rem" }}>
                <FlexRow style={{ alignItems: "center" }}>
                  <SectionHeading1>내 리워드 정보</SectionHeading1>
                  {/* TODO: 로버트 보쉬 코리아 용도로 추가됨 */}
                  {auth.user.companyId._id !== "655edc229c47154313fe6a93" && (
                    <WithdrawButton onClick={handleWithdrawRequestClick}>
                      환급 신청하기{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                      >
                        <path
                          d="M11.3536 3.64645C11.5488 3.84171 11.5488 4.15829 11.3536 4.35355L8.17157 7.53553C7.97631 7.7308 7.65973 7.7308 7.46447 7.53553C7.2692 7.34027 7.2692 7.02369 7.46447 6.82843L10.2929 4L7.46447 1.17157C7.2692 0.976311 7.2692 0.659728 7.46447 0.464466C7.65973 0.269204 7.97631 0.269204 8.17157 0.464466L11.3536 3.64645ZM1 4.5C0.723858 4.5 0.5 4.27614 0.5 4C0.5 3.72386 0.723858 3.5 1 3.5V4.5ZM11 4.5H1V3.5H11V4.5Z"
                          fill="#00DBB1"
                        />
                      </svg>
                    </WithdrawButton>
                  )}
                </FlexRow>
                <FlexRow
                  style={{
                    gap: isTablet ? "0.84rem" : "1.2rem",
                    marginTop: "1.87rem",
                    marginBottom: "2.5rem",
                  }}
                >
                  <Box style={{ background: "#E8FBF7" }}>
                    <span>현재 보유 캐시</span>
                    <FlexRow style={{ alignItems: "center" }}>
                      <img
                        style={{
                          width: "1.25rem",
                          height: "1.25rem",
                          marginRight: "0.38rem",
                        }}
                        src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                      />
                      <BoxCashText style={{ color: "#111" }}>
                        {cash.toLocaleString()}원
                      </BoxCashText>
                    </FlexRow>
                  </Box>
                  <Box>
                    <span>사용한 캐시</span>
                    <FlexRow style={{ alignItems: "center" }}>
                      <img
                        style={{
                          width: "1.25rem",
                          height: "1.25rem",
                          marginRight: "0.38rem",
                        }}
                        src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                      />
                      <BoxCashText>{usedCash.toLocaleString()}원</BoxCashText>
                    </FlexRow>
                  </Box>
                  <Box>
                    <span>총 누적 캐시</span>
                    <FlexRow style={{ alignItems: "center" }}>
                      <img
                        style={{
                          width: "1.25rem",
                          height: "1.25rem",
                          marginRight: "0.38rem",
                        }}
                        src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                      />
                      <BoxCashText>
                        {(cash - usedCash).toLocaleString()}원
                      </BoxCashText>
                    </FlexRow>
                  </Box>
                </FlexRow>
                <TableTitleText>캐시 적립/사용 내역</TableTitleText>
                <ListContainer>
                  <ColumnHeader>
                    <ColumnItem style={{ textAlign: "center", flex: 1 }}>
                      날짜
                    </ColumnItem>
                    <ColumnItem style={{ textAlign: "center", flex: 1.5 }}>
                      내역
                    </ColumnItem>
                    <ColumnItem style={{ textAlign: "center", flex: 1 }}>
                      적립 캐시
                    </ColumnItem>
                    <ColumnItem style={{ textAlign: "center", flex: 1 }}>
                      사용 캐시
                    </ColumnItem>
                    <ColumnItem style={{ textAlign: "center", flex: 1 }}>
                      보유 캐시
                    </ColumnItem>
                  </ColumnHeader>
                  {cashHistory.map((item, index) => (
                    <Row key={index}>
                      <RowItem style={{ flex: 1 }}>
                        {getDate(new Date(item.createdAt), "YYYY-MM-DD")}
                      </RowItem>
                      <RowItem style={{ flex: 1.5 }}>
                        {item.pointTypeInKr}
                      </RowItem>
                      <RowItem style={{ flex: 1, color: "#00DBB1" }}>
                        {item.point >= 0 && `+${item.point.toLocaleString()}원`}
                      </RowItem>
                      <RowItem style={{ flex: 1, color: "#7C8585" }}>
                        {item.point < 0 && `${item.point.toLocaleString()}원`}
                      </RowItem>
                      <RowItem style={{ flex: 1 }}>
                        {item.currentCash.toLocaleString()}원
                      </RowItem>
                    </Row>
                  ))}
                </ListContainer>
              </FlexColumn>
            )}
          </FlexRow>
        </CardLayout>
      </Section>
    </Container>
  );
};

export default PersonalMypage;
