import styled from "styled-components";

const Image = styled.img`
  width: 11.69rem;
  height: 2.06rem;
`;

const Logo = ({ style }) => {
  return (
    <Image
      style={style}
      src={`${process.env.PUBLIC_URL}/assets/logo/login_logo.png`}
    />
  );
};

export default Logo;
