import styled from "styled-components";

const List = styled.div`
  border-top: 1px solid #000;
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  display: flex;
`;
const Item = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 600;
  line-height: 160%;
  flex: 1;
  padding: 0.69rem;
  text-align: center;
`;
const WellBeingCompareList = ({ headers, rows }) => {
  return (
    <List>
      <Row>
        {headers.map((header, index) => (
          <Item
            key={index}
            style={{
              background: index === headers.length - 1 ? "#E1EDEA" : "#EDF3F3",
            }}
          >
            {header}
          </Item>
        ))}
      </Row>
      {rows.map((row, index) => (
        <Row key={index}>
          {row.map((item, rowIndex) => (
            <Item
              key={rowIndex}
              style={{
                fontWeight:
                  rowIndex === 0
                    ? 600
                    : rowIndex === row.length - 1
                    ? 500
                    : 400,
                color:
                  rowIndex === row.length - 1 && item >= 0
                    ? "#00DBB1"
                    : rowIndex === row.length - 1 && item < 0 && "#7C8585",
                background:
                  rowIndex === 0
                    ? "#EDF3F3"
                    : rowIndex === row.length - 1
                    ? "#F6FAF9"
                    : "#FFF",
              }}
            >
              {rowIndex === row.length - 1 && item >= 0 ? `+${item}` : item}
            </Item>
          ))}
        </Row>
      ))}
    </List>
  );
};

export default WellBeingCompareList;
