import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { USER_MANAGER } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import isEndWithConsonant from "../../../utils/isEndWithConsonant";

const Container = styled.div`
  background: "#DFE7E6";
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2.75rem;
`;

const Wrapper = styled.div`
  width: 100%;

  max-width: 600px;
  background: ${({ theme }) => theme.grayScaleColor.white};
  display: flex;
  flex-direction: column;
  padding: 1.88rem;
`;

const ReportDate = styled.p`
  margin-bottom: 1.25rem;
  font-size: 0.88rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  font-weight: 600;
`;

const SummaryText = styled.p`
  margin-bottom: 2.25rem;
  font-size: 1rem;
  line-height: 1.63rem;
`;

const Callout = styled.div`
  padding: 0.75rem 1.75rem;
  background: ${({ theme }) => theme.primary.light};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

const CalloutTitle = styled.span`
  color: #2b6c60;
`;

const CalloutResult = styled.span`
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const FeelingResultContainer = styled.div`
  margin-bottom: 2.75rem;
  font-weight: 600;
`;

const FeelingResult = styled.p`
  line-height: 160%;
`;

const WellBeingResultContainer = styled.div``;

const WellBeingResultItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 1rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const Bold = styled.span`
  font-weight: 600;
`;

const WeekReport = () => {
  const { auth } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const axiosPrivate = useAxiosPrivate();
  const [period, setPeriod] = useState();
  const [totalSent, setTotalSent] = useState();
  const [total, setTotal] = useState();
  const [emotionTop, setEmotionTop] = useState();
  const [emotionSentence, setEmotionSentence] = useState();
  const [high, setHigh] = useState();
  const [low, setLow] = useState();
  const [emotions, setEmotions] = useState();
  const [wellBeing, setWellBeing] = useState();
  const [wellBeingResult, setWellBeingResult] = useState();
  const [prevWellBeingResult, setPrevWellBeingResult] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const targetName =
    auth.user.role >= USER_MANAGER
      ? auth.user.companyId.name
      : auth.user.departmentId.name;

  useEffect(() => {
    const fetch = async () => {
      // const res = searchParams.get("id")
      //   ? await axiosPrivate.get(
      //       `/checkup-v2/weekly-report/test?demoId=${searchParams.get("id")}`
      //     )
      //   : await axiosPrivate.get(
      //       `/checkup-v2/weekly-report?surveyId=${searchParams.get("surveyId")}`
      //     );
      const res = await axiosPrivate.get(
        `/checkup-v2/weekly-report?surveyId=${searchParams.get("surveyId")}`
      );
      setPeriod(res.data.period);
      setTotalSent(res.data.totalSent);
      setTotal(res.data.total);
      setEmotionTop(res.data.emotionTop);
      setEmotionSentence(res.data.emotionSentence);
      setHigh(res.data.high);
      setLow(res.data.low);
      setEmotions(res.data.emotions);
      setWellBeing(res.data.wellBeing);
      setWellBeingResult(res.data.wellBeingResult);
      setPrevWellBeingResult(res.data.prevWellBeingResult);

      setIsLoading(false);
    };
    fetch();
  }, []);

  if (isLoading) {
    return null;
  }
  if (total === 0) {
    return (
      <Container>
        <Wrapper>
          <SummaryText>리포트가 없습니다.</SummaryText>
        </Wrapper>
      </Container>
    );
  }
  return (
    <Container>
      <Wrapper>
        <ReportDate>📆 {period}</ReportDate>
        <SummaryText>
          {auth.user.name} 리더님, 안녕하세요 :)
          <br /> 지난 한 주 마음 리포트를 살펴보면, 구독자 총 {totalSent}명 중{" "}
          {total}명이 응답하였습니다. 응답자들은{" "}
          <Bold>
            {emotionTop}({emotionSentence})
          </Bold>
          {isEndWithConsonant(emotionTop) ? "을" : "를"} 가장 많이 경험하였으며,
          마음 웰빙 영역 중 가장 높은 것은 <Bold>{high}</Bold>, 가장 낮은 것은{" "}
          <Bold>{low}</Bold>입니다.
        </SummaryText>
        <Callout>
          <CalloutTitle>마음 분포</CalloutTitle>
          <CalloutResult>{emotions.split(",")[0]}</CalloutResult>
        </Callout>
        <FeelingResultContainer>
          <FeelingResult>{emotions}</FeelingResult>
        </FeelingResultContainer>
        <Callout>
          <CalloutTitle>마음 웰빙</CalloutTitle>
          <CalloutResult>{wellBeing}</CalloutResult>
        </Callout>
        <WellBeingResultContainer>
          {wellBeingResult.map((item, index) => (
            <WellBeingResultItem key={index}>
              <p>
                <span style={{ fontWeight: "600", color: "#111" }}>
                  [{item.type}]{" "}
                </span>
                {item.question}
              </p>
              <p>
                <span style={{ fontWeight: "600", color: "#111" }}>
                  {item.value}
                </span>{" "}
                {prevWellBeingResult.length > 0 && (
                  <span
                    style={{
                      fontWeight: "600",
                      color:
                        Number(item.value) >=
                        Number(prevWellBeingResult[index].value)
                          ? "#00DBB1"
                          : "#7C8585",
                    }}
                  >
                    {`(${
                      Number(item.value) -
                        Number(prevWellBeingResult[index].value) >=
                      0
                        ? "+"
                        : "-"
                    }${Math.abs(
                      Number(item.value) -
                        Number(prevWellBeingResult[index].value)
                    ).toFixed(1)})`}
                  </span>
                )}
              </p>
            </WellBeingResultItem>
          ))}
        </WellBeingResultContainer>
      </Wrapper>
    </Container>
  );
};

export default WeekReport;
