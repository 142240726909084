import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Youtube from "react-youtube";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { converDate } from "../../utils/convertDate";
import generateLevelStar from "../../utils/generateLevelStar";
import Slider from "./Slider";
import Toggle from "./Toggle";
import Tooltip from "./Tooltip";
import lineBreaker from "../../utils/lineBreaker";
import { useMediaQuery } from "react-responsive";
import FlexRow from "../../components/FlexRow";
import Confetti from "./components/Confetti";
import ModalBackground from "../../components/ModalBackground";
import Modal from "./components/Modal";
import StartModal from "./components/StartModal";
import { motion } from "framer-motion";
import shuffleArray from "../../utils/shuffleArray";
import HelpModal from "./components/HelpModal";

const Container = styled.div``;

const SideMenu = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 8.5rem;
  left: calc(50% - 37rem);
  font-family: Montserrat;
  z-index: 1;
`;

const SideMenuItem = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 1.25rem;
  cursor: pointer;
  color: ${({ theme }) => theme.grayScaleColor.white};
  opacity: 0.5;
  display: flex;
  align-items: center;
`;

const SideMenuIndicator = styled.div`
  width: 0.81rem;
  height: 0.81rem;
  border: 4px solid currentColor;
  border-radius: 50%;
`;
const SideMenuText = styled.span`
  margin-left: 0.94rem;
`;

const Section = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: ${({ theme, bgColor }) =>
    bgColor === "primary" ? theme.primary.normal : "#EDF3F3"};
  &:nth-child(3) {
    height: auto;
    padding-bottom: 100px;
    @media (max-width: 425px) {
      height: 100%;
      padding-bottom: 0;
    }
  }
  &:nth-child(3) > div {
    @media (max-width: 425px) {
      height: auto;
    }
  }
  &:nth-child(3) {
    ${({ forLeader }) => forLeader && "height: auto; padding-bottom: 50px;"}
  }
  &:nth-child(4) {
    height: 100%;
  }
`;
const Wrapper = styled.div`
  width: 55.63rem;
  height: auto;
  display: flex;
  flex-direction: column;
  background: inherit;
  position: relative;
  min-width: 375px;
  @media (max-width: 425px) {
    padding: 3.13rem 30px;
  }
`;

const BackgroundLogo = styled.img`
  position: absolute;
  top: -3.31rem;
  right: -8.56rem;
  width: 31.06rem;
  height: 30.5rem;
  @media (max-width: 425px) {
    width: 11.94rem;
    height: 11.75rem;
    top: 17.81rem;
    right: 1.5rem;
  }
`;

const Logo = styled.img`
  margin-top: 2rem;
  width: 2.5rem;
  height: 2.4rem;
  position: relative;
  /* bottom: 10%; */

  /* left: 90%; */
  /* right: 10; */
  cursor: pointer;
  @media (max-width: 425px) {
    width: 2.3rem;
    height: 2.2rem;
    /* margin-top: 1.44rem; */
    /* bottom: 5%;
    right: 5%; */
  }
`;
const Title = styled.h1`
  font-size: 4.38rem;
  margin-top: 8.5rem;
  margin-bottom: 1.75rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-weight: 800;
  letter-spacing: -1px;
  line-height: normal;
  @media (max-width: 425px) {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 1.69rem;
  }
`;

const SubTitle = styled.p`
  font-size: 1.75rem;
  line-height: 2.56rem;
  font-weight: 400;
  color: ${({ theme }) => theme.grayScaleColor.white};
  margin-bottom: 3.38rem;
  white-space: pre-wrap;
  letter-spacing: -1px;
  @media (max-width: 425px) {
    font-size: 1.13rem;
    margin-bottom: 4.25rem;
    letter-spacing: -0.5px;
    line-height: 1.56rem;
    word-break: keep-all;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Button = styled.div`
  border: 1px solid white;
  background: ${({ theme }) => theme.primary.normal};
  display: flex;
  justify-content: center;
  border-radius: 3.13rem;
  color: white;
  font-size: 0.94rem;
  line-height: 1.44rem;
  letter-spacing: -0.04em;
  padding: 0.63rem 1.24rem;
  margin-bottom: 1.19rem;
  @media (max-width: 425px) {
  }
`;

const LikeButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 6.88rem;
  cursor: pointer;
  position: absolute;
  bottom: 20%;
  right: 5%;
`;

const LikeButton = styled.div`
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  display: flex;
  align-items: center;
  padding: 0.31rem 1.13rem;
  border-radius: 3.13rem;
  position: relative;
  z-index: 1;
`;

const LikeButtonText = styled.span`
  font-size: 0.81rem;
  font-weight: 300;
  line-height: 1.44rem;
  margin-right: 0.38rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const LikeCount = styled.span`
  font-size: 0.81rem;
  font-weight: 500;
  color: ${({ theme }) => theme.primary.normal};
  margin-left: 0.38rem;
`;

const ScrollDownButtonContainer = styled.div`
  color: ${({ theme }) => theme.grayScaleColor.white};
  display: flex;
  justify-content: center;
  /* margin-top: 1.94rem; */
  position: absolute;
  bottom: 5%;
  margin: 0 auto;
  left: 0;
  right: 0;
  ${({ isAuthEmpty }) =>
    isAuthEmpty &&
    `position: absolute; bottom: 90px; margin:0 auto; left: 0; right:0;`}

  @media (max-width: 425px) {
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
  }
`;

const ScrollDownButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.81rem;
  line-height: 1.44rem;
  font-weight: 600;
  cursor: pointer;
`;

const ScrollDownButtonText = styled.span`
  margin-bottom: 0.5rem;
`;

const bounceAnimation = keyframes`
0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}
40% {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
}
60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
}
`;

const SymbolImage = styled.img`
  width: 20%;
  max-width: 252px;
  //   border: 1px solid black;
  animation: ${bounceAnimation} 1.5s infinite;
`;

const GuideDescription = styled.p`
  font-size: 1.2rem;
  line-height: 180%;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 6rem;
  white-space: pre-wrap;
  letter-spacing: -1px;
  @media (max-width: 425px) {
    margin: 0;
    font-size: 1rem;
    line-height: 1.75rem;
    word-break: keep-all;
  }
`;
const GuideBold = styled.span`
  font-weight: 500;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 55.63rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 3.81rem 3.63rem;
  overflow-y: auto;
  // border: 1px solid black;
  @media (max-width: 425px) {
    padding: 3.81rem 1.88rem;
  }
`;

const ImageContent = styled.div`
  width: 55.5rem;
  height: 31.19rem;
  border-radius: 0.94rem;
  border: 1px solid black;
  margin-bottom: 4.45rem;
`;

const ContentQuestion = styled.h3`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 3.19rem;
  letter-spacing: -1px;
  @media (max-width: 425px) {
    font-size: 1.25rem;
    line-height: 1.88rem;
    margin-bottom: 1.5rem;
  }
`;

const ContentQuestionHighlight = styled.span`
  color: ${({ theme }) => theme.primary.normal};
  margin-right: 0.63rem;
`;
const InputForm = styled.form`
  width: 560px;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;

const TextAreaContainer = styled.div`
  position: relative;
  margin-bottom: 0.5rem;
  width: 100%;
  ${({ isAuthEmpty }) => isAuthEmpty && "margin-top: 2.5em"};
  @media (max-width: 425px) {
    margin-top: 1rem;
  }
`;
const Textarea = styled.textarea`
  width: 100%;
  height: 7.25rem;
  background: ${({ theme }) => theme.primary.superLight};
  border-radius: 0.94rem;
  border: none;
  padding: 1.06rem 1.88rem;
  font-size: 1rem;
  line-height: 1.5rem;
  resize: none;
  // outline-color: ${({ theme }) => theme.primary.normal};
  &::placeholder {
    color: #888888;
  }
  &:focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.primary.normal};
  }
`;

const TextareaTriangle = styled.div`
  // width: 0;
  // height: 0;
  // border-left: 1.31rem solid transparent;
  // border-right: 1.31rem solid transparent;
  // border-bottom: 1.31rem solid ${({ theme }) => theme.primary.superLight};
  // border-top-left-radius: 0.31rem;
  // border-top-right-radius: 0.31rem;
  // margin-left: 2.06rem;
  // top: -20px;
  // position: absolute;
  // z-index: 0;
  display: block;
  height: 2.63rem;
  width: 2.63rem;
  background-color: ${({ theme }) => theme.primary.superLight};
  // border: inherit;
  border: 2px solid
    ${({ theme, clicked }) =>
      clicked ? theme.primary.normal : theme.primary.superLight};
  position: absolute;
  top: -1.2rem;
  left: 2.06rem;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(135deg);
  border-radius: 0 0 0 0.31rem;
`;

const TextLengthCheck = styled.p`
  font-size: 0.75rem;
  line-height: 1.19rem;
  font-weight: 300;
  text-align: right;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  font-family: Montserrat;
  letter-spacing: -1px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0.19rem;
  margin-bottom: 2.31rem;
  // border: 1px solid black;
  position: relative;
`;
const Input = styled.input`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 1rem;
  width: 100%;
`;
const SubmitButton = styled.button`
  border: none;
  background: ${({ theme }) => theme.primary.normal};
  margin-top: 0.5rem;
  padding: 0.38rem 2.19rem;
  font-size: 0.94rem;
  font-weight: 500;
  line-height: 1.44rem;
  border-radius: 0.31rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  cursor: pointer;
  &:disabled {
    background: ${({ theme }) => theme.grayScaleColor.gray10};
    color: ${({ theme }) => theme.grayScaleColor.gray30};
    cursor: not-allowed;
  }
  ${({ isAuthEmpty }) => isAuthEmpty && "margin-left: auto; margin-right: 0;"}
  @media (max-width: 425px) {
    padding: 0.75rem 2.19rem;
    ${({ isAuthEmpty }) => isAuthEmpty && "width: 100%;"}
  }
`;

const ReplyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  // border: 1px solid black;

  overflow-y: auto;
`;

const ReplyHeader = styled.h3`
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 1.31rem;
  letter-spacing: -1px;
`;

const ReplyItem = styled.div`
  width: 100%;
  padding: 1rem 1.38rem;
  background: ${({ theme, isPublic }) =>
    isPublic ? theme.primary.superLight : theme.grayScaleColor.gray5};
  border-radius: 0.63rem;
  margin-bottom: 0.63rem;
`;

const ReplyItemTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.19rem;
`;

const ReplyItemInfo = styled.div`
  display: flex;
  align-items: center;
`;
const ReplyName = styled.span`
  font-size: 0.94rem;
  line-height: 1.44rem;
  color: #888;
  // ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 400;
`;

const ReplyDate = styled.span`
  font-size: 0.69rem;
  line-height: 1.44rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  font-family: Montserrat;
  margin-left: 0.5rem;
`;

const ReplyItemPublicButton = styled.button`
  border-radius: 6.25rem;
  width: 3.94rem;
  height: 1.44rem;
  border: 1px solid
    ${({ theme, isPublic }) =>
      isPublic ? theme.primary.normal : theme.grayScaleColor.gray30};
  color: ${({ theme, isPublic }) =>
    isPublic ? theme.primary.normal : theme.grayScaleColor.gray30};
  font-size: 0.63rem;
  line-height: 1.19rem;
  // padding: 0.13rem 0.63rem;
  background: none;
  letter-spacing: -0.04em;
  cursor: pointer;
`;

const ReplyComment = styled.p`
  font-size: 1rem;
  line-height: 1.44rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  font-weight: 500;
  // letter-spacing: -1px;
  margin-bottom: 0.38rem;
  word-break: keep-all;
  word-wrap: break-word;
`;

const CommentLikeButton = styled.button`
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 3.13rem;
  background: ${({ theme, isPublic }) =>
    isPublic ? theme.grayScaleColor.white : theme.grayScaleColor.gray10};
  border: none;
  color: ${({ theme, likeComment }) =>
    likeComment ? theme.primary.normal : theme.grayScaleColor.gray30};
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

const CommentLikeCount = styled.span`
  margin-left: 0.38rem;
  font-size: 0.69rem;
  line-height: 0.84rem;
  font-weight: 400;
  font-family: Montserrat;
  margin-top: 0.13rem;
`;

const CommentLikeSvg = styled.svg``;
const VideoContainer = styled.div`
  // position: relative;
  margin-bottom: 3.31rem;
  // width: 100%;
  // height: 27.19rem;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  @media (max-width: 425px) {
    width: 315px;
  }
`;

const NicknameInput = styled.input`
  margin-bottom: 1rem;
  border-radius: 0.31rem;
  // border: none;
  border: 1px solid
    ${({ theme, nicknameFocus, nickname }) =>
      !nicknameFocus && nickname.length > 0
        ? "#f4faf9"
        : theme.grayScaleColor.gray20};
  padding-left: 1.44rem;
  font-size: 0.94rem;
  // height: 2.94rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.primary.normal};
  }
  background: ${({ nickname, nicknameFocus }) =>
    nicknameFocus ? "none" : nickname.length > 0 ? "#f4faf9" : "none"};
`;

const MoreTrainingButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  background: ${({ theme }) => theme.grayScaleColor.white};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  font-size: 0.88rem;
  line-height: 1.75rem;
  padding: 0.25rem 1rem;
  cursor: pointer;
`;

const TipBox = styled.div`
  margin-top: 2.5rem;
  padding: 1.5rem 1.75rem;
  border-radius: 0.9375rem;
  background: white;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  align-self: flex-start;
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

const FlipButton = styled.button`
  padding: 1rem 1.75rem;
  border: 1px solid ${({ theme }) => theme.primary.normal};
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 0.625rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.primary.normal};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  align-self: center;
  cursor: pointer;
`;

const CardFlip = ({ isFront, flip, prevIndex, currentIndex, images }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });

  return (
    <>
      <motion.div
        style={{
          width: "100%",
          height: isMobile ? "13rem" : "27rem",
          position: "relative",
        }}
        transition={{ duration: 0.5 }}
        animate={{ rotateY: flip ? 0 : 180 }}
      >
        <motion.div
          style={{ width: "100%" }}
          transition={{ duration: 0.5 }}
          animate={{ rotateY: flip ? 0 : 180 }}
          className="Card"
        >
          <motion.div
            transition={{ duration: 0.5 }}
            animate={{ rotateY: flip ? 0 : 180 }}
            className="front"
            style={{
              width: "100%",
              position: "absolute",
              backfaceVisibility: "hidden",
            }}
          >
            <img
              style={{ width: "100%" }}
              src={
                isFront
                  ? images[currentIndex]
                  : images[
                      prevIndex === 0
                        ? 0
                        : currentIndex === 1
                        ? images.length - currentIndex
                        : currentIndex - 1
                    ]
              }
              alt="front"
            />
          </motion.div>
          <motion.div
            initial={{ rotateY: 180 }}
            animate={{ rotateY: flip ? 180 : 0 }}
            // style={{ display: flip ? "none" : "block" }}
            transition={{ duration: 0.5 }}
            className="back"
            style={{
              width: "100%",
              position: "absolute",
              backfaceVisibility: "hidden",
            }}
          >
            <img
              style={{ width: "100%" }}
              src={isFront ? images[currentIndex - 1] : images[currentIndex]}
              alt="back"
            />
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  );
};

const Prototype = () => {
  const ANIMATION_DELAY = 1000;
  const [isLoading, setIsLoading] = useState(true);
  const [block, setBlock] = useState();
  const [comment, setComment] = useState("");
  const [commentFocus, setCommentFocus] = useState(false);
  const [nickname, setNickname] = useState("");
  const [nicknameFocus, setNicknameFocus] = useState(false);
  //   const [updatedComment, setUpdatedComment] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [isLike, setIsLike] = useState(false);
  const [isLikeClicked, setIsLikeClicked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [comments, setComments] = useState([]);
  const [commentLikes, setCommentLikes] = useState();
  const [currentY, setCurrentY] = useState(0);
  const [visible, setVisible] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showStartModal, setShowStartModal] = useState(true);
  const [showConfettiModal, setShowConfettiModal] = useState(false);
  const [confettiType, setConfettiType] = useState(0);
  const [flip, setFlip] = useState(true);
  const [prevIndex, setPrevIndex] = useState(0);
  const [isFront, setIsFront] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cash, setCash] = useState(100);

  const axiosPrivate = useAxiosPrivate();
  const { auth, setAuth } = useAuth();
  const { id } = useParams();
  const [indicators, setIndicators] = useState([1, 0, 0]);
  const introMenuItemRef = useRef();
  const guideMenuItemRef = useRef();
  const actionMenuItemRef = useRef();
  const introRef = useRef();
  const guideRef = useRef();
  const actionRef = useRef();
  const imagesRef = useRef([]);
  const iframeRef = useRef();
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });
  const missionBlocks = [50, 51];
  const navigate = useNavigate();
  const handleMenuClick = (index) => {
    switch (index) {
      case 0: {
        introRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
        // document.documentElement.offsetTop = introRef.current.offsetTop;
        return;
      }
      case 1: {
        guideRef.current.scrollIntoView({
          behavior: "smooth",
          block: isMobile ? "start" : "start",
          inline: "nearest",
        });
        // document.documentElement.offsetTop = guideRef.current.offsetTop;
        return;
      }
      case 2: {
        actionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });

        return;
      }
      default: {
        introRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
        // document.documentElement.offsetTop = introRef.current.offsetTop;
        return;
      }
    }
  };

  const handleScrollDownClick = () => {
    handleMenuClick(!missionBlocks.includes(block.number) ? 1 : 2);
    // guideRef.current.scrollIntoView({
    //   behavior: "smooth",
    //   block: "end",
    //   inline: "end",
    // });
  };

  const handleNicknameChange = (e) => {
    setNicknameFocus(true);
    setNickname(e.target.value);
  };
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let response;
    if (!isAuthEmpty(auth)) {
      response = await axiosPrivate.post("/block/comment", {
        userId: auth.user.id,
        cid: auth.user.companyId._id,
        did: auth.user.departmentId._id,
        blockId: block._id,
        comment: comment.replaceAll("<br>", "\r\n"),
        isMobile,
        isPublic,
      });
    } else {
      response = await axiosPrivate.post("/training/comment", {
        blockId: block._id,
        comment: comment.replaceAll("<br>", "\r\n"),
        nickname,
      });
    }

    setComments(response.data.comments);
    setComment("");
    setVisible(true);
    if (response.data.isFirstBlockInToday && response.data.isWeekday) {
      setConfettiType(1);
      // response.data.cash: 블록 참여시 받은 금액
      setCash(response.data.cash);
      setAuth({
        accessToken: auth.accessToken,
        user: {
          ...auth.user,
          point: auth.user.point + response.data.cash,
          blockInWeek: response.data.blockInWeek,
          todayEarnedPointFromBlock: response.data.todayEarnedPointFromBlock,
          experiencePoint: response.data.experiencePoint,
        },
      });
    } else {
      setConfettiType(2);
      setAuth({
        accessToken: auth.accessToken,
        user: {
          ...auth.user,
          experiencePoint: response.data.experiencePoint,
        },
      });
    }
    if (!response.data.didClick) {
      setShowHelpModal(true);
    } else {
      setShowConfettiModal(true);
    }

    setTimeout(() => {
      setVisible(false);
    }, 2100);
  };

  const handleLikeChange = async (e) => {
    // console.log("clicked!");
    if (!isLike) {
      setLikes(likes + 1);
      setIsLikeClicked(true);
    } else {
      setLikes(Math.max(likes - 1, 0));
      setIsLikeClicked(false);
    }
    setIsLike(!isLike);

    await axiosPrivate.post("/block/like", {
      userId: auth.user.id,
      blockId: block._id,
      isLike: !isLike,
    });
  };

  const handleMoreTrainingClick = () => {
    window.open(
      "https://zep.us/play/8ZwAV4?customData={%22blockLocation%22:%22tzone%22}",
      "_blank"
    );
  };

  const editCommentClick = async (commentId) => {
    const response = await axiosPrivate.patch(`/block/comment`, {
      commentId,
      comment: "수정해야 합니다",
    });
    window.location.reload();
  };

  const deleteCommentClick = async (commentId) => {
    const response = await axiosPrivate.delete(`/block/comment`, {
      data: {
        commentId,
        blockId: id,
      },
    });
    window.location.reload();
  };

  const handlePublicClick = async (isPublic, commentId) => {
    const response = await axiosPrivate.patch(`/block/comment`, {
      commentId,
      isPublic,
    });

    setComments(response.data);
    // console.log(response.data);
    // window.location.reload();
  };

  const handleCommentLikeClick = async (commentId, likeIds) => {
    const response = await axiosPrivate.patch(`/block/comment/like`, {
      commentId,
      userId: auth.user.id,
      isLike: likeIds.indexOf(auth.user.id) === -1 ? true : false,
    });

    const copyComments = comments.map((item) => {
      if (item._id === response.data.data._id) {
        return response.data.data;
      } else {
        return item;
      }
    });

    setComments(copyComments);
  };

  const onPlayClick = (e) => {
    ReactGA.event({
      category: "Youtube_play_click",
      action: `block_${block.number}_play_clicked`,
    });
  };

  const isAuthEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const onHelpClick = async (e, type) => {
    // API 호출
    const res = await axiosPrivate.post(`/block/help`, {
      isHelping: type,
      blockId: block._id,
    });

    if (!res.data.ok) {
      return setShowHelpModal(false);
    }

    // 다음 팝업 노출
    setShowHelpModal(false);
    setShowConfettiModal(true);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    const fetchData = async () => {
      const blockResponse = await axiosPrivate.get(`/block?number=${id}`);
      const response = await axiosPrivate.get(
        `/block/comment?id=${blockResponse.data._id}&userId=${auth.user.id}`
      );
      const likeResponse = await axiosPrivate.get(
        `/block/like?id=${blockResponse.data._id}&userId=${auth.user.id}`
      );

      if (blockResponse.data.isArchieved) {
        if (
          (missionBlocks.includes(blockResponse.data.number) &&
            auth.user.companyId._id === "66273eafc407fd8d8d0eddfc") ||
          (missionBlocks.includes(blockResponse.data.number) &&
            auth.user.companyId._id === "662ee1aafa4c1c126321da42")
        ) {
        } else {
          navigate("/blocks/recommend");
          return;
        }
      }
      setBlock(blockResponse.data);
      imagesRef.current = [
        blockResponse.data.imageUrls[0],
        ...shuffleArray(blockResponse.data.imageUrls.slice(1)),
      ];
      //   setLike(likeResponse?.data?.like || false);
      setLikes(blockResponse.data.likes);
      setIsLike(likeResponse?.data?.like || false);
      setComments(response.data);
      setIsLoading(false);
    };

    const fetchPublicData = async () => {
      const blockResponse = await axiosPrivate.get(`/block?number=${id}`);
      const commentResponse = await axiosPrivate.get(
        `/training/comment?id=${blockResponse.data._id}`
      );
      setBlock(blockResponse.data);
      setComments(commentResponse.data);
      setIsLoading(false);
    };

    if (!isAuthEmpty(auth)) {
      fetchData();
    } else {
      fetchPublicData();
    }

    const handleScroll = (event) => {
      setCurrentY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const currentLocation = window.location.href;
    if (currentLocation.includes("#")) {
      const scrollTo = currentLocation.slice(currentLocation.indexOf("#") + 1);
      if (scrollTo === "action") {
        actionRef?.current?.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "nearest",
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (Math.floor(actionRef.current?.getBoundingClientRect().top) <= 0) {
      introMenuItemRef.current.style = "opacity: 0.5; color: #D9D9D9;";
      if (!missionBlocks.includes(block.number)) {
        guideMenuItemRef.current.style = "opacity: 0.5; color:#D9D9D9;";
      }

      actionMenuItemRef.current.style = "opacity: 1; color: #00DBB1";
      setIndicators([0, 0, 1]);
    } else if (Math.floor(guideRef.current?.getBoundingClientRect().top) <= 0) {
      introMenuItemRef.current.style = "opacity: 0.5; color: #D9D9D9;";
      if (!missionBlocks.includes(block.number)) {
        guideMenuItemRef.current.style = "opacity: 1; color: #00DBB1";
      }

      actionMenuItemRef.current.style = "opacity: 0.5; color: #D9D9D9";
      setIndicators([0, 1, 0]);
    } else if (Math.floor(introRef.current?.getBoundingClientRect().top) <= 0) {
      introMenuItemRef.current.style = "opacity: 1;";
      if (!missionBlocks.includes(block.number)) {
        guideMenuItemRef.current.style = "opacity: 0.5; color: white;";
      }

      actionMenuItemRef.current.style = "opacity: 0.5; color: white";
      setIndicators([1, 0, 0]);
    } else {
    }
  }, [currentY]);

  if (isLoading) {
    return null;
  }
  return (
    <Container>
      <SideMenu>
        <SideMenuItem
          style={{ opacity: indicators[0] === 1 ? 1 : 0.5 }}
          ref={introMenuItemRef}
          onClick={() => handleMenuClick(0)}
        >
          <SideMenuIndicator style={{ opacity: indicators[0] === 1 ? 1 : 0 }} />
          <SideMenuText>INTRO</SideMenuText>
        </SideMenuItem>
        {!missionBlocks.includes(block.number) && (
          <SideMenuItem
            ref={guideMenuItemRef}
            onClick={() => handleMenuClick(1)}
          >
            <SideMenuIndicator
              style={{ opacity: indicators[1] === 1 ? 1 : 0 }}
            />
            <SideMenuText>GUIDE</SideMenuText>
          </SideMenuItem>
        )}
        <SideMenuItem
          ref={actionMenuItemRef}
          onClick={() => handleMenuClick(2)}
        >
          <SideMenuIndicator style={{ opacity: indicators[2] === 1 ? 1 : 0 }} />
          <SideMenuText>ACTION</SideMenuText>
        </SideMenuItem>
      </SideMenu>
      <Section ref={introRef} bgColor="primary">
        <Wrapper>
          <Title>{block.title}</Title>
          <SubTitle>{block.summary}</SubTitle>
          <InfoContainer>
            <Button>⏰ 소요시간 : {block.time}분</Button>
            <Button>💡 난이도 : {generateLevelStar(block.level)}</Button>
          </InfoContainer>
          {!isAuthEmpty(auth) && (
            <LikeButtonContainer>
              <LikeButton onClick={handleLikeChange}>
                <LikeButtonText>트레이닝 좋아요</LikeButtonText>
                {isLike ? (
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_639_3340)">
                      <path
                        d="M16 3.78886C16 1.69746 14.1394 0 11.8391 0C10.1086 0 8.62431 0.960828 7.99824 2.33161C7.37217 0.960828 5.8914 0 4.16091 0C1.86063 0 0 1.69746 0 3.78886C0 3.84651 0 3.90096 0.00351726 3.95861H0C0 9.72998 7.99824 13 7.99824 13C7.99824 13 16 9.72998 16 3.95861H15.9965C15.9965 3.90096 16 3.84651 16 3.78886Z"
                        fill="#00DBB1"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_639_3340">
                        <rect width="16" height="13" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.54343 2.53933L7.99824 3.53513L8.45305 2.53933C8.99214 1.35899 10.2903 0.5 11.8391 0.5C13.908 0.5 15.5 2.01639 15.5 3.78886C15.5 3.80859 15.4994 3.82888 15.4985 3.85805L15.4984 3.86052C15.4976 3.8871 15.4965 3.92184 15.4965 3.95861V4.15877C15.4067 6.71129 13.6108 8.77291 11.6942 10.2473C10.7225 10.9948 9.74789 11.5692 9.01476 11.9569C8.64881 12.1504 8.34458 12.2967 8.13305 12.394C8.08272 12.4172 8.03765 12.4375 7.99828 12.4551C7.95895 12.4376 7.91394 12.4172 7.86366 12.394C7.65223 12.2967 7.34814 12.1505 6.98235 11.9569C6.24955 11.5692 5.27535 10.9949 4.30408 10.2473C2.46403 8.83118 0.735138 6.87315 0.52201 4.45861H0.534952L0.502589 3.92816C0.500145 3.8881 0.5 3.84861 0.5 3.78886C0.5 2.01639 2.09195 0.5 4.16091 0.5C5.70918 0.5 7.00415 1.35858 7.54343 2.53933Z"
                      stroke="#00DBB1"
                    />
                  </svg>
                )}
                <LikeCount>{likes}</LikeCount>
              </LikeButton>
              <Tooltip
                text="트레이닝 좋아요 완료! 잘 기억해 놓을게요😉"
                visible={isLike && isLikeClicked}
              />
            </LikeButtonContainer>
          )}
          <ScrollDownButtonContainer isAuthEmpty={isAuthEmpty(auth)}>
            <ScrollDownButton onClick={handleScrollDownClick}>
              <ScrollDownButtonText>Scroll Down</ScrollDownButtonText>
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.29289 19.7071C7.68342 20.0976 8.31658 20.0976 8.70711 19.7071L15.0711 13.3431C15.4616 12.9526 15.4616 12.3195 15.0711 11.9289C14.6805 11.5384 14.0474 11.5384 13.6569 11.9289L8 17.5858L2.34315 11.9289C1.95262 11.5384 1.31946 11.5384 0.928932 11.9289C0.538408 12.3195 0.538408 12.9526 0.928932 13.3431L7.29289 19.7071ZM9 1C9 0.447716 8.55228 -2.41411e-08 8 0C7.44771 2.41411e-08 7 0.447716 7 1L9 1ZM9 19L9 1L7 1L7 19L9 19Z"
                  fill="white"
                />
              </svg>
            </ScrollDownButton>
          </ScrollDownButtonContainer>
          <BackgroundLogo
            src={`${process.env.PUBLIC_URL}/assets/block/block_top_background_symbol.png`}
          />
        </Wrapper>
      </Section>
      {!missionBlocks.includes(block.number) && (
        <Section ref={guideRef} forLeader={false}>
          <Wrapper>
            <GuideDescription>
              {/* {isMobile
              ? block.description
                  .replaceAll("\n\n", "/q")
                  .split("\n")
                  .join(" ")
                  .split("/q")
                  .join("\n\n")
              : block.description} */}
              {isMobile
                ? lineBreaker(block.description)
                : lineBreaker(block.description)}
              {/* [
                  {
                    0,
                  ANIMATION_DELAY * 1,
                  ANIMATION_DELAY * 2,
                  ANIMATION_DELAY * 3,
                  ANIMATION_DELAY * 4,
                  ANIMATION_DELAY * 5,
                  ANIMATION_DELAY * 6,
                  ANIMATION_DELAY * 7,
                  ANIMATION_DELAY * 8,
                  ANIMATION_DELAY * 9,
                  ANIMATION_DELAY * 10,
                  ANIMATION_DELAY * 11,
                  ANIMATION_DELAY * 12,
                  ANIMATION_DELAY * 13,
                  ANIMATION_DELAY * 14,
                  ANIMATION_DELAY * 15,
                  ANIMATION_DELAY * 16,
                  ANIMATION_DELAY * 17,
                  ANIMATION_DELAY * 18,
                  ANIMATION_DELAY * 19,
                  ANIMATION_DELAY * 20,
                  ANIMATION_DELAY * 21,
                  ANIMATION_DELAY * 22,
                  ANIMATION_DELAY * 23, 
                  },
                ] */}
            </GuideDescription>

            {block.mediaType === "flip" ? (
              <>
                <TipBox>
                  <p style={{ marginBottom: "0.69rem" }}>
                    <span style={{ color: "#00DBB1", fontWeight: 700 }}>
                      TIP!
                    </span>{" "}
                    새로운 관점을 열 수 있는 2가지 질문
                  </p>
                  <li>사건을 다르게 볼 수 있는 ‘여지’는 무엇인가요?</li>
                  <li>감정과 사실을 분리해서 볼 수 있는 부분은 무엇인가요?</li>
                </TipBox>
              </>
            ) : (
              <Logo
                src={`${process.env.PUBLIC_URL}/assets/block/block_bottom_background_symbol.png`}
                // data-aos={"fade-in"}
                // data-aos-duration={500}
                // data-aos-delay={14000}
                // data-aos-once="true"
                // data-aos-anchor-placement={"bottom"}
                onClick={() => {
                  actionRef?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "nearest",
                  });
                }}
              />
            )}
          </Wrapper>
        </Section>
      )}
      <Section
        id="action"
        ref={actionRef}
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <ContentWrapper>
          {/* <div> */}

          {missionBlocks.includes(block.number) ? (
            <></>
          ) : block.mediaType === "video" ? (
            <VideoContainer>
              <Youtube
                ref={iframeRef}
                videoId={block.videoId}
                onPlay={onPlayClick}
                opts={{
                  height: isMobile ? "189" : "435",
                  width: "100%",
                  playerVars: {
                    rel: 0,
                    modestbranding: 1,
                    controls: 1,
                  },
                }}
              />
            </VideoContainer>
          ) : block.mediaType === "flip" ? (
            <>
              <CardFlip
                images={imagesRef.current}
                prevIndex={prevIndex}
                currentIndex={currentIndex}
                flip={flip}
                isFront={isFront}
              />
              <FlipButton
                style={{
                  marginTop: "2rem",
                  marginBottom: isMobile ? "1.5rem" : "3.37rem",
                }}
                onClick={() => {
                  setIsFront((prev) => !prev);
                  setPrevIndex((prev) => {
                    if (currentIndex === 0) {
                      return 0;
                    } else {
                      return currentIndex - 1;
                    }
                  });
                  setCurrentIndex((prev) => {
                    if (prev === block.imageUrls.length - 1) {
                      return 1;
                    } else {
                      return prev + 1;
                    }
                  });
                  setFlip((prev) => !prev);
                }}
              >
                📨 사연 뽑기
              </FlipButton>
            </>
          ) : (
            <Slider
              images={block.imageUrls}
              number={block.number}
              mediaType={block.mediaType}
            />
          )}

          <ContentQuestion>
            <ContentQuestionHighlight>Q.</ContentQuestionHighlight>
            {block.question}
          </ContentQuestion>
          {isAuthEmpty(auth) && (
            <NicknameInput
              placeholder="닉네임을 알려주세요 🙏"
              onChange={handleNicknameChange}
              nickname={nickname}
              nicknameFocus={nicknameFocus}
              onFocus={() => {
                setNicknameFocus(true);
              }}
              onBlur={() => {
                setNicknameFocus(false);
              }}
              value={nickname}
            />
          )}
          <TextAreaContainer isAuthEmpty={isAuthEmpty(auth)}>
            {!isMobile && <TextareaTriangle clicked={commentFocus} />}
            <Textarea
              placeholder="지금 당신의 생각이 옳습니다☺️"
              value={comment}
              onChange={handleCommentChange}
              onFocus={() => setCommentFocus(true)}
              onBlur={() => setCommentFocus(false)}
            ></Textarea>
          </TextAreaContainer>
          <TextLengthCheck>{comment.length}자</TextLengthCheck>
          <ButtonContainer>
            {!isAuthEmpty(auth) && (
              <Toggle toggle={isPublic} setToggle={setIsPublic}></Toggle>
            )}
            <SubmitButton
              disabled={
                isAuthEmpty(auth)
                  ? comment.length === 0 || nickname.length === 0
                  : comment.length === 0
              }
              onClick={handleSubmit}
              isAuthEmpty={isAuthEmpty(auth)}
            >
              마음 쓰기 ✍️
            </SubmitButton>
            {!isAuthEmpty(auth) && (
              <Tooltip
                text={`${auth.user.nickname}님의 마음을 잘 기록했어요!`}
                visible={visible}
              />
            )}
          </ButtonContainer>
          <FlexRow
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <ReplyHeader>마음 함께 보기 👀</ReplyHeader>
            <MoreTrainingButton onClick={handleMoreTrainingClick}>
              + 트레이닝 더하기
            </MoreTrainingButton>
          </FlexRow>
          <ReplyContainer>
            {comments.map((item) => {
              // console.log(item);
              return (
                <ReplyItem
                  key={item._id}
                  isPublic={!isAuthEmpty(auth) ? item.isPublic : true}
                >
                  <ReplyItemTop>
                    <ReplyItemInfo>
                      <ReplyName>
                        {isAuthEmpty(auth)
                          ? item.nickname
                          : item.userId.nickname}
                      </ReplyName>
                      {!isAuthEmpty(auth) && !item.isPublic && (
                        <svg
                          style={{ marginLeft: "0.25rem" }}
                          width="8"
                          height="11"
                          viewBox="0 0 8 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_659_4107)">
                            <path
                              d="M6.90914 4.46919V2.74977C6.90914 1.234 5.60384 0 3.99951 0C2.39518 0 1.09086 1.234 1.09086 2.74977V4.46826C0.488873 4.46826 0 4.93043 0 5.49954V9.96872C0 10.5378 0.488873 11 1.09086 11H6.90914C7.51113 11 8 10.5378 8 9.96872V5.50046C8 4.93136 7.51113 4.46919 6.90914 4.46919ZM1.81778 2.74977C1.81778 1.61248 2.79651 0.687209 3.99951 0.687209C5.20251 0.687209 6.18124 1.61248 6.18124 2.74977V4.46826H1.81778V2.74977ZM7.2721 9.96872C7.2721 10.1584 7.10882 10.3128 6.90815 10.3128H1.09086C0.8902 10.3128 0.726915 10.1584 0.726915 9.96872V5.50046C0.726915 5.31076 0.8902 5.1564 1.09086 5.1564H6.90914C7.1098 5.1564 7.27309 5.31076 7.27309 5.50046V9.96965L7.2721 9.96872Z"
                              fill="#222222"
                            />
                            <path
                              d="M3.99951 6.53174C3.79884 6.53174 3.63556 6.68611 3.63556 6.87581V8.5943C3.63556 8.784 3.79786 8.93837 3.99951 8.93837C4.20116 8.93837 4.36346 8.78493 4.36346 8.5943V6.87581C4.36346 6.68611 4.20017 6.53174 3.99951 6.53174Z"
                              fill="#222222"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_659_4107">
                              <rect width="8" height="11" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      <ReplyDate>
                        {converDate(new Date(item.createdAt))}
                      </ReplyDate>
                    </ReplyItemInfo>
                    {!isAuthEmpty(auth) && item.userId._id === auth.user.id && (
                      <ReplyItemPublicButton
                        isPublic={item.isPublic}
                        onClick={() =>
                          handlePublicClick(!item.isPublic, item._id)
                        }
                      >
                        {item.isPublic ? "마음 비공개" : "마음 공개"}
                      </ReplyItemPublicButton>
                    )}
                  </ReplyItemTop>
                  <ReplyComment>{lineBreaker(item.comment)}</ReplyComment>
                  {!isAuthEmpty(auth) && (
                    <CommentLikeButton
                      isPublic={item.isPublic}
                      likeComment={item.likeIds.indexOf(auth.user.id) !== -1}
                      onClick={() =>
                        handleCommentLikeClick(item._id, item.likeIds)
                      }
                      disabled={!item.isPublic}
                    >
                      <svg
                        width="13"
                        height="15"
                        viewBox="0 0 13 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 14.3731C1.79429 14.3731 3.0339 14.3731 3.0339 14.3731V6.01489C3.0339 6.01489 1.79429 6.01489 1 6.01489"
                          stroke={
                            item.likeIds.indexOf(auth.user.id) !== -1
                              ? "#00DBB1"
                              : "#BBBBBB"
                          }
                          strokeWidth="0.8"
                          strokeliterlimit="10"
                          strokeLinecap="round"
                        />
                        <path
                          d="M1 6V14.5"
                          stroke={
                            item.likeIds.indexOf(auth.user.id) !== -1
                              ? "#00DBB1"
                              : "#BBBBBB"
                          }
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.32202 6.01489H10.3152H11.5966C12.0359 6.01489 12.3898 6.39101 12.3898 6.85907V7.26027C12.3898 7.72415 12.0359 8.10444 11.5966 8.10444H11.5763"
                          stroke={
                            item.likeIds.indexOf(auth.user.id) !== -1
                              ? "#00DBB1"
                              : "#BBBBBB"
                          }
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.03387 6.85075C3.84743 6.38687 5.47455 5.1791 6.00743 4.02149C6.19862 3.60776 6.26371 2.55881 6.24337 1.80239C6.23116 1.36358 6.54032 1 6.92676 1H6.98777C7.94777 1 8.72879 1.89015 8.72879 2.98507V3.05612C8.72879 3.43224 8.32201 6.01493 8.32201 6.01493"
                          stroke={
                            item.likeIds.indexOf(auth.user.id) !== -1
                              ? "#00DBB1"
                              : "#BBBBBB"
                          }
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.5762 8.10449H11.5966C12.0359 8.10449 12.3898 8.47225 12.3898 8.92778V9.37494C12.3898 9.83046 12.0359 10.1982 11.5966 10.1982H11.1694"
                          stroke={
                            item.likeIds.indexOf(auth.user.id) !== -1
                              ? "#00DBB1"
                              : "#BBBBBB"
                          }
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.1695 10.194H11.1898C11.6291 10.194 11.983 10.5617 11.983 11.0173V11.4644C11.983 11.9199 11.6291 12.2877 11.1898 12.2877H10.7627"
                          stroke={
                            item.likeIds.indexOf(auth.user.id) !== -1
                              ? "#00DBB1"
                              : "#BBBBBB"
                          }
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.783 12.2836C11.2223 12.2836 11.5762 12.6597 11.5762 13.1277V13.5289C11.5762 13.9928 11.2223 14.3731 10.783 14.3731H4.75858C4.17689 14.3731 3.6196 14.2394 3.11926 14.0054"
                          stroke={
                            item.likeIds.indexOf(auth.user.id) !== -1
                              ? "#00DBB1"
                              : "#BBBBBB"
                          }
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <CommentLikeCount>{item.likeNums}</CommentLikeCount>
                    </CommentLikeButton>
                  )}
                </ReplyItem>
              );
            })}
          </ReplyContainer>
        </ContentWrapper>
      </Section>
      {showStartModal && !missionBlocks.includes(block.number) && (
        <ModalBackground style={{ zIndex: 50 }}>
          <StartModal
            onConfirm={() => setShowStartModal(false)}
            onCancel={() => {
              actionRef?.current?.scrollIntoView({
                behavior: "instant",
                block: "nearest",
                inline: "nearest",
              });
              setShowStartModal(false);
            }}
            block={block}
            type={confettiType}
          ></StartModal>
        </ModalBackground>
      )}
      {showHelpModal && (
        <ModalBackground style={{ zIndex: 50 }}>
          <HelpModal
            onClick={(e, type) => onHelpClick(e, type)}
            block={block}
          ></HelpModal>
        </ModalBackground>
      )}
      {showConfettiModal && (
        <ModalBackground style={{ zIndex: 50 }}>
          <Modal
            close={() => setShowConfettiModal(false)}
            block={block}
            type={confettiType}
            cash={cash}
          ></Modal>
        </ModalBackground>
      )}
    </Container>
  );
};

export default Prototype;
