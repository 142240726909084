import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import CardLayout from "../CardLayout";
import Badge from "../components/Badge";
import CloseXButton from "../components/CloseXButton";
import Divider from "../components/Divider";
import Heading1 from "../components/Heading1";
import Heading2 from "../components/Heading2";
import Modal from "../components/Modal";
import ModalHeading2 from "../components/ModalHeading2";
import Text1 from "../components/Text1";
import TextLink from "../components/TextLink";
import AddManagerModal from "../modals/AddManagerModal";
import DeleteManagerModal from "../modals/DeleteManagerModal";
import EditManagerModal from "../modals/EditManagerModal";
import DotListItem from "../components/DotListItem";
import NumberText from "../components/NumberText";
import GroupManageModal from "../modals/GroupManageModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import validateEmailDomain from "../../../utils/validateEmailDomain";
import { useSearchParams } from "react-router-dom";

const Container = styled.div`
  padding: 2.75rem 2.75rem;
  padding-left: calc(2.75rem + var(--sidebar-width));
  width: 100%;
  position: relative;
`;

const KeyText = styled.p`
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  flex: 1;
`;
const ValueText = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  flex: 4;
`;

const SubText = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const Input = styled.input`
  border-radius: 0.3125rem;
  border: 1px solid black;
  padding: 0.38rem 0.5rem;
  outline: none;
`;

const SmallButton = styled.button`
  border-radius: 0.3125rem;
  background: ${({ theme }) => theme.primary.normal};
  border: 1px solid ${({ theme }) => theme.primary.normal};
  padding: 0.25rem 0.75rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
`;

const Ul = styled.ul`
  flex: 4;
`;

const Li = styled.li`
  display: flex;
  align-items: center;
  padding: 0.94rem 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
`;

const HeaderItem = styled.p`
  flex: 1;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Item = styled.p`
  flex: 1;
  font-size: 0.9375rem;
  line-height: 150%; /* 1.40625rem */
  letter-spacing: -0.03125rem;
`;

const Button = styled.button`
  border: none;
  border-radius: 0.3125rem;
  display: flex;
  padding: 0.25rem 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
`;

const EditButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  color: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const DeleteButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.error};
`;

const AddButton = ({ style, onClick }) => (
  <svg
    style={style}
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <rect width="46" height="46" rx="10" fill="#F7F7F7" />
    <path
      d="M22.7997 30.7431V16H23.9185V30.7431H22.7997ZM16 23.9309V22.8122H30.7307V23.9309H16Z"
      fill="#BBBBBB"
    />
  </svg>
);

const ManagerGroup = () => {
  const [params] = useSearchParams();
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [showGroupManageModal, setShowGroupManageModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditDomainClicked, setIsEditDomainClicked] = useState(false);
  const [domain, setDomain] = useState("");
  const [company, setCompany] = useState({});
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const companyId = params.get("id") || auth.user.company._id;
  const onDomainEditClick = () => {
    setDomain("");
    setIsEditDomainClicked((prev) => !prev);
  };

  const onDomainChange = (e) => {
    setDomain(e.target.value);
  };

  const onDomainDoneClick = async () => {
    if (!validateEmailDomain(domain)) return;

    // domain 변경 api
    const res = await axiosPrivate.patch(`/company`, {
      companyId: companyId,
      data: {
        emailDomain: domain,
      },
    });
    if (res.data.ok) {
      setAuth({
        accessToken: auth.accessToken,
        user: {
          ...auth.user,
          companyId: res.data.data,
        },
      });
    }
    setIsEditDomainClicked(false);
  };

  useEffect(() => {
    (async () => {
      const companyResponse = await axiosPrivate.get(`/company/${companyId}`);

      if (!companyResponse.data.ok) {
        return;
      }
      setCompany(companyResponse.data.data.company);
      setDepartments(companyResponse.data.data.departments);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <Container>
      <Heading1 text="그룹 관리" />
      <Heading2
        text="그룹에 대한 정보와 조직도를 수정 및 관리할 수 있습니다"
        style={{ marginBottom: "2.44rem" }}
      />
      <FlexColumn style={{ gap: "1.88rem" }}>
        <CardLayout style={{ padding: "1.88rem 2.5rem" }}>
          <FlexRow
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Text1 text="그룹 정보" />
          </FlexRow>
          <Divider />
          <FlexColumn style={{ gap: "1.25rem" }}>
            <FlexRow>
              <KeyText>조직명</KeyText>
              <ValueText>{company.name}</ValueText>
            </FlexRow>
            {/* <FlexRow>
              <KeyText>조직 도메인</KeyText>

              <FlexRow
                style={{ flex: 4, gap: "0.75rem", alignItems: "center" }}
              >
                {isEditDomainClicked ? (
                  <>
                    <Input onChange={onDomainChange} value={domain} />
                    <SmallButton
                      style={{
                        border: "1px solid #BBB",
                        color: "#7C8585",
                        background: "white",
                      }}
                      onClick={onDomainEditClick}
                    >
                      취소
                    </SmallButton>
                    <SmallButton
                      style={{ opacity: validateEmailDomain(domain) ? 1 : 0.5 }}
                      onClick={onDomainDoneClick}
                    >
                      수정 완료
                    </SmallButton>
                  </>
                ) : (
                  <>
                    <ValueText style={{ flex: "none" }}>
                      {auth.user.companyId.emailDomain}
                    </ValueText>
                    <img
                      onClick={onDomainEditClick}
                      src={`${process.env.PUBLIC_URL}/svg/dashboard/edit_button.svg`}
                      alt="edit button"
                    />
                  </>
                )}
              </FlexRow>
            </FlexRow> */}
            {/* <FlexRow>
              <KeyText>조직 로고</KeyText>
              <FlexRow
                style={{
                  alignItems: "center",
                  gap: "0.63rem",
                  flex: 4,
                }}
              >
                <ValueText style={{ flex: "none" }}>월 구독</ValueText>
              </FlexRow>
            </FlexRow> */}
          </FlexColumn>
        </CardLayout>
        <CardLayout style={{ padding: "1.88rem 2.5rem" }}>
          <FlexRow style={{ alignItems: "center", gap: "0.63rem" }}>
            <Text1 text="그룹 관리" />
            <img
              onClick={() => setShowGroupManageModal(true)}
              src={`${process.env.PUBLIC_URL}/svg/dashboard/setting_button.svg`}
              alt="setting button"
            />
          </FlexRow>
          <Divider />
          <FlexColumn style={{ gap: "1.25rem" }}>
            <FlexRow style={{ alignItems: "center" }}>
              <KeyText>{company.name}</KeyText>
            </FlexRow>
            {departments.map((department, index) => (
              <FlexRow
                key={index}
                style={{ alignItems: "center", gap: "0.75rem" }}
              >
                <DotListItem>{department.name}</DotListItem>
                <NumberText>{department.numUsers}</NumberText>
              </FlexRow>
            ))}
          </FlexColumn>
        </CardLayout>
        <Modal
          showing={showGroupManageModal}
          setShowing={setShowGroupManageModal}
        >
          <GroupManageModal
            companyName={company.name}
            companyId={company._id}
            departments={departments}
            setDepartments={setDepartments}
            closeHandler={() => setShowGroupManageModal(false)}
          />
        </Modal>
        {/* <Modal showing={showEditModal} setShowing={setShowAddModal}>
          <EditManagerModal closeHandler={() => setShowEditModal(false)} />
        </Modal>
        <Modal showing={showDeleteModal} setShowing={setShowDeleteModal}>
          <DeleteManagerModal closeHandler={() => setShowDeleteModal(false)} />
        </Modal> */}
      </FlexColumn>
    </Container>
  );
};

export default ManagerGroup;
