import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { forwardRef } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ReportBarChart = forwardRef(
  ({ scale, stepSize = 1, labels, results, legend, compare }, ref) => {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: "top",
          align: "end",
        },
        title: {
          display: false,
          text: "Chart.js Bar Chart",
        },
        datalabels: {
          display: false,
          color: ["#CBEAE4", "#80E5D2", "#00DBB1"],
          font: { size: 15, weight: "bold" },
          anchor: "end",
          offset: -25,
          align: "start",
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              return context[0].label.replaceAll(",", " ");
            },
            label: (context) => {
              return `${context.dataset.label}: ${
                context.formattedValue === "0.023"
                  ? "0"
                  : context.formattedValue
              }`;
            },
          },
        },
      },
      layout: {
        padding: 0,
      },
      categoryPercentage: 1,
      barPercentage: 0,
      scales: {
        x: {
          position: "bottom",
          offset: true,
          grid: { display: false, drawBorder: false, lineWidth: 1 },
          ticks: {
            color: "#000000",
            padding: 10,
            //   font: labels
            //     .map((item, index) => [`Q${index + 1}.`, item])
            //     .map((item, index) =>
            //       item[0].includes("Q") ? { weight: "bold" } : { weight: "normal" }
            //     ),
          },
        },
        y: {
          offset: false, // y축 위/아래 짤리는 거 옵션
          min: 0,
          max: scale,
          ticks: {
            stepSize,
            padding: 5,
            color: "#777777",
            font: {
              family: "Montserrat",
            },
          },
          grid: {
            drawBorder: false,
            lineWidth: 1,
          },
        },
      },
    };
    const colors = ["rgba(0, 219, 177, 0.7)", "rgba(217, 217, 217, 0.7)"];
    const data = {
      labels: labels.map((item, index) => [
        item.substring(0, 2),
        item.substring(2),
      ]),
      datasets: results.map((result, index) => {
        return {
          label: index === 0 ? legend : compare,
          data: result.map((item) => (item === 0 ? 0.023 : item)),
          backgroundColor: colors[index],
          borderRadius: 20,

          categoryPercentage: 0.6,
          barPercentage: 0.7,
        };
      }),
      // datasets: results.map((result, index) => ({
      //   label: `${index + 1}차`,
      //   data: result[index],
      //   backgroundColor: colors[index],
      //   borderRadius: 50,
      //   // barThickness: 24,
      //   // borderWidth: 10,
      //   // borderColor: "rgba(0,0,0,0)",
      //   categoryPercentage: 0.6,
      //   barPercentage: 0.7,
      // })),
    };
    return <Bar ref={ref} options={options} data={data} height={"100px"} />;
  }
);

export default ReportBarChart;
