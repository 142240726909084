import { useNavigate, useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useEffect, useState } from "react";
import axios from "../api/axios";
import FlexRow from "../components/FlexRow";
import FlexColumn from "../components/FlexColumn";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import "react-slideshow-image/dist/styles.css";
import convertPermavac from "../utils/convertPermavac";
import { MOBILE } from "../Constants";
import { getDate, getThisWeekFriday } from "../utils/date";
import useCountdown from "../hooks/useCountdown";
import { isAuthEmpty } from "../utils/isAuthEmpty";
import { Helmet } from "react-helmet";
import convertBGColor from "../utils/convertBlockBgColor";
import useLogout from "../hooks/useLogout";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 5.75rem);
  background: ${({ theme }) => theme.grayScaleColor.white};
  // border: 1px solid black;
`;

const TapbarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
  /* padding-bottom: 0.5rem; */
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const Tap = styled.div`
  flex: 1;
  text-align: center;
  margin: 0 0.75rem;
  ${({ theme, selected }) =>
    selected && `border-bottom: 0.125rem solid ${theme.primary.normal}`};
  padding-bottom: 0.5rem;
  color: ${({ theme, selected }) => selected && theme.primary.normal};
  font-weight: ${({ theme, selected }) => selected && 600};
`;

const Wrapper = styled.div`
  padding: 1.75rem 1.25rem;
`;

const Heading1 = styled.h2`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 2rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Heading2 = styled.h2`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  color: black;
`;

const Heading3 = styled.h3`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  margin-top: 2.56rem;
  margin-bottom: 1.25rem;
`;

const Heading4 = styled.h4`
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  color: #000;
`;

const TextLink = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.03125rem;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const Section = styled.section`
  padding: 1.75rem 1.25rem;
`;

const Divider = styled.div`
  width: 100%;
  height: 0.3125rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
`;

const CardContainer = styled.div`
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 0.9375rem;
  background: ${({ bgColor }) => bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.62rem;
  & img {
    width: 5.3125rem;
    height: 5.3125rem;
  }
`;
const CardTitle = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;
const CardNumber = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const Card = ({ block, permavac }) => {
  const navigate = useNavigate();
  if (!block) {
    return null;
  }
  return (
    <FlexColumn onClick={() => navigate(`/blocks/${block?.number}`)}>
      <CardContainer bgColor={convertBGColor(block?.theme)}>
        <img src={block?.iconUrl} alt={"block icon"} />
      </CardContainer>
      <CardTitle>{block?.title}</CardTitle>
      <CardNumber>#{permavac}</CardNumber>
    </FlexColumn>
  );
};

// ===================================
// 마이페이지 스타일 컴포넌트
// ===================================

const Avatar = styled.div`
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.backgroundColor.darkgray20};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Name = styled.p`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Nickname = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const EditButton = styled.button`
  border-radius: 0.4375rem;
  padding: 0.38rem 0.62rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  border: none;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.3rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
`;

const CashContainer = styled.div`
  padding: 1.25rem;
  margin-top: 1.69rem;
  border-radius: 0.9375rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
  display: flex;
  align-items: center;
  & .cash {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #000;
  }
`;

const Text5 = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;
const ExpContainer = styled.div`
  border-radius: 0.9375rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
  padding: 1.25rem;
  margin-top: 0.81rem;
  margin-bottom: 1.75rem;
  & .key {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 1.4rem */
    letter-spacing: -0.03125rem;
    color: ${({ theme }) => theme.backgroundColor.darkgray70};
  }
  & .value {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 1.4rem */
    color: ${({ theme }) => theme.primary.normal};
  }
`;
const IconButtonText = styled.p`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
`;
const IconButton = ({ icon, text, onClick, style }) => {
  return (
    <FlexRow
      style={{ alignItems: "center", gap: "0.5rem", ...style }}
      onClick={onClick}
    >
      <img src={icon} alt={"icon"} style={{ width: "1.5rem" }} />
      <IconButtonText>{text}</IconButtonText>
    </FlexRow>
  );
};

function MyPageMobile() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [low, setLow] = useState("positive_emotions");
  const logout = useLogout();
  const MIN_WITHDRAW_AMOUNT = auth?.user?.isFirstWithdraw ? 5000 : 10000;

  const navigate = useNavigate();

  const handleLogoutClick = async () => {
    await logout();
  };

  useEffect(() => {
    const fetch = async () => {
      let lowExp = {
        type: "positive_emotions",
        point: auth.user.experiencePoint["positive_emotions"],
      };
      for (const key in auth.user.experiencePoint) {
        if (lowExp.point > auth.user.experiencePoint[key]) {
          lowExp = {
            type: key,
            point: auth.user.experiencePoint[key],
          };
        }
      }
      setLow(lowExp.type);
      const homeResponse = await axiosPrivate.get(
        `/home/mypage?permavac=${lowExp.type}`
      );
      setData(homeResponse.data.data);
      setIsLoading(false);
    };

    if (isAuthEmpty(auth)) {
      return navigate("/login");
    }
    fetch();
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const t = new Date();
  //     setNow(t);
  //     if (
  //       t.getTime() >= friday.getTime() &&
  //       t.getTime() < surveyEnd.getTime()
  //     ) {
  //       setIsSurveyPeriod(true);
  //     } else {
  //       setIsSurveyPeriod(false);
  //     }
  //   }, [1000]);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [now]);
  if (isLoading) {
    return <div />;
  }

  if (isAuthEmpty(auth)) {
    return navigate("/login");
  }

  return (
    <Container>
      <Helmet>
        <meta name="theme-color" content="#fff" />
      </Helmet>
      {/* <TapbarContainer>
        <Tap selected={tabIndex === 0} onClick={(e) => onTabClick(e, 0)}>
          홈
        </Tap>
        <Tap selected={tabIndex === 1} onClick={(e) => onTabClick(e, 1)}>
          마이페이지
        </Tap>
      </TapbarContainer> */}
      <>
        <Section>
          <FlexRow
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <FlexRow style={{ gap: "0.81rem", alignItems: "center" }}>
              <Avatar>
                <svg
                  width="33"
                  height="31"
                  viewBox="0 0 33 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_6346_13135)">
                    <path
                      d="M29.1864 25.7787V4.92381C29.1864 2.69583 27.3269 0.882355 25.0424 0.882355H5.02633C2.74181 0.882355 0.882324 2.69583 0.882324 4.92381V26.8409C0.882324 29.0689 2.74181 30.8824 5.02633 30.8824H31.3912C32.5069 30.8824 33.0648 29.5611 32.2811 28.7969L29.1864 25.7787ZM15.041 25.2217C10.804 25.2217 7.32413 21.9963 7.03192 17.9419C7.00536 17.644 7.271 17.3979 7.56321 17.3979H9.30316C9.58208 17.3979 9.80788 17.6181 9.83444 17.8901C10.0868 20.4678 12.3182 22.5015 15.041 22.5015C17.7638 22.5015 19.9952 20.4808 20.2476 17.8901C20.2741 17.6181 20.4867 17.3979 20.7789 17.3979H22.5188C22.8243 17.3979 23.0767 17.644 23.0501 17.9419C22.7579 22.0093 19.278 25.2217 15.041 25.2217Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6346_13135">
                      <rect
                        width="31.7647"
                        height="30"
                        fill="white"
                        transform="translate(0.882324 0.882355)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </Avatar>
              <FlexColumn>
                <Name>{auth.user.name}</Name>
                <Nickname>{auth.user.nickname}</Nickname>
              </FlexColumn>
            </FlexRow>
            <EditButton onClick={() => navigate("profile")}>
              내 정보 수정
            </EditButton>
          </FlexRow>
          <CashContainer>
            <FlexRow
              style={{
                alignItems: "center",
                justifyContent: "space-between",

                width: "100%",
              }}
            >
              <FlexRow style={{ gap: "0.5rem" }}>
                <Text5>내 캐시</Text5>
                <FlexRow style={{ alignItems: "center", gap: "0.25rem" }}>
                  <img
                    style={{ width: "1rem", height: "1rem" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                    alt="cash icon"
                  />
                  <p className="cash">{auth.user.point.toLocaleString()}원</p>
                </FlexRow>
              </FlexRow>
              <TextLink onClick={() => navigate("reward")}>환급 신청</TextLink>
            </FlexRow>
          </CashContainer>
          <Text5
            style={{
              fontWeight: 400,
              textAlign: "right",
              marginTop: "0.62rem",
            }}
          >
            {auth.user.point >= MIN_WITHDRAW_AMOUNT
              ? "📢 캐시 환급이 가능해요!"
              : `📢 캐시 환급까지 ${(
                  MIN_WITHDRAW_AMOUNT - auth.user.point
                ).toLocaleString()}원 남았어요!`}
          </Text5>
        </Section>
        <Divider />
        <Section>
          <Heading2>보유 마음 에너지 ✨</Heading2>
          <ExpContainer>
            <FlexRow style={{ gap: "3.5rem" }}>
              <FlexColumn style={{ flex: 1, gap: "0.5rem" }}>
                <FlexRow
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid rgba(124, 133, 133, 0.15)",
                    paddingBottom: "0.5rem",
                  }}
                >
                  <p className="key">긍정정서</p>
                  <p className="value">
                    {auth.user.experiencePoint["positive_emotions"]}
                  </p>
                </FlexRow>
                <FlexRow
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid rgba(124, 133, 133, 0.15)",
                    paddingBottom: "0.5rem",
                  }}
                >
                  <p className="key">관계</p>
                  <p className="value">
                    {auth.user.experiencePoint["relationship"]}
                  </p>
                </FlexRow>
                <FlexRow
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid rgba(124, 133, 133, 0.15)",
                    paddingBottom: "0.5rem",
                  }}
                >
                  <p className="key">유능감</p>
                  <p className="value">
                    {auth.user.experiencePoint["competence"]}
                  </p>
                </FlexRow>
                <FlexRow
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className="key">자율성</p>
                  <p className="value">
                    {auth.user.experiencePoint["autonomy"]}
                  </p>
                </FlexRow>
              </FlexColumn>
              <FlexColumn style={{ flex: 1, gap: "0.5rem" }}>
                <FlexRow
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid rgba(124, 133, 133, 0.15)",
                    paddingBottom: "0.5rem",
                  }}
                >
                  <p className="key">성취감</p>
                  <p className="value">
                    {auth.user.experiencePoint["achievement"]}
                  </p>
                </FlexRow>
                <FlexRow
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid rgba(124, 133, 133, 0.15)",
                    paddingBottom: "0.5rem",
                  }}
                >
                  <p className="key">몰입</p>
                  <p className="value">
                    {auth.user.experiencePoint["engagement"]}
                  </p>
                </FlexRow>
                <FlexRow
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid rgba(124, 133, 133, 0.15)",
                    paddingBottom: "0.5rem",
                  }}
                >
                  <p className="key">의미</p>
                  <p className="value">
                    {auth.user.experiencePoint["meaning"]}
                  </p>
                </FlexRow>
                <FlexRow
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className="key">활력</p>
                  <p className="value">
                    {auth.user.experiencePoint["vitality"]}
                  </p>
                </FlexRow>
              </FlexColumn>
            </FlexRow>
          </ExpContainer>

          <Heading4>
            {convertPermavac(low)} 에너지를 바로 높일 수 있는 <br />
            추천 마음 트레이닝
          </Heading4>
          <FlexRow
            style={{ gap: "0.75rem", marginTop: "1.19rem", overflow: "auto" }}
          >
            {data.blocks.map((block, index) => (
              <Card key={index} block={block} permavac={convertPermavac(low)} />
            ))}
          </FlexRow>
        </Section>
        <Divider style={{ height: "0.0625rem" }} />
        <Section>
          <Heading4>내 활동 기록</Heading4>
          <FlexColumn style={{ marginTop: "1.25rem", gap: "1.25rem" }}>
            <IconButton
              icon={`${process.env.PUBLIC_URL}/svg/home/checkup.svg`}
              text={"마음 체크업"}
              onClick={() => navigate("checkup")}
            />
            <IconButton
              icon={`${process.env.PUBLIC_URL}/svg/home/training.svg`}
              text={"마음 트레이닝"}
              onClick={() => navigate("training")}
            />
            <IconButton
              icon={`${process.env.PUBLIC_URL}/svg/home/badge.svg`}
              text={"맘핏 뱃지"}
              onClick={() => navigate("badge")}
            />
          </FlexColumn>
        </Section>
        <Divider style={{ height: "0.0625rem" }} />
        <Section>
          <Heading4>기타</Heading4>
          <FlexColumn style={{ marginTop: "1.25rem", gap: "1.25rem" }}>
            <IconButton
              icon={`${process.env.PUBLIC_URL}/svg/home/notification.svg`}
              text={"문자 알림 설정"}
              onClick={() => navigate("notification")}
            />
          </FlexColumn>
        </Section>
        <Divider style={{ height: "0.0625rem" }} />
        <Section>
          <IconButton
            icon={`${process.env.PUBLIC_URL}/svg/home/logout.svg`}
            text={"로그아웃"}
            onClick={handleLogoutClick}
          />
        </Section>
      </>
    </Container>
  );
}

export default MyPageMobile;
