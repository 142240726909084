import styled from "styled-components";

const Text = styled.h3`
  color: ${({ theme }) => theme.primary.normal};
  font-size: 1.13rem;
  font-weight: 600;
  line-height: 1.5rem;
`;

const SubHeading = ({ children, style }) => {
  return <Text style={style}>{children}</Text>;
};

export default SubHeading;
