import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "../../api/axios";
import TopBar from "../../components/TopBar";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { converDate } from "../../utils/convertDate";
import { AverageChart } from "./AverageChart";
import { Chart } from "./Chart";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 4.06rem);
  border: 1px solid black;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 62.5rem;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 2.31rem;
  margin-bottom: 2.94rem;
  align-self: flex-start;
  line-height: 2rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  //   border: 1px solid black;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.25rem;
`;
const SubTitle = styled.h3`
  font-size: 1.13rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 500;
  align-self: flex-start;
`;

const TextButton = styled.button`
  color: ${({ theme }) => theme.primary.dark};
  font-size: 0.75rem;
  font-weight: 300;
  border: none;
  background-color: inherit;
  cursor: pointer;
`;

const SmallBlockContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3.94rem;
`;
const SmallBlock = styled.div`
  width: 19.44rem;
  height: 9.19rem;
  display: flex;
  flex-direction: column;
  padding: 1.06rem 1.56rem;
  border-radius: 0.63rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
`;

const SmallBlockTitle = styled.span`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const SmallBlockDivider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
  margin-top: 0.81rem;
  margin-bottom: 1.19rem;
`;

const SmallBlockValue = styled.span`
  font-size: 2.25rem;
  line-height: 2.75rem;
  color: ${({ theme }) => theme.primary.normal};
  font-weight: 700;
`;

const BlockContainer = styled.section`
  width: 100%;
  display: flex;
  gap: 2.06rem;
  margin-bottom: 6.56rem;
`;

const BlockCard = styled.div`
  width: 19.44rem;
  height: 12.63rem;
  border-radius: 0.63rem;
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  color: ${({ theme }) => theme.grayScaleColor.black};
  display: flex;
  flex-direction: column;
`;

const BlockImage = styled.img`
  width: 100%;
  height: 12.63rem;
  border-top-left-radius: 0.63rem;
  border-top-right-radius: 0.63rem;
`;

const BlockTitle = styled.div`
  width: 100%;
  height: 4.06rem;
  display: flex;
  align-items: center;
  font-size: 0.94rem;
  font-weight: 500;
  padding-left: 1.44rem;
`;

const SmallText = styled.span`
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  font-size: 0.81rem;
  line-height: 1.44rem;
`;

function Dashboard() {
  const { auth } = useAuth();
  const [statistics, setStatistics] = useState();
  const [blockStatistics, setBlockStatistics] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleMaumScoreDetailClick = () => {
    navigate("score", { state: { result: statistics.result } });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get(
          `/checkup/statistics?id=${auth.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${auth.accessToken}`,
            },
            withCredentials: true,
          }
        );
        const blockResponse = await axiosPrivate.get(
          `/block/statistics?id=${auth.user.id}`
        );
        // console.log(blockResponse.data.data);
        setBlockStatistics(blockResponse.data.data);
        setStatistics(response.data);
      } catch (err) {
        if (err.response.status === 403) {
          // TODO: logout user only when (403 err) refresh token expired.
          // 1. show session expired alert
          // 2. logout the user: setAuth({}), setPersist(false), remove persist from localstorage
          // 3. redirect user to login in JSX

          // Remove above code from userRefreshToken hook
          alert("session expired. Please login again");
        }
      } finally {
        setIsLoading(false);
      }
      //   return response;
    };

    fetchData();
    // console.log(data);
  }, []);
  if (isLoading) {
    return null;
  }
  return (
    <Container>
      {statistics.result && (
        <Wrapper>
          <Title>
            {auth.user.name}님의
            <br />
            마음은 안녕하신가요?
          </Title>
          <SmallBlockContainer>
            <SmallBlock>
              <SmallBlockTitle>마음 체크업 횟수</SmallBlockTitle>
              <SmallBlockDivider />
              <SmallBlockValue>{statistics.totalCount}</SmallBlockValue>
            </SmallBlock>
            <SmallBlock>
              <SmallBlockTitle>마음 체크업 평균</SmallBlockTitle>
              <SmallBlockDivider />
              <SmallBlockValue>{statistics.average}</SmallBlockValue>
            </SmallBlock>
            <SmallBlock>
              <SmallBlockTitle>최근 마음 체크업</SmallBlockTitle>
              <SmallBlockDivider />
              <SmallBlockValue>
                {statistics.result[statistics.result.length - 1]
                  ? converDate(
                      statistics.result[statistics.result.length - 1].createdAt
                    )
                  : "-"}
              </SmallBlockValue>
            </SmallBlock>
          </SmallBlockContainer>

          <SectionHeader>
            <SubTitle>최근 마음 그래프 📈</SubTitle>
            <TextButton onClick={handleMaumScoreDetailClick}>
              자세히 보기
              <svg
                style={{ marginLeft: "0.5rem" }}
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.999999 9L6 5L1 1"
                  stroke="#00CA99"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </TextButton>
          </SectionHeader>
          <AverageChart results={statistics.result} />
          <SectionHeader>
            <SubTitle>나의 마음 트레이닝 기록 🚩</SubTitle>
            <SmallText>ⓘ 참여 횟수 상위 3항목이 노출됩니다😊</SmallText>
          </SectionHeader>
          <BlockContainer>
            {blockStatistics.slice(0, 3).map((item, index) => (
              <BlockCard key={index}>
                <BlockImage src={item.dashboardThumbnail} />
                <BlockTitle>{item.title}</BlockTitle>
              </BlockCard>
            ))}
          </BlockContainer>
        </Wrapper>
      )}
    </Container>
  );
}

export default Dashboard;
