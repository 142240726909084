import axios, { axiosPrivate } from "../../../api/axios";

const postSmileStatistics = async ({ auth, level, cid }) => {
  console.log(auth);
  if (auth?.user) {
    try {
      const response = await axiosPrivate.post(
        `/smile/statistic`,
        {
          level,
          uid: auth.user.id,
          cid: auth.user.companyId._id,
          did: auth.user.departmentId._id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  } else {
    try {
      const response = await axios.post(`/smile/guests/statistic`, {
        level,
        cid,
      });
      return response.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
};

export default postSmileStatistics;
