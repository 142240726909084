import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { TYPE_MEMBER } from "../../Constants";
import useAuth from "../../hooks/useAuth";
import { isAuthEmpty } from "../../utils/isAuthEmpty";
import TopBar from "../TopBar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.backgroundColor.darkgray20};
  width: 100%;
  // height: 100%;
  height: cal(100%);
  overflow: auto;
  // border: 1px solid black;
`;

const Layout = ({ type = TYPE_MEMBER }) => {
  const { auth } = useAuth();
  return (
    <Container>
      <TopBar
        type={type}
        name={!isAuthEmpty(auth) ? auth.user.name : "김블룸"}
        company={!isAuthEmpty(auth) ? auth.user.companyId.name : "블룸컴퍼니"}
      />
      <Outlet />
    </Container>
  );
};

export default Layout;
