import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import isEndWithConsonant from "../../../utils/isEndWithConsonant";
import { TABLET_LANDSCAPE } from "../../../Constants";
import Heading1 from "../Heading1";
import Heading2 from "../Heading2";
import CardLayout from "../CardLayout";
import { getDate } from "../../../utils/date";
import lineBreaker from "../../../utils/lineBreaker";

const Container = styled.div`
  margin-left: var(--sidebar-width);
  height: calc(100vh - 4.06rem);
  padding: 2.25rem 2.5rem;
  //   margin-bottom: 4rem;
  width: 100%;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    margin-left: var(--sidebar-tablet-landscape-width);
  }
`;

const CardContainer = styled.div`
  padding: 3rem 2rem 3rem 2.94rem;
  border-radius: 1.875rem;
  background: linear-gradient(180deg, #f8fbfb 0%, #e9f6f2 100%);
  width: 100%;
  position: relative;
`;

const CardTag = styled.div`
  border-radius: 0.3125rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 0.25rem 0.75rem;
  color: ${({ theme }) => theme.primary.normal};
  font-size: 0.8125rem;
  line-height: 150%; /* 1.21875rem */
  letter-spacing: -0.03125rem;
`;

const CardText1 = styled.p`
  font-weight: 600;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.primary.normal};
  margin-top: 1.25rem;
`;

const CardText2 = styled.p`
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 150%; /* 2.625rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-top: 0.25rem;
`;

const CardText3 = styled.p`
  font-size: 0.9375rem;
  line-height: 150%; /* 1.40625rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  margin-top: 1.19rem;
`;

const CardText4 = styled.p`
  font-size: 0.8125rem;
  line-height: 150%; /* 1.21875rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  margin-top: 1rem;
  margin-bottom: 10rem;
`;

const CardButton = styled.button`
  border-radius: 0.3125rem;
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
  padding: 0.62rem 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  border: 1px solid ${({ theme }) => theme.primary.normal};
  margin-top: 2.31rem;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  max-width: 425px;
  //   width: 372.857px;
  //   height: 14.9375rem;
  //   border: 1px solid black;
  position: absolute;
  bottom: 0;
  right: 0;
`;
const Card = ({
  tag1,
  tag2,
  text1,
  text2,
  text3,
  text4,
  buttonText,
  img,
  link,
  index,
}) => {
  const handleClick = () => {
    const random = Math.floor(Math.random() * 24 + 1);
    window.open(index === 1 ? link + random : link, "_blank");
  };
  return (
    <CardContainer>
      <FlexRow style={{ gap: "0.38rem" }}>
        <CardTag>{tag1}</CardTag>
        <CardTag>{tag2}</CardTag>
      </FlexRow>
      <CardText1>{text1}</CardText1>
      <CardText2>{text2}</CardText2>
      <CardText3>{lineBreaker(text3)}</CardText3>
      <CardButton onClick={handleClick}>{buttonText}</CardButton>
      <CardText4>{text4} </CardText4>
      <Image src={img} />
    </CardContainer>
  );
};

const PersonalGuide = () => {
  const data = [
    {
      tag1: "주간",
      tag2: "내 마음 점검",
      text1: "금요일 오후 1시",
      text2: "마음 체크업",
      text3: `매주 금요일 오후 1시, 
            메일함으로 찾아가는 마음 체크업을 통해 
            나의 마음을 건강하게 관리해 보세요!`,
      text4: "* 이 곳에서 진행한 테스트 결과는 저장되지 않아요!",
      buttonText: "체험하기",
      img: `${process.env.PUBLIC_URL}/assets/tutorial/checkup_tutorial.png`,
      link: "https://app.maum.fit/checkup/v2/demo?id=0",
    },
    {
      tag1: "맞춤",
      tag2: "멘탈관리",
      text1: "월요일 오전 8시",
      text2: "마음 트레이닝",
      text3: `매주 월요일 오전 8시,
      메일함으로 찾아가는 마음 트레이닝은
      내 마음에 꼭 필요한 트레이닝으로 구성되어 있답니다!`,
      text4: "* 트레이닝은 랜덤으로 노출됩니다.",
      buttonText: "체험하기",
      img: `${process.env.PUBLIC_URL}/assets/tutorial/training_tutorial.png`,
      link: `https://app.maum.fit/blocks/`,
    },
    {
      tag1: "상시",
      tag2: "온라인",
      text1: "언제 어디서나",
      text2: "메타버스 맘핏 센터",
      text3: `24시간 방문 가능한 메타버스 맘핏 센터에서
      혼자서 할 수 있는 마음 트레이닝과
      함께 모여 트레이닝을 할 수 있는 GX를 이용해 보세요!`,
      text4: "* GX : Group eXercise",
      buttonText: "체험하기",
      img: `${process.env.PUBLIC_URL}/assets/tutorial/metaverse_tutorial.png`,
      link: "https://zep.us/play/8ZwAV4",
    },
  ];
  return (
    <Container>
      <Heading1>맘핏 둘러보기</Heading1>
      <Heading2>맘핏의 주요 서비스를 경험해 보세요!</Heading2>
      <CardLayout style={{ marginTop: "2.25rem" }}>
        <FlexRow style={{ justifyContent: "space-between", gap: "3.25rem" }}>
          {data.map((item, index) => (
            <Card
              key={index}
              index={index}
              tag1={item.tag1}
              tag2={item.tag2}
              text1={item.text1}
              text2={item.text2}
              text3={item.text3}
              text4={item.text4}
              buttonText={item.buttonText}
              img={item.img}
              link={item.link}
            />
          ))}
        </FlexRow>
      </CardLayout>
    </Container>
  );
};

export default PersonalGuide;
