import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import FlexRow from "../../../components/FlexRow";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getDate } from "../../../utils/date";
import CardLayout from "../CardLayout";
import Heading1 from "../Heading1";
import Heading2 from "../Heading2";
import FlexColumn from "../../../components/FlexColumn";
import { TABLET_LANDSCAPE } from "../../../Constants";

const Container = styled.div`
  margin-left: var(--sidebar-width);
  padding: 2.25rem 2.5rem;
  width: 100%;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    margin-left: var(--sidebar-tablet-landscape-width);
  }
`;

const Badge = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 3.125rem;
  font-size: 0.875rem;
  line-height: 140%;
  border: none;
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  cursor: pointer;
`;

const TableButton = styled.button`
  border-radius: 0.4375rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  cursor: pointer;
`;

const ListContainer = styled.ul`
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const ColumnHeader = styled.li`
  display: flex;
  justify-content: space-evenly;
  font-weight: 600;
  padding: 1rem 0;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  border-top: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding-left: 2.31rem;
`;

const ColumnItem = styled.div`
  text-align: center;
`;

const Row = styled.li`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 400;
  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding-left: 2.31rem;
`;
const RowItem = styled.div`
  text-align: center;
`;

const SelectBox = styled.div`
  padding: 0.25rem 0.75rem;
  text-align: right;
  font-size: 0.875rem;
  border-radius: 0.4375rem;
  letter-spacing: -0.03125rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  height: 2rem;
  width: 7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ArrowDownSvg = ({ style }) => {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
    >
      <path d="M5 6L0.669872 0H9.33013L5 6Z" fill="#222222" />
    </svg>
  );
};

const Dropdown = ({ close, items, setItem }) => {
  const handleClick = (e, item) => {
    setItem(item);
    close(false);
  };
  return (
    <FlexColumn
      style={{
        position: "absolute",
        marginTop: "1.8rem",
        width: "100%",
        border: "1px solid #BBB",
        height: "15rem",
        overflowY: "auto",
        background: "white",
        borderRadius: "0rem 0rem 0.4375rem 0.4375rem",
        padding: "0 1rem",
        fontSize: "0.875rem",
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "center",
            borderBottom: "1px solid #EEEEEE",
            padding: "0.56rem 0",
            fontSize: "0.875rem",
            lineHeight: "140%",
          }}
          onClick={(e) => handleClick(e, item)}
        >
          {item}
        </div>
      ))}
    </FlexColumn>
  );
};

const WithdrawManagement = () => {
  const STATUS = [
    {
      status: 1,
      name: "지급 대기",
      color: "#3A3F3F",
      background: "#EEE",
    },
    {
      status: 2,
      name: "지급 완료",
      color: "#3A3F3F",
      background: "#E8FBF7",
    },
    {
      status: -1,
      name: "지급 오류",
      color: "#F11B1B",
      background: "#FDEFEF",
    },
  ];
  const YEAR = new Date().getFullYear();
  const DUE_DATE = 21;
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [transactionStatusCounts, setTransactionStatusCounts] = useState([
    0, 0, 0,
  ]);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [month, setMonth] = useState();
  const selectBoxRef = useRef();

  const handleDropdownClick = () => {
    setOpenDropDown((prev) => !prev);
  };

  const handleBadgeClick = (e, data) => {
    if (month === "전체 기간" || !month) {
      const dest = transactions.filter((item) => item.status === data.status);
      setFilteredTransactions(dest);

      return;
    }

    const dest = transactions.filter(
      (item) =>
        new Date(item.createdAt) >= new Date(YEAR, month[0] - 2, DUE_DATE) &&
        new Date(item.createdAt) < new Date(YEAR, month[0] - 1, DUE_DATE) &&
        item.status === data.status
    );

    setFilteredTransactions(dest);
  };

  const handleDownloadClick = async () => {
    const excelFileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const excelFileExtension = ".xls";
    const excelFileName = `${YEAR}년 ${month ? month : ""} 환급요청명단`;
    const data = [
      [
        "*입금은행",
        "*입금계좌",
        "고객관리성명",
        "*입금액",
        "출금통장표시내용",
        "입금통장표시내용",
        "입금인코드",
        "비고",
        "업체사용key",
      ],
      ...filteredTransactions.map((item) => [
        item.bankCode,
        item.accountNumber,
        item.accountOwner,
        item.amount,
        item.accountOwner,
        item.depositDisplayName,
        "",
        "",
        "",
      ]),
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);

    // 마지막으로 wb에 이제 내가 만든 worksheet과 sheet name을 정해야 하는데,
    // sheet name은 영어를 사용. (만약 엑셀에 데이터가 안나오면 sheet name을 'data'로 변경해서 체크).
    const wb = {
      Sheets: { data: ws },
      SheetNames: ["data"],
    };

    // 여기서부턴 실제 엑셀파일 준비하는 곳
    const excelButter = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    // 여기에 1초의 간격을 두어서 혹시 데이터가 다 안 받아진 상태에서 엑셀이 준비되는걸 방지
    // 사실 이 아래 await new Promise... 이 코드는 그냥 safe를 위한거지 없어도 상관x
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // 파일세이버를 이용해 파일을 생성
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
  };

  const calculateStatus = (data) => {
    let request = 0;
    let complete = 0;
    let error = 0;
    data.forEach((item) => {
      switch (item.status) {
        case 1:
          request++;
          break;
        case 2:
          complete++;
          break;
        case -1:
          error++;
          break;
        default:
          break;
      }
    });
    setTransactionStatusCounts([request, complete, error]);
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await axiosPrivate.get(`/cash/transaction`);
      setTransactions(response.data.data);
      setFilteredTransactions(response.data.data);
      calculateStatus(response.data.data);
      setIsLoading(false);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (!selectBoxRef.current) {
      return;
    }
    if (!openDropDown) {
      selectBoxRef.current.style = "border: 1px solid #BBB;";
    } else {
      selectBoxRef.current.style =
        "border: 1px solid #BBB; border-bottom: none;";
    }
  }, [openDropDown]);

  useEffect(() => {
    if (month === "전체 기간" || !month) {
      setFilteredTransactions(transactions);
      calculateStatus(transactions);
      return;
    }
    const data = transactions.filter(
      (item) =>
        new Date(item.createdAt) >=
          new Date(YEAR, Number(month.slice(0, -1)) - 2, DUE_DATE) &&
        new Date(item.createdAt) <
          new Date(YEAR, Number(month.slice(0, -1)) - 1, DUE_DATE)
    );
    setFilteredTransactions(data);
    calculateStatus(data);
  }, [month]);
  if (isLoading) {
    return null;
  }
  return (
    <Container>
      <Heading1 style={{ marginBottom: "0.38rem" }}>리워드 환급 현황</Heading1>
      <Heading2 style={{ marginBottom: "2.12rem" }}>
        캐시 리워드 환급 신청 및 지급 현황을 살펴볼 수 있어요
      </Heading2>

      <CardLayout>
        <FlexRow
          style={{ marginBottom: "1.62rem", justifyContent: "space-between" }}
        >
          <FlexRow style={{ gap: "1.25rem", alignItems: "center" }}>
            <FlexColumn style={{ position: "relative" }}>
              <SelectBox
                ref={selectBoxRef}
                onChange={(e) => setMonth(e.target.value)}
                value={month}
                onClick={handleDropdownClick}
              >
                {month ? month : "월 선택"}
                <ArrowDownSvg style={{ marginLeft: "1rem" }} />
              </SelectBox>
              {openDropDown && (
                <Dropdown
                  setItem={setMonth}
                  close={setOpenDropDown}
                  items={[
                    "12월",
                    "11월",
                    "10월",
                    "9월",
                    "8월",
                    "7월",
                    "6월",
                    "5월",
                    "4월",
                    "3월",
                    "2월",
                    "1월",
                    "전체 기간",
                  ]}
                />
              )}
            </FlexColumn>
            <FlexRow style={{ gap: "0.5rem" }}>
              {STATUS.map((item, index) => (
                <Badge
                  key={index}
                  style={{ color: item.color, background: item.background }}
                  onClick={(e) => handleBadgeClick(e, item)}
                >
                  {item.name} {transactionStatusCounts[index]}
                </Badge>
              ))}
            </FlexRow>
          </FlexRow>
          <FlexRow>
            <TableButton onClick={handleDownloadClick}>
              시트 다운로드
            </TableButton>
          </FlexRow>
        </FlexRow>
        <ListContainer>
          <ColumnHeader>
            <ColumnItem style={{ flex: 1 }}>번호</ColumnItem>
            <ColumnItem style={{ flex: 1 }}>신청일</ColumnItem>
            <ColumnItem style={{ flex: 1 }}>지급일</ColumnItem>
            <ColumnItem style={{ flex: 1 }}>입금 은행</ColumnItem>
            <ColumnItem style={{ flex: 1 }}>계좌번호</ColumnItem>
            <ColumnItem style={{ flex: 1 }}>예금주</ColumnItem>
            <ColumnItem style={{ flex: 1 }}>지급 금액</ColumnItem>
            <ColumnItem style={{ flex: 1 }}>지급 상태</ColumnItem>
          </ColumnHeader>
          {filteredTransactions.map((item, index) => {
            const status = STATUS.find((s) => s.status === item.status);
            return (
              <Row key={index}>
                <RowItem style={{ flex: 1 }}>{index + 1}</RowItem>
                <RowItem style={{ flex: 1 }}>{getDate(item.createdAt)}</RowItem>
                <RowItem style={{ flex: 1 }}>
                  {item.completedAt ? getDate(item.completedAt) : "-"}
                </RowItem>
                <RowItem style={{ flex: 1 }}>{item.bankName}</RowItem>
                <RowItem style={{ flex: 1 }}>{item.accountNumber}</RowItem>
                <RowItem style={{ flex: 1 }}>{item.accountOwner}</RowItem>
                <RowItem style={{ flex: 1 }}>
                  {item.amount.toLocaleString()}원
                </RowItem>
                <RowItem style={{ flex: 1 }}>
                  <Badge
                    style={{
                      color: status.color,
                      background: status.background,
                    }}
                  >
                    {status.name}
                  </Badge>
                </RowItem>
              </Row>
            );
          })}
        </ListContainer>
      </CardLayout>
    </Container>
  );
};

export default WithdrawManagement;
