import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FlexRow from "../../components/FlexRow";
import SubmitButton from "../../components/SubmitButton";
import Confetti from "../block/components/Confetti";
import Card from "./components/Card";

const Heading1 = styled.h1`
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  margin-bottom: 2.37rem;
  margin-top: 4.37rem;
`;

const Text1 = styled.p`
  text-align: center;
  font-weight: 600;
  line-height: 180%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 0.31rem;
`;

const Text2 = styled.p`
  text-align: center;
  font-size: 0.9375rem;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  margin-top: 1.5rem;
  margin-bottom: 1.63rem;
`;

const AdminSignupSuccess = () => {
  const navigate = useNavigate();

  const handleSubmitClick = () => {
    navigate("/manager/dashboard");
  };
  return (
    <Card>
      <Heading1>김블룸 관리자님!</Heading1>
      <Text1>
        맘핏 서비스 가입 절차가 모두 완료 되었어요.
        <br />
        이제부터 <span style={{ color: "#00DBB1" }}>블룸컴퍼니</span>의 마음
        관리가 시작됩니다 💪
      </Text1>
      <FlexRow style={{ justifyContent: "center" }}>
        <Confetti />
      </FlexRow>
      <Text2>
        지금 바로 아래 버튼을 눌러
        <br />
        관리자 페이지의 기능을 살펴보세요 👀
      </Text2>
      <SubmitButton
        value={"관리자 페이지 바로가기"}
        style={{ marginBottom: "3.94rem" }}
        onClick={handleSubmitClick}
      />
    </Card>
  );
};

export default AdminSignupSuccess;
