import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth, persist, setPersist } = useAuth();

  useEffect(() => {
    // console.log("Persist login - useEffect", persist, auth);
    let isMounted = true;

    const verifyRefreshToken = async () => {
      // console.log("verify refresh token!!");
      try {
        await refresh();
      } catch (err) {
        console.error(err);
        // When refresh token not valid
        localStorage.setItem("persist", false);
        setPersist(false);
      } finally {
        isMounted && setIsLoading(false);
      }
    };
    // console.log("persist login", !auth?.accessToken && persist);
    !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);

    return () => {
      isMounted = false;
    };
  }, []);

  return <>{!persist ? <Outlet /> : isLoading ? null : <Outlet />}</>;
};

export default PersistLogin;
