import styled from "styled-components";

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.grayScaleColor.gray30};
  margin-top: 1rem;
  margin-bottom: 2.25rem;
`;

const ReportSeparator = ({ style }) => {
  return <Line style={style} />;
};

export default ReportSeparator;
