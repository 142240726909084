import { useEffect, useState } from "react";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Heading1 from "./components/Heading1";
import Heading2 from "./components/Heading2";
import Heading3 from "./components/Heading3";
import ReportSummaryCallout from "./components/ReportSummaryCallout";
import ReportCallout from "./components/ReportCallout";
import ReportHeading1 from "./components/ReportHeading1";
import ReportHeading2 from "./components/ReportHeading2";
import ReportSeparator from "./components/ReportSeparator";
import FlexRow from "../../../components/FlexRow";
import ReportTable from "./components/ReportTable";
import ReportBarChart from "./components/ReportBarChart";
import useAuth from "../../../hooks/useAuth";
import ReportRadarChart from "./components/ReportRadarChart";
import {
  getDate,
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from "../../../utils/date";
import isEndWithConsonant from "../../../utils/isEndWithConsonant";
import ReportComment from "./components/ReportComment";
import { useSearchParams } from "react-router-dom";
import ReportListTable from "./components/ReportListTable";
import ReportHorizontalBarList from "./components/ReportHorizontalBarList";
import ReportBetaLogo from "./components/ReportBetaLogo";
import Button from "./components/Button";
import ReportBadge from "./components/ReportBadge";
import HighlightTable from "./components/HighlightTable";
import { HighlightChart } from "./components/HighlightChart";
import { useMediaQuery } from "react-responsive";
import { MOBILE, PERMAVAC } from "../../../Constants";
import MaumSentenceHorizontalBarList from "./components/MaumSentenceHorizontalBarList";
import WellBeingCompareList from "./components/WellBeingCompareList";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3.13rem;
  padding-bottom: 4.63rem;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 62.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SummaryContainer = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.grayScaleColor.white};

  border-top: 10px solid ${({ theme }) => theme.primary.normal};
  display: flex;
  justify-content: space-between;
  padding: 30px 60px;
  margin-top: 3.94rem;
  gap: 4rem;
  @media (max-width: ${MOBILE}) {
    flex-direction: column;
    padding: 1.56rem 1.37rem;
    gap: 1.25rem;
  }
`;

const SummaryItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  &:before {
    content: "";
    border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
    align-self: stretch;
  }
  &:nth-child(1) {
    &:before {
      content: "";
      border: none;
    }
  }
  @media (max-width: ${MOBILE}) {
    gap: 0;
    justify-content: flex-start;
    &:before {
      content: "";
      border: none;
      align-self: stretch;
    }
  }
`;

const SummaryIcon = styled.img`
  width: 2.25rem;
  height: 2.25rem;
  margin-left: 1rem;
  @media (max-width: ${MOBILE}) {
    margin-left: 0;
    margin-right: 1.25rem;
  }
`;

const SummaryLabel = styled.span`
  font-size: 0.88rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  line-height: 1.38rem;
  @media (max-width: ${MOBILE}) {
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 160%;
  }
`;

const SummaryText = styled.span`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 2rem;
  font-weight: 600;
  @media (max-width: ${MOBILE}) {
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 160%;
  }
`;

const ReportContainer = styled.section`
  width: 100%;
  padding: 3rem 3.13rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  margin-top: 2rem;
  @media (max-width: ${MOBILE}) {
    padding: 3rem 1.25rem;
  }
`;

const ReportSection = styled.div`
  margin-top: 4rem;
  @media (max-width: ${MOBILE}) {
    margin-top: 2.5rem;
  }
`;

const Bold = styled.span`
  font-weight: 600;
`;

const HighlightContainer = styled.div`
  padding: 3.19rem 2.31rem;
  border-radius: 0.9375rem;
  background: #fbfbfb;
  @media (max-width: ${MOBILE}) {
    padding: 2.88rem 0.69rem;
  }
`;

const PersonalReport = () => {
  const [searchParams] = useSearchParams();
  const { auth } = useAuth();
  const name = auth.user.name;
  const company = auth.user.companyId.name;
  const axiosPrivate = useAxiosPrivate();

  const date = new Date();
  const year =
    (searchParams.get("year") && Number(searchParams.get("year"))) || 2023;
  const month = searchParams.get("month")
    ? Number(searchParams.get("month")) - 1
    : date.getMonth();

  const type = searchParams.get("type");

  const startOfMonth = getFirstDayOfMonth(month);
  const endOfMonth = getLastDayOfMonth(month);

  const [isLoading, setIsLoading] = useState(true);
  const [blocks, setBlocks] = useState([]);
  const [checkupCount, setCheckupCount] = useState(0);
  const [surveyCount, setSurveyCount] = useState(0);
  const [myWellBeingScore, setMyWellBeingScore] = useState(0);
  const [myPermavac, setMyPermavac] = useState([]);
  const [sortedMyPermavac, setSortedMyPermavac] = useState([]);
  const [myFeeling, setMyFeeling] = useState([]);
  const [highlights, setHighlights] = useState([]);
  const [sortedMyFeeling, setSortedMyFeeling] = useState([]);
  const [companyWellBeingScore, setCompanyWellBeingScore] = useState(0);
  const [companyPermavac, setCompanyPermavac] = useState([]);
  const [sortedCompanyPermavac, setSortedCompanyPermavac] = useState([]);
  const [companyFeeling, setCompanyFeeling] = useState([]);
  const [sortedCompanyFeeling, setSortedCompanyFeeling] = useState([]);
  const [myPrevPermavacResult, setMyPrevPermavacResult] = useState([]);
  const [period, setPeriod] = useState({
    start: getFirstDayOfMonth(month),
    end: getLastDayOfMonth(month),
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE})`,
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await axiosPrivate.get(
        type === "all"
          ? `/checkup-v2/report?type=all`
          : `/checkup-v2/report?year=${year}&month=${searchParams.get("month")}`
      );
      setBlocks(response.data.blocks);
      setCheckupCount(response.data.checkupCount);
      setSurveyCount(response.data.surveyCount);
      setMyWellBeingScore(response.data.myWellBeingScore);
      setHighlights(response.data.highlights);
      setMyPermavac(
        response.data.myPermavacResult.length > 0
          ? response.data.myPermavacResult
          : new Array(9).fill({ value: 0 })
      );
      setMyFeeling(response.data.myFeelingResult);
      const sortedPermavac = [...response.data.myPermavacResult];
      setSortedMyPermavac(sortedPermavac.sort((a, b) => b.value - a.value));
      const sortedFeeling = [...response.data.myFeelingResult];
      setSortedMyFeeling(sortedFeeling.sort((a, b) => b[1] - a[1]));
      setPeriod({
        start: getDate(
          type === "all" ? response.data.start : response.data.startOfMonth,
          type === "all" ? "YYYY.M.D" : "M.D(ddd)"
        ),
        end: getDate(
          type === "all" ? response.data.end : response.data.endOfMonth,
          type === "all" ? "YYYY.M.D" : "M.D(ddd)"
        ),
      });
      // 나의 마음 분포 순서대로 조직 마음 분포 순서 변경
      if (response.data.myFeelingResult.length > 0) {
        const sorted = [];
        for (let i = 0; i < sortedFeeling.length; i++) {
          for (let j = 0; j < response.data.companyFeelingResult.length; j++) {
            if (
              sortedFeeling[i][0] === response.data.companyFeelingResult[j][0]
            ) {
              sorted.push(response.data.companyFeelingResult[j]);
            }
          }
        }
        setSortedCompanyFeeling(sorted);
      } else {
        const sorted = [...response.data.companyFeelingResult];
        setSortedCompanyFeeling(sorted.sort((a, b) => b[1] - a[1]));
      }
      // console.log(sortedPermavac);
      // 나의 마음 분포 순서대로 조직 마음 분포 순서 변경
      if (response.data.myPermavacResult.length > 0) {
        const sorted = [];
        for (let i = 0; i < sortedPermavac.length; i++) {
          for (let j = 0; j < response.data.companyPermavacResult.length; j++) {
            if (
              sortedPermavac[i].type ===
              response.data.companyPermavacResult[j].type
            ) {
              sorted.push(response.data.companyPermavacResult[j]);
            }
          }
        }

        setSortedCompanyPermavac(sorted);
      } else {
        const sorted = [...response.data.companyPermavacResult];
        setSortedCompanyPermavac(sorted.sort((a, b) => b[1] - a[1]));
      }
      // console.log(response.data.myPrevPermavacResult);
      setCompanyWellBeingScore(response.data.companyWellBeingScore);
      setCompanyPermavac(response.data.companyPermavacResult);
      setCompanyFeeling(response.data.companyFeelingResult);
      setMyPrevPermavacResult(
        response.data.myPrevPermavacResult.length > 0
          ? response.data.myPrevPermavacResult
          : new Array(9).fill({ value: 0 })
      );
      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <Container>데이터 불러오는 중...</Container>;
  }

  return (
    <Container>
      <Wrapper>
        {type !== "all" && (
          <FlexRow>
            <Heading2 style={{ marginBottom: isMobile ? "0.25rem" : "0.5rem" }}>
              월간 개인 리포트
            </Heading2>
            <ReportBetaLogo />
          </FlexRow>
        )}
        <Heading1 style={{ marginBottom: isMobile ? "0.37rem" : "0.5rem" }}>
          {type !== "all"
            ? `${year}년 ${month + 1}월 마음 리포트`
            : "전체 기간 마음 리포트"}
        </Heading1>

        <Heading3>
          {period.start} ~ {period.end}
        </Heading3>

        {isMobile ? (
          <SummaryContainer>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_user_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>이름</SummaryLabel>
                <SummaryText>{name}</SummaryText>
              </FlexColumn>
            </SummaryItem>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_calendar_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>기간</SummaryLabel>
                <FlexColumn>
                  <SummaryLabel>기간</SummaryLabel>
                  {type === "all" ? (
                    <FlexColumn>
                      <SummaryText>{period.start}~</SummaryText>
                      <SummaryText>{period.end}</SummaryText>
                    </FlexColumn>
                  ) : (
                    <SummaryText>{`${year}년 ${month + 1}월`}</SummaryText>
                  )}
                </FlexColumn>
              </FlexColumn>
            </SummaryItem>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_check_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>마음 체크업</SummaryLabel>
                <SummaryText>{checkupCount}회</SummaryText>
              </FlexColumn>
            </SummaryItem>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_heart_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>마음 트레이닝</SummaryLabel>
                <SummaryText>{blocks.length}회</SummaryText>
              </FlexColumn>
            </SummaryItem>
          </SummaryContainer>
        ) : (
          <SummaryContainer>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_user_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>이름</SummaryLabel>
                <SummaryText>{name}</SummaryText>
              </FlexColumn>
            </SummaryItem>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_calendar_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>기간</SummaryLabel>
                {type === "all" ? (
                  <FlexColumn>
                    <SummaryText>{period.start}~</SummaryText>
                    <SummaryText>{period.end}</SummaryText>
                  </FlexColumn>
                ) : (
                  <SummaryText>{`${year}년 ${month + 1}월`}</SummaryText>
                )}
              </FlexColumn>
            </SummaryItem>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_check_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>마음 체크업</SummaryLabel>
                <SummaryText>{checkupCount}회</SummaryText>
              </FlexColumn>
            </SummaryItem>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_heart_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>마음 트레이닝</SummaryLabel>
                <SummaryText>{blocks.length}회</SummaryText>
              </FlexColumn>
            </SummaryItem>
          </SummaryContainer>
        )}
        <ReportContainer>
          <ReportHeading2 style={{ marginBottom: "1rem" }}>
            {type !== "all"
              ? `📅 한 달 동안 ${name} 님은`
              : `📅 전체 기간 동안 ${name} 님은`}
          </ReportHeading2>
          <ReportSummaryCallout>
            <Bold>
              {sortedMyFeeling.length > 1 &&
              sortedMyFeeling[0][1] === sortedMyFeeling[1][1]
                ? `${sortedMyFeeling[0][0]}(외 ${
                    sortedMyFeeling.filter(
                      (item) => item[1] === sortedMyFeeling[0][1]
                    ).length - 1
                  }개)`
                : `${sortedMyFeeling[0][0]}`}
            </Bold>
            {isEndWithConsonant(sortedMyFeeling[0][0]) ? "을" : "를"} 가장 많이
            경험했습니다. 평균 웰빙 지수는 <Bold>{myWellBeingScore}점</Bold>
            으로, {company} 구성원 평균과{" "}
            {myWellBeingScore > companyWellBeingScore
              ? "비교해 조금 높은 편에 속합니다."
              : myWellBeingScore < companyWellBeingScore
              ? "비교해 조금 낮은 편에 속합니다."
              : "동일합니다."}{" "}
            9가지 웰빙 영역 중 가장 높았던 영역은{" "}
            <Bold>{sortedMyPermavac[0]?.type}</Bold>, 가장 낮았던 영역은{" "}
            <Bold>{sortedMyPermavac[sortedMyPermavac.length - 1]?.type}</Bold>{" "}
            입니다.
          </ReportSummaryCallout>
          <ReportSection>
            <ReportHeading1 style={{ color: "#00DBB1" }}>01</ReportHeading1>
            <ReportHeading1>마음 체크업</ReportHeading1>
            <ReportSeparator style={{ marginBottom: isMobile && "0.94rem" }} />
            <ReportCallout
              title={"내 대표 마음 문장"}
              value={
                sortedMyFeeling.length > 1 &&
                sortedMyFeeling[0][1] === sortedMyFeeling[1][1]
                  ? `${sortedMyFeeling[0][0]}(외 ${
                      sortedMyFeeling.filter(
                        (item) => item[1] === sortedMyFeeling[0][1]
                      ).length - 1
                    }개)`
                  : `${sortedMyFeeling[0][0]}`
              }
              style={{ marginBottom: isMobile ? "1.37rem" : "2.5rem" }}
            />
            {isMobile ? (
              <MaumSentenceHorizontalBarList
                legends={["나", `${company}`]}
                labels={myFeeling.map((item) => item[0])}
                data={[
                  myFeeling.map((item) => item[1]),
                  companyFeeling.map((item) => item[1]),
                ]}
                checkupCount={surveyCount}
              />
            ) : (
              <div>
                <ReportBarChart
                  scale={surveyCount || 4}
                  labels={myFeeling.map((item) => item[0])}
                  results={[
                    myFeeling.map((item) => item[1]),
                    companyFeeling.map((item) => item[1]),
                  ]}
                  legend={"나"}
                  compare={`${company}`}
                />
              </div>
            )}
            {isMobile && (
              <ReportSeparator
                style={{
                  marginTop: "3.69rem",
                  marginBottom: "2.44rem",
                  opacity: 0.3,
                }}
              />
            )}
            {isMobile ? (
              <FlexColumn style={{ marginBottom: "1.5rem" }}>
                <ReportHeading2 style={{ width: "100%", marginBottom: "2rem" }}>
                  {company} 구성원과 나의 마음 분포 비교
                </ReportHeading2>
                <ReportListTable
                  colHeader={["순위", "마음 문장", "빈도", "우리 조직 빈도"]}
                  rows={sortedMyFeeling.map((item, index) => [
                    index + 1,
                    item[0],
                    `${item[1]}회`,
                    `${sortedCompanyFeeling[index][1]}회`,
                    // `${
                    //   surveyCount > 0
                    //     ? ((item[1] / surveyCount) * 100).toFixed(1)
                    //     : 0
                    // }%`,
                    // `${
                    //   surveyCount > 0
                    //     ? (
                    //         (sortedCompanyFeeling[index][1] / surveyCount) *
                    //         100
                    //       ).toFixed(1)
                    //     : 0
                    // }%`,
                  ])}
                  flex={[1, 2, 1, 1.5]}
                  // hoverAt={0}
                  rowData={sortedMyFeeling}
                ></ReportListTable>
              </FlexColumn>
            ) : (
              <FlexRow
                style={{
                  gap: "55px",
                  marginTop: "4.25rem",
                  marginBottom: "1.5rem",
                }}
              >
                <ReportHeading2>
                  {company} 구성원과
                  <br />
                  나의 마음 분포 비교
                </ReportHeading2>
                <ReportListTable
                  colHeader={["순위", "마음 문장", "빈도", "우리 조직 빈도"]}
                  rows={sortedMyFeeling.map((item, index) => [
                    index + 1,
                    item[0],
                    `${item[1]}회`,
                    `${sortedCompanyFeeling[index][1]}회`,
                    // `${
                    //   surveyCount > 0
                    //     ? ((item[1] / surveyCount) * 100).toFixed(1)
                    //     : 0
                    // }%`,
                    // `${
                    //   surveyCount > 0
                    //     ? (
                    //         (sortedCompanyFeeling[index][1] / surveyCount) *
                    //         100
                    //       ).toFixed(1)
                    //     : 0
                    // }%`,
                  ])}
                  flex={[1, 2, 1, 1]}
                  hoverAt={1}
                  rowData={sortedMyFeeling}
                ></ReportListTable>
                {/* <ReportTable
                colHeader={["순위", "마음 상태", "빈도", "분포", "주변 평균"]}
                rows={sortedMyFeeling.map((item, index) => [
                  index + 1,
                  item[0],
                  `${item[1]}회`,
                  `${((item[1] / surveyCount) * 100).toFixed(1)}%`,
                  `${(
                    (sortedCompanyFeeling[index][1] / surveyCount) *
                    100
                  ).toFixed(1)}%`,
                ])}
                // diff={sortedMyFeeling.map(
                //   (item, index) => item[1] - sortedCompanyFeeling[index][1]
                // )}
                // diffDirection={"column"}
                // diffAt={2}
              /> */}
              </FlexRow>
            )}
            <ReportComment style={{ marginBottom: "3.37rem" }}>
              {/* * 마음 분포는 해당 마음에 대한 문장을 선택한 비율입니다.
              (복수선택) */}
            </ReportComment>
            <ReportCallout
              title={"마음 웰빙 지수"}
              value={`${myWellBeingScore}`}
              style={{ marginBottom: "2.38rem" }}
              diff={Number(
                (myWellBeingScore - companyWellBeingScore).toFixed(1)
              )}
            />
            <ReportRadarChart
              me={myPermavac.map((item) => item.value)}
              company={companyPermavac.map((item) => item.value)}
              legend={"나"}
              compare={company}
            />
            {isMobile && (
              <ReportSeparator
                style={{
                  marginTop: "3.69rem",
                  marginBottom: "2.44rem",
                  opacity: 0.3,
                }}
              />
            )}
            {isMobile ? (
              <FlexColumn>
                {" "}
                <ReportHeading2 style={{ width: "100%" }}>
                  {company} 구성원과 타 그룹 마음 웰빙 비교
                  <ReportComment
                    style={{ fontWeight: 400, marginTop: "0.56rem" }}
                  >
                    * 7점 척도 기준
                  </ReportComment>
                </ReportHeading2>
                <ReportHorizontalBarList
                  rows={sortedMyPermavac}
                  diff={sortedCompanyPermavac}
                  showBadge={true}
                />
              </FlexColumn>
            ) : (
              <FlexRow
                style={{
                  gap: "55px",
                  marginTop: "4.25rem",
                  marginBottom: "1.5rem",
                }}
              >
                <ReportHeading2 style={{ width: "12.38rem" }}>
                  <p>
                    {company} 구성원과
                    <br />
                    나의 마음 웰빙 비교
                  </p>
                  <ReportComment
                    style={{ fontWeight: 400, marginTop: "0.56rem" }}
                  >
                    * 7점 척도 기준
                  </ReportComment>
                </ReportHeading2>
                <ReportHorizontalBarList
                  rows={sortedMyPermavac}
                  diff={sortedCompanyPermavac}
                />
              </FlexRow>
            )}
            {isMobile ? (
              <ReportComment
                style={{
                  marginTop: "1rem",
                  marginBottom: isMobile ? "2rem" : "3.37rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  color: "#3A3F3F",
                  fontSize: "0.56rem",
                  gap: "0.26rem",
                }}
              >
                <p style={{ fontSize: "0.56rem" }}>
                  * 전체 평균과 내 점수의 차이
                </p>
                <FlexRow style={{ alignItems: "center" }}>
                  +20% 이상
                  <ReportBadge
                    style={{
                      fontSize: "0.4rem",
                      padding: "0.1815rem 0.5445rem",
                      marginLeft: "0.25rem",
                      marginRight: "0.5rem",
                      display: "flex",
                      height: "1.3rem",
                      alignItems: "center",
                    }}
                    level={"good"}
                  >
                    대단해요
                  </ReportBadge>{" "}
                  ±20% 범위 내{" "}
                  <ReportBadge
                    style={{
                      fontSize: "0.4rem",
                      marginLeft: "0.25rem",
                      marginRight: "0.5rem",
                      padding: "0.1815rem 0.5445rem",
                      display: "flex",
                      height: "1.3rem",
                      alignItems: "center",
                    }}
                    level={"ok"}
                  >
                    괜찮아요
                  </ReportBadge>
                  -20% 미만{" "}
                  <ReportBadge
                    style={{
                      fontSize: "0.4rem",
                      marginLeft: "0.25rem",
                      padding: "0.1815rem 0.5445rem",
                      display: "flex",
                      height: "1.3rem",
                      alignItems: "center",
                    }}
                    level={"bad"}
                  >
                    걱정돼요
                  </ReportBadge>
                </FlexRow>
              </ReportComment>
            ) : (
              <ReportComment
                style={{
                  marginBottom: "3.37rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  color: "#3A3F3F",
                }}
              >
                * 우리 조직 평균과 내 점수의 차이: +20% 초과{" "}
                <ReportBadge
                  style={{
                    fontSize: "0.63rem",
                    padding: "0.1815rem 0.5445rem",
                    marginLeft: "0.25rem",
                    marginRight: "0.5rem",
                    display: "flex",
                    height: "1.3rem",
                    alignItems: "center",
                  }}
                  level={"good"}
                >
                  대단해요
                </ReportBadge>{" "}
                ±20% 범위 내{" "}
                <ReportBadge
                  style={{
                    fontSize: "0.63rem",
                    marginLeft: "0.25rem",
                    marginRight: "0.5rem",
                    padding: "0.1815rem 0.5445rem",
                    display: "flex",
                    height: "1.3rem",
                    alignItems: "center",
                  }}
                  level={"ok"}
                >
                  괜찮아요
                </ReportBadge>
                -20% 미만{" "}
                <ReportBadge
                  style={{
                    fontSize: "0.63rem",
                    marginLeft: "0.25rem",
                    padding: "0.1815rem 0.5445rem",
                    display: "flex",
                    height: "1.3rem",
                    alignItems: "center",
                  }}
                  level={"bad"}
                >
                  걱정돼요
                </ReportBadge>
              </ReportComment>
            )}
            {type !== "all" && (
              <FlexColumn style={{ marginTop: "3.38rem" }}>
                <ReportHeading2 style={{ marginBottom: "2.13rem" }}>
                  이전 달과 이번 달
                  <br />
                  나의 마음 웰빙 비교
                </ReportHeading2>
                {isMobile ? (
                  <WellBeingCompareList
                    headers={[
                      "",
                      `${month === 0 ? 12 : month}월`,
                      `${month + 1}월`,
                      "비교",
                    ]}
                    rows={PERMAVAC.map((item, index) => [
                      item,
                      myPrevPermavacResult[index].value,
                      myPermavac[index].value,
                      parseFloat(
                        (
                          myPermavac[index].value -
                          myPrevPermavacResult[index].value
                        ).toFixed(1)
                      ),
                    ])}
                  />
                ) : (
                  <ReportTable
                    colHeader={[
                      "",
                      "몰입",
                      "자율성",
                      "유능감",
                      "성취",
                      "관계",
                      "긍정정서",
                      "의미",
                      "활력",
                      "행복",
                    ]}
                    rows={[
                      [
                        `${month === 0 ? 12 : month}월`,
                        ...myPrevPermavacResult.map((i) => i.value),
                      ],
                      [`${month + 1}월`, ...myPermavac.map((i) => i.value)],
                      [
                        "비교",
                        ...myPermavac.map((i, index) =>
                          Number(
                            (
                              i.value - myPrevPermavacResult[index].value
                            ).toFixed(1)
                          )
                        ),
                      ],
                    ]}
                    diffDirection={"row"}
                    diffAt={2}
                  />
                )}
              </FlexColumn>
            )}
            <FlexColumn style={{ marginTop: isMobile ? "2.88rem" : "5.81rem" }}>
              <ReportHeading2
                style={{ marginBottom: isMobile ? "1.5rem" : "1.88rem" }}
              >
                {type === "all"
                  ? "전체 기간 마음 하이라이트"
                  : "이번 달 마음 하이라이트"}
              </ReportHeading2>
              <HighlightContainer>
                <HighlightChart
                  dates={highlights.map((item) =>
                    getDate(item.endDate, "YY/M/D")
                  )}
                  result={highlights.map((item) => item.wellBeingScore)}
                  highlights={highlights.map((item) => {
                    return !item.highlight
                      ? "하이라이트 기록이 없어요"
                      : item.highlight;
                  })}
                />
                <div
                  style={{
                    width: "100%",
                    border: "1px dashed #D9D9D9",
                    marginTop: "3rem",
                    marginBottom: "3rem",
                  }}
                />
                <HighlightTable data={highlights} />
              </HighlightContainer>
            </FlexColumn>
          </ReportSection>
          <ReportSection>
            <ReportHeading1 style={{ color: "#00DBB1" }}>02</ReportHeading1>
            <ReportHeading1>마음 트레이닝</ReportHeading1>
            <ReportSeparator style={{ marginBottom: isMobile && "0.94rem" }} />
            <ReportCallout
              title={
                type === "all"
                  ? "나의 마음 트레이닝"
                  : `${month + 1}월 나의 마음 트레이닝`
              }
              value={`${blocks.length}회`}
              style={{ marginBottom: isMobile ? "1.62rem" : "3.75rem" }}
            />
            {/* <ReportTable
              colHeader={["날짜", "마음 트레이닝", "마음 기록"]}
              rows={blocks.map((block) => [
                getDate(block.createdAt),
                block.name,
                block.comment,
              ])}
            /> */}
            {isMobile ? (
              <FlexColumn>
                <ReportTable
                  colHeader={["날짜", "마음 트레이닝", "마음 기록"]}
                  rows={
                    blocks.length > 0
                      ? blocks
                          .reverse()
                          .map((block) => [
                            getDate(block.createdAt),
                            block.name,
                            block.comment,
                          ])
                      : []
                  }
                />
              </FlexColumn>
            ) : (
              <ReportListTable
                colHeader={
                  blocks.length > 0
                    ? ["날짜", "마음 트레이닝", "마음 기록"]
                    : [
                        "",
                        "😥 이번 달에 진행한 마음 트레이닝 기록이 없어요.",
                        "",
                      ]
                }
                rows={
                  blocks.length > 0
                    ? blocks
                        .reverse()
                        .map((block) => [
                          getDate(block.createdAt),
                          block.name,
                          block.comment,
                        ])
                    : []
                }
                flex={blocks.length > 0 ? [1, 1, 2] : [1, 2, 1]}
              ></ReportListTable>
            )}
          </ReportSection>

          <FlexRow style={{ justifyContent: "center", marginTop: "3.81rem" }}>
            <Button
              style={{ padding: "0.75rem 1.75rem", borderRadius: "10px" }}
              onClick={() =>
                window.open("https://zep.us/play/8ZwAV4", "_blank")
              }
            >
              {blocks.length > 0
                ? "마음 트레이닝 더 하러 가기"
                : "마음 트레이닝 하러 가기"}
            </Button>
          </FlexRow>
        </ReportContainer>
      </Wrapper>
    </Container>
  );
};

export default PersonalReport;
