import { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import axios from "../../../api/axios";
import { ROUTES } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import dataURLtoFile from "../../../utils/dataURLtoFile";
import includesPathname from "../../../utils/includesPathname";
import uploadSmilePhoto from "../api/uploadSmilePhoto";
import Button from "./Button";

const Container = styled.div`
  width: 100%;
  max-width: 335px;
  max-height: 100vh;
  height: auto;
  padding: 30px;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  position: relative;
  overflow: auto;
`;

const NameInput = styled.input`
  width: 100%;
  font-weight: 700;
  color: #111;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.03em;
  text-align: center;
  margin-bottom: 27px;
  outline: none;
  border-radius: 5px;
  ${({ theme, hasError }) =>
    hasError ? `border: 1px solid ${theme.colors.error};` : "border: none;"}
  &::placeholder {
    color: ${({ theme }) => theme.grayScaleColor.gray20};
  }
`;

const NameText = styled.h3`
  font-size: 1.38rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 700;
  line-height: 1.63rem;
  letter-spacing: -0.03em;
  text-align: center;
  margin-bottom: 1.69rem;
`;

const Image = styled.img`
  width: 100%;
  max-height: 350px;
  height: auto;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 20px;
`;

const ScoreText = styled.p`
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.75rem;
  text-align: center;
  color: ${({ theme }) => theme.primary.normal};
  font-family: "Montserrat";
`;

const PasswordInput = styled.input`
  width: 100%;
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.colors.error : theme.grayScaleColor.gray20};
  padding: 14px;
  padding-left: 17px;
  margin: 20px 0;
  font-size: 15px;
  border-radius: 5px;
  outline: none;
  &::placeholder {
    color: ${({ theme }) => theme.grayScaleColor.gray30};
  }
`;

const SubmitButton = styled.input`
  padding: 1.06rem 0;
  width: 100%;
  background: ${({ theme }) => theme.primary.normal};
  border-radius: 0.31rem;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 1.13rem;
  font-weight: 600;
  color: white;
  -webkit-appearance: none;
`;
const ClostButton = ({ onClick }) => (
  <div style={{ textAlign: "right" }}>
    <svg
      onClick={onClick}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.03033 0.96967C1.73744 0.676777 1.26256 0.676777 0.96967 0.96967C0.676777 1.26256 0.676777 1.73744 0.96967 2.03033L8.42499 9.48565L0.96991 16.9407C0.677017 17.2336 0.677017 17.7085 0.96991 18.0014C1.2628 18.2943 1.73768 18.2943 2.03057 18.0014L9.48565 10.5463L16.9407 18.0014C17.2336 18.2943 17.7085 18.2943 18.0014 18.0014C18.2943 17.7085 18.2943 17.2336 18.0014 16.9407L10.5463 9.48565L18.0016 2.03033C18.2945 1.73744 18.2945 1.26256 18.0016 0.96967C17.7087 0.676777 17.2339 0.676777 16.941 0.96967L9.48565 8.42499L2.03033 0.96967Z"
        fill="#BBBBBB"
      />
    </svg>
  </div>
);

const UploadPhotoDialog = ({
  imageUrl,
  fileName,
  cid,
  score,
  okHandler,
  closeHandler,
}) => {
  const { auth } = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const img = dataURLtoFile(imageUrl, fileName);
    const formData = new FormData();

    if (auth?.user && !includesPathname(ROUTES.GUEST_SMILE)) {
      formData.append("name", auth.user.nickname);
      formData.append("cid", auth.user.companyId._id);
      formData.append("uid", auth.user.id);
    } else {
      if (cid) {
        formData.append("cid", cid);
      }
      formData.append("name", data.name);
      formData.append("password", data.password);
    }
    formData.append("file", img);
    formData.append("score", score);
    formData.append("isPublic", true);

    await uploadSmilePhoto({
      auth: includesPathname(ROUTES.GUEST_SMILE) ? null : auth,
      formData,
    });
    okHandler();
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  // console.log(imageUrl);
  return (
    <Container>
      <ClostButton onClick={closeHandler} />
      <form onSubmit={handleSubmit(onSubmit)}>
        {!includesPathname(ROUTES.GUEST_SMILE) && auth?.user ? (
          <>
            <NameText>{auth.user.nickname}</NameText>
            <Image src={imageUrl} />
            <ScoreText style={{ marginBottom: "1.69rem" }}>
              {score}
              <span style={{ fontSize: "24px", lineHeight: "32px" }}>%</span>
            </ScoreText>
          </>
        ) : (
          <>
            <NameInput
              autoFocus={true}
              {...register("name", { required: true })}
              type={"text"}
              placeholder="닉네임을 입력해 주세요"
              hasError={errors.name ? true : false}
            />
            <Image src={imageUrl} />
            <ScoreText>
              {score}
              <span style={{ fontSize: "24px", lineHeight: "32px" }}>%</span>
            </ScoreText>
            <PasswordInput
              {...register("password", { required: true })}
              placeholder="🔒 비밀번호를 설정해 주세요"
              type="text"
              hasError={errors.password ? true : false}
            />
          </>
        )}
        <SubmitButton type="submit" value={"찐-미소 챔피언즈 올리기"} />
        {/* <Button text={"찐-미소 챔피언즈 올리기"} clickHandler={okHandler} /> */}
      </form>
    </Container>
  );
};

export default UploadPhotoDialog;
