import { useState } from "react";
import styled from "styled-components";
import axios from "../../api/axios";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import LabelInput from "../../components/LabelInput";
import SubmitButton from "../../components/SubmitButton";
import Card from "./components/Card";

const Heading1 = styled.h1`
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  margin-bottom: 2.37rem;
  margin-top: 4.37rem;
`;

const Text1 = styled.p`
  text-align: center;
  font-weight: 600;
  line-height: 180%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 1.31rem;
`;

const Text2 = styled.p`
  text-align: center;
  font-size: 0.9375rem;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  margin-bottom: 4.31rem;
`;

const CheckboxContainer = styled.div`
  margin-top: 2.94rem;
  margin-bottom: 2.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const CheckboxText = styled.p`
  font-size: 0.875rem;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-left: 0.62rem;
`;

const Divider = styled.div`
  width: 100%;
  height: 0.0625rem;
  background: #d9d9d9;
  margin: 0.31rem 0;
`;
const EnterEmail = () => {
  const ALL = "all";
  const AGE = "age";
  const USAGE = "usage";
  const PRIVACY = "privacy";
  const [email, setEmail] = useState("");
  const [checks, setChecks] = useState({ [ALL]: false });
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleCheckboxClick = (e, key) => {
    if (key === ALL && checks[ALL] === false) {
      setChecks({
        [ALL]: true,
        [AGE]: true,
        [USAGE]: true,
        [PRIVACY]: true,
      });

      return;
    }
    if (
      (key === AGE && checks[AGE]) ||
      (key === USAGE && checks[USAGE]) ||
      (key === PRIVACY && checks[PRIVACY])
    ) {
      setChecks((prev) => ({
        ...prev,
        [ALL]: false,
        [key]: !prev[key],
      }));
      return;
    }
    // if (checks[AGE] && checks[USAGE] && checks[PRIVACY]) {
    //   console.log("hi");
    //   setChecks((prev) => ({
    //     ...prev,
    //     [ALL]: true,
    //   }));
    //   return;
    // }
    setChecks((prev) => ({
      ...prev,
      [key]: prev[key] ? !prev[key] : true,
    }));
  };

  const handleOnClick = async () => {
    const response = await axios.post("/signup-verify", { email });

    if (!response.data.ok) {
      return alert(response.data.msg);
    }
    // alert(`${response.data.data.link}에서 회원가입을 진행하세요`);
    setIsEmailSent(true);
  };

  const isDisable = () => {
    if (email.length === 0) {
      return true;
    }

    if (!checks[AGE] || !checks[USAGE] || !checks[PRIVACY]) {
      return true;
    }

    return false;
  };

  if (isEmailSent) {
    return (
      <Card>
        <Heading1>🚀 발송 완료!</Heading1>
        <Text1>
          {email} 로 인증 메일을 보냈어요.
          <br />
          혹시 이메일이 오지 않았다면 스팸함도 확인해 주세요!
        </Text1>
        <Text2>
          ⏰ 3분이 경과해도 메일이 도착하지 않았나요?
          <br /> 아래 [다시 보내기] 버튼을 통해 한 번 더 보낼 수 있어요.
        </Text2>
        <FlexColumn style={{ gap: "1.25rem" }}>
          <Button text="다시 보내기" state="enabled" />
          <Button
            text="다른 주소로 메일 보내기"
            state="line"
            style={{ marginBottom: "3.56rem" }}
          />
        </FlexColumn>
      </Card>
    );
  }
  return (
    <Card>
      <Heading1>
        반갑습니다 관리자님 🙌
        <br />
        아이디로 활용하실 회사 이메일을 입력해 주세요
      </Heading1>
      <LabelInput
        htmlFor={"회사 이메일"}
        placeholder={"bloom@maum.fit"}
        onChange={setEmail}
      />

      <CheckboxContainer>
        <FlexRow style={{ alignItems: "center" }}>
          <Checkbox
            active={checks[ALL]}
            onClick={(e) => handleCheckboxClick(e, ALL)}
          />
          <CheckboxText>모두 동의합니다</CheckboxText>
        </FlexRow>
        <Divider />
        <FlexRow style={{ alignItems: "center" }}>
          <Checkbox
            active={checks[AGE]}
            onClick={(e) => handleCheckboxClick(e, AGE)}
          />
          <CheckboxText>[필수] 만 14세 이상입니다</CheckboxText>
        </FlexRow>
        <FlexRow style={{ alignItems: "center" }}>
          <Checkbox
            active={checks[USAGE]}
            onClick={(e) => handleCheckboxClick(e, USAGE)}
          />
          <CheckboxText>
            [필수]{" "}
            <span style={{ textDecoration: "underline" }}>
              서비스 이용 약관
            </span>{" "}
            동의
          </CheckboxText>
        </FlexRow>
        <FlexRow style={{ alignItems: "center" }}>
          <Checkbox
            active={checks[PRIVACY]}
            onClick={(e) => handleCheckboxClick(e, PRIVACY)}
          />
          <CheckboxText>
            [필수]{" "}
            <span style={{ textDecoration: "underline" }}>
              개인정보 수집 및 이용
            </span>{" "}
            동의
          </CheckboxText>
        </FlexRow>
      </CheckboxContainer>
      <SubmitButton
        style={{ marginBottom: "3.56rem" }}
        value={"메일 인증 요청하기"}
        disabled={isDisable()}
        onClick={handleOnClick}
      />
    </Card>
  );
};

export default EnterEmail;
