import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useAuth from "../hooks/useAuth";
import useLogout from "../hooks/useLogout";
import Loading from "./Loading";

const Container = styled.div`
  width: 100%;
  height: 60px;
  //   border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const Wrapper = styled.ul`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  //   border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1280px) {
    padding: 0 40px;
  }
`;

const Column = styled.div`
  display: flex;
  align-items: center;
`;
const Logo = styled.img`
  width: 40px;
  height: 40px;
`;

const LogoSpan = styled.span`
  margin-left: 10px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.6);
`;

const SLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: inherit;
`;

const Item = styled.li`
  margin: 0 10px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
`;

function Navbar() {
  const { persist } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const logout = useLogout();

  const handleLogoutClick = async () => {
    setIsLoading(true);
    await logout();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    // window.location.reload();
  };

  return (
    <Container>
      <Wrapper>
        <Column>
          <SLink to="/">
            <Logo src="/assets/logo.png" />
            <LogoSpan>Maum.Fit</LogoSpan>
          </SLink>
        </Column>
        <Column>
          <SLink to="/itree">
            <Item>아이트리</Item>
          </SLink>
          <SLink to="/duchenne-smile">
            <Item>뒤센 미소</Item>
          </SLink>
          <SLink to="/checkup">
            <Item>마음체크업</Item>
          </SLink>
          {!persist ? (
            <>
              <SLink to="/login">
                <Item>로그인</Item>
              </SLink>
              {/* <SLink to="/signup">
                <Item>회원가입</Item>
              </SLink> */}
            </>
          ) : (
            <>
              <SLink to="/dashboard">
                <Item>대시보드</Item>
              </SLink>
              <SLink to="/mypage">
                <Item>마이페이지</Item>
              </SLink>
              <Item onClick={handleLogoutClick}>로그아웃</Item>
            </>
          )}
        </Column>
      </Wrapper>
      {isLoading && <Loading />}
    </Container>
  );
}

export default Navbar;
