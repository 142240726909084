import styled, { keyframes } from "styled-components";

const fadeInAndOut = keyframes`
    0% {
        opacity: 0;
    }
    15% {
        opacity: 0.8;
    }
    85% {
        opacity: 0.8;
    }
    100% {
        opacity: 0;
    }
`;

const fadeOut = keyframes`
0% {
    opacity: 0.7;
}
100% {
    opacity: 0;
} 
`;
const Container = styled.div`
  padding: 0.31rem 0.94rem;
  background: ${({ theme, background }) =>
    background || theme.grayScaleColor.black};
  border-radius: 0.44rem;
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: 3rem;
  animation: ${({ visible }) => visible && fadeInAndOut} 2s ease-out;
`;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 0.75rem solid transparent;
  border-right: 0.75rem solid transparent;
  border-top: 0.75rem solid
    ${({ theme, background }) => background || theme.grayScaleColor.black};
  position: absolute;
  ${({ dir }) => (dir === "left" ? "left: 0.56rem" : "right: 0.56rem")};
`;

const Text = styled.p`
  font-size: 0.81rem;
  line-height: 1.44rem;
  letter-spacing: -0.04em;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.white};
`;
const Highlight = styled.span`
  color: ${({ theme }) => theme.primary.normal};
`;
const Bold = styled.span`
  font-weight: 600;
`;

const Tooltip = ({
  text,
  visible,
  style,
  background,
  dir = "right",
  children,
}) => {
  return (
    <Container visible={visible} style={style} background={background}>
      <Text>
        {children && children}
        {text}
        {/* <Bold>
          마음 포인트가 <Highlight>+1</Highlight> 누적 되었습니다
        </Bold>{" "}
        ☺️ */}
      </Text>
      <ArrowDown background={background} dir={dir} />
    </Container>
  );
};

export default Tooltip;
