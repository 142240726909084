import styled from "styled-components";
import FlexRow from "../../../components/FlexRow";

const Text = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%;
`;
const Image = styled.img``;

const StatusText = ({ status }) => {
  const STATUS = {
    INVITE: {
      name: "초대중",
      src: `${process.env.PUBLIC_URL}/svg/dashboard/status_invite_button.svg`,
      color: "#4ED020",
    },
    ACTIVE: {
      name: "사용중",
      src: `${process.env.PUBLIC_URL}/svg/dashboard/status_activate_button.svg`,
      color: "#017FC6",
    },
    INACTIVE: {
      name: "비활성화",
      src: `${process.env.PUBLIC_URL}/svg/dashboard/status_inactive_button.svg`,
      color: "#BBB",
    },
    SIGNUP: {
      name: "",
      src: "",
      color: "",
    },
  };
  return (
    <FlexRow style={{ alignItems: "center" }}>
      <Image src={STATUS[status].src} />
      <Text style={{ color: STATUS[status].color }}>{STATUS[status].name}</Text>
    </FlexRow>
  );
};
export default StatusText;
