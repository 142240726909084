import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import {
  MONTH_PRICE,
  PAYMENT_TYPE,
  TAX,
  YEAR_PRICE,
} from "../../../constants/payments";
import { getDate } from "../../../utils/date";
import CloseXButton from "../components/CloseXButton";
import ModalHeading2 from "../components/ModalHeading2";

const ModalButton = styled.button`
  border-radius: 0.4375rem;
  padding: 0.78rem 3.84rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  cursor: pointer;
  margin: 0 auto;
`;

const ConfirmButton = styled(ModalButton)`
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
`;

const KeyText = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 180%; /* 1.6875rem */
  letter-spacing: -0.03125rem;
  flex: 1;
`;

const ValueText = styled(KeyText)`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  flex: 2;
`;

const DetailText = styled.p`
  font-size: 0.875rem;
  line-height: 180%;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
`;

const Ul = styled.ul`
  margin-top: 2.25rem;
  border-top: 0.125rem solid #000;
`;

const Li = styled.li`
  display: flex;
  align-items: center;
  padding: 0.56rem 1.19rem;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
`;

const HeaderItem = styled.p`
  flex: 1;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 180%; /* 1.575rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  text-align: center;
`;

const Item = styled.p`
  flex: 1;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 180%; /* 1.575rem */
  letter-spacing: -0.03125rem;
  text-align: center;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const PaymentHistoryDetailModal = ({ order, closeHandler }) => {
  console.log(order);

  const onPrintRecieptClick = () => {
    {
      let receiptWin =
        "https://testadmin8.kcp.co.kr/assist/bill.BillActionNew.do?cmd=card_bill&tno=";
      receiptWin += order.tno + "&";
      receiptWin += "order_no=" + order.order_no + "&";
      receiptWin += "trade_mony=" + order.amount;

      window.open(receiptWin, "", "width=455, height=815");
    }
  };
  return (
    <>
      <FlexRow
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <ModalHeading2 text="결제 상세 내역" />
        <CloseXButton onClick={closeHandler} />
      </FlexRow>
      <FlexColumn style={{ marginTop: "2.69rem", gap: "1rem" }}>
        <FlexRow style={{ alignItems: "flex-start" }}>
          <KeyText>결제일</KeyText>
          <ValueText>{getDate(order.createdAt, "YYYY년 M월 D일")}</ValueText>
        </FlexRow>
        <FlexRow style={{ alignItems: "flex-start" }}>
          <KeyText>결제(주문) 번호</KeyText>
          <ValueText>{order.order_no}</ValueText>
        </FlexRow>
        <FlexRow style={{ alignItems: "flex-start" }}>
          <KeyText>결제 플랜</KeyText>
          <ValueText>맘핏</ValueText>
        </FlexRow>
        <FlexRow style={{ alignItems: "flex-start" }}>
          <KeyText>결제 주기</KeyText>
          <FlexColumn style={{ flex: 2 }}>
            <ValueText>
              {order.subscribePaymentId.planType === PAYMENT_TYPE.MONTH
                ? "[월 구독] 1개월"
                : "[연 구독] 1개월"}{" "}
            </ValueText>
            <DetailText>
              (이용 기간: {getDate(order.createdAt, "YYYY년 M월 D일")} ~{" "}
              {getDate(
                new Date(
                  new Date(order.subscribePaymentId.nextPayDate).getFullYear(),
                  new Date(order.subscribePaymentId.nextPayDate).getMonth(),
                  new Date(order.subscribePaymentId.nextPayDate).getDate() - 1
                ),
                "YYYY년 M월 D일"
              )}
              )
            </DetailText>
          </FlexColumn>
        </FlexRow>
        <FlexRow style={{ alignItems: "flex-start" }}>
          <KeyText>결제 카드</KeyText>
          <ValueText>
            {order.cardName}{" "}
            {order.cardNumber?.slice(0, 4) +
              " " +
              order.cardNumber?.slice(4, 8) +
              " **** " +
              order.cardNumber?.slice(12)}
          </ValueText>
        </FlexRow>
      </FlexColumn>
      <Ul>
        <Li>
          <HeaderItem>구분</HeaderItem>
          <HeaderItem>단가</HeaderItem>
          <HeaderItem>부가세(10%)</HeaderItem>
          <HeaderItem>수량</HeaderItem>
          <HeaderItem style={{ textAlign: "right" }}>금액</HeaderItem>
        </Li>
        <Li>
          <Item>맘핏</Item>
          <Item>
            {order.subscribePaymentId.planType === PAYMENT_TYPE.MONTH
              ? MONTH_PRICE.toLocaleString()
              : YEAR_PRICE.toLocaleString()}
            원
          </Item>
          <Item>
            {order.subscribePaymentId.planType === PAYMENT_TYPE.MONTH
              ? ((MONTH_PRICE * TAX) / 100).toLocaleString()
              : ((YEAR_PRICE * TAX) / 100).toLocaleString()}
            원
          </Item>
          <Item>{order.subscribePaymentId.users}개</Item>
          <Item style={{ textAlign: "right" }}>
            <Bold>{order.amount.toLocaleString()}원</Bold>
          </Item>
        </Li>
      </Ul>
      <FlexColumn
        style={{ alignItems: "flex-end", marginTop: "0.75rem", gap: "0.5rem" }}
      >
        <FlexRow style={{ width: "35%" }}>
          <Item style={{ flex: 1, textAlign: "left" }}>소계</Item>
          <Item style={{ flex: 2, textAlign: "right" }}>
            {order.amountWithoutTax.toLocaleString()}원
          </Item>
        </FlexRow>
        <FlexRow style={{ width: "35%" }}>
          <Item style={{ flex: 1, textAlign: "left" }}>부가세</Item>
          <Item style={{ flex: 2, textAlign: "right" }}>
            {((order.amountWithoutTax * TAX) / 100).toLocaleString()}원
          </Item>
        </FlexRow>
        <FlexRow style={{ width: "35%" }}>
          <Item
            style={{
              flex: 1,
              textAlign: "left",
              fontSize: "1.125rem",
              fontWeight: 600,
              lineHeight: "180%",
            }}
          >
            합계
          </Item>
          <Item
            style={{
              flex: 2,
              textAlign: "right",
              fontSize: "1.125rem",
              fontWeight: 600,
              lineHeight: "180%",
            }}
          >
            {order.amount.toLocaleString()}원
          </Item>
        </FlexRow>
      </FlexColumn>
      <FlexRow
        style={{
          marginTop: "2.44rem",
          justifyContent: "space-between",
          gap: "0.69rem",
        }}
      >
        <ConfirmButton onClick={onPrintRecieptClick}>영수증 출력</ConfirmButton>
      </FlexRow>
    </>
  );
};

export default PaymentHistoryDetailModal;
