import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import FlexRow from "../../../components/FlexRow";
import { TABLET_LANDSCAPE } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getDate, subtractDate } from "../../../utils/date";
import Card1 from "../Card1";
import Card2 from "../Card2";
import Card3 from "../Card3";
import Card4 from "../Card4";
import Card5 from "../Card5";
import Card6 from "../Card6";
import Card7 from "../Card7";
import Card8 from "../Card8";
import Card9 from "../Card9";
import CardLayout from "../CardLayout";
import Heading1 from "../Heading1";
import Heading2 from "../Heading2";
import NoDataText from "../components/NoDataText";

const Container = styled.div`
  margin-left: var(--sidebar-width);
  // height: calc(100% - 4.06rem);
  padding: 2.25rem 2.5rem;
  width: 100%;
  // border: 1px solid black;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    margin-left: var(--sidebar-tablet-landscape-width);
  }
`;

const Section = styled.section`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(4, 1fr);
  // grid-template-areas:
  //   "box1 box5 box6 box7"
  //   "box1 box5 box6 box7"
  //   "box2 box5 box6 box7"
  //   "box2 box5 box6 box7"
  //   "box3 box5 box6 box7"
  //   "box3 box5 box6 box8"
  //   "box3 box5 box6 box8";
  gap: 1rem;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(2, 1fr);
    // max-height: 2400px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.grayScaleColor.gray20};
  margin: 2.13rem 0;
`;

const PersonalDashboard = () => {
  const { auth } = useAuth();
  const CHART_TYPES = ["마음 웰빙 지수"];
  const MIN_WITHDRAW_AMOUNT = auth.user.isFirstWithdraw ? 5000 : 10000;
  const DEFAULT_SEARCH_START_DATE = new Date(
    new Date(auth.user.createdAt).getFullYear(),
    new Date(auth.user.createdAt).getMonth(),
    new Date(auth.user.createdAt).getDate()
  );

  const axiosPrivate = useAxiosPrivate();

  const signupPeriod = Math.round(
    (new Date().getTime() - new Date(auth.user.createdAt).getTime()) /
      (1000 * 60 * 60 * 24)
  );

  const isTablet = useMediaQuery({ query: `(max-width: ${TABLET_LANDSCAPE})` });
  const [isLoading, setIsLoading] = useState(true);
  const [cash, setCash] = useState(0);
  const [checkupCount, setCheckupCount] = useState(0);
  const [blockCount, setBlockCount] = useState(0);
  const [topParticipatedBlock, setTopParticipatedBlock] = useState({});
  const [topParticipatedBlocks, setTopParticipatedBlocks] = useState({});
  const [blockPerWeek, setBlockPerWeek] = useState(0);
  const [checkupPerWeek, setCheckupPerWeek] = useState(0);
  const [wellBeingAverage, setWellBeingAverage] = useState(0);
  const [maumSentence, setMaumSentence] = useState({});
  const [wellBeingType, setWellBeingType] = useState({});
  const [wellBeingScores, setWellBeingScores] = useState([]);
  const [cashHistory, setCashHistory] = useState([]);
  const [chartType, setChartType] = useState("");
  const [chartData1, setChartData1] = useState({});
  const [searchStartDate, setSearchStartDate] = useState(
    DEFAULT_SEARCH_START_DATE
  );
  const [searchEndDate, setSearchEndDate] = useState(new Date());

  useEffect(() => {
    const fetch = async () => {
      const response = await axiosPrivate.get(
        `/statistics/user?searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`
      );

      if (!response.data.ok) {
      }
      setChartType(CHART_TYPES[0]);
      setCash(response.data.cash);
      setCheckupCount(response.data.checkupCount);
      setBlockCount(response.data.blockCount);
      setBlockPerWeek(response.data.blockPerWeek);
      setCheckupPerWeek(response.data.checkupPerWeek);
      setTopParticipatedBlocks(response.data.topParticipatedBlocks);
      setWellBeingAverage(response.data.wellBeingAverage);
      setMaumSentence({
        maxSentenceEmoji: response.data.maxSentenceEmoji,
        maxSentence: response.data.maxSentence,
        maxSentenceWord: response.data.maxSentenceWord,
        maxSentenceCount: response.data.maxSentenceCount,
        maumSentences: response.data.maumSentences,
        maumSentencesInOrder: response.data.maumSentencesInOrder,
      });
      // setChartData1({
      //   data: response.data.wellBeingScores.map((item) => ({
      //     date: item.date,
      //     wellBeingScore: item.wellBeingScore,
      //     permavacScore: item.permavac,
      //   })),
      //   tabs: [
      //     { type: "전체" },
      //     ...response.data.wellBeingScores[0].permavac?.map((item) => ({
      //       type: item[0],
      //     })),
      //   ],
      // });
      setWellBeingType({
        maxWellBeing: response.data.maxWellBeing,
        minWellBeing: response.data.minWellBeing,
      });
      setWellBeingScores(response.data.wellBeingScores);
      setCashHistory(response.data.cashHistory);
      setIsLoading(false);
    };

    fetch();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <Heading1>대시보드</Heading1>
      <Heading2>나의 맘핏 활동 내역을 한눈에 확인해 보세요!</Heading2>
      <Section>
        {/* <Card1 title={"누적 보유 캐시"} data={`${cash.toLocaleString()}원`} /> */}
        <CardLayout style={{ gridArea: "1 / 1 / span 1  / span 1" }}>
          <Card1
            title={"맘핏과 함께한 시간"}
            icon={`${process.env.PUBLIC_URL}/assets/icons/dashboard_maumfit_icon.png`}
            data={`+${signupPeriod}일`}
            desc={`${getDate(auth.user.createdAt, "YYYY년 M월 D일")}부터 시작`}
          />
        </CardLayout>
        {/* <Card1 title={"평균 마음 웰빙 지수"} data={`${wellBeingAverage}`} /> */}
        <CardLayout style={{ gridArea: "2 / 1 / span 1  / span 1" }}>
          <Card1
            title={"마음 체크업 참여"}
            icon={`${process.env.PUBLIC_URL}/assets/icons/report_check_icon.png`}
            data={`${checkupCount}회`}
            desc={`1주에 약 ${checkupPerWeek}회 참여`}
          />
        </CardLayout>
        <CardLayout style={{ gridArea: "3 / 1 / span 3  / span 1" }}>
          <Card1
            title={"마음 트레이닝 참여"}
            icon={`${process.env.PUBLIC_URL}/assets/icons/dashboard_training_icon.png`}
            data={`${blockCount}회`}
            desc={`1주에 약 ${blockPerWeek}회 참여`}
          />
          <Divider />
          <Card2 title={"마음 트레이닝 Top3"} data={topParticipatedBlocks} />
        </CardLayout>
        <CardLayout style={{ gridArea: "1 / 2 / span 5  / span 1" }}>
          <Card1
            title={"마음 웰빙 지수"}
            icon={`${process.env.PUBLIC_URL}/assets/icons/dashboard_wellBeing_icon.png`}
            data={`${wellBeingAverage}점`}
            desc={`전체 기간 평균 마음 웰빙 지수`}
          />
          <Divider style={{ marginBottom: "2rem" }} />
          <Card3
            data={[
              {
                title: "높은 영역 (+)",
                value: wellBeingType.maxWellBeing,
                background: "#00DBB1",
              },
              {
                title: "낮은 영역 (-)",
                value: wellBeingType.minWellBeing,
                background: "#F11B1B",
              },
            ]}
          />
          <Divider style={{ marginTop: "2rem", marginBottom: "1.75rem" }} />
          <Card6 title={"주간 변화 추이"} data={wellBeingScores} />
        </CardLayout>
        <CardLayout style={{ gridArea: "1 / 3 / span 5  / span 1" }}>
          <Card1
            title={"대표 마음 문장"}
            icon={`${process.env.PUBLIC_URL}/assets/icons/dashboard_sentence_icon.png`}
            data={
              maumSentence.maxSentenceEmoji &&
              `${maumSentence.maxSentenceEmoji} ${maumSentence.maxSentenceWord}`
            }
            desc={maumSentence.maxSentence && `${maumSentence.maxSentence}`}
          />
          <Divider style={{ marginBottom: "1.88rem" }} />
          <Card7
            data={maumSentence.maumSentences.slice(0, 6)}
            checkupCount={checkupCount}
          />
          <Divider style={{ marginTop: "1.75rem", marginBottom: "1.5rem" }} />
          <Card8
            title={"최근 내 마음 분포"}
            data={maumSentence.maumSentencesInOrder}
            checkupCount={checkupCount}
          />
        </CardLayout>
        <CardLayout
          style={{
            gridArea: isTablet
              ? "6 / 1 / span 2 / span 1"
              : "1 / 4 / span 3  / span 1",
          }}
        >
          <Card1
            title={"보유 캐시"}
            icon={`${process.env.PUBLIC_URL}/assets/icons/dashboard_cash_icon.png`}
            titleIcon={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
            data={`${cash.toLocaleString()}원`}
            desc={
              cash >= MIN_WITHDRAW_AMOUNT
                ? "리워드 환급이 가능해요!"
                : `환급까지 ${(
                    MIN_WITHDRAW_AMOUNT - cash
                  ).toLocaleString()}원 남았어요`
            }
          />
          <Divider />
          <Card9
            style={{ height: "60%" }}
            title={"캐시 적립/사용 내역"}
            data={cashHistory.slice(0, 4)}
            auth={auth}
          />
          {/* <Card2 title={"마음 트레이닝 Top3"} data={[]} /> */}
        </CardLayout>
        <CardLayout
          style={{
            gridArea: isTablet
              ? "6 / 2 / span 1 / span 1"
              : "4 / 4 / span 2  / span 1",
          }}
        >
          <FlexRow
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <p>맘핏 공지사항</p>
            <svg
              // onClick={handleAnnouncementClick}
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
            >
              <path
                d="M15.1012 13.4827L9.64062 8.01126L10.5579 7.09399L16.9358 13.4827L10.5579 19.8605L9.64062 18.9432L15.1012 13.4827Z"
                fill="#222222"
              />
            </svg>
          </FlexRow>
          <Divider style={{ marginTop: "1rem" }} />
          <NoDataText />
        </CardLayout>

        {/* <Card1 title={"마음 트레이닝 횟수"} data={`${blockCount}회`} />
        <Card1
          title={"가장 많이 한 트레이닝"}
          data={`${topParticipatedBlock.title}`}
        /> */}
        {/* <Card2 title={""} data={wellBeingType} /> */}
        {/* <Card3 title={"대표 마음 문장"} data={maumSentence} /> */}
        {/* <Card4
          types={CHART_TYPES}
          style={{ gridArea: "2 / 2 / span 3 / span 3" }}
          data1={chartData1.data}
          tabs1={chartData1.tabs}
          chartType={chartType}
          setChartType={setChartType}
        /> */}
      </Section>
    </Container>
  );
};

export default PersonalDashboard;
