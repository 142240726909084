import axios from "../api/axios";
import useAuth from "./useAuth";

const useLogout = () => {
  const { auth, setAuth, setPersist } = useAuth();

  const logout = async () => {
    try {
      await axios("/logout", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      });
    } catch (err) {
      console.error(err);
    }
    localStorage.setItem("persist", false);
    setPersist(false);
    setAuth({});
  };

  return logout;
};

export default useLogout;
