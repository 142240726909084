import React, { useEffect, useMemo, useState } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import styled from "styled-components";
import Youtube from "react-youtube";
import shuffleArray from "../../utils/shuffleArray";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  margin-bottom: 4.5rem;
  width: 100%;
  @media (max-width: 425px) {
    margin-bottom: 1.5rem;
  }
  position: relative;
`;

const Indicator = styled.div`
  text-align: center;
  font-family: Pretendard;
  font-size: 0.875rem;
  line-height: 125%;
  color: #00dbb1;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 1rem;
`;

const Slider = ({ images, number, mediaType }) => {
  const location = useLocation();
  const isSmartTraining = location.pathname.includes("smart-training");
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });
  const start = useMemo(() => {
    return mediaType === "images random start"
      ? Math.floor(Math.random() * images.length)
      : 0;
  }, [images]);
  const [currentIndex, setCurrentIndex] = useState(start);

  const [color, setColor] = useState(number === 20 ? "#FFFFFF" : "#00D2C1"); // handleSlideChange 도 변경
  const buttonStyle =
    isMobile & isSmartTraining
      ? {
          background: "none",
          border: "none",
          // width: "50px",
          marginLeft: isMobile ? "0.3rem" : "1rem",
          marginRight: isMobile ? "0.3rem" : "1rem",
          position: "absolute",
          bottom: "1rem",
          left: 0,
          right: 0,
          width: "50px",
        }
      : {
          background: "none",
          border: "none",
          marginLeft: isMobile ? "0.3rem" : "1rem",
          marginRight: isMobile ? "0.3rem" : "1rem",
        };

  const leftbuttonStyle =
    isMobile & isSmartTraining
      ? {
          ...buttonStyle,
          left: "50%",
          // marginLeft: "-30px",
          // border: "1px solid black",
          transform: "translate(-150%)",
        }
      : {};
  const rightbuttonStyle =
    isMobile & isSmartTraining
      ? {
          ...buttonStyle,
          left: "50%",
          // marginLeft: "30px",
          // border: "1px solid black",
          transform: "translate(30%, 0)",
        }
      : {};

  const properties =
    isMobile & isSmartTraining
      ? {
          prevArrow: (
            <button style={{ ...leftbuttonStyle }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
              >
                <path
                  d="M6 1L1 5.5L6 10"
                  stroke="#111111"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          ),
          nextArrow: (
            <button style={{ ...rightbuttonStyle }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
              >
                <path
                  d="M1 1L6 5.5L1 10"
                  stroke="black"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          ),
        }
      : {
          prevArrow: (
            <button style={{ ...buttonStyle }}>
              <svg
                width="25"
                height="46"
                viewBox="0 0 25 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23 2L2 23L23 44"
                  stroke={color}
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          ),
          nextArrow: (
            <button style={{ ...buttonStyle }}>
              <svg
                width="25"
                height="46"
                viewBox="0 0 25 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 44L23 23L2 2"
                  stroke={color}
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          ),
        };
  const handleSlideChange = (from, to) => {
    setCurrentIndex((start + to) % images.length);
    if (number === 20) {
      if (to !== 0) {
        setColor("#00D2C1");
      } else {
        setColor("#FFFFFF");
      }
    }
  };
  useEffect(() => {});
  switch (mediaType) {
    case "image":
      images = [images[0]];
      break;
    case "images":
      images = images;
      break;
    case "images random start":
      images = images.map((item, index) => {
        return images[(start + index) % images.length];
      });

      break;
    case "random":
      images = [shuffleArray(images)[0]];
      break;
    case "randomSlide":
      images = shuffleArray(images);
      break;
    default:
      break;
  }
  return (
    <Container>
      <Slide
        arrows={
          mediaType === "images" ||
          mediaType === "images random start" ||
          mediaType === "randomSlide" ||
          mediaType === "mix"
        }
        {...properties}
        transitionDuration={400}
        autoplay={false}
        onChange={(from, to) => handleSlideChange(from, to)}
        canSwipe={false}
        // indicators={indicators}
      >
        {images.map((image, index) => {
          if (!image.includes("https")) {
            return (
              <Youtube
                style={{
                  borderWidth: "1",
                  borderColor: "black",
                  borderStyle: "solid",
                }}
                videoId={image}
                opts={{
                  height: isMobile ? "189" : "435",
                  width: "100%",
                  playerVars: {
                    rel: 0,
                    modestbranding: 1,
                    controls: 1,
                  },
                }}
              />
            );
          }
          return (
            <div
              key={index}
              style={{
                width: "100%",
                height:
                  isMobile & isSmartTraining
                    ? "27.25rem"
                    : isMobile
                    ? "189px"
                    : "27.19rem",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundImage: `url(${image})`,
                }}
              />
            </div>
          );
        })}
      </Slide>
      {isSmartTraining & (mediaType === "images") ||
      mediaType === "images random start" ||
      mediaType === "randomSlide" ||
      mediaType === "mix" ? (
        <Indicator>
          <span style={{ fontWeight: 700 }}>{currentIndex + 1}</span>{" "}
          <span style={{ color: "#BBB" }}>/</span>{" "}
          <span style={{ color: "#000" }}>{images.length}</span>
        </Indicator>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default React.memo(Slider, (prev, next) => {
  return prev.images === next.images;
});
