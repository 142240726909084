import styled from "styled-components";

const Container = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function SocialShareButton({ type, title, file }) {
  const url = encodeURIComponent(
    "https://pic-prod-resources.s3.ap-northeast-2.amazonaws.com/(image)+%EA%B0%95%EC%A0%90_2%EC%B0%A8%EC%8B%9C_%EC%9D%B4%EB%AF%B8%EC%A7%80_%EA%B0%95%EC%A0%90+%EB%8B%A8%EC%96%B4+%EB%A6%AC%EC%8A%A4%ED%8A%B81.png"
  );
  const handleClick = async () => {
    if (type === "url") {
      console.log("Copy url!");
      const blob = await (await fetch(file)).blob();
      const image = new File([blob], "fileName.png", { type: blob.type });
      console.log(image);
      if (navigator.share) {
        navigator.share({
          title: "나만의 아이트리 만들기",
          //   text: "Hello World",
          files: [image],
          url: "https://maum.fit/itree",
        });
      } else {
        alert("공유하기가 지원되지 않는 환경 입니다.");
      }
      return;
    }

    if (type === "facebook") {
      shareFacebook();
      return;
    }

    if (type === "twitter") {
      shareTwitter();
    }

    if (type === "kakao") {
      shareKakao();
    }
  };

  const shareFacebook = () => {
    window.open("http://www.facebook.com/sharer/sharer.php?u=" + url);
  };

  const shareTwitter = () => {
    const text = "지금 우리 MBTI는?";
    console.log(url);
    window.open(
      "https://twitter.com/intent/tweet?text=" + text + "&url=" + url
    );
  };

  const shareKakao = () => {
    console.log(url);
    window.Kakao.Link.sendDefault({
      objectType: "feed",
      content: {
        title: "지금 우리 MBTI는?",
        description: "파릇파릇하기만 했던 나, 입사후에 무슨 일이?",
        imageUrl: decodeURIComponent(url),
        link: {
          webUrl: url,
          mobileWebUrl: url,
        },
      },
      buttons: [
        {
          title: "웹으로 이동",
          link: {
            webUrl: url,
            mobileWebUrl: url,
          },
        },
      ],
    });
    // window.Kakao.API.request({
    //   url: "/v2/api/talk/memo/default/send",
    //   data: {
    //     template_object: {
    //       object_type: "feed",
    //       content: {
    //         title: "카카오톡 링크 4.0",
    //         description: "디폴트 템플릿 FEED",
    //         image_url:
    //           "https://mud-kage.kakao.com/dn/Q2iNx/btqgeRgV54P/VLdBs9cvyn8BJXB3o7N8UK/kakaolink40_original.png",
    //         link: {
    //           web_url: "https://developers.kakao.com",
    //           mobile_web_url: "https://developers.kakao.com",
    //         },
    //       },
    //       item_content: {
    //         profile_text: "Kakao",
    //         profile_image_url:
    //           "https://mud-kage.kakao.com/dn/Q2iNx/btqgeRgV54P/VLdBs9cvyn8BJXB3o7N8UK/kakaolink40_original.png",
    //         title_image_url:
    //           "https://mud-kage.kakao.com/dn/Q2iNx/btqgeRgV54P/VLdBs9cvyn8BJXB3o7N8UK/kakaolink40_original.png",
    //         title_image_text: "Cheese cake",
    //         title_image_category: "Cake",
    //         items: [
    //           {
    //             item: "Cake1",
    //             item_op: "1000원",
    //           },
    //           {
    //             item: "Cake2",
    //             item_op: "2000원",
    //           },
    //           {
    //             item: "Cake3",
    //             item_op: "3000원",
    //           },
    //           {
    //             item: "Cake4",
    //             item_op: "4000원",
    //           },
    //           {
    //             item: "Cake5",
    //             item_op: "5000원",
    //           },
    //         ],
    //         sum: "Total",
    //         sum_op: "15000원",
    //       },
    //       social: {
    //         like_count: 100,
    //         comment_count: 200,
    //       },
    //       button_title: "바로 확인",
    //     },
    //   },
    //   success: function (response) {
    //     console.log(response);
    //   },
    //   fail: function (error) {
    //     console.log(error);
    //   },
    // });
  };
  return <Container onClick={handleClick}>{title}</Container>;
}

export default SocialShareButton;
