import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import {
  MONTH_PRICE,
  PAYMENT_TYPE,
  TAX,
  YEAR_PRICE,
} from "../../../constants/payments";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getDate } from "../../../utils/date";
import CardLayout from "../CardLayout";
import Divider from "../components/Divider";
import Heading1 from "../components/Heading1";
import Heading2 from "../components/Heading2";
import Modal from "../components/Modal";
import Text1 from "../components/Text1";
import TextLink from "../components/TextLink";
import AddManagerModal from "../modals/AddManagerModal";
import CancelPlanModal from "../modals/CancelPlanModal";
import ChangePlanModal from "../modals/ChangePlanModal";

const Container = styled.div`
  padding: 2.75rem 2.75rem;
  padding-left: calc(2.75rem + var(--sidebar-width));
  width: 100%;
  position: relative;
`;

const KeyText = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  flex: 1;
  //   border: 1px solid black;
`;

const ValueText = styled(KeyText)`
  flex: 5;
`;

const SmallText = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 150%; /* 1.125rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  margin-left: 0.25rem;
`;

const Button = styled.button`
  border-radius: 0.3125rem;
  padding: 0.5rem 0.75rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  font-size: 0.875rem;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
`;

const PrimaryButton = styled(Button)`
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
  border: 1px solid ${({ theme }) => theme.primary.normal};
`;

const ManagerPaymentManagement = () => {
  const { auth } = useAuth();
  console.log(auth);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [showCancelPlanModal, setShowCancelPlanModal] = useState(false);
  const [showChangeToYearPlanModal, setShowChangeToYearPlanModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [subscribeInfo, setSubscribeInfo] = useState();

  function jsf__pay(form) {
    try {
      window.KCP_Pay_Execute(form);
    } catch (e) {
      /* IE 에서 결제 정상종료시 throw로 스크립트 종료 */
    }
  }

  // 주문번호 생성 예제
  function init_orderid() {
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    var date = today.getDate();
    var time = today.getTime();

    if (parseInt(month) < 10) {
      month = "0" + month;
    }

    var vOrderID = year + "" + month + "" + date + "" + time;

    return vOrderID;
  }

  const onChangeCardClick = async () => {
    console.log(document.order_info);
    jsf__pay(document.order_info);
  };

  useEffect(() => {
    const fetch = async () => {
      const subscribeResponse = await axiosPrivate.get("/payments/subscribe");
      if (subscribeResponse.data.ok) {
        console.log(subscribeResponse);
        setSubscribeInfo(subscribeResponse.data.data);
      }
      setIsLoading(false);
    };

    fetch();
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <Container>
      <Heading1 text="결제 관리" />
      <Heading2
        text="결제 내역 및 정보를 확인하고 플랜 변경, 자동 결제 등을 설정합니다."
        style={{ marginBottom: "2.44rem" }}
      />

      <FlexColumn style={{ gap: "1.88rem" }}>
        <CardLayout style={{ padding: "1.88rem 2.5rem" }}>
          <Text1 text="이용 현황" />
          <Divider />
          <FlexColumn style={{ gap: "1.25rem" }}>
            <FlexRow style={{ alignItems: "center" }}>
              <KeyText>이용 상품</KeyText>
              <ValueText>맘핏</ValueText>
            </FlexRow>
            <FlexRow style={{ alignItems: "center" }}>
              <KeyText>결제 주기</KeyText>
              <ValueText>
                [{subscribeInfo.planType === PAYMENT_TYPE.MONTH ? "월" : "연"}{" "}
                구독] 1개월
              </ValueText>
            </FlexRow>
            <FlexRow style={{ alignItems: "center" }}>
              <KeyText>이용 기간</KeyText>
              <FlexRow style={{ flex: 5 }}>
                <ValueText style={{ flex: "none", marginRight: "1rem" }}>
                  {getDate(subscribeInfo.payDate, "YYYY년 M월 D일")} ~{" "}
                  {getDate(
                    new Date(
                      new Date(subscribeInfo.nextPayDate).getFullYear(),
                      new Date(subscribeInfo.nextPayDate).getMonth(),
                      new Date(subscribeInfo.nextPayDate).getDate() - 1
                    ),
                    "YYYY년 M월 D일"
                  )}
                </ValueText>
                <TextLink
                  style={{ fontSize: "1rem" }}
                  onClick={() => setShowCancelPlanModal(true)}
                >
                  구독 종료
                </TextLink>
              </FlexRow>
            </FlexRow>
            <FlexRow>
              <KeyText>사용 인원</KeyText>
              <ValueText>{subscribeInfo.users}명</ValueText>
            </FlexRow>
            <FlexRow style={{ alignItems: "center" }}>
              <KeyText>결제 금액</KeyText>
              <FlexRow
                style={{
                  flex: 5,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ValueText style={{ flex: "none" }}>
                  {subscribeInfo.amount.toLocaleString()}원
                  <SmallText>(VAT 포함)</SmallText>
                </ValueText>
                <Button
                  onClick={() => navigate("/manager/dashboard/payment/history")}
                >
                  이전 결제내역 확인
                </Button>
              </FlexRow>
            </FlexRow>
          </FlexColumn>
        </CardLayout>
        <CardLayout style={{ padding: "1.88rem 2.5rem" }}>
          <Text1 text="다음 결제 예정" />
          <Divider />
          <FlexColumn style={{ gap: "1.25rem" }}>
            <FlexRow style={{ alignItems: "center" }}>
              <KeyText>이용 상품</KeyText>
              <ValueText>맘핏</ValueText>
            </FlexRow>
            <FlexRow style={{ alignItems: "center" }}>
              <KeyText>결제 주기</KeyText>
              <FlexRow
                style={{
                  flex: 5,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ValueText>
                  {subscribeInfo.nextPlanType === PAYMENT_TYPE.MONTH
                    ? "[월 구독] 1개월"
                    : subscribeInfo.nextPlanType === PAYMENT_TYPE.YEAR
                    ? "[연 구독] 1개월"
                    : "-"}{" "}
                </ValueText>
                <PrimaryButton onClick={() => setShowChangePlanModal(true)}>
                  결제 주기 변경
                </PrimaryButton>
              </FlexRow>
            </FlexRow>
            <FlexRow style={{ alignItems: "center" }}>
              <KeyText>이용 기간</KeyText>
              <FlexRow style={{ flex: 5 }}>
                <ValueText style={{ flex: "none", marginRight: "1rem" }}>
                  {getDate(subscribeInfo.nextPayDate, "YYYY년 M월 D일")} ~{" "}
                  {getDate(
                    new Date(
                      new Date(subscribeInfo.nextPayDate).getFullYear(),
                      new Date(subscribeInfo.nextPayDate).getMonth() + 1,
                      new Date(subscribeInfo.nextPayDate).getDate() - 1
                    ),
                    "YYYY년 M월 D일"
                  )}
                </ValueText>
              </FlexRow>
            </FlexRow>
            <FlexRow>
              <KeyText>결제일</KeyText>
              <ValueText>
                {" "}
                {getDate(
                  new Date(new Date(subscribeInfo.nextPayDate)),
                  "YYYY년 M월 D일"
                )}
              </ValueText>
            </FlexRow>
            <FlexRow>
              <KeyText>사용 인원</KeyText>
              <FlexRow
                style={{
                  flex: 5,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ValueText>{auth.user.companyId.numUsers}명</ValueText>
                <Button onClick={() => navigate("/manager/dashboard/users")}>
                  사용 인원 변경
                </Button>
              </FlexRow>
            </FlexRow>
            <FlexRow style={{ alignItems: "center" }}>
              <KeyText>결제 금액</KeyText>
              <FlexRow
                style={{
                  flex: 5,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ValueText style={{ flex: "none" }}>
                  {subscribeInfo.nextPlanType === PAYMENT_TYPE.MONTH
                    ? (
                        auth.user.companyId.numUsers *
                          MONTH_PRICE *
                          (1 + TAX / 100) +
                        subscribeInfo.extraAmount
                      ).toLocaleString()
                    : (
                        auth.user.companyId.numUsers *
                          YEAR_PRICE *
                          (1 + TAX / 100) +
                        subscribeInfo.extraAmount
                      ).toLocaleString()}
                  원<SmallText>(VAT 포함)</SmallText>
                </ValueText>
                <Button
                  onClick={() => navigate("/manager/dashboard/payment/history")}
                >
                  이전 결제내역 확인
                </Button>
              </FlexRow>
            </FlexRow>
            <FlexRow style={{ alignItems: "center" }}>
              <KeyText>결제 카드</KeyText>
              <FlexRow
                style={{
                  flex: 5,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ValueText style={{ flex: "none" }}>
                  {subscribeInfo.cardName}{" "}
                  {subscribeInfo.cardNumber?.slice(0, 4)}
                </ValueText>
                <Button onClick={onChangeCardClick}>결제 카드 변경</Button>
              </FlexRow>
            </FlexRow>
          </FlexColumn>
        </CardLayout>
      </FlexColumn>
      <Modal showing={showChangePlanModal} width="auto">
        <ChangePlanModal
          numUsers={auth.user.companyId.numUsers}
          planType={subscribeInfo.nextPlanType}
          confirmHandler={() => setShowChangeToYearPlanModal(true)}
          closeHandler={() => setShowChangePlanModal(false)}
        />
      </Modal>
      <Modal showing={showCancelPlanModal} width="auto">
        <CancelPlanModal closeHandler={() => setShowCancelPlanModal(false)} />
      </Modal>
      <form
        name="order_info"
        // method="post"
        action="/payments/subscribe/update"
      >
        <div>
          {/* <!-- 주문번호(ordr_idxx) --> */}
          <input
            type="hidden"
            name="ordr_idxx"
            value={init_orderid()}
            maxLength="40"
          />
          {/* <!-- 가맹점 정보 설정--> */}
          <input type="hidden" name="site_cd" value="BA001" />
          {/* <!-- 그룹아이디(kcpgroup_id) --> */}
          {/* TODO: 그룹아이디는 KCP관리자 페이지에서 생성해야 함. 고정값인듯 */}
          <input type="hidden" name="kcpgroup_id" value="BA0011000348" />
          <input type="hidden" name="site_name" value="TEST SITE" />
          {/* <!-- 상품제공기간 설정 --> */}
          {/* <input ref={paymentTypeRef} type="hidden" name="good_expr" value="" /> */}
          {/* <!-- 결제 방법 : 인증키 요청--> */}
          <input type="hidden" name="pay_method" value="AUTH:CARD" />
          {/* <!-- 인증 방식 : 공동인증--> */}
          <input type="hidden" name="card_cert_type" value="BATCH" />
          {/* <!-- 배치키 발급시 주민번호 입력을 결제창 안에서 진행 --> */}
          <input type="hidden" name="batch_soc" value="Y" />
          {/* <!-- 
			※필수 항목
			표준웹에서 값을 설정하는 부분으로 반드시 포함되어야 합니다값을 설정하지 마십시오
	    --> */}
          <input type="hidden" name="module_type" value="01" />
          <input type="hidden" name="res_cd" value="" />
          <input type="hidden" name="res_msg" value="" />
          <input type="hidden" name="enc_info" value="" />
          <input type="hidden" name="enc_data" value="" />
          <input type="hidden" name="tran_cd" value="" />

          {/* <!-- 주민번호 S / 사업자번호 C 픽스 여부 --> */}
          <input type="hidden" name="batch_soc_choice" value="S" />

          {/* <!-- 배치키 발급시 카드번호 리턴 여부 설정 --> */}
          {/* <!-- Y : 1234-4567-****-8910 형식, L : 8910 형식(카드번호 끝 4자리) --> */}
          <input type="hidden" name="batch_cardno_return_yn" value="Y" />

          {/* <!-- batch_cardno_return_yn 설정시 결제창에서 리턴 --> */}
          {/* <!-- <input type='hidden' name='card_mask_no'			  value=''>    --> */}
        </div>
      </form>
    </Container>
  );
};

export default ManagerPaymentManagement;
