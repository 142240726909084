import styled from "styled-components";
import Button from "./Button";

const Container = styled.div`
  width: 100%;
  max-width: 335px;
  height: 178px;
  padding: 30px;
  background: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Title = styled.h3`
  color: #000;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.04em;
  text-align: center;
  margin-bottom: 23px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 22px;
  gap: 10px;
`;

const DeleteSuccessDialog = ({ okHandler }) => {
  return (
    <Container>
      <Title>
        완벽하게 삭제되었습니다!
        <br />
        다음에 또 도전해 보세요 💪
      </Title>
      <Button text={"이전 페이지로 돌아가기"} clickHandler={okHandler} />
    </Container>
  );
};

export default DeleteSuccessDialog;
