import styled from "styled-components";
import { MOBILE } from "../../../../Constants";

const P = styled.p`
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.primary.normal};
  font-weight: 600;
  height: 1.4rem;
  display: flex;
  align-items: center;
  padding: 0.1875rem 0.375rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 7px;
  margin-left: 0.25rem;
  @media (max-width: ${MOBILE}) {
    font-size: 0.75rem;
    line-height: normal;
    padding: 0.1875rem 0.375rem;
  }
`;

const ReportBetaLogo = () => {
  return <P>Beta</P>;
};

export default ReportBetaLogo;
