import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import FlexRow from "../../components/FlexRow";
import { Chart } from "./components/Chart";
import Heading from "./components/Heading";
import QuestionHeading from "./components/QuestionHeading";
import SubHeading from "./components/SubHeading";
import TopNav from "./components/TopNav";

const Container = styled.div`
  width: 100%;
  background: #edf3f3;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InfoBox = styled.div`
  display: flex;
  //   justify-content: space-evenly;
  padding: 0.5rem 1.75rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  margin-bottom: 4.13rem;
  border-radius: 0.44rem;
  gap: 2rem;
  //   width: 42.56rem;
`;

const InfoBoxText = styled.span`
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  font-size: 0.88rem;
  font-weight: 500;
  line-height: 1.38rem;
  margin-left: 0.5rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 3.38rem 3.13rem;
  margin-bottom: 3.75rem;
`;

const QuestionContainer = styled.section`
  margin-bottom: 6.25rem;
`;

const QuestionSummaryBox = styled.div`
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  padding: 1rem 1.75rem;
  display: flex;
  align-items: center;
  border-radius: 0.44rem;
`;

const QuestionSummaryText = styled.span`
  line-height: 1.56rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  margin-left: 0.5rem;
`;

const FooterText = styled.p`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 1.88rem;
  text-align: center;
`;

const PoweredBy = styled.p`
  color: ${({ theme }) => theme.primary.normal};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 150%;
`;

const SmartTrainingReport = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [myResult, setMyResult] = useState([]);
  const [allResult, setAllResult] = useState([]);
  const [dates, setDates] = useState([]);
  const [checkupName, setCheckupName] = useState("");
  const round = searchParams.get("round");
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_SMART_TOOL_SERVER_BASE_URL}/maum-checkup/report`,
        {
          params: {
            pid: searchParams.get("pid") || "",
            group: searchParams.get("group") || "",
            name: searchParams.get("name") || "",
            birthday: searchParams.get("birthday") || "",
          },
        }
      );

      setCheckupName(res.data.checkupName);
      setQuestions(res.data.questions);
      setMyResult(res.data.me);
      setAllResult(res.data.all);
      setDates(res.data.dates);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return null;
  }
  console.log(myResult);
  return (
    <Container>
      {/* <TopNav>
        <img
          src={`${process.env.PUBLIC_URL}/assets/logo/topbar_logo.png`}
          alt="logo"
          width={103}
        />
      </TopNav> */}
      <Wrapper>
        <SubHeading style={{ marginTop: "52px", marginBottom: "8px" }}>
          마음 리포트
        </SubHeading>
        <Heading style={{ marginBottom: "28px" }}>
          {searchParams.get("name")} 님의 '{checkupName}' 변화를 살펴볼까요?
        </Heading>
        <InfoBox>
          <FlexRow style={{ alignItems: "center" }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/calendar_icon.png`}
              alt="calendar_icon"
              width={22}
            />
            <InfoBoxText>1차 체크업: 워크숍 전</InfoBoxText>
          </FlexRow>
          <FlexRow style={{ alignItems: "center" }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/calendar_icon.png`}
              alt="calendar_icon"
              width={22}
            />
            <InfoBoxText>2차 체크업: 워크숍 직후</InfoBoxText>
          </FlexRow>
          <FlexRow style={{ alignItems: "center" }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/calendar_icon.png`}
              alt="calendar_icon"
              width={22}
            />
            <InfoBoxText>3차 체크업 : 워크루틴 4회 진행 후</InfoBoxText>
          </FlexRow>
        </InfoBox>
        <ContentContainer>
          {questions.map((q, index) => (
            <QuestionContainer key={index}>
              <QuestionHeading style={{ marginBottom: "1.5rem" }}>
                Q{index + 1}. {q}
              </QuestionHeading>
              {round >= 3 && (
                <QuestionSummaryBox>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/symbol/symbol_sm.png`}
                    alt="maumfit symbol image"
                    width={18}
                  />

                  <QuestionSummaryText>
                    나의 응답 점수는 전체 교육생{" "}
                    {myResult[index].reduce((prev, cur) => prev + cur, 0) >
                    allResult[index].reduce((prev, cur) => prev + cur.score, 0)
                      ? "평균에 비해 높은 편이에요."
                      : myResult[index].reduce((prev, cur) => prev + cur, 0) <
                        allResult[index].reduce(
                          (prev, cur) => prev + cur.score,
                          0
                        )
                      ? "평균에 비해 낮은 편이에요."
                      : "평균과 동일해요."}
                  </QuestionSummaryText>
                </QuestionSummaryBox>
              )}
              <Chart
                scale={7}
                results={
                  round >= 3
                    ? [myResult[index], allResult[index]]
                    : [myResult[index], []]
                }
                dates={dates.map((d, i) => [`${i + 1}차 체크업`, d])}
              />
            </QuestionContainer>
          ))}
        </ContentContainer>
        <img
          src={`${process.env.PUBLIC_URL}/assets/symbol/symbol_sm.png`}
          alt="maum fit symbol image"
          width={35}
          style={{ marginBottom: "2rem" }}
        />
        <FooterText style={{ marginBottom: "1.5rem" }}>
          나의 마음 상태를 가볍게 체크하며,
          <br />
          잠시 나를 성찰해보세요.
        </FooterText>
        <FooterText style={{ fontWeight: 600, marginBottom: "3rem" }}>
          변화는, 아주 작은 실천부터 시작됩니다.
        </FooterText>
        <FlexRow style={{ alignItems: "center", marginBottom: "2rem" }}>
          <PoweredBy>Powered by</PoweredBy>
          <img
            style={{ width: "5.4375rem", height: "1rem", marginLeft: "0.5rem" }}
            src={`${process.env.PUBLIC_URL}/assets/logo/powered_by_logo.png`}
          />
        </FlexRow>
      </Wrapper>
    </Container>
  );
};

export default SmartTrainingReport;
