import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { MOBILE } from "../../../../Constants";
import FlexColumn from "../../../../components/FlexColumn";

ChartJS.register(ArcElement, Tooltip, Legend);
const emotions = {
  뿌듯함: { emotion: "😎뿌듯함", word: "뿌듯함", isPositive: true },
  정신없음: { emotion: "😵정신없음", word: "정신없음", isPositive: false },
  효능감: { emotion: "🤩효능감", word: "효능감", isPositive: true },
  "낮은 자신감": {
    emotion: "😥낮은 자신감",
    word: "낮은 자신감",
    isPositive: false,
  },
  재미: { emotion: "😉재미", word: "재미", isPositive: true },
  여유: { emotion: "😀여유", word: "여유", isPositive: true },
  "분노/화": { emotion: "😡분노/화", word: "분노/화", isPositive: false },
  스트레스: { emotion: "😱스트레스", word: "스트레스", isPositive: false },
  지루함: { emotion: "🥱지루함", word: "지루함", isPositive: false },
};

const Container = styled.div`
  position: relative;

  width: 32rem;
  height: 32rem;
  @media (max-width: 425px) {
    display: flex;
    justify-content: center;
    width: 100%;
    /* height: 20rem; */
    height: 20rem;
  }
`;
const DoughnutChart = ({ style, result }) => {
  const isMobile = useMediaQuery({ maxWidth: MOBILE });

  const doughnutLablesLine = {
    id: "doughnutLablesLine",
    afterDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { top, bottom, left, right, width, height },
      } = chart;
      // console.log(top, bottom, left, right, width, height);
      chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
          const { x, y } = datapoint.tooltipPosition();
          // console.log(index, datapoint.tooltipPosition());
          const halfwidth = (left + right) / 2;
          const halfheight = (top + bottom) / 2;
          const angle =
            datapoint.startAngle +
            (datapoint.endAngle - datapoint.startAngle) / 2;
          const extendPointX = x >= halfwidth ? 20 : -20;
          const extendPointY = y >= halfheight ? 20 : -20;

          const originX =
            x +
            extendPointX +
            (Math.cos(angle) * (datapoint.outerRadius + 80)) / 2;
          const originY =
            y +
            extendPointY +
            (Math.sin(angle) * (datapoint.outerRadius + 80)) / 2;
          const xTo =
            halfwidth + (Math.cos(angle) * (datapoint.outerRadius + 10)) / 2;
          const yTo =
            halfheight + (Math.sin(angle) * (datapoint.outerRadius + 10)) / 2;

          const extendTextX = x >= halfwidth ? -20 : 20;
          const extendTextY = y >= halfheight ? -8 : 8;
          const textX =
            halfwidth +
            extendTextX +
            (Math.cos(angle) * (datapoint.outerRadius + 0)) / 2;
          const textY =
            halfheight +
            extendTextY +
            (Math.sin(angle) * (datapoint.outerRadius + 0)) / 2;

          // const r1 = datapoint.innerRadius;
          // const r2 = datapoint.outerRadius;
          // const xinner = r1 * Math.sin(datapoint.startAngle);
          // const yinner = r1 * Math.cos(datapoint.startAngle);
          // const xouter = r2 * Math.sin(datapoint.startAngle);
          // const youter = r2 * Math.cos(datapoint.startAngle);
          // console.log(index, xinner, yinner, xouter, youter);
          // console.log(index, x, xTo, y, yTo);
          // ctx.fillStyle = "black";
          // ctx.fillRect(x, y, 1, 1);
          // console.log("datapoint:", datapoint);
          // console.log(width, height, x, y);

          const xLine = x >= halfwidth ? x + 30 : x - 30;
          const yLine = y >= halfheight ? y + 30 : y - 30;
          const extraLine = x >= halfwidth ? 15 : -15;
          // console.log(dataset.borderColor[index]);
          ctx.beginPath();
          const moveX = x >= halfwidth ? 0 + xTo : 0 - xTo;
          const moveY = y >= halfheight ? 0 + yTo : 0 - yTo;
          ctx.moveTo(x, y);
          ctx.lineTo(2 * x - xTo, 2 * y - yTo);
          // ctx.lineTo(xLine + extraLine, yLine);

          ctx.strokeStyle = "#7C8585";
          ctx.setLineDash([2, 1]);
          ctx.stroke();

          const textAlign = result.length - 1 === index ? "left" : "center"; // 마지막 항목들이 겹쳐서 임시방편으로
          const textSpacing = x >= halfwidth ? 5 : -5;
          ctx.font = isMobile ? "12px Roboto" : "14px Roboto";
          ctx.textBaseline = "middle";
          ctx.textAlign = textAlign;
          ctx.fillText(
            `${result[index][3]}${result[index][2]}`,
            2 * x - textX + textSpacing,
            2 * y - textY - 6
          );

          ctx.font = isMobile ? "bold 10px Roboto" : "bold 14px Roboto";
          ctx.fillText(
            `${result[index][1]}%`,
            result.length - 1 === index
              ? 2 * x - textX + 10
              : 2 * x - textX + textSpacing,
            2 * y - textY + 12
          );
          // console.log(ctx);
        });
      });
    },
  };
  const plugins = [
    doughnutLablesLine,
    {
      //   beforeDatasetsDraw(chart, args, pluginOptions) {
      //     const { ctx, data } = chart;
      //     ctx.restore();
      //     const xCoor = chart.getDatasetMeta(0).data[0].x;
      //     const yCoor = chart.getDatasetMeta(0).data[0].y;
      //     ctx.font = "bold 28px Pretendard Variable";
      //     ctx.textAlign = "center";
      //     ctx.textBaseline = "middle";
      //     ctx.fillText(`Total\n100%`, xCoor, yCoor);
      //     ctx.save();
      //   },
    },
  ];
  const options = {
    maintainAspectRatio: false,
    // responsive: true,
    // cutout: 100,
    // percentageInnerCutout: 100,
    // radius: "100%",
    layout: {
      padding: isMobile ? 80 : 100,
    },
    rotation: 0,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };
  const data = {
    // labels: result.map((item) => item.word),
    datasets: [
      {
        // label: "# of Votes",
        data: result.filter((item) => item[1] > 0).map((item) => item[1]),
        backgroundColor: result.map((item) =>
          emotions[item[2]].isPositive ? "#00DBB1" : "#FF7E21"
        ),
        borderColor: [
          "rgba(0,0,0,0)",
          "rgba(0,0,0,0)",
          "rgba(0,0,0,0)",
          "rgba(0,0,0,0)",
          "rgba(0,0,0,0)",
          "rgba(0,0,0,0)",
          "rgba(0,0,0,0)",
          "rgba(0,0,0,0)",
          "rgba(0,0,0,0)",
        ],
        borderWidth: 1,
        // borderRadius: 10,
        cutout: "70%",
        offset: 12,
        hoverOffset: 12,
      },
    ],
  };
  return (
    <Container style={style}>
      <Doughnut
        // width={isMobile ? "15rem" : "15rem"}
        // height={isMobile ? "15rem" : "15rem"}
        options={options}
        data={data}
        plugins={plugins}
      />
      <FlexColumn
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "#7C8585",
          fontSize: "1.75rem",
          fontWeight: 700,
          textAlign: "center",
          lineHeight: isMobile ? "1.125rem" : "2rem",
          fontFamily: "Roboto",
        }}
      >
        <span
          style={{
            fontWeight: "500",
            fontSize: isMobile ? "0.5rem" : "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          Total
        </span>
        <span style={{ fontSize: isMobile && "1rem" }}>100%</span>
      </FlexColumn>
    </Container>
  );
};

export default DoughnutChart;
