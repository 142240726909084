const convertBGColor = (theme) => {
  switch (theme) {
    case "회복탄력성":
      return "#FFF8E7";
    case "마음챙김":
      return "#EBF8F9";
    case "긍정정체성":
      return "#F8F3FF";
    case "긍정리더십":
      return "#FFF3EB";
    case "내재동기":
      return "#F8F9DF";
    case "자기효능감":
      return "#FDF1F6";
    case "활력":
      return "#EBF1FA";
    default:
      return "#FFF8E7";
  }
};

export default convertBGColor;
