import styled from "styled-components";
import { MOBILE } from "../../../../Constants";

const Text = styled.h4`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.19rem;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  @media (max-width: ${MOBILE}) {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal;
  }
`;

const Heading3 = ({ children, style }) => {
  return <Text style={style}>{children}</Text>;
};

export default Heading3;
