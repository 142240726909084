import styled from "styled-components";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import DoughnutChart from "./components/DoughnutChart";
import NoDataText from "./components/NoDataText";

const Container = styled.div``;

const Title = styled.p`
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Text1 = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 140%;
`;

const Text2 = styled.span`
  font-size: 0.8125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 500;
  line-height: 140%;
`;

const Circle = styled.div`
  width: 0.6875rem;
  height: 0.6875rem;
  border-radius: 50%;
`;

const Card8 = ({ title, data, checkupCount, style }) => {
  return (
    <Container style={style}>
      <FlexRow style={{ marginBottom: "2.5rem" }}>
        <Title>{title}</Title>
      </FlexRow>
      {checkupCount > 0 ? (
        <FlexRow style={{ justifyContent: "space-between", gap: "1.5rem" }}>
          <FlexColumn
            style={{
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            {data.slice(0, 3).map((item, index) => (
              <FlexRow key={index} style={{ justifyContent: "space-between" }}>
                <FlexRow style={{ alignItems: "center", gap: "0.75rem" }}>
                  <Circle
                    style={{
                      background:
                        index === 0
                          ? "#00DBB1"
                          : index === 1
                          ? "#017FC6"
                          : index === 2
                          ? "#FF7E21"
                          : "#EEEEEE",
                    }}
                  />
                  <Text1>{item.word}</Text1>
                </FlexRow>
                <Text2>{item.percentage}%</Text2>
              </FlexRow>
            ))}
            <FlexRow ke style={{ justifyContent: "space-between" }}>
              <FlexRow style={{ alignItems: "center", gap: "0.75rem" }}>
                <Circle
                  style={{
                    background: "#EEEEEE",
                  }}
                />
                <Text1>기타 ({data.slice(3).length}개)</Text1>
              </FlexRow>
              <Text2>
                {data
                  .slice(3)
                  .reduce(
                    (prev, cur) =>
                      parseFloat((prev + cur.percentage).toFixed(1)),
                    0
                  )}
                %
              </Text2>
            </FlexRow>
          </FlexColumn>
          <DoughnutChart
            result={[
              ...data.slice(0, 3),
              {
                word: `기타 (${data.slice(3).length}개)`,
                percentage: data
                  .slice(3)
                  .reduce(
                    (prev, cur) =>
                      parseFloat((prev + cur.percentage).toFixed(1)),
                    0
                  ),
              },
            ]}
          />
        </FlexRow>
      ) : (
        <NoDataText />
      )}
    </Container>
  );
};

export default Card8;
