import styled from "styled-components";
import FlexRow from "../../../../components/FlexRow";
import { MOBILE } from "../../../../Constants";

const Container = styled.div`
  border-radius: 1.25rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 2.44rem 1.87rem 2.13rem 1.87rem;
  width: 27.125rem;
  @media (max-width: ${MOBILE}) {
    width: 19rem;
    padding: 1.77rem 1.36rem 1.44rem 1.36rem;
  }
`;
const Heading1 = styled.p`
  color: #000;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 140%;
  position: relative;

  text-align: center;
  @media (max-width: ${MOBILE}) {
    font-size: 0.9375rem;
  }
`;

const Underline = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-35%);

  @media (max-width: ${MOBILE}) {
    width: 4.2rem;
  }
`;

const Heading2 = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  font-size: 0.875rem;
  line-height: 160%;
  text-align: center;
  margin-top: 1.5rem;
  @media (max-width: ${MOBILE}) {
    font-size: 0.8125rem;
  }
`;

const Textarea = styled.textarea`
  border-radius: 0.4375rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  padding: 1.25rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  font-size: 0.9375rem;
  font-weight: 500;
  width: 100%;
  line-height: 160%;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  resize: none;
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.grayScaleColor.black};
  }
  @media (max-width: ${MOBILE}) {
    font-size: 0.875rem;
    padding: 0.91rem;
    margin-top: 0.64rem;
    margin-bottom: 1.36rem;
  }
`;

const Button = styled.button`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.primary.normal};
  background: ${({ theme, state }) =>
    state === "enabled" ? theme.primary.normal : theme.grayScaleColor.white};
  color: ${({ theme, state }) =>
    state === "enabled" ? theme.grayScaleColor.white : theme.primary.normal};
  cursor: pointer;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4375rem;
  padding: 0.69rem 0.75rem;
  border-radius: 0.3125rem;
  &:hover:not([disabled]) {
    background: ${({ theme }) => theme.primary.hover};
    border: 1px solid ${({ theme }) => theme.primary.hover};
  }
  &:disabled {
    opacity: 0.5;
  }
  transition: all 0.3s;
`;

const HighlightModal = ({ onClick, text, handleTextChange }) => {
  return (
    <Container>
      <Heading1>
        이번 주 하이라이트 ✍
        <Underline src={`${process.env.PUBLIC_URL}/svg/underline.svg`} />
      </Heading1>
      <Heading2>
        이번 주, 어떤 일이 있었는지 가볍게 적어볼까요?
        <br />
        나에게 중요한 순간들을 언제든 꺼내볼 수 있어요.
      </Heading2>
      <Textarea
        onChange={handleTextChange}
        placeholder="ex. 워크숍이 있었던 한 주"
      ></Textarea>
      <FlexRow style={{ width: "100%", gap: "0.75rem" }}>
        <Button
          onClick={() => onClick("no")}
          style={{
            width: "100%",
            border: "1px solid #00DBB1",
            background: "white",
            color: "#00DBB1",
          }}
        >
          결과 바로가기
        </Button>
        <Button
          onClick={() => onClick()}
          style={{ width: "100%" }}
          state="enabled"
          disabled={text.length === 0}
        >
          기록 완료하기
        </Button>
      </FlexRow>
    </Container>
  );
};

export default HighlightModal;
