import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { axiosPrivate } from "../../../api/axios";
import { ROUTES } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import includesPathname from "../../../utils/includesPathname";
import getSmileAverage from "../api/getSmileAverage";
import Button from "../components/Button";
import Description from "../components/Description";
import Heading from "../components/Heading";
import SubHeading from "../components/SubHeading";

const Gif = styled.img`
  width: 11rem;
  height: 11rem;
  margin-top: 1.25rem;
  margin-bottom: 1.88rem;
`;

const Text = styled.p`
  font-size: 1.13rem;
  font-weight: 600;
  color: ${({ theme }) => theme.grayScaleColor.black};
  text-align: center;
  line-height: 1.69rem;
  letter-spacing: -0.03em;
  margin-bottom: 2.38rem;
`;

const TextHighlight = styled.span`
  color: ${({ theme }) => theme.primary.normal};
  font-size: 1.38rem;
  font-weight: 700;
  font-family: "Montserrat";
`;

const Copyright = styled.span`
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  margin-bottom: 1.88rem;
`;

const SmileMobile = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { cid } = useParams();

  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleChampionsClick = () => {
    if (cid) {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE)
          ? `${ROUTES.GUEST_SMILE_CHAMPIONS}/${cid}`
          : ROUTES.SMILE_CHAMPIONS
      );
    } else {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE)
          ? `${ROUTES.GUEST_SMILE_CHAMPIONS}`
          : ROUTES.SMILE_CHAMPIONS
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getSmileAverage({
        auth: includesPathname(ROUTES.GUEST_SMILE) ? null : auth,
        cid,
      });

      if (response.ok) {
        setTotalCount(response.result?.totalCount || 0);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (isLoading) return null;

  return (
    <>
      <SubHeading text={"AI 행복 미소 챌린지"} />
      <Heading text={"나의 미소는\n어떤 미소일까요?"} />
      <Description
        text={"맘핏의 AI 행복 미소 챌린지를 통해\n나의 미소를 판별해 보세요."}
      />

      <Gif src={`${process.env.PUBLIC_URL}/assets/ai_bounce.gif`} />
      <Text>
        지금까지 총<br />
        <TextHighlight>{totalCount.toLocaleString()}</TextHighlight> 명이 챌린지
        참여 중이에요.
      </Text>
      <Button
        text={"나의 미소 확인하기"}
        clickHandler={() =>
          navigate(
            includesPathname(ROUTES.GUEST_SMILE)
              ? ROUTES.GUEST_SMILE_POPUP
              : ROUTES.SMILE_POPUP,
            { state: { cid } }
          )
        }
      />
      <Button
        text={"찐-미소 챔피언즈 구경하기"}
        clickHandler={handleChampionsClick}
        style={{
          backgroundColor: "#EDF3F3",
          color: "#7C8585",
          marginTop: "10px",
          marginBottom: "2.94rem",
        }}
      />
      <Copyright>ⓒ MAUMFIT</Copyright>
    </>
  );
};

export default SmileMobile;
