import { useNavigate, useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useEffect, useState } from "react";
import axios from "../api/axios";
import FlexRow from "../components/FlexRow";
import FlexColumn from "../components/FlexColumn";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import "react-slideshow-image/dist/styles.css";
import convertPermavac from "../utils/convertPermavac";
import { MOBILE } from "../Constants";
import { getDate, getThisWeekFriday } from "../utils/date";
import useCountdown from "../hooks/useCountdown";
import { isAuthEmpty } from "../utils/isAuthEmpty";
import { Helmet } from "react-helmet";
import lineBreaker from "../utils/lineBreaker";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 5.75rem);
  background: ${({ theme }) => theme.grayScaleColor.white};
  // border: 1px solid black;
`;

const TapbarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
  /* padding-bottom: 0.5rem; */
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const Tap = styled.div`
  flex: 1;
  text-align: center;
  margin: 0 0.75rem;
  ${({ theme, selected }) =>
    selected && `border-bottom: 0.125rem solid ${theme.primary.normal}`};
  padding-bottom: 0.5rem;
  color: ${({ theme, selected }) => selected && theme.primary.normal};
  font-weight: ${({ theme, selected }) => selected && 600};
`;

const Wrapper = styled.div`
  padding: 1.75rem 1.25rem;
`;

const Heading1 = styled.h2`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 2rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Heading2 = styled.h2`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  color: black;
`;

const Heading3 = styled.h3`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  margin-top: 2.56rem;
  margin-bottom: 1.25rem;
`;

const Heading4 = styled.h4`
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  color: #000;
`;

const Section = styled.section`
  padding: 1.75rem 1.25rem;
`;

const Divider = styled.div`
  width: 100%;
  height: 0.3125rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
`;

const BannerImage = styled.img`
  width: 100%;
`;

const Text1 = styled.p`
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 3.4rem */
  color: ${({ theme }) => theme.grayScaleColor.black};
  text-align: center;
  margin-bottom: 0.25rem;
`;

const Text2 = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.3rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  flex: 2;
`;

const Text3 = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.3rem */
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  flex: 1;
  text-align: right;
`;

const Text4 = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.3rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const Button = styled.button`
  border-radius: 0.75rem;
  padding: 1rem 0.25rem;
  text-align: center;
  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  width: 100%;
  color: ${({ color, theme }) => theme.primary.normal};
  background: ${({ theme }) => theme.grayScaleColor.white};
  border: 1px solid ${({ theme }) => theme.primary.normal};
  &:disabled {
    background: ${({ theme }) => theme.grayScaleColor.gray10};
    border: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
    color: ${({ theme }) => theme.grayScaleColor.gray30};
  }
`;

const Callout = styled.div`
  padding: 0.75rem 1.25rem;
  border-radius: 0.625rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const CommentList = styled.div``;

const CommentItem = styled.div`
  padding: 0.75rem;
  border-top: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
  }

  & .comment-block {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 1.225rem */
    letter-spacing: -0.03125rem;
    color: ${({ theme }) => theme.backgroundColor.darkgray80};
  }

  & .comment-date {
    color: ${({ theme }) => theme.grayScaleColor.gray30};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }

  & .comment {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.225rem */
    letter-spacing: -0.03125rem;
    color: ${({ theme }) => theme.backgroundColor.darkgray70};
    word-break: keep-all;
    word-wrap: break-word;
  }
`;

// ===================================
// 마이페이지 스타일 컴포넌트
// ===================================

function MyPageTrainingAll() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const tabIndex = useOutletContext();

  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const response = await axiosPrivate.get(`/home/comments`);
      setData(response.data.data);

      setIsLoading(false);
    };

    if (isAuthEmpty(auth)) {
      return navigate("/login");
    }
    fetch();
  }, []);

  if (isLoading) {
    return <div />;
  }
  return (
    <Container>
      <Helmet>
        <meta name="theme-color" content="#fff" />
      </Helmet>
      {/* <TapbarContainer>
        <Tap selected={tabIndex === 0} onClick={(e) => onTabClick(e, 0)}>
          홈
        </Tap>
        <Tap selected={tabIndex === 1} onClick={(e) => onTabClick(e, 1)}>
          마이페이지
        </Tap>
      </TapbarContainer> */}

      <Section>
        <Heading2>✍ 나의 마음 기록</Heading2>
        <FlexColumn style={{ marginTop: "1rem" }}>
          {data.comments.map((item, index) => (
            <CommentItem key={index}>
              <FlexRow
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "0.25rem",
                }}
              >
                <p className="comment-block">{item.blockId?.title}</p>
                <p className="comment-date">
                  {getDate(item.createdAt, "YYYY.M.D")}
                </p>
              </FlexRow>
              <p className="comment">{lineBreaker(item.comment)}</p>
            </CommentItem>
          ))}
        </FlexColumn>
      </Section>
    </Container>
  );
}

export default MyPageTrainingAll;
