import styled from "styled-components";

import FlexRow from "../../components/FlexRow";
import NoDataText from "./components/NoDataText";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Text1 = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 140%;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const Text2 = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const Card7 = ({ data, checkupCount, style }) => {
  return (
    <Container style={style}>
      {checkupCount > 0 ? (
        data.map((item, index) => (
          <FlexRow key={index} style={{ justifyContent: "space-between" }}>
            <Text1>{item.date}</Text1>
            <Text2>
              {" "}
              {item.answerCount === 0
                ? "-"
                : item.sentences
                    .slice(0, 2)
                    .map((s) => s.word)
                    .join(", ")}
            </Text2>
          </FlexRow>
        ))
      ) : (
        <NoDataText />
      )}
    </Container>
  );
};

export default Card7;
