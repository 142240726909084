import styled from "styled-components";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { motion } from "framer-motion";
import FlexRow from "../../../../components/FlexRow";
import FlexColumn from "../../../../components/FlexColumn";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MOBILE } from "../../../../Constants";

const ModalBackground = styled.div`
  /* width: 100%;
height: 100vh;
background: #000;
opacity: 0.5;
position: fixed;
top: 0;
left: 0;
z-index: 10; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ModalContainer = styled.div`
  max-width: 37.5rem;
  width: 100%;
  max-height: 48rem;
  height: 80%;
  padding: 2.5rem;
  border-radius: 1.8rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox에 대한 스크롤바를 숨깁니다. */
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none; /* WebKit(Chrome, Safari 등)에 대한 스크롤바를 숨깁니다. */
  }
  @media (max-width: ${MOBILE}) {
    padding: 2rem;
    max-width: 22rem;
  }
`;

const ModalTitle = styled.h1`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 2rem */
  letter-spacing: -0.03125rem;
  color: #000;
`;

const AccordionContainer = styled.div``;
const AccordionList = styled.div`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  border-radius: 0.4375rem;
  padding: 1rem 1.75rem;
  @media (max-width: ${MOBILE}) {
    padding: 1rem 1.25rem;
  }
`;
const AccordionHeading = styled(AccordionItemHeading)`
  //   height: 3.94rem;
  position: relative;
  cursor: pointer;
`;

const AccordionHeadingTitle = styled.h3`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: #000;
  flex: 1;
  @media (max-width: ${MOBILE}) {
    // width: 6rem;
  }
`;

const AccordionHeadingSubTitle = styled.h4`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  flex: 5;
  @media (max-width: ${MOBILE}) {
    margin-right: 0.25rem;
    flex: 3;
    word-break: keep-all;
  }
`;

const Panel = styled(motion.ul)`
  font-size: 0.94rem;
  line-height: 1.44rem;
  border-radius: 0.75rem;
  font-weight: 300;
  list-style: none;
  margin-top: 1.25rem;
  padding-left: 1.25rem;
`;

const PanelItem = styled.li`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 1.4875rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  list-style-position: outside;
  list-style-type: disc;
`;
const ArrowDownSvg = styled.svg`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
`;

const ArrowUpSvg = styled(ArrowDownSvg)``;

const ArrowDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
  >
    <path
      d="M3 6.5L7.5 11.5L12 6.5"
      stroke="#7C8585"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 6.5L7.5 11.5L12 6.5"
      stroke="black"
      stroke-opacity="0.2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 6.5L7.5 11.5L12 6.5"
      stroke="black"
      stroke-opacity="0.2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ArrowUp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
  >
    <path
      d="M3 11.5L7.5 6.5L12 11.5"
      stroke="#7C8585"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 11.5L7.5 6.5L12 11.5"
      stroke="black"
      stroke-opacity="0.2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 11.5L7.5 6.5L12 11.5"
      stroke="black"
      stroke-opacity="0.2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DATA = [
  {
    title: "몰입",
    subtitle: "업무를 할 때 시간 가는 줄 모르고 집중력을 발휘하는 경험",
    list: [
      "내 능력을 최대한 발휘하며 높은 수준의 집중을 보여요.",
      "일시적인 걱정, 건강, 사회적 압박과 무관하게 경험하는 가장 즐거운 순간 중 하나예요.",
      "몰입에 빠지면 시간이 빠르게 지나가는 것처럼 느껴져요.",
      "몰입을 느낄 때 긍정적인 감정과 함께 삶의 질이 높아지는 느낌이 들어요.",
    ],
  },
  {
    title: "자율성",
    subtitle: "외부의 압력이나 강요가 아닌, 내 의지에 따라 행동하는 경험",
    list: [
      "내 행동과 삶의 방향성을 스스로 결정하고 조절하는 능력이에요.",
      "내 가치관과 목표를 바탕으로 선택하고, 그에 따라 행동해요.",
      "자율성을 높이면 내 역할과 목표에 대해 높은 동기와 만족감을 가져요.",
    ],
  },
  {
    title: "유능감",
    subtitle: "내가 할 수 있는 일에 대한 자신감을 가지고 업무에 임하는 경험",
    list: [
      "나의 능력을 스스로 인식하고, 도전하며 성취를 느끼는 과정에서 나타나요.",
      "동기와 몰입을 증진시키는 데 도움이 돼요.",
      "유능감을 가지면 새로운 것을 배우고 발전하며 성장하는 데 좋은 영향을 줘요.",
    ],
  },
  {
    title: "성취",
    subtitle: "내가 설정한 목표를 달성하거나, 성과를 인정받은 경험",
    list: [
      "내가 세운 목표를 달성하거나, 능력을 발휘하여 성과를 인정받는 것과 관련이 있어요.",
      "목표를 달성하면 자신감과 적극성이 높아져요.",
      "내 능력을 인정받을 수 있는 기회가 되기도 해요.",
      "사회적 지위나 자아존중감을 향상시키는 데 도움이 돼요.",
    ],
  },
  {
    title: "관계",
    subtitle: "타인과 함께하는 사회적 관계가 원만하게 이루어지는 경험",
    list: [
      "가족, 친구, 동료, 연인 등과의 관계 모두 포함돼요.",
      "적극적으로 타인과의 관계를 구축하고 유지하는 것은 삶의 질을 높여줘요.",
      "원만한 관계 유지를 통해 지식, 경험, 지원 등을 얻을 수 있어요.",
      "서로를 돕고 지지함으로써 상호 의존성과 상호 참여성을 강화할 수 있어요.",
    ],
  },
  {
    title: "긍정정서",
    subtitle: "직장에서 즐거움, 기쁨, 희열 등 긍정적인 감정을 느낀 경험",
    list: [
      "긍정적인 감정을 느끼면 내 삶에 대해 긍정적인 인식을 가질 수 있어요.",
      "스트레스가 감소되고 건강한 심리 상태를 유지할 수 있는 힘이 생겨요.",
      "긍정정서는 관계에도 좋은 영향을 주며, 함께 나누는 즐거움도 느낄 수 있어요.",
    ],
  },
  {
    title: "의미",
    subtitle: "내 능력과 열정을 발휘하여 성취와 만족을 느낀 경험",
    list: [
      "내 삶에 대한 목적과 의미를 가지고 살아가는 것을 뜻해요.",
      "의미란 개인의 가치관, 관심사, 역량 등에 따라 달라질 수 있어요.",
      "내 삶이 의미 있다고 느껴지고, 내 능력과 열정을 발휘하여 성취와 만족을 얻게 돼요.",
      "의미 있는 삶은  어려움에 직면했을 때에도 흔들리지 않는 내면의 힘을 길러줘요.",
    ],
  },
  {
    title: "활력",
    subtitle: "직장에서 신체적, 정신적으로 건강한 한 주를 보낸 경험",
    list: [
      "생명력, 활력, 체력, 기운, 활기 등을 모두 포함해요.",
      "신체적, 정신적, 사회적 안녕과 관련되어 있어요.",
      "건강한 삶을 유지하기 위해서는 균형 잡힌 식습관과 꾸준한 운동이 필요해요.",
      "충분한 휴식과 수면, 긍정적인 마인드셋을 유지하는 것도 중요해요.",
    ],
  },
  {
    title: "행복",
    subtitle: "스스로 느끼는 만족과 긍정적인 감정의 경험",
    list: [
      "행복은 개인의 주관적 경험과 인식에 따라 다르게 경험해요.",
      "외부적인 성취나 재화보다는 내적인 만족과 안녕, 사회적 지지 등에 의해 결정돼요.",
      "나만의 가치관과 목표를 바탕으로 나다운 삶을 살아가며 경험할 수 있어요.",
    ],
  },
];

const CloseSvg = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      onClick={onClick}
    >
      <path
        d="M1 1L18.9999 19"
        stroke="#BBBBBB"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19 1L1.0001 19"
        stroke="#BBBBBB"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

const HelpModal = ({ isOpen, onClose }) => {
  const isMobile = useMediaQuery({ maxWidth: MOBILE });
  const [accordionIndex, setAccordionIndex] = useState([]);
  const onClick = (e) => {
    onClose();
  };
  const onAccordionClick = (index) => {
    if (accordionIndex.includes(index)) {
      setAccordionIndex(accordionIndex.filter((item) => item !== index));
    } else {
      setAccordionIndex((prev) => [...prev, index]);
    }
  };
  useEffect(() => {}, [isOpen]);
  return (
    <div>
      <ModalBackground onClick={onClick}>
        <ModalContainer onClick={(e) => e.stopPropagation()}>
          <FlexRow
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1.88rem",
            }}
          >
            <ModalTitle>🔍 용어가 궁금해요</ModalTitle>
            <CloseSvg onClick={onClose} />
          </FlexRow>
          <FlexColumn style={{ gap: "0.75rem" }}>
            {DATA.map((item, index) => (
              <AccordionContainer key={index}>
                <Accordion allowZeroExpanded={true}>
                  <AccordionList>
                    <AccordionItem>
                      <AccordionHeading onClick={() => onAccordionClick(index)}>
                        <AccordionItemButton>
                          <FlexRow
                            style={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <AccordionHeadingTitle
                            // highlight={accordionOpenAt === 1}
                            >
                              {item.title}
                            </AccordionHeadingTitle>
                            <AccordionHeadingSubTitle>
                              {item.subtitle}
                            </AccordionHeadingSubTitle>

                            {accordionIndex.includes(index) ? (
                              <ArrowUp />
                            ) : (
                              <ArrowDown />
                            )}
                          </FlexRow>
                        </AccordionItemButton>
                      </AccordionHeading>
                      <AccordionItemPanel>
                        <Panel>
                          {item.list.map((li, id) => (
                            <PanelItem key={id}>{li}</PanelItem>
                          ))}
                        </Panel>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </AccordionList>
                </Accordion>
              </AccordionContainer>
            ))}
          </FlexColumn>
        </ModalContainer>
      </ModalBackground>
    </div>
  );
};

export default HelpModal;
