import styled from "styled-components";

const Text = styled.p`
  color: ${({ theme }) => theme.primary.normal};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 150%;
`;

const NumberText = ({ children, style }) => {
  return <Text style={style}>{children}</Text>;
};

export default NumberText;
