import styled from "styled-components";

const Container = styled.div`
  transform: ${({ order }) => (order === "asc" ? "rotate(0)" : "rotate(180)")};
`;

const SortButton = ({ order }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: "0.5rem" }}
    >
      <path
        d="M6.69019 11.8328C6.46704 12.0559 6.10523 12.0559 5.88207 11.8328L2.24552 8.19622C2.02237 7.97307 2.02237 7.61126 2.24552 7.3881C2.46868 7.16494 2.83049 7.16494 3.05364 7.3881L6.28613 10.6206L9.51862 7.3881C9.74178 7.16494 10.1036 7.16494 10.3267 7.3881C10.5499 7.61126 10.5499 7.97307 10.3267 8.19622L6.69019 11.8328ZM5.7147 1.143C5.7147 0.827405 5.97054 0.571568 6.28613 0.571568C6.60172 0.571568 6.85756 0.827405 6.85756 1.143L5.7147 1.143ZM5.7147 11.4287L5.7147 1.143L6.85756 1.143L6.85756 11.4287L5.7147 11.4287Z"
        fill="#7C8585"
      />
    </svg>
  );
};

export default SortButton;
