import { useState } from "react";
import styled from "styled-components";
import axios from "../../../api/axios";
import updateSmilePhoto from "../api/updateSmilePhoto";

const Container = styled.button`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 4px 10px;
  background: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
`;
const Icon = styled.img`
  width: 10px;
  height: auto;
`;
const Number = styled.span`
  color: ${({ theme, isLike }) =>
    isLike ? theme.primary.normal : theme.grayScaleColor.gray30};
  font-size: 11px;
  font-weight: 400;
  line-height: 13.41px;
  margin-left: 6px;
`;

const LikeButton = ({
  auth,
  isLike,
  setIsLike,
  likes,
  setLikes,
  photo,
  setPhotos,
  index,
}) => {
  const handleClick = async (e) => {
    e.stopPropagation();

    if (isLike) {
      if (auth?.user) {
        const updatedPhoto = await updateSmilePhoto({
          auth,
          photo,
          likes: photo.likes - 1,
          isLike: false,
        });
        setIsLike(false);
        setLikes(updatedPhoto.photo.likes);
        setPhotos((cur) => {
          const arr = [...cur];
          arr[index] = updatedPhoto.photo;
          return arr;
        });
      } else {
        // update localstorage: delete the item with photo._id
        localStorage.removeItem(photo._id);

        // update DB: decrease likes -1
        const updatedPhoto = await updateSmilePhoto({
          photo,
          likes: photo.likes - 1,
          isLike: false,
        });
        setIsLike(false);
        setLikes(updatedPhoto.photo.likes);
        setPhotos((cur) => {
          const arr = [...cur];
          arr[index] = updatedPhoto.photo;
          return arr;
        });
      }
    } else {
      if (auth?.user) {
        const updatedPhoto = await updateSmilePhoto({
          auth,
          photo,
          likes: photo.likes + 1,
          isLike: true,
        });
        setIsLike(true);
        setLikes(updatedPhoto.photo.likes);
        setPhotos((cur) => {
          const arr = [...cur];
          arr[index] = updatedPhoto.photo;
          console.log(arr);
          return arr;
        });
      } else {
        // update localstorage: set new item with photo._id
        localStorage.setItem(photo._id, true);

        // update DB: Increase likes +1
        const updatedPhoto = await updateSmilePhoto({
          photo,
          likes: photo.likes + 1,
          isLike: true,
        });
        setIsLike(true);
        setLikes(updatedPhoto.photo.likes);
        setPhotos((cur) => {
          const arr = [...cur];
          arr[index] = updatedPhoto.photo;
          return arr;
        });
      }
    }
  };

  return (
    <Container onClick={handleClick}>
      <Icon
        src={`${process.env.PUBLIC_URL}/assets/${
          isLike ? "like_icon.png" : "unlike_icon.png"
        }`}
      />
      <Number isLike={isLike}>{likes}</Number>
    </Container>
  );
};

export default LikeButton;
