import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d9d9d9;
  padding-top: 12px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Text = styled.span`
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: -0.02em;
  color: #aaaaaa;
`;

const Logo = styled.img`
  width: 16px;
`;

const Footer = () => {
  return (
    <Container>
      <Text>ⓒBLOOM COMPANY</Text>
      <Logo
        src={`${process.env.PUBLIC_URL}/assets/itree/itree_bloom_logo.png`}
      />
    </Container>
  );
};

export default Footer;
