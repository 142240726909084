import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Footer from "./Footer";

const Container = styled.div`
  // border: 1px solid black;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #fcfcfc;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 50px;
  padding: 30px;
  width: 100%;
  max-width: 425px;
  border: 1px solid #fcfcfc;
`;

const TopBar = styled.div`
  width: 100%;
  height: 50px;
  background: #ffc842;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 2.81rem;
  margin: 0 auto;
`;

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fcfcfc;
  position: relative;
  // border: 1px solid black;
`;

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
`;
const BackButton = styled.span`
  font-size: 12px;
  color: #bbb;
  font-weight: 500;
  cursor: pointer;
`;

const StepBarContainer = styled.div`
  width: 52px;
  height: 8px;
  background-color: #eee;
  margin-left: auto;
  margin-right: 0;
  display: flex;
  border-radius: 50px;
`;
const StepBar = styled.div`
  width: 13px;
  height: 8px;
  background-color: ${({ active }) => (active ? "#ffc842" : "#eee")};
  border-radius: 50px;
`;

const SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
`;

const SubTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #ffc842;
  font-family: "Montserrat";
  // text-align: center;
`;

const TitleContainer = styled.div`
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  // border: 1px solid black;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #010101;
`;

const CountText = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  margin-left: 9px;
  font-family: "Montserrat";
  color: ${({ highlight }) => (highlight ? "#ffc842" : "#aaaaaa")};
`;

const BaseInput = styled.input`
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: solid 1px #d9d9d9;
  background-color: #fff;
`;

const Input = styled(BaseInput)`
  margin-bottom: 9px;
  text-align: center;
  font-size: 14px;
  padding: 16px 0;
  font-family: "Montserrat";
  &:nth-child(even) {
    border-color: ${({ enable }) => enable && "#b9d530"};
    &:focus {
      outline: none;
      border-color: #b9d530;
    }
  }
  &:nth-child(odd) {
    border-color: ${({ enable }) => enable && "#dfe126"};
    &:focus {
      outline: none;
      border-color: #dfe126;
    }
  }
  ::placeholder {
    font-size: 14px;
    color: #aaaaaa;
    text-align: center;
    padding-left: 0;
  }
`;

const NextButton = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 7px;
  border: solid 1px ${({ enable }) => (enable ? "#ffc842" : "#d9d9d9")};
  background-color: ${({ enable }) => (enable ? "#ffc842" : "#d9d9d9")};
  color: white;
  font-size: 18px;
  margin-top: 6px;
  margin-bottom: 70px;
  cursor: ${({ enable }) => (enable ? "pointer" : "not-allowed")};
`;

function StepOne({ currentStep = 1 }) {
  const inputRefs = useRef([]);
  const { state } = useLocation();

  const [leafs, setLeafs] = useState(state?.leafs || Array(8).fill(""));
  const navigate = useNavigate();

  const handleLeafChange = (e, index) => {
    setLeafs(leafs.map((leaf, idx) => (idx === index ? e.target.value : leaf)));
  };

  const handleClick = () => {
    if (leafs.filter((leaf) => leaf).length === 8) {
      navigate("/itree/2", {
        state: { leafs, name: state?.name, smallActions: state?.smallActions },
      });
    }
  };

  const handleNameInputFocus = (index) => {
    // treeNameInputRef.current.placeholder = "";
    // console.log(inputRefs);
    inputRefs.current[index].placeholder = "";
  };

  const handleNameInputBlur = (index, placeholder) => {
    inputRefs.current[index].placeholder = placeholder;
    // treeNameInputRef.current.placeholder = "ex) Blooming tree";
  };

  return (
    <Container>
      <Helmet>
        <title>아이트리</title>
        <meta name="theme-color" content="#FCFCFC" />
      </Helmet>
      <Wrapper>
        {/* <TopBar>
          <Logo src={`${process.env.PUBLIC_URL}/assets/itree/itree_logo.png`} />
        </TopBar> */}
        <Screen>
          {/* <NavigationContainer>
            <BackButton onClick={handleBackButtonClick}>{"< Back"}</BackButton>
            <StepBarContainer>
              {Array(3)
                .fill(0)
                .map((_, index) => (
                  <StepBar key={index} active={index < currentStep} />
                ))}
            </StepBarContainer>
          </NavigationContainer> */}
          <SubTitleContainer>
            <SubTitle>STEP {currentStep}</SubTitle>
            <StepBarContainer>
              {Array(3)
                .fill(0)
                .map((_, index) => (
                  <StepBar key={index} active={index < currentStep} />
                ))}
            </StepBarContainer>
          </SubTitleContainer>
          <TitleContainer>
            <Title>8개의 키워드를 채워주세요</Title>
            <CountText highlight={leafs.filter((leaf) => leaf).length === 8}>
              ({leafs.filter((leaf) => leaf).length}/8)
            </CountText>
          </TitleContainer>
          {Array(8)
            .fill()
            .map((_, index) => {
              let placeholder = "";
              if (index < 4) {
                placeholder = "WANNA BE";
              } else {
                placeholder = "I AM";
              }
              return (
                <Input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  value={leafs[index]}
                  enable={leafs[index].length > 0}
                  placeholder={placeholder}
                  onChange={(e) => handleLeafChange(e, index)}
                  onFocus={() => handleNameInputFocus(index)}
                  onBlur={() => handleNameInputBlur(index, placeholder)}
                />
              );
            })}

          <NextButton
            enable={leafs.filter((leaf) => leaf).length === 8}
            onClick={() => handleClick()}
          >
            푯말 만들기
          </NextButton>
          <Footer />
        </Screen>
      </Wrapper>
    </Container>
  );
}

export default StepOne;
