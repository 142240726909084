import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BackgroundContainer from "../../components/BackgroundContainer";
import BackgroundSymbol from "../../components/BackgroundSymbol";
import ErrorText from "../../components/ErrorText";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import Form from "../../components/Form";
import LabelInput from "../../components/LabelInput";
import Logo from "../../components/Logo";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import validatePassword from "../../utils/validatePassword";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const Text = styled.span`
  font-size: 0.94rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  font-weight: 300;
  margin-bottom: 1.88rem;
`;

const Verify = styled.img`
  width: 0.9375rem;
  height: 0.9375rem;
`;

const VerifyText = styled.p`
  font-size: 0.8125rem;
  line-height: 140%; /* 1.1375rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme, state }) =>
    state === "correct"
      ? theme.primary.normal
      : state === "error"
      ? theme.colors.error
      : theme.grayScaleColor.gray70};
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.88rem;
  width: 100%;
  // border: 1px solid black;
`;

const BaseButton = styled.button`
  width: 10rem;
  height: 3.75rem;
  border: none;
  border-radius: 0.31rem;
  cursor: pointer;
  font-size: 1.13rem;
  font-weight: 500;
`;

const CancelButton = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  color: ${({ theme }) => theme.grayScaleColor.gray70};
`;

const ConfirmButton = styled(BaseButton)`
  color: ${({ theme }) => theme.grayScaleColor.white};
  background-color: ${({ theme }) => theme.primary.normal};
  &:hover {
    background-color: ${({ theme }) => theme.primary.hover};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.grayScaleColor.gray10};
    color: ${({ theme }) => theme.grayScaleColor.gray30};
  }
`;

const Password = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [newPasswordConfirmError, setNewPasswordConfirmError] = useState("");
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isPasswordLongerThanEight, setIsPasswordLongerThanEight] = useState();
  const [isPasswordValid, setIsPasswordValid] = useState();

  // const handleNewPasswordChange = (value) => {
  //   setNewPassword(value);
  //   if (!validatePassword(value)) {
  //     setDisabled(true);
  //     setNewPasswordError(
  //       "최소 8자 이상, 영문, 숫자, 특수문자 조합해서 입력해주세요"
  //     );
  //   } else {
  //     setNewPasswordError("");
  //   }
  // };

  // const handleNewPasswordConfirmChange = (value) => {
  //   setNewPasswordConfirm(value);
  //   if (!validatePassword(value)) {
  //     setDisabled(true);
  //     setNewPasswordConfirmError(
  //       "최소 8자 이상, 영문, 숫자, 특수문자 조합해서 입력해주세요"
  //     );
  //   } else {
  //     setNewPasswordConfirmError("");
  //   }
  // };
  const handlePasswordChange = (value) => {
    setNewPassword(value);
    if (value.length === 0) {
      setIsPasswordLongerThanEight();
      setIsPasswordValid();
      return;
    }
    if (value.length > 0 && value.length < 8) {
      setDisabled(true);
      setIsPasswordLongerThanEight(false);
    } else {
      setIsPasswordLongerThanEight(true);
    }
    if (validatePassword(value)) {
      setIsPasswordValid(true);
      setDisabled(false);
    } else {
      setIsPasswordValid(false);
    }
  };

  const updatePasswordClicked = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosPrivate.patch(
        `/users/password`,
        {
          id: auth.user.id,
          currentPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      navigate("/dashboard/mypage");
    } catch (err) {
      setError(err.response.data.msg);
    }
  };

  const handleCancelClick = () => {
    navigate("/dashboard/mypage");
  };

  useEffect(() => {
    setError("");
    if (currentPassword.length > 0 && isPasswordValid) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [currentPassword, newPassword, newPasswordConfirm]);
  return (
    <BackgroundContainer>
      <Wrapper>
        <Form>
          <Logo style={{ marginTop: "3.94rem", marginBottom: "2.5rem" }} />
          <Text>새롭게 변경할 비밀번호를 입력해 주세요.</Text>
          <LabelInput
            htmlFor="현재 비밀번호"
            type="password"
            value={currentPassword}
            error={error}
            onChange={setCurrentPassword}
          />
          {error && (
            <ErrorText style={{ marginTop: "0.63rem" }}>{error}</ErrorText>
          )}
          <div style={{ marginTop: "0.81rem" }} />
          <LabelInput
            htmlFor={"신규 비밀번호"}
            type={isShowPassword ? "text" : "password"}
            error={
              newPassword.length === 0 ? false : !validatePassword(newPassword)
            }
            onChange={handlePasswordChange}
            isShowPassword={isShowPassword}
            setIsShowPassword={setIsShowPassword}
          />
          <FlexColumn
            style={{
              gap: "0.5rem",
              marginTop: "1rem",
              marginBottom: "3.13rem",
              width: "100%",
            }}
          >
            <FlexRow
              style={{
                alignItems: "center",
                gap: "0.62rem",
              }}
            >
              <Verify
                src={
                  isPasswordLongerThanEight === undefined
                    ? `${process.env.PUBLIC_URL}/svg/verify_default.svg`
                    : isPasswordLongerThanEight
                    ? `${process.env.PUBLIC_URL}/svg/verify_correct.svg`
                    : `${process.env.PUBLIC_URL}/svg/verify_error.svg`
                }
              />
              <VerifyText
                state={
                  isPasswordLongerThanEight === undefined
                    ? "default"
                    : isPasswordLongerThanEight
                    ? "correct"
                    : "error"
                }
              >
                최소 8자 이상
              </VerifyText>
            </FlexRow>
            <FlexRow style={{ alignItems: "center", gap: "0.62rem" }}>
              <Verify
                src={
                  isPasswordValid === undefined
                    ? `${process.env.PUBLIC_URL}/svg/verify_default.svg`
                    : isPasswordValid
                    ? `${process.env.PUBLIC_URL}/svg/verify_correct.svg`
                    : `${process.env.PUBLIC_URL}/svg/verify_error.svg`
                }
              />
              <VerifyText
                state={
                  isPasswordValid === undefined
                    ? "default"
                    : isPasswordValid
                    ? "correct"
                    : "error"
                }
              >
                영문, 숫자, 특수문자 조합
              </VerifyText>
            </FlexRow>
          </FlexColumn>
          {/* <LabelInput
            htmlFor="변경할 비밀번호"
            type="password"
            value={newPassword}
            error={newPasswordError}
            onChange={handleNewPasswordChange}
            style={{ marginTop: "0.81rem" }}
          />
          {newPasswordError && (
            <ErrorText style={{ marginTop: "0.63rem" }}>
              {newPasswordError}
            </ErrorText>
          )}
          <LabelInput
            htmlFor="변경할 비밀번호 확인"
            type="password"
            value={newPasswordConfirm}
            error={newPasswordConfirmError}
            onChange={handleNewPasswordConfirmChange}
            style={{ marginTop: "0.81rem" }}
          />
          {newPasswordConfirmError && (
            <ErrorText style={{ marginTop: "0.63rem" }}>
              {newPasswordConfirmError}
            </ErrorText>
          )} */}

          <ButtonContainer>
            <CancelButton onClick={handleCancelClick}>
              다음에 변경하기
            </CancelButton>
            <ConfirmButton onClick={updatePasswordClicked} disabled={disabled}>
              변경 완료하기
            </ConfirmButton>
          </ButtonContainer>
        </Form>
      </Wrapper>
      {/* <BackgroundSymbol /> */}
    </BackgroundContainer>
  );
};

export default Password;
