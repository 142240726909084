import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import FlexRow from "../../../components/FlexRow";
import useAuth from "../../../hooks/useAuth";
import convertPermavac from "../../../utils/convertPermavac";
import { useEffect, useState } from "react";

const Container = styled.div`
  border-radius: 1.25rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 2rem 2.25rem;
  position: relative;
  width: 22.125rem;

  display: flex;
  flex-direction: column;
`;

const Timer = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  margin-bottom: 0.85rem;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  & .number {
    color: ${({ theme }) => theme.primary.normal};
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 1.9rem */
    letter-spacing: -0.03125rem;
  }
`;

const countdown = keyframes`
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 113px;
  }
`;
const CountdownSvg = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  width: 2.8rem;
  height: 2.8rem;
  transform: rotateY(-180deg) rotateZ(-90deg);

  & circle {
    stroke-dasharray: 113px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 4px;
  }
  & .back {
    stroke: ${({ theme }) => theme.backgroundColor.darkgray20};
    fill: none;
  }
  & .front {
    stroke: ${({ theme }) => theme.primary.normal};
    fill: none;
    animation: ${countdown} 3s linear forwards;
  }
`;

const Heading1 = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  margin-bottom: 0.75rem;
`;

const Heading2 = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  margin-bottom: 6.42rem;
`;

const Image = styled.img`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 5.19rem;
`;

const Button = styled.button`
  border-radius: 0.75rem;
  border: 1px solid
    ${({ theme, state }) =>
      state === "modified"
        ? theme.backgroundColor.darkgray20
        : theme.primary.normal};
  background: ${({ theme, state }) =>
    state === "modified"
      ? theme.backgroundColor.darkgray20
      : theme.primary.normal};
  padding: 0.69rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${({ theme, state }) =>
    state === "modified"
      ? theme.backgroundColor.darkgray40
      : theme.grayScaleColor.white};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4375rem;
  cursor: pointer;
`;
const StartModal = ({ onConfirm, onCancel, block, type }) => {
  const { auth } = useAuth();
  const [time, setTime] = useState(3);
  const navigate = useNavigate();

  const points = [];
  for (const key in block.permavacPoint) {
    const point = block.permavacPoint[key];
    if (point > 0) {
      points.push([convertPermavac(key), point]);
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prev) => {
        if (prev - 1 >= 0) {
          return prev - 1;
        } else {
          return 0;
        }
      });
    }, [1000]);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      onConfirm();
    }, 3200);
  }, []);
  return (
    <Container>
      <Timer>
        <div className="number">{time}</div>
        <CountdownSvg>
          <circle className="back" r="18" cx="23" cy="21"></circle>
          <circle className="front" r="18" cx="23" cy="21"></circle>
        </CountdownSvg>
      </Timer>
      <Heading1>3초 후 마음 트레이닝이 시작됩니다.</Heading1>
      <Heading2>
        트레이닝을 마친 후 하단의
        <br />
        트레이닝 완료 버튼을
        <br />
        눌러주세요!
      </Heading2>

      {/* <Heading2>
        오늘의 마음 트레이닝을 완료하여
        <br /> <span style={{ fontWeight: 600 }}>캐시 100원</span>이
        지급되었습니다! (1일 1회)
      </Heading2> */}
      <Image
        src={`${process.env.PUBLIC_URL}/assets/block/start.png`}
        alt="start"
      />
      <FlexRow style={{ width: "100%", gap: "0.62rem" }}>
        <Button onClick={onConfirm}>가이드와 함께하기</Button>
        <Button state={"modified"} onClick={onCancel}>
          가이드 건너뛰기
        </Button>
      </FlexRow>
    </Container>
  );
};

export default StartModal;
