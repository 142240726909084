import styled from "styled-components";
import FlexRow from "../../../../components/FlexRow";
import { MOBILE } from "../../../../Constants";

const Container = styled.table`
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  display: block;
  white-space: nowrap;
`;

const TR = styled.tr`
  height: 3.5rem;
  // vertical-align: middle;
`;

const TH = styled.th`
  vertical-align: middle;
  text-align: center;
  max-width: 100%;
  width: 1000px;
  white-space: nowrap;
  @media (max-width: ${MOBILE}) {
    /* overflow: hidden; */

    /* text-overflow: ellipsis; */
    font-size: 0.875rem;
    padding: 0.69rem 0.42rem;
  }
`;
const THead = styled.thead`
  background: ${({ theme }) => theme.grayScaleColor.white};
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 600;
  ${TR} {
    border-top: 2px solid ${({ theme }) => theme.grayScaleColor.black};
    border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  }
`;
const TBody = styled.tbody`
  ${TR} {
    border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  }
`;
const TD = styled.td`
  vertical-align: middle;
  text-align: center;
  padding: 0 10px;
  max-width: 100%;
  white-space: nowrap;
  color: ${({ color }) => color};
  &:first-child {
    font-weight: 500;
  }
  @media (max-width: ${MOBILE}) {
    /* overflow: hidden; */
    white-space: wrap;
    /* text-overflow: ellipsis; */
    word-break: keep-all;
    padding: 0.69rem 0.42rem;
    font-size: 0.875rem;
    line-height: 160%;
  }
`;

const ReportTable = ({
  colHeader,
  rows,
  diff,
  diffDirection,
  diffAt,
  highlightRow = [],
  highlightCol = [],
  highlightBox = [],
}) => {
  return (
    <Container>
      <THead>
        <TR
          style={{
            background:
              highlightRow.map((item) => item.pos).includes(0) &&
              highlightRow[0].color,
          }}
        >
          {colHeader.map((item, index) => (
            <TH
              key={index}
              colSpan={index === diffAt && 1}
              style={{
                background:
                  highlightBox.find((i) => i.col === index) &&
                  highlightBox.find((i) => i.col === index).color,
              }}
            >
              {item}
            </TH>
          ))}
        </TR>
      </THead>
      <TBody>
        {rows.map((row, index) => {
          return (
            <TR key={index}>
              {row.map((item, itemIndex) => {
                if (diffDirection === "column") {
                  if (itemIndex === diffAt) {
                    return (
                      <TD
                        key={itemIndex}
                        colSpan={1}
                        style={{ textAlign: "center" }}
                      >
                        <span>{item}</span>{" "}
                        {diff[index] >= 0 ? (
                          // <p style={{ color: "#00DBB1", alignItems: "center" }}>
                          <span
                            style={{
                              color: "#00DBB1",
                            }}
                          >
                            (
                            <span
                              style={{
                                fontSize: "0.5rem",
                                // paddingBottom: "10px",
                                marginBottom: "5px",
                                marginRight: "0.25rem",
                                // border: "1px solid black",
                              }}
                            >
                              ▲
                            </span>
                            <span style={{}}>{diff[index]})</span>
                          </span>
                        ) : (
                          // </p>

                          <span
                            style={{
                              color: "#7C8585",
                            }}
                          >
                            (
                            <span
                              style={{
                                fontSize: "0.5rem",
                              }}
                            >
                              ▼
                            </span>{" "}
                            {Math.abs(diff[index])})
                          </span>
                        )}
                      </TD>
                    );
                  } else {
                    return (
                      <TD
                        key={itemIndex}
                        style={{
                          background:
                            highlightCol.map((item) => item.pos).includes(0) &&
                            highlightCol[0].color,
                        }}
                      >
                        {item}
                      </TD>
                    );
                  }
                } else if (diffDirection === "row") {
                  if (index === diffAt) {
                    if (itemIndex === 0) {
                      return (
                        <TD key={itemIndex} style={{ background: "#F8FBFB" }}>
                          {item}
                        </TD>
                      );
                    } else {
                      return (
                        <TD key={itemIndex} style={{ background: "#F8FBFB" }}>
                          {item >= 0 ? (
                            // <p style={{ color: "#00DBB1", alignItems: "center" }}>
                            <span
                              style={{
                                color: "#00DBB1",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "0.5rem",
                                  // paddingBottom: "10px",
                                  marginBottom: "5px",
                                  marginRight: "0.25rem",
                                  // border: "1px solid black",
                                }}
                              >
                                ▲
                              </span>
                              <span style={{}}>{item}</span>
                            </span>
                          ) : (
                            <span
                              style={{
                                color: "#7C8585",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "0.5rem",
                                }}
                              >
                                ▼
                              </span>{" "}
                              {Math.abs(item)}
                            </span>
                          )}
                        </TD>
                      );
                    }
                  } else {
                    return <TD key={itemIndex}>{item}</TD>;
                  }
                } else {
                  return (
                    <TD
                      key={itemIndex}
                      style={{
                        background:
                          highlightCol
                            .map((item) => item.pos)
                            .includes(itemIndex) &&
                          highlightCol.find((i) => i.pos === itemIndex).color,
                        fontWeight:
                          highlightCol
                            .map((item) => item.pos)
                            .includes(itemIndex) && "600",
                      }}
                    >
                      {item}
                    </TD>
                  );
                }
              })}
            </TR>
          );
        })}
      </TBody>
    </Container>
  );
};

export default ReportTable;
