import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Loading from "../../../components/Loading";
import Smile from "../../../components/svg/Smile";
import { PERMAV } from "../../../Constants";
import { checkupSurvey } from "../../../db/survey";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const Container = styled.div`
  width: 100%;
  height: calc(100% - 4.06rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  // border: 1px solid black;
  @media (max-width: 425px) {
    width: 23.44rem;
    border: none;
  }
`;

const Wrapper = styled.div`
  max-width: 62.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  // border: 1px solid black;
  // padding: 50px 20px;
  @media (max-width: 425px) {
    padding: 0 1.25rem;
    border: none;
  }
`;

const Heading = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: -0.04rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin: 2.31rem 0;
  @media (max-width: 425px) {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    margin: 1.44rem 0;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const QuestionContainer = styled.div`
  padding: 1.88rem;
  width: 100%;
  background: ${({ theme }) => theme.grayScaleColor.white};
  display: flex;
  flex-direction: column;
  border-radius: 0.63rem;
  margin-bottom: 1.5rem;
  @media (max-width: 425px) {
    padding: 1.25rem 0.94rem;
    margin-bottom: 1.25rem;
  }
`;

const QuestionText = styled.h1`
  font-size: 1.13rem;
  font-weight: 500;
  line-height: 1.63rem;
  margin-bottom: 1.56rem;
  @media (max-width: 425px) {
    font-size: 1rem;
    margin-bottom: 1.25rem;
  }
  // animation: ${(props) => (props.show ? fadeIn : fadeOut)} 0.3s linear;
`;

const QuestionChoiceContainer = styled.div`
  display: flex;
  // width: 48.75rem;
  padding: 0 5rem;
  justify-content: space-between;
  // border: 1px solid black;
  @media (max-width: 425px) {
    width: 100%;
    justify-content: space-between;
    padding: 0;
  }
`;

const QuestionChoiceButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QuestionChoiceIcon = styled.button`
  width: 6.5rem;
  height: 4.25rem;
  border-radius: 0.63rem;
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  @media (max-width: 425px) {
    width: 3.44rem;
  }
`;
const QuestionChoiceText = styled.span`
  font-size: 0.94rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  font-weight: 300;
  margin-top: 0.69rem;
  @media (max-width: 425px) {
    font-size: 0.81rem;
  }
`;

const QuestionMultipleChoiceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.94rem;
  column-gap: 2rem;
  width: 100%;
  padding: 0 5rem;
  // border: 1px solid black;
  @media (max-width: 425px) {
    // border: 1px solid black;
    gap: 0.44rem;
    padding: 0;
  }
`;

const QuestionMultipleChoiceButton = styled.button`
  width: 8.13rem;
  height: 2.5rem;
  text-align: center;
  background: ${({ theme, selected }) =>
    selected ? theme.primary.normal : theme.grayScaleColor.gray10};
  border: none;
  border-radius: 0.44rem;
  color: ${({ theme, selected }) =>
    selected ? theme.grayScaleColor.white : theme.grayScaleColor.black};
  font-weight: 300;
  cursor: pointer;
  @media (max-width: 425px) {
    width: 6.06rem;
    height: 1.88rem;
  }
`;
const QuestionShortTextInput = styled.input`
  border: none;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  border-bottom: 2px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 0.56rem 0.4rem;
  outline: none;
  font-size: 1rem;
  line-height: 1.5rem;
  &:focus {
    border-bottom: 2px solid ${({ theme }) => theme.primary.normal};
  }
`;

const SubmitButton = styled.button`
  border: none;
  padding: 1.06rem 0;
  background: ${({ theme }) => theme.primary.normal};
  font-size: 1.13rem;
  line-height: 1.63rem;
  width: 20.75rem;
  margin: 0 auto;
  margin-bottom: 3.75rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 0.31rem;
  font-weight: 500;
  &:disabled {
    background: ${({ theme }) => theme.grayScaleColor.gray10};
    color: ${({ theme }) => theme.grayScaleColor.gray30};
  }
  letter-spacing: -0.04em;
  @media (max-width: 425px) {
    margin-top: 2.5rem;
  }
  cursor: pointer;
`;

function CheckupSurvey() {
  const axiosPrivate = useAxiosPrivate();

  const [choices, setChoices] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    [],
    "",
  ]);
  const { persist, auth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const choicesRef = useRef([[], [], [], [], [], []]);

  // const location = useLocation();
  // const params = new URLSearchParams(location.search);

  const navigate = useNavigate();
  // const isMobile = useMediaQuery({
  //   query: "(max-width: 425px)",
  // });

  const handleChoiceClick = (index, value) => {
    if (choices[index] !== null) {
      choicesRef.current[index][choices[index] - 1].style =
        "background: #EEEEEE;";
    }
    choicesRef.current[index][value].style = `background: ${"#00DBB1;"}`;
    const copy = [...choices];

    copy[index] = value + 1;
    setChoices(copy);
  };

  const handleMultipleChoiceClick = (index, data) => {
    const copy = [...choices];
    const wordIndex = copy[6].map((i) => i[0]).findIndex((e) => e === data[0]);

    if (wordIndex === -1) {
      copy[6].push(data);
    } else {
      copy[6].splice(wordIndex, 1);
    }
    setChoices(copy);
  };

  const handleShortTextChange = (e) => {
    const copy = [...choices];
    copy[copy.length - 1] = e.target.value;
    setChoices(copy);
  };

  const isDisabled = () => {
    // return true;
    for (let i = 0; i < 6; i++) {
      if (choices[i] === null) {
        return true;
      }
    }
    return choices[6].length === 0;
  };
  const handleSubmit = async () => {
    // DB에 저장
    const score =
      choices.slice(0, 6).reduce((prev, cur) => prev + cur, 30) +
      choices[6].reduce((prev, cur) => (cur[1] < 0 ? prev - 4 : prev + 4), 0);

    await axiosPrivate.post("/checkup", {
      questions: checkupSurvey.body.questions.map((question) => question.title),
      answers: choices,
      score,
      userId: auth.user.id,
      companyId: auth.user.companyId._id,
      departmentId: auth.user.departmentId._id,
      firstCheckupDate: auth.user.firstCheckupDate,
    });

    let min = Math.min(...choices.slice(0, 6));

    const indexs = [];
    for (let i = 0; i < choices.slice(0, 6).length; i++) {
      if (min === choices.slice(0, 6)[i]) {
        indexs.push(i);
      }
    }
    const random = Math.floor(Math.random() * indexs.length);

    // 결과 페이지 이동
    navigate(`result`, {
      state: {
        results: choices,
        recommendContentType: PERMAV[indexs[random]].name,
        score,
        name: persist ? auth.user.name : null,
      },
    });
  };

  return (
    <Container>
      <Wrapper>
        <Heading>
          {auth.user.name}님, 이번 주<br />
          직장 생활 어떠셨나요?
        </Heading>
        {checkupSurvey.body.questions.map((question, questionIndex) => {
          if (question.type === "choice") {
            return (
              <QuestionContainer key={questionIndex}>
                <QuestionText>
                  {questionIndex + 1}. {question.title}
                </QuestionText>
                <QuestionChoiceContainer>
                  {question.choices.map((choice, choiceIndex) => {
                    return (
                      <QuestionChoiceButton key={choiceIndex}>
                        <QuestionChoiceIcon
                          ref={(el) =>
                            (choicesRef.current[questionIndex][choiceIndex] =
                              el)
                          }
                          onClick={() =>
                            handleChoiceClick(questionIndex, choiceIndex)
                          }
                        >
                          <Smile
                            type={choiceIndex}
                            color={
                              choices[questionIndex] - 1 === choiceIndex
                                ? "white"
                                : "#BBBBBB"
                            }
                          />
                        </QuestionChoiceIcon>
                        <QuestionChoiceText>{choice}</QuestionChoiceText>
                      </QuestionChoiceButton>
                    );
                  })}
                </QuestionChoiceContainer>
              </QuestionContainer>
            );
          } else if (question.type === "multipleChoice") {
            return (
              <QuestionContainer key={questionIndex}>
                <QuestionText>
                  {questionIndex + 1}. {question.title}
                </QuestionText>
                <QuestionMultipleChoiceContainer>
                  {question.multipleChoices.map((word, index) => {
                    return (
                      <QuestionMultipleChoiceButton
                        key={index}
                        selected={choices[6]
                          .map((choice) => choice[0])
                          .includes(word[0])}
                        onClick={() => handleMultipleChoiceClick(index, word)}
                      >
                        {word[0]}
                      </QuestionMultipleChoiceButton>
                    );
                  })}
                </QuestionMultipleChoiceContainer>
              </QuestionContainer>
            );
          } else if (question.type === "short text") {
            return (
              <QuestionContainer key={questionIndex}>
                <QuestionText>
                  {questionIndex + 1}. {question.title}
                </QuestionText>
                <QuestionShortTextInput
                  type="text"
                  onChange={handleShortTextChange}
                />
              </QuestionContainer>
            );
          }
        })}
        <SubmitButton disabled={isDisabled()} onClick={handleSubmit}>
          응답 완료
        </SubmitButton>
      </Wrapper>
      {isLoading && <Loading />}
    </Container>
  );
}

export default CheckupSurvey;
