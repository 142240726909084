import styled from "styled-components";
import lineBreaker from "../../../utils/lineBreaker";

const Title = styled.h1`
  text-align: center;
  font-size: 2rem;
  line-height: 2.69rem;
  font-weight: 700;
  margin-bottom: 1.88rem;
  letter-spacing: -0.04em;
`;

const Heading = ({ text, style }) => (
  <Title style={style}>{lineBreaker(text)}</Title>
);

export default Heading;
