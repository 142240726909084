import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CardLayout from "../CardLayout";
import { useRef } from "react";
import { getDate } from "../../../utils/date";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  border-left: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  /* & div {
    border-right: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
    border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  } */
`;

const Text1 = styled.p`
  font-size: 1.1875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.78125rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  width: 10rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  padding: 0.63rem 0;
  border-right: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  &.red {
    color: #e95151;
  }
`;

const Item = styled.div`
  padding: 0.58rem 0.75rem;
  //   max-width: 12rem;
  height: 8rem;
  max-height: 8.125rem;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  /* border: 1px solid black; */
  border-right: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  &.red {
    color: #ec6767;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const ListItem = styled.div`
  padding: 0.13rem 1rem;
  border-radius: 0.3125rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  display: flex;
  justify-content: space-between;
  &.red {
    background: #fcf6f6;
  }
`;
const Button = styled.div`
  border-radius: 0.4375rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.38rem;
`;
const LeftButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M10.7998 14.3999L4.7998 8.9999L10.7998 3.5999"
          stroke="#777777"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Button>
  );
};
const RightButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M7.20019 14.3999L13.2002 8.9999L7.2002 3.5999"
          stroke="#777777"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Button>
  );
};

function generateCalendar(year, month) {
  const firstDayOfMonth = dayjs(`${year}-${month}-01`);
  const firstDayOfWeek = firstDayOfMonth.startOf("week"); // Start from the first day of the week

  const calendar = [];

  // Loop through each day of the month (total 42 days)
  for (let i = 0; i < 42; i++) {
    const currentDate = firstDayOfWeek.add(i, "day");
    calendar.push(currentDate.format("YYYY-MM-DD"));
  }

  return calendar;
}

const Portal = ({ children }) => {
  const el = document.getElementById("portal");

  return ReactDOM.createPortal(children, el);
};

const CloseButton = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L16.9999 17"
        stroke="#BBBBBB"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M17 1L1.00009 17"
        stroke="#BBBBBB"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

const Calendar = ({ companyId }) => {
  const DAY = [0, 1, 2, 3, 4, 5, 6];
  const axiosPrivate = useAxiosPrivate();
  const nowMonth = dayjs().startOf("month");
  const [trainingData, setTrainingData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  //   console.log(dayjs().startOf("month"));
  const [selectedMonth, setSelectedMonth] = useState(nowMonth);
  const [showModal, setShowModal] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const [clickDate, setClickDate] = useState(null);
  const calendarRef = useRef();
  const calendar = generateCalendar(
    selectedMonth.year(),
    selectedMonth.month() + 1
  );

  const onLeftClick = () => {
    setSelectedMonth((prev) => dayjs(prev).subtract(1, "month"));
    setClickDate(null);
    setShowModal(false);
    setClickPosition({
      x: 0,
      y: 0,
    });
  };
  const onRightClick = () => {
    setSelectedMonth((prev) => dayjs(prev).add(1, "month"));
    setClickDate(null);
    setShowModal(false);
    setClickPosition({
      x: 0,
      y: 0,
    });
  };

  const onDateClick = (e, date) => {
    // console.log(e.clientX, e.clientY);
    if (!trainingData[date]) {
      return;
    }
    const calendarRect = calendarRef.current.getBoundingClientRect();

    // console.log("왼도우 사이즈", windowWidth, windowHeight);
    // console.log(calendarRect);
    let x = e.clientX - calendarRect.left;
    let y = e.clientY - calendarRect.top;
    const dialogWidth = 400;
    if (x + dialogWidth > calendarRect.width) {
      x = calendarRect.width - dialogWidth;
    }

    setClickDate(date);
    setClickPosition({
      x,
      y,
    });
    setShowModal(true);
  };
  //   console.log(days);
  const fetch = async (selectedMonth) => {
    // setIsLoading(true);
    const res = await axiosPrivate.get(
      `/statistics/project/training?companyId=${companyId}&searchStart=${selectedMonth}&searchEnd=${dayjs(
        selectedMonth
      ).add(1, "month")}`
    );

    setTrainingData(res.data.data.trainingData);
    setIsLoading(false);
  };
  useEffect(() => {
    fetch(selectedMonth);
  }, [selectedMonth]);
  if (isLoading) {
    return <div />;
  }

  return (
    <FlexRow style={{ width: "100%" }}>
      <FlexColumn>
        <Text1>
          {selectedMonth.year()}년 {selectedMonth.month() + 1}월
        </Text1>
        <FlexRow style={{ gap: "0.5rem", marginTop: "1rem" }}>
          <LeftButton onClick={onLeftClick} />
          <RightButton onClick={onRightClick} />
        </FlexRow>
      </FlexColumn>

      <Container style={{ position: "relative" }} ref={calendarRef}>
        <Header className="red">일</Header>

        <Header>월</Header>

        <Header>화</Header>

        <Header>수</Header>

        <Header>목</Header>

        <Header>금</Header>

        <Header>토</Header>

        {calendar.map((date, index) => (
          <Item
            key={index}
            onClick={(e) => onDateClick(e, date)}
            style={{
              color:
                dayjs(date).month() === dayjs(selectedMonth).month() &&
                index % 7 === 0
                  ? "#EC6767"
                  : dayjs(date).month() === dayjs(selectedMonth).month()
                  ? "#7C8585"
                  : index % 7 === 0
                  ? "#FAA8A8"
                  : "#BBBBBB",
            }}
          >
            <span>{dayjs(date).get("date")}</span>
            {trainingData[date]?.length > 0 && (
              <FlexColumn style={{ gap: "0.2rem" }}>
                <ListItem
                  className={index % 7 === 0 && `red`}
                  style={{ justifyContent: "space-between" }}
                >
                  <span>전체</span>
                  <span>
                    {trainingData[date].reduce(
                      (acc, cur) => acc + cur.count,
                      0
                    )}
                    회
                  </span>
                </ListItem>
              </FlexColumn>
            )}
          </Item>
        ))}
        {showModal && (
          <CardLayout
            style={{
              width: "17rem",
              overflow: "scroll",
              backgroundColor: "#FFF",
              border: "1px solid #EEE",
              position: "absolute",
              boxShadow: "0px 10px 80px 0px rgba(0, 0, 0, 0.07)",
              top: clickPosition.y,
              left: clickPosition.x,
            }}
          >
            <FlexRow
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: 600,
                  lineHeight: "150%",
                  color: "#111",
                }}
              >{`${getDate(clickDate, "M월 D일(ddd)")}`}</p>
              <CloseButton onClick={() => setShowModal(false)} />
            </FlexRow>
            <div
              style={{
                width: "100%",
                height: "1px",
                background: "#d9d9d9",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            ></div>
            <FlexColumn style={{ gap: "0.75rem" }}>
              {trainingData[clickDate].map((item, index) => {
                return (
                  <FlexRow
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "0.875rem",
                      fontWeight: 500,
                      lineHeight: "150%",
                      letterSpacing: "-0.03125rem",
                    }}
                  >
                    <p>{item._id.departmentName}</p>
                    <p>{item.count}회</p>
                  </FlexRow>
                );
              })}
            </FlexColumn>
          </CardLayout>
        )}
      </Container>
    </FlexRow>
  );
};

export default Calendar;
