import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import FlexRow from "../../../../components/FlexRow";
import { MOBILE } from "../../../../Constants";
import CheckButton from "./CheckButton";
import ScaleQuestion from "./ScaleQuestion";
import ShortTextQuestion from "./ShortTextQuestion";

const QuestionContainer = styled.section`
  width: 100%;
  margin-bottom: 6.25rem;
  padding: 2.81rem 1.88rem;
  border: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.primary.normal : theme.grayScaleColor.gray20};
  opacity: ${({ isActive, done }) => (isActive || done ? 1 : 0.5)};
  ${({ isActive, done }) =>
    isActive &&
    "box-shadow: 0px 10px 80px rgba(0, 0, 0, 0.1);"} //   margin: 30px 0;
    transition: all 0.3s;
  border-radius: 1.25rem;
  @media (max-width: 425px) {
    box-shadow: none;
    padding: 0;
    border: none;
  }
`;

const QuestionTitle = styled.h2`
  font-size: 1.25rem;
  line-height: 1.88rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 500;
  letter-spacing: -0.04em;
  margin-bottom: 2.69rem;
  text-align: center;
  @media (max-width: 425px) {
    margin-bottom: 0.94rem;
  }
`;

const Asterisk = styled.span`
  color: ${({ theme }) => theme.primary.normal};
`;

const QuestionSubText = styled.span`
  font-weight: 500;
  opacity: 0.6;
  font-size: 1rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 1.5rem;
  letter-spacing: -0.04em;
`;

const BoxText = styled.span`
  font-size: 0.88rem;
  line-height: 1.5rem;
  padding: 0.25rem 0.75rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  border-radius: 0.44rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  margin-bottom: 1.63rem;
`;

const Symbol = styled.img`
  width: 1.56rem;
  height: 1.56rem;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.81rem;
`;

const MultipleChoiceContainer = styled.div`
  display: flex;
  // justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`;

const ScaleChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const QuestionForSale = ({
  data,
  done,
  title,
  subTitle,
  required,
  handleInputChange,
  children,
  question,
  questionIndex,
  scaleChoices,
  setScaleChoices,
  scales,
  multipleChoices,
  setMultipleChoices,
}) => {
  const ref = useRef();
  const [isActive, setIsActive] = useState(false);
  const [num, setNum] = useState(0);
  const isMobile = useMediaQuery({
    maxWidth: MOBILE,
  });

  const getPosition = () => {
    // console.log(window.scrollY, window.innerHeight / 2);
    // console.log(ref.current?.getBoundingClientRect());
    const { bottom, top } = ref.current?.getBoundingClientRect();
    if (top < window.innerHeight / 2 && bottom + 90 > window.innerHeight / 2) {
      setIsActive(true);
    } else {
      //   console.log("opacity: 0");
      setIsActive(false);
    }
  };

  useEffect(() => {
    // 첫번째 문항 acitve시키기 위해 getPosition 호출
    getPosition();

    window.addEventListener("scroll", () => getPosition());

    return () => {
      window.removeEventListener("scroll", () => getPosition());
    };
  }, []);

  const convertChoiceText = (choice) => {
    let msg = choice;
    switch (choice) {
      case "계획한 대로 일이 되어 뿌듯했어요":
        msg = "계획한 대로 일이 되어 뿌듯해요";
        break;
      case "맡은 과업, 잘해볼 수 있을 거 같아요":
        break;
      case "일하는 동안 재미를 느꼈어요":
        msg = "일하는 동안 재미를 느껴요";
        break;
      case "스트레스가 너무 많았어요":
        msg = "스트레스가 너무 많아요";
        break;
      case "잘 해내고 싶은데 자신감이 떨어져요":
        break;
      case "다른 사람의 행동에 화가 났어요":
        msg = "다른 사람의 행동에 화가 나요";
        break;
      case "너무 바빠서 정신이 없었어요":
        msg = "너무 바빠서 정신이 없어요";
        break;
      case "마음에 잠시 여유가 생겼어요":
        msg = "마음에 잠시 여유가 생겨요";
        break;
      case "이번 주 유독 만사가 귀찮았어요":
        msg = "요즘 유독 만사가 귀찮아요";
        break;
      default:
        break;
    }

    return msg;
  };

  switch (data.type) {
    case "multiple choices":
      return (
        <QuestionContainer ref={ref} isActive={isActive} done={done}>
          <QuestionTitle>
            <Symbol
              src={`${process.env.PUBLIC_URL}/assets/symbol/symbol_sm.png`}
            />
            {title}{" "}
            {!isMobile && (
              <QuestionSubText>{"(복수 응답 가능)"} </QuestionSubText>
            )}
            {required && <Asterisk>*</Asterisk>}
          </QuestionTitle>
          {isMobile && (
            <FlexRow style={{ justifyContent: "center" }}>
              <BoxText>복수 응답 가능</BoxText>
            </FlexRow>
          )}
          <MultipleChoiceContainer>
            {data.choices.map((item, index) => (
              <CheckButton
                key={index}
                index={index}
                multipleChoices={multipleChoices}
                setMultipleChoices={setMultipleChoices}
              >
                {`${item.emoji} ${convertChoiceText(item.sentence)}`}
              </CheckButton>
            ))}
          </MultipleChoiceContainer>
        </QuestionContainer>
      );
    case "scale":
      return (
        <QuestionContainer ref={ref} isActive={isActive} done={done}>
          <QuestionTitle>
            <Symbol
              src={`${process.env.PUBLIC_URL}/assets/symbol/symbol_state${num}.png`}
            />
            {title} {subTitle && <QuestionSubText>{subTitle}</QuestionSubText>}{" "}
            {required && <Asterisk>*</Asterisk>}
          </QuestionTitle>
          {isMobile && (
            <FlexRow style={{ justifyContent: "center" }}>
              <BoxText>‘매우 그렇다’일수록 7에 가까워져요</BoxText>
            </FlexRow>
          )}
          <ScaleChoiceContainer>
            <ScaleQuestion
              question={title}
              questionIndex={questionIndex}
              scaleChoices={scaleChoices}
              setScaleChoices={setScaleChoices}
              scales={scales}
              setNum={setNum}
            />
          </ScaleChoiceContainer>
        </QuestionContainer>
      );
    case "short text":
      return (
        <QuestionContainer ref={ref} isActive={isActive} done={done}>
          {isMobile ? (
            <QuestionTitle>
              <Symbol
                src={`${process.env.PUBLIC_URL}/assets/symbol/symbol_sm.png`}
              />
              [마음 나누기]
              <br />
              {title} <br />
              {subTitle && <QuestionSubText>{subTitle}</QuestionSubText>}{" "}
              {required && <Asterisk>*</Asterisk>}
            </QuestionTitle>
          ) : (
            <QuestionTitle>
              <Symbol
                src={`${process.env.PUBLIC_URL}/assets/symbol/symbol_sm.png`}
              />
              [마음 나누기] {title}{" "}
              {subTitle && <QuestionSubText>{subTitle}</QuestionSubText>}{" "}
              {required && <Asterisk>*</Asterisk>}
            </QuestionTitle>
          )}
          <ShortTextQuestion
            type="text"
            placeholder="동료들이 당신의 마음을 기다리고 있어요!"
            onChange={handleInputChange}
            isActive={isActive}
          />
        </QuestionContainer>
      );
    default:
      break;
  }
  return (
    <QuestionContainer ref={ref} isActive={isActive} done={done}>
      <QuestionTitle>
        <Symbol src={`${process.env.PUBLIC_URL}/assets/symbol/symbol_sm.png`} />
        {title} {subTitle && <QuestionSubText>{subTitle}</QuestionSubText>}{" "}
        {required && <Asterisk>*</Asterisk>}
      </QuestionTitle>
      {children}
    </QuestionContainer>
  );
};

export default QuestionForSale;
