import styled from "styled-components";

const Heading = styled.h3`
  font-size: 1.13rem;
  line-height: 1.5rem;
  letter-spacing: -0.5px;
  font-weight: 600;
  color: ${({ theme }) => theme.primary.normal};
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

const SectionHeading2 = ({ style, children }) => (
  <Heading style={style}>{children}</Heading>
);

export default SectionHeading2;
