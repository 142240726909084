import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import CloseXButton from "../components/CloseXButton";
import Divider from "../components/Divider";
import ModalHeading2 from "../components/ModalHeading2";

const Image = styled.img`
  max-width: 11.25rem;
  margin-top: 1.7rem;
  margin-bottom: 2.25rem;
`;

const Heading = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 160%; /* 2.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  text-align: center;
`;

const DescriptionText = styled.p`
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 170%; /* 1.59375rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 1.25rem;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.87rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 170%; /* 1.59375rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 1.81rem;
  border-radius: 0.4375rem;
`;

const Separator = styled.div`
  width: 1px;
  height: 1rem;
  background: ${({ theme }) => theme.grayScaleColor.gray30};
  margin: 0 0.5rem;
`;
const ModalButton = styled.button`
  border-radius: 0.4375rem;
  padding: 0.78rem 0;

  // max-width: 11rem;
  border: none;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem; /* 153.333% */
  letter-spacing: -0.0375rem;
  cursor: pointer;
  width: 14rem;
  margin: 0 auto;
  margin-top: 2.21rem;
`;

const CancelButton = styled(ModalButton)`
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const OkButton = styled(ModalButton)`
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
`;

const InviteUsersSuccessModal = ({ closeHandler }) => {
  const handleOnClick = () => {
    window.location.reload();
    closeHandler();
  };
  return (
    <FlexColumn>
      <FlexRow
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <FlexRow style={{ gap: "0.5rem" }}></FlexRow>
        <CloseXButton onClick={handleOnClick} />
      </FlexRow>
      <FlexRow style={{ justifyContent: "center" }}>
        <Image
          src={`${process.env.PUBLIC_URL}/assets/dashboard/manager/send_plane.png`}
        />
      </FlexRow>
      <Heading>
        맘핏 서비스를 이용할 수 있도록
        <br />
        구성원을 초대해 주세요
      </Heading>
      <DescriptionText>
        등록한 구성원들을 선택하고 초대하기 버튼을 누르면
        <br />
        구성원이 비밀번호 설정 후, 멤버십이 활성화 됩니다.
      </DescriptionText>

      <OkButton onClick={handleOnClick}>확인했어요</OkButton>
    </FlexColumn>
  );
};

export default InviteUsersSuccessModal;
