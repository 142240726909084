import { useNavigate, useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useEffect, useState } from "react";
import axios from "../api/axios";
import FlexRow from "../components/FlexRow";
import FlexColumn from "../components/FlexColumn";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import "react-slideshow-image/dist/styles.css";
import convertPermavac from "../utils/convertPermavac";
import { MOBILE } from "../Constants";
import {
  getDate,
  getThisWeekFriday,
  getThisWeekMonday,
  getWeekNumOfMonth,
} from "../utils/date";
import useCountdown from "../hooks/useCountdown";
import { isAuthEmpty } from "../utils/isAuthEmpty";
import { Helmet } from "react-helmet";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 5.75rem);
  background: ${({ theme }) => theme.grayScaleColor.white};
  // border: 1px solid black;
`;

const TapbarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
  /* padding-bottom: 0.5rem; */
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const Tap = styled.div`
  flex: 1;
  text-align: center;
  margin: 0 0.75rem;
  ${({ theme, selected }) =>
    selected && `border-bottom: 0.125rem solid ${theme.primary.normal}`};
  padding-bottom: 0.5rem;
  color: ${({ theme, selected }) => selected && theme.primary.normal};
  font-weight: ${({ theme, selected }) => selected && 600};
`;

const Wrapper = styled.div`
  padding: 1.75rem 1.25rem;
`;

const Heading1 = styled.h2`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 2rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Heading2 = styled.h2`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  color: black;
`;

const Heading3 = styled.h3`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  margin-top: 2.56rem;
  margin-bottom: 1.25rem;
`;

const Heading4 = styled.h4`
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  color: #000;
`;

const Section = styled.section`
  padding: 1.75rem 1.25rem;
`;

const Divider = styled.div`
  width: 100%;
  height: 0.3125rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
`;

const BannerImage = styled.img`
  width: 100%;
`;

const Text1 = styled.p`
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 3.4rem */
  color: ${({ theme }) => theme.grayScaleColor.black};
  text-align: center;
  margin-bottom: 0.25rem;
`;

const Text2 = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.3rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  flex: 2;
`;

const Text3 = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.3rem */
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  flex: 1;
  text-align: right;
`;

const Text4 = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.3rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const Button = styled.button`
  border-radius: 0.75rem;
  padding: 1rem 0.25rem;
  text-align: center;
  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  width: 100%;
  color: ${({ color, theme }) => theme.primary.normal};
  background: ${({ theme }) => theme.grayScaleColor.white};
  border: 1px solid ${({ theme }) => theme.primary.normal};
  &:disabled {
    background: ${({ theme }) => theme.grayScaleColor.gray10};
    border: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
    color: ${({ theme }) => theme.grayScaleColor.gray30};
  }
`;

const Callout = styled.div`
  padding: 0.75rem 1.25rem;
  border-radius: 0.625rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Item = styled.div`
  padding: 1rem 1.25rem;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};

  & .checkup-date {
    color: ${({ theme }) => theme.grayScaleColor.gray30};
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }

  & .checkup {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.225rem */
    letter-spacing: -0.03125rem;
    color: ${({ theme }) => theme.backgroundColor.darkgray70};
  }
`;

// ===================================
// 마이페이지 스타일 컴포넌트
// ===================================

function MyPageCheckup() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [surveys, setSurveys] = useState([]);
  const [surveyYearMonth, setSurveyYearMonth] = useState([]);
  const [monthPeriod, setMonthPeriod] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const surveyEndAt = [new Date().getFullYear(), new Date().getMonth() + 1];
  const handleWeekReportClick = (e, id) => {
    navigate("/checkup/v2/report/week", { state: { surveyId: id } });
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await axiosPrivate.get("/checkup-v2/surveys?type=me");
      if (!response.data.ok) {
        return;
      }
      if (response.data.surveys.length > 0) {
        setMonthPeriod(response.data.monthPeriod);
        const firstSurvey =
          response.data.surveys[response.data.surveys.length - 1];

        const [firstSurveyWeek, firstSurveyMonth] = getWeekNumOfMonth(
          new Date(firstSurvey.openAt)
        );
        const firstSurveyYear = new Date(firstSurvey.openAt).getFullYear();
        let y = firstSurveyYear;
        let m = firstSurveyMonth;
        const yearMonth = [];

        while (surveyEndAt[0] !== y || surveyEndAt[1] !== m) {
          // getWeekNumOfMonth(new Date(y, m-1,1));
          yearMonth.push([
            y,
            m,
            getDate(new Date(y, m - 1, 1), "MM.DD"),
            getDate(new Date(y, m, 0), "MM.DD"),
          ]);
          if (m > 12) {
            m = 1;
            y++;
          } else {
            m++;
          }
        }

        setSurveyYearMonth(yearMonth.reverse());
        setSurveys(response.data.surveys);
        // console.log(response.data.surveys);
      }
      setIsLoading(false);
    };

    if (isAuthEmpty(auth)) {
      return navigate("/login");
    }
    fetch();
  }, []);
  // console.log(surveyYearMonth, monthPeriod);
  if (isLoading) {
    return <div />;
  }

  return (
    <Container>
      <Helmet>
        <meta name="theme-color" content="#fff" />
      </Helmet>
      <Section>
        <Heading1>마음 체크업</Heading1>
        <Heading2 style={{ marginTop: "1rem" }}>📊 주간 마음 리포트</Heading2>
        <FlexColumn style={{ marginTop: "1rem", gap: "0.5rem" }}>
          {surveys.slice(0, 5).map((survey, index) => {
            const [week, month] = getWeekNumOfMonth(new Date(survey.openAt));
            const start = getThisWeekMonday(
              new Date(survey.weekStartAt || survey.openAt),
              "YY.MM.DD"
            );
            const end = getThisWeekFriday(
              new Date(survey.weekEndAt || survey.openAt),
              "YY.MM.DD"
            );
            const year = new Date(survey.openAt).getFullYear();
            return (
              <Item onClick={(e) => handleWeekReportClick(e, survey._id)}>
                <FlexRow
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "0.25rem",
                  }}
                >
                  <p className="checkup-date">{`${start} ~ ${end}`}</p>
                  <p className="checkup">
                    {" "}
                    {month}월 {week}주차 마음 체크업 결과
                  </p>
                </FlexRow>
              </Item>
            );
          })}
        </FlexColumn>
        <Button
          style={{ marginTop: "1.5rem" }}
          onClick={() => navigate("week")}
        >
          주간 마음 리포트 전체 보기
        </Button>
      </Section>
      <Divider style={{ height: "0.0625rem" }} />
      <Section>
        <Heading2>📆 월간 마음 리포트</Heading2>
        <FlexColumn style={{ marginTop: "1rem", gap: "0.5rem" }}>
          {surveyYearMonth.slice(0, 5).map((item, index) => {
            if (!monthPeriod[item[1]]) {
              return null;
            }
            return (
              <Item>
                <FlexRow
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "0.25rem",
                  }}
                  onClick={() =>
                    navigate(
                      `/checkup/v2/report?year=${item[0]}&month=${item[1]}`
                    )
                  }
                >
                  <p className="checkup-date">
                    {" "}
                    {`${getDate(
                      monthPeriod[item[1]][0] || item[2],
                      "YY.MM.DD"
                    )} ~ ${getDate(
                      monthPeriod[item[1]][1] || item[3],
                      "YY.MM.DD"
                    )}`}
                  </p>
                  <p className="checkup">{item[1]}월 마음 리포트</p>
                </FlexRow>
              </Item>
            );
          })}
        </FlexColumn>
        <Button
          style={{ marginTop: "1.5rem" }}
          onClick={() => navigate("month")}
        >
          월간 마음 리포트 전체 보기
        </Button>
      </Section>
    </Container>
  );
}

export default MyPageCheckup;
