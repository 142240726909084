import axios, { axiosPrivate } from "../../../api/axios";

const uploadSmilePhoto = async ({ auth, formData }) => {
  if (auth?.user) {
    try {
      const response = await axiosPrivate.post(`/smile/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      console.log(response.data);
      return response.data;
    } catch (err) {
      console.err(err);
      return null;
    }
  }

  try {
    const response = await axios.post(`/smile/guests/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.err(err);
    return null;
  }
};
export default uploadSmilePhoto;
