import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const ToggleBtn = styled.button`
  width: 2.69rem;
  height: 1.44rem;
  border-radius: 3.13rem;
  border: none;
  cursor: pointer;
  background-color: ${({ theme, toggle }) =>
    !toggle ? theme.grayScaleColor.gray20 : theme.primary.normal};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
`;
const Circle = styled.div`
  background-color: white;
  width: 1.06rem;
  height: 1.06rem;
  border-radius: 50%;
  position: absolute;
  left: 0.25rem;
  transition: all 0.25s ease-in-out;
  ${({ toggle }) =>
    toggle &&
    `
      transform: translate(1.1rem, 0);
      transition: all 0.25s ease-in-out;
    `}
`;

const Text = styled.span`
  font-size: 0.81rem;
  line-height: 1.44rem;
  font-weight: 300;
  color: #777777;
  margin-left: 0.56rem;
`;

const Toggle = ({ toggle, setToggle }) => {
  const clickedToggle = () => {
    setToggle((prev) => !prev);
  };
  return (
    <Container>
      <ToggleBtn onClick={clickedToggle} toggle={toggle}>
        <Circle toggle={toggle} />
      </ToggleBtn>
      {!toggle ? <Text>마음 비공개</Text> : <Text>마음 공개</Text>}
    </Container>
  );
};

export default Toggle;
