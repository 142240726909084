import React, { useState } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 30.44rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 0.63rem;
  padding: 2.19rem;
  margin-bottom: 3.44rem;
  display: flex;
  justify-content: center;
  @media (max-width: 425px) {
    margin-top: 21px;
    width: 100%;
    height: 300px;
    margin-bottom: 0;
  }
`;

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ChartDataLabels
);

const options = (department) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
      // align: "end",
      labels: {
        padding: 10,
        usePointStyle: false,
        pointStyle: "circle",
        pointStyleWidth: 32,
        font: {
          size: 12,
          weight: 300,
          family: "PretendardVariable",
        },
      },
      title: {
        padding: 10,
      },
    },
    datalabels: {
      backgroundColor: function (context) {
        return context.dataset.borderColor;
      },
      color: "white",
      font: {
        weight: "bold",
      },
      anchor: "start",
      align: function (context) {
        console.log(context, department);
        if (context.dataset.label === "지난 주 평균, 나") {
          if (
            context.dataset.data[context.dataIndex] >
            Number(department[context.dataIndex])
          ) {
            return "center";
          } else {
            return "start";
          }
        } else {
          if (
            context.dataset.data[context.dataIndex] >
            Number(department[context.dataIndex])
          ) {
            return "center";
          } else {
            return "start";
          }
        }
      },
      //   formatter: Math.round,
      padding: 4,
    },
  },
  scales: {
    r: {
      angleLines: {
        display: true,
      },
      beginAtZero: false,
      suggestedMax: 5,
      suggestedMin: 0,
      ticks: {
        stepSize: 1,
      },
      pointLabels: {
        color: "black",
      },
    },
  },
  // Core options
  aspectRatio: 4 / 3,
  elements: {
    point: {
      hoverRadius: 7,
      radius: 3,
    },
  },
});

export function CheckupRadarChart({ maumfit, department, me }) {
  //   console.log("Radar Chart =>", companyAverage, myAverage);
  const data = {
    labels: ["긍정정서", "몰입", "관계", "의미", "성취", "활력"],
    datasets: [
      {
        label: "지난 주 평균, 나",
        backgroundColor: "rgba(0, 210, 193, 0.2)",
        borderColor: "#00D2C1",
        data: me,
      },
      {
        label: "지난 주 평균, 회사",
        backgroundColor: "rgba(255, 211, 180, 0.2)",
        borderColor: "rgba(255, 211, 180, 1)",
        data: department,
        // datalabels: {
        //   align: "end",
        //   anchor: "end",
        // },
      },
      {
        label: "지난 주 평균, 맘핏",
        backgroundColor: "rgba(1, 127, 198, 0.2)",
        borderColor: "rgba(1, 127, 198, 1)",
        data: maumfit,
        // datalabels: {
        //   align: "start",
        //   anchor: "start",
        // },
      },
    ],
  };
  return (
    <Container>
      <Radar
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "bottom",
              // align: "end",
              labels: {
                padding: 10,
                usePointStyle: false,
                pointStyle: "circle",
                pointStyleWidth: 32,
                font: {
                  size: 12,
                  weight: 300,
                  family: "PretendardVariable",
                },
              },
              title: {
                padding: 10,
              },
            },
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.borderColor;
              },
              color: "white",
              font: {
                weight: "bold",
              },

              align: function (context) {
                // console.log(context);
                // console.log(department);
                if (context.dataset.label === "지난 주 평균, 나") {
                  // console.log("hello me");
                  if (
                    context.dataset.data[context.dataIndex] >
                    department[context.dataIndex]
                  ) {
                    // console.log("greater than");
                    return "end";
                  } else {
                    // console.log("less than");
                    return "start";
                  }
                }
                // else {
                //   console.log("hi");
                //   if (
                //     context.dataset.data[context.dataIndex] >
                //     department[context.dataIndex]
                //   ) {
                //     return "center";
                //   } else {
                //     return "start";
                //   }
                // }
              },
              anchor: "start",
              //   formatter: Math.round,
              padding: 4,
              // display: true,
            },
          },
          scales: {
            r: {
              angleLines: {
                display: true,
              },
              beginAtZero: false,
              suggestedMax: 5,
              suggestedMin: 0,
              ticks: {
                stepSize: 1,
              },
              pointLabels: {
                color: "black",
              },
            },
          },
          // Core options
          aspectRatio: 4 / 3,
          elements: {
            point: {
              hoverRadius: 7,
              radius: 3,
            },
          },
        }}
      />
    </Container>
  );
}
