import styled from "styled-components";
import FlexColumn from "../../../../components/FlexColumn";
import { MOBILE } from "../../../../Constants";
import ReportBadge from "./ReportBadge";
import { useMediaQuery } from "react-responsive";

const Container = styled.div`
  border-top: 2px solid ${({ theme }) => theme.grayScaleColor.black};
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  font-size: 1.125rem;
  line-height: 160%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 1.88rem 0;
  @media (max-width: ${MOBILE}) {
    border-top: none;
    border-bottom: 1px solid rgba(187, 187, 187, 0.3);
    padding: 0;
    padding-bottom: 2rem;
    margin-top: 2rem;
    font-size: 0.71875rem;
    gap: 2rem;
  }
`;

const Col = styled.p`
  //   border: 1px solid black;
  display: flex;
  flex: 1;
`;
const Row = styled.div`
  padding: 0 2rem;
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 600;
  color: ${({ theme }) => theme.grayScaleColor.black};
  @media (max-width: ${MOBILE}) {
    padding: 0;
  }
`;

const OrderText = styled.span`
  color: ${({ theme }) => theme.primary.normal};
  margin-right: 1rem;
  flex: 1;
  @media (max-width: ${MOBILE}) {
    flex: 0.5;
    margin-right: 0.5rem;
  }
`;

const TitleText = styled.span`
  margin-right: 1.75rem;
  flex: 4;
  @media (max-width: ${MOBILE}) {
    font-size: 0.71875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 1.15rem */
    letter-spacing: -0.02013rem;
    flex: 2;
    margin-right: 0.25rem;
  }
`;
const Score = styled.span`
  font-size: 1.375rem;
  @media (max-width: ${MOBILE}) {
    font-size: 0.88438rem;
  }
`;
const ScoreDiff = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  @media (max-width: ${MOBILE}) {
    font-size: 0.56275rem;
  }
`;

const BarContainer = styled.div`
  position: relative;
  //   width: 300px;
  height: 15px;
  @media (max-width: ${MOBILE}) {
    height: 0.60294rem;
  }
`;
const FullBar = styled.div`
  background: ${({ theme, type }) =>
    type === "me" ? theme.primary.normal : theme.grayScaleColor.gray10};
  opacity: ${({ type }) => (type === "me" ? 0.1 : 0.5)};
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 50px;
`;
const BarGraph = styled.div`
  position: absolute;
  background: ${({ theme, type }) =>
    type === "me" ? theme.primary.normal : theme.grayScaleColor.gray20};
  top: 0;
  left: 0;
  width: ${({ value }) => (value * 100) / 7}%;
  height: 15px;
  z-index: 2;
  border-radius: 50px;
  @media (max-width: ${MOBILE}) {
    height: 0.60294rem;
  }
`;

const Badge = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
  border-radius: 50px;
  color: ${({ theme, level }) =>
    level === "good"
      ? theme.primary.dark
      : level === "ok"
      ? theme.colors.success
      : "#F11B1B"};
  background: ${({ theme, level }) =>
    level === "good"
      ? theme.primary.light
      : level === "ok"
      ? "#EEF8FD"
      : "#FDEFEF"};
`;

const Bar = ({ type, value }) => {
  return (
    <BarContainer style={{ position: "relative" }}>
      <FullBar type={type}></FullBar>
      <BarGraph type={type} value={value} />
    </BarContainer>
  );
};

const ReportHorizontalBarList = ({ rows, diff, showBadge = true }) => {
  const PERCENTAGE = 20;

  const isMobile = useMediaQuery({ maxWidth: MOBILE });
  return (
    <Container>
      {rows.map((row, index) => (
        <Row key={index}>
          <OrderText>{index + 1}</OrderText>
          <TitleText>{row.type}</TitleText>
          <FlexColumn style={{ gap: "0.37rem", flex: isMobile ? 4 : 12 }}>
            <Bar type={"me"} value={Number(row.value.toFixed(1))}></Bar>
            <Bar
              type={"company"}
              value={Number((diff[index]?.value).toFixed(1))}
            ></Bar>
          </FlexColumn>
          <FlexColumn style={{ alignItems: "center", flex: isMobile ? 3 : 8 }}>
            <Score>{Number(row.value.toFixed(1))}</Score>
            <ScoreDiff>
              평균보다 {isMobile && <br />}
              {row.value >= diff[index]?.value
                ? `+${(((row.value - diff[index]?.value) * 100) / 7).toFixed(
                    1
                  )}%`
                : `-${(((diff[index]?.value - row.value) * 100) / 7).toFixed(
                    1
                  )}%`}
            </ScoreDiff>
          </FlexColumn>
          {showBadge && (
            <ReportBadge
              level={
                ((row.value - diff[index]?.value) * 100) / 7 > PERCENTAGE
                  ? "good"
                  : ((row.value - diff[index]?.value) * 100) / 7 < -PERCENTAGE
                  ? "warning"
                  : "ok"
              }
            >
              {((row.value - diff[index]?.value) * 100) / 7 > PERCENTAGE
                ? "대단해요"
                : ((row.value - diff[index]?.value) * 100) / 7 < -PERCENTAGE
                ? "걱정돼요"
                : "괜찮아요"}{" "}
            </ReportBadge>
          )}
        </Row>
      ))}
    </Container>
  );
};

export default ReportHorizontalBarList;
