export const ROUTES = {
  SMILE: "/ai-smile",
  SMILE_POPUP: "/ai-smile/popup",
  SMILE_ANALYZE: "/ai-smile/analyze",
  SMILE_RESULT: "/ai-smile/result",
  SMILE_CHAMPIONS: "/ai-smile/champions",
  GUEST_SMILE: "/smile-challenge",
  GUEST_SMILE_POPUP: "/smile-challenge/popup",
  GUEST_SMILE_ANALYZE: "/smile-challenge/analyze",
  GUEST_SMILE_RESULT: "/smile-challenge/result",
  GUEST_SMILE_CHAMPIONS: "/smile-challenge/champions",
};

export const MOBILE = "425px";
export const TABLET_LANDSCAPE = "1440px";

// 사용자 권한
// 백엔드와 sync 맞춰야 함.
export const USER_NORMAL = 1;
export const USER_LEADER = 2;
export const USER_MANAGER = 3;
export const USER_ADMIN = 4;
export const USER_PM = 200;
export const USER_SUPER_ADMIN = 500;

// 사용자 활성 상태
// 백엔드와 sync 맞춰야 함.
export const STATUS_ACTIVE = 1;
export const STATUS_INVITE = 2;
export const STATUS_INACTIVE = 3;
export const STATUS_SIGNUP = 4;

export const POSITIVE_TEAM = "#ed2b2b";
export const POSITIVE_LEADER = "#ff812c";
export const RECOVERY = "#ffc842";
export const MOTIVATION = "#dae233";
export const MAUM = "#00c0e0";
export const MBSAT = "#7ca1d4";
export const POSITIVE_IDENTITY = "#b782b9";
export const PERMAVAC = [
  "몰입",
  "자율성",
  "유능감",
  "성취",
  "관계",
  "긍정정서",
  "의미",
  "활력",
  "행복",
];
export const PERMAV = [
  {
    name: "긍정정서",
    word: "Positive Emotions",
    tooltip: "기쁨, 감사, 평온과 같은 기분 좋은 정서",
    color: "#ffc842",
  },
  {
    name: "몰입",
    word: "Engagement",
    tooltip: "어떤 활동에 푹 빠져서 시간 가는 줄 모르는 경험",
    color: "#dae233",
  },
  {
    name: "관계",
    word: "Relationships",
    tooltip: "마음을 주고받을 수 있는 사람들과 연결되어 있는 상태",
    color: "#ff812c",
  },
  {
    name: "의미",
    word: "Meaning",
    tooltip: "삶의 목적을 알고 그것을 추구하는 것",
    color: "#00c0e0",
  },
  {
    name: "성취",
    word: "Accomplishment",
    tooltip: "목표를 위해 노력하고 그 자체에 즐거움을 느끼는 것",
    color: "#b782b9",
  },
  {
    name: "활력",
    word: "Vitality",
    tooltip: "건강한 몸과 마음을 잘 돌보는 것",
    color: "#ed2b2b",
  },
];
export const TYPE_MEMBER = "member";
export const TYPE_LEADER = "leader";
