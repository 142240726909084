import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Heading1 from "./components/Heading1";
import Heading2 from "./components/Heading2";
import NoDataText from "../../dashboard/components/NoDataText";
import useAuth from "../../../hooks/useAuth";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - var(--navbar-height));
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  // max-width: 1000px;
`;

const ListContainer = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  border: 1px solid ${({ theme }) => theme.grayScaleColor.white};
  padding: 1.25rem 2.5rem;
  gap: 1rem;
  border-radius: 10px;
  &:hover {
    background: ${({ theme }) => theme.primary.light};
    border: 1px solid ${({ theme }) => theme.primary.normal};
    svg {
      path {
        fill: ${({ theme }) => theme.primary.normal};
      }
    }
  }
  transition: all 0.3s;
  cursor: pointer;
`;

const Date = styled.span`
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  margin-right: 2.5rem;
  width: 8rem;
  //   border: 1px solid black;
`;

const Question = styled.span`
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  line-height: 160%;
`;

const ArrowSvg = styled.svg`
  &:hover {
    path {
      fill: ${({ theme }) => theme.primary.normal};
    }
  }
`;

const PaginationContainer = styled.div`
  margin: 0 auto;
  margin-top: 2.25rem;
  max-width: 10rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const PaginationButton = styled.img`
  cursor: pointer;
`;

const PaginationNumber = styled.span`
  font-size: 0.81rem;
  color: ${({ theme, selected }) =>
    selected
      ? theme.backgroundColor.darkgray70
      : theme.backgroundColor.darkgray40};
  width: 30px;
  height: 30px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme, selected }) =>
    selected && `background: ${theme.grayScaleColor.white};`}
  cursor: pointer;
`;

const ShareList = ({ style }) => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const companyId = params.get("id") || auth.user.companyId._id;
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = (e, id) => {
    navigate(`${id}?id=${companyId}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axiosPrivate.get(
        `/checkup-v2/surveys?companyId=${companyId}`
      );

      if (response.data.ok) {
        setSurveys(response.data.surveys);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Container
      style={{
        ...style,
        height: surveys.length > 0 ? "auto" : "calc(100vh - 300px)",
      }}
    >
      {surveys.length > 0 ? (
        <Wrapper>
          {/* <FlexColumn style={{ alignItems: "center" }}>
          <Heading2 style={{ marginBottom: "0.5rem" }}>마음 나누기</Heading2>
          <Heading1>매주 동료들과 나눈 마음</Heading1>
        </FlexColumn> */}
          <ListContainer>
            {surveys.map((item, index) => (
              <Item key={item._id} onClick={(e) => handleClick(e, item._id)}>
                <FlexRow style={{ alignItems: "center" }}>
                  <Date>{item.period}</Date>
                  <Question>Q. {item.shareQuestionId.question}</Question>
                </FlexRow>
                <ArrowSvg
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="#00DBB1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.7071 9.79289C21.0976 10.1834 21.0976 10.8166 20.7071 11.2071L14.3431 17.5711C13.9526 17.9616 13.3195 17.9616 12.9289 17.5711C12.5384 17.1805 12.5384 16.5474 12.9289 16.1569L18.5858 10.5L12.9289 4.84315C12.5384 4.45262 12.5384 3.81946 12.9289 3.42893C13.3195 3.03841 13.9526 3.03841 14.3431 3.42893L20.7071 9.79289ZM2 11.5C1.44772 11.5 1 11.0523 1 10.5C1 9.94772 1.44772 9.5 2 9.5V11.5ZM20 11.5H2V9.5H20V11.5Z"
                    fill="#D9D9D9"
                  />
                </ArrowSvg>
              </Item>
            ))}
          </ListContainer>
          <PaginationContainer>
            <PaginationButton
              src={`${process.env.PUBLIC_URL}/svg/pagination_arrow_left.svg`}
            />
            <PaginationNumber selected={true}>1</PaginationNumber>

            <PaginationButton
              src={`${process.env.PUBLIC_URL}/svg/pagination_arrow_right.svg`}
            />
          </PaginationContainer>
        </Wrapper>
      ) : (
        <FlexColumn style={{ justifyContent: "center" }}>
          <NoDataText />
        </FlexColumn>
      )}
    </Container>
  );
};

export default ShareList;
