import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexColumn = ({ children, style, onClick }) => {
  return (
    <Container style={style} onClick={onClick}>
      {children}
    </Container>
  );
};

export default FlexColumn;
