import { useEffect, useRef } from "react";
import styled from "styled-components";

const Input = styled.div`
  width: 100%;
  border-radius: 0.3125rem;
  ${({ isOpen }) =>
    isOpen && "border-bottom-left-radius: 0; border-bottom-right-radius: 0;"}
  border: 1px solid
    ${({ theme, active }) =>
    active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  color: ${({ theme, active }) =>
    active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  padding: 0.75rem;
  font-size: 0.9375rem;
  line-height: 140%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  outline: none;

  // flex: 3;
  width: 17.75rem;
`;
const Item = styled.div`
  color: #222;
  &:hover {
    color: ${({ theme }) => theme.primary.normal};
  }
`;

const SDropdown = ({ close, isOpen, items, setItem, item, type, label }) => {
  const selectRef = useRef();

  const handleClick = (e, data) => {
    setItem(data);
    close(false);
  };
  let index = 0;
  if (item) {
    index = items.indexOf(item);
  }
  useEffect(() => {
    if (type === "birthYear") {
      selectRef.current.scrollTop = 600;
    }
  }, []);
  return (
    <Input active={isOpen || item} onClick={() => close(!isOpen)}>
      {!item ? label : typeof item === "object" ? item.name : item}
      <img
        src={`${process.env.PUBLIC_URL}/svg/dropdown_triangle_down.svg`}
        style={{}}
        id="workExperience"
        onClick={() => close(!isOpen)}
      />
      {isOpen && (
        <div
          ref={selectRef}
          style={{
            position: "absolute",
            left: "-1px",
            right: "-1px",
            top: "2.7rem",
            border: "1px solid #222222",
            height: "11.1875rem",
            overflowY: "auto",
            background: "white",
            borderRadius: "0rem 0rem 0.4375rem 0.4375rem",
            padding: "0.25rem 1rem",
            zIndex: 1,
            filter: "drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.20))",
          }}
        >
          {items.map((data, index) => (
            <Item
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                // color: data === item ? "#00DBB1" : "#222",
                borderBottom: "1px solid #EEEEEE",
                padding: "0.56rem 0",
                fontSize: "0.875rem",
                lineHeight: "140%",
              }}
              onClick={(e) => handleClick(e, data)}
            >
              {typeof data === "object" ? data.name : data}
            </Item>
          ))}
        </div>
      )}
    </Input>
  );
};

export default SDropdown;
