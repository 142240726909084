import styled from "styled-components";
import FlexColumn from "../../../../components/FlexColumn";
import { getToday } from "../../../../utils/date";

const Container = styled.div`
  width: 9.75rem;
  height: 7.06rem;
  background: ${({ theme, complete }) =>
    complete ? theme.primary.normal : "#F3F6F6"};
  border-radius: 1.25rem;
  color: ${({ theme, complete }) =>
    complete ? theme.grayScaleColor.white : theme.grayScaleColor.gray30};
  font-weight: 600;
  padding: 1rem 1rem 1rem 1.19rem;
  position: relative;
`;

const StampText = styled.span`
  font-size: 1rem;
  letter-spacing: -0.5px;
`;

const StampDate = styled.span`
  font-size: 0.88rem;

  line-height: 1.06rem;
`;

const Check = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${({ theme, complete }) =>
    complete ? theme.grayScaleColor.white : "#E4E4E4"};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const CheckIcon = ({ complete }) => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9091 0.806932C15.568 1.30899 15.6952 2.25019 15.1931 2.90914L8.33601 11.9091C8.07102 12.2569 7.66747 12.4718 7.23098 12.4975C6.79449 12.5232 6.36853 12.3571 6.06457 12.0428L0.921716 6.72464C0.345828 6.12911 0.361744 5.1795 0.957264 4.60361C1.55278 4.02772 2.5024 4.04364 3.07829 4.63916L7.00765 8.70248L12.8069 1.09101C13.3089 0.432058 14.2501 0.304869 14.9091 0.806932Z"
        fill={complete ? "#00DBB1" : "white"}
      />
    </svg>
  );
};

const StampBox = ({ index, complete }) => {
  return (
    <Container complete={complete}>
      {index === 4 && (
        <img src={`${process.env.PUBLIC_URL}/svg/stamp_medal.svg`} />
      )}
      <Check complete={complete}>
        <CheckIcon complete={complete} />
      </Check>
      <FlexColumn style={{ position: "absolute", bottom: "1rem" }}>
        <StampText>체크업 {index + 1}회 완료</StampText>
        {index === 0 && <StampDate>{getToday()}</StampDate>}
      </FlexColumn>
    </Container>
  );
};

export default StampBox;
