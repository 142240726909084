import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import FlexRow from "../../components/FlexRow";
import BarChart from "./components/BarChart";
import CardBox from "./components/CardBox";
import { Chart } from "./components/Chart";
import Filter from "./components/Filter";
import Heading from "./components/Heading";
import { LeaderChart } from "./components/LeaderChart";
import QuestionHeading from "./components/QuestionHeading";
import SubHeading from "./components/SubHeading";
import Table from "./components/Table";
import TopNav from "./components/TopNav";

const Container = styled.div`
  width: 100%;
  background: #edf3f3;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Page = styled.div`
  width: 100%;
`;

const Document = styled.div`
  width: 100%;
  background: #edf3f3;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading2 = styled.h2`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.13rem;
  line-height: 1.69rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

const InfoBox = styled.div`
  display: flex;
  //   justify-content: space-evenly;
  padding: 0.5rem 1.75rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  margin-bottom: 1.63rem;
  border-radius: 0.44rem;
  gap: 2rem;
  //   width: 42.56rem;
`;

const InfoBoxText = styled.span`
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  font-size: 0.88rem;
  font-weight: 500;
  line-height: 1.38rem;
  margin-left: 0.5rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 3.38rem 3.13rem;
  margin-bottom: 3.75rem;
`;

const QuestionContainer = styled.section`
  margin-bottom: 6.25rem;
`;

const QuestionSummaryBox = styled.div`
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  padding: 1rem 1.75rem;
  display: flex;
  align-items: center;
  border-radius: 0.44rem;
`;

const QuestionSummaryText = styled.span`
  line-height: 1.56rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  margin-left: 0.5rem;
`;

const FooterText = styled.p`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 1.88rem;
  text-align: center;
`;

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.19rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  background: ${({ theme }) => theme.backgroundColor.darkgray20};
  border: none;
  border-radius: 0.31rem;
  gap: 0.25rem;
  cursor: pointer;
`;

const DownloadButton = styled.button`
  padding: 0.5rem 0.75rem;
  background: ${({ theme }) => theme.primary.normal};
  color: white;
  font-size: 1rem;
  line-height: 1.19rem;
  font-weight: 600;
  border-radius: 0.31rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 1.25rem;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 20.31rem;
  border-radius: 0.63rem;
  padding: 20px;
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  @media (max-width: 425px) {
    width: 100%;
    height: 225px;
    padding: 0;
    margin-top: 20px;
  }
  // border: 1px solid black;
  font-family: Montserrat;
`;

const PoweredBy = styled.p`
  color: ${({ theme }) => theme.primary.normal};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 150%;
`;

const SmartTrainingLeaderReport = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [dates, setDates] = useState([]);
  const [courseName, setCourseName] = useState();
  const [leaderName, setLeaderName] = useState("");
  const [highIndex, setHighIndex] = useState();
  const [lowIndex, setLowIndex] = useState();
  const [checkupName, setCheckupName] = useState("");
  const [groupOptions, setGroupOptions] = useState(new Array(3).fill(0));
  const [jobOptions, setJobOptions] = useState(new Array(10).fill(0));
  const [genderOptions, setGenderOptions] = useState(new Array(2).fill(0));
  const [ageOptions, setAgeOptions] = useState(new Array(4).fill(0));
  const [isDownloading, setIsDownloading] = useState(false);
  const docRef = useRef();
  const downloadButtonRef = useRef();

  const groups = [1, 2, 3];
  const jobs = [
    "경영관리/지원",
    "제조/생산",
    "유통/무역",
    "마케팅/광고",
    "디자인",
    "고객서비스",
    "IT/인터넷",
    "영업",
    "연구개발/설계",
    "기타",
  ];
  const genders = ["남성", "여성"];
  const ages = ["0~3년 차", "4~6년 차", "7~9년 차", "10년 차 이상"];

  const showPdf = async () => {
    navigate("/smart-training-set/pdf", {
      state: {
        leaderName,
        courseName,
        questions,
        results,
        dates,
        high: `Q${highIndex + 1}. ${questions[highIndex]}`,
        low: `Q${lowIndex + 1}. ${questions[lowIndex]}`,
      },
    });
    // downloadButtonRef.current.style = "display: none";
    // // console.log(docRef.current.children);
    // const date = new Date();
    // setIsDownloading(true);
    // try {
    //   const pdf = await convertToPdf(docRef.current.children);
    //   pdf.save(
    //     `마음체크업 리포트-${date.getFullYear()}${
    //       date.getMonth() + 1
    //     }${date.getDate()}.pdf`
    //   );
    // } finally {
    //   setIsDownloading(false);
    //   downloadButtonRef.current.style = "display: block";
    //   // setTimeout(() => {
    //   //   console.log("done");
    //   //   setIsDownloading(false);
    //   // }, 5000);
    // }

    // window.open(pdf.output("bloburl"));
  };

  const convertToPdf = async (elems) => {
    const pdf = new jsPDF("p", "mm", "a4");
    // const elem = docRef.current;
    for (let i = 0; i < elems.length; i++) {
      const canvas = await html2canvas(elems[i], { scale: 3 });
      const image = canvas.toDataURL("image/jpeg", 2);
      const imgProperties = pdf.getImageProperties(image);

      const pageWidth = 210; //pdf.internal.pageSize.getWidth();
      const pageHeight = pageWidth * 1.414;
      const imageHeight =
        (imgProperties.height * pageWidth) / imgProperties.width;
      //   const imageHeight = (canvas.height * pageWidth) / canvas.width;
      const imageWidth =
        (imgProperties.width * pageHeight) / imgProperties.height;
      let position = 0;

      let heightLeft = imageHeight;
      //   pdf.addImage(
      //     image,
      //     "jpeg",
      //     pageWidth - imageWidth > 0 ? (pageWidth - imageWidth) / 2 : 0, // x축 위치, 모바일 대응.
      //     position,
      //     imageWidth,
      //     pageHeight,
      //     undefined,
      //     "FAST"
      //   );
      //   if (i !== elems.length - 1) {
      //     pdf.addPage();
      //   }
      // }

      while (heightLeft > 40) {
        position = heightLeft - imageHeight;
        pdf.addPage();
        pdf.addImage(
          image,
          "JPEG",
          0,
          position,
          pageWidth,
          imageHeight,
          undefined,
          "FAST"
        );
        heightLeft -= pageHeight;
      }
    }

    return pdf;
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_SMART_TOOL_SERVER_BASE_URL}/maum-checkup/leader-report`,
        {
          params: {
            pid: searchParams.get("pid") || "",
          },
        }
      );
      setData(res.data.results);
      //   setCheckupName(res.data.checkupName);
      const round1Result = res.data.results.filter((item) => item.round === 1);
      const round2Result = res.data.results.filter((item) => item.round === 2);
      const round3Result = res.data.results.filter((item) => item.round === 3);

      const questionResults = [];
      for (let i = 0; i < res.data.questions.length; i++) {
        questionResults[i] = [
          {
            score:
              round1Result
                .map((item) => item.answers[i])
                .reduce((prev, cur) => prev + cur, 0) / round1Result.length,
            total: round1Result.length,
          },
          {
            score:
              round2Result
                .map((item) => item.answers[i])
                .reduce((prev, cur) => prev + cur, 0) / round2Result.length,
            total: round2Result.length,
          },
          {
            score:
              round3Result
                .map((item) => item.answers[i])
                .reduce((prev, cur) => prev + cur, 0) / round3Result.length,
            total: round3Result.length,
          },
        ];
      }

      let max = 3;
      let maxIndex = 0;
      let min = 28;
      let minIndex = 0;

      for (let i = 0; i < questionResults.length; i++) {
        let sum = questionResults[i].reduce(
          (prev, cur) => prev + (cur.score || 0),
          0
        );

        if (sum < min) {
          min = sum;
          minIndex = i;
        }
        if (sum > max) {
          max = sum;
          maxIndex = i;
        }
      }

      //   console.log(questionResults);
      setHighIndex(maxIndex);
      setLowIndex(minIndex);
      setQuestions(res.data.questions);
      setResults(questionResults);
      setFilteredResults(questionResults);
      //   setAllResult(res.data.all);
      setDates(res.data.dates);
      setCourseName(res.data.checkupName);
      setLeaderName(res.data.leaderName || "박블룸");
      setIsLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const groupConditions = [];
    const jobConditions = [];
    const genderConditions = [];
    const ageConditions = [];
    groupOptions.forEach((item, index) => {
      if (item === 1) {
        groupConditions.push(groups[index]);
      }
    });
    jobOptions.forEach((item, index) => {
      if (item === 1) {
        jobConditions.push(jobs[index]);
      }
    });
    genderOptions.forEach((item, index) => {
      if (item === 1) {
        genderConditions.push(genders[index]);
      }
    });
    ageOptions.forEach((item, index) => {
      if (item === 1) {
        ageConditions.push(ages[index]);
      }
    });

    let filtered = data;

    if (groupConditions.length > 0) {
      filtered = filtered.filter((item) =>
        groupConditions.includes(item.group)
      );
    }
    if (jobConditions.length > 0) {
      filtered = filtered.filter((item) => jobConditions.includes(item.job));
    }
    if (genderConditions.length > 0) {
      filtered = filtered.filter((item) =>
        genderConditions.includes(item.gender)
      );
    }
    if (ageConditions.length > 0) {
      filtered = filtered.filter((item) => ageConditions.includes(item.age));
    }

    const questionResults = [];
    const round1Result = filtered.filter((item) => item.round === 1);
    const round2Result = filtered.filter((item) => item.round === 2);
    const round3Result = filtered.filter((item) => item.round === 3);
    for (let i = 0; i < questions.length; i++) {
      questionResults[i] = [
        {
          score:
            round1Result.length === 0
              ? null
              : round1Result
                  .map((item) => item.answers[i])
                  .reduce((prev, cur) => prev + cur, 0) / round1Result.length,
          total: round1Result.length,
        },
        {
          score:
            round2Result.length === 0
              ? null
              : round2Result
                  .map((item) => item.answers[i])
                  .reduce((prev, cur) => prev + cur, 0) / round2Result.length,
          total: round2Result.length,
        },
        {
          score:
            round3Result.length === 0
              ? null
              : round3Result
                  .map((item) => item.answers[i])
                  .reduce((prev, cur) => prev + cur, 0) / round3Result.length,
          total: round3Result.length,
        },
      ];
    }

    let max = 3;
    let maxIndex = 0;
    let min = 28;
    let minIndex = 0;

    for (let i = 0; i < questionResults.length; i++) {
      let sum = questionResults[i].reduce(
        (prev, cur) => prev + (cur.score || 0),
        0
      );

      if (sum < min) {
        min = sum;
        minIndex = i;
      }
      if (sum > max) {
        max = sum;
        maxIndex = i;
      }
    }
    setHighIndex(maxIndex);
    setLowIndex(minIndex);
    setFilteredResults(questionResults);

    //     console.log(groupOptions, jobOptions, genderOptions, ageOptions);
  }, [groupOptions, jobOptions, genderOptions, ageOptions]);

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <Page ref={docRef}>
        <Document>
          {/* <TopNav
            src={`${process.env.PUBLIC_URL}/assets/logo/topbar_leader_logo.png`}
            width={170}
            style={{
              background:
                "linear-gradient(269.78deg, #80C8BA -29.71%, #1F3733 128.01%)",
            }}
          /> */}
          <Wrapper>
            <SubHeading style={{ marginTop: "52px", marginBottom: "8px" }}>
              마음 리포트
            </SubHeading>
            <Heading style={{ marginBottom: "28px" }}>
              {leaderName} 님, 안녕하세요 :)
            </Heading>
            <Heading2>
              지난 한 달 간의 ‘{courseName}’ 교육생 분들의 마음 변화를
              살펴보세요.
            </Heading2>
            <InfoBox>
              <FlexRow style={{ alignItems: "center" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/calendar_icon.png`}
                  alt="calendar_icon"
                  width={22}
                />
                <InfoBoxText>1차 체크업: 워크숍 전</InfoBoxText>
              </FlexRow>
              <FlexRow style={{ alignItems: "center" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/calendar_icon.png`}
                  alt="calendar_icon"
                  width={22}
                />
                <InfoBoxText>2차 체크업: 워크숍 직후</InfoBoxText>
              </FlexRow>
              <FlexRow style={{ alignItems: "center" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/calendar_icon.png`}
                  alt="calendar_icon"
                  width={22}
                />
                <InfoBoxText>3차 체크업 : 워크루틴 4회 진행 후</InfoBoxText>
              </FlexRow>
            </InfoBox>
            <DownloadButton ref={downloadButtonRef} onClick={showPdf}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/download_icon.png`}
                width={24}
                height={24}
                alt={"download icon"}
              />
              <span>리포트 다운로드</span>
            </DownloadButton>
            <FlexRow
              style={{
                width: "100%",
                justifyContent: "space-between",
                marginBottom: "2.75rem",
              }}
            >
              <CardBox
                src={`${process.env.PUBLIC_URL}/assets/icons/chart_up_icon.png`}
                title={"가장 높은 점수를 기록한 문항"}
                answer={
                  questions[highIndex] &&
                  `Q${highIndex + 1}. ${questions[highIndex]}`
                }
              />
              <CardBox
                src={`${process.env.PUBLIC_URL}/assets/icons/chart_down_icon.png`}
                title={"가장 낮은 점수를 기록한 문항"}
                answer={
                  questions[lowIndex] &&
                  `Q${lowIndex + 1}. ${questions[lowIndex]}`
                }
              />
            </FlexRow>
            <ContentContainer>
              <QuestionContainer>
                <QuestionHeading
                  style={{
                    marginBottom: "1.5rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>전체 개요</span>
                  <Filter
                    groupOptions={groupOptions}
                    setGroupOptions={setGroupOptions}
                    jobOptions={jobOptions}
                    setJobOptions={setJobOptions}
                    genderOptions={genderOptions}
                    setGenderOptions={setGenderOptions}
                    ageOptions={ageOptions}
                    setAgeOptions={setAgeOptions}
                  />
                </QuestionHeading>

                <BarChart labels={questions} results={filteredResults} />

                {/* <Table results={results} /> */}
              </QuestionContainer>
              {questions.map((q, index) => (
                <QuestionContainer key={index}>
                  <QuestionHeading style={{ marginBottom: "1.5rem" }}>
                    Q{index + 1}. {q}
                  </QuestionHeading>
                  <QuestionSummaryBox>
                    <QuestionSummaryText>
                      전체 평균 점수를 기준으로, 워크숍 진행 전에 비해 진행 후{" "}
                      <span style={{ fontWeight: 700 }}>
                        {isNaN(filteredResults[index][1].score)
                          ? `${(-filteredResults[index][0].score).toFixed(1)}점`
                          : filteredResults[index][1].score -
                              filteredResults[index][0].score !==
                              0 &&
                            `${(
                              filteredResults[index][1].score -
                              filteredResults[index][0].score
                            ).toFixed(1)}점`}{" "}
                      </span>
                      <span style={{ fontWeight: 700 }}>
                        {isNaN(filteredResults[index][1].score)
                          ? `하락`
                          : filteredResults[index][1].score -
                              filteredResults[index][0].score >
                            0
                          ? `상승`
                          : (
                              filteredResults[index][1].score -
                              filteredResults[index][0].score
                            ).toFixed(1) < 0
                          ? `하락`
                          : `동일`}
                      </span>
                      하였으며
                      <br />
                      현업에 돌아가 워크루틴 4회 실천 후의 변화는{" "}
                      <span style={{ fontWeight: 700 }}>
                        {isNaN(filteredResults[index][2].score)
                          ? `${(-filteredResults[index][0].score).toFixed(1)}`
                          : filteredResults[index][2].score -
                              filteredResults[index][0].score >
                            0
                          ? `+${(
                              filteredResults[index][2].score -
                              filteredResults[index][0].score
                            ).toFixed(1)}`
                          : (
                              filteredResults[index][2].score -
                              filteredResults[index][0].score
                            ).toFixed(1)}
                        점
                      </span>{" "}
                      입니다.
                    </QuestionSummaryText>
                  </QuestionSummaryBox>
                  <ChartContainer>
                    <LeaderChart
                      scale={7}
                      results={filteredResults[index]}
                      dates={dates.map((d, i) => [`${i + 1}차 체크업`])}
                    />
                  </ChartContainer>
                </QuestionContainer>
              ))}
            </ContentContainer>
            {/* <img
          src={`${process.env.PUBLIC_URL}/assets/symbol/symbol_sm.png`}
          alt="maum fit symbol image"
          width={35}
          style={{ marginBottom: "2rem" }}
        />
        <FooterText style={{ marginBottom: "1.5rem" }}>
          나의 마음 상태를 가볍게 체크하며,
          <br />
          잠시 나를 성찰해보세요.
        </FooterText>
        <FooterText style={{ fontWeight: 600, marginBottom: "4rem" }}>
          변화는, 아주 작은 실천부터 시작됩니다.
        </FooterText> */}
            <FlexRow style={{ alignItems: "center", marginBottom: "3rem" }}>
              <PoweredBy>Powered by</PoweredBy>
              <img
                style={{
                  width: "5.4375rem",
                  height: "1rem",
                  marginLeft: "0.5rem",
                }}
                src={`${process.env.PUBLIC_URL}/assets/logo/powered_by_logo.png`}
              />
            </FlexRow>
          </Wrapper>
        </Document>
      </Page>
    </Container>
  );
};

export default SmartTrainingLeaderReport;
