import styled from "styled-components";

const Button = styled.button`
  cursor: pointer;
  border: none;
  background-color: inherit;
`;

const Svg = styled.svg``;

const RightArrowButton = ({ className, onClick }) => {
  return (
    <Button className={className} onClick={onClick}>
      <Svg
        className={className}
        width="9"
        height="16"
        viewBox="0 0 9 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.999998 15L8 8L1 1"
          stroke="#777777"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Button>
  );
};

export default RightArrowButton;
