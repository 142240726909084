import styled from "styled-components";
import ReactLoading from "react-loading";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  // min-height: calc(100vh - 60px);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: black;
  color: white;
  opacity: 0.7;
`;

const Loader = styled(ReactLoading)``;

const Text = styled.p`
  margin-top: 30px;
`;

function Loading({ type = "spinningBubbles", text = "Loading..." }) {
  return (
    <Container>
      <Loader type={type} color={"white"} height={"80px"} width={"80px"} />
      <Text>{text}</Text>
    </Container>
  );
}

export default Loading;
