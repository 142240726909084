import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import FlexRow from "../../../../components/FlexRow";
import { MOBILE } from "../../../../Constants";

const Container = styled.div`
  width: 100%;
`;

const Item = styled.div`
  flex: 1;
  padding-bottom: 0.5rem;
  text-align: center;
  //   border: 1px solid black;
  border-bottom: 0.125rem solid ${({ theme }) => theme.grayScaleColor.gray10};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  cursor: pointer;
  &:nth-child(${({ clickIndex }) => clickIndex + 1}) {
    color: ${({ theme }) => theme.primary.normal};
    border-bottom: 0.125rem solid ${({ theme }) => theme.primary.normal};
  }
  transition: all 0.3s;
`;

const Badge = styled.div`
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  border-radius: 3.125rem;
  cursor: pointer;
  &:nth-child(${({ clickIndex }) => clickIndex + 1}) {
    color: ${({ theme }) => theme.grayScaleColor.white};
    background: ${({ theme }) => theme.primary.normal};
  }
`;

const ReportTab = ({ tabs, clickIndex, setClickIndex }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE})`,
  });

  const handleTabClick = (e, index) => {
    setClickIndex(index);
  };
  if (isMobile) {
    return (
      <FlexRow
        style={{
          flexWrap: "wrap",
          gap: "0.37rem 0.75rem",
          marginBottom: "1.5rem",
        }}
      >
        {tabs.map((tab, index) => (
          <Badge
            key={index}
            clickIndex={clickIndex}
            onClick={(e) => handleTabClick(e, index)}
          >
            {tab}
          </Badge>
        ))}
      </FlexRow>
    );
  }
  return (
    <FlexRow
      style={{
        justifyContent: "space-evenly",
        marginTop: "1.56rem",
        marginBottom: "2.13rem",
      }}
    >
      {tabs.map((tab, index) => (
        <Item
          key={index}
          clickIndex={clickIndex}
          onClick={(e) => handleTabClick(e, index)}
        >
          {tab}
        </Item>
      ))}
    </FlexRow>
  );
};

export default ReportTab;
