import { useState } from "react";
import styled from "styled-components";

const Container = styled.div``;
const Button = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 3px;
  position: absolute;
  right: 12px;
  // border: 1px solid black;
  padding: 0 4px;
  cursor: pointer;
`;

const Circle = styled.div`
  width: 2px;
  height: 2px;
  margin: 1px 0;
  border-radius: 50%;
  background: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const ButtonLists = styled.ul``;

const ButtonItems = styled.li`
  background: #111;
  border-radius: 5px;
  padding: 1px 12px;
  color: white;
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  letter-spacing: -0.04em;
  position: absolute;
  right: 12px;
  top: 30px;
  cursor: pointer;
`;

const MoreButton = ({ deleteClick }) => {
  const [clicked, setClicked] = useState(false);
  const handleMoreButtonClick = () => {
    setClicked((cur) => !cur);
  };
  const handleDeleteClick = async () => {
    setClicked(false);
    deleteClick();
  };
  return (
    <Container>
      <Button onClick={handleMoreButtonClick}>
        <Circle />
        <Circle />
        <Circle />
      </Button>
      {clicked && (
        <ButtonLists onClick={handleDeleteClick}>
          <ButtonItems>삭제 요청하기</ButtonItems>
        </ButtonLists>
      )}
    </Container>
  );
};

export default MoreButton;
