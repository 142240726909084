import styled from "styled-components";

const Container = styled.button`
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.88rem;
  letter-spacing: -0.04em;
  border-radius: 0.75rem;
  border: none;
  height: 3.81rem;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.primary.hover};
  }
  &:disabled {
    background: ${({ theme, disabledColor }) =>
      disabledColor || theme.grayScaleColor.gray20};
    color: ${({ theme }) => theme.grayScaleColor.gray30};
  }
  transition: all 0.3s;
`;

const Button = ({ disabled, disabledColor, children, onClick, style }) => {
  return (
    <Container
      disabled={disabled}
      disabledColor={disabledColor}
      onClick={onClick}
      style={style}
    >
      {children}
    </Container>
  );
};

export default Button;
