import styled from "styled-components";
import FlexRow from "../../../components/FlexRow";
import { useNavigate } from "react-router-dom";

const ModalContainer = styled.div`
  padding: 3.19rem 3.75rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 1.875rem;
  max-width: 80%;
`;

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 2.25rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const ModalCard = styled.div`
  padding: 2rem;
  border-radius: 0.75rem;
  border: 1px solid #dee9e9;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  width: 16.25rem;
`;

const ModalCardSubTitle = styled.h4`
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.primary.normal};
`;

const ModalCardTitle = styled.h3`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 2.25rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
`;

const ModalCardButton = styled.button`
  background: inherit;
  padding: 0.5rem 0.75rem;
  border-radius: 0.3125rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  cursor: pointer;
`;

const SwitchDashboardModal = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <ModalContainer>
      <FlexRow
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <ModalTitle>대시보드로 이동</ModalTitle>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          onClick={onClose}
          style={{ cursor: "pointer" }}
        >
          <path
            d="M1 1L18.9999 19"
            stroke="#BBBBBB"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M19 1L1.0001 19"
            stroke="#BBBBBB"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </FlexRow>
      <FlexRow style={{ gap: "1.25rem", marginTop: "2rem" }}>
        <ModalCard>
          <ModalCardSubTitle>구성원용</ModalCardSubTitle>
          <ModalCardTitle>개인 대시보드</ModalCardTitle>
          <ModalCardButton onClick={() => navigate("/dashboard")}>
            이동하기
          </ModalCardButton>
        </ModalCard>
        <ModalCard>
          <ModalCardSubTitle>리더용</ModalCardSubTitle>
          <ModalCardTitle>리더 대시보드</ModalCardTitle>
          <ModalCardButton onClick={() => navigate("/leader/dashboard")}>
            이동하기
          </ModalCardButton>
        </ModalCard>
      </FlexRow>
    </ModalContainer>
  );
};

export default SwitchDashboardModal;
