import { Helmet } from "react-helmet";
import styled from "styled-components";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import { MOBILE } from "../../Constants";
import lineBreaker from "../../utils/lineBreaker";

const Container = styled.div`
  width: 100%;
  max-width: ${MOBILE};
  background: ${({ theme }) => theme.grayScaleColor.white};
  margin: 0 auto;
`;

const Topbar = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  background: ${({ theme }) => theme.primary.normal};
`;

const Wrapper = styled.div`
  padding: 1.94rem 1.88rem;
`;

const Logo = styled.img`
  width: 6.25rem;
  height: 1.13188rem;
`;

const Text3 = styled.p`
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 160%;
  color: ${({ theme }) => theme.primary.normal};
  text-align: center;
  margin-bottom: 0.12rem;
`;

const Text1 = styled.p`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  text-align: center;
  margin-bottom: 1.94rem;
`;

const CardContainer = styled.div`
  background: ${({ backgroundColor }) => backgroundColor};
  padding: 1.44rem 1.69rem 1.31rem 1.75rem;
  border-radius: 0.75rem;
`;

const CardTitle = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 150%; /* 1.875rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 0.75rem;
`;
const CardDesc = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.21875rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  margin-bottom: 1.44rem;
`;

const CardTextButton = styled.button`
  border: none;
  padding: 0;
  text-align: left;
  background: none;
  color: ${({ theme }) => theme.primary.normal};
`;

const CardImage = styled.img`
  width: 4.5625rem;
`;

const Card = ({ backgroundColor, title, description, button, link, src }) => {
  const handleClick = () => {
    const random = Math.floor(Math.random() * 24 + 1);
    window.open(title === "마음 트레이닝" ? link + random : link, "_blank");
  };
  return (
    <CardContainer backgroundColor={backgroundColor} onClick={handleClick}>
      <Helmet>
        <meta name="theme-color" content="#00DBB1" />
      </Helmet>
      <FlexRow
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <FlexColumn>
          <CardTitle>{title}</CardTitle>
          <CardDesc>{lineBreaker(description)}</CardDesc>
          <CardTextButton>{button} →</CardTextButton>
        </FlexColumn>
        <CardImage src={src} alt={`${title} image`} />
      </FlexRow>
    </CardContainer>
  );
};

const Tutorial = () => {
  const data = [
    {
      title: "AI 미소 챌린지",
      description: "AI가 즉석에서 판별하는\n나의 찐-미소 가능성",
      button: "체험하기",
      link: "https://app.maum.fit/ai-smile",
      src: `${process.env.PUBLIC_URL}/assets/tutorial/ai_tutorial_sm.png`,
      backgroundColor: "#E8FBF7",
    },
    {
      title: "마음 체크업",
      description: "나의 마음을 건강하게 관리하는\n60초 마음 체크업",
      button: "체험하기",
      link: "https://app.maum.fit/checkup/v2/demo?id=0",
      src: `${process.env.PUBLIC_URL}/assets/tutorial/checkup_tutorial_sm.png`,
      backgroundColor: "#E8FBF7",
    },
    {
      title: "마음 트레이닝",
      description: "체크업 결과에 따라 추천되는\n나를 위한 맞춤 트레이닝",
      button: "체험하기",
      link: `https://app.maum.fit/blocks/`,
      src: `${process.env.PUBLIC_URL}/assets/tutorial/training_tutorial_sm.png`,
      backgroundColor: "#EBFAF4",
    },
    {
      title: "메타버스 맘핏 센터",
      description: "언제 어디서나 방문 가능한\n맘핏 센터에서 내 마음 관리하기",
      button: "체험하기",
      link: "https://zep.us/play/8ZwAV4",
      src: `${process.env.PUBLIC_URL}/assets/tutorial/metaverse_tutorial_sm.png`,
      backgroundColor: "#F0FBEE",
    },
  ];
  return (
    <Container>
      <Topbar>
        <Logo src={`${process.env.PUBLIC_URL}/assets/logo/topbar_logo.png`} />
      </Topbar>
      <Wrapper>
        <Text3>Experience</Text3>
        <Text1>
          직접 경험해 보면
          <br />더 쉬워요
        </Text1>
        <FlexColumn style={{ gap: "1.75rem" }}>
          {data.map((item) => (
            <Card
              backgroundColor={item.backgroundColor}
              title={item.title}
              description={item.description}
              button={item.button}
              link={item.link}
              src={item.src}
            />
          ))}
        </FlexColumn>
      </Wrapper>
    </Container>
  );
};

export default Tutorial;
