import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TopBar = styled.nav`
  width: 100%;
  height: 3.44rem;
  background: ${({ theme }) => theme.primary.normal};
  display: flex;
  justify-content: center;
  padding: 0 1.63rem;
  position: relative;
`;

const Logo = styled.img`
  width: 6.31rem;
  height: 1.06rem;
  position: absolute;
  top: 1.13rem;
  left: 1.44rem;
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;

  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding: 2.5rem 9.69rem;
`;

const SubHeading = styled.h3`
  font-size: 1.13rem;
  line-height: 1.63rem;
  color: ${({ theme }) => theme.primary.normal};
  font-weight: 500;
  margin-bottom: 0.38rem;
`;

const Heading = styled.h1`
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 700;
  margin-bottom: 1.13rem;
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.black};
  text-align: center;
  margin-bottom: 1.69rem;
`;

const Guide = styled.p`
  border-radius: 0.63rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  font-weight: 400;
  font-size: 0.81rem;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 5px 55px;
  margin-bottom: 1.69rem;
  line-height: 1.13rem;
  letter-spacing: -0.08em;
  text-align: center;
`;

const Canvas = styled.canvas`
  display: none;
`;

const Video = styled.video`
  // display: none;
  // width: 320px;
  // height: 240px;
  // padding-top: 56.25%;
  width: 390px;
  // height: auto;
  height: 220px;
  // width: 489px;
  // height: 276px;
  object-fit: contain;
  margin-bottom: 1.88rem;
  border-radius: 1.25rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray20};
  // border: 1px solid black;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
`;

const VideoFrame = styled.div`
  width: 390px;
  height: 220px;
  margin-bottom: 1.88rem;
  border-radius: 1.25rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray20};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const Button = styled.button`
  border: 1px solid
    ${({ theme, color }) =>
      color === "primary" ? theme.grayScaleColor.white : theme.primary.normal};
  width: 11.88rem;
  padding: 0.69rem 0;
  border-radius: 0.31rem;
  background: ${({ theme, color }) =>
    color === "primary" ? theme.primary.normal : theme.grayScaleColor.white};
  color: ${({ theme, color }) =>
    color === "primary" ? theme.grayScaleColor.white : theme.primary.normal};
  cursor: pointer;
  margin: 0 0.31rem;
`;

const FileInput = styled.input`
  padding: 20px;
  background: #ffc842;
  border: 1px solid black;
  width: 200px;
  color: white;
`;

const WarningText = styled.p`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  // line-height: 1.19rem;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 1.44rem;
`;

const SettingSvg = styled.svg`
  margin-right: 0.31rem;
`;

const SettingIcon = () => {
  return (
    <SettingSvg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_538_2314)">
        <path
          d="M13.8121 8.49984C13.8121 8.33692 13.8051 8.18109 13.7909 8.01817L15.1084 7.01942C15.3917 6.80692 15.4696 6.41025 15.2926 6.09859L13.968 3.81067C13.7909 3.499 13.4084 3.3715 13.0826 3.51317L11.5596 4.15775C11.2976 3.97359 11.0213 3.81067 10.7309 3.67609L10.5255 2.03984C10.483 1.68567 10.1784 1.4165 9.82422 1.4165H7.18214C6.82089 1.4165 6.51631 1.68567 6.47381 2.03984L6.26839 3.67609C5.97797 3.81067 5.70172 3.97359 5.43964 4.15775L3.91672 3.51317C3.59089 3.3715 3.20839 3.499 3.03131 3.81067L1.70672 6.10567C1.52964 6.41734 1.60756 6.80692 1.89089 7.0265L3.20839 8.02525C3.19422 8.18109 3.18714 8.33692 3.18714 8.49984C3.18714 8.66275 3.19422 8.81859 3.20839 8.9815L1.89089 9.98025C1.60756 10.1928 1.52964 10.5894 1.70672 10.9011L3.03131 13.189C3.20839 13.5007 3.59089 13.6282 3.91672 13.4865L5.43964 12.8419C5.70172 13.0261 5.97797 13.189 6.26839 13.3236L6.47381 14.9598C6.51631 15.314 6.82089 15.5832 7.17506 15.5832H9.81714C10.1713 15.5832 10.4759 15.314 10.5184 14.9598L10.7238 13.3236C11.0142 13.189 11.2905 13.0261 11.5526 12.8419L13.0755 13.4865C13.4013 13.6282 13.7838 13.5007 13.9609 13.189L15.2855 10.9011C15.4626 10.5894 15.3846 10.1998 15.1013 9.98025L13.7838 8.9815C13.8051 8.81859 13.8121 8.66275 13.8121 8.49984ZM8.52797 10.979C7.16089 10.979 6.04881 9.86692 6.04881 8.49984C6.04881 7.13275 7.16089 6.02067 8.52797 6.02067C9.89506 6.02067 11.0071 7.13275 11.0071 8.49984C11.0071 9.86692 9.89506 10.979 8.52797 10.979Z"
          fill="#D60606"
        />
      </g>
      <defs>
        <clipPath id="clip0_538_2314">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </SettingSvg>
  );
};

function Popup() {
  console.log("popup render");
  const videoRef = useRef(null);
  const canvasRef = useRef();
  const fileInputRef = useRef();

  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isCameraReday, setIsCameraReady] = useState(false);
  const [error, setError] = useState("");

  const startVideo = async () => {
    // setShowVideo(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: 489, height: 276 },
      });
      setIsCameraOn(true);

      if (videoRef && videoRef.current && !videoRef.current.srcObject) {
        videoRef.current.srcObject = stream;
      }

      videoRef.current.addEventListener("playing", () => {
        setIsCameraReady(true);
      });

      setError("");
    } catch (err) {
      setIsCameraOn(false);
      setError("원활한 미소 촬영을 위한 카메라 접근을 허용해주세요");
    }
  };

  const handleTakePhoto = () => {
    const pixelRatio = window.devicePixelRatio || 1;
    const context = canvasRef.current.getContext("2d");
    // const width = 320;
    // const width = 390;
    const width = 489;
    const height = width / (16 / 9);
    // const height = 276;
    // canvasRef.current.width = width;
    // canvasRef.current.height = height;
    canvasRef.current.width = width * pixelRatio;
    canvasRef.current.height = height * pixelRatio;
    context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    context.translate(width, 0);
    context.scale(-1, 1);
    context.drawImage(videoRef.current, 0, 0, width, height);

    const data = canvasRef.current.toDataURL("image/png");

    // const sayHi = window.opener;
    window.opener.sendImage(data);
    // console.log(window.opener.sayHi("bk"));
    window.close();
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    // Encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
      window.opener.sendImage(reader.result);
      window.close();
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    startVideo();

    return () => {
      videoRef.current.removeEventListener("playing", () => {
        console.log("remove event");
        setIsCameraOn(false);
      });
    };
  }, []);
  return (
    <Container>
      <TopBar>
        <Logo src={`${process.env.PUBLIC_URL}/assets/logo/ai_logo_white.png`} />
      </TopBar>
      <Wrapper>
        <SubHeading>Challenge Start</SubHeading>
        <Heading>지금부터 미소를 촬영할게요 📷</Heading>
        <Text>
          1 .눈코입 모두 나오도록 정면 바라보기
          <br />
          2. 행복한 순간을 떠올리며 함박 웃음
          <br />
          3. 준비가 되면 '미소 촬영하기' 버튼 누르기
        </Text>
        <Guide>
          마음 편하게 촬영하고 확인하세요. 사진은 AI 판별 후, 저장이나 올리기
          버튼을 누리지 않으면 자동으로 사라집니다.
        </Guide>
        <Video width={390} height={220} autoPlay ref={videoRef} />
        {!isCameraOn ? (
          <>
            <Button
              onClick={() => {
                startVideo();
              }}
            >
              카메라 시작
            </Button>
            {error && (
              <WarningText>
                <SettingIcon />
                {error}
              </WarningText>
            )}
          </>
        ) : (
          isCameraReday && (
            <ButtonContainer>
              <Button onClick={handleTakePhoto} color="primary">
                미소 촬영하기
              </Button>
              <>
                <FileInput
                  type="file"
                  accept="image/png, image/jpeg"
                  ref={fileInputRef}
                  color="white"
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                ></FileInput>
                <Button onClick={handleFileUploadClick}>
                  미소 사진 업로드하기
                </Button>
              </>
            </ButtonContainer>
          )
        )}

        <Canvas id="canvas" ref={canvasRef}></Canvas>
      </Wrapper>
    </Container>
  );
}

export default Popup;
