import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import CloseXButton from "../components/CloseXButton";
import ModalHeading2 from "../components/ModalHeading2";

const Label = styled.p`
  font-weight: 500;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: #000;
  flex: 1;
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  border-radius: 0.4375rem;
  padding: 0.75rem;
  font-size: 0.9375rem;
  line-height: 140%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  outline: none;
  &:placeholder {
    color: ${({ theme }) => theme.grayScaleColor.gray30};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.grayScaleColor.black};
  }
  flex: auto;
`;

const ModalButton = styled.button`
  border-radius: 0.4375rem;
  padding: 0.78rem 3.84rem;
  flex: 1;
  border: none;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  cursor: pointer;
`;

const CancelButton = styled(ModalButton)`
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const OkButton = styled(ModalButton)`
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
`;

const EditManagerModal = ({ closeHandler }) => {
  return (
    <>
      <FlexRow
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <ModalHeading2 text="관리자 정보 수정" />
        <CloseXButton onClick={closeHandler} />
      </FlexRow>
      <FlexColumn style={{ marginTop: "2.69rem", gap: "1rem" }}>
        <FlexRow style={{ alignItems: "center" }}>
          <Label>이름</Label>
          <Input placeholder="이름 입력" />
        </FlexRow>
        <FlexRow style={{ alignItems: "center" }}>
          <Label>소속</Label>
          <Input placeholder="소속/팀 입력" />
        </FlexRow>
        <FlexRow style={{ alignItems: "center" }}>
          <Label>직책</Label>
          <Input placeholder="직책 입력" />
        </FlexRow>
        <FlexRow style={{ alignItems: "center" }}>
          <Label>이메일</Label>
          <Input placeholder="이메일 주소 입력" />
        </FlexRow>
      </FlexColumn>
      <FlexRow
        style={{
          marginTop: "2.44rem",
          justifyContent: "space-between",
          gap: "0.69rem",
        }}
      >
        <CancelButton onClick={closeHandler}>돌아가기</CancelButton>
        <OkButton>수정 완료</OkButton>
      </FlexRow>
    </>
  );
};

export default EditManagerModal;
