import { useEffect, useState } from "react";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Heading1 from "./components/Heading1";
import Heading2 from "./components/Heading2";
import Heading3 from "./components/Heading3";
import ReportSummaryCallout from "./components/ReportSummaryCallout";
import ReportCallout from "./components/ReportCallout";
import ReportHeading1 from "./components/ReportHeading1";
import ReportHeading2 from "./components/ReportHeading2";
import ReportSeparator from "./components/ReportSeparator";
import FlexRow from "../../../components/FlexRow";
import ReportTable from "./components/ReportTable";
import ReportBarChart from "./components/ReportBarChart";
import useAuth from "../../../hooks/useAuth";
import ReportRadarChart from "./components/ReportRadarChart";
import {
  getDate,
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from "../../../utils/date";
import isEndWithConsonant from "../../../utils/isEndWithConsonant";
import ReportComment from "./components/ReportComment";
import { useSearchParams } from "react-router-dom";
import ReportListTable from "./components/ReportListTable";
import ReportHorizontalBarList from "./components/ReportHorizontalBarList";
import ReportBetaLogo from "./components/ReportBetaLogo";
import { Chart } from "../../dashboard/Chart";
import ReportTab from "./components/ReportTab";
import { useMediaQuery } from "react-responsive";
import { MOBILE, PERMAVAC } from "../../../Constants";
import MaumSentenceHorizontalBarList from "./components/MaumSentenceHorizontalBarList";
import WellBeingCompareList from "./components/WellBeingCompareList";
import SummaryList from "./components/SummaryList";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3.13rem;
  padding-bottom: 4.63rem;
  @media (max-width: ${MOBILE}) {
    padding-top: 2.06rem;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 62.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SummaryContainer = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-top: 10px solid;
  border-image: linear-gradient(218deg, #2f8171 0%, #1f3733 100%) 1 0 0 0;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px;
  margin-top: 3.94rem;
  gap: 2rem;
  @media (max-width: ${MOBILE}) {
    flex-direction: column;
    padding: 1.56rem 1.37rem;
    gap: 1.25rem;
  }
`;

const SummaryItem = styled.div`
  display: flex;
  //   border: 1px solid black;
  align-items: center;
  width: 100%;
  gap: 1.25rem;
  &:before {
    content: "";
    border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
    align-self: stretch;
  }
  &:nth-child(1) {
    &:before {
      content: "";
      border: none;
    }
  }
  @media (max-width: ${MOBILE}) {
    gap: 0;
    justify-content: flex-start;
    &:before {
      content: "";
      border: none;
      align-self: stretch;
    }
  }
`;

const SummaryIcon = styled.img`
  width: 2.25rem;
  height: 2.25rem;
  margin-left: 1rem;
  @media (max-width: ${MOBILE}) {
    margin-left: 0;
    margin-right: 1.25rem;
  }
`;

const SummaryLabel = styled.span`
  font-size: 0.88rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  line-height: 1.38rem;
  @media (max-width: ${MOBILE}) {
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 160%;
  }
`;

const SummaryText = styled.span`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 2rem;
  font-weight: 600;
  @media (max-width: ${MOBILE}) {
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 160%;
  }
`;

const ReportContainer = styled.section`
  width: 100%;
  padding: 3rem 3.13rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  margin-top: 2rem;
  @media (max-width: ${MOBILE}) {
    padding: 3rem 1.25rem;
  }
`;

const ReportSection = styled.div`
  margin-top: 4rem;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const LeaderReport = () => {
  const [searchParams] = useSearchParams();
  const { auth } = useAuth();
  const name = auth.user.name;

  const axiosPrivate = useAxiosPrivate();
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE})`,
  });

  const date = new Date();
  const year =
    (searchParams.get("year") && Number(searchParams.get("year"))) || 2023;
  const month = searchParams.get("month")
    ? Number(searchParams.get("month")) - 1
    : date.getMonth();

  const startOfMonth = getFirstDayOfMonth(month);
  const endOfMonth = getLastDayOfMonth(month);

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState(0);
  const [weekResults, setWeekResults] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [checkupCount, setCheckupCount] = useState(0);
  const [otherCompanyCheckupCount, setOtherCompanyCheckupCount] = useState(0);
  const [surveyCount, setSurveyCount] = useState(0);
  const [sentCount, setSentCount] = useState([]);
  const [myWellBeingScore, setMyWellBeingScore] = useState(0);
  const [myPermavac, setMyPermavac] = useState([]);
  const [sortedMyPermavac, setSortedMyPermavac] = useState([]);
  const [myFeeling, setMyFeeling] = useState([]);
  const [sortedMyFeeling, setSortedMyFeeling] = useState([]);
  const [companyWellBeingScore, setCompanyWellBeingScore] = useState(0);
  const [companyPermavac, setCompanyPermavac] = useState([]);
  const [sortedCompanyPermavac, setSortedCompanyPermavac] = useState([]);
  const [companyFeeling, setCompanyFeeling] = useState([]);
  const [sortedCompanyFeeling, setSortedCompanyFeeling] = useState([]);
  const [myPrevPermavacResult, setMyPrevPermavacResult] = useState([]);
  const [weekEmotionResults, setWeekEmotionResults] = useState([]);
  const [weekWellBeingResults, setWeekWellBeingResults] = useState([]);
  const [emotionClickIndex, setEmotionClickIndex] = useState(0);
  const [wellBeingClickIndex, setWellBeingClickIndex] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(
    auth.user.companyId.name
  );
  const companyLabel =
    auth.user.role === 2
      ? "그룹"
      : selectedDepartment === auth.user.companyId.name
      ? "조직"
      : "그룹";
  const company =
    auth.user.role === 2 ? auth.user.departmentId.name : selectedDepartment;
  const handleSelectChange = async (e) => {
    setSelectedDepartment(e.target.selectedOptions[0].text);
    await getCompanyReport(e.target.value);
  };

  const getCompanyReport = async (departmentId) => {
    const response = await axiosPrivate.get(
      `/checkup-v2/leader-report?year=${year}&month=${searchParams.get(
        "month"
      )}&departmentId=${departmentId}`
    );

    setUsers(response.data.users);
    setWeekResults(response.data.weekResults);
    setBlocks(response.data.blocks);
    setCheckupCount(response.data.checkupCount);
    setSentCount(response.data.sentCounts);
    setOtherCompanyCheckupCount(response.data.otherCompanyCheckupCount);
    setSurveyCount(response.data.surveyCount);
    setMyWellBeingScore(response.data.companyWellBeingScore);

    setMyPermavac(
      response.data.companyPermavacResult.length > 0
        ? response.data.companyPermavacResult
        : new Array(9).fill({ value: 0, type: "" })
    );
    setMyFeeling(response.data.companyFeelingResult);
    const sortedPermavac =
      response.data.companyPermavacResult.length > 0
        ? [...response.data.companyPermavacResult]
        : new Array(9).fill({ value: 0, type: "" });
    setSortedMyPermavac(sortedPermavac.sort((a, b) => b.value - a.value));
    const sortedFeeling = [...response.data.companyFeelingResult];
    setSortedMyFeeling(sortedFeeling.sort((a, b) => b[1] - a[1]));
    setWeekEmotionResults(response.data.weekEmotionResults);
    setWeekWellBeingResults(response.data.weekWellBeingResults);

    // 나의 마음 분포 순서대로 조직 마음 분포 순서 변경
    if (response.data.companyFeelingResult.length > 0) {
      const sorted = [];
      for (let i = 0; i < sortedFeeling.length; i++) {
        for (
          let j = 0;
          j < response.data.otherCompanyFeelingResult.length;
          j++
        ) {
          if (
            sortedFeeling[i][0] ===
            response.data.otherCompanyFeelingResult[j][0]
          ) {
            sorted.push(response.data.otherCompanyFeelingResult[j]);
          }
        }
      }
      setSortedCompanyFeeling(sorted);
    } else {
      const sorted = [...response.data.ohterCompanyFeelingResult];
      setSortedCompanyFeeling(sorted.sort((a, b) => b[1] - a[1]));
    }

    // 나의 마음 분포 순서대로 조직 마음 분포 순서 변경
    if (response.data.companyPermavacResult.length > 0) {
      const sorted = [];
      for (let i = 0; i < sortedPermavac.length; i++) {
        for (
          let j = 0;
          j < response.data.otherCompanyPermavacResult?.length;
          j++
        ) {
          if (
            sortedPermavac[i].type ===
            response.data.otherCompanyPermavacResult[j].type
          ) {
            sorted.push(response.data.otherCompanyPermavacResult[j]);
          }
        }
      }

      setSortedCompanyPermavac(
        sorted.length > 0
          ? sorted
          : new Array(sortedPermavac.length).fill({ value: 0 })
      );
    } else {
      const sorted =
        response.data.otherCompanyPermavacResult.length > 0
          ? [...response.data.otherCompanyPermavacResult]
          : new Array(sortedPermavac.length).fill({ value: 0 });
      setSortedCompanyPermavac(sorted.sort((a, b) => b[1] - a[1]) || []);
    }

    setCompanyWellBeingScore(response.data.otherCompanyWellBeingScore);
    setCompanyPermavac(response.data.otherCompanyPermavacResult || []);
    setCompanyFeeling(response.data.otherCompanyFeelingResult);
    setMyPrevPermavacResult(
      response.data.companyPrevPermavacResult.length > 0
        ? response.data.companyPrevPermavacResult
        : new Array(9).fill({ value: 0 })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (auth.user.role > 2) {
        const companyResponse = await axiosPrivate.get("/department/report");
        if (!companyResponse.data.ok) {
          alert(companyResponse.data.msg);
          // TODO: go back
        }
        setDepartments(companyResponse.data.data.departments);
      }

      await getCompanyReport(
        auth.user.role === 2 ? auth.user.departmentId._id : ""
      );
      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return null;
  }
  // console.log(users);
  // if (users <= 3) {
  //   console.log("nor report");
  //   return (
  //     <Container>
  //       <Wrapper>
  //         그룹 인원이 3명 미만인 경우엔, 리포트를 확인할 수 없습니다.
  //       </Wrapper>
  //     </Container>
  //   );
  // }
  return (
    <Container>
      <Wrapper>
        <FlexRow>
          <Heading2 style={{ marginBottom: "0.25rem" }}>
            월간 그룹 리포트
          </Heading2>
          <ReportBetaLogo />
        </FlexRow>
        <Heading1 style={{ marginBottom: "0.37rem" }}>
          {year}년 {month + 1}월 마음 리포트
        </Heading1>
        <Heading3>
          {getDate(weekResults[0]?.start || startOfMonth, "M.D(ddd)")} ~{" "}
          {getDate(
            weekResults[weekResults.length - 1]?.end || endOfMonth,
            "M.D(ddd)"
          )}
        </Heading3>
        {auth.user.role >= 3 && (
          <FlexRow
            style={{
              justifyContent: "flex-end",

              width: "100%",
            }}
          >
            <select name="company" onChange={handleSelectChange}>
              {[
                { name: auth.user.companyId.name, _id: "" },
                ...departments,
              ].map((item, index) => (
                <option key={index} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>
          </FlexRow>
        )}
        {isMobile ? (
          <SummaryContainer>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_company_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>{companyLabel}명</SummaryLabel>
                <SummaryText>{company}</SummaryText>
              </FlexColumn>
            </SummaryItem>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_group_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>인원</SummaryLabel>
                <SummaryText>
                  총{" "}
                  {sentCount.length > 0
                    ? `${parseFloat(
                        (
                          sentCount.reduce((prev, cur) => prev + cur, 0) /
                          sentCount.length
                        ).toFixed(1)
                      )}명`
                    : `0명`}
                </SummaryText>
              </FlexColumn>
            </SummaryItem>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_check_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>마음 체크업</SummaryLabel>
                <SummaryText>{checkupCount}회</SummaryText>
              </FlexColumn>
            </SummaryItem>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_heart_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>마음 트레이닝</SummaryLabel>
                <SummaryText>
                  {blocks.reduce((prev, cur) => prev + cur.count, 0)}회
                </SummaryText>
              </FlexColumn>
            </SummaryItem>
          </SummaryContainer>
        ) : (
          <SummaryContainer>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_company_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>{companyLabel}명</SummaryLabel>
                <SummaryText>{company}</SummaryText>
              </FlexColumn>
            </SummaryItem>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_group_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>인원</SummaryLabel>
                <SummaryText>
                  총{" "}
                  {sentCount.length > 0
                    ? `${parseFloat(
                        (
                          sentCount.reduce((prev, cur) => prev + cur, 0) /
                          sentCount.length
                        ).toFixed(1)
                      )}명`
                    : `0명`}
                </SummaryText>
              </FlexColumn>
            </SummaryItem>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_check_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>마음 체크업</SummaryLabel>
                <SummaryText>{checkupCount}회</SummaryText>
              </FlexColumn>
            </SummaryItem>
            <SummaryItem>
              <SummaryIcon
                src={`${process.env.PUBLIC_URL}/assets/icons/report_heart_icon.png`}
                alt="user icon"
                width={36}
                height={36}
              />
              <FlexColumn>
                <SummaryLabel>마음 트레이닝</SummaryLabel>
                <SummaryText>
                  {blocks.reduce((prev, cur) => prev + cur.count, 0)}회
                </SummaryText>
              </FlexColumn>
            </SummaryItem>
          </SummaryContainer>
        )}
        <ReportContainer>
          <ReportHeading2 style={{ marginBottom: "0.75rem" }}>
            📅 한 달 동안 {company}
            {isEndWithConsonant(company) ? "은" : "는"}
          </ReportHeading2>
          <ReportSummaryCallout style={{ marginBottom: "1.13rem" }}>
            <Bold>
              {sortedMyFeeling.length > 1 &&
              sortedMyFeeling[0][1] === sortedMyFeeling[1][1]
                ? `${sortedMyFeeling[0][0]}(외 ${
                    sortedMyFeeling.filter(
                      (item) => item[1] === sortedMyFeeling[0][1]
                    ).length - 1
                  }개)`
                : `${sortedMyFeeling[0][0]}`}
            </Bold>
            {isEndWithConsonant(sortedMyFeeling[0][0]) ? "을" : "를"} 가장 많이
            경험했습니다. 평균 웰빙 지수는 <Bold>{myWellBeingScore}</Bold>
            점으로, 다른 {companyLabel} 평균(
            {companyWellBeingScore}점)과 비교해{" "}
            {myWellBeingScore > companyWellBeingScore
              ? "조금 높은 편에 속합니다."
              : myWellBeingScore < companyWellBeingScore
              ? "조금 낮은 편에 속합니다."
              : "동일합니다."}{" "}
            9가지 웰빙 영역 중 가장 높았던 영역은{" "}
            <Bold>{sortedMyPermavac[0].type}</Bold>, 가장 낮았던 영역은{" "}
            <Bold>{sortedMyPermavac[sortedMyPermavac.length - 1].type}</Bold>{" "}
            입니다.
          </ReportSummaryCallout>
          {isMobile ? (
            <SummaryList
              headers={[
                "",
                ...weekResults.map(
                  (item) =>
                    `${getDate(item.start, "M/D")}~${getDate(item.end, "M/D")}`
                ),
                `${month + 1}월 평균`,
              ]}
              rows={[
                [
                  `구독자 수`,
                  ...sentCount.map((i) => `${i}명`),
                  sentCount.length > 0
                    ? `${parseFloat(
                        (
                          sentCount.reduce((prev, cur) => prev + cur, 0) /
                          sentCount.length
                        ).toFixed(1)
                      )}명`
                    : `0명`,
                ],
                [
                  `마음 체크업`,
                  ...weekResults.map(
                    (item, index) =>
                      `${item.checkupCount}회 (${(
                        (item.checkupCount / sentCount[index]) *
                        100
                      ).toFixed(1)}%)`
                  ),
                  weekResults.length > 0
                    ? `${(
                        weekResults.reduce(
                          (prev, cur) => prev + cur.checkupCount,
                          0
                        ) / weekResults.length
                      ).toFixed(1)}회 (${(
                        (weekResults.reduce(
                          (prev, cur) => prev + cur.checkupCount,
                          0
                        ) /
                          weekResults.length /
                          (sentCount.reduce((prev, cur) => prev + cur, 0) /
                            sentCount.length)) *
                        100
                      ).toFixed(1)}%)`
                    : `0회 (0%)`,
                ],
                [
                  "마음 트레이닝",
                  ...weekResults.map(
                    (item, index) =>
                      `${item.blockCount}회 (${(
                        (item.blockCount / sentCount[index]) *
                        100
                      ).toFixed(1)}%)`
                  ),
                  weekResults.length > 0
                    ? `${(
                        weekResults.reduce(
                          (prev, cur) => prev + cur.blockCount,
                          0
                        ) / weekResults.length
                      ).toFixed(1)}회  (${(
                        (weekResults.reduce(
                          (prev, cur) => prev + cur.blockCount,
                          0
                        ) /
                          weekResults.length /
                          (sentCount.reduce((prev, cur) => prev + cur, 0) /
                            sentCount.length)) *
                        100
                      ).toFixed(1)}%)`
                    : "0회 (0%)",
                ],
                [
                  `마음 웰빙`,
                  ...weekResults.map((item) => `${item.wellBeingScore}점`),
                  weekResults.length > 0 ? `${myWellBeingScore}점` : "0점",
                ],
              ]}
            />
          ) : (
            <ReportTable
              highlightRow={[{ pos: 0, color: "#EDF3F3" }]}
              highlightCol={[
                { pos: 0, color: "#EDF3F3" },
                { pos: weekResults.length + 1, color: "#F6FAF9" },
              ]}
              highlightBox={[
                { row: 0, col: weekResults.length + 1, color: "#E1EDEA" },
              ]}
              colHeader={[
                "",
                ...weekResults.map(
                  (item) =>
                    `${getDate(item.start, "M/D")}~${getDate(item.end, "M/D")}`
                ),
                `${month + 1}월 평균`,
              ]}
              rows={[
                [
                  `구독자 수`,
                  ...sentCount.map((i) => `${i}명`),
                  sentCount.length > 0
                    ? `${parseFloat(
                        (
                          sentCount.reduce((prev, cur) => prev + cur, 0) /
                          sentCount.length
                        ).toFixed(1)
                      )}명`
                    : `0명`,
                ],
                [
                  `마음 체크업`,
                  ...weekResults.map(
                    (item, index) =>
                      `${item.checkupCount}회 (${(
                        (item.checkupCount / sentCount[index]) *
                        100
                      ).toFixed(1)}%)`
                  ),
                  weekResults.length > 0
                    ? `${(
                        weekResults.reduce(
                          (prev, cur) => prev + cur.checkupCount,
                          0
                        ) / weekResults.length
                      ).toFixed(1)}회 (${(
                        (weekResults.reduce(
                          (prev, cur) => prev + cur.checkupCount,
                          0
                        ) /
                          weekResults.length /
                          (sentCount.reduce((prev, cur) => prev + cur, 0) /
                            sentCount.length)) *
                        100
                      ).toFixed(1)}%)`
                    : `0회 (0%)`,
                ],
                [
                  "마음 트레이닝",
                  ...weekResults.map(
                    (item, index) =>
                      `${item.blockCount}회 (${(
                        (item.blockCount / sentCount[index]) *
                        100
                      ).toFixed(1)}%)`
                  ),
                  weekResults.length > 0
                    ? `${(
                        weekResults.reduce(
                          (prev, cur) => prev + cur.blockCount,
                          0
                        ) / weekResults.length
                      ).toFixed(1)}회  (${(
                        (weekResults.reduce(
                          (prev, cur) => prev + cur.blockCount,
                          0
                        ) /
                          weekResults.length /
                          (sentCount.reduce((prev, cur) => prev + cur, 0) /
                            sentCount.length)) *
                        100
                      ).toFixed(1)}%)`
                    : "0회 (0%)",
                ],
                [
                  `마음 웰빙`,
                  ...weekResults.map((item) => `${item.wellBeingScore}점`),
                  weekResults.length > 0 ? `${myWellBeingScore}점` : "0점",
                ],
              ]}
            />
          )}
          <ReportSection>
            <ReportHeading1 style={{ color: "#00DBB1" }}>01</ReportHeading1>
            {isMobile ? (
              <ReportHeading1 style={{ fontSize: "1.0625rem" }}>
                {" "}
                마음 체크업
              </ReportHeading1>
            ) : (
              <ReportHeading1>마음 체크업</ReportHeading1>
            )}

            <ReportSeparator style={{ ...(isMobile && { opacity: 0.3 }) }} />
            <ReportCallout
              title={
                isMobile
                  ? "대표 마음 문장"
                  : `우리 ${companyLabel} 대표 마음 문장`
              }
              value={
                sortedMyFeeling.length > 1 &&
                sortedMyFeeling[0][1] === sortedMyFeeling[1][1]
                  ? `${sortedMyFeeling[0][0]}(외 ${
                      sortedMyFeeling.filter(
                        (item) => item[1] === sortedMyFeeling[0][1]
                      ).length - 1
                    }개)`
                  : `${sortedMyFeeling[0][0]}`
              }
              style={{ marginBottom: "1.37rem" }}
            />
            {isMobile ? (
              <MaumSentenceHorizontalBarList
                legends={[company, `타 ${companyLabel}`]}
                labels={myFeeling.map((item) => item[0])}
                data={[
                  myFeeling.map((item) =>
                    checkupCount > 0
                      ? Number(((item[1] / checkupCount) * 100).toFixed(1))
                      : 0
                  ),
                  companyFeeling.map((item) =>
                    otherCompanyCheckupCount > 0
                      ? Number(
                          ((item[1] / otherCompanyCheckupCount) * 100).toFixed(
                            1
                          )
                        )
                      : 0
                  ),
                ]}
              />
            ) : (
              <div>
                <ReportBarChart
                  scale={100}
                  stepSize={20}
                  labels={myFeeling.map((item) => item[0])}
                  results={[
                    myFeeling.map((item) =>
                      checkupCount > 0
                        ? Number(((item[1] / checkupCount) * 100).toFixed(1))
                        : 0
                    ),
                    companyFeeling.map((item) =>
                      checkupCount > 0
                        ? Number(
                            (
                              (item[1] / otherCompanyCheckupCount) *
                              100
                            ).toFixed(1)
                          )
                        : 0
                    ),
                  ]}
                  legend={company}
                  compare={`타 ${companyLabel}`}
                />
              </div>
            )}
            {isMobile && (
              <ReportSeparator
                style={{
                  marginTop: "3.69rem",
                  marginBottom: "2.44rem",
                  opacity: 0.3,
                }}
              />
            )}
            {isMobile ? (
              <FlexColumn style={{ marginBottom: "1.5rem" }}>
                <ReportHeading2 style={{ width: "100%", marginBottom: "2rem" }}>
                  {company} 구성원과 타 {companyLabel} 마음 분포 비교
                </ReportHeading2>
                <ReportListTable
                  colHeader={["마음 문장", "빈도", "분포", "주변 평균"]}
                  rows={sortedMyFeeling.map((item, index) => [
                    item[0],
                    `${item[1]}회`,
                    `${
                      checkupCount > 0
                        ? ((item[1] / checkupCount) * 100).toFixed(1)
                        : 0
                    }%`,
                    `${
                      otherCompanyCheckupCount > 0
                        ? (
                            (sortedCompanyFeeling[index][1] /
                              otherCompanyCheckupCount) *
                            100
                          ).toFixed(1)
                        : 0
                    }%`,
                  ])}
                  flex={[2, 1, 1.4, 1]}
                  // hoverAt={0}
                  rowData={sortedMyFeeling}
                ></ReportListTable>
              </FlexColumn>
            ) : (
              <FlexRow
                style={{
                  gap: "55px",
                  marginTop: "4.25rem",
                  marginBottom: "1.5rem",
                }}
              >
                <ReportHeading2>
                  {company} 구성원과
                  <br />타 {companyLabel} 마음 분포 비교
                </ReportHeading2>
                <ReportListTable
                  colHeader={["순위", "마음 문장", "빈도", "분포", "주변 평균"]}
                  rows={sortedMyFeeling.map((item, index) => [
                    index + 1,
                    item[0],
                    `${item[1]}회`,
                    `${
                      checkupCount > 0
                        ? ((item[1] / checkupCount) * 100).toFixed(1)
                        : 0
                    }%`,
                    `${
                      otherCompanyCheckupCount > 0
                        ? (
                            (sortedCompanyFeeling[index][1] /
                              otherCompanyCheckupCount) *
                            100
                          ).toFixed(1)
                        : 0
                    }%`,
                  ])}
                  flex={[1, 2, 1, 1, 1]}
                  hoverAt={1}
                  rowData={sortedMyFeeling}
                ></ReportListTable>
                {/* <ReportTable
                colHeader={["순위", "마음 상태", "빈도", "분포", "주변 평균"]}
                rows={sortedMyFeeling.map((item, index) => [
                  index + 1,
                  item[0],
                  `${item[1]}회`,
                  `${((item[1] / surveyCount) * 100).toFixed(1)}%`,
                  `${(
                    (sortedCompanyFeeling[index][1] / surveyCount) *
                    100
                  ).toFixed(1)}%`,
                ])}
                // diff={sortedMyFeeling.map(
                //   (item, index) => item[1] - sortedCompanyFeeling[index][1]
                // )}
                // diffDirection={"column"}
                // diffAt={2}
              /> */}
              </FlexRow>
            )}
            <ReportComment style={{ marginBottom: "3.37rem" }}>
              * 마음 분포는 해당 마음에 대한 문장을 선택한 비율입니다.
              (복수선택)
            </ReportComment>
            <ReportHeading2 style={{ marginBottom: "1.5rem" }}>
              📊 주별 그래프
            </ReportHeading2>
            <ReportTab
              tabs={sortedMyFeeling.map((item) => item[0])}
              setClickIndex={setEmotionClickIndex}
              clickIndex={emotionClickIndex}
            />

            <FlexColumn style={{ marginBottom: "5.5rem" }}>
              <Chart
                style={{
                  width: "100%",
                  height: isMobile ? "14.68775rem" : "19rem",
                }}
                result={weekEmotionResults.map((r) => {
                  return r.count > 0
                    ? Number(
                        (r.feeling[sortedMyFeeling[emotionClickIndex][2]]
                          .count /
                          r.count) *
                          100
                      ).toFixed(1)
                    : 0;
                })}
                dates={weekEmotionResults.map((r) =>
                  isMobile ? r.date.split("~")[0] : r.date
                )}
                unit="%"
              />
            </FlexColumn>

            <ReportCallout
              title={"마음 웰빙 지수"}
              value={`${myWellBeingScore}`}
              style={{ marginBottom: "2.38rem" }}
              diff={Number(
                (myWellBeingScore - companyWellBeingScore).toFixed(1)
              )}
            />
            <ReportRadarChart
              me={myPermavac.map((item) => item.value)}
              company={companyPermavac.map((item) => item.value)}
              legend={company}
              compare={`타 ${companyLabel}`}
            />
            {isMobile && (
              <ReportSeparator
                style={{
                  marginTop: "2.5rem",
                  marginBottom: "1.94rem",
                  opacity: 0.3,
                }}
              />
            )}
            {isMobile ? (
              <FlexColumn
                style={{
                  marginBottom: "4.23rem",
                }}
              >
                {" "}
                <ReportHeading2 style={{ width: "100%" }}>
                  {company} 구성원과 타 그룹 마음 웰빙 비교
                  <ReportComment
                    style={{ fontWeight: 400, marginTop: "0.56rem" }}
                  >
                    * 7점 척도 기준
                  </ReportComment>
                </ReportHeading2>
                <ReportHorizontalBarList
                  rows={sortedMyPermavac}
                  diff={sortedCompanyPermavac}
                  showBadge={false}
                />
              </FlexColumn>
            ) : (
              <FlexRow
                style={{
                  gap: "55px",
                  marginTop: "4.25rem",
                  marginBottom: "5rem",
                }}
              >
                <ReportHeading2 style={{ width: "12.38rem" }}>
                  {company} 구성원과
                  <br />타 그룹 마음 웰빙 비교
                  <ReportComment
                    style={{ fontWeight: 400, marginTop: "0.56rem" }}
                  >
                    * 7점 척도 기준
                  </ReportComment>
                </ReportHeading2>
                <ReportHorizontalBarList
                  rows={sortedMyPermavac}
                  diff={sortedCompanyPermavac}
                  showBadge={false}
                />
                {/* <ReportTable
                colHeader={["순위", "마음 상태", "빈도", "비교"]}
                rows={sortedMyPermavac.map((item, index) => [
                  index + 1,
                  item.type,
                  item.question,
                  item.value,
                ])}
                diff={sortedMyPermavac.map((item, index) =>
                  Number(
                    (item.value - sortedCompanyPermavac[index].value).toFixed(1)
                  )
                )}
                diffDirection={"column"}
                diffAt={3}
              /> */}
              </FlexRow>
            )}
            <ReportHeading2 style={{ marginBottom: "1.5rem" }}>
              📊 주별 그래프
            </ReportHeading2>
            <ReportTab
              tabs={sortedMyPermavac.map((item) => item.type)}
              setClickIndex={setWellBeingClickIndex}
              clickIndex={wellBeingClickIndex}
            />

            <FlexColumn style={{ marginBottom: "3rem" }}>
              <Chart
                style={{ width: "100%", height: isMobile ? "16rem" : "22rem" }}
                result={weekWellBeingResults.map((r) => {
                  return r.count > 0
                    ? Number(
                        r.wellBeing[sortedMyPermavac[wellBeingClickIndex].type]
                          .value / r.count
                      ).toFixed(1)
                    : 0;
                })}
                dates={weekWellBeingResults.map((r) =>
                  isMobile ? r.date.split("~")[0] : r.date
                )}
                min={0}
                max={7}
                step={1}
              />
            </FlexColumn>
            {isMobile && (
              <ReportSeparator
                style={{
                  marginTop: "2.5rem",
                  marginBottom: "2.87rem",
                  opacity: 0.3,
                }}
              />
            )}
            {/* <ReportComment style={{ marginBottom: "3.37rem" }}>
              * 우리 조직 평균과 내 점수의 차이: +20% 초과  20% 범위 내 -20% 미만
            </ReportComment> */}
            <FlexColumn
              style={{ marginTop: "3.38rem", marginBottom: "1.62rem" }}
            >
              {isMobile ? (
                <ReportHeading2
                  style={{ width: "100%", marginBottom: "1.13rem" }}
                >
                  이전 달과 이번 달 {company} 구성원 마음 웰빙 비교
                </ReportHeading2>
              ) : (
                <ReportHeading2
                  style={{ width: "16rem", marginBottom: "2.13rem" }}
                >
                  이전 달과 이번 달
                  <br />
                  {company} 구성원 마음 웰빙 비교
                </ReportHeading2>
              )}
              {isMobile ? (
                <WellBeingCompareList
                  headers={[
                    "",
                    `${month === 0 ? 12 : month}월`,
                    `${month + 1}월`,
                    "비교",
                  ]}
                  rows={PERMAVAC.map((item, index) => [
                    item,
                    myPrevPermavacResult[index].value,
                    myPermavac[index].value,
                    parseFloat(
                      (
                        myPermavac[index].value -
                        myPrevPermavacResult[index].value
                      ).toFixed(1)
                    ),
                  ])}
                />
              ) : (
                <ReportTable
                  colHeader={[
                    "",
                    "몰입",
                    "자율성",
                    "유능감",
                    "성취",
                    "관계",
                    "긍정정서",
                    "의미",
                    "활력",
                    "행복",
                  ]}
                  rows={[
                    [
                      `${month === 0 ? 12 : month}월`,
                      ...myPrevPermavacResult.map((i) => i.value),
                    ],
                    [`${month + 1}월`, ...myPermavac.map((i) => i.value)],
                    [
                      "비교",
                      ...myPermavac.map((i, index) =>
                        Number(
                          (i.value - myPrevPermavacResult[index].value).toFixed(
                            1
                          )
                        )
                      ),
                    ],
                  ]}
                  diffDirection={"row"}
                  diffAt={2}
                />
              )}
            </FlexColumn>
          </ReportSection>
          <ReportSection>
            <ReportHeading1 style={{ color: "#00DBB1" }}>02</ReportHeading1>
            <ReportHeading1>마음 트레이닝</ReportHeading1>
            <ReportSeparator />
            <ReportCallout
              title={`마음 트레이닝 횟수`}
              value={`${blocks.reduce((prev, cur) => prev + cur.count, 0)}회`}
              style={{ marginBottom: "3.75rem" }}
            />
            {isMobile ? (
              <FlexColumn>
                <ReportHeading2 style={{ marginBottom: "1.13rem" }}>
                  TOP 5 마음 트레이닝
                </ReportHeading2>
                <ReportTable
                  colHeader={["순위", "마음 트레이닝", "참여"]}
                  rows={blocks
                    .slice(0, 5)
                    .map((block, index) => [
                      index + 1,
                      block.name,
                      `${block.count}회`,
                    ])}
                />
              </FlexColumn>
            ) : (
              <FlexRow>
                <ReportHeading2>TOP 5 마음 트레이닝</ReportHeading2>
                <ReportTable
                  colHeader={["순위", "마음 트레이닝", "참여"]}
                  rows={blocks
                    .slice(0, 5)
                    .map((block, index) => [
                      index + 1,
                      block.name,
                      `${block.count}회`,
                    ])}
                />
              </FlexRow>
            )}
          </ReportSection>
        </ReportContainer>
      </Wrapper>
    </Container>
  );
};

export default LeaderReport;
