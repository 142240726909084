import { useState } from "react";
import styled from "styled-components";
import axios from "../../../api/axios";
import { ROUTES } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import includesPathname from "../../../utils/includesPathname";
import deleteSmilePhoto from "../api/deleteSmilePhoto";
import Button from "./Button";

const Container = styled.div`
  width: 100%;
  max-width: 335px;
  height: 245px;
  padding: 30px;
  background: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Title = styled.h3`
  color: #000;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.04em;
  text-align: center;
`;

const PasswordInput = styled.input`
  border: 1px solid
    ${({ theme, isError }) =>
      isError ? theme.colors.error : theme.grayScaleColor.gray20};
  padding: 14px;
  padding-left: 17px;
  margin-top: 18px;
  font-size: 15px;
  border-radius: 5px;
  &::placeholder {
    color: ${({ theme }) => theme.grayScaleColor.gray30};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 22px;
  gap: 10px;
`;

const ErrorText = styled.span`
  font-size: 14px;
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.error};
`;

const DeletePhotoPassword = ({ photo, okHandler, cancelHandler }) => {
  const { auth } = useAuth();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const handlePasswordChange = (e) => {
    console.log(e.target.value);
    setError("");
    setPassword(e.target.value);
  };

  const deletePhoto = async () => {
    const response = await deleteSmilePhoto({
      auth: includesPathname(ROUTES.GUEST_SMILE) ? null : auth,
      photo,
      password,
    });
    if (!response.ok) {
      setError("비밀번호가 틀렸습니다.");
      return;
    }

    return okHandler();
  };
  return (
    <Container>
      <Title>
        안전히 삭제하기 위해
        <br />
        설정한 비밀번호를 입력해 주세요
      </Title>
      <PasswordInput
        type="text"
        placeholder="🔒 비밀번호를 입력해 주세요"
        isError={error?.length > 0}
        onChange={handlePasswordChange}
      />
      {error && <ErrorText>{error}</ErrorText>}
      <ButtonContainer>
        <Button
          text={"삭제하기"}
          style={{ background: "#EDF3F3", color: "#7C8585" }}
          clickHandler={deletePhoto}
        />
        <Button text={"돌아가기"} clickHandler={cancelHandler} />
      </ButtonContainer>
    </Container>
  );
};

export default DeletePhotoPassword;
