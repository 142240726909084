import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../api/axios";
import { converToLocaleDate } from "../../../utils/convertDate";
import deleteSmilePhoto from "../api/deleteSmilePhoto";
import DeletePhotoDialog from "./DeletePhotoDialog";
import DeletePhotoPassword from "./DeletePhotoPassword";
import DeleteSuccessDialog from "./DeleteSuccessDialog";
import LikeButton from "./LikeButton";
import Modal from "./Modal";
import PhotoDialog from "./PhotoDialog";
import StampImage from "./StampImage";

const Container = styled.div`
  width: 150px;
  padding: 12px;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  /* margin: 7px 5px; */
`;

const Name = styled.span`
  font-size: 0.75rem;
  color: #111;
  font-weight: 600;
  text-align: center;
  width: 100%;
  display: block;
  letter-spacing: -0.03em;
  line-height: 14px;
  margin-bottom: 16px;
  margin-top: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: ${({ height }) => height || "auto"};
  margin-bottom: 16px;
  border-radius: 7px;
  cursor: pointer;
`;

const Date = styled.span`
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  font-size: 0.5rem;
  font-weight: 400;
  line-height: 10px;
  margin-bottom: 10px;
  display: block;
  font-family: "Montserrat";
`;

const Score = styled.span`
  font-weight: 600;
  font-size: 1.13rem;
  color: #111111;
  display: block;
  font-family: "Montserrat";
  margin-bottom: 12px;
`;

const MoreButton = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 3px;
  position: absolute;
  right: 12px;
  // border: 1px solid black;
  padding: 0 4px;
  cursor: pointer;
`;

const Circle = styled.div`
  width: 2px;
  height: 2px;
  margin: 1px 0;
  border-radius: 50%;
  background: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const DeleteButton = styled.button`
  background: #111;
  border-radius: 5px;
  padding: 1px 12px;
  color: white;
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  letter-spacing: -0.04em;
  position: absolute;
  right: 12px;
  top: 30px;
`;

const PhotoCard = ({ auth, photo, setPhotos, index, style }) => {
  const [show, setShow] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [deleteClick, setDeleteClick] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [isLike, setIsLike] = useState(localStorage.getItem(photo._id));
  const [likes, setLikes] = useState(photo.likes);

  const handleMoreClick = (e) => {
    e.stopPropagation();
    setShow((cur) => !cur);
  };
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setDeleteClick(true);
  };

  const handleCancelDeleteClick = () => {
    setShow(false);
    setDeleteClick(false);
  };

  const handleOkDeleteClick = async () => {
    setDeleteClick(false);
    setShow(false);

    if (auth?.user) {
      await deleteSmilePhoto({ auth, photo });
      setShowDeleteSuccess(true);
    } else {
      setShowPassword(true);
    }
  };

  const handleCancelPasswordClick = () => {
    setShowPassword(false);
    setShow(false);
  };

  const handleOkPasswordClick = () => {
    setShowDeleteSuccess(true);
    setShowPassword(false);
  };

  const handleOkSuccess = () => {
    window.location.reload();
    setShowDeleteSuccess(false);
    setShow(false);
  };

  const isLiked = (item) => {
    if (auth?.user) {
      return item.likeUserIds.includes(auth.user.id);
    }
  };

  return (
    <Container style={style}>
      {!auth?.user ? (
        <MoreButton onClick={handleMoreClick}>
          <Circle />
          <Circle />
          <Circle />
        </MoreButton>
      ) : (
        auth.user.id === photo.uid && (
          <MoreButton onClick={handleMoreClick}>
            <Circle />
            <Circle />
            <Circle />
          </MoreButton>
        )
      )}
      {show && (
        <DeleteButton onClick={handleDeleteClick}>삭제하기</DeleteButton>
      )}
      <Name>{photo.name}</Name>
      <Image src={photo.url} onClick={() => setShowCard(true)} />
      <Date>{converToLocaleDate(photo.createdAt)}</Date>
      <Score>
        {photo.score}
        <span style={{ fontSize: "12px" }}>%</span>
      </Score>
      <LikeButton
        auth={auth}
        likes={likes}
        isLike={auth?.user ? isLiked(photo) : isLike}
        photo={photo}
        setPhotos={setPhotos}
        index={index}
        setIsLike={setIsLike}
        setLikes={setLikes}
      />
      {photo.score >= 80 && (
        <StampImage
          style={{
            width: "44px",
            position: "absolute",
            bottom: "34px",
            right: "12px",
          }}
          src={`${process.env.PUBLIC_URL}/assets/smile-stamp.png`}
        />
      )}
      {deleteClick && (
        <Modal style={{ padding: "20px" }}>
          <DeletePhotoDialog
            cancelHandler={handleCancelDeleteClick}
            okHandler={handleOkDeleteClick}
          />
        </Modal>
      )}
      {showPassword && (
        <Modal style={{ padding: "20px" }}>
          <DeletePhotoPassword
            photo={photo}
            cancelHandler={handleCancelPasswordClick}
            okHandler={handleOkPasswordClick}
          />
        </Modal>
      )}
      {showDeleteSuccess && (
        <Modal style={{ padding: "20px" }}>
          <DeleteSuccessDialog okHandler={handleOkSuccess} />
        </Modal>
      )}
      {showCard && (
        <Modal>
          <PhotoDialog
            auth={auth}
            likes={likes}
            setLikes={setLikes}
            isLike={auth?.user ? isLiked(photo) : isLike}
            setIsLike={setIsLike}
            photo={photo}
            setPhotos={setPhotos}
            index={index}
            closeHandler={() => setShowCard(false)}
          ></PhotoDialog>
        </Modal>
      )}
    </Container>
  );
};

export default PhotoCard;
