import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import isEndWithConsonant from "../../../utils/isEndWithConsonant";
import { TABLET_LANDSCAPE } from "../../../Constants";
import Heading1 from "../Heading1";
import Heading2 from "../Heading2";
import CardLayout from "../CardLayout";
import { getDate } from "../../../utils/date";
import lineBreaker from "../../../utils/lineBreaker";
import NoDataText from "../components/NoDataText";

const Container = styled.div`
  margin-left: var(--sidebar-width);
  height: calc(100vh - 4.06rem);
  padding: 2.25rem 2.5rem;
  width: 100%;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    margin-left: var(--sidebar-tablet-landscape-width);
  }
`;

const Section = styled.section`
  width: 50%;
  flex: 1;
  max-width: 46.0625rem;
`;

const SectionHeading = styled.h2`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 140%;
`;

const Callout = styled.div`
  padding: 1.38rem 2.25rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 0.75rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.03125rem;
  margin-top: 2.37rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
`;

const Symbol = styled.img`
  width: 1.5rem;
  height: 1.4375rem;
  margin-right: 1.25rem;
`;

const BlockCardContainer = styled.div`
  border-radius: 0.625rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding-bottom: 1rem;
  cursor: pointer;
`;

const BlockImage = styled.img`
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  width: 14.931rem;
  height: 12.02044rem;
  object-fit: cover;
`;

const ListContainer = styled.ul`
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const ColumnHeader = styled.li`
  display: flex;
  /* justify-content: space-evenly; */
  font-weight: 600;
  padding: 1rem 0;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  border-top: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  /* padding-left: 2.31rem; */
`;

const ColumnItem = styled.div`
  text-align: center;
`;

const Row = styled.li`
  display: flex;
  /* justify-content: space-evenly; */
  font-weight: 400;
  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
`;
const RowItem = styled.div`
  text-align: center;
  font-size: 0.875rem;
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const BlockCard = ({ block }) => {
  const handleTitleClick = (e, item) => {
    window.open(item.link);
  };
  return (
    <BlockCardContainer onClick={(e) => handleTitleClick(e, block)}>
      <BlockImage src={block.reportThumbnail} />
      <FlexRow
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1.44rem 0.96rem 0.25rem 0.96rem",
          fontWeight: 500,
        }}
      >
        <span style={{ fontSize: "0.875rem", lineHeight: "1.4375rem" }}>
          {block.title}
        </span>
        <span style={{ color: "#00DBB1", lineHeight: "1.4375rem" }}>
          #{block.number}
        </span>
      </FlexRow>
    </BlockCardContainer>
  );
};

const BlockTable = ({ data }) => {
  const handleTitleClick = (e, item) => {
    window.open(item.link);
  };
  return (
    <ListContainer>
      <ColumnHeader>
        <ColumnItem style={{ flex: 1 }}>날짜</ColumnItem>
        <ColumnItem style={{ flex: 2 }}>트레이닝</ColumnItem>
        <ColumnItem style={{ flex: 3 }}>마음 기록</ColumnItem>
      </ColumnHeader>
      {data.map((item, index) => (
        <Row key={index}>
          <RowItem style={{ flex: 1, color: "#7C8585" }}>
            {getDate(item.createdAt)}
          </RowItem>
          <RowItem
            style={{ flex: 2, cursor: "pointer" }}
            onClick={(e) => handleTitleClick(e, item)}
          >
            {item.title}
          </RowItem>
          <RowItem style={{ flex: 3, textAlign: "left" }}>
            {lineBreaker(item.comment)}
          </RowItem>
        </Row>
      ))}
    </ListContainer>
  );
};

const PersonalTraining = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [isLoading, setIsLoading] = useState(true);
  const [blocks, setBlocks] = useState([]);
  const [blockCount, setBlockCount] = useState(0);
  const [blockComments, setBlockComments] = useState([]);
  const [recommendBlocks, setRecommendBlocks] = useState([]);
  const [blockLikes, setBlockLikes] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const response = await axiosPrivate.get("/block/statistics");
      if (!response.data.ok) {
      }
      setBlockCount(response.data.data.count);
      setBlocks(response.data.data.blocks);
      setRecommendBlocks(response.data.data.recommendBlocks);
      setBlockLikes(response.data.data.blockLikes);
      setBlockComments(response.data.data.blockComments);
      setIsLoading(false);
    };

    fetch();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <Heading1>마음 트레이닝</Heading1>
      <Heading2>내가 어떤 마음들을 기록했는지 확인할 수 있어요</Heading2>
      {blocks.length === 0 ? (
        <Callout>
          <Symbol
            src={`${process.env.PUBLIC_URL}/assets/symbol/symbol_sm.png`}
          />
          {auth.user.name} 님은 아직 마음 트레이닝을 시작하지 않았네요. 한 번
          시작해 볼까요?
        </Callout>
      ) : (
        <Callout>
          <Symbol
            src={`${process.env.PUBLIC_URL}/assets/symbol/symbol_sm.png`}
          />
          {auth.user.name} 님은&nbsp;
          <span style={{ color: "#00DBB1" }}> 총 {blockCount}회</span>의 마음
          트레이닝 중 '{blocks[0].title}'
          {isEndWithConsonant(blocks[0].title) ? "을" : "를"} 가장 많이 했어요
        </Callout>
      )}
      <FlexRow
        style={{ gap: "1.86rem", width: "100%", paddingBottom: "4.38rem" }}
      >
        <Section>
          <FlexRow style={{ marginBottom: "1.69rem" }}>
            <SectionHeading>✍ 나의 마음 기록</SectionHeading>
          </FlexRow>
          <CardLayout
            style={{
              paddingTop: "2.31rem",
              paddingBottom: "2.31rem",
              height: blocks.length === 0 ? "100%" : "auto",
            }}
          >
            {blockComments.length > 0 ? (
              <BlockTable data={blockComments} />
            ) : (
              <FlexColumn style={{ justifyContent: "center", height: "100%" }}>
                <NoDataText />
              </FlexColumn>
            )}
          </CardLayout>
        </Section>
        <Section style={{ maxWidth: "100%" }}>
          <FlexColumn>
            <SectionHeading style={{ marginBottom: "1.69rem" }}>
              내가 좋아한 트레이닝
            </SectionHeading>
            {blockLikes.length > 0 ? (
              <FlexRow style={{ gap: "1.6rem", overflow: "auto" }}>
                {blockLikes.map((item, index) => (
                  <BlockCard key={index} block={item} />
                ))}
              </FlexRow>
            ) : (
              <FlexRow
                style={{
                  color: "#BBBBBB",
                  background: "#F7F7F7",
                  padding: "1.2rem 2.5rem",
                  borderRadius: "0.625rem",
                  marginBottom: "11rem",
                }}
              >
                아직 좋아요를 누른 트레이닝이 없어요
              </FlexRow>
            )}
          </FlexColumn>
          <FlexColumn style={{ marginTop: "2.5rem" }}>
            <SectionHeading style={{ marginBottom: "1.69rem" }}>
              추천 마음 트레이닝
            </SectionHeading>
            {blockCount > 0 ? (
              <FlexRow style={{ gap: "1.6rem", overflow: "auto" }}>
                {recommendBlocks.map((item, index) => (
                  <BlockCard key={index} block={item} />
                ))}
              </FlexRow>
            ) : (
              <FlexRow
                style={{
                  color: "#BBBBBB",
                  background: "#F7F7F7",
                  padding: "1.2rem 2.5rem",
                  borderRadius: "0.625rem",
                  marginBottom: "11rem",
                }}
              >
                아직 추천받은 마음 트레이닝이 없어요
              </FlexRow>
            )}
          </FlexColumn>
        </Section>
      </FlexRow>
    </Container>
  );
};

export default PersonalTraining;
