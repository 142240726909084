import styled from "styled-components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import BackgroundContainer from "../../components/BackgroundContainer";
import BackgroundSymbol from "../../components/BackgroundSymbol";
import Form from "../../components/Form";
import LabelInput from "../../components/LabelInput";
import SubmitButton from "../../components/SubmitButton";
import ErrorText from "../../components/ErrorText";
import ToastPopup from "../../components/ToastPopup";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import Card from "./components/Card";
import Checkbox from "../../components/Checkbox";
import validatePassword from "../../utils/validatePassword";
import { MOBILE } from "../../Constants";
import { useMediaQuery } from "react-responsive";
import { DotWave } from "@uiball/loaders";
import { Helmet } from "react-helmet";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // @media screen and (max-width: 480px) {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   width: 90%;
  // }
`;

const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  margin-top: ${({ isMobile }) => (isMobile ? "0" : "4.94rem")};
  margin-bottom: ${({ isMobile }) => (isMobile ? "2rem" : "2.5rem")};
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const CheckboxText = styled.p`
  font-size: 0.875rem;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const InputCheckbox = styled.input`
  accent-color: ${({ theme }) => theme.primary.normal};
`;

const Verify = styled.img`
  width: 0.9375rem;
  height: 0.9375rem;
`;

const VerifyText = styled.p`
  font-size: 0.8125rem;
  line-height: 140%; /* 1.1375rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme, state }) =>
    state === "correct"
      ? theme.primary.normal
      : state === "error"
      ? theme.colors.error
      : theme.grayScaleColor.gray70};
`;

const Button = styled.div`
  width: 20.56rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme, disabled }) =>
    disabled ? theme.grayScaleColor.gray30 : theme.grayScaleColor.white};
  font-size: 1.13rem;
  border-radius: 0.31rem;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.grayScaleColor.gray10 : theme.primary.normal};
  border: none;
  cursor: pointer;
  // &:hover {
  //   background-color: ${({ theme }) => theme.primary.hover};
  // }

  transition: all 0.3s;
  // To adapt css in iphone
  -webkit-appearance: none;
`;

// 모바일 컴포넌트

const MobileContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
`;

const BottomSheet = styled.div`
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 1.88rem;
  bottom: 0;
  width: 100%;
  position: fixed;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
`;

const Logo = styled.img`
  width: 6.25rem;
  height: 1.13188rem;
`;

const SetAgree = ({ email, password, setPasswordToken }) => {
  console.log(email, password, setPasswordToken);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const params = new URLSearchParams(location.search);
  const { auth, setAuth, setPersist } = useAuth();
  const [disabled, setDisabled] = useState(true);

  const [isUseAgreeCheck, setIsUseAgreeCheck] = useState(false);
  const [isPrivacyAgreeCheck, setIsPrivacyAgreeCheck] = useState(false);
  const [isMarketingCheck, setIsMarketingCheck] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE})`,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.patch(
        "/set-password",
        {
          email,
          password,
          setPasswordToken,
          isMarketingAgree: isMarketingCheck,
        },
        {
          withCredentials: true,
        }
      );

      // alert(response.data.msg);

      const accessToken = response.data.accessToken;
      setAuth({
        user: {
          email,
          id: response.data.user._id,
          ...response.data.user,
        },
        accessToken,
      });
      localStorage.setItem("persist", true);
      setPersist(true);
      setIsLoading(false);
      navigate("/set-phone");
    } catch (err) {
      alert(err.response.data.msg);
      setIsLoading(false);
    }
  };

  const isDisable = () => {
    return !(isUseAgreeCheck && isPrivacyAgreeCheck);
  };

  if (isMobile) {
    return (
      <MobileContainer>
        <Helmet>
          <meta name="theme-color" content="#FFFFFF" />
        </Helmet>
        <BottomSheet>
          <Text isMobile={isMobile}>
            맘핏 서비스 이용약관에
            <br />
            동의가 필요해요
          </Text>
          <FlexColumn
            style={{
              width: "100%",
              gap: "0.1rem",
              fontSize: "0.75rem",
            }}
          >
            <FlexRow
              style={{
                alignItems: "center",
                gap: "0.625rem",
                padding: "1rem",
                backgroundColor: "#F8FBFB",
                borderRadius: "10px",
              }}
            >
              <Checkbox
                active={
                  isPrivacyAgreeCheck && isUseAgreeCheck && isMarketingCheck
                }
                onClick={(e) => {
                  if (
                    isPrivacyAgreeCheck &&
                    isUseAgreeCheck &&
                    isMarketingCheck
                  ) {
                    setIsPrivacyAgreeCheck(false);
                    setIsUseAgreeCheck(false);
                    setIsMarketingCheck(false);
                  } else {
                    setIsPrivacyAgreeCheck(true);
                    setIsUseAgreeCheck(true);
                    setIsMarketingCheck(true);
                  }
                }}
              />
              <CheckboxText>약관 전체 동의</CheckboxText>
            </FlexRow>
            <FlexRow
              style={{ alignItems: "center", gap: "0.625rem", padding: "1rem" }}
            >
              <Checkbox
                active={isUseAgreeCheck}
                onClick={(e) => {
                  setIsUseAgreeCheck((prev) => !prev);
                }}
              />
              <CheckboxText>
                [필수]{" "}
                <a
                  href="https://s3.ap-northeast-2.amazonaws.com/maum.fit-assets/64d28640-7ebb-11ee-b962-0242ac120002.pdf"
                  target={"_blank"}
                  style={{ color: "inherit" }}
                  rel="noreferrer"
                >
                  <span style={{ textDecoration: "underline" }}>
                    서비스 이용약관
                  </span>
                </a>{" "}
                동의
              </CheckboxText>
            </FlexRow>
            <FlexRow
              style={{ alignItems: "center", gap: "0.625rem", padding: "1rem" }}
            >
              <Checkbox
                active={isPrivacyAgreeCheck}
                onClick={(e) => {
                  setIsPrivacyAgreeCheck((prev) => !prev);
                }}
              />
              <CheckboxText>
                [필수]{" "}
                <a
                  href="https://s3.ap-northeast-2.amazonaws.com/maum.fit-assets/47dcdc70-7ebb-11ee-b962-0242ac120002.pdf"
                  target={"_blank"}
                  style={{ color: "inherit" }}
                  rel="noreferrer"
                >
                  <span style={{ textDecoration: "underline" }}>
                    개인정보 수집 및 이용
                  </span>
                </a>{" "}
                동의
              </CheckboxText>
            </FlexRow>
            <FlexRow
              style={{ alignItems: "center", gap: "0.625rem", padding: "1rem" }}
            >
              <Checkbox
                active={isMarketingCheck}
                onClick={(e) => {
                  setIsMarketingCheck((prev) => !prev);
                }}
              />
              <CheckboxText>[선택] 이벤트/마케팅 혜택 소식 받기</CheckboxText>
            </FlexRow>
          </FlexColumn>
          <Button
            style={{
              marginTop: "2rem",
              width: "100%",
            }}
            disabled={isDisable() || isLoading}
            onClick={
              isLoading || isDisable()
                ? () => {
                    console.log("click");
                  }
                : handleSubmit
            }
          >
            {isLoading ? <DotWave size={40} speed={1} color="#FFF" /> : "확인"}
          </Button>
          {/* value={
            isLoading ? (
              "회원가입 중..."
            ) : (
              <DotWave size={47} speed={1} color="#FF812C" />
            )
          }
          style={{
            marginTop: "3.12rem",
            marginBottom: "3.12rem",
            width: "100%",
          }}
          disabled={isDisable() || isLoading}
          onClick={handleSubmit}
        /> */}
        </BottomSheet>
      </MobileContainer>
    );
  }

  return (
    // <BackgroundContainer>
    <Card style={{ width: "480px" }}>
      {/* <Form> */}
      <Text>
        맘핏 서비스 이용약관에
        <br />
        동의가 필요해요
      </Text>

      <FlexColumn
        style={{
          width: "100%",
          gap: "0.25rem",
          fontSize: "0.75rem",
        }}
      >
        <FlexRow
          style={{
            alignItems: "center",
            gap: "0.625rem",
            padding: "1rem",
            backgroundColor: "#F8FBFB",
            borderRadius: "10px",
          }}
        >
          <Checkbox
            active={isPrivacyAgreeCheck && isUseAgreeCheck && isMarketingCheck}
            onClick={(e) => {
              if (isPrivacyAgreeCheck && isUseAgreeCheck && isMarketingCheck) {
                setIsPrivacyAgreeCheck(false);
                setIsUseAgreeCheck(false);
                setIsMarketingCheck(false);
              } else {
                setIsPrivacyAgreeCheck(true);
                setIsUseAgreeCheck(true);
                setIsMarketingCheck(true);
              }
            }}
          />
          <CheckboxText>약관 전체 동의</CheckboxText>
        </FlexRow>
        <FlexRow
          style={{ alignItems: "center", gap: "0.625rem", padding: "1rem" }}
        >
          <Checkbox
            active={isUseAgreeCheck}
            onClick={(e) => {
              setIsUseAgreeCheck((prev) => !prev);
            }}
          />
          <CheckboxText>
            [필수]{" "}
            <a
              href="https://s3.ap-northeast-2.amazonaws.com/maum.fit-assets/64d28640-7ebb-11ee-b962-0242ac120002.pdf"
              target={"_blank"}
              style={{ color: "inherit" }}
              rel="noreferrer"
            >
              <span style={{ textDecoration: "underline" }}>
                서비스 이용약관
              </span>
            </a>{" "}
            동의
          </CheckboxText>
        </FlexRow>
        <FlexRow
          style={{ alignItems: "center", gap: "0.625rem", padding: "1rem" }}
        >
          <Checkbox
            active={isPrivacyAgreeCheck}
            onClick={(e) => {
              setIsPrivacyAgreeCheck((prev) => !prev);
            }}
          />
          <CheckboxText>
            [필수]{" "}
            <a
              href="https://s3.ap-northeast-2.amazonaws.com/maum.fit-assets/%E1%84%80%E1%85%A2%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%87%E1%85%A9%E1%84%89%E1%85%AE%E1%84%8C%E1%85%B5%E1%86%B8%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%83%E1%85%A9%E1%86%BC%E1%84%8B%E1%85%B4%E1%84%89%E1%85%A5.pdf"
              target={"_blank"}
              style={{ color: "inherit" }}
              rel="noreferrer"
            >
              <span style={{ textDecoration: "underline" }}>
                개인정보 수집 및 이용
              </span>
            </a>{" "}
            동의
          </CheckboxText>
        </FlexRow>
        <FlexRow
          style={{ alignItems: "center", gap: "0.625rem", padding: "1rem" }}
        >
          <Checkbox
            active={isMarketingCheck}
            onClick={(e) => {
              setIsMarketingCheck((prev) => !prev);
            }}
          />
          <CheckboxText>[선택] 이벤트/마케팅 혜택 소식 받기</CheckboxText>
        </FlexRow>
      </FlexColumn>
      <Button
        style={{
          marginTop: "3.12rem",
          marginBottom: "3.12rem",
          width: "100%",
        }}
        disabled={isDisable() || isLoading}
        onClick={
          isLoading || isDisable()
            ? () => {
                console.log("click");
              }
            : handleSubmit
        }
      >
        {isLoading ? <DotWave size={40} speed={1} color="#FFF" /> : "확인"}
      </Button>
      {/* </Form> */}
      {/* {success && (
        <ToastPopup
          text={`비밀번호를 성공적으로 변경했어요 🥳<br />맘핏 센터에서 로그인을 완료해 주세요!`}
          buttonText="로그인 화면으로 이동하기"
          onClick={handleCloseClick}
        />
      )} */}
    </Card>
  );
};

export default SetAgree;
