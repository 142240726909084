import styled from "styled-components";
import lineBreaker from "../../../utils/lineBreaker";
import Button from "./Button";

const Container = styled.div`
  width: 100%;
  max-width: 335px;
  height: 178px;
  padding: 30px;
  background: white;
  border-radius: 20px;
`;

const Title = styled.h3`
  color: #000;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.04em;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 22px;
  gap: 10px;
`;

const SavePhotoDialog = ({ text, okHandler, cancelHandler }) => {
  return (
    <Container>
      <Title>{lineBreaker(text)}</Title>
      <ButtonContainer>
        <Button text={"결과 화면으로 돌아가기"} clickHandler={okHandler} />
      </ButtonContainer>
    </Container>
  );
};

export default SavePhotoDialog;
