import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const Background = styled.div`
  background: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #000;
  width: fit-content;
`;

const Box = styled.div`
  height: 40px;
  overflow: hidden;
  position: relative;

  &::before {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    content: "";
    height: 10px;
    position: absolute;
    background: linear-gradient(180deg, #fff, rgba(255, 255, 255, 0));
  }
  &::after {
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    content: "";
    height: 10px;
    position: absolute;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff);
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  transform: translateY(0);
  transition: transform 2.5s ease-in-out;
`;

const ListItem = styled.li`
  opacity: 1;
  height: 40px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.primary.normal};
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 3.4rem */
  letter-spacing: -0.03125rem;
`;

const RandomNumberScroll = ({ setRollingFinish, fixedNumber = 10 }) => {
  const ulRef = useRef(null);
  const itemCount = 30; // 원하는 아이템 수
  const min = 10;
  const max = 100;

  // 가중치를 고려하여 랜덤 값을 생성하는 함수
  const weightedRandom = () => {
    const random = Math.random();
    if (random < 0.75) {
      // 75% 확률로 10~50 사이의 값을 반환
      return Math.floor(Math.random() * (50 - 10 + 1)) + 10;
    } else {
      // 25% 확률로 51~100 사이의 값을 반환
      return Math.floor(Math.random() * (100 - 51 + 1)) + 51;
    }
  };

  useEffect(() => {
    const ul = ulRef.current;
    const items = ul.children;
    const itemHeight = items[0].offsetHeight; // 패딩을 제거한 순수 높이
    const numItems = items.length;

    const getRandomInt = (max) => {
      return Math.floor(Math.random() * max);
    };

    const scrollToRandomItem = () => {
      const randomIndex = getRandomInt(numItems); // 0부터 numItems-1 사이의 랜덤 인덱스
      const translateYValue = -(itemCount - 1) * itemHeight;
      ul.style.transform = `translateY(${translateYValue}px)`;
    };

    // 0.5초 후에 랜덤한 지점에서 멈추도록 설정
    const timer = setTimeout(scrollToRandomItem, 0);
    const end = setTimeout(() => {
      setRollingFinish(true);
    }, 2000);

    return () => {
      clearTimeout(timer);
      clearTimeout(end);
    };
  }, []);

  return (
    <Background id="two">
      <Box className="box">
        <List ref={ulRef}>
          {Array.from({ length: itemCount - 1 }, (_, i) => (
            <ListItem key={i}>{weightedRandom()} 원</ListItem>
          ))}
          <ListItem key={itemCount - 1}>{fixedNumber} 원</ListItem>
        </List>
      </Box>
    </Background>
  );
};

export default React.memo(RandomNumberScroll);
