import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import axios from "../../../api/axios";
import FlexRow from "../../../components/FlexRow";
import ModalBackground from "../../../components/ModalBackground";
import TopBar from "../../../components/TopBar";
import { MOBILE } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { isAuthEmpty } from "../../../utils/isAuthEmpty";
import Button from "./components/Button";
import HighlightModal from "./components/HighlightModal";
import Question from "./components/Question";
import QuestionForSale from "./components/QuestioForSale";

const Container = styled.div`
  width: 100%;
  //   height: 100vh;
  background: ${({ theme }) => theme.backgroundColor.darkgray20};
  padding: 8.13rem 6.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 425px) {
    padding: 0;
    padding-top: 8.13rem;
  }
`;

const Wrapper = styled.div`
  max-width: 62.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 1.25rem;
  padding: 3.75rem 4.06rem;
  margin-bottom: 40px;
  @media (max-width: 425px) {
    padding: 2.5rem 1.56rem;

    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const Heading = styled.h1`
  font-size: 1.63rem;
  font-weight: 700;
  line-height: 2.25rem;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.grayScaleColor.black};
  text-align: center;
  margin-bottom: 4rem;
`;

// const ProgressIndicator = styled.div`
//   width: 7.69rem;
//   height: 2.31rem;
//   background: ${({ theme, active }) =>
//     active ? theme.primary.normal : theme.grayScaleColor.gray20};
//   position: fixed;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-top-left-radius: 0.44rem;
//   border-bottom-left-radius: 0.44rem;
//   color: white;
//   right: 0;
//   font-weight: 600;
//   font-size: 1.13rem;
//   font-family: "Montserrat";
// `;

const NavBar = styled.div`
  width: 100%;
  height: 4.06rem;
  background-color: ${({ theme }) => theme.primary.normal};
  display: flex;
  justify-content: center;
  z-index: 1;
  padding: 0 6.25rem;
  top: 0;
  ${({ sticky }) => sticky && "position: fixed;"}
  @media (max-width: 425px) {
    padding: 0 1.56rem;
  }
`;

const NavBarWrapper = styled.div`
  width: 100%;
  max-width: 62.5rem;

  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? "center" : "space-between")};
  align-items: center;
  color: white;
`;

const ProgressContainer = styled.div`
  width: 100%;
  border-radius: 0.75rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProgressBarContainer = styled.div`
  height: 0.75rem;
  border-radius: 0.75rem;
  width: 100%;
  position: relative;
`;
const ProgressBar = styled.div`
  opacity: 0.5;
  width: 100%;
  height: 0.75rem;
  border-radius: 0.75rem;
  background: white;
  opacity: 0.5;
`;

const progressAnimation = (prev, cur) => keyframes`
    from {
        width: ${prev}%;
    }
    to {
        width: ${cur}%;
    }
`;

const Progressing = styled.div`
  width: ${({ width }) => width}%;
  height: 0.75rem;
  border-radius: 0.75rem;
  background: white;
  position: absolute;
  opacity: 1;
  top: 0;
  animation: ${({ prevWidth, width }) => progressAnimation(prevWidth, width)}
    0.3s linear;
  animation-fill-mode: forwards;
`;

const ProgressText = styled.div`
  font-size: 1.13rem;
  font-weight: 600;
  line-height: 1.38rem;
  margin-left: 1.06rem;
  width: 60px;
  text-align: right;
`;

const SurveyForSale = () => {
  const CASH = 1000;
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [isSticky, setIsSticky] = useState(false);
  const [y, setY] = useState(0);
  const [survey, setSurvey] = useState([]);
  const [multipleChoices, setMultipleChoices] = useState({});
  const [scaleChoices, setScaleChoices] = useState({});
  const [shortText, setShortText] = useState("");
  const [highlightText, setHighlightText] = useState("");
  const [count, setCount] = useState(0);
  const [prevCount, setPrevCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: MOBILE });

  const headerRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();

  const handleInputChange = (e) => {
    setShortText(e.target.value);
  };

  const isDisabled = () => {
    const mc = Object.values(multipleChoices).filter((i) => i === true).length;
    const sc = Object.values(scaleChoices).filter((i) => i).length;

    if (mc === 0 || sc < 9) {
      return true;
    }

    return false;
  };

  const handleShowModalClick = () => {
    if (isDisabled()) {
      // 질문에 답변 안 한 문항으로 스크롤
      let scrollAmount = -1;
      const mc = Object.values(multipleChoices).filter(
        (i) => i === true
      ).length;
      if (mc === 0) {
        window.scrollTo({
          top: isMobile ? 250 : 300,
          behavior: "smooth",
        });
      } else {
        for (let i = 1; i <= 9; i++) {
          if (!Object.keys(scaleChoices).includes(i.toString())) {
            scrollAmount = i;
            break;
          }
        }
        // if (!scaleChoices[questionIndex + 1]) {
        // }

        window.scrollTo({
          top: isMobile
            ? window.scrollY - 360 * (10 - scrollAmount)
            : window.scrollY - 390 * (10 - scrollAmount),
          behavior: "smooth",
        });
      }
    }
    // setIsModalOpen(true);
  };

  const handleSubmitClick = async (type) => {
    console.log(survey);
    if (isDisabled()) {
      // 질문에 답변 안 한 문항으로 스크롤
      let scrollAmount = -1;
      const mc = Object.values(multipleChoices).filter(
        (i) => i === true
      ).length;
      if (mc === 0) {
        window.scrollTo({
          top: isMobile ? 250 : 300,
          behavior: "smooth",
        });
      } else {
        for (let i = 1; i <= 9; i++) {
          if (!Object.keys(scaleChoices).includes(i.toString())) {
            scrollAmount = i;
            break;
          }
        }
        // if (!scaleChoices[questionIndex + 1]) {
        // }

        window.scrollTo({
          top: isMobile
            ? window.scrollY - 360 * (10 - scrollAmount)
            : window.scrollY - 390 * (10 - scrollAmount),
          behavior: "smooth",
        });
      }
      return;
    }
    setIsSubmitting(true);
    const mc = [];
    for (const key in multipleChoices) {
      if (multipleChoices[key]) {
        mc.push(Number(key));
      }
    }

    const pickIndex = mc[Math.floor(Math.random() * mc.length)];

    let wellBeing = 0;
    for (const key in scaleChoices) {
      if (key === "9") {
        wellBeing += (20 * scaleChoices[key]) / 7; // 7 = 7점 척도
      } else {
        wellBeing += (10 * scaleChoices[key]) / 7;
      }
    }

    const surveyId = survey._id;
    const state = {
      surveyId,
      shareId: survey.shareQuestionId._id,
      weekSentence: survey.questionIds[0].choices[pickIndex].recommend[1],
      recommendQuestion:
        survey.questionIds[0].choices[pickIndex].recommendQuestion[1],
      chatGPTPrompt: survey.questionIds[0].choices[pickIndex].chatGPTPrompts[1],
      wellBeing: Number(wellBeing.toFixed(2)),
      weekHighlight: type === "no" ? "" : highlightText,
    };

    const q1 = [];
    const q1State = [];

    for (let i = 0; i < mc.length; i++) {
      q1.push(survey.questionIds[0].choices[mc[i]]._id);
      q1State.push(survey.questionIds[0].choices[mc[i]].word);
    }

    const q2 = {};
    const q2State = {};
    // 2번 항목 답안
    for (const key in scaleChoices) {
      q2[survey.questionIds[key].question] = scaleChoices[key];
      q2State[survey.questionIds[key].permavacType || "happiness"] =
        scaleChoices[key];
    }

    // 2번 항목 최대, 최소
    let minIndex = 1;
    let min = scaleChoices[minIndex];
    let maxIndex = 1;
    let max = scaleChoices[maxIndex];
    for (let i = 1; i < 9; i++) {
      if (scaleChoices[i] < min) {
        min = scaleChoices[i];
        minIndex = i;
      }
      if (scaleChoices[i] > max) {
        max = scaleChoices[i];
        maxIndex = i;
      }
    }
    let response;
    if (location.pathname.includes("test")) {
      response = await axios.post("/checkup-v2/result/test", {
        answers: [q1, q2, shortText],
        wellBeingScore: wellBeing,
        low: survey.questionIds[minIndex].permavacType,
        high: survey.questionIds[maxIndex].permavacType,
        weekSentence: survey.questionIds[0].choices[pickIndex].recommend[1],
        surveyId,
        shareId: survey.shareQuestionId._id,
        demoId: location.state.id,
        type: "test",
      });
    } else {
      const point = auth.user.isFirstCheckup
        ? CASH
        : auth.user.lastCheckupPoint === CASH
        ? CASH
        : auth.user.checkupInARow + 1 < 5
        ? (auth.user.checkupInARow + 1) * 200
        : CASH;
      response = await axiosPrivate.post(`/checkup-v2/result`, {
        answers: [q1, q2, shortText],
        wellBeingScore: wellBeing,
        low: survey.questionIds[minIndex].permavacType,
        high: survey.questionIds[maxIndex].permavacType,
        weekSentence: survey.questionIds[0].choices[pickIndex].recommend[1],
        recommendQuestion:
          survey.questionIds[0].choices[pickIndex].recommendQuestion[1],
        chatGPTPrompt:
          survey.questionIds[0].choices[pickIndex].chatGPTPrompts[1],
        weekHighlight: type === "no" ? "" : highlightText,
        totalEarnedCash: auth.user.point + point,
        surveyId,
        shareId: survey.shareQuestionId._id,
        point,
        checkupCount: auth.user.checkupCount + 1,
        checkupInARow: auth.user.checkupInARow + 1,
      });
    }
    setIsModalOpen(false);
    setHighlightText("");

    location.pathname.includes("test")
      ? navigate("/checkup/v2/test/result", {
          state: {
            ...state,
            low: survey.questionIds[minIndex].permavacType,
            high: survey.questionIds[maxIndex].permavacType,
            block: response.data.data.result.blockId,
            answers: [q1State, q2State, shortText],
          },
        })
      : navigate("/checkup/v2/result", { state });
  };

  const convertQuestion = (question) => {
    let msg = question;

    switch (question) {
      case "이번 주, 나를 잘 표현한 문장에 체크해주세요.":
        msg = "요즘, 나를 잘 표현한 문장에 체크해주세요.";
        break;
      case "일하면서 집중을 잘 했나요?":
        msg = "일하면서 집중을 잘 하나요?";
        break;
      case "업무를 주도적으로 수행했나요?":
        msg = "업무를 주도적으로 수행하나요?";
        break;
      case "맡은 일에 자신감이 있었나요?":
        msg = "맡은 일에 자신감이 있나요?";
        break;
      case "계획한 바를 잘 마무리했나요?":
        msg = "계획한 바를 잘 마무리하나요?";
        break;
      case "주변 동료들과 잘 지냈나요?":
        msg = "주변 동료들과 잘 지내나요?";
        break;
      case "직장에서 기분 좋게 지냈나요?":
        msg = "직장에서 기분 좋게 지내나요?";
        break;
      case "일하면서 보람이나 의미를 느꼈나요?":
        msg = "일하면서 보람이나 의미를 느끼나요?";
        break;
      case "직장에서 활기차게 보냈나요?":
        msg = "직장에서 활기차게 보내나요?";
        break;
      case "전반적으로 직장에서 행복했나요?":
        msg = "전반적으로 직장에서 행복하나요?";
        break;
      default:
        break;
    }

    return msg;
  };
  const getPosition = (e) => {
    // console.log(window.scrollY);

    // console.log("####### getPosition", window.scrollY);
    // console.log(headerRef.current);
    if (window.scrollY > 100) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }

    setY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", getPosition);

    const fetch = async () => {
      const response = location.pathname.includes("test")
        ? await axios.get("checkup-v2/survey/test?type=test")
        : await axiosPrivate.get(`checkup-v2/survey`);
      if (response.data.ok && !response.data.msg) {
        setSurvey(response.data.survey);
      } else {
        navigate("/checkup/v2");
      }
    };

    fetch();

    return () => {
      window.removeEventListener("scroll", getPosition);
    };
  }, []);

  useEffect(() => {
    const mc = Object.values(multipleChoices).filter((i) => i === true).length;
    const sc = Object.values(scaleChoices).filter((i) => i).length;
    let sum = 0;
    if (mc > 0) {
      sum++;
    }
    // if (shortText.length > 0) {
    //   sum++;
    // }
    sum += sc;
    setPrevCount(count);
    setCount(sum);
  }, [multipleChoices, scaleChoices, shortText]);

  if (survey.length === 0) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>맘핏 | 마음체크업</title>
      </Helmet>
      {!isSticky ? (
        <TopBar
          ref={headerRef}
          sticky={true}
          name={isAuthEmpty(auth) ? "김블룸" : auth.user.name}
          company={isAuthEmpty(auth) ? "블룸컴퍼니" : auth.user.companyId.name}
        />
      ) : (
        <NavBar sticky={true}>
          <NavBarWrapper>
            <ProgressContainer>
              <ProgressBarContainer>
                <ProgressBar />
                <Progressing width={count * 10} prevWidth={prevCount * 10} />
              </ProgressBarContainer>

              <ProgressText>{count * 10}%</ProgressText>
            </ProgressContainer>
          </NavBarWrapper>
        </NavBar>
      )}
      <Container>
        {isMobile && <Heading>요즘 내 마음을 되돌아 봤을 때,</Heading>}
        <Wrapper>
          {!isMobile && <Heading>요즘 내 마음을 되돌아 봤을 때,</Heading>}
          {[...survey.questionIds, survey.shareQuestionId].map(
            (data, index) => {
              return (
                <QuestionForSale
                  key={data._id}
                  data={data}
                  done={count >= 10}
                  title={convertQuestion(data.question)}
                  subTitle={data.subQuestion}
                  required={data.required}
                  multipleChoices={multipleChoices}
                  setMultipleChoices={setMultipleChoices}
                  question={data.question}
                  questionIndex={index}
                  scaleChoices={scaleChoices}
                  setScaleChoices={setScaleChoices}
                  scales={data.scale}
                  handleInputChange={handleInputChange}
                ></QuestionForSale>
              );
            }
          )}

          <Button
            // disabled={isDisabled()}
            onClick={handleSubmitClick}
            style={{ width: "18.5rem" }}
          >
            👌 모두 응답했어요!
          </Button>
        </Wrapper>
        {/* <ProgressIndicator active={!isDisabled()}>
        {count} / 11 완료
      </ProgressIndicator> */}
      </Container>
      {/* {isModalOpen && (
        <ModalBackground>
          <HighlightModal
            onClick={handleSubmitClick}
            setHighlightText={setHighlightText}
            text={highlightText}
            handleTextChange={(e) => setHighlightText(e.target.value)}
          />
        </ModalBackground>
      )} */}
    </>
  );
};

export default SurveyForSale;
