import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { PAYMENT_TYPE } from "../../constants/payments";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const SubscribePayment = () => {
  const PAYMENT_CLIENT_KEY = "test_ck_GePWvyJnrKb42nKBgB6VgLzN97Eo";

  const { auth } = useAuth();

  const [users, setUsers] = useState(0);
  const [planType, setPlanType] = useState("");
  const [isSubscribing, setIsSubscribing] = useState();
  const paymentTypeRef = useRef();
  const axiosPrivate = useAxiosPrivate();
  const customerKey = "_6T8fgAD5KB07QzAVEfPQ";
  const paymentWidgetRef = useRef();
  const paymentMethodsWidgetRef = useRef();
  // const handleClick = async () => {
  //   var tossPayments = window.TossPayments(PAYMENT_CLIENT_KEY);

  //   try {
  //     // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
  //     // https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
  //     await tossPayments.requestBillingAuth("카드", {
  //       customerKey: "AP5F2TJNRmVRwjghqV95k",
  //       successUrl: `${window.location.origin}/payments/subscribe/success`,
  //       failUrl: `${window.location.origin}/fail`,
  //     });
  //   } catch (error) {
  //     // 에러 처리하기
  //     console.error(error);
  //   }
  // };

  //   useEffect(() => {
  //     (async () => {
  //       const paymentWidget = await window.PaymentWidget(
  //         PAYMENT_CLIENT_KEY,
  //         customerKey
  //       );
  //       const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
  //         "#payment-widget",
  //         {
  //           value: 5000,
  //         }
  //       );

  //       paymentWidget.renderAgreement("#agreement");

  //       paymentWidgetRef.current = paymentWidget;
  //       paymentMethodsWidgetRef.current = paymentMethodsWidget;
  //     })();
  //   });

  function m_Completepayment(frm_mpi, closeEvent) {
    var frm = document.order_info;

    if (frm_mpi.res_cd.value == "0000") {
      window.GetField(frm, frm_mpi);

      frm.submit();

      closeEvent();
    } else {
      closeEvent();

      setTimeout(
        'alert( "[' +
          frm_mpi.res_cd.value +
          "]" +
          frm_mpi.res_msg.value +
          '");',
        1000
      );
    }
  }
  /* 표준웹 실행 */
  function jsf__pay(form) {
    try {
      window.KCP_Pay_Execute(form);
    } catch (e) {
      /* IE 에서 결제 정상종료시 throw로 스크립트 종료 */
    }
  }

  // 주문번호 생성 예제
  function init_orderid() {
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    var date = today.getDate();
    var time = today.getTime();

    if (parseInt(month) < 10) {
      month = "0" + month;
    }

    var vOrderID = year + "" + month + "" + date + "" + time;

    return vOrderID;
  }

  const handleButtonClick = async (event, type) => {
    if (type === PAYMENT_TYPE.YEAR) {
      paymentTypeRef.current.value = "2:1m";
    } else {
      paymentTypeRef.current.value = "2:1m";
    }
    setPlanType(type);
    console.log(document.order_info);
    jsf__pay(document.order_info);
  };

  useEffect(() => {
    (async () => {
      const response = await axiosPrivate.get(
        `/company/${auth.user.companyId._id}`
      );
      console.log(response.data.data);
      if (response.data.ok) {
        // TODO: 유저 인원 수 변경
        setUsers(response.data.data.users);
        setIsSubscribing(response.data.data.company.isSubscribing);
      }
    })();
  }, []);
  return (
    <div id="test">
      <div>
        <h3>월간 플랜</h3>
        <p>10,000원</p>
        <p>1인 / 월 1회 결제</p>
        <button onClick={(e) => handleButtonClick(e, PAYMENT_TYPE.MONTH)}>
          이 플랜 선택
        </button>
      </div>
      <div>
        <h3>연간 플랜</h3>
        <p>6,000원</p>
        <p>1인 / 월 1회 결제</p>
        <button onClick={(e) => handleButtonClick(e, PAYMENT_TYPE.YEAR)}>
          이 플랜 선택
        </button>
      </div>
      <div>
        <h3>카드 변경</h3>
        <button onClick={(e) => handleButtonClick(e, PAYMENT_TYPE.YEAR)}>
          변경하기
        </button>
      </div>
      {/* <div id="payment-widget" />
      <div id="agreement" />
      <button onClick={handleClick}>결제</button> */}
      <form
        name="order_info"
        // method="post"
        action="/payments/subscribe/success"
      >
        <div>
          {/* <!-- header --> */}
          {/* <div class="header">
            <a href="../index.html" class="btn-back">
              <span>뒤로가기</span>
            </a>
            <h1 class="title">배치키 발급 SAMPLE</h1>
          </div> */}
          {/* <!-- //header -->
        <!-- contents --> */}
          {/* <div id="skipCont" class="contents">
            <p class="txt-type-1">
              이 페이지는 결제를 배치키 발급 요청하는 샘플 페이지입니다.
            </p>
            <p class="txt-type-2">
              소수 수정 시 [※ 필수] 또는 [※ 옵션] 표시가 포함된 문장은 가맹점의
              상황에 맞게 적절히 수정 적용하시기 바랍니다.
            </p>
            
            <div Class="Line-Type-1"></div>
            <ul class="list-btn-2">
              <li>
                <a
                  href="#none"
                  // onclick="jsf__pay(document.order_info);"
                  onClick={() => jsf__pay(document.order_info)}
                  class="btn-type-2 pc-wd-3"
                >
                  결제하기
                </a>
              </li>
            </ul>
          </div> */}
          {/* <!-- //contents --> */}

          <input type="hidden" name="users" value={users} />
          <input type="hidden" name="planType" value={planType} />
          {/* <!-- 주문번호(ordr_idxx) --> */}
          <input
            type="hidden"
            name="ordr_idxx"
            value={init_orderid()}
            maxLength="40"
          />
          {/* <!-- 가맹점 정보 설정--> */}
          <input type="hidden" name="site_cd" value="BA001" />
          {/* <!-- 그룹아이디(kcpgroup_id) --> */}
          {/* TODO: 그룹아이디는 KCP관리자 페이지에서 생성해야 함. 고정값인듯 */}
          <input type="hidden" name="kcpgroup_id" value="BA0011000348" />
          <input type="hidden" name="site_name" value="TEST SITE" />
          {/* <!-- 상품제공기간 설정 --> */}
          <input ref={paymentTypeRef} type="hidden" name="good_expr" value="" />
          {/* <!-- 결제 방법 : 인증키 요청--> */}
          <input type="hidden" name="pay_method" value="AUTH:CARD" />
          {/* <!-- 인증 방식 : 공동인증--> */}
          <input type="hidden" name="card_cert_type" value="BATCH" />
          {/* <!-- 배치키 발급시 주민번호 입력을 결제창 안에서 진행 --> */}
          <input type="hidden" name="batch_soc" value="Y" />
          {/* <!-- 
			※필수 항목
			표준웹에서 값을 설정하는 부분으로 반드시 포함되어야 합니다값을 설정하지 마십시오
	    --> */}
          <input type="hidden" name="module_type" value="01" />
          <input type="hidden" name="res_cd" value="" />
          <input type="hidden" name="res_msg" value="" />
          <input type="hidden" name="enc_info" value="" />
          <input type="hidden" name="enc_data" value="" />
          <input type="hidden" name="tran_cd" value="" />

          {/* <!-- 주민번호 S / 사업자번호 C 픽스 여부 --> */}
          <input type="hidden" name="batch_soc_choice" value="S" />

          {/* <!-- 배치키 발급시 카드번호 리턴 여부 설정 --> */}
          {/* <!-- Y : 1234-4567-****-8910 형식, L : 8910 형식(카드번호 끝 4자리) --> */}
          <input type="hidden" name="batch_cardno_return_yn" value="Y" />

          {/* <!-- batch_cardno_return_yn 설정시 결제창에서 리턴 --> */}
          {/* <!-- <input type='hidden' name='card_mask_no'			  value=''>    --> */}
        </div>
      </form>
    </div>
  );
};

export default SubscribePayment;
