import { useEffect, useState } from "react";
import styled from "styled-components";
import { TABLET_LANDSCAPE } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getDate } from "../../../utils/date";
import CardLayout from "../CardLayout";
import Heading1 from "../Heading1";

const Container = styled.div`
  margin-left: var(--sidebar-width);
  padding: 2.25rem 2.5rem;
  width: 100%;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    margin-left: var(--sidebar-tablet-landscape-width);
  }
`;

const Tabs = styled.div`
  display: flex;
  gap: 1.25rem;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  line-height: 140%;
`;
const Tab = styled.span`
  cursor: pointer;
  ${({ theme, clicked }) =>
    clicked && `color: ${theme.grayScaleColor.black}; font-weight: 600;`}
`;

const ListContainer = styled.ul`
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const ColumnHeader = styled.li`
  display: flex;
  justify-content: space-evenly;
  font-weight: 600;
  padding: 1rem 0;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  border-top: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding-left: 2.31rem;
`;

const ColumnItem = styled.div``;

const Row = styled.li`
  display: flex;
  justify-content: space-evenly;
  font-weight: 400;
  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding-left: 2.31rem;
`;
const RowItem = styled.div``;

const GroupsManagement = () => {
  const ALL = "모든 그룹";
  const {
    auth: { user },
  } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [users, setUsers] = useState([]);
  const [name, setName] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabClick = (e, index) => {
    setTabIndex(index);
    if (index === 0) {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(
        users.filter((item) => item.department === departments[index].name)
      );
    }
  };

  const handleGroupAddClick = async () => {
    if (name.length <= 0) {
      return;
    }
    const response = await axiosPrivate.post("/department", {
      name,
      companyId: user.companyId._id,
    });
    console.log(response.data);
    setName("");
    if (!response.data.ok) {
      return;
    }
    setDepartments([...departments, ...response.data.data]);
  };
  const handleOnNameChange = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await axiosPrivate.get(`/department`);
      console.log(response.data);
      //   setUsers(response.data.users);
      //   setFilteredUsers(response.data.users);
      setDepartments([{ name: ALL }, ...response.data.data]);
    };
    fetch();
  }, []);
  return (
    <Container>
      <Heading1 style={{ marginBottom: "1.75rem" }}>그룹 관리</Heading1>
      <CardLayout>
        <Tabs>
          {departments.map((item, index) => (
            <Tab
              key={index}
              onClick={(e) => handleTabClick(e, index)}
              clicked={index === tabIndex}
            >
              {item.name} ({item.count})
            </Tab>
          ))}
        </Tabs>
        <input onChange={handleOnNameChange} value={name} />
        <button onClick={handleGroupAddClick}>그룹 추가</button>
        {/* <button onClick={handleGroupAddClick}>그룹 삭제</button> */}
        <ListContainer>
          <ColumnHeader>
            <ColumnItem style={{ flex: 1 }}>이름</ColumnItem>

            <ColumnItem style={{ flex: 1 }}>마음 체크업 횟수</ColumnItem>
            <ColumnItem style={{ flex: 1 }}>마음 트레이닝 횟수</ColumnItem>
            <ColumnItem style={{ flex: 1 }}>생성일</ColumnItem>
          </ColumnHeader>
          {filteredUsers.map((item, index) => {
            return (
              <Row key={index}>
                <RowItem style={{ flex: 1 }}>{item.name}</RowItem>
                <RowItem style={{ flex: 1 }}>{item.checkupCount}</RowItem>
                <RowItem style={{ flex: 1 }}>{item.trainingCount}</RowItem>
                <RowItem style={{ flex: 1 }}>
                  {getDate(new Date(item.createdAt))}
                </RowItem>
              </Row>
            );
          })}
        </ListContainer>
      </CardLayout>
    </Container>
  );
};

export default GroupsManagement;
