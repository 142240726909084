import styled from "styled-components";

const Text = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  font-size: 0.875rem;
  line-height: 150%;
`;

const Heading2 = ({ style, children }) => {
  return <Text style={style}>{children}</Text>;
};

export default Heading2;
