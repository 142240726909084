import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 5.38rem;
  height: 14rem;
  border-radius: 0.63rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  z-index: 1;
  position: absolute;
  top: 1rem;
  left: 10.44rem;
  overflow-y: scroll;
`;

const Item = styled.div`
  width: 100%;
  height: 1.81rem;
  font-size: 0.75rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.black};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, selected }) => selected && theme.primary.light};
  &:hover {
    background: ${({ theme }) => theme.primary.light};
  }
  cursor: pointer;
`;

const WorkExperienceDropdown = ({
  style,
  data,
  setData,
  setFocusAt,
  setClick,
}) => {
  const workExperiences = [];
  for (let i = 1; i < 30; i++) {
    workExperiences.push(i);
  }
  workExperiences.push("30년 이상");
  const [open, setOpen] = useState(true);
  const selectRef = useRef();
  const handleClick = (value) => {
    setData(value);
    setOpen(false);
    setFocusAt(0);
    setClick(false);
    // selectRef.current.style = "display: none";
  };

  useEffect(() => {}, []);

  if (!open) {
    return null;
  }
  return (
    <Container ref={selectRef} style={style}>
      {workExperiences.map((item) => (
        <Item
          key={item}
          selected={item === data}
          value={item}
          onClick={() => {
            handleClick(item);
          }}
        >
          {item}
        </Item>
      ))}
    </Container>
  );
};

export default WorkExperienceDropdown;
