import { useState } from "react";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import { MONTH_PRICE, YEAR_PRICE } from "../../../constants/payments";
import useAuth from "../../../hooks/useAuth";
import Badge from "../components/Badge";
import CloseXButton from "../components/CloseXButton";
import ModalHeading2 from "../components/ModalHeading2";
import ChangeToMonthPlanModal from "./ChangeToMonthPlanModal";
import ChangeToYearPlanModal from "./ChangeToYearPlanModal";
import MonthPlanInfoModal from "./MonthPlanInfoModal";
import YearPlanInfoModal from "./YearPlanInfoModal";

const Container = styled.div`
  // width: 63rem;
`;

const PlanBox = styled.div`
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  padding: 2rem;
  width: 100%;
`;

const PlanText1 = styled.p`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 150%; /* 1.875rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const PlanText2 = styled(PlanText1)`
  font-size: 1.5rem;
`;

const DetailText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
`;

const DetailContainer = styled.ul`
  padding-left: 1em;
  display: flex;
  flex-direction: column;
`;

const DetailText2 = styled(DetailText).attrs({ as: "li" })`
  font-size: 0.9375rem;
  list-style: disc;
`;
const Button = styled.button`
  border-radius: 0.3125rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  background: ${({ theme }) => theme.grayScaleColor.gray70};
  color: ${({ theme }) => theme.grayScaleColor.white};
  cursor: pointer;
  border: none;
  margin-top: 1.25rem;
`;

const ActiveButton = styled(Button)`
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
`;

const SelectPlanModal = ({ confirmHandler, closeHandler }) => {
  const { auth } = useAuth();
  const userCount = auth.user.companyId.numUsers;
  const [isMonthSelected, setIsMonthSelected] = useState(false);
  const [isYearSelected, setIsYearSelected] = useState(false);

  const onMonthClicked = () => {
    // closeHandler();
    // confirmHandler();
    setIsMonthSelected(true);
  };

  const onYearClicked = () => {
    // closeHandler();
    // confirmHandler();
    setIsYearSelected(true);
  };
  if (isMonthSelected) {
    return (
      <MonthPlanInfoModal
        userCount={userCount}
        closeHandler={() => setIsMonthSelected(false)}
      />
    );
  }

  if (isYearSelected) {
    return (
      <YearPlanInfoModal
        userCount={userCount}
        closeHandler={() => setIsYearSelected(false)}
      />
    );
  }

  return (
    <Container>
      <FlexRow
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <ModalHeading2 text="맘핏 플랜 선택" />
        {/* <CloseXButton onClick={closeHandler} /> */}
      </FlexRow>
      <FlexRow style={{ gap: "1.75rem", marginTop: "2rem" }}>
        <PlanBox>
          <PlanText1>월 구독</PlanText1>
          <FlexRow
            style={{ marginTop: "0.5rem", alignItems: "center", gap: "0.5rem" }}
          >
            <PlanText2>1인 {MONTH_PRICE.toLocaleString()}원</PlanText2>
            <DetailText>(VAT 별도)</DetailText>
          </FlexRow>
          <Button onClick={onMonthClicked}>월 구독 선택</Button>
        </PlanBox>
        <PlanBox>
          <FlexRow style={{ gap: "0.5rem", alignItems: "center" }}>
            <PlanText1>연 구독</PlanText1>
            <Badge>25% 할인</Badge>
          </FlexRow>
          <FlexRow
            style={{ marginTop: "0.5rem", alignItems: "center", gap: "0.5rem" }}
          >
            <PlanText2>1인 {YEAR_PRICE.toLocaleString()}원</PlanText2>
            <DetailText>(VAT 별도)</DetailText>
          </FlexRow>
          <ActiveButton onClick={onYearClicked}>연 구독 선택</ActiveButton>
        </PlanBox>
      </FlexRow>
      <DetailContainer style={{ marginTop: "2.75rem", gap: "0.25rem" }}>
        <DetailText2>
          결제 주기 변경 시 다음 달 결제일부터 바로 적용됩니다.
        </DetailText2>
        <DetailText2>
          연 구독의 경우 1년간 매월 할인된 금액을 인원으로 계산하여 결제됩니다.
          (중도에 인원, 구독방식 변경이 자유롭습니다)
        </DetailText2>
        <DetailText2>
          연 구독 중 구독 기간 1년 기간을 채우지 못하고 서비스를 중도 해지할
          경우 다음 요금 납부일에 월별 할인 적용된 금액이 합산하여 청구됩니다.
        </DetailText2>
        <DetailText2>
          이용자 1인당 구독료를 책정하며, 과금 시점의 ‘활성 이용자 수’를
          기준으로 월 이용료를 과금합니다.
        </DetailText2>
        <DetailText2>
          활성 이용자 수는 관리자가 [이용자 관리]에 등록한 맘핏 이용 대상자
          전체를 뜻하며, 비활성화 처리하여 맘핏 서비스 이용이 중단된 이용자의
          경우 과금 대상이 아닙니다.{" "}
        </DetailText2>
        <DetailText2>
          무료 기간 중 결제 카드를 등록하여도 무료 이용 동안은 결제되지
          않습니다.
        </DetailText2>
      </DetailContainer>
    </Container>
  );
};

export default SelectPlanModal;
