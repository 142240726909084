import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
from { opacity: 0; }
to { opacity: 0.9; }
`;

// const fadeOut = keyframes`
// from { opacity: 0.9; }
// to { opacity: 0; }
// `;

const Container = styled.div`
  background: ${({ theme }) => theme.grayScaleColor.black};
  color: ${({ theme }) => theme.grayScaleColor.white};
  opacity: 0.9;
  border-radius: 0.4375rem;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
  position: absolute;
  animation: ${fadeIn} 0.3s;
`;

const ReportTooltip = ({ children, style, hover }) => {
  return (
    <Container style={style} hover={hover}>
      {children}
    </Container>
  );
};

export default ReportTooltip;
