import styled, { keyframes } from "styled-components";
import FlexColumn from "../../components/FlexColumn";
import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useMediaQuery } from "react-responsive";
import { MOBILE } from "../../Constants";
import FlexRow from "../../components/FlexRow";
import convertPermavac from "../../utils/convertPermavac";
import { useLocation, useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 0 1.88rem;
`;

const Text1 = styled.h1`
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  margin-top: 5.31rem;
  & span {
    color: ${({ theme }) => theme.primary.normal};
  }
  @media (max-width: ${MOBILE}) {
    margin-top: 2.25rem;
  }
`;

const fill = keyframes`
    0% {
      width: 0%;
    }
    
    100% {
      width: 100%;
    }
`;

const AnimatedText = styled.p`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  position: relative;
  margin-top: 0.38rem;
  color: ${({ theme }) => theme.grayScaleColor.gray20};
  &:before {
    content: attr(data-hover);
  }
  &:after {
    content: attr(data-hover);
    position: absolute;
    color: ${({ theme }) => theme.primary.normal};
    left: 0;
    width: 0;
    overflow: hidden;
    white-space: nowrap;
    animation: ${fill} 2s linear infinite;
  }
`;

const Text2 = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  margin-bottom: 0.75rem;
`;
const Text3 = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.21875rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.primary.normal};
`;
const Text4 = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const BlockContainer = styled.div`
  border-radius: 0.9375rem;
  padding: 1.6rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 19.6875rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  @media (max-width: ${MOBILE}) {
    max-width: 100%;
  }
`;

const BlockImage = styled.img`
  width: 5.33556rem;
  height: 4.50944rem;
`;

const Button = styled.button`
  border-radius: 3.125rem;
  display: flex;
  gap: 0.25rem;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 160% */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  padding: 0.75rem 1.5rem;
  background: none;
  margin: 0 auto;
  margin-top: 2.5rem;
  cursor: pointer;
  margin-bottom: 2.5rem;
`;
const BlockCard = ({ block }) => {
  const navigate = useNavigate();
  const onBlockClick = () => {
    navigate(`/blocks/${block.number}`);
  };
  return (
    <BlockContainer onClick={onBlockClick} style={{ width: "100%" }}>
      <FlexColumn>
        <Text3>{convertPermavac(block.permavac[0])}</Text3>
        <Text2>{block.title}</Text2>
        <FlexRow style={{ alignItems: "center", gap: "0.25rem" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M11.6048 12.3952L12.3952 11.6048L9.56246 8.77193V5.24998H8.4375V9.22787L11.6048 12.3952ZM9.00124 16.125C8.01579 16.125 7.08951 15.938 6.22241 15.564C5.3553 15.19 4.60104 14.6824 3.95963 14.0413C3.3182 13.4001 2.81041 12.6462 2.43624 11.7795C2.06208 10.9128 1.875 9.98669 1.875 9.00124C1.875 8.01579 2.062 7.08951 2.436 6.22241C2.81 5.3553 3.31756 4.60104 3.95869 3.95963C4.59983 3.3182 5.35376 2.81041 6.22048 2.43624C7.08719 2.06208 8.01328 1.875 8.99873 1.875C9.98418 1.875 10.9105 2.062 11.7776 2.436C12.6447 2.81 13.3989 3.31756 14.0403 3.95869C14.6818 4.59983 15.1896 5.35376 15.5637 6.22048C15.9379 7.08719 16.125 8.01328 16.125 8.99873C16.125 9.98418 15.938 10.9105 15.564 11.7776C15.19 12.6447 14.6824 13.3989 14.0413 14.0403C13.4001 14.6818 12.6462 15.1896 11.7795 15.5637C10.9128 15.9379 9.98669 16.125 9.00124 16.125ZM8.99998 15C10.6625 15 12.0781 14.4156 13.2469 13.2469C14.4156 12.0781 15 10.6625 15 8.99998C15 7.33748 14.4156 5.92186 13.2469 4.75311C12.0781 3.58436 10.6625 2.99998 8.99998 2.99998C7.33748 2.99998 5.92186 3.58436 4.75311 4.75311C3.58436 5.92186 2.99998 7.33748 2.99998 8.99998C2.99998 10.6625 3.58436 12.0781 4.75311 13.2469C5.92186 14.4156 7.33748 15 8.99998 15Z"
              fill="#7C8585"
            />
            <path
              d="M11.6048 12.3952L12.3952 11.6048L9.56246 8.77193V5.24998H8.4375V9.22787L11.6048 12.3952ZM9.00124 16.125C8.01579 16.125 7.08951 15.938 6.22241 15.564C5.3553 15.19 4.60104 14.6824 3.95963 14.0413C3.3182 13.4001 2.81041 12.6462 2.43624 11.7795C2.06208 10.9128 1.875 9.98669 1.875 9.00124C1.875 8.01579 2.062 7.08951 2.436 6.22241C2.81 5.3553 3.31756 4.60104 3.95869 3.95963C4.59983 3.3182 5.35376 2.81041 6.22048 2.43624C7.08719 2.06208 8.01328 1.875 8.99873 1.875C9.98418 1.875 10.9105 2.062 11.7776 2.436C12.6447 2.81 13.3989 3.31756 14.0403 3.95869C14.6818 4.59983 15.1896 5.35376 15.5637 6.22048C15.9379 7.08719 16.125 8.01328 16.125 8.99873C16.125 9.98418 15.938 10.9105 15.564 11.7776C15.19 12.6447 14.6824 13.3989 14.0413 14.0403C13.4001 14.6818 12.6462 15.1896 11.7795 15.5637C10.9128 15.9379 9.98669 16.125 9.00124 16.125ZM8.99998 15C10.6625 15 12.0781 14.4156 13.2469 13.2469C14.4156 12.0781 15 10.6625 15 8.99998C15 7.33748 14.4156 5.92186 13.2469 4.75311C12.0781 3.58436 10.6625 2.99998 8.99998 2.99998C7.33748 2.99998 5.92186 3.58436 4.75311 4.75311C3.58436 5.92186 2.99998 7.33748 2.99998 8.99998C2.99998 10.6625 3.58436 12.0781 4.75311 13.2469C5.92186 14.4156 7.33748 15 8.99998 15Z"
              fill="black"
              fillOpacity="0.2"
            />
            <path
              d="M11.6048 12.3952L12.3952 11.6048L9.56246 8.77193V5.24998H8.4375V9.22787L11.6048 12.3952ZM9.00124 16.125C8.01579 16.125 7.08951 15.938 6.22241 15.564C5.3553 15.19 4.60104 14.6824 3.95963 14.0413C3.3182 13.4001 2.81041 12.6462 2.43624 11.7795C2.06208 10.9128 1.875 9.98669 1.875 9.00124C1.875 8.01579 2.062 7.08951 2.436 6.22241C2.81 5.3553 3.31756 4.60104 3.95869 3.95963C4.59983 3.3182 5.35376 2.81041 6.22048 2.43624C7.08719 2.06208 8.01328 1.875 8.99873 1.875C9.98418 1.875 10.9105 2.062 11.7776 2.436C12.6447 2.81 13.3989 3.31756 14.0403 3.95869C14.6818 4.59983 15.1896 5.35376 15.5637 6.22048C15.9379 7.08719 16.125 8.01328 16.125 8.99873C16.125 9.98418 15.938 10.9105 15.564 11.7776C15.19 12.6447 14.6824 13.3989 14.0413 14.0403C13.4001 14.6818 12.6462 15.1896 11.7795 15.5637C10.9128 15.9379 9.98669 16.125 9.00124 16.125ZM8.99998 15C10.6625 15 12.0781 14.4156 13.2469 13.2469C14.4156 12.0781 15 10.6625 15 8.99998C15 7.33748 14.4156 5.92186 13.2469 4.75311C12.0781 3.58436 10.6625 2.99998 8.99998 2.99998C7.33748 2.99998 5.92186 3.58436 4.75311 4.75311C3.58436 5.92186 2.99998 7.33748 2.99998 8.99998C2.99998 10.6625 3.58436 12.0781 4.75311 13.2469C5.92186 14.4156 7.33748 15 8.99998 15Z"
              fill="black"
              fillOpacity="0.2"
            />
          </svg>
          <Text4>{block.time}분</Text4>
        </FlexRow>
      </FlexColumn>
      <BlockImage src={block.iconUrl} alt="block img" />
    </BlockContainer>
  );
};

const MoreTraining = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [blocks, setBlocks] = useState([]);

  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE})`,
  });

  const getRecommendBlocks = async () => {
    setIsLoading(true);
    const response = await axiosPrivate.get(
      `/block/recommend?permavac=${location.state?.type[0]}`
    );
    if (response.data.ok) {
      setBlocks(response.data.data.blocks);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, [1500]);
  };

  const onRetryClick = async () => {
    getRecommendBlocks();
  };

  useEffect(() => {
    getRecommendBlocks();
  }, []);
  if (isLoading) {
    return (
      <Container>
        <FlexColumn style={{ alignItems: "center" }}>
          <Text1>
            {auth?.user.name} 님에게 꼭 맞는 <br />
            마음 트레이닝을 찾고 있어요 👀
          </Text1>
          <img
            style={{ width: "8.25rem", height: "8.25rem", marginTop: "3rem" }}
            src={`${process.env.PUBLIC_URL}/assets/ai_bounce.gif`}
            alt="logo"
          />
          <AnimatedText data-hover={"LOADING ···"}></AnimatedText>
        </FlexColumn>
      </Container>
    );
  }
  return (
    <Container>
      <FlexColumn style={{ alignItems: "center", marginBottom: "2.5rem" }}>
        <Text1>
          <span>{blocks.length}개</span>의 마음 트레이닝을
          <br />
          추천 드려요
        </Text1>
      </FlexColumn>
      <FlexColumn style={{ alignItems: "center" }}>
        <div
          style={{
            display: "grid",
            gap: "0.75rem",
            gridTemplateColumns: isMobile
              ? "repeat(1, 1fr)"
              : "repeat(2, 19.6875rem)",
            alignItems: "center",
            width: isMobile && "100%",
          }}
        >
          {blocks.slice(0, 4).map((block) => (
            <BlockCard key={block._id} block={block} />
          ))}
        </div>
      </FlexColumn>
      <Button onClick={onRetryClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M12.539 19.5C10.4453 19.5 8.67185 18.7735 7.21874 17.3207C5.76562 15.8679 5.03906 14.0948 5.03906 12.0015C5.03906 9.90818 5.76562 8.13461 7.21874 6.68078C8.67185 5.22693 10.4453 4.5 12.539 4.5C13.7083 4.5 14.8147 4.75994 15.8583 5.27982C16.9018 5.79969 17.7698 6.53335 18.4621 7.4808V4.5H19.9621V10.6153H13.8467V9.11538H17.7967C17.2698 8.14999 16.539 7.38941 15.6044 6.83363C14.6698 6.27786 13.648 5.99998 12.539 5.99998C10.8724 5.99998 9.4557 6.58331 8.28904 7.74998C7.12237 8.91664 6.53904 10.3333 6.53904 12C6.53904 13.6666 7.12237 15.0833 8.28904 16.25C9.4557 17.4166 10.8724 18 12.539 18C13.8224 18 14.9807 17.6333 16.014 16.9C17.0474 16.1666 17.7724 15.2 18.189 14H19.7698C19.3159 15.632 18.422 16.9567 17.088 17.974C15.7541 18.9913 14.2377 19.5 12.539 19.5Z"
            fill="#BBBBBB"
          />
        </svg>
        <span>다시 추천받기</span>
      </Button>
    </Container>
  );
};

export default MoreTraining;
