import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { createWorker } from "tesseract.js";
import Footer from "./Footer";

const Container = styled.div`
  //   border: 1px solid black;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #fcfcfc;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 50px;
  width: 100%;
  height: 100%;
  max-width: 425px;
  padding: 30px;
  border: 1px solid #fcfcfc;
`;

const TopBar = styled.div`
  width: 100%;
  height: 50px;
  background: #ffc842;
  display: flex;
  align-items: center;
`;

const Logo = styled.img``;

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fcfcfc;

  height: 100%;
  position: relative;
`;

const SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  margin-top: 12px;
`;

const SubTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #ffc842;
  font-family: "Montserrat";
  // text-align: center;
`;
const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
`;

const StepBarContainer = styled.div`
  width: 52px;
  height: 8px;
  background-color: #eee;
  margin-left: auto;
  margin-right: 0;
  display: flex;
  border-radius: 50px;
`;
const StepBar = styled.div`
  width: 39px;
  height: 8px;
  background: #ffc842;
  // background-color: ${({ active }) => (active ? "#ffc842" : "#eee")};
  border-radius: 50px;
`;

const TitleContainer = styled.div`
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  // border: 1px solid black;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #010101;
`;

const BaseInput = styled.input`
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: solid 1px #d9d9d9;
  background-color: #fff;
`;

const Input = styled(BaseInput)`
  // margin-top: 47px;
  margin-bottom: 0.75rem;
  text-align: center;
  font-size: 18px;
  font-family: "Montserrat";
  border-color: ${({ enable }) => enable && "#B9D530"};
  &:focus {
    outline: none;
    border-color: #b9d530;
  }
  ::placeholder {
    font-size: 14px;
    color: #aaaaaa;
    text-align: center;
    padding-left: 0;
  }
`;

const NextButton = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 7px;
  border: solid 1px ${({ enable }) => (enable ? "#ffc842" : "#d9d9d9")};
  background-color: ${({ enable }) => (enable ? "#ffc842" : "#d9d9d9")};
  color: white;
  font-size: 18px;
  margin-top: 
  margin-bottom: 70px;
  cursor: ${({ enable }) => (enable ? "pointer" : "not-allowed")};
`;

function StepThree() {
  const { state } = useLocation();
  const navigate = useNavigate();
  // if (!state) {
  //   navigate("/itree/1");
  //   return;
  // }
  const [smallActions, setSmallActions] = useState(
    state?.smallActions || Array(3).fill("")
  );

  const inputRefs = useRef([]);

  // const [ocr, setOcr] = useState("");
  // const [imageData, setImageData] = useState(null);

  // const convertImageToText = async () => {
  //   const worker = await createWorker({
  //     logger: (m) => {
  //       console.log(m);
  //     },
  //   });
  //   if (!imageData) return;
  //   await worker.load();
  //   await worker.loadLanguage("kor");
  //   await worker.initialize("kor");
  //   const {
  //     data: { text },
  //   } = await worker.recognize(imageData);
  //   setOcr(text);
  // };

  // function handleImageChange(e) {
  //   const file = e.target.files[0];
  //   if (!file) return;
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     const imageDataUri = reader.result;
  //     console.log({ imageDataUri });
  //     setImageData(imageDataUri);
  //   };
  //   reader.readAsDataURL(file);
  // }

  // useEffect(() => {
  //   convertImageToText();
  // }, [imageData]);

  const handleInputChange = (e, index) => {
    setSmallActions(
      smallActions.map((item, idx) => (idx === index ? e.target.value : item))
    );
  };

  const handleSmallActionInputFocus = (index) => {
    // treeNameInputRef.current.placeholder = "";
    // console.log(inputRefs);
    inputRefs.current[index].placeholder = "";
  };

  const handleSmallActionInputBlur = (index, placeholder) => {
    inputRefs.current[index].placeholder = placeholder;
    // treeNameInputRef.current.placeholder = "ex) Blooming tree";
  };

  const handleNextButtonClick = async () => {
    // 입력한 값 DB에 저장
    const response = await axios.post(
      `${process.env.REACT_APP_SMART_TOOL_SERVER_BASE_URL}/itree`,
      {
        leafs: state.leafs,
        name: state.name,
        smallActions,
      }
    );

    // 결과 페이지 이동
    if (smallActions.filter((item) => item).length === 3) {
      navigate("/itree/result", {
        state: { leafs: state.leafs, name: state.name, smallActions },
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Helmet>
        <title>아이트리</title>
        <meta name="theme-color" content="#FCFCFC" />
      </Helmet>
      <Wrapper>
        {/* <TopBar>
          <Logo src={`${process.env.PUBLIC_URL}/assets/itree/itree_logo.png`} />
        </TopBar> */}
        <Screen>
          {/* <NavigationContainer>
            <StepBarContainer>
              {Array(3)
                .fill(0)
                .map((_, index) => (
                  <StepBar key={index} active={index < currentStep} />
                ))}
            </StepBarContainer>
          </NavigationContainer> */}
          <svg
            onClick={() =>
              navigate("/itree/2", {
                state: { leafs: state?.leafs, name: state.name, smallActions },
              })
            }
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" fill="#FCFCFC" />
            <path
              d="M4.29289 12.2929C3.90237 12.6834 3.90237 13.3166 4.29289 13.7071L10.6569 20.0711C11.0474 20.4616 11.6805 20.4616 12.0711 20.0711C12.4616 19.6805 12.4616 19.0474 12.0711 18.6569L6.41421 13L12.0711 7.34315C12.4616 6.95262 12.4616 6.31946 12.0711 5.92893C11.6805 5.53841 11.0474 5.53841 10.6569 5.92893L4.29289 12.2929ZM23 14C23.5523 14 24 13.5523 24 13C24 12.4477 23.5523 12 23 12V14ZM5 14H23V12H5V14Z"
              fill="black"
            />
          </svg>
          <SubTitleContainer>
            <SubTitle>STEP 3</SubTitle>
            <StepBarContainer>
              <StepBar />
            </StepBarContainer>
          </SubTitleContainer>
          <TitleContainer>
            <Title>스몰액션 3개를 적어주세요 💪</Title>
          </TitleContainer>

          {Array(3)
            .fill()
            .map((_, index) => {
              return (
                <Input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  value={smallActions[index]}
                  enable={smallActions[index].length > 0}
                  placeholder={`Small Action ${index + 1}`}
                  onChange={(e) => handleInputChange(e, index)}
                  onFocus={() => handleSmallActionInputFocus(index)}
                  onBlur={() =>
                    handleSmallActionInputBlur(
                      index,
                      `Small Action ${index + 1}`
                    )
                  }
                />
              );
            })}
          {/* <div>
            <p>Choose an Image</p>
            <input
              type="file"
              name=""
              id=""
              onChange={handleImageChange}
              accept="image/*"
            />
          </div>
          <div className="display-flex">
            <img src={imageData} alt="" srcset="" />
            <p>{ocr}</p>
          </div> */}
          <NextButton
            enable={smallActions.filter((item) => item).length === 3}
            onClick={handleNextButtonClick}
          >
            스몰액션 작성하기
          </NextButton>
          <Footer />
        </Screen>
      </Wrapper>
    </Container>
  );
}

export default StepThree;
