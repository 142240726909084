import styled from "styled-components";

const SButton = styled.button`
  width: 100%;
  padding: 1.06rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, state }) =>
    state === "enabled" ? theme.primary.normal : theme.grayScaleColor.white};
  border: 1px solid
    ${({ theme, state }) =>
      state === "enabled" ? theme.primary.normal : theme.grayScaleColor.gray30};
  color: ${({ theme, state }) =>
    state === "enabled"
      ? theme.grayScaleColor.white
      : theme.grayScaleColor.gray70};
  border-radius: 0.3125rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.625rem; /* 144.444% */
  letter-spacing: -0.045rem;
`;

const Button = ({ state = "enabled", text, onClick, style }) => {
  return (
    <SButton style={style} state={state} onClick={onClick}>
      {text}
    </SButton>
  );
};

export default Button;
