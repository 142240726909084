import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { TYPE_MEMBER } from "../../Constants";
import useAuth from "../../hooks/useAuth";
import { isAuthEmpty } from "../../utils/isAuthEmpty";
import TopBar from "../TopBar";
import FlexRow from "../FlexRow";
import { useState } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  width: 100%;
  // height: 100%;
  height: cal(100%);
  // border: 1px solid black;
`;

const Logo = styled.img`
  width: 6.25rem;
`;

const TapbarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
  /* padding-bottom: 0.5rem; */
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const Tap = styled.div`
  flex: 1;
  text-align: center;
  margin: 0 0.75rem;
  ${({ theme, selected }) =>
    selected && `border-bottom: 0.125rem solid ${theme.primary.normal}`};
  padding-bottom: 0.5rem;
  color: ${({ theme, selected }) => selected && theme.primary.normal};
  font-weight: ${({ theme, selected }) => selected && 600};
`;

const MobileLayout = ({ type = TYPE_MEMBER }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const pathname = window.location.pathname;
  const onTabClick = (e, index) => {
    setTabIndex(index);
  };

  const onLogoClick = () => {
    navigate("/");
  };
  return (
    <Container>
      <FlexRow
        style={{
          justifyContent: "space-between",
          padding: "1rem 1.25rem",
        }}
      >
        <Logo
          onClick={onLogoClick}
          src={`${process.env.PUBLIC_URL}/assets/logo/maumfit_logo.png`}
          alt="logo"
        />
      </FlexRow>
      <TapbarContainer>
        <Tap
          selected={!pathname.includes("/mypage")}
          onClick={(e) => navigate("/")}
        >
          홈
        </Tap>
        <Tap
          selected={pathname.includes("/mypage")}
          onClick={() => navigate("/mypage")}
        >
          마이페이지
        </Tap>
      </TapbarContainer>
      <Outlet context={tabIndex} />
    </Container>
  );
};

export default MobileLayout;
