import styled from "styled-components";

const Container = styled.div`
  padding: 1.69rem 2rem;
  border-radius: 1.25rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  /* border: 1px solid black; */
`;

const CardLayout = ({ children, style }) => {
  return <Container style={style}>{children}</Container>;
};

export default CardLayout;
