import { useEffect, useState } from "react";
import styled from "styled-components";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import ReportTab from "../surveys/checkup@2.0/components/ReportTab";
import CardLayout from "./CardLayout";
import { Chart } from "./Chart";

const ImageContainer = styled.div`
  border-radius: 0.4375rem;
  background: ${({ theme }) => theme.primary.light};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
`;

const Heading1 = styled.p`
  font-size: 1rem;
  font-weight: 500;
  line-height: 140%;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;
const Heading2 = styled.p`
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-bottom: 0.25rem;
`;

const Heading3 = styled.p`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 500;
  line-height: 140%;
  align-self: flex-start;
  margin-bottom: 1.5rem;
`;

const TabButton = styled.div`
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  border-radius: 0.4375rem;
  color: ${({ theme, clicked }) =>
    clicked ? theme.grayScaleColor.white : theme.grayScaleColor.gray20};
  font-weight: 500;
  line-height: 150%;
  font-size: 1rem;
  background: ${({ theme, clicked }) =>
    clicked ? theme.primary.normal : theme.grayScaleColor.white};
  border: 1px solid
    ${({ theme, clicked }) =>
      clicked ? theme.primary.normal : theme.grayScaleColor.gray20};
  display: flex;
  align-items: center;
`;

const Card4 = ({
  types,
  tabs1,
  tabs2,
  data1,
  data2,
  style,
  chartType,
  setChartType,
}) => {
  const [clickIndex, setClickIndex] = useState(0);
  const [result, setResult] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [dates, setDates] = useState([]);
  const [minMax, setMinMax] = useState([0, 100]);
  const [step, setStep] = useState(20);

  const handleChartTypeClick = (e, type) => {
    setChartType(type);
  };

  useEffect(() => {
    if (chartType === types[0]) {
      setDates([...data1.map((r) => r.date)]);
      setTabs(tabs1);
      if (clickIndex > 0) {
        setMinMax([0, 7]);
        setStep(1);
        setResult(data1.map((item) => item.permavacScore[clickIndex - 1][1]));
      } else {
        setMinMax([0, 100]);
        setStep(20);
        setResult(data1.map((item) => item.wellBeingScore));
      }
    } else {
      if (clickIndex === 9) {
        setClickIndex(0);
      }
      setDates([...data2.map((r) => r.date)]);
      setResult(data2.map((item) => item.score[clickIndex % 9].score));
      setTabs(tabs2);
      setMinMax([0, 100]);
      setStep(20);
    }
  }, [chartType, clickIndex]);

  return (
    <CardLayout style={style}>
      <FlexRow style={{ justifyContent: "space-between" }}>
        <Heading3>최근 마음 웰빙 그래프</Heading3>
        <FlexRow style={{ gap: "0.75rem" }}>
          {types.map((item) => (
            <TabButton
              clicked={chartType === item}
              onClick={(e) => handleChartTypeClick(e, item)}
            >
              {item}
            </TabButton>
          ))}
        </FlexRow>
      </FlexRow>
      {/* {chartType === "permavac" && ( */}
      <ReportTab
        tabs={tabs.map((item) => item.type)}
        setClickIndex={setClickIndex}
        clickIndex={clickIndex}
      />
      {/* )} */}

      <FlexColumn>
        <Chart
          style={{ width: "100%", height: "25rem" }}
          result={result}
          dates={dates}
          min={minMax[0]}
          max={minMax[1]}
          step={step}
        />
      </FlexColumn>
    </CardLayout>
  );
};

export default Card4;
