import styled from "styled-components";
import { MOBILE } from "../../../../Constants";

const Text = styled.div`
  display: flex;
  /* width: 14rem; */
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  font-size: 1.25rem;
  line-height: 160%;
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 600;
  @media (max-width: ${MOBILE}) {
    font-size: 0.9375rem;

    font-weight: 600;
  }
`;

const ReportHeading2 = ({ children, style }) => {
  return <Text style={style}>{children}</Text>;
};

export default ReportHeading2;
