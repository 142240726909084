import styled from "styled-components";

const Container = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const TR = styled.tr`
  height: 3.5rem;
`;

const TH = styled.th`
  vertical-align: middle;
  text-align: center;
`;
const THead = styled.thead`
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-weight: 600;
  ${TR} {
    border: none;
  }
`;
const TBody = styled.tbody`
  ${TR} {
    border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  }
`;
const TD = styled.td`
  vertical-align: middle;
  text-align: center;
  color: ${({ color }) => color};
  &:first-child {
    font-weight: 500;
  }
`;

const Table = ({ results }) => {
  return (
    <Container>
      <THead>
        <TR>
          <TH></TH>
          <TH>Q1</TH>
          <TH>Q2</TH>
          <TH>Q3</TH>
          <TH>Q4</TH>
        </TR>
      </THead>
      <TBody>
        <TR>
          <TD>1차 체크업</TD>
          {results.map((item, index) => (
            <TD>{item[0].score}</TD>
          ))}
        </TR>
        <TR>
          <TD>2차 체크업</TD>
          {results.map((item, index) => (
            <TD>{item[1].score}</TD>
          ))}
        </TR>
        <TR>
          <TD>3차 체크업</TD>
          {results.map((item, index) => (
            <TD>{item[2].score}</TD>
          ))}
        </TR>
        <TR>
          <TD>워크숍 후 변화</TD>
          {results.map((item, index) => (
            <TD
              color={item[1].score - item[0].score >= 0 ? "#00DBB1" : "#E05E00"}
            >
              {item[1].score - item[0].score >= 0
                ? `+${item[1].score - item[0].score}`
                : `${item[1].score - item[0].score}`}
            </TD>
          ))}
        </TR>
        <TR>
          <TD>워크루틴 후 변화</TD>
          {results.map((item, index) => (
            <TD
              color={item[2].score - item[0].score >= 0 ? "#00DBB1" : "#E05E00"}
            >
              {item[2].score - item[0].score >= 0
                ? `+${item[2].score - item[0].score}`
                : `${item[2].score - item[0].score}`}
            </TD>
          ))}
        </TR>
      </TBody>
    </Container>
  );
};

export default Table;
