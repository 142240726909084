import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { MOBILE } from "../../../../Constants";

const Button = styled.button`
  width: 6.69rem;
  height: 3.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, isClicked }) =>
    isClicked ? theme.primary.light : "#F3F3F3"};
  border-radius: 0.44rem;
  border: 1px solid
    ${({ theme, isClicked }) => (isClicked ? theme.primary.normal : "#F3F3F3")};
  color: ${({ theme, isClicked }) =>
    isClicked ? theme.primary.normal : "#888"};
  &:hover {
    color: ${({ theme }) => theme.primary.normal};
    background: ${({ theme }) => theme.primary.light};
    border: 1px solid ${({ theme }) => theme.primary.normal};
  }
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
  @media (max-width: 425px) {
    width: 5.38rem;
    height: 3.13rem;
    font-size: 1.13rem;
  }
  @media (max-width: 375px) {
    width: 5.13rem;
    height: 3.13rem;
    font-size: 1.13rem;
  }
`;

const ScaleButton = ({
  children,
  questionIndex,
  scaleChoices,
  setScaleChoices,
  onHover,
  onMouseLeave,
  setNum,
}) => {
  const isMobile = useMediaQuery({ maxWidth: MOBILE });
  const [isClicked, setIsClicked] = useState(false);
  const handleClick = (value) => {
    if (scaleChoices[questionIndex] === value) {
      setScaleChoices((cur) => ({ ...cur, [questionIndex]: value }));
    } else {
      setScaleChoices((cur) => ({ ...cur, [questionIndex]: value }));
      setIsClicked((cur) => !cur);
    }
    setNum((prev) => {
      let n;
      switch (value) {
        case 1:
          n = 1;
          break;
        case 2:
          n = 1;
          break;
        case 3:
          n = 2;
          break;
        case 4:
          n = 3;
          break;
        case 5:
          n = 3;
          break;
        case 6:
          n = 4;
          break;
        case 7:
          n = 5;
          break;
        default:
          n = 0;
          break;
      }
      return n;
    });

    // 질문에 답변 안 한 문항으로 스크롤
    let scrollAmount = 1;

    for (let i = 1; i <= 9; i++) {
      if (
        i !== questionIndex &&
        !Object.keys(scaleChoices).includes(i.toString())
      ) {
        scrollAmount = i - questionIndex;
        break;
      }
    }

    if (!scaleChoices[questionIndex + 1]) {
    }

    window.scrollTo({
      // top: isMobile ? window.scrollY + 360 : window.scrollY + 390,
      top: isMobile
        ? window.scrollY + 360 * scrollAmount
        : window.scrollY + 390 * scrollAmount,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (scaleChoices[questionIndex] !== children) {
      setIsClicked(false);
    }
  }, [scaleChoices]);

  return (
    <Button
      onMouseEnter={onHover}
      onMouseLeave={onMouseLeave}
      isClicked={isClicked}
      onClick={() => handleClick(children)}
    >
      {children}
    </Button>
  );
};

export default ScaleButton;
