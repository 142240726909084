import { useEffect, useState } from "react";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import { PAYMENT_TYPE } from "../../../constants/payments";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getDate } from "../../../utils/date";
import CardLayout from "../CardLayout";
import Divider from "../components/Divider";
import Heading1 from "../components/Heading1";
import Heading2 from "../components/Heading2";
import Modal from "../components/Modal";
import Text1 from "../components/Text1";
import TextLink from "../components/TextLink";
import PaymentHistoryDetailModal from "../modals/PaymentHistoryDetailModal";

const Container = styled.div`
  padding: 2.75rem 2.75rem;
  padding-left: calc(2.75rem + var(--sidebar-width));
  width: 100%;
  position: relative;
`;

const KeyText = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  flex: 1;
  //   border: 1px solid black;
`;

const ValueText = styled(KeyText)`
  flex: 5;
`;

const SmallText = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 150%; /* 1.125rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  margin-left: 0.25rem;
`;

const Ul = styled.ul``;

const Li = styled.li`
  display: flex;
  align-items: center;
  padding: 0.94rem 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
`;

const HeaderItem = styled.p`
  flex: 1;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Item = styled.p`
  flex: 1;
  font-size: 0.9375rem;
  line-height: 150%; /* 1.40625rem */
  letter-spacing: -0.03125rem;
`;

const ManagerPaymentHistory = () => {
  const axiosPrivate = useAxiosPrivate();
  const [showDetailHistory, setShowDetailHistory] = useState(false);
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOrderDetail, setSelectedOrderDetail] = useState();

  const onOrderDetailClick = (e, order) => {
    setSelectedOrderDetail(order);
    setShowDetailHistory(true);
  };

  useEffect(() => {
    (async () => {
      const res = await axiosPrivate.get("/payments/history");
      if (res.data.ok) {
        setHistory(res.data.data);
      }
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <Container>
      <Heading1 text="결제 이력" />
      <Heading2
        text="결제 이력 및 각 결제의 상세 내역을 확인하고 매출전표를 발행할 수 있습니다."
        style={{ marginBottom: "2.44rem" }}
      />

      <FlexColumn style={{ gap: "1.88rem" }}>
        <CardLayout style={{ padding: "1.88rem 2.5rem" }}>
          <Text1 text="이용 현황" />
          <Divider />
          <FlexColumn style={{ gap: "1.25rem" }}>
            <Ul>
              <Li style={{ background: "#F7F7F7" }}>
                <HeaderItem>결제일</HeaderItem>
                <HeaderItem>결제(주문) 번호</HeaderItem>
                <HeaderItem>결제 플랜</HeaderItem>
                <HeaderItem>결제 주기</HeaderItem>
                <HeaderItem>결제 금액</HeaderItem>
                <HeaderItem></HeaderItem>
              </Li>
              {history.map((item, index) => (
                <Li>
                  <Item>{getDate(item.createdAt, "YYYY.MM.DD")}</Item>
                  <Item>{item.order_no}</Item>
                  <Item>맘핏</Item>
                  <Item>
                    [{item.planType === PAYMENT_TYPE.YEAR ? "연" : "월"} 구독]
                    1개월
                  </Item>
                  <Item>{item.amount.toLocaleString()}원</Item>
                  <Item>
                    <TextLink onClick={(e) => onOrderDetailClick(e, item)}>
                      상세 내역
                    </TextLink>
                  </Item>
                </Li>
              ))}
            </Ul>
          </FlexColumn>
        </CardLayout>
      </FlexColumn>
      <Modal showing={showDetailHistory} width="36.6875rem">
        <PaymentHistoryDetailModal
          order={selectedOrderDetail}
          closeHandler={() => setShowDetailHistory(false)}
        />
      </Modal>
    </Container>
  );
};

export default ManagerPaymentHistory;
