import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import ModalBackground from "../../../components/ModalBackground";
import { TABLET_LANDSCAPE } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getDate } from "../../../utils/date";
import MyPage from "../../mypage/MyPage";
import CardLayout from "../CardLayout";
import Heading1 from "../Heading1";
import Heading2 from "../Heading2";
import EventModal from "./EventModal";
import WithdrawModal from "./WithdrawModal";

const Container = styled.div`
  margin-left: var(--sidebar-width);
  // height: calc(100% - 4.06rem);
  padding: 2.25rem 2.5rem;
  width: 100%;
  // border: 1px solid black;
  display: flex;
  flex-direction: column;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    margin-left: var(--sidebar-tablet-landscape-width);
  }
`;

const Section = styled.section`
  margin-top: 2rem;
  //   display: grid;
  //   grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.5rem;
  width: 100%;
`;

const SectionHeading1 = styled.p`
font-weight: 600;
line-height: 140%
font-size: 1.25rem;
`;

const Callout = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.primary.light};
  border-radius: 0.75rem;
  padding: 1.84rem 2.19rem;
  display: flex;
  align-items: center;
  margin-bottom: 2.69rem;
`;

const CalloutTitle = styled.span`
  color: ${({ theme }) => theme.primary.normal};
  margin-right: 1.75rem;
`;

const SectionHeading2 = styled.p`
  font-weight: 500;
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 1.44rem;
`;

const Box = styled.div`
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  border-radius: 0.75rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  padding: 1.87rem 2rem;
  //   line-height: 140%;
`;

const BoxHeading = styled.p`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 1.06rem;
`;

const BoxText = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  line-height: 160%;
`;

const BoxButton = styled.button`
  background: ${({ theme }) => theme.primary.normal};
  padding: 0.38rem 1.25rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 0.4375rem;
  border: none;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 160%;
  align-self: flex-end;
`;

const EventBanner = styled.img`
  width: 49%;
  ${({ status }) => status !== "end" && "cursor: pointer"};
`;

const BackSvg = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM19 9C19.5523 9 20 8.55228 20 8C20 7.44772 19.5523 7 19 7V9ZM1 9H19V7H1V9Z"
        fill="#222222"
      />
    </svg>
  );
};

const PersonalReward = () => {
  const { auth } = useAuth();

  const NOW = new Date();

  const axiosPrivate = useAxiosPrivate();

  const [isLoading, setIsLoading] = useState(true);
  const [currentCash, setCurrentCash] = useState();

  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);

  const navigate = useNavigate();

  const handleCancelEditButton = () => {
    navigate("/dashboard/mypage");
  };

  const handleWithdrawClick = () => {
    setShowWithdrawModal(true);
  };
  const handleCloseWithdrawModalClick = (e, type) => {
    if (type === "cancel") {
      setShowWithdrawModal(false);
    } else {
      window.location.reload();
    }
  };

  const handleEventBannerClick = () => {
    setShowEventModal(true);
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await axiosPrivate.get("/cash");

      setCurrentCash(response.data.data.currentCash);
      setIsLoading(false);
    };

    fetch();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <Heading1>마이 페이지</Heading1>
      <Heading2>맘핏 멤버십 정보를 확인할 수 있어요</Heading2>
      <Section>
        <CardLayout style={{ width: "100%" }}>
          <FlexColumn>
            <BackSvg onClick={handleCancelEditButton} />
            <SectionHeading1
              style={{ marginTop: "1.56rem", marginBottom: "1.56rem" }}
            >
              캐시 환급 신청
            </SectionHeading1>
            <Callout>
              <CalloutTitle>캐시 현황</CalloutTitle>
              <span>
                {NOW.getFullYear()}년 {NOW.getMonth() + 1}월 {NOW.getDate()}일
                기준 {auth.user.name} 님의 보유 캐시는
              </span>
              <img
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  marginLeft: "0.63rem",
                  marginRight: "0.37rem",
                }}
                src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                alt={"cash icon"}
              />
              <span
                style={{
                  fontWeight: 600,
                  fontSize: "1.375rem",
                  marginRight: "0.5rem",
                }}
              >
                {currentCash.toLocaleString()}원
              </span>
              입니다.
            </Callout>
            <SectionHeading2>환급 방식을 선택해 주세요</SectionHeading2>
            <FlexRow style={{ gap: "2.69rem" }}>
              <Box>
                <FlexColumn>
                  <BoxHeading>현금 이체</BoxHeading>
                  <BoxText>
                    현금 이체는 10,000원 단위로 이체할 수 있습니다.
                    <br /> 월 1회만 신청 가능하며, 매월 말 일괄 이체됩니다.
                    <br />{" "}
                    <span style={{ fontWeight: 600 }}>
                      (신청 가능 기간: 매월 20일까지)
                    </span>
                  </BoxText>
                </FlexColumn>
                <FlexRow
                  style={{
                    marginTop: "0.44rem",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <BoxButton onClick={handleWithdrawClick}>신청하기</BoxButton>
                  {/* <BoxButton>신청하기</BoxButton> */}
                  <img
                    src={`${process.env.PUBLIC_URL}/svg/withdraw_icon.svg`}
                    alt={"withdraw_icon"}
                  />
                </FlexRow>
              </Box>
              <Box style={{ opacity: 0.5 }}>
                <FlexColumn>
                  <BoxHeading>맘핏 굿즈</BoxHeading>
                  <BoxText>
                    맘핏 굿즈 스토어에서 원하는 굿즈를 캐시로 구매할 수
                    있습니다.
                    <br />
                    <span style={{ fontWeight: 600 }}>
                      (신청 가능 기간: 상시)
                    </span>
                  </BoxText>
                </FlexColumn>
                <FlexRow
                  style={{
                    marginTop: "0.44rem",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <BoxButton>신청하기</BoxButton>
                  <img
                    src={`${process.env.PUBLIC_URL}/svg/goods_icon.svg`}
                    alt={"withdraw_icon"}
                  />
                </FlexRow>
              </Box>
              <Box style={{ opacity: 0.5 }}>
                <FlexColumn>
                  <BoxHeading>기부하기</BoxHeading>
                  <BoxText>
                    현금 이체는 10,000원 단위로 이체할 수 있습니다.
                    <br /> 월 1회만 신청 가능하며, 매월 말 일괄 이체됩니다.
                    <br />{" "}
                    <span style={{ fontWeight: 600 }}>
                      (신청 가능 기간: 매월 20일까지)
                    </span>
                  </BoxText>
                </FlexColumn>
                <FlexRow
                  style={{
                    marginTop: "0.44rem",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <BoxButton>신청하기</BoxButton>
                  <img
                    src={`${process.env.PUBLIC_URL}/svg/donation_icon.svg`}
                    alt={"withdraw_icon"}
                  />
                </FlexRow>
              </Box>
            </FlexRow>
          </FlexColumn>
          <FlexRow
            style={{
              justifyContent: "space-between",
              marginTop: "3rem",
            }}
          >
            <EventBanner
              src={
                "https://s3.ap-northeast-2.amazonaws.com/maum.fit-assets/events/event_banner_1.png"
              }
              alt={"event 1 banner"}
              onClick={handleEventBannerClick}
            />

            <EventBanner
              src={
                "https://s3.ap-northeast-2.amazonaws.com/maum.fit-assets/events/event_banner_2.png"
              }
              alt={"event 2 banner"}
              status={"end"}
            />
          </FlexRow>
        </CardLayout>
      </Section>
      {showWithdrawModal && (
        <ModalBackground>
          <WithdrawModal closeClick={handleCloseWithdrawModalClick} />
        </ModalBackground>
      )}
      {showEventModal && (
        <ModalBackground>
          <EventModal closeClick={() => setShowEventModal(false)} />
        </ModalBackground>
      )}
    </Container>
  );
};

export default PersonalReward;
