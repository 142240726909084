import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import axios from "../../api/axios";
import Loading from "../../components/Loading";
import { RECOVERY } from "../../Constants";
import useAuth from "../../hooks/useAuth";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  @media screen and (max-width: 480px) {
    padding: 50px 10px;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  width: 50%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  /* align-items: center; */
`;

const Form = styled.form`
  width: 40%;
  max-width: 390px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(55, 53, 47, 0.16);
  padding: 20px;
`;

const Input = styled.input`
  padding: 15px 10px;
  width: 100%;
  border: 1px solid rgba(55, 53, 47, 0.16);
`;

const Submit = styled.input`
  padding: 15px 10px;
  width: 100%;
  margin-top: 20px;
  background-color: ${RECOVERY};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #ffb500;
  }
  transition: background-color 0.2s;

  // To adapt css in iphone
  -webkit-appearance: none;
`;

const DetailContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
`;

const Span = styled.span`
  font-size: 13px;
  margin: 0 5px;
  cursor: pointer;
`;

function Signup() {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { auth, setAuth, setPersist } = useAuth();
  const [formData, setFormData] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleNameChange = (e) => {
    // console.log(e.target.value);
    setName(e.target.value);
  };
  const handleEmailChange = (e) => {
    // console.log(e.target.value);
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    // console.log(e.target.value);
    setPassword(e.target.value);
  };
  const handlePasswordCheckChange = (e) => {
    // console.log(e.target.value);
    setPasswordCheck(e.target.value);
  };
  const handleLoginClick = () => {
    navigate("/login");
  };
  const handleSignupClick = async (e) => {
    e.preventDefault();
    // TODO: email 중복체크
    console.log(formData);
    if (formData.password !== formData.passwordCheck) {
      alert("비밀번호가 일치하지 않습니다. 다시 입력해주세요.");
      return;
    }

    setIsLoading(true);

    const response = await axios.post("/signup-admin", {
      name: formData.name,
      email: params.get("email"),
      password: formData.password,
      companyName: formData.company,
      employees: formData.employees,
      phone: formData.phone,
    });
    console.log(response.data);

    // TODO: 로그인 처리해주기
    try {
      const response = await axios.post(
        `/login`,
        JSON.stringify({
          email: params.get("email"),
          password: formData.password,
        }),
        {
          withCredentials: true,
        }
      );
      const accessToken = response.data.accessToken;
      setAuth({
        user: {
          email,
          id: response.data.user._id,
          ...response.data.user,
        },
        accessToken,
      });
      console.log("success");
      localStorage.setItem("persist", true);
      setPersist(true);
      setTimeout(() => {
        setIsLoading(false);
        navigate("/leader/dashboard");
        return;
      }, 1000);
    } catch (err) {
      setTimeout(() => {
        setIsLoading(false);
        return;
      }, 500);
    }
    // try {
    //   const response = await axios.post("/signup", {
    //     name,
    //     email,
    //     password,
    //   });

    //   if (response.status === 200) {
    //     setTimeout(() => {
    //       setIsLoading(false);
    //       navigate("/login");

    //       return;
    //     }, 2000);
    //   }
    // } catch (err) {
    //   console.error(err.response);
    //   setIsLoading(false);
    //   alert(err.response.data.msg);
    // }
  };

  useEffect(() => {
    (async () => {
      const res = await axios.get(
        `/signup/verify?verify=${params.get("verify")}`
      );
    })();
  }, []);

  return (
    <Container>
      <Wrapper>
        <Form>
          <Title>회원가입</Title>
          <Input
            name="password"
            type="password"
            placeholder="비밀번호"
            onChange={handleOnChange}
          />
          <Input
            name="company"
            type="text"
            placeholder="회사명"
            onChange={handleOnChange}
          />
          <Input
            name="employees"
            type="text"
            placeholder="직원 수"
            onChange={handleOnChange}
          />
          <Input
            name="name"
            type="text"
            placeholder="관리자 이름"
            onChange={handleOnChange}
          />
          <Input
            name="phone"
            type="text"
            placeholder="관리자 연락처"
            onChange={handleOnChange}
          />
          <Submit
            type="submit"
            value={"회원가입"}
            onClick={handleSignupClick}
          />
          <DetailContainer>
            <Span onClick={handleLoginClick}>로그인하러 가기</Span>
          </DetailContainer>
        </Form>
      </Wrapper>
      {isLoading && <Loading />}
    </Container>
  );
}

export default Signup;
