import { useNavigate, useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useEffect, useState } from "react";
import axios from "../api/axios";
import FlexRow from "../components/FlexRow";
import FlexColumn from "../components/FlexColumn";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import "react-slideshow-image/dist/styles.css";
import convertPermavac from "../utils/convertPermavac";
import { MOBILE } from "../Constants";
import {
  getDate,
  getThisWeekFriday,
  getThisWeekMonday,
  getWeekNumOfMonth,
} from "../utils/date";
import useCountdown from "../hooks/useCountdown";
import { isAuthEmpty } from "../utils/isAuthEmpty";
import { Helmet } from "react-helmet";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 5.75rem);
  background: ${({ theme }) => theme.grayScaleColor.white};
  // border: 1px solid black;
`;

const Heading1 = styled.h2`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 2rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Heading2 = styled.h2`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const Heading3 = styled.h3`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  margin-top: 2.56rem;
  margin-bottom: 1.25rem;
`;

const Heading4 = styled.h4`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const Section = styled.section`
  padding: 1.75rem 1.25rem;
`;

const Callout = styled.div`
  padding: 0.75rem 1.25rem;
  border-radius: 0.625rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .right {
    font-weight: 500;
    color: ${({ theme }) => theme.backgroundColor.darkgray40};
  }
`;

const LineButton = styled.p`
  text-align: center;
  font-family: Pretendard;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  text-decoration-line: underline;
  color: ${({ theme, state }) =>
    state ? theme.backgroundColor.darkgray40 : theme.primary.normal};
`;

function MypageNotification() {
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const onSubscribeClick = async () => {
    const response = await axiosPrivate.post(`/subscribe`);
    if (!response.data.ok) {
      return;
    }
    setAuth(() => ({
      accessToken: auth.accessToken,
      user: {
        ...auth.user,
        isSubscribe: true,
        unsubscribeAt: null,
      },
    }));
  };

  const onUnsubscribeClick = () => {
    window.location.assign(
      `https://660movmk899.typeform.com/to/M5D9qaDE#sid=${auth.user.subscribeId}`
    );
  };

  useEffect(() => {
    const fetch = async () => {
      // console.log(response.data.surveys);
      setIsLoading(false);
    };

    if (isAuthEmpty(auth)) {
      return navigate("/login");
    }
    fetch();
  }, []);
  // console.log(surveyYearMonth, monthPeriod);
  if (isLoading) {
    return <div />;
  }

  return (
    <Container>
      <Helmet>
        <meta name="theme-color" content="#fff" />
      </Helmet>
      <Section>
        <Heading1>문자 알림 설정</Heading1>
        <Heading4 style={{ marginTop: "0.5rem" }}>
          마음 체크업 및 트레이닝 문자 알림을 설정할 수 있어요.
        </Heading4>
        <FlexColumn style={{ marginTop: "1.75rem", gap: "0.5rem" }}>
          <Heading2>현재 설정</Heading2>
          <Callout>
            <span>문자 알림</span>
            <span
              className="right"
              style={{ color: auth.user.isSubscribe && "#00DBB1" }}
            >
              {auth.user.isSubscribe ? "ON" : "OFF"}
            </span>
          </Callout>
        </FlexColumn>
        <FlexColumn style={{ marginTop: "1.75rem", gap: "0.5rem" }}>
          <Heading2>수신 거부</Heading2>
          <Callout>
            <span>수신 거부 날짜</span>
            <span className="right">
              {auth.user.isSubscribe
                ? "해당없음"
                : getDate(auth.user.unsubscribeAt, "YYYY.MM.DD(ddd)")}
            </span>
          </Callout>
        </FlexColumn>
        <LineButton
          state={auth.user.isSubscribe}
          style={{ marginTop: "1.75rem" }}
          onClick={
            auth.user.isSubscribe ? onUnsubscribeClick : onSubscribeClick
          }
        >
          {auth.user.isSubscribe ? "수신 거부" : "수신 거부 해제"}{" "}
        </LineButton>
      </Section>
    </Container>
  );
}

export default MypageNotification;
