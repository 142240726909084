import { useEffect, useState } from "react";
import styled from "styled-components";
import { TABLET_LANDSCAPE } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getDate } from "../../../utils/date";
import CardLayout from "../CardLayout";
import Heading1 from "../Heading1";
import { useSearchParams } from "react-router-dom";
import SortButton from "../components/SortButtons";

const Container = styled.div`
  padding: 2.25rem 2.5rem;
  padding-left: calc(2.75rem + var(--sidebar-width));
  width: 100%;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    margin-left: var(--sidebar-tablet-landscape-width);
  }
`;

const Tabs = styled.div`
  display: flex;
  gap: 1.25rem;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  line-height: 140%;
  flex-wrap: wrap;
`;
const Tab = styled.div`
  cursor: pointer;
  ${({ theme, clicked }) =>
    clicked && `color: ${theme.grayScaleColor.black}; font-weight: 600;`}
`;

const ListContainer = styled.ul`
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const ColumnHeader = styled.li`
  display: flex;
  justify-content: space-evenly;
  font-weight: 600;
  padding: 1rem 0;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  border-top: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding-left: 2.31rem;
`;

const ColumnItem = styled.div``;

const Row = styled.li`
  display: flex;
  justify-content: space-evenly;
  font-weight: 400;
  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding-left: 2.31rem;
`;
const RowItem = styled.div``;

const UsersManagement = () => {
  const ALL = "모든 구성원";
  const {
    auth: { user },
  } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [params] = useSearchParams();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState({
    name: {
      order: "asc",
    },
    checkupCount: {
      order: "asc",
    },
    trainingCount: {
      order: "asc",
    },
  });

  const onSortClick = (e, column) => {
    const sortArr = [...filteredUsers];

    if (sortOrder[column].order === "asc") {
      sortArr.sort((a, b) => {
        if (a[column] < b[column]) {
          return -1;
        }
        if (a[column] > b[column]) {
          return 1;
        }
        return 0;
      });

      setFilteredUsers(sortArr);
      setSortOrder((prev) => ({
        ...prev,
        [column]: {
          order: "desc",
        },
      }));
    } else {
      sortArr.sort((a, b) => {
        if (a[column] < b[column]) {
          return 1;
        }
        if (a[column] > b[column]) {
          return -1;
        }
        return 0;
      });

      setFilteredUsers(sortArr);
      setSortOrder((prev) => ({
        ...prev,
        [column]: {
          order: "asc",
        },
      }));
    }
  };
  const companyId = params.get("id") || user.companyId._id;
  const handleTabClick = (e, index) => {
    setTabIndex(index);
    if (index === 0) {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(
        users.filter(
          (item) => item.departmentId.name === departments[index].name
        )
      );
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await axiosPrivate.get(`/users?company=${companyId}`);

      setUsers(response.data.users);
      setFilteredUsers(response.data.users);
      setDepartments([
        { name: ALL, count: response.data.users.length },
        ...response.data.departments,
      ]);
    };
    fetch();
  }, []);
  return (
    <Container>
      <Heading1 style={{ marginBottom: "1.75rem" }}>구성원 관리</Heading1>
      <CardLayout style={{ flex: 1 }}>
        <Tabs>
          {departments.map((item, index) => (
            <Tab
              key={index}
              onClick={(e) => handleTabClick(e, index)}
              clicked={index === tabIndex}
            >
              {item.name} ({item.count})
            </Tab>
          ))}
        </Tabs>
        <ListContainer>
          <ColumnHeader>
            <ColumnItem
              style={{ flex: 1 }}
              onClick={(e) => onSortClick(e, "name")}
            >
              이름
              <SortButton />
            </ColumnItem>
            <ColumnItem style={{ flex: 1 }}>그룹</ColumnItem>
            <ColumnItem style={{ flex: 2 }}>이메일</ColumnItem>
            <ColumnItem
              style={{ flex: 1 }}
              onClick={(e) => onSortClick(e, "checkupCount")}
            >
              마음 체크업 횟수
              <SortButton />
            </ColumnItem>
            <ColumnItem
              style={{ flex: 1 }}
              onClick={(e) => onSortClick(e, "trainingCount")}
            >
              마음 트레이닝 횟수
              <SortButton />
            </ColumnItem>
            <ColumnItem style={{ flex: 1 }}>가입일</ColumnItem>
          </ColumnHeader>
          {filteredUsers.map((item, index) => {
            return (
              <Row key={index}>
                <RowItem style={{ flex: 1 }}>{item.name}</RowItem>
                <RowItem style={{ flex: 1 }}>{item.departmentId.name}</RowItem>
                <RowItem style={{ flex: 2 }}>{item.email}</RowItem>
                <RowItem style={{ flex: 1 }}>{item.checkupCount}</RowItem>
                <RowItem style={{ flex: 1 }}>{item.trainingCount}</RowItem>
                <RowItem style={{ flex: 1 }}>
                  {getDate(new Date(item.createdAt))}
                </RowItem>
              </Row>
            );
          })}
        </ListContainer>
      </CardLayout>
    </Container>
  );
};

export default UsersManagement;
