export default (lines, durations = []) => {
  return lines.split("\n").map((line, index) => {
    return (
      <span
        key={index}
        data-aos={durations.length > 0 && line.length > 0 && "fade-up"}
        data-aos-duration={durations.length > 0 && 1500}
        data-aos-delay={durations.length > 0 && durations[index]}
        data-aos-once="true"
        data-aos-anchor-placement={"bottom"}
      >
        {line}
        <br />
      </span>
    );
  });
};
