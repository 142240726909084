import styled from "styled-components";

const Container = styled.div`
  display: flex;
  cursor: pointer;
`;

const Svg = styled.svg``;
const Text = styled.span`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1rem;
  margin-left: 0.69rem;
`;

const BackButton = ({ children, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 1L1 6L7 11"
          stroke="#111111"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
      <Text>{children}</Text>
    </Container>
  );
};

export default BackButton;
