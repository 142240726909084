import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const RouteChangeTracker = () => {
  const location = useLocation();
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID);
      setInit(true);
    }
  }, []);
  useEffect(() => {
    // console.log(location.pathname + location.search);
    if (init) {
      ReactGA.set({ page: location.pathname + location.search });
      ReactGA.send("pageview");
    }
  }, [init, location]);

  return <div></div>;
};

export default RouteChangeTracker;
