import styled from "styled-components";
import { MOBILE } from "../../../../Constants";

const P = styled.p`
  padding: 1rem 1.63rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.13rem;
  line-height: 1.81rem;
  @media (max-width: ${MOBILE}) {
    font-size: 0.8125rem;
    line-height: 160%;
    padding: 1rem;
  }
`;

const ReportSummaryCallout = ({ children, style }) => {
  return <P style={style}>{children}</P>;
};

export default ReportSummaryCallout;
