import { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "../../../api/axios";
import { ROUTES } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import includesPathname from "../../../utils/includesPathname";
import getSmilePhotos from "../api/getSmilePhotos";
import BottomSheet from "../components/BottomSheet";
import Description from "../components/Description";
import Heading from "../components/Heading";
import Modal from "../components/Modal";
import PhotoCard from "../components/PhotoCard";
import PhotoDialog from "../components/PhotoDialog";
import SubHeading from "../components/SubHeading";
import { LIKE, RECENT, SCORE } from "../constants";

const Container = styled.div`
  width: 100%;
  padding-bottom: 45px;
`;

const Photos = styled.section`
  /* border: 1px solid black; */
  width: 100%;
  margin-bottom: 45px;
`;

const DropdownButton = styled.div`
  font-size: 0.94rem;
  color: #111;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.04em;
  text-align: right;
  margin-top: 24px;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  float: right;
`;

const DropdownText = styled.span`
  margin-right: 15px;
`;
const DownCaret = styled.span`
  position: absolute;
  bottom: 4px;
  font-size: 1rem;
`;

const MoreButton = styled.button`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  background: white;
  padding: 6px 24px;
  font-size: 0.94rem;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.04em;
  border-radius: 5px;
  display: block;
  margin: 0 auto;
`;

const AIChampions = () => {
  const { auth } = useAuth();
  const [sortOpen, setSortOpen] = useState(false);
  const [sort, setSort] = useState(RECENT);
  const [photos, setPhotos] = useState([]);
  const [page, setPage] = useState(0);
  const { cid } = useParams();

  const handleSortClick = () => {
    setSortOpen(true);
  };

  const selectSort = async () => {
    const response = await getSmilePhotos({
      auth: includesPathname(ROUTES.GUEST_SMILE) ? null : auth,
      cid,
      sort: sort.value,
      page: 0,
      isPublic: true,
    });
    if (response.ok) {
      setPhotos(response.photos);
      setPage(response.nextPage);
    }
    setSortOpen(false);
  };

  const handleMorePhotoClick = async () => {
    const response = await getSmilePhotos({
      auth: includesPathname(ROUTES.GUEST_SMILE) ? null : auth,
      cid,
      sort: sort.value,
      page,
      isPublic: true,
    });
    setPage(response.nextPage);
    setPhotos((cur) => [...cur, ...response.photos]);
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await getSmilePhotos({
        auth: includesPathname(ROUTES.GUEST_SMILE) ? null : auth,
        cid,
        sort: sort.value,
        page,
        isPublic: true,
      });
      setPage(response.nextPage);
      setPhotos(response.photos);
    };

    fetchData();
  }, []);

  return (
    <Container>
      <SubHeading text={`Duchenne Smile Champions`} />
      <Heading text="찐-미소 챔피언즈 🏆" />
      <Description
        text={"광대승천 찐-미소를 달성한\n명예의 맘핏러들을 소개합니다🎉"}
      />
      <DropdownButton onClick={handleSortClick}>
        <DropdownText>{sort.label}</DropdownText> <DownCaret>&#8964;</DownCaret>
      </DropdownButton>
      <Photos>
        <ResponsiveMasonry columnsCountBreakPoints={{ 750: 2 }}>
          <Masonry gutter="10px">
            {photos.map((item, index) => (
              <PhotoCard
                key={item._id}
                setPhotos={setPhotos}
                index={index}
                auth={includesPathname(ROUTES.GUEST_SMILE) ? null : auth}
                photo={item}
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </Photos>
      <MoreButton onClick={handleMorePhotoClick}>
        + 더 많은 미소 보기
      </MoreButton>
      {sortOpen && (
        <Modal style={{ alignItems: "flex-end" }}>
          <BottomSheet
            items={[RECENT, LIKE, SCORE]}
            closeHandler={selectSort}
            selected={sort}
            setSelected={(item) => setSort(item)}
          />
        </Modal>
      )}
    </Container>
  );
};

export default AIChampions;
