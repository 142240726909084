import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "./components/GlobalStyles";
import { AuthProvider } from "./context/AuthProvider";

import Navbar from "./components/Navbar";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import ScrollToTop from "./components/ScrollToTop";

const TRACKING_ID = process.env.REACT_APP_GA4_MEASUREMENT_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <>
    <GlobalStyles />
    <BrowserRouter>
      <AuthProvider>
        {/* <Navbar /> */}
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <App />
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  </>
  // </React.StrictMode>
);
