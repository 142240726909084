import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import { useEffect, useRef, useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  zoomPlugin
);

// ChartJS.pluginService.register({
//   beforeInit: function (chart) {
//     console.log("hi", chart);
//     chart.legend.afterFit = function () {
//       chart.legend.options.labels.padding = 20;
//       chart.height += 30;
//     };
//   },
// });

const Container = styled.div`
  /* width: 28.75rem;
  height: 18.75rem; */
  width: 100%;
  /* border-radius: 0.9375rem; */
  /* padding: 20px; */
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  @media (max-width: 425px) {
    /* width: 100%; */
    height: 225px;
  }
  // border: 1px solid black;
  font-family: Montserrat;
`;

export function GradientChart({
  result,
  dates,
  color,
  style,
  min = 0,
  max = 100,
  step = 20,
  unit = "",
}) {
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });
  let labels = [...dates];
  // let extraDates = 7 - dates.length;
  // let lastDates = labels[dates.length - 1];
  if (labels.length === 0) {
    labels.push(new Date());
  }
  if (labels.length < 7) {
    for (let i = 0; i < 7; i++) {
      if (labels[i] === undefined) {
        labels.push(null);
      }
    }
  }

  // if (dates.length < 7) {
  //   for (let i = extraDates; i > 0; i--) {
  //     lastDates = new Date(lastDates).setDate(
  //       new Date(lastDates).getDate() + 1
  //     );
  //     labels.push(lastDates);
  //   }
  // }
  // labels = labels.map((item) => {
  //   return item
  //     ? `${new Date(item).getMonth() + 1}/${new Date(item).getDate()}`
  //     : null;
  // });

  const countRef = useRef(0);
  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    layout: {
      // padding: 20,
    },
    plugins: {
      legend: {
        labels: {
          // padding: 10,
          boxHeight: 2,
        },
        display: false,
        position: "bottom",
      },
      zoom: {
        pan: { enabled: true, mode: "x" },
      },
      title: {
        display: false,
        text: "주간 변화 추이",
      },
      datalabels: {
        display: false,
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderRadius: 4,
        color: "white",
        font: {
          weight: "bold",
        },
        // formatter: Math.round,
        padding: 6,
      },
      tooltip: {
        backgroundColor: "#00DBB1",
        xAlign: "center",
        yAlign: "bottom",
        // x: 100,
        // y: 500,
        padding: {
          top: 6,
          bottom: 6,
          left: 20,
          right: 20,
        },
        bodyFont: {
          size: 12,
          weight: "bold",
        },
        // title: "aaa",
        callbacks: {
          title: () => {
            return "";
          },
          label: (context) => {
            return `${context.formattedValue}${unit}`;
          },
          labelTextColor: () => {
            return "white";
          },
        },
        displayColors: false,
      },
    },
    // elements: {
    //   line: {
    //     fill: true,
    //     tension: 0,
    //   },
    // },
    scales: {
      x: {
        offset: true, // x축 양끝 짤리는 거 옵션
        min: labels.length - 4,
        max: labels.length - 1,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          color: "#777777",
          padding: 10,
          font: {
            size: 11,
            family: "Montserrat",
          },
          maxTicksLimit: 4,
        },
      },
      y: {
        offset: true, // y축 위/아래 짤리는 거 옵션
        min,
        max,
        ticks: {
          stepSize: step,
          padding: 0,
          color: "#777777",
          font: {
            size: 10,
            family: "Montserrat",
          },
        },
        grid: {
          drawBorder: false,
          lineWidth: 1,
        },
      },
    },
  };

  const [data, setData] = useState();
  useEffect(() => {
    // console.log(result);
    setData({
      labels,
      datasets: [
        {
          data: result,
          // borderColor: color,
          // backgroundColor: color,
          borderColor: "#00DBB1",
          backgroundColor: (context) => {
            if (!context.chart.chartArea) {
              return;
            }

            const {
              ctx,
              data,
              chartArea: { top, bottom },
            } = context.chart;
            const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
            gradientBg.addColorStop(0, "rgba(0, 219, 177, 1)");
            // gradientBg.addColorStop(0.8, "#D9D9D9");
            gradientBg.addColorStop(1, "rgba(255,255,255, 0.3)");
            return gradientBg;
          },
          fill: true,
          borderWidth: 1.5,
          tension: 0.4,
          pointRadius: 4,
          pointHoverRadius: 6,
          pointHoverBorderWidth: 1.5,
          pointHoverBackgroundColor: "white",
          pointBackgroundColor: "white",
          pointBorderColor: "#00DBB1",
        },
      ],
    });
  }, [result]);
  // useEffect(() => {
  //   if (countRef.current === 0) {
  //     if (result.length === 1) {
  //       const obj = {
  //         ...data,
  //         datasets: [data.datasets[1]],
  //       };
  //       console.log(obj);
  //       console.log(result[0].slice(1, 7));
  //       obj.datasets[0].data = result[0].slice(1, 7);
  //       console.log(obj);
  //       setData(obj);
  //     }
  //     countRef.current += 1;
  //   }
  //   // setData();
  // }, []);

  return (
    <Container style={style}>
      {data && (
        <Line options={options} data={data} height={isMobile ? 220 : 220} />
      )}
    </Container>
  );
}
