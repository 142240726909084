import { useNavigate, useOutletContext } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useEffect, useState } from "react";
import axios from "../api/axios";
import FlexRow from "../components/FlexRow";
import FlexColumn from "../components/FlexColumn";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import "react-slideshow-image/dist/styles.css";
import convertPermavac from "../utils/convertPermavac";
import { MOBILE } from "../Constants";
import { getDate, getThisWeekFriday } from "../utils/date";
import useCountdown from "../hooks/useCountdown";
import { isAuthEmpty } from "../utils/isAuthEmpty";
import { Helmet } from "react-helmet";
import lineBreaker from "../utils/lineBreaker";
import Modal from "./dashboard/components/Modal";
import RandomNumberScroll from "./RandomNumberScroll";
import ReactConfetti from "react-confetti";
import useWindowSize from "../hooks/useWindowSize";
import useDocumentHeight from "../hooks/useDocumentHeight";
import useScrollCenter from "../hooks/useScrollCenter";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 5.75rem);
  background: ${({ theme }) => theme.grayScaleColor.white};
  // border: 1px solid black;
`;

const TapbarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
  /* padding-bottom: 0.5rem; */
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const Tap = styled.div`
  flex: 1;
  text-align: center;
  margin: 0 0.75rem;
  ${({ theme, selected }) =>
    selected && `border-bottom: 0.125rem solid ${theme.primary.normal}`};
  padding-bottom: 0.5rem;
  color: ${({ theme, selected }) => selected && theme.primary.normal};
  font-weight: ${({ theme, selected }) => selected && 600};
`;

const Wrapper = styled.div`
  padding: 1.75rem 1.25rem;
`;

const Heading1 = styled.h2`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 2rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Heading2 = styled.h2`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  color: black;
`;

const Heading3 = styled.h3`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  margin-top: 2.56rem;
  margin-bottom: 1.25rem;
`;

const Heading4 = styled.h4`
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  color: #000;
`;

const Section = styled.section`
  padding: 1.75rem 1.25rem;
`;

const Divider = styled.div`
  width: 100%;
  height: 0.3125rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
`;

const BannerImage = styled.img`
  width: 100%;
`;

const Text1 = styled.p`
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 3.4rem */
  color: ${({ theme }) => theme.grayScaleColor.black};
  text-align: center;
  margin-bottom: 0.25rem;
`;

const Text2 = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.3rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  flex: 2;
`;

const Text3 = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.3rem */
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  flex: 1;
  text-align: right;
`;

const Text4 = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.3rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const Badge = styled.div`
  padding: 0.25rem 0.75rem;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.3rem */
  letter-spacing: -0.03125rem;
  border-radius: 3.125rem;
  background: ${({ bgColor }) =>
    bgColor === 1 ? "#E8FBF7" : bgColor === 0 ? "#EEF8FD" : "#FDEFEF"};
  color: ${({ bgColor }) =>
    bgColor === 1 ? "#00CA99" : bgColor === 0 ? "#017FC6" : "#F11B1B"};
  text-align: center;
  width: fit-content;
  margin: 0 auto;
`;

const RightCaret = () => {
  return (
    <svg
      style={{ marginLeft: "0.5rem" }}
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
    >
      <path
        opacity="0.6"
        d="M1 1L5 4.5L1 8"
        stroke="#7C8585"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const BlockCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.9375rem;
  background: ${({ theme }) => theme.primary.light};
  box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.1);
  padding: 2.19rem 0;
  & h1 {
    color: #000;

    font-size: 1.5rem;
    font-weight: 500;
    line-height: 160%; /* 2.4rem */
    letter-spacing: -0.03125rem;
  }
  & h2 {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 140%; /* 1.225rem */
    letter-spacing: -0.03125rem;
    color: ${({ theme }) => theme.primary.normal};
  }
  & h3 {
    color: #666;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 160%; /* 1.4rem */
    letter-spacing: -0.03125rem;
  }
  & .cash {
    padding: 0.31rem 0.75rem;
    border-radius: 3.125rem;
    background: #fff;
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 160%;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-top: 0.63rem;
    & img {
      width: 1rem;
      height: 1rem;
    }
  }
  & .block {
    width: 8.125rem;
    height: 8.125rem;
    margin-top: 1rem;
  }
  & .start__btn {
    border-radius: 0.75rem;
    background: ${({ theme, isComplete }) =>
      isComplete ? theme.grayScaleColor.gray20 : theme.primary.normal};
    border: 1px solid
      ${({ theme, isComplete }) =>
        isComplete ? theme.grayScaleColor.gray20 : theme.primary.normal};
    color: ${({ theme }) => theme.grayScaleColor.white};
    font-size: 1rem;
    font-weight: 500;
    line-height: 160%; /* 1.6rem */
    letter-spacing: -0.03125rem;
    padding: 0.75rem 1.38rem;
    margin-top: 0.62rem;
  }
  & .complete {
    border-radius: 0.75rem;
    background: ${({ theme }) => theme.grayScaleColor.gray20};
    border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  }
`;

const StampContainer = styled.div`
  width: 3.125rem;
  height: 3.125rem;
  background: ${({ theme, isEarned, isTriple, isComplete }) =>
    isTriple && isComplete
      ? "#FFDB65"
      : isTriple && !isComplete
      ? "#FFF1CE"
      : isEarned
      ? theme.primary.normal
      : theme.grayScaleColor.gray10};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 4px solid transparent;
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  ${({ isComplete, isEarned, isTriple }) =>
    isComplete &&
    !isEarned &&
    "background-image: linear-gradient(#00dbb1, #00dbb1), linear-gradient(0deg, #10ffd4 0%, #c7f2ff 100%);"}
  ${({ isComplete, isEarned }) =>
    isComplete && !isEarned && "background-origin: border-box;"}
${({ isComplete, isEarned }) =>
    isComplete && !isEarned && "background-clip: content-box, border-box;"}
  ${({ isComplete, isEarned, isTriple }) =>
    isTriple &&
    isComplete &&
    !isEarned &&
    "background-image: linear-gradient(#FFCE2B, #FFCE2B), linear-gradient(0deg, #FFABD3 0%, #FFB800 100%);"}
  ${({ isComplete, isEarned, isTriple }) =>
    isTriple && isComplete && !isEarned && "background-origin: border-box;"}
  ${({ isComplete, isEarned, isTriple }) =>
    isTriple &&
    isComplete &&
    !isEarned &&
    "background-clip: content-box, border-box;"}
`;
const StampDay = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
`;

const BarContainer = styled.div`
  position: relative;
  //   width: 300px;
  height: 15px;
  @media (max-width: ${MOBILE}) {
    height: 0.60294rem;
  }
`;
const FullBar = styled.div`
  background: ${({ theme, type }) =>
    type === "me" ? theme.primary.normal : theme.grayScaleColor.gray10};
  opacity: ${({ type }) => (type === "me" ? 0.1 : 0.5)};
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 50px;
`;
const BarGraph = styled.div`
  position: absolute;
  background: ${({ bgColor }) =>
    bgColor === null
      ? "#7C8585"
      : bgColor === 1
      ? "#00CA99"
      : bgColor === 0
      ? "#017FC6"
      : "#F11B1B"};
  top: 0;
  left: 0;
  width: ${({ value }) => value}%;
  height: 15px;
  z-index: 2;
  border-radius: 50px;
  @media (max-width: ${MOBILE}) {
    height: 0.60294rem;
  }
`;

// const Badge = styled.span`
//   font-size: 0.875rem;
//   font-weight: 500;
//   padding: 0.25rem 0.75rem;
//   border-radius: 50px;
//   color: ${({ theme, level }) =>
//     level === "good"
//       ? theme.primary.dark
//       : level === "ok"
//       ? theme.colors.success
//       : "#F11B1B"};
//   background: ${({ theme, level }) =>
//     level === "good"
//       ? theme.primary.light
//       : level === "ok"
//       ? "#EEF8FD"
//       : "#FDEFEF"};
// `;

const Button = styled.button`
  border-radius: 0.75rem;
  padding: 1rem 0.25rem;
  text-align: center;
  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  width: 100%;
  color: ${({ color, theme }) => color || theme.grayScaleColor.white};
  background: ${({ bgColor }) => bgColor};
  border: 1px solid ${({ bgColor }) => bgColor};
  &:disabled {
    background: ${({ theme }) => theme.grayScaleColor.gray10};
    border: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
    color: ${({ theme }) => theme.grayScaleColor.gray30};
  }
`;

const Timer = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 3.125rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  gap: 0.25rem;
  width: fit-content;
`;

const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.88rem;
  font-weight: 400;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  line-height: 1.31rem;
  letter-spacing: -0.5px;
  margin-bottom: 0.75rem;
`;

const Time = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.primary.normal};
  //   padding: 0.19rem 0.63rem;
  min-width: 2.88rem;
  //   background: ${({ theme }) => theme.primary.light};
  border-radius: 0.31rem;
  margin: 0 0.31rem;
  text-align: center;
`;

// ===================================
// 마이페이지 스타일 컴포넌트
// ===================================

const Avatar = styled.div`
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.backgroundColor.darkgray20};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Name = styled.p`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Nickname = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const CashContainer = styled.div`
  padding: 1.25rem;
  margin-top: 1.69rem;
  border-radius: 0.9375rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
  display: flex;
  align-items: center;
  & .cash {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #000;
  }
`;

const Text5 = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;
const ExpContainer = styled.div`
  border-radius: 0.9375rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
  padding: 1.25rem;
  margin-top: 0.81rem;
  & .key {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 1.4rem */
    letter-spacing: -0.03125rem;
    color: ${({ theme }) => theme.backgroundColor.darkgray70};
  }
  & .value {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 1.4rem */
    color: ${({ theme }) => theme.primary.normal};
  }
`;
const IconButtonText = styled.p`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
`;

const ModalText1 = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.3rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.primary.normal};
`;

const ModalText2 = styled.p`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  color: #000;
`;

const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ModalButton = styled.button`
  border-radius: 0.75rem;

  border: 1px solid
    ${({ theme, state }) =>
      state === "modified" ? theme.primary.dark : theme.primary.normal};
  background: ${({ theme, state }) =>
    state === "modified" ? theme.grayScaleColor.white : theme.primary.normal};
  padding: 0.69rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${({ theme, state }) =>
    state === "modified" ? theme.primary.dark : theme.grayScaleColor.white};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem; /* 164.286% */
  letter-spacing: -0.035rem;

  cursor: pointer;
  animation: ${({ animation }) =>
    animation
      ? css`
          ${slideUp} 0.5s ease-in-out 0.2s forwards
        `
      : "none"};

  transform: translateY(100%);
  opacity: 0;
`;

const IconButton = ({ icon, text, onClick, style }) => {
  return (
    <FlexRow
      style={{ alignItems: "center", gap: "0.5rem", ...style }}
      onClick={onClick}
    >
      <img src={icon} alt={"icon"} />
      <IconButtonText>{text}</IconButtonText>
    </FlexRow>
  );
};

const Countdown = ({ targetDate, start, style }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  return (
    <TimerContainer style={style}>
      {start ? "⏰ 체크업 마감까지" : "⏰ 다음 체크업까지"}{" "}
      {/* {days > 0 && <Time>{days}일</Time>}{" "}
      {hours > 0 && <Time>{hours}시간</Time>}{" "}
      {minutes > 0 && <Time>{minutes}분</Time>}
      <Time>{seconds}초</Time> */}
      <Time>
        {days > 0 && `${days}일`} {hours > 0 && `${hours}시간`}{" "}
        {minutes > 0 && `${minutes}분`} {seconds}초
      </Time>
      남았어요
    </TimerContainer>
  );
};

const Bar = ({ bgColor, type, value }) => {
  return (
    <BarContainer style={{ position: "relative", flex: 5 }}>
      <FullBar type={type}></FullBar>
      <BarGraph bgColor={bgColor} type={type} value={value} />
    </BarContainer>
  );
};

const Stamp = ({
  isComplete,
  day,
  cash,
  isEarned,
  isTriple = true,
  onClick,
}) => {
  return (
    <FlexColumn style={{ alignItems: "center", gap: "0.5rem" }}>
      <StampContainer
        isComplete={isComplete}
        isEarned={isEarned}
        isTriple={isTriple}
        onClick={onClick}
      >
        {isTriple ? (
          isEarned ? (
            `${cash}원`
          ) : isComplete ? (
            <svg
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.89551 9.28336V22.7794C1.89551 23.3499 2.35751 23.8119 2.92801 23.8119H20.204C20.7745 23.8119 21.2365 23.3499 21.2365 22.7794V9.28336H1.89551Z"
                fill="white"
              />
              <path
                d="M22.514 5.32312H0.616211V9.28337H22.514V5.32312Z"
                fill="white"
              />
              <path
                d="M13.6239 5.32312H9.5061V23.8101H13.6239V5.32312Z"
                fill="white"
              />
              <path
                d="M14.4062 18.5387C13.9587 18.5387 13.5578 18.4631 13.2035 18.3118C12.8492 18.1585 12.5706 17.9441 12.3675 17.6685C12.1666 17.393 12.0609 17.078 12.0505 16.7238H13.2066C13.217 16.8936 13.275 17.0428 13.3806 17.1713C13.4863 17.2997 13.6282 17.3992 13.8064 17.4696C13.9867 17.5401 14.1856 17.5753 14.4031 17.5753C14.6351 17.5753 14.8434 17.5359 15.0277 17.4572C15.2121 17.3785 15.3551 17.2666 15.4566 17.1215C15.5602 16.9765 15.612 16.8118 15.612 16.6274C15.612 16.4347 15.5592 16.2638 15.4535 16.1146C15.3499 15.9655 15.1976 15.8494 14.9967 15.7666C14.7978 15.6837 14.5626 15.6423 14.2912 15.6423H13.7256V14.7597H14.2912C14.515 14.7597 14.7139 14.7203 14.8879 14.6416C15.0619 14.5628 15.1976 14.4541 15.295 14.3153C15.3924 14.1744 15.4411 14.0117 15.4411 13.8273C15.4411 13.6512 15.3986 13.4959 15.3137 13.3612C15.2308 13.2244 15.1117 13.1188 14.9563 13.0442C14.803 12.9696 14.6237 12.9323 14.4186 12.9323C14.2177 12.9323 14.0312 12.9686 13.8592 13.0411C13.6893 13.1115 13.5526 13.2131 13.449 13.3456C13.3454 13.4762 13.2895 13.6274 13.2812 13.7994H12.1811C12.1893 13.4513 12.2929 13.1405 12.4918 12.8671C12.6907 12.5936 12.959 12.3812 13.2967 12.23C13.6365 12.0767 14.0136 12 14.428 12C14.8465 12 15.2163 12.0787 15.5374 12.2362C15.8585 12.3936 16.1072 12.607 16.2833 12.8764C16.4594 13.1436 16.5464 13.4389 16.5443 13.7621C16.5464 13.9983 16.4987 14.2117 16.4014 14.4023C16.3061 14.5929 16.1693 14.7514 15.9911 14.8778C15.815 15.0041 15.611 15.0912 15.3789 15.1388V15.1885C15.6752 15.23 15.929 15.318 16.1403 15.4527C16.3516 15.5874 16.5122 15.7604 16.622 15.9717C16.7318 16.1809 16.7857 16.4192 16.7836 16.6865C16.7857 17.0387 16.6842 17.3557 16.4791 17.6374C16.276 17.9192 15.9932 18.1398 15.6306 18.2994C15.2681 18.4589 14.8599 18.5387 14.4062 18.5387Z"
                fill="#FFCE2B"
              />
              <path
                d="M8.22445 13.6782L9.19095 15.4434L10.1699 13.6782H11.357L9.91816 16.0649L11.3788 18.4517H10.2041L9.19095 16.73L8.19026 18.4517H7L8.45442 16.0649L7.03419 13.6782H8.22445Z"
                fill="#FFCE2B"
              />
              <path
                d="M17.2745 0.0381165C14.3555 0.0381165 11.9895 2.40412 11.9895 5.32312H14.9908C16.515 5.32312 17.8888 4.67737 18.853 3.64662C20.1323 2.27812 19.1488 0.0381165 17.2763 0.0381165L17.2745 0.0381165Z"
                fill="white"
              />
              <path
                d="M5.85582 0.0381165C8.77482 0.0381165 11.1408 2.40412 11.1408 5.32312H8.13957C6.61532 5.32312 5.24157 4.67737 4.27732 3.64662C2.99982 2.27812 3.98332 0.0381165 5.85582 0.0381165Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.89551 9.28336V22.7794C1.89551 23.3499 2.35751 23.8119 2.92801 23.8119H20.204C20.7745 23.8119 21.2365 23.3499 21.2365 22.7794V9.28336H1.89551Z"
                fill="white"
              />
              <path
                d="M22.514 5.32312H0.616211V9.28337H22.514V5.32312Z"
                fill="white"
              />
              <path
                d="M13.6239 5.32312H9.5061V23.8101H13.6239V5.32312Z"
                fill="white"
              />
              <path
                d="M14.4062 18.5387C13.9587 18.5387 13.5578 18.4631 13.2035 18.3118C12.8492 18.1585 12.5706 17.9441 12.3675 17.6685C12.1666 17.393 12.0609 17.078 12.0505 16.7238H13.2066C13.217 16.8936 13.275 17.0428 13.3806 17.1713C13.4863 17.2997 13.6282 17.3992 13.8064 17.4696C13.9867 17.5401 14.1856 17.5753 14.4031 17.5753C14.6351 17.5753 14.8434 17.5359 15.0277 17.4572C15.2121 17.3785 15.3551 17.2666 15.4566 17.1215C15.5602 16.9765 15.612 16.8118 15.612 16.6274C15.612 16.4347 15.5592 16.2638 15.4535 16.1146C15.3499 15.9655 15.1976 15.8494 14.9967 15.7666C14.7978 15.6837 14.5626 15.6423 14.2912 15.6423H13.7256V14.7597H14.2912C14.515 14.7597 14.7139 14.7203 14.8879 14.6416C15.0619 14.5628 15.1976 14.4541 15.295 14.3153C15.3924 14.1744 15.4411 14.0117 15.4411 13.8273C15.4411 13.6512 15.3986 13.4959 15.3137 13.3612C15.2308 13.2244 15.1117 13.1188 14.9563 13.0442C14.803 12.9696 14.6237 12.9323 14.4186 12.9323C14.2177 12.9323 14.0312 12.9686 13.8592 13.0411C13.6893 13.1115 13.5526 13.2131 13.449 13.3456C13.3454 13.4762 13.2895 13.6274 13.2812 13.7994H12.1811C12.1893 13.4513 12.2929 13.1405 12.4918 12.8671C12.6907 12.5936 12.959 12.3812 13.2967 12.23C13.6365 12.0767 14.0136 12 14.428 12C14.8465 12 15.2163 12.0787 15.5374 12.2362C15.8585 12.3936 16.1072 12.607 16.2833 12.8764C16.4594 13.1436 16.5464 13.4389 16.5443 13.7621C16.5464 13.9983 16.4987 14.2117 16.4014 14.4023C16.3061 14.5929 16.1693 14.7514 15.9911 14.8778C15.815 15.0041 15.611 15.0912 15.3789 15.1388V15.1885C15.6752 15.23 15.929 15.318 16.1403 15.4527C16.3516 15.5874 16.5122 15.7604 16.622 15.9717C16.7318 16.1809 16.7857 16.4192 16.7836 16.6865C16.7857 17.0387 16.6842 17.3557 16.4791 17.6374C16.276 17.9192 15.9932 18.1398 15.6306 18.2994C15.2681 18.4589 14.8599 18.5387 14.4062 18.5387Z"
                fill="#FFDB65"
              />
              <path
                d="M8.22445 13.6782L9.19095 15.4434L10.1699 13.6782H11.357L9.91816 16.0649L11.3788 18.4517H10.2041L9.19095 16.73L8.19026 18.4517H7L8.45442 16.0649L7.03419 13.6782H8.22445Z"
                fill="#FFDB65"
              />
              <path
                d="M17.2745 0.0381165C14.3555 0.0381165 11.9895 2.40412 11.9895 5.32312H14.9908C16.515 5.32312 17.8888 4.67737 18.853 3.64662C20.1323 2.27812 19.1488 0.0381165 17.2763 0.0381165L17.2745 0.0381165Z"
                fill="white"
              />
              <path
                d="M5.85582 0.0381165C8.77482 0.0381165 11.1408 2.40412 11.1408 5.32312H8.13957C6.61532 5.32312 5.24157 4.67737 4.27732 3.64662C2.99982 2.27812 3.98332 0.0381165 5.85582 0.0381165Z"
                fill="white"
              />
            </svg>
          )
        ) : isEarned ? (
          `${cash}원`
        ) : isComplete ? (
          <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.89551 9.28336V22.7794C1.89551 23.3499 2.35751 23.8119 2.92801 23.8119H20.204C20.7745 23.8119 21.2365 23.3499 21.2365 22.7794V9.28336H1.89551Z"
              fill="white"
            />
            <path
              d="M22.514 5.32312H0.616211V9.28337H22.514V5.32312Z"
              fill="white"
            />
            <path
              d="M13.6239 5.32312H9.5061V23.8101H13.6239V5.32312Z"
              fill="white"
            />
            <path
              d="M10.5962 16.4482C10.6015 15.1077 10.9556 14.6985 11.5899 14.2948C12.0444 13.9933 12.3985 13.6595 12.3932 13.1642C12.3985 12.6258 11.9863 12.2759 11.4736 12.2705C10.982 12.2759 10.5222 12.6097 10.5011 13.2396H9C9.02643 11.7268 10.1311 11 11.4841 11C12.9588 11 14 11.7806 14 13.1104C14 13.9987 13.5507 14.5585 12.8584 14.9838C12.2611 15.3445 11.9968 15.6945 11.9915 16.4482V16.5666H10.5962V16.4482ZM10.4376 18.0956C10.4271 17.6003 10.8288 17.1965 11.3256 17.2019C11.796 17.1965 12.203 17.6003 12.203 18.0956C12.203 18.5962 11.796 19 11.3256 19C10.8288 19 10.4271 18.5962 10.4376 18.0956Z"
              fill="#00DBB1"
            />
            <path
              d="M17.2745 0.0381165C14.3555 0.0381165 11.9895 2.40412 11.9895 5.32312H14.9908C16.515 5.32312 17.8888 4.67737 18.853 3.64662C20.1323 2.27812 19.1488 0.0381165 17.2763 0.0381165L17.2745 0.0381165Z"
              fill="white"
            />
            <path
              d="M5.85582 0.0381165C8.77482 0.0381165 11.1408 2.40412 11.1408 5.32312H8.13957C6.61532 5.32312 5.24157 4.67737 4.27732 3.64662C2.99982 2.27812 3.98332 0.0381165 5.85582 0.0381165Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.89551 9.28336V22.7794C1.89551 23.3499 2.35751 23.8119 2.92801 23.8119H20.204C20.7745 23.8119 21.2365 23.3499 21.2365 22.7794V9.28336H1.89551Z"
              fill="white"
            />
            <path
              d="M22.514 5.32312H0.616211V9.28337H22.514V5.32312Z"
              fill="white"
            />
            <path
              d="M13.6239 5.32312H9.5061V23.8101H13.6239V5.32312Z"
              fill="white"
            />
            <path
              d="M10.5962 16.4482C10.6015 15.1077 10.9556 14.6985 11.5899 14.2948C12.0444 13.9933 12.3985 13.6595 12.3932 13.1642C12.3985 12.6258 11.9863 12.2759 11.4736 12.2705C10.982 12.2759 10.5222 12.6097 10.5011 13.2396H9C9.02643 11.7268 10.1311 11 11.4841 11C12.9588 11 14 11.7806 14 13.1104C14 13.9987 13.5507 14.5585 12.8584 14.9838C12.2611 15.3445 11.9968 15.6945 11.9915 16.4482V16.5666H10.5962V16.4482ZM10.4376 18.0956C10.4271 17.6003 10.8288 17.1965 11.3256 17.2019C11.796 17.1965 12.203 17.6003 12.203 18.0956C12.203 18.5962 11.796 19 11.3256 19C10.8288 19 10.4271 18.5962 10.4376 18.0956Z"
              fill="#D8D8D8"
            />
            <path
              d="M17.2745 0.0381165C14.3555 0.0381165 11.9895 2.40412 11.9895 5.32312H14.9908C16.515 5.32312 17.8888 4.67737 18.853 3.64662C20.1323 2.27812 19.1488 0.0381165 17.2763 0.0381165L17.2745 0.0381165Z"
              fill="white"
            />
            <path
              d="M5.85582 0.0381165C8.77482 0.0381165 11.1408 2.40412 11.1408 5.32312H8.13957C6.61532 5.32312 5.24157 4.67737 4.27732 3.64662C2.99982 2.27812 3.98332 0.0381165 5.85582 0.0381165Z"
              fill="white"
            />
          </svg>
        )}
      </StampContainer>
      <StampDay>{day}</StampDay>
    </FlexColumn>
  );
};
const BlockCard = ({ block, isComplete }) => {
  const navigate = useNavigate();

  return (
    <BlockCardContainer>
      <h2>{convertPermavac(block.permavac[0])} 올리는</h2>
      <h1>{block.title}</h1>
      <h3>소요시간 : {block.time}분</h3>
      {/* {!isComplete && (
        <div className="cash">
          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
            alt={"cash img"}
          />
          100원
        </div>
      )} */}
      <img className="block" src={`${block.iconUrl}`} alt={"block icon"} />
      <button
        className={isComplete ? `start__btn complete` : `start__btn`}
        onClick={() => navigate(`/blocks/${block.number}`)}
      >
        {isComplete ? "트레이닝 완료" : "지금 트레이닝 시작"}
      </button>
    </BlockCardContainer>
  );
};

const Training = () => {
  return (
    <Section>
      <Heading2>마음 트레이닝</Heading2>
    </Section>
  );
};

function HomeMobile() {
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [block, setBlock] = useState();
  const [welcomeMsg, setWelcomeMsg] = useState("");
  const [wellBeingScore, setWellBeingScore] = useState();
  const [banners, setBanners] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [rollingFinish, setRollingFinish] = useState(false);
  const [blockReward, setBlockReward] = useState(10);
  const [selectedStamp, setSelectedStamp] = useState(null);
  const [isExtraReward, setIsExtraReward] = useState(false);
  const tabIndex = useOutletContext();

  const { width } = useWindowSize();
  const documentHeight = useDocumentHeight();
  const scrollCenter = useScrollCenter();

  const navigate = useNavigate();
  const t = new Date();
  const todayDay = t.getDay();
  const [now, setNow] = useState(t);

  const friday = new Date(new Date(getThisWeekFriday()).setHours(13));
  const surveyEnd = new Date(new Date(friday).setDate(friday.getDate() + 1));
  const [isSurveyPeriod, setIsSurveyPeriod] = useState(
    now
      ? now.getTime() >= friday.getTime() && now.getTime() < surveyEnd.getTime()
      : t.getTime() >= friday.getTime() && t.getTime() < surveyEnd.getTime()
  );
  // const [days, hours, minutes, seconds] = useCountdown(
  //   now &&
  //     now.getTime() >= friday.getTime() &&
  //     now.getTime() < surveyEnd.getTime()
  //     ? surveyEnd
  //     : now &&
  //       now.getTime() < friday.getTime() &&
  //       now.getTime() > surveyEnd.getTime()
  //     ? friday
  //     : t &&
  //       t.getTime() >= friday.getTime() &&
  //       t.getTime() < surveyEnd.getTime()
  //     ? surveyEnd
  //     : friday
  // );

  const compareScore = (me, company) => {
    if (me > company - company * 0.2 && me < company + company * 0.2) {
      return 0;
    } else if (company * 1.2 > me) {
      return -1;
    } else {
      return 1;
    }
  };

  const onStampClick = async (e, index) => {
    setSelectedStamp(index);
    if (
      auth.user.blockInWeek.slice(1, 6)[index] === 1 &&
      auth.user.blockCashInWeek.slice(1, 6)[index] === 0
    ) {
      setShowRewardModal(true);
      setIsExtraReward(
        index + 1 === 5 &&
          auth.user.blockInWeek.slice(1, 6).every((element) => element === 1)
      );
      // 블록 캐시 api 호출
      //   - 블록 리워드 가져오기
      const res = await axiosPrivate.post("/reward", {
        day: index + 1,
        isExtra:
          index + 1 === 5 &&
          auth.user.blockInWeek.slice(1, 6).every((element) => element === 1),
      });
      setAuth({
        accessToken: auth.accessToken,
        user: {
          ...auth.user,
          point: res.data.data.point,
          blockCashInWeek: res.data.data.blockCashInWeek,
        },
      });

      setBlockReward(res.data.data.cash);
    }
  };
  useEffect(() => {
    const fetch = async () => {
      const homeResponse = await axiosPrivate.get(`/home`);

      setBanners(homeResponse.data.data.banners);
      setBlock(homeResponse.data.data.block);
      setWellBeingScore({
        // myWellBeingScore: 80,
        myWellBeingScore: homeResponse.data.data.myWellBeingScore,
        companyWellBeingScore: homeResponse.data.data.companyWellBeingScore,
        allWellBeingScore: homeResponse.data.data.allWellBeingScore,
      });
      setWelcomeMsg(homeResponse.data.data.welcomeMsg);
      setIsLoading(false);
    };

    if (isAuthEmpty(auth)) {
      return navigate("/login");
    }
    fetch();
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const t = new Date();
  //     setNow(t);
  //     if (
  //       t.getTime() >= friday.getTime() &&
  //       t.getTime() < surveyEnd.getTime()
  //     ) {
  //       setIsSurveyPeriod(true);
  //     } else {
  //       setIsSurveyPeriod(false);
  //     }
  //   }, [1000]);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [now]);
  if (isLoading) {
    return <div />;
  }

  return (
    <Container>
      <Helmet>
        <meta name="theme-color" content="#fff" />
      </Helmet>
      {/* <TapbarContainer>
        <Tap selected={tabIndex === 0} onClick={(e) => onTabClick(e, 0)}>
          홈
        </Tap>
        <Tap selected={tabIndex === 1} onClick={(e) => onTabClick(e, 1)}>
          마이페이지
        </Tap>
      </TapbarContainer> */}

      <>
        <Section>
          <FlexRow
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "1.5rem",
            }}
          >
            <Heading1>{lineBreaker(welcomeMsg)}</Heading1>
            {/* <Heading4>
            자세히 보기 <RightCaret />
          </Heading4> */}
          </FlexRow>
          <BlockCard
            block={block}
            isComplete={auth.user.blockInWeek[todayDay]}
          />
          <Heading3>연속 5일 트레이닝 완료하면 캐시x3 ✨</Heading3>
          <FlexRow style={{ justifyContent: "space-evenly" }}>
            {["월", "화", "수", "목", "금"].map((item, index) => (
              <Stamp
                key={index}
                isComplete={auth.user.blockInWeek.slice(1, 6)[index]}
                isTriple={
                  index === 4 &&
                  auth.user.blockInWeek
                    .slice(1, todayDay < 5 ? todayDay + 1 : todayDay)
                    .every((element) => element === 1)
                }
                isEarned={
                  auth.user.blockInWeek.slice(1, 6)[index] === 1 &&
                  auth.user.blockCashInWeek.slice(1, 6)[index] !== 0
                }
                cash={auth.user.blockCashInWeek.slice(1, 6)[index]}
                day={item}
                onClick={(e) => onStampClick(e, index)}
              />
            ))}
          </FlexRow>
        </Section>
        <Divider />
        <Section style={{ marginBottom: "1rem" }}>
          <Slide arrows={false} transitionDuration={400}>
            {banners.map((item, index) => (
              <BannerImage
                key={index}
                src={item.url}
                alt={`banner img ${index}`}
                onClick={() => {
                  navigate(item.redirectTo);
                }}
              />
            ))}
          </Slide>
          <FlexRow
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "1rem",
              marginTop: "2.75rem",
            }}
          >
            <Heading2>최근 내 마음 점수</Heading2>
            {/* <Heading4>
            자세히 보기 <RightCaret />
          </Heading4> */}
          </FlexRow>
          <Text1>{wellBeingScore.myWellBeingScore}점</Text1>
          <Badge
            bgColor={compareScore(
              wellBeingScore.myWellBeingScore,
              wellBeingScore.companyWellBeingScore
            )}
          >
            {compareScore(
              wellBeingScore.myWellBeingScore,
              wellBeingScore.companyWellBeingScore
            ) === 1
              ? `우리 조직 평균 대비 ${
                  wellBeingScore.myWellBeingScore -
                  wellBeingScore.companyWellBeingScore
                }점 높아요`
              : compareScore(
                  wellBeingScore.myWellBeingScore,
                  wellBeingScore.companyWellBeingScore
                ) === -1
              ? `우리 조직 평균 대비 ${
                  wellBeingScore.companyWellBeingScore -
                  wellBeingScore.myWellBeingScore
                }점 낮아요`
              : `우리 조직 평균과 비슷해요`}
          </Badge>
          <FlexColumn
            style={{
              marginTop: "2.25rem",
              marginBottom: "2rem",
              gap: "1.12rem",
            }}
          >
            <FlexRow
              style={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text2>내 점수</Text2>
              <Bar
                bgColor={compareScore(
                  wellBeingScore.myWellBeingScore,
                  wellBeingScore.companyWellBeingScore
                )}
                value={wellBeingScore.myWellBeingScore}
              />
              <Text3>{wellBeingScore.myWellBeingScore}</Text3>
            </FlexRow>
            <FlexRow
              style={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text2>우리 조직 평균</Text2>
              <Bar
                bgColor={null}
                value={wellBeingScore.companyWellBeingScore}
              />
              <Text3>{wellBeingScore.companyWellBeingScore}</Text3>
            </FlexRow>
            <FlexRow
              style={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text2>전체 평균</Text2>
              <Bar bgColor={null} value={wellBeingScore.allWellBeingScore} />
              <Text3>{wellBeingScore.allWellBeingScore}</Text3>
            </FlexRow>
          </FlexColumn>
          <FlexColumn style={{ gap: "0.5rem", marginBottom: "1.5rem" }}>
            <Button
              bgColor="#00DBB1"
              onClick={() => navigate(`/blocks/${block.number}`)}
            >
              내 마음 점수 높이기
            </Button>
            <Button
              bgColor="#111"
              disabled={false}
              onClick={() => navigate("/checkup/v2")}
            >
              이번 주 내 마음 체크업 하기
            </Button>
          </FlexColumn>
          {/* <Timer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M13.1266 13.8446L13.8317 13.1395L10.5192 9.80592V5.63465H9.57373V10.1939L13.1266 13.8446ZM10.0014 17.9167C8.91499 17.9167 7.8914 17.7089 6.93063 17.2933C5.96985 16.8778 5.12965 16.3103 4.41002 15.591C3.69038 14.8717 3.1227 14.0319 2.70696 13.0715C2.29122 12.1112 2.08335 11.0878 2.08335 10.0014C2.08335 8.91501 2.29113 7.89142 2.70669 6.93065C3.12224 5.96987 3.68967 5.12967 4.40898 4.41004C5.1283 3.6904 5.96813 3.12272 6.92848 2.70698C7.88884 2.29124 8.91222 2.08337 9.99861 2.08337C11.085 2.08337 12.1086 2.29115 13.0694 2.70671C14.0302 3.12226 14.8704 3.68969 15.59 4.409C16.3096 5.12832 16.8773 5.96815 17.293 6.9285C17.7088 7.88886 17.9166 8.91224 17.9166 9.99863C17.9166 11.085 17.7089 12.1086 17.2933 13.0694C16.8778 14.0302 16.3103 14.8704 15.591 15.59C14.8717 16.3096 14.0319 16.8773 13.0715 17.2931C12.1112 17.7088 11.0878 17.9167 10.0014 17.9167ZM10 16.9712C11.9177 16.9712 13.559 16.2887 14.9239 14.9239C16.2887 13.559 16.9711 11.9178 16.9711 10C16.9711 8.08228 16.2887 6.44099 14.9239 5.07615C13.559 3.7113 11.9177 3.02887 10 3.02887C8.08227 3.02887 6.44097 3.7113 5.07613 5.07615C3.71128 6.44099 3.02885 8.08228 3.02885 10C3.02885 11.9178 3.71128 13.559 5.07613 14.9239C6.44097 16.2887 8.08227 16.9712 10 16.9712Z"
              fill="#7C8585"
            />
          </svg>{" "}
          {isSurveyPeriod ? (
            <p>
              마감까지{" "}
              <span style={{ fontWeight: 700 }}>
                {hours > 0 && `${hours}시간`}
              </span>{" "}
              남았어요!
            </p>
          ) : (
            <p>
              마음 체크업까지{" "}
              <span style={{ fontWeight: 700 }}>
                {days > 0 && `${days}일`} {hours > 0 && `${hours}시간`}
              </span>{" "}
              남았어요!
            </p>
          )}
        </Timer> */}
          <Text4 style={{ marginBottom: "2rem" }}>
            체크업은 한 주 마무리를 위해 금요일에만 진행해요
          </Text4>
        </Section>
      </>

      {showRewardModal && rollingFinish && (
        <ReactConfetti
          height={documentHeight}
          width={width}
          initialVelocityY={10}
          initialVelocityX={5}
          confettiSource={{ x: width / 2, y: scrollCenter }}
        />
      )}
      {showRewardModal && (
        <Modal showing={true} width={"18.75rem"} padding={"2.19rem 1.87rem"}>
          <FlexColumn style={{ alignItems: "center", overflow: "hidden" }}>
            <ModalText1>
              {isExtraReward
                ? `연속 5회 달성`
                : `${selectedStamp + 1}회차 달성`}
            </ModalText1>
            <ModalText2 style={{ marginBottom: "1rem" }}>
              {isExtraReward
                ? "연속 5회 특별 보상 x3 🥳"
                : "트레이닝 보상 완료 🥳"}
            </ModalText2>
            <FlexRow
              style={{
                gap: "0.37rem",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "1.875rem", height: "1.875rem" }}
                src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                alt={"cash icon"}
              />
              <RandomNumberScroll
                setRollingFinish={setRollingFinish}
                fixedNumber={blockReward}
              />
            </FlexRow>
            <FlexRow
              style={{ width: "100%", gap: "0.62rem", marginTop: "2rem" }}
            >
              <ModalButton
                state={"modified"}
                onClick={() => {
                  navigate("/mypage");
                }}
                animation={rollingFinish}
              >
                마이페이지
              </ModalButton>
              <ModalButton
                onClick={(e) => {
                  setShowRewardModal(false);
                  setRollingFinish(false);
                }}
                animation={rollingFinish}
              >
                확인
              </ModalButton>
            </FlexRow>
          </FlexColumn>
        </Modal>
      )}
    </Container>
  );
}

export default HomeMobile;
