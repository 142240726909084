import styled from "styled-components";

const Container = styled.input`
  width: 20.56rem;
  height: 3.75rem;
  text-align: center;
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-size: 1.13rem;
  border-radius: 0.31rem;
  background-color: ${({ theme }) => theme.primary.normal};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.primary.hover};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.grayScaleColor.gray10};
    color: ${({ theme }) => theme.grayScaleColor.gray30};
  }
  transition: background-color 0.3s;
  // To adapt css in iphone
  -webkit-appearance: none;
`;

const SubmitButton = ({ value, disabled, style, onClick }) => {
  return (
    <Container
      type="submit"
      value={value}
      disabled={disabled}
      style={style}
      onClick={onClick}
    />
  );
};

export default SubmitButton;
