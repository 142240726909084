import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Youtube from "react-youtube";
import { motion } from "framer-motion";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { converDate } from "../../utils/convertDate";
import generateLevelStar from "../../utils/generateLevelStar";
import Slider from "./Slider";
import Toggle from "./Toggle";
import Tooltip from "./Tooltip";
import lineBreaker from "../../utils/lineBreaker";
import { useMediaQuery } from "react-responsive";
import FlexRow from "../../components/FlexRow";
import LikeButton from "../../components/block/LikeButton";
import axios from "../../api/axios";
import MoreButton from "../../components/block/MoreButton";
import ModalBackground from "../../components/ModalBackground";
import DeleteModal from "../../components/block/DeleteModal";
import DeleteSuccessModal from "../../components/block/DeleteSuccessModal";
import shuffleArray from "../../utils/shuffleArray";

const Container = styled.div``;

const SideMenu = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 8.5rem;
  left: calc(50% - 37rem);
  font-family: Montserrat;
  z-index: 1;
`;

const SideMenuItem = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 1.25rem;
  cursor: pointer;
  color: ${({ theme }) => theme.grayScaleColor.white};
  opacity: 0.5;
  display: flex;
  align-items: center;
`;

const SideMenuIndicator = styled.div`
  width: 0.81rem;
  height: 0.81rem;
  border: 4px solid currentColor;
  border-radius: 50%;
`;
const SideMenuText = styled.span`
  margin-left: 0.94rem;
`;

const Section = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: ${({ theme, bgColor }) =>
    bgColor === "primary" ? theme.primary.normal : "#EDF3F3"};
  &:nth-child(3) {
    @media (max-width: 425px) {
      height: 100%;
    }
  }
  &:nth-child(3) > div {
    @media (max-width: 425px) {
      height: auto;
    }
  }
  &:nth-child(3) {
    ${({ forLeader }) => forLeader && "height: auto; padding-bottom: 50px;"}
  }
  &:nth-child(4) {
    height: 100%;
  }
`;
const Wrapper = styled.div`
  width: 55.63rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: inherit;
  position: relative;
  min-width: 375px;
  @media (max-width: 425px) {
    padding: 3.13rem 30px;
  }
`;

const BackgroundLogo = styled.img`
  position: absolute;
  top: -3.31rem;
  right: -8.56rem;
  width: 31.06rem;
  height: 30.5rem;
  @media (max-width: 425px) {
    width: 11.94rem;
    height: 11.75rem;
    top: 17.81rem;
    right: 1.5rem;
  }
`;

const Logo = styled.img`
  margin-top: 7.44rem;
  width: 4rem;
  height: 3.9rem;
  @media (max-width: 425px) {
    width: 4.31rem;
    height: 4.19rem;
    margin-top: 1.44rem;
  }
`;
const Title = styled.h1`
  font-size: 4.38rem;
  margin-top: 8.5rem;
  margin-bottom: 1.75rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-weight: 800;
  letter-spacing: -1px;
  line-height: normal;
  @media (max-width: 425px) {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 1.69rem;
  }
`;

const SubTitle = styled.p`
  font-size: 1.75rem;
  line-height: 2.56rem;
  font-weight: 400;
  color: ${({ theme }) => theme.grayScaleColor.white};
  margin-bottom: 3.38rem;
  white-space: pre-wrap;
  letter-spacing: -1px;
  @media (max-width: 425px) {
    font-size: 1.13rem;
    margin-bottom: 4.25rem;
    letter-spacing: -0.5px;
    line-height: 1.56rem;
    word-break: keep-all;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Button = styled.div`
  border: 1px solid white;
  background: ${({ theme }) => theme.primary.normal};
  display: flex;
  justify-content: center;
  border-radius: 3.13rem;
  color: white;
  font-size: 0.94rem;
  line-height: 1.44rem;
  letter-spacing: -0.04em;
  padding: 0.63rem 1.24rem;
  margin-bottom: 1.19rem;
  @media (max-width: 425px) {
  }
`;

const ScrollDownButtonContainer = styled.div`
  color: ${({ theme }) => theme.grayScaleColor.white};
  display: flex;
  justify-content: center;
  margin-top: 1.94rem;
  ${({ isAuthEmpty }) =>
    isAuthEmpty &&
    `position: absolute; bottom: 90px; margin:0 auto; left: 0; right:0;`}

  @media (max-width: 425px) {
    position: absolute;
    bottom: 7rem;
    left: 0;
    right: 0;
  }
`;

const ScrollDownButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.81rem;
  line-height: 1.44rem;
  font-weight: 600;
  cursor: pointer;
`;

const ScrollDownButtonText = styled.span`
  margin-bottom: 0.5rem;
`;

const bounceAnimation = keyframes`
0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}
40% {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
}
60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
}
`;

const SymbolImage = styled.img`
  width: 20%;
  max-width: 252px;
  //   border: 1px solid black;
  animation: ${bounceAnimation} 1.5s infinite;
`;

const GuideDescription = styled.p`
  font-size: 1.4rem;
  line-height: 2.81rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 3.88rem;
  white-space: pre-wrap;
  letter-spacing: -1px;
  @media (max-width: 425px) {
    font-size: 1rem;
    line-height: 1.75rem;
    word-break: keep-all;
  }
`;
const GuideBold = styled.span`
  font-weight: 500;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 55.63rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 3.81rem 3.63rem;
  overflow-y: auto;
  // border: 1px solid black;
  @media (max-width: 425px) {
    padding: 3.81rem 1.88rem;
  }
`;

const ImageContent = styled.div`
  width: 55.5rem;
  height: 31.19rem;
  border-radius: 0.94rem;
  border: 1px solid black;
  margin-bottom: 4.45rem;
`;

const ContentQuestion = styled.h3`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 1.38rem;
  letter-spacing: -1px;
  @media (max-width: 425px) {
    font-size: 1.25rem;
    line-height: 1.88rem;
    margin-bottom: 1.5rem;
  }
`;

const ContentQuestionHighlight = styled.span`
  color: ${({ theme }) => theme.primary.normal};
  margin-right: 0.63rem;
`;
const InputForm = styled.form`
  width: 560px;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;

const TextAreaContainer = styled.div`
  position: relative;
  margin-bottom: 0.5rem;
  width: 100%;
  ${({ isAuthEmpty }) => isAuthEmpty && "margin-top: 2.5em"};
  @media (max-width: 425px) {
    margin-top: 1rem;
  }
`;
const Textarea = styled.textarea`
  width: 100%;
  height: 7.25rem;
  background: ${({ theme }) => theme.primary.superLight};
  border-radius: 0.94rem;
  border: 2px solid ${({ theme }) => theme.primary.superLight};
  padding: 1.06rem 1.88rem;
  font-size: 1rem;
  line-height: 1.5rem;
  resize: none;
  // outline-color: ${({ theme }) => theme.primary.normal};
  &::placeholder {
    color: #888888;
  }
  &:focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.primary.normal};
  }
`;

const TextareaTriangle = styled.div`
  // width: 0;
  // height: 0;
  // border-left: 1.31rem solid transparent;
  // border-right: 1.31rem solid transparent;
  // border-bottom: 1.31rem solid ${({ theme }) => theme.primary.superLight};
  // border-top-left-radius: 0.31rem;
  // border-top-right-radius: 0.31rem;
  // margin-left: 2.06rem;
  // top: -20px;
  // position: absolute;
  // z-index: 0;
  display: block;
  height: 2.63rem;
  width: 2.63rem;
  background-color: ${({ theme }) => theme.primary.superLight};
  // border: inherit;
  border: 2px solid
    ${({ theme, clicked }) =>
      clicked ? theme.primary.normal : theme.primary.superLight};
  position: absolute;
  top: -1.2rem;
  left: 2.06rem;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(135deg);
  border-radius: 0 0 0 0.31rem;
`;

const HelpText = styled.p`
  font-size: 0.81rem;
  font-weight: 400;
  line-height: 1.44rem;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
`;

const TextLengthCheck = styled.p`
  font-size: 0.75rem;
  line-height: 1.19rem;
  font-weight: 300;
  text-align: right;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  font-family: Montserrat;
  letter-spacing: -1px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* margin-top: 0.19rem; */
  margin-bottom: 2.31rem;
  // border: 1px solid black;
  position: relative;
`;
const Input = styled.input`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 1rem;
  width: 100%;
`;
const SubmitButton = styled.button`
  border: none;
  background: ${({ theme }) => theme.primary.normal};
  margin-top: 0.5rem;
  padding: 0.38rem 2.19rem;
  font-size: 0.94rem;
  font-weight: 500;
  line-height: 1.44rem;
  border-radius: 0.31rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  cursor: pointer;
  &:disabled {
    background: ${({ theme }) => theme.grayScaleColor.gray10};
    color: ${({ theme }) => theme.grayScaleColor.gray30};
    cursor: not-allowed;
  }
  ${({ isAuthEmpty }) => isAuthEmpty && "margin-left: auto; margin-right: 0;"}
  @media (max-width: 425px) {
    padding: 0.75rem 2.19rem;
    ${({ isAuthEmpty }) => isAuthEmpty && "width: 100%;"}
  }
`;

const ReplyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // border: 1px solid black;

  overflow-y: auto;
`;

const ReplyHeader = styled.h3`
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 1.31rem;
  letter-spacing: -1px;
`;

const ReplyItem = styled.div`
  width: 100%;
  padding: 1rem 1.38rem;
  background: ${({ theme, isPublic }) =>
    isPublic ? theme.primary.superLight : theme.grayScaleColor.gray5};
  border-radius: 0.63rem;
  margin-bottom: 0.63rem;
`;

const ReplyItemTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.19rem;
  position: relative;
`;

const ReplyItemInfo = styled.div`
  display: flex;
  align-items: center;
`;
const ReplyName = styled.span`
  font-size: 0.94rem;
  line-height: 1.44rem;
  color: #888;
  // ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 400;
`;

const ReplyDate = styled.span`
  font-size: 0.69rem;
  line-height: 1.44rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  font-family: Montserrat;
  margin-left: 0.5rem;
`;

const ReplyItemPublicButton = styled.button`
  border-radius: 6.25rem;
  width: 3.94rem;
  height: 1.44rem;
  border: 1px solid
    ${({ theme, isPublic }) =>
      isPublic ? theme.primary.normal : theme.grayScaleColor.gray30};
  color: ${({ theme, isPublic }) =>
    isPublic ? theme.primary.normal : theme.grayScaleColor.gray30};
  font-size: 0.63rem;
  line-height: 1.19rem;
  // padding: 0.13rem 0.63rem;
  background: none;
  letter-spacing: -0.04em;
  cursor: pointer;
`;

const ReplyComment = styled.p`
  font-size: 1rem;
  line-height: 1.44rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  font-weight: 500;
  // letter-spacing: -1px;
  margin-bottom: 0.38rem;
  word-break: keep-all;
  word-wrap: break-word;
`;

const VideoContainer = styled.div`
  // position: relative;
  margin-bottom: 3.31rem;
  // width: 100%;
  // height: 27.19rem;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  @media (max-width: 425px) {
    width: 315px;
  }
`;

const TipBox = styled.div`
  margin-top: 2.5rem;
  padding: 1.5rem 1.75rem;
  border-radius: 0.9375rem;
  background: white;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  align-self: flex-start;
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

const CardFlip = ({ isFront, flip, prevIndex, currentIndex, images }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });

  return (
    <>
      <motion.div
        style={{
          width: "100%",
          height: isMobile ? "13rem" : "27rem",
          position: "relative",
        }}
        transition={{ duration: 0.5 }}
        animate={{ rotateY: flip ? 0 : 180 }}
      >
        <motion.div
          style={{ width: "100%" }}
          transition={{ duration: 0.5 }}
          animate={{ rotateY: flip ? 0 : 180 }}
          className="Card"
        >
          <motion.div
            transition={{ duration: 0.5 }}
            animate={{ rotateY: flip ? 0 : 180 }}
            className="front"
            style={{
              width: "100%",
              position: "absolute",
              backfaceVisibility: "hidden",
            }}
          >
            <img
              style={{ width: "100%" }}
              src={
                isFront
                  ? images[currentIndex]
                  : images[
                      prevIndex === 0
                        ? 0
                        : currentIndex === 1
                        ? images.length - currentIndex
                        : currentIndex - 1
                    ]
              }
              alt="front"
            />
          </motion.div>
          <motion.div
            initial={{ rotateY: 180 }}
            animate={{ rotateY: flip ? 180 : 0 }}
            // style={{ display: flip ? "none" : "block" }}
            transition={{ duration: 0.5 }}
            className="back"
            style={{
              width: "100%",
              position: "absolute",
              backfaceVisibility: "hidden",
            }}
          >
            <img
              style={{ width: "100%" }}
              src={isFront ? images[currentIndex - 1] : images[currentIndex]}
              alt="back"
            />
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  );
};
const FlipButton = styled.button`
  padding: 1rem 1.75rem;
  border: 1px solid ${({ theme }) => theme.primary.normal};
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 0.625rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.primary.normal};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  align-self: center;
  cursor: pointer;
`;

// const NicknameInput = styled.input`
//   margin-bottom: 1rem;
//   border-radius: 0.31rem;
//   // border: none;
//   border: 1px solid
//     ${({ theme, nicknameFocus, nickname }) =>
//       !nicknameFocus && nickname.length > 0
//         ? "#f4faf9"
//         : theme.grayScaleColor.gray20};
//   padding-left: 1.44rem;
//   font-size: 0.94rem;
//   // height: 2.94rem;
//   padding-top: 0.75rem;
//   padding-bottom: 0.75rem;
//   &:focus {
//     outline: none;
//     border: 1px solid ${({ theme }) => theme.primary.normal};
//   }
//   background: ${({ nickname, nicknameFocus }) =>
//     nicknameFocus ? "none" : nickname.length > 0 ? "#f4faf9" : "none"};
// `;

const Prototype = () => {
  const NICKNAME = "익명의 맘핏러";
  const [isLoading, setIsLoading] = useState(true);
  const [block, setBlock] = useState();
  const [comment, setComment] = useState("");
  const [commentFocus, setCommentFocus] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [nickname, setNickname] = useState("");
  const [nicknameFocus, setNicknameFocus] = useState(false);
  //   const [updatedComment, setUpdatedComment] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [isLike, setIsLike] = useState(false);
  const [isLikeClicked, setIsLikeClicked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [comments, setComments] = useState([]);
  const [commentLikes, setCommentLikes] = useState();
  const [currentY, setCurrentY] = useState(0);
  const [visible, setVisible] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
  const [deleteComment, setDeleteComment] = useState(null);

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const { id } = useParams();
  const [indicators, setIndicators] = useState([1, 0, 0]);
  const introMenuItemRef = useRef();
  const guideMenuItemRef = useRef();
  const actionMenuItemRef = useRef();
  const introRef = useRef();
  const guideRef = useRef();
  const actionRef = useRef();
  const videoThumbnailRef = useRef();
  const iframeRef = useRef();
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });
  const imagesRef = useRef([]);
  const [flip, setFlip] = useState(true);
  const [prevIndex, setPrevIndex] = useState(0);
  const [isFront, setIsFront] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleMenuClick = (index) => {
    switch (index) {
      case 0: {
        introRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
        // document.documentElement.offsetTop = introRef.current.offsetTop;
        return;
      }
      case 1: {
        guideRef.current.scrollIntoView({
          behavior: "smooth",
          block: isMobile ? "start" : "start",
          inline: "nearest",
        });
        // document.documentElement.offsetTop = guideRef.current.offsetTop;
        return;
      }
      case 2: {
        actionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });

        return;
      }
      default:
        break;
    }
  };

  const handleScrollDownClick = () => {
    handleMenuClick(1);
    // guideRef.current.scrollIntoView({
    //   behavior: "smooth",
    //   block: "end",
    //   inline: "end",
    // });
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let response = await axiosPrivate.post("/training/comment", {
      blockId: block._id,
      comment: comment.replaceAll("<br>", "\r\n"),
      nickname: NICKNAME,
    });

    setComments(response.data.comments);
    setComment("");
    setVisible(true);
    setSubmitClicked(true);
    setTimeout(() => {
      setVisible(false);
      setSubmitClicked(false);
    }, 2000);
  };

  const handleCommentLikeClick = async (comment) => {
    let response = await axios.patch(`/training/comment`, {
      commentId: comment._id,
      isLike: localStorage.getItem(comment._id) ? false : true,
    });

    if (localStorage.getItem(comment._id)) {
      localStorage.removeItem(comment._id);
    } else {
      localStorage.setItem(comment._id, true);
    }

    const copyComments = [];
    comments.forEach((c) => {
      if (c._id === response.data.comment._id) {
        copyComments.push(response.data.comment);
      } else {
        copyComments.push(c);
      }
    });

    setComments(copyComments);
  };

  const isAuthEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleDeleteClick = (comment) => {
    setShowDeleteModal(true);
    setDeleteComment(comment);
  };

  const handleCloseModal = () => {
    setDeleteComment(null);
    setShowDeleteModal(false);
  };
  const handleCloseDeleteSuccessModal = () => {
    setShowDeleteSuccessModal(false);
  };

  const handleDeleteComment = async () => {
    await axios.delete(`/training/comment`, {
      data: {
        commentId: deleteComment._id,
      },
    });
    const target = [];
    comments.forEach((c) => {
      if (c._id !== deleteComment._id) {
        target.push(c);
      }
    });
    console.log("delete success", target);
    setComments(target);
    setShowDeleteModal(false);
    setShowDeleteSuccessModal(true);
  };

  useEffect(() => {
    console.log("useEffect called");
    const fetchPublicData = async () => {
      const blockResponse = await axiosPrivate.get(`/block?number=${id}`);
      const commentResponse = await axiosPrivate.get(
        `/training/comment?id=${blockResponse.data._id}`
      );
      // setBlock(blockResponse.data);
      setBlock(blockResponse.data);
      imagesRef.current = [
        blockResponse.data.imageUrls[0],
        ...shuffleArray(blockResponse.data.imageUrls.slice(1)),
      ];
      setComments(commentResponse.data);
      setIsLoading(false);
    };
    fetchPublicData();

    const handleScroll = (event) => {
      setCurrentY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const currentLocation = window.location.href;
    if (currentLocation.includes("#")) {
      const scrollTo = currentLocation.slice(currentLocation.indexOf("#") + 1);
      if (scrollTo === "action") {
        actionRef?.current?.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "nearest",
        });
        // setCommentFocus(true);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (Math.floor(actionRef.current?.getBoundingClientRect().top) <= 0) {
      introMenuItemRef.current.style = "opacity: 0.5; color: #D9D9D9;";
      guideMenuItemRef.current.style = "opacity: 0.5; color:#D9D9D9;";
      actionMenuItemRef.current.style = "opacity: 1; color: #00DBB1";
      setIndicators([0, 0, 1]);
    } else if (Math.floor(guideRef.current?.getBoundingClientRect().top) <= 0) {
      introMenuItemRef.current.style = "opacity: 0.5; color: #D9D9D9;";
      guideMenuItemRef.current.style = "opacity: 1; color: #00DBB1";
      actionMenuItemRef.current.style = "opacity: 0.5; color: #D9D9D9";
      setIndicators([0, 1, 0]);
    } else if (Math.floor(introRef.current?.getBoundingClientRect().top) <= 0) {
      introMenuItemRef.current.style = "opacity: 1;";
      guideMenuItemRef.current.style = "opacity: 0.5; color: white;";
      actionMenuItemRef.current.style = "opacity: 0.5; color: white";
      setIndicators([1, 0, 0]);
    } else {
    }
  }, [currentY]);

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <SideMenu>
        <SideMenuItem
          style={{ opacity: indicators[0] === 1 ? 1 : 0.5 }}
          ref={introMenuItemRef}
          onClick={() => handleMenuClick(0)}
        >
          <SideMenuIndicator style={{ opacity: indicators[0] === 1 ? 1 : 0 }} />
          <SideMenuText>INTRO</SideMenuText>
        </SideMenuItem>
        <SideMenuItem ref={guideMenuItemRef} onClick={() => handleMenuClick(1)}>
          <SideMenuIndicator style={{ opacity: indicators[1] === 1 ? 1 : 0 }} />
          <SideMenuText>GUIDE</SideMenuText>
        </SideMenuItem>
        <SideMenuItem
          ref={actionMenuItemRef}
          onClick={() => handleMenuClick(2)}
        >
          <SideMenuIndicator style={{ opacity: indicators[2] === 1 ? 1 : 0 }} />
          <SideMenuText>ACTION</SideMenuText>
        </SideMenuItem>
      </SideMenu>
      <Section ref={introRef} bgColor="primary">
        <Wrapper>
          <Title>{block.title}</Title>
          <SubTitle>{block.summary}</SubTitle>
          <InfoContainer>
            <Button>⏰ 소요시간 : {block.time}분</Button>
            <Button>💡 난이도 : {generateLevelStar(block.level)}</Button>
          </InfoContainer>

          <ScrollDownButtonContainer isAuthEmpty={true}>
            <ScrollDownButton onClick={handleScrollDownClick}>
              <ScrollDownButtonText>Scroll Down</ScrollDownButtonText>
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.29289 19.7071C7.68342 20.0976 8.31658 20.0976 8.70711 19.7071L15.0711 13.3431C15.4616 12.9526 15.4616 12.3195 15.0711 11.9289C14.6805 11.5384 14.0474 11.5384 13.6569 11.9289L8 17.5858L2.34315 11.9289C1.95262 11.5384 1.31946 11.5384 0.928932 11.9289C0.538408 12.3195 0.538408 12.9526 0.928932 13.3431L7.29289 19.7071ZM9 1C9 0.447716 8.55228 -2.41411e-08 8 0C7.44771 2.41411e-08 7 0.447716 7 1L9 1ZM9 19L9 1L7 1L7 19L9 19Z"
                  fill="white"
                />
              </svg>
            </ScrollDownButton>
          </ScrollDownButtonContainer>
          <BackgroundLogo
            src={`${process.env.PUBLIC_URL}/assets/block/block_top_background_symbol.png`}
          />
        </Wrapper>
      </Section>
      <Section ref={guideRef} forLeader={true}>
        <Wrapper>
          {/* <Logo
            src={`${process.env.PUBLIC_URL}/assets/block/block_bottom_background_symbol.png`}
          /> */}

          <GuideDescription>
            {isMobile
              ? block.description
                  .replaceAll("\n\n", "/q")
                  .split("\n")
                  .join(" ")
                  .split("/q")
                  .join("\n\n")
              : block.description}
          </GuideDescription>
          {block.mediaType === "flip" ? (
            <>
              <TipBox>
                <p style={{ marginBottom: "0.69rem" }}>
                  <span style={{ color: "#00DBB1", fontWeight: 700 }}>
                    TIP!
                  </span>{" "}
                  새로운 관점을 열 수 있는 2가지 질문
                </p>
                <li>사건을 다르게 볼 수 있는 ‘여지’는 무엇인가요?</li>
                <li>감정과 사실을 분리해서 볼 수 있는 부분은 무엇인가요?</li>
              </TipBox>
            </>
          ) : (
            <Logo
              src={`${process.env.PUBLIC_URL}/assets/block/block_bottom_background_symbol.png`}
              // data-aos={"fade-in"}
              // data-aos-duration={500}
              // data-aos-delay={14000}
              // data-aos-once="true"
              // data-aos-anchor-placement={"bottom"}
              onClick={() => {
                actionRef?.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "nearest",
                });
              }}
            />
          )}
        </Wrapper>
      </Section>
      <Section id="action" ref={actionRef} style={{ paddingBottom: "50px" }}>
        <ContentWrapper>
          {/* <div> */}

          {block.mediaType === "video" ? (
            <VideoContainer>
              <Youtube
                ref={iframeRef}
                videoId={block.videoId}
                opts={{
                  height: isMobile ? "189" : "435",
                  width: "100%",
                  playerVars: {
                    rel: 0,
                    modestbranding: 1,
                    controls: 1,
                  },
                }}
              />
            </VideoContainer>
          ) : block.mediaType === "flip" ? (
            <>
              <CardFlip
                images={imagesRef.current}
                prevIndex={prevIndex}
                currentIndex={currentIndex}
                flip={flip}
                isFront={isFront}
              />
              <FlipButton
                style={{
                  marginTop: "2rem",
                  marginBottom: isMobile ? "1.5rem" : "3.37rem",
                }}
                onClick={() => {
                  setIsFront((prev) => !prev);
                  setPrevIndex((prev) => {
                    if (currentIndex === 0) {
                      return 0;
                    } else {
                      return currentIndex - 1;
                    }
                  });
                  setCurrentIndex((prev) => {
                    if (prev === block.imageUrls.length - 1) {
                      return 1;
                    } else {
                      return prev + 1;
                    }
                  });
                  setFlip((prev) => !prev);
                }}
              >
                📨 사연 뽑기
              </FlipButton>
            </>
          ) : (
            <Slider
              images={block.imageUrls}
              number={block.number}
              mediaType={block.mediaType}
            />
          )}

          <ContentQuestion>
            <ContentQuestionHighlight>Q.</ContentQuestionHighlight>
            {block.question}
          </ContentQuestion>

          {/* <NicknameInput
            placeholder="닉네임을 알려주세요 🙏"
            onChange={handleNicknameChange}
            nickname={nickname}
            nicknameFocus={nicknameFocus}
            onFocus={() => {
              setNicknameFocus(true);
            }}
            onBlur={() => {
              setNicknameFocus(false);
            }}
            value={nickname}
          /> */}

          <TextAreaContainer isAuthEmpty={true}>
            {!isMobile && <TextareaTriangle clicked={commentFocus} />}
            <Textarea
              placeholder="지금 당신의 생각이 옳습니다☺️"
              value={comment}
              onChange={handleCommentChange}
              onFocus={() => setCommentFocus(true)}
              onBlur={() => setCommentFocus(false)}
              // autoFocus={true}
            ></Textarea>
          </TextAreaContainer>
          <FlexRow
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1.69rem",
            }}
          >
            {isMobile ? (
              <HelpText>
                💡 내 마음은 익명으로 기록됩니다.
                <br />
                자유롭게 생각을 표현해 보세요.
              </HelpText>
            ) : (
              <HelpText>
                💡 내 마음은 익명으로 기록됩니다. 자유롭게 생각을 표현해 보세요.
              </HelpText>
            )}
            <TextLengthCheck>{comment.length}자</TextLengthCheck>
          </FlexRow>
          <ButtonContainer>
            <SubmitButton
              disabled={comment.length === 0}
              onClick={handleSubmit}
              isAuthEmpty={true}
            >
              마음 쓰기 ✍️
            </SubmitButton>
            <Tooltip text="마음 기록 완료! 😎" visible={submitClicked} />
          </ButtonContainer>
          <ReplyHeader>
            <span style={{ color: "#00DBB1" }}>{comments.length}</span>개의 마음
            함께 보기 👀
          </ReplyHeader>
          <ReplyContainer>
            {comments.map((item) => {
              // console.log(item);
              return (
                <ReplyItem key={item._id} isPublic={true}>
                  <ReplyItemTop>
                    <ReplyItemInfo>
                      <ReplyName>{item.nickname}</ReplyName>

                      <ReplyDate>
                        {converDate(new Date(item.createdAt))}
                      </ReplyDate>
                    </ReplyItemInfo>
                    <MoreButton deleteClick={() => handleDeleteClick(item)} />
                  </ReplyItemTop>
                  <ReplyComment>{lineBreaker(item.comment)}</ReplyComment>
                  <LikeButton
                    item={item}
                    isLike={localStorage.getItem(item._id)}
                    onClick={() => handleCommentLikeClick(item)}
                  />
                </ReplyItem>
              );
            })}
          </ReplyContainer>
        </ContentWrapper>
      </Section>
      {showDeleteModal && (
        <ModalBackground>
          <DeleteModal
            okHandler={handleDeleteComment}
            closeModal={handleCloseModal}
          />
        </ModalBackground>
      )}
      {showDeleteSuccessModal && (
        <ModalBackground>
          <DeleteSuccessModal
            okHandler={handleCloseDeleteSuccessModal}
            closeModal={handleCloseDeleteSuccessModal}
          />
        </ModalBackground>
      )}
    </Container>
  );
};

export default Prototype;
