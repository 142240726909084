import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MOBILE, TYPE_LEADER } from "../Constants";

const Container = styled.div`
  width: 100%;
  height: var(--navbar-height);
  background: ${({ theme, type }) =>
    type === "leader"
      ? `linear-gradient(218deg, #2F8171 0%, #1F3733 100%)`
      : theme.primary.normal};
  display: flex;
  justify-content: center;
  z-index: 10;
  ${({ sticky }) => sticky && "position: fixed; top:0; left: 0; right: 0;"}
`;

const Wrapper = styled.div`
  width: 100%;
  // max-width: 62.5rem;
  padding: 0 3.25rem;
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? "center" : "space-between")};
  align-items: center;
`;

const Logo = styled.img`
  width: ${({ type }) => (type === "leader" ? "10.6rem" : "6.44rem")};
  height: 1.19rem;
  cursor: pointer;
`;

const InfoContainer = styled.div`
  color: ${({ theme }) => theme.grayScaleColor.white};
  display: flex;
  cursor: pointer;
`;
const Avatar = styled.div`
  width: 2.31rem;
  height: 2.31rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvatarImage = styled.img`
  width: 1.06rem;
  height: 1.06rem;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Name = styled.span`
  font-size: 0.94rem;
  font-weight: 500;
  margin-bottom: 0.18rem;
`;

const Company = styled.span`
  font-size: 0.75rem;
  font-weight: 300;
`;

const TopBar = ({ type, name, company, sticky }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE})`,
  });

  const handleLogoClick = () => {
    // window.open("https://zep.us/play/8ZwAV4", "_blank");
  };

  const handleInfoClick = () => {
    navigate("/dashboard");
  };

  return (
    <Container sticky={sticky} type={type}>
      <Wrapper isMobile={isMobile}>
        <Logo
          type={type}
          onClick={handleLogoClick}
          src={
            type === TYPE_LEADER
              ? `${process.env.PUBLIC_URL}/assets/logo/maumfit_leaders.png`
              : `${process.env.PUBLIC_URL}/assets/logo/topbar_logo.png`
          }
        />

        {!isMobile && (
          <InfoContainer onClick={handleInfoClick}>
            <Avatar>
              <AvatarImage
                src={`${process.env.PUBLIC_URL}/assets/default_avatar.png`}
              />
            </Avatar>
            <NameContainer>
              <Name>{name}</Name>
              <Company>{company}</Company>
            </NameContainer>
          </InfoContainer>
        )}
      </Wrapper>
    </Container>
  );
};

export default TopBar;
