import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import CloseXButton from "../components/CloseXButton";
import Divider from "../components/Divider";
import ModalHeading2 from "../components/ModalHeading2";

const Image = styled.img`
  max-width: 11.25rem;
  margin-top: 1.7rem;
  margin-bottom: 2.25rem;
`;

const Heading = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 160%; /* 2.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  text-align: center;
`;

const DescriptionText = styled.p`
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 170%; /* 1.59375rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 1.25rem;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.87rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 170%; /* 1.59375rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 1.81rem;
  border-radius: 0.4375rem;
`;

const Separator = styled.div`
  width: 1px;
  height: 1rem;
  background: ${({ theme }) => theme.grayScaleColor.gray30};
  margin: 0 0.5rem;
`;
const ModalButton = styled.button`
  border-radius: 0.4375rem;
  padding: 0.78rem 0;

  // max-width: 11rem;
  border: none;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem; /* 153.333% */
  letter-spacing: -0.0375rem;
  cursor: pointer;
  width: 14rem;
  margin: 0 auto;
  margin-top: 2.21rem;
`;

const CancelButton = styled(ModalButton)`
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const OkButton = styled(ModalButton)`
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
`;

const InviteSuccessModal = ({ closeHandler, users }) => {
  const onConfirmClick = () => {
    window.location.reload();
    closeHandler();
  };
  return (
    <FlexColumn>
      <FlexRow
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <FlexRow style={{ gap: "0.5rem" }}></FlexRow>
        <CloseXButton onClick={closeHandler} />
      </FlexRow>
      <FlexRow style={{ justifyContent: "center" }}>
        <Image
          src={`${process.env.PUBLIC_URL}/assets/dashboard/manager/send_plane.png`}
        />
      </FlexRow>
      <Heading>
        초대장 발송이
        <br />
        완료되었습니다
      </Heading>
      <DescriptionText>
        {users.length === 1
          ? `${users[0].name} 님이 초대를 수락하고`
          : `${users[0].name} 님 외 ${users.length - 1}명에게`}
        <br />
        비밀번호를 설정하면 멤버십이 활성화됩니다
      </DescriptionText>

      <OkButton onClick={onConfirmClick}>확인했어요</OkButton>
    </FlexColumn>
  );
};

export default InviteSuccessModal;
