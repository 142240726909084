import styled from "styled-components";

const TextBox = styled.span`
  font-weight: 600;
  font-size: 0.94rem;
  color: ${({ theme }) => theme.primary.normal};
  padding: 0.25rem 0.75rem;
  min-width: 2.88rem;
  background: ${({ theme }) => theme.primary.light};
  border-radius: 0.31rem;
  // margin: 0 0.31rem;
  text-align: center;
  margin-left: 1.25rem;
  display: flex;
  align-items: center;
  line-height: 1.5rem;
`;

const BackgroundText = ({ children, style }) => {
  return <TextBox style={style}>{children}</TextBox>;
};

export default BackgroundText;
