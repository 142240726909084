import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
    datalabels: {
      display: true,
      color: ["#C0EDE4", "#00DBB1", "#D7DEDB"],
      font: { size: 15, weight: "bold" },
      anchor: "end",
      offset: -25,
      align: "start",
    },
  },
  scales: {
    x: {
      grid: { display: false },
    },
    y: {
      offset: false, // y축 위/아래 짤리는 거 옵션
      min: 0,
      max: 100,
      ticks: {
        stepSize: 20,
        padding: 10,
        color: "#777777",
        font: {
          family: "Montserrat",
        },
      },
      grid: {
        drawBorder: false,
        lineWidth: 1,
      },
    },
  },
};

const BarChart = ({ last, me, next }) => {
  const labels = ["지난번 나", "이번 나", "다음번 나"];
  const data = {
    labels,
    datasets: [
      {
        // label: labels,
        data: [last, me, next],
        backgroundColor: ["#C0EDE4", "#00DBB1", "#D7DEDB"],
        borderRadius: 20,
        barThickness: 36,
      },
    ],
  };
  return <Bar options={options} data={data} width={400} height={249} />;
};

export default BarChart;
