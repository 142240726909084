import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import FlexRow from "../../../../components/FlexRow";
import ModalBackground from "../../../../components/ModalBackground";
import { MOBILE } from "../../../../Constants";
import Tooltip from "../../../block/Tooltip";
import Popup from "./Popup";

const Container = styled.div`
  font-size: 1.13rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: ${({ theme, type }) => theme.grayScaleColor.black};
  background: ${({ theme, background }) => background};
  width: 100%;
  padding: 1.25rem 1.63rem;
  border-radius: 0.63rem;
  display: flex;

  align-items: center;
  justify-content: space-between;
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

const NumberText = styled.span`
  color: ${({ theme, type }) =>
    type === "now" ? theme.primary.normal : theme.grayScaleColor.black};
  font-weight: 600;
  font-size: 1.25rem;
  margin-left: 0.38rem;
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;
const Icon = styled.img`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-right: 0.38rem;
`;

const Info = styled.img`
  margin-left: 0.38rem;
`;

const UpIcon = styled.img``;
const Box = ({ type, icon, background, text, point, name }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: MOBILE });

  const handleHover = (e) => {
    e.stopPropagation();
    setIsVisible(true);
  };

  const handleOnLeave = (e) => {
    e.stopPropagation();
    setIsVisible(false);
  };

  const handleClick = () => {
    if (isMobile) {
      setIsClick(true);
    }
  };
  return (
    <Container background={background} type={type}>
      <FlexRow style={{ alignItems: "center" }}>
        <Icon
          src={icon}
          width={
            text === "바이브" || text === "누적 바이브" ? "1.06rem" : "1.38rem"
          }
          height={
            text === "바이브" || text === "누적 바이브" ? "0.88rem" : "1.38rem"
          }
        />
        {text}
        {type === "now" && (
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Info
              src={`${process.env.PUBLIC_URL}/svg/info.svg`}
              onMouseEnter={handleHover}
              onMouseLeave={handleOnLeave}
              onClick={handleClick}
            />
            {!isMobile && (
              <Tooltip
                text={
                  text === "바이브" &&
                  `${name} 님의 맘핏 활동량을 나타내며, 다양한 활동을 통해 누적됩니다`
                }
                visible={isVisible}
                style={{
                  bottom: "2rem",
                  left: 0,
                  width: text === "바이브" ? "380px" : "300px",
                  maxWidth: text === "바이브" ? "380px" : "300px",
                }}
                dir={"left"}
              >
                {text !== "바이브" && (
                  <FlexRow style={{ alignItems: "center" }}>
                    <Icon src={icon} width={"1rem"} height={"1rem"} />
                    <span style={{ fontWeight: 700 }}>30,000원</span>을 채우면
                    현금으로 교환할 수 있어요!
                  </FlexRow>
                )}
              </Tooltip>
            )}
          </div>
        )}
      </FlexRow>
      <FlexRow>
        {type === "now" && (
          <UpIcon src={`${process.env.PUBLIC_URL}/svg/up.svg`} />
        )}
        <NumberText type={type}>
          {point.toLocaleString()}
          {text.includes("포인트") && "P"}
        </NumberText>
      </FlexRow>
      {isClick && (
        <ModalBackground style={{ padding: "0 1.56rem" }}>
          <Popup onClick={() => setIsClick(false)}>
            {text === "바이브" ? (
              <p style={{ textAlign: "center" }}>
                {name} 님의 맘핏 활동량을 나타내며,
                <br />
                다양한 활동을 통해 누적됩니다
              </p>
            ) : (
              <p>
                <Icon
                  src={icon}
                  width={isMobile ? "0.94rem" : "1rem"}
                  height={isMobile ? "0.94rem" : "1rem"}
                  style={{
                    position: "relative",
                    top: "0.1rem",
                    marginRight: "0.25rem",
                  }}
                />
                <span style={{ fontWeight: 700 }}>30,000원</span>을 채우면
                <br />
                현금으로 교환할 수 있어요!
              </p>
            )}
          </Popup>
        </ModalBackground>
      )}
    </Container>
  );
};

export default Box;
