import { useEffect } from "react";
import styled from "styled-components";
import { converToLocaleDate } from "../../../utils/convertDate";
import LikeButton from "./LikeButton";
import StampImage from "./StampImage";

const Container = styled.div`
  width: 100%;
  max-width: 335px;
  height: auto;
  max-height: 100vh;
  padding: 0 30px;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  border-radius: 5px;
  // display: flex;
  // flex-direction: column;
  border-radius: 30px;
  position: relative;
  overflow: auto;
`;

const Name = styled.h4`
  font-weight: 700;
  color: #111;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.03em;
  text-align: center;
  margin-bottom: 27px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 32px;
  border-radius: 20px;
  max-height: 350px;
  object-fit: cover;
`;

const Date = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  font-family: "Montserrat";
`;

const Score = styled.span`
  font-weight: 600;
  font-size: 1.7rem;
  color: #111111;
  display: block;
  font-family: "Montserrat";
  margin-bottom: 12px;
  margin-top: 8px;
`;

const Spacer = ({ height }) => {
  return <div style={{ width: "100%", height }}></div>;
};
const ClostButton = ({ onClick }) => (
  <div style={{ textAlign: "right", cursor: "pointer" }}>
    <svg
      onClick={onClick}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.03033 0.96967C1.73744 0.676777 1.26256 0.676777 0.96967 0.96967C0.676777 1.26256 0.676777 1.73744 0.96967 2.03033L8.42499 9.48565L0.96991 16.9407C0.677017 17.2336 0.677017 17.7085 0.96991 18.0014C1.2628 18.2943 1.73768 18.2943 2.03057 18.0014L9.48565 10.5463L16.9407 18.0014C17.2336 18.2943 17.7085 18.2943 18.0014 18.0014C18.2943 17.7085 18.2943 17.2336 18.0014 16.9407L10.5463 9.48565L18.0016 2.03033C18.2945 1.73744 18.2945 1.26256 18.0016 0.96967C17.7087 0.676777 17.2339 0.676777 16.941 0.96967L9.48565 8.42499L2.03033 0.96967Z"
        fill="#BBBBBB"
      />
    </svg>
  </div>
);

const PhotoDialog = ({
  auth,
  likes,
  setLikes,
  isLike,
  setIsLike,
  photo,
  setPhotos,
  index,
  closeHandler,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <Container>
      <Spacer height={30} />
      <ClostButton onClick={closeHandler} />
      <Name>{photo.name}</Name>
      <Image src={photo.url} />
      <Date>{converToLocaleDate(photo.createdAt)}</Date>
      <Score>
        {photo.score}
        <span style={{ fontSize: 18 }}>%</span>
      </Score>
      <LikeButton
        auth={auth}
        likes={likes}
        setLikes={setLikes}
        isLike={isLike}
        setIsLike={setIsLike}
        photo={photo}
        setPhotos={setPhotos}
        index={index}
      />
      {photo.score >= 80 && (
        <StampImage
          style={{
            width: 75,
            position: "absolute",
            bottom: 40,
            right: 30,
          }}
        />
      )}
      <Spacer height={30} />
    </Container>
  );
};

export default PhotoDialog;
