import styled from "styled-components";
import { MOBILE } from "../../../../Constants";

const Heading = styled.h1`
  font-size: 1.5rem;
  line-height: 2.5rem;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 600;
  @media (max-width: ${MOBILE}) {
    line-height: 150%;
    text-align: center;
    word-break: keep-all;
    font-size: 1.125rem;
    font-weight: 600;
  }
`;

const Heading1 = ({ children, style }) => {
  return <Heading style={style}>{children}</Heading>;
};

export default Heading1;
