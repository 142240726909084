import styled from "styled-components";

const Line = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.grayScaleColor.gray20};
  width: 100%;
  margin-top: 1.06rem;
  margin-bottom: 1.88rem;
`;

const Divider = ({ style }) => {
  return <Line style={style} />;
};

export default Divider;
