import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import CardLayout from "../CardLayout";

import Heading1 from "../components/Heading1";
import Heading2 from "../components/Heading2";

import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

import { USER_LEADER, USER_MANAGER } from "../../../Constants";

import { useLocation, useSearchParams } from "react-router-dom";
import ModalBackground from "../../../components/ModalBackground";
import { getDate } from "../../../utils/date";
import Calendar from "../components/Calendar";
import { useRef } from "react";

const Container = styled.div`
  padding: 2.75rem 2.75rem;
  padding-left: calc(2.75rem + var(--sidebar-width));
  width: 100%;
  position: relative;
`;

const TableTitle = styled.h1`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: -0.03125rem;
`;

const Bold = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const HR = styled.div`
  display: flex;
  align-items: center;
  padding: 0.94rem 2rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
`;
const TR = styled.div`
  display: flex;
  align-items: center;
  padding: 0.94rem 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
`;

const HeaderItem = styled.p`
  flex: 1;
  font-weight: 600;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Item = styled.p`
  flex: 1;
  font-size: 0.9375rem;
  line-height: 150%; /* 1.40625rem */
  letter-spacing: -0.03125rem;
`;

const Button = styled.button`
  border: none;
  border-radius: 0.3125rem;
  display: flex;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  cursor: pointer;
`;

const NormalButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  &:hover {
    color: ${({ theme }) => theme.grayScaleColor.black};
    border: 1px solid ${({ theme }) => theme.grayScaleColor.black};
  }
  transition: all 0.1s;
`;

const PaginationText = styled.p`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
`;
const Portal = ({ children }) => {
  const el = document.getElementById("portal");

  return ReactDOM.createPortal(children, el);
};

const CloseButton = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L16.9999 17"
        stroke="#BBBBBB"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M17 1L1.00009 17"
        stroke="#BBBBBB"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

const ArrowButton = styled.div`
  padding: 0.38rem;
  border-radius: 0.4375rem;
  background: ${({ theme }) => theme.grayScaleColor.gray10};
`;
const LeftButton = ({ onClick }) => {
  return (
    <ArrowButton onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M10.7998 14.3999L4.7998 8.9999L10.7998 3.5999"
          stroke="#777777"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ArrowButton>
  );
};
const RightButton = ({ onClick }) => {
  return (
    <ArrowButton onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M7.20019 14.3999L13.2002 8.9999L7.2002 3.5999"
          stroke="#777777"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ArrowButton>
  );
};

const ManagerUsageResult = () => {
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const [params] = useSearchParams();

  const companyId = params.get("id") || auth.user.companyId._id;

  const [companyResult, setCompanyResult] = useState();
  const [checkupResultLoading, setCheckupResultLoading] = useState(true);
  const [checkupResult, setCheckupResult] = useState({});
  const [showCheckupDetail, setShowCheckupDetail] = useState(false);
  const [showBlockDetail, setShowBlockDetail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const [clickData, setClickData] = useState(null);
  const calendarRef = useRef();
  const modalRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const getCheckupResult = async (page) => {
    setCheckupResultLoading(true);
    const checkupResponse = await axiosPrivate.get(
      `/statistics/project/checkup?companyId=${companyId}&page=${page}`
    );

    setCheckupResult(checkupResponse.data.data);
    setCheckupResultLoading(false);
  };

  const onPageClick = async (e, cur) => {
    setClickData(null);
    setClickPosition({
      x: 0,
      y: 0,
    });
    setShowModal(false);
    setCurrentPage(cur);
    await getCheckupResult(cur);
  };
  const onClickCheckupDetail = async () => {
    setShowCheckupDetail(true);
    await getCheckupResult(currentPage);
  };
  const onClickBlockDetail = () => {
    setShowBlockDetail(true);
  };

  const onDateClick = (e, data, position) => {
    // console.log(e.clientX, e.clientY);
    // if (!trainingData[date]) {
    //   return;
    // }
    if (showModal) {
      return;
    }
    const modalRect = modalRef.current?.getBoundingClientRect();
    const calendarRect = calendarRef.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    // console.log("왼도우 사이즈", windowWidth, windowHeight);

    let x = e.clientX - calendarRect.left;
    let y = 53.5 * position;
    const dialogWidth = modalRect?.width || 384;
    const dialogHeight = modalRect?.height || 200;

    if (x + dialogWidth > calendarRect.width) {
      x = calendarRect.width - dialogWidth;
    } else if (x < dialogWidth) {
      x = dialogWidth / 2;
    }

    if (y + dialogHeight > windowHeight) {
      y = Math.max(0, y - dialogHeight);
    }

    setClickData(data);
    setClickPosition({
      x,
      y,
    });
    setShowModal(true);
  };

  useEffect(() => {
    (async () => {
      const companyResponse = await axiosPrivate.get(
        `/statistics/project/${companyId}`
      );

      if (!companyResponse.data.ok) {
        return;
      }
      setCompanyResult(companyResponse.data.data);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) return <div></div>;

  return (
    <Container>
      <Heading1 text="참여현황" />
      <Heading2
        text="이번 주 참여현황을 볼 수 있습니다."
        style={{ marginBottom: "2.44rem" }}
      />
      <FlexRow style={{ gap: "1.88rem", height: "100%" }}>
        <CardLayout style={{ flex: 1 }}>
          <FlexColumn>
            <FlexRow
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <TableTitle>
                마음 체크업 참여 현황
                <Bold style={{ color: "#00DBB1", marginLeft: "0.75rem" }}>
                  {companyResult.checkupDate}
                </Bold>
              </TableTitle>
              <FlexRow style={{ gap: "0.5rem" }}>
                <NormalButton onClick={onClickCheckupDetail}>
                  전체 보기
                </NormalButton>
              </FlexRow>
            </FlexRow>
            <FlexColumn style={{ marginTop: "1.5rem" }}>
              <HR>
                <HeaderItem>그룹</HeaderItem>
                <HeaderItem>인원</HeaderItem>
                <HeaderItem>체크업 참여</HeaderItem>
                <HeaderItem>웰빙 지수</HeaderItem>
                {/* <HeaderItem>직급</HeaderItem>
              <HeaderItem>성별</HeaderItem>
              <HeaderItem>생년</HeaderItem> */}
                <HeaderItem style={{ flex: 3 }}>마음 문장</HeaderItem>
              </HR>
              {companyResult.checkupData.map((item, index) => {
                if (item.total <= 2) {
                  return null;
                }
                return (
                  <TR key={index}>
                    <Item>{item.name}</Item>
                    <Item>{item.total}명</Item>
                    <Item>
                      {item.participants}명 (
                      {item.total === 0
                        ? 0
                        : ((item.participants / item.total) * 100).toFixed(0)}
                      %)
                    </Item>
                    <Item>{item.average}점</Item>
                    {/* <Item>Manager</Item>
                <Item>{user.gender || "-"} </Item>
                <Item>{user.birthYear || "-"}</Item> */}
                    <Item style={{ flex: 3 }}>{item.sentences.join(", ")}</Item>
                  </TR>
                );
              })}
            </FlexColumn>
          </FlexColumn>
          <FlexColumn style={{ marginTop: "2.8rem" }}>
            <FlexRow
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <TableTitle>
                마음 트레이닝 참여 현황
                <Bold style={{ color: "#00DBB1", marginLeft: "0.75rem" }}>
                  {companyResult.trainingDate}
                </Bold>
              </TableTitle>
              <FlexRow style={{ gap: "0.5rem" }}>
                <NormalButton onClick={onClickBlockDetail}>
                  전체 보기
                </NormalButton>
              </FlexRow>
            </FlexRow>
            <FlexColumn style={{ marginTop: "1.5rem" }}>
              <HR>
                <HeaderItem>그룹</HeaderItem>
                {companyResult.trainingData[0].trainingCounts.map(
                  (item, index) => (
                    <HeaderItem key={index}>{item.date}</HeaderItem>
                  )
                )}
                {/* <HeaderItem>직급</HeaderItem>
              <HeaderItem>성별</HeaderItem>
              <HeaderItem>생년</HeaderItem> */}
              </HR>

              {companyResult.trainingData.map((item, index) => (
                <TR key={index}>
                  <Item>{item.name}</Item>
                  {item.trainingCounts.map((c, cIx) => (
                    <Item key={cIx}>
                      {c.count}명 ({((c.count / c.total) * 100).toFixed(0)}%)
                    </Item>
                  ))}
                </TR>
              ))}
            </FlexColumn>
          </FlexColumn>
        </CardLayout>
      </FlexRow>
      {showCheckupDetail && (
        <Portal>
          <ModalBackground>
            <CardLayout
              style={{
                paddingTop: "3.19rem",
                paddingBottom: "2.69rem",
                width: "80%",
                height: "90%",
                overflow: "scroll",
              }}
            >
              <FlexRow
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
              >
                <TableTitle>마음 체크업 참여 현황</TableTitle>
                <CloseButton
                  onClick={() => {
                    setShowCheckupDetail(false);
                  }}
                />
              </FlexRow>

              <HR>
                <HeaderItem>날짜</HeaderItem>
                <HeaderItem>인원</HeaderItem>
                <HeaderItem>체크업 참여</HeaderItem>
                <HeaderItem>웰빙 지수</HeaderItem>
                <HeaderItem style={{ flex: 2 }}>마음 문장</HeaderItem>
              </HR>
              {checkupResultLoading ? (
                <div style={{ textAlign: "center", marginTop: "4rem" }}>
                  데이터를 가져오는 중...
                </div>
              ) : (
                <>
                  <div style={{ position: "relative" }} ref={calendarRef}>
                    {checkupResult.allCheckupData?.map((item, index) => (
                      <TR
                        key={index}
                        onClick={(e) => onDateClick(e, item, index)}
                      >
                        <Item>{getDate(item.date, "YY/M/D(ddd)")}</Item>
                        <Item>{item.send}명</Item>
                        <Item>
                          {item.participated}명 (
                          {item.participated === 0
                            ? 0
                            : ((item.participated / item.send) * 100).toFixed(
                                0
                              )}
                          %)
                        </Item>
                        <Item>{item.average}점</Item>
                        {/* <Item>Manager</Item>
                <Item>{user.gender || "-"} </Item>
                <Item>{user.birthYear || "-"}</Item> */}
                        <Item style={{ flex: 2 }}>
                          {item.maumSentences.join(", ")}
                        </Item>
                      </TR>
                    ))}
                    {showModal && (
                      <div
                        ref={modalRef}
                        style={{
                          borderRadius: "1.25rem",
                          width: "24rem",
                          maxHeight: "20rem",
                          overflow: "scroll",
                          backgroundColor: "#FFF",
                          border: "1px solid #EEE",
                          position: "absolute",
                          boxShadow: "0px 10px 80px 0px rgba(0, 0, 0, 0.07)",
                          top: clickPosition.y,
                          left: clickPosition.x,
                        }}
                      >
                        <CardLayout>
                          <FlexRow
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "1rem",
                                fontWeight: 600,
                                lineHeight: "150%",
                                color: "#111",
                              }}
                            >{`${getDate(clickData.date, "M월 D일(ddd)")}`}</p>
                            <CloseButton
                              onClick={() => {
                                setClickData(null);
                                setClickPosition({
                                  x: 0,
                                  y: 0,
                                });
                                setShowModal(false);
                              }}
                            />
                          </FlexRow>
                          <div
                            style={{
                              width: "100%",
                              height: "1px",
                              background: "#d9d9d9",
                              marginBottom: "1rem",
                              marginTop: "1rem",
                            }}
                          ></div>
                          <FlexColumn style={{ gap: "0.75rem" }}>
                            {clickData?.departments.map((item, index) => {
                              return (
                                <FlexRow
                                  key={index}
                                  style={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    fontSize: "0.875rem",
                                    fontWeight: 500,
                                    lineHeight: "150%",
                                    letterSpacing: "-0.03125rem",
                                  }}
                                >
                                  <p>{item.name}</p>
                                  <FlexRow style={{ gap: "1rem" }}>
                                    <p>{item.average}점</p>
                                    <p>{item.maumSentences.join(", ")}</p>
                                  </FlexRow>
                                </FlexRow>
                              );
                            })}
                          </FlexColumn>
                        </CardLayout>
                      </div>
                    )}
                  </div>

                  <FlexRow
                    style={{
                      marginTop: "1.69rem",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <PaginationText>
                      {checkupResult.total}개 중 {10 * (currentPage - 1) + 1}-
                      {Math.min(10 * currentPage, checkupResult.total)}
                    </PaginationText>
                    <FlexRow style={{ gap: "0.5rem", marginLeft: "1.12rem" }}>
                      <LeftButton
                        onClick={(e) =>
                          onPageClick(e, currentPage > 1 ? currentPage - 1 : 1)
                        }
                      />
                      <RightButton
                        onClick={(e) =>
                          onPageClick(
                            e,
                            currentPage * 10 < checkupResult.total
                              ? currentPage + 1
                              : currentPage
                          )
                        }
                      />
                    </FlexRow>
                  </FlexRow>
                </>
              )}
            </CardLayout>
          </ModalBackground>
        </Portal>
      )}
      {showBlockDetail && (
        <Portal>
          <ModalBackground>
            <CardLayout
              style={{ width: "80%", height: "90%", overflow: "scroll" }}
            >
              <FlexRow
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
              >
                <TableTitle>마음 트레이닝 참여 현황</TableTitle>
                <CloseButton onClick={() => setShowBlockDetail(false)} />
              </FlexRow>
              <FlexRow style={{ gap: "3.5rem" }}>
                <Calendar companyId={companyId} />
              </FlexRow>
            </CardLayout>
          </ModalBackground>
        </Portal>
      )}
    </Container>
  );
};

export default ManagerUsageResult;
