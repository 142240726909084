import styled from "styled-components";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import NoDataText from "./components/NoDataText";

const Container = styled.div``;
const ImageContainer = styled.div`
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.primary.light};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.625rem;
  height: 3.625rem;
`;

const Title = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 140%;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;
const Data = styled.p`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-bottom: 0.31rem;
  display: flex;
  align-items: center;
`;

const Description = styled.p`
  font-size: 0.8125rem;
  line-height: 140%;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const Icon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
`;

const Card1 = ({ title, titleIcon, icon, data, subData, desc, style }) => {
  return (
    <Container style={style}>
      <Title>{title}</Title>
      <FlexRow
        style={{
          //   width: "22.5rem",
          marginTop: "1.13rem",
          alignItems: data ? "flex-end" : "center",
          gap: "1.75rem",
        }}
      >
        <ImageContainer>
          <Icon src={icon} />
        </ImageContainer>
        {data ? (
          <FlexColumn
            style={{
              alignItems: "flex-start",
            }}
          >
            <Data>
              {titleIcon && (
                <img
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                    marginRight: "0.44rem",
                  }}
                  src={titleIcon}
                />
              )}
              {data}
            </Data>
            <Description>{desc}</Description>
          </FlexColumn>
        ) : (
          <NoDataText />
        )}
      </FlexRow>
    </Container>
  );
};

export default Card1;
