import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  // height: 11.69rem;
  border-radius: 0.63rem;
  padding: 3.13rem 4.06rem;
  margin-bottom: 2.81rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  @media (max-width: 425px) {
    width: 100%;
    height: 225px;
  }
  // border: 1px solid black;
  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 0.63rem;
  }
`;

const EmotionContainer = styled.div`
  display: flex;
  // justify-content: space-between;
  align-items: center;
  // border: 1px solid black;
  width: 100%;
  padding: 1.06rem 0;
  @media (max-width: 425px) {
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
`;

const Title = styled.span`
  font-size: 1rem;
  font-weight: 500;
  width: 7rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  letter-spacing: -0.04em;
  @media (max-width: 425px) {
    font-size: 0.94rem;
    line-height: 1.44rem;
    width: 100%;
    text-align: center;
    margin-bottom: 0.44rem;
  }
`;

const BarContainer = styled.div`
  display: flex;
  height: 1.75rem;
  align-items: center;
  margin-left: 4rem;
  @media (max-width: 425px) {
    // flex-direction: column;
    margin-left: 0;s
  }
`;

const BarAxis = styled.div`
  width: 30.75rem;
  height: 1px;
  background: ${({ theme }) => theme.grayScaleColor.black};
  position: relative;
  margin: 0 1.63rem;
  @media (max-width: 425px) {
    width: 12.56rem;
    margin: 0 0.63rem;
  }
`;

const BarCenter = styled.div`
  width: 1px;
  height: 1.75rem;
  background: ${({ theme }) => theme.grayScaleColor.black};
  position: absolute;
  left: 50%;
  top: -0.85rem;
  z-index: 1;
`;

const Bar = styled.div`
  height: 0.94rem;
  width: calc(50% - ${({ percentage }) => `${percentage}%`});
  background: ${({ theme, type, tag }) =>
    tag === "lastWeek"
      ? type === "positive"
        ? "#B4E0F9"
        : "#FFBF91"
      : type === "positive"
      ? "#3DAFEF"
      : theme.secondary.normal};
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transform: ${({ type }) => type === "negative" && `rotate(-180deg)`};
  transform-origin: center left;
`;

const SubTitle = styled.span`
  font-size: 0.94rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.black};
  letter-spacing: -0.04em;
  @media (max-width: 425px) {
    font-size: 0.75rem;
    line-height: 1.19rem;
  }
`;

const CheckupEmotionChart = ({ emotions }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });
  return (
    <Container>
      {emotions.map((emotion, index) => (
        <EmotionContainer key={index}>
          <Title>{emotion.title}</Title>
          <BarContainer>
            <SubTitle>부정 감정</SubTitle>
            <BarAxis>
              <Bar
                tag={emotion.tag}
                type="negative"
                percentage={Math.max(0, (40 - emotion.negative) * (50 / 40))}
              />
              <BarCenter />
              <Bar
                tag={emotion.tag}
                type="positive"
                percentage={Math.max(0, (40 - emotion.positive) * (50 / 40))}
              />
            </BarAxis>
            <SubTitle>긍정 감정</SubTitle>
          </BarContainer>
        </EmotionContainer>
      ))}
      {/* <EmotionContainer>
        <Title>지난 감정 지수</Title>
        <BarContainer>
          <SubTitle>부정 감정</SubTitle>
          <BarAxis>
            <Bar type="negative" percentage="10" />
            <BarCenter />
            <Bar type="positive" percentage="30" />
          </BarAxis>
          <SubTitle>긍정 감정</SubTitle>
        </BarContainer>
      </EmotionContainer> */}
    </Container>
  );
};

export default CheckupEmotionChart;
