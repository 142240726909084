import {
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_INVITE,
  STATUS_SIGNUP,
} from "../Constants";

const convertStatus = (status) => {
  switch (status) {
    case STATUS_ACTIVE:
      return "ACTIVE";
    case STATUS_INACTIVE:
      return "INACTIVE";
    case STATUS_INVITE:
      return "INVITE";
    case STATUS_SIGNUP:
      return "SIGNUP";
    default:
      return "INACTIVE";
  }
};

export default convertStatus;
