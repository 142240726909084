import styled from "styled-components";

const Container = styled.div`
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 0 4.69rem;
  border-radius: 1.875rem;
  position: relative;
`;

const Logo = styled.img`
  width: 5.4375rem;
  height: 0.9375rem;
  position: absolute;
  top: 2rem;
  right: 2.25rem;
`;

const Card = ({ children, style }) => {
  return (
    <Container style={style}>
      <Logo src={`${process.env.PUBLIC_URL}/assets/logo/login_logo.png`} />
      {children}
    </Container>
  );
};

export default Card;
