import styled from "styled-components";
import useCountdown from "../../../../hooks/useCountdown";

const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.88rem;
  font-weight: 400;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  line-height: 1.31rem;
  letter-spacing: -0.5px;
  margin-bottom: 0.75rem;
`;

const Time = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.primary.normal};
  //   padding: 0.19rem 0.63rem;
  min-width: 2.88rem;
  //   background: ${({ theme }) => theme.primary.light};
  border-radius: 0.31rem;
  margin: 0 0.31rem;
  text-align: center;
`;

const Countdown = ({ targetDate, start, style }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  return (
    <TimerContainer style={style}>
      {start ? "⏰ 체크업 마감까지" : "⏰ 다음 체크업까지"}{" "}
      {/* {days > 0 && <Time>{days}일</Time>}{" "}
      {hours > 0 && <Time>{hours}시간</Time>}{" "}
      {minutes > 0 && <Time>{minutes}분</Time>}
      <Time>{seconds}초</Time> */}
      <Time>
        {days > 0 && `${days}일`} {hours > 0 && `${hours}시간`}{" "}
        {minutes > 0 && `${minutes}분`} {seconds}초
      </Time>
      남았어요
    </TimerContainer>
  );
};

export default Countdown;
