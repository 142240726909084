import React, { useState } from "react";
import styled from "styled-components";

const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 30px;
`;

const Track = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 4px;
  background: #ddd;
  border-radius: 2px;
  transform: translateY(-50%);
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    background: #ddd;
    border-radius: 50%;
    transform: translateY(-50%);
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
`;

const FilledTrackLeft = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 4px;
  background: #00d1b2;
  transform: translateY(-50%);
  width: ${({ value }) => `${((30 - value - 1) * 100) / 30 / 2}%`};
  ${({ value }) => (value < 30 ? "left: auto; right: 50%;" : "display: none;")}
`;

const FilledTrackRight = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 4px;
  background: #00d1b2;
  border-radius: 2px;
  transform: translateY(-50%);
  width: ${({ value }) => `${((value - 30) * 100) / 30 / 2}%`};
  ${({ value }) => (value > 30 ? "right: auto; left: 50%;" : "display: none;")}
`;

const Thumb = styled.input.attrs({ type: "range" })`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 30px;
  background: transparent;
  position: relative;
  cursor: pointer;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 2.1875rem;
    height: 1.57225rem;
    background: #00d1b2;
    border-radius: 0.34rem;
    cursor: pointer;
    position: relative;
    z-index: 1;
    // border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &::-webkit-slider-thumb::before,
  &::-webkit-slider-thumb::after {
    content: "aaa";
    // position: absolute;
    width: 2px;
    height: 15px;
    background: red;
    border: 1px solid black;
    top: 0;
    left: 0;
  }
  &::-webkit-slider-thumb::before {
    // top: 15%;
  }
  &::-webkit-slider-thumb::after {
    // top: 55%;
  }
`;

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  padding: 10px 0;
`;

const Label = styled.div`
  font-size: 14px;
`;

const Slider = ({ value, setValue }) => {
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <SliderContainer>
      <SliderWrapper>
        <Track />
        <FilledTrackLeft value={value} />
        <FilledTrackRight value={value} />
        <Thumb
          value={value}
          step={10}
          min="0"
          max="60"
          onChange={handleChange}
        />
      </SliderWrapper>
    </SliderContainer>
  );
};

export default Slider;
