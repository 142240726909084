import styled, { css, keyframes } from "styled-components";
import FlexRow from "../../../components/FlexRow";
import { useEffect, useState } from "react";

const Container = styled.div`
  border-radius: 1.25rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 2rem 2.25rem;
  position: relative;
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Timer = styled.div`
  width: 10rem;
  height: 10rem;
  margin-bottom: 1.69rem;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  & .number {
    color: ${({ theme }) => theme.primary.normal};
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 1.9rem */
    letter-spacing: -0.03125rem;
  }
`;

const countdown = keyframes`
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 420px;
  }
`;
const CountdownSvg = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  width: 10rem;
  height: 10rem;
  transform: rotateY(-180deg) rotateZ(-90deg);

  & circle {
    stroke-dasharray: 420px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 14px;
  }
  & .back {
    stroke: ${({ theme }) => theme.backgroundColor.darkgray20};
    fill: none;
  }
  & .front {
    stroke: ${({ theme }) => theme.primary.normal};
    fill: none;
    animation: ${countdown} 10s linear forwards;
  }
`;

const Heading1 = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
`;

const Heading2 = styled.p`
  color: #666;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  margin-bottom: 1.69rem;
`;

const Button = styled.button`
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.primary.normal};
  background: ${({ theme, state }) =>
    state === "modified" ? theme.grayScaleColor.white : theme.primary.normal};
  padding: 0.69rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${({ theme, state }) =>
    state === "modified" ? theme.primary.normal : theme.grayScaleColor.white};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4375rem;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  }
`;
const TimerModal = ({ onConfirm, onCancel, block, type }) => {
  const [time, setTime] = useState(10);
  const [retry, setRetry] = useState(false);
  const [randomKey, setRandomKey] = useState(Math.random());

  const onRetryClick = () => {
    setRetry(true);
    setRandomKey(Math.random());
    setTime(10);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prev) => {
        if (prev - 1 >= 0) {
          return prev - 1;
        } else {
          return 0;
        }
      });
    }, [1000]);

    if (time === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [time]);
  return (
    <Container>
      <Heading1>10초 마음챙김 호흡하기</Heading1>
      <Heading2>편안하게 숨을 들이쉬고 내쉬어 보세요</Heading2>

      {/* <Heading2>
        오늘의 마음 트레이닝을 완료하여
        <br /> <span style={{ fontWeight: 600 }}>캐시 100원</span>이
        지급되었습니다! (1일 1회)
      </Heading2> */}
      <Timer>
        <div className="number" style={{ color: time === 0 && "#D9D9D9" }}>
          {time}
        </div>
        <CountdownSvg key={randomKey} retry={retry}>
          <circle className="back" r="65" cx="80" cy="80"></circle>
          <circle className="front" r="65" cx="80" cy="80"></circle>
        </CountdownSvg>
      </Timer>
      <FlexRow style={{ width: "100%", gap: "0.62rem" }}>
        <Button state={"modified"} onClick={onCancel}>
          닫기
        </Button>
        <Button disabled={time !== 0} retry={retry} onClick={onRetryClick}>
          다시하기
        </Button>
      </FlexRow>
    </Container>
  );
};

export default TimerModal;
