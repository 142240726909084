import axios from "axios";
import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { axiosPrivate } from "../../../api/axios";
import { ROUTES } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import dataURLtoFile from "../../../utils/dataURLtoFile";
import includesPathname from "../../../utils/includesPathname";
import { isAuthEmpty } from "../../../utils/isAuthEmpty";
import lineBreaker from "../../../utils/lineBreaker";
import getSmile from "../api/getSmile";
import getSmileAverage from "../api/getSmileAverage";
import uploadSmilePhoto from "../api/uploadSmilePhoto";
import Button from "../components/Button";
import Description from "../components/Description";
import Heading from "../components/Heading";
import Modal from "../components/Modal";
import PhotoDialog from "../components/PhotoDialog";
import SavePhotoDialog from "../components/SavePhotoDialog";
import SubHeading from "../components/SubHeading";
import UploadPhotoDialog from "../components/UploadPhotoDialog";

const StampImage = styled.img`
  width: 72px;
  height: 72px;
  position: absolute;
  right: 30px;
  top: 160px;
`;

const ImageContainer = styled.div`
  width: 19.69rem;
  // height: 13.75rem;
  position: relative;
  margin-top: 2.75rem;
  margin-bottom: 2.94rem;
`;
const Image = styled.img`
  width: 100%;
  // height: 100%;
  // height: 13.75rem;
  border-radius: 1.88rem;
  object-fit: cover;
`;

const DivImage = styled.div`
  width: 19.69rem;
  // height: auto;
  height: 13.75rem;
  border-radius: 1.88rem;
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

const WatermarkImage = styled.img`
  position: absolute;
  width: 4.88rem;
  height: 0.88rem;
  bottom: 1rem;
  right: 1.13rem;
`;

const ResultContainer = styled.div`
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  padding-top: 0.94rem;
  padding-bottom: 0.94rem;
  border-radius: 0.63rem;
  background: #f4f9f8;
  width: 100%;
  margin-bottom: 1.44rem;
  text-align: center;
`;

const ResultDescription = styled.span`
  font-size: 1.13rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 500;
  line-height: 1.88rem;
`;

const ResultText = styled.span`
  font-size: ${({ fontSize }) => fontSize || "2.25rem"};
  line-height: 2.75rem;
  color: ${({ theme }) => theme.primary.normal};
  font-weight: 700;
  font-family: "Montserrat";
`;

const TipContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.75rem;
  width: 100%;
`;
const TipIcon = styled.img`
  width: 34px;
  margin-right: 12px;
`;
const TipText = styled.p`
  font-size: 0.88rem;
  font-weight: 400;
  color: ${({ theme }) => theme.primary.dark};
  line-height: 1.25rem;
  letter-spacing: -0.04em;
`;

const IconButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const RetryButton = styled.button`
  border: none;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  font-size: 0.94rem;
  line-height: 1.44rem;
  font-weight: 500;
  background: none;
  display: flex;
  align-items: center;
  // margin: 0 auto;
  // margin-right: 0;
`;

const ShareButton = styled.img`
  width: 21px;
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px dashed #d9d9d9;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
`;

const LevelContainer = styled.div`
  padding: 1.56rem;
  border: 1px solid #e6f0f0;
  border-radius: 0.94rem;
  width: 100%;
  margin-bottom: 2.38rem;
  display: flex;
  justify-content: space-between;
`;

const LevelItemList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const LevelItem = styled.li`
  display: flex;
  flex-direction: column;
  color: ${({ theme, highlight }) => theme.grayScaleColor.gray30};
  // margin-bottom: 1.06rem;
  // &:nth-child(5) {
  //   margin-bottom: 0;
  //   border: 1px solid black;
  // }
`;

const LevelItemTop = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.94rem;
  margin-bottom: 0.5rem;
`;
const LevelText = styled.span`
  font-weight: 600;
  color: ${({ theme, highlight }) => highlight && theme.primary.normal};
`;
const LevelPercent = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  margin-left: 0.8rem;
`;
const LevelItemBottom = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.88rem;
`;

const LevelDesc = styled.span`
  font-weight: 500;
  color: ${({ theme, highlight }) => highlight && theme.primary.normal};
`;

const LevelDuchenneMark = styled.span`
  border: 1px solid
    ${({ theme, highlight }) =>
      highlight ? theme.primary.normal : theme.grayScaleColor.gray30};
  border-radius: 3.13rem;
  font-size: 0.75rem;
  padding: 0.19rem 0.5rem;
  margin-left: 0.5rem;
  color: ${({ theme, highlight }) => highlight && theme.primary.normal};
`;

const LevelGraphContainer = styled.div`
  width: 24px;
  height: 312px;
  position: relative;
`;

const Bar = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(
    rgba(0, 210, 193, 0.4) 0%,
    rgba(214, 248, 245, 0.2) 100%
  );
  border-radius: 100px;
`;
const BarSeperatorContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 1;
`;
const BarSeperator = styled.div`
  border: 1px solid white;
  width: 100%;
`;

const ResultBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 6.25rem;
  width: 100%;
  height: ${({ result }) => `${result}%`};
  background: ${({ theme }) => theme.primary.normal};
`;

const DescriptionContainer = styled.div`
  border-radius: 0.94rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  padding: 2.19rem 1.25rem;
  width: 100%;
  margin-bottom: 2.88rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const DescriptionHeader = styled.h3`
  font-weight: 700;
  font-size: 1.13rem;
  line-height: 1.5rem;
`;

const DescriptionText = styled.p`
  font-size: 0.94rem;
  line-height: 1.44rem;
  letter-spacing: -0.04em;
`;

const ExplainContainer = styled.div`
  // display: flex;
  // flex-direction: column;
`;

const ExplainLogo = styled.img`
  width: 18px;
  height: 17px;
`;

const ExplainText = styled.p`
  font-size: 0.88rem;
  line-height: 1.25;
  letter-spacing: -0.04em;
  margin-top: 10px;
  color: ${({ theme }) => theme.primary.normal};
`;

const SharePage = styled.div`
  display: flex;
  width: calc(100% + 60px);
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
`;

const ShareImageContainer = styled.div`
  width: calc(100% + 60px);
`;
const ShareImage = styled.img`
  width: 100%;
`;

const FooterContainer = styled.footer`
  text-align: center;
  letter-spacing: -0.04em;
  font-weight: 400;
  margin-bottom: 1.56rem;
`;

const FooterText = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  line-height: 1.13rem;
  margin-bottom: 1px;
  font-size: 0.75rem;
`;

const FooterCopyright = styled.span`
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  font-size: 0.69rem;
  line-height: 1.5rem;
`;

const RetrySvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "0.25rem" }}
  >
    <g clipPath="url(#clip0_1406_5215)">
      <path
        d="M14.7081 5.29182C13.3497 3.93349 11.4247 3.15016 9.30807 3.36682C6.24973 3.67516 3.73307 6.15849 3.3914 9.21682C2.93307 13.2585 6.05807 16.6668 9.99973 16.6668C12.6581 16.6668 14.9414 15.1085 16.0081 12.8668C16.2747 12.3085 15.8747 11.6668 15.2581 11.6668C14.9497 11.6668 14.6581 11.8335 14.5247 12.1085C13.5831 14.1335 11.3247 15.4168 8.85807 14.8668C7.00807 14.4585 5.5164 12.9502 5.12473 11.1002C4.42473 7.86682 6.88307 5.00016 9.99973 5.00016C11.3831 5.00016 12.6164 5.57516 13.5164 6.48349L12.2581 7.74182C11.7331 8.26682 12.0997 9.16682 12.8414 9.16682H15.8331C16.2914 9.16682 16.6664 8.79182 16.6664 8.33349V5.34182C16.6664 4.60016 15.7664 4.22516 15.2414 4.75016L14.7081 5.29182Z"
        fill="#777777"
      />
    </g>
    <defs>
      <clipPath id="clip0_1406_5215">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const GraphIndicatorSvg = ({ result }) => (
  <svg
    width="33"
    height="21"
    viewBox="0 0 33 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      position: "absolute",
      bottom: `calc(${result}% - 10px)`,
      left: -40,
    }}
  >
    <path
      d="M10.1541 20.375C15.7621 20.375 32.6 10.1875 32.6 10.1875C32.6 10.1875 15.7621 -6.88981e-07 10.1541 -4.4385e-07C4.54614 -1.98718e-07 1.99372e-07 4.5611 4.4531e-07 10.1875C6.91248e-07 15.8139 4.54615 20.375 10.1541 20.375Z"
      fill="#00DBB1"
    />
    <circle
      r="3.39583"
      transform="matrix(4.37114e-08 1 1 -4.37114e-08 11.5462 10.1878)"
      fill="white"
    />
  </svg>
);

const AiResultMobile = () => {
  const { auth } = useAuth();
  const {
    state: { data, fileName, name, result, level, cid },
  } = useLocation();

  const [smile, setSmile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [shareImage, setShareImage] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [statistic, setStatistic] = useState();
  const [showSavePhotoDialog, setShowSavePhotoDialog] = useState({
    open: false,
    msg: "",
  });
  const navigate = useNavigate();
  const sharePage = useRef();
  const shareImageRef = useRef();

  const downloadImage = (blob, fileName) => {
    const link = window.document.createElement("a");
    link.style = "display: none;";
    link.download = fileName;
    link.href = blob;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    link.remove();
  };

  const exportAsImage = async (el, fileName) => {
    const canvas = await html2canvas(el, { scale: 2 });
    const image = canvas.toDataURL("image/png", 1.0);
    // downloadImage(image, fileName);
    return image;
  };

  const handleSavePhotoClick = async () => {
    const img = dataURLtoFile(data, fileName);
    const formData = new FormData();

    formData.append("name", auth.user.nickname);
    formData.append("cid", auth.user.companyId._id);
    formData.append("uid", auth.user.id);
    formData.append("file", img);
    formData.append("score", result);
    formData.append("isPublic", false);

    let msg = "";
    try {
      await uploadSmilePhoto({ auth, formData });
      msg = `오늘의 미소 저장 완료!\nPC 대시보드에서 확인할 수 있어요😉`;
    } catch (err) {
      msg = `이미 사진을 저장했습니다!`;
    } finally {
      setShowSavePhotoDialog({ open: true, msg });
    }
  };

  const handleShareClick = async (el, fileName) => {
    // exportAsImage(el, fileName);
    if (navigator.share) {
      try {
        await navigator.share({
          // title: "나만의 아이트리 만들기",
          text: "나의 미소는 어떤 미소일까요? AI가 판별해 드립니다.",
          url: "https://app.maum.fit/smile-challenge",
          files: [shareImage],
        });
        // alert("finish!");
      } catch (err) {
        // console.log(err);
        // alert(err);
        window.location.reload();
      }
    } else {
      console.log("not supported");
    }
  };

  const handleUploadClick = () => {
    setUploadImage(true);
  };

  const handleUploadOkClick = () => {
    if (cid) {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE)
          ? `${ROUTES.GUEST_SMILE_CHAMPIONS}/${cid}}`
          : ROUTES.SMILE_CHAMPIONS
      );
    } else {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE)
          ? ROUTES.GUEST_SMILE_CHAMPIONS
          : ROUTES.SMILE_CHAMPIONS
      );
    }
  };

  const checkRange = (num) => {
    if (num >= 80) {
      return 5;
    } else if (num >= 60) {
      return 4;
    } else if (num >= 40) {
      return 3;
    } else if (num >= 20) {
      return 2;
    } else {
      return 1;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const response = await getSmile(level);
      const averageResponse = await getSmileAverage({
        auth: includesPathname(ROUTES.GUEST_SMILE) ? null : auth,
        cid,
      });

      let ratio =
        (averageResponse.result[`level${level}Count`] /
          averageResponse.result.totalCount) *
        100;
      setStatistic(ratio % 1 === 0 ? ratio : ratio.toFixed(2));
      // console.log(response.data.smile[level - 1]);
      setSmile(response.smile);
      setIsLoading(false);

      // setSmile(response.data.smile[level - 1]);
      // setSubTitles(response.data.smile.map((i) => i.subTitle));
      //   imageRef.current.setAttribute("src", data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const renderingImage = async () => {
      const image = await exportAsImage(sharePage.current, "test");
      shareImageRef.current.src = image;
      // console.log("image added");
      sharePage.current.style = "display:none";
      const blob = await (await fetch(image)).blob();
      const file = new File([blob], `ai-smile-${Date.now()}.png`, {
        type: blob.type,
      });
      setShareImage(file);
      // console.log("display removed");
    };

    renderingImage();
  }, [isLoading]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <SharePage ref={sharePage}>
        <SubHeading
          text={`Lv. ${smile[level - 1].level}`}
          style={{
            fontWeight: 700,
            marginBottom: "1.25rem",
            borderStyle: "solid",
            borderBottomWidth: 3,
          }}
        />
        <Heading
          text={`${smile[level - 1].title}\n${smile[level - 1].subTitle}`}
          style={{
            fontSize: "1.75rem",
            lineHeight: "2.38rem",
          }}
        />
        {level === 5 && (
          <StampImage
            src={`${process.env.PUBLIC_URL}/assets/smile-stamp.png`}
          />
        )}
        <Description
          text={`${smile[level - 1].summary}`}
          style={{ fontSize: "0.94rem", lineHeight: "1.44rem" }}
        />
        <ImageContainer>
          <Image src={data} />
          {/* <DivImage img={data} /> */}
          <WatermarkImage
            src={`${process.env.PUBLIC_URL}/assets/logo/watermark_logo.png`}
          />
        </ImageContainer>
        <ResultContainer>
          <ResultDescription>내 미소가 진짜로 보일 가능성</ResultDescription>
          <br />
          <ResultText>
            {result}
            <span style={{ fontSize: "1.5rem" }}>%</span>
          </ResultText>
        </ResultContainer>
      </SharePage>
      <ShareImageContainer>
        <ShareImage ref={shareImageRef} />
      </ShareImageContainer>

      <TipContainer>
        <TipIcon
          src={`${process.env.PUBLIC_URL}/assets/ai-smile/ai_smile_explain_symbol.png`}
        />
        <TipText>
          행복 미소 챌린지는 AI 미소 엔진을 사용해 뒤센 미소(찐-미소)와 사회적
          미소를 구별하고 있어요.{" "}
        </TipText>
      </TipContainer>
      <Button
        text={
          isAuthEmpty(auth) || includesPathname(ROUTES.GUEST_SMILE)
            ? "📢 결과 공유하기"
            : "내 미소 저장하기"
        }
        clickHandler={() =>
          isAuthEmpty(auth) || includesPathname(ROUTES.GUEST_SMILE)
            ? handleShareClick(sharePage.current, fileName)
            : handleSavePhotoClick()
        }
      />
      <Button
        text={"찐-미소 챔피언즈 올리기"}
        clickHandler={handleUploadClick}
        style={{
          backgroundColor: "#EDF3F3",
          color: "#7C8585",
          marginTop: "10px",
        }}
      />
      <IconButtonContainer>
        <RetryButton
          onClick={() =>
            navigate(
              includesPathname(ROUTES.GUEST_SMILE)
                ? ROUTES.GUEST_SMILE_POPUP
                : ROUTES.SMILE_POPUP,
              { state: { cid } }
            )
          }
        >
          <RetrySvg />
          다시 도전!
        </RetryButton>
        {!isAuthEmpty(auth) && !includesPathname(ROUTES.GUEST_SMILE) && (
          <ShareButton
            onClick={handleShareClick}
            src={`${process.env.PUBLIC_URL}/assets/ai-smile/icon_share.png`}
          />
        )}
      </IconButtonContainer>
      <Divider />
      <LevelContainer>
        <LevelItemList>
          {[5, 4, 3, 2, 1].map((item, index) => (
            <LevelItem key={index}>
              <LevelItemTop>
                <LevelText highlight={item === checkRange(result)}>
                  Lv. {item}
                </LevelText>
                <LevelPercent>{`${20 * (item - 1)} ~ ${
                  20 * item
                }%`}</LevelPercent>
              </LevelItemTop>
              <LevelItemBottom>
                <LevelDesc highlight={item === checkRange(result)}>
                  {item === 5 ? "광대승천 찐-미소" : smile[level - 1].subTitle}
                </LevelDesc>
                {item === 5 && (
                  <LevelDuchenneMark highlight={item === checkRange(result)}>
                    뒤센 미소
                  </LevelDuchenneMark>
                )}
              </LevelItemBottom>{" "}
            </LevelItem>
          ))}
        </LevelItemList>
        <LevelGraphContainer>
          <Bar />
          <BarSeperatorContainer>
            <BarSeperator />
            <BarSeperator />
            <BarSeperator />
            <BarSeperator />
          </BarSeperatorContainer>
          <ResultBar result={result} />
          <GraphIndicatorSvg result={result} />
        </LevelGraphContainer>
      </LevelContainer>
      <ResultContainer>
        <ResultText fontSize={"1.63rem"}>
          {statistic}
          <span style={{ fontSize: "1.13rem" }}>%</span>
        </ResultText>
        <ResultDescription>
          {" "}
          의 챌린저들이 <br />
          나와 같은 미소를 짓고 있어요!
        </ResultDescription>
      </ResultContainer>
      <DescriptionContainer>
        <DescriptionHeader>
          내 미소는 레벨 {level}, {smile[level - 1].subTitle}
        </DescriptionHeader>
        <br />
        <br />
        <DescriptionText>
          {lineBreaker(
            smile[level - 1].description.replace("{result}", `${result}%`)
          )}
        </DescriptionText>
      </DescriptionContainer>
      <FooterContainer>
        <FooterText>
          맘핏은 블룸컴퍼니에서 운영하고 있는
          <br />
          직장인을 위한 구독형 마음관리 서비스입니다.
        </FooterText>
        <FooterCopyright>ⓒ BLOOM COMPANY</FooterCopyright>
      </FooterContainer>
      {uploadImage && (
        <Modal>
          <UploadPhotoDialog
            imageUrl={data}
            fileName={fileName}
            cid={cid}
            score={result}
            okHandler={handleUploadOkClick}
            closeHandler={() => setUploadImage(false)}
          />
        </Modal>
      )}
      {showSavePhotoDialog.open && (
        <Modal>
          <SavePhotoDialog
            text={showSavePhotoDialog.msg}
            okHandler={() => setShowSavePhotoDialog({ open: false, msg: "" })}
          />
        </Modal>
      )}
    </>
  );
};

export default AiResultMobile;
