import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FlexColumn from "../../components/FlexColumn";

const Container = styled.div`
  background: #ffc842;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 415px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
`;

const Logo = styled.img`
  margin-top: 8rem;
  width: 6.94rem;
  height: auto;
  margin-bottom: 2.38rem;
`;

const LogoText = styled.span`
  font-size: 1.31rem;
  color: #111111;
  font-weight: 700;
  font-family: Comfortaa;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 2rem;
  position: absolute;
  bottom: 2.06rem;
`;

const FooterImage = styled.img`
  margin-bottom: 0.25rem;
  width: 2.94rem;
`;

const FooterCopyRight = styled.span`
  font-size: 0.69rem;
  color: #db9b00;
  font-weight: 300;
  font-family: "Montserrat";
`;

const TextBox = styled.div`
  width: 100%;
  padding: 0.75rem 1rem;
  background: #ffe199;
  color: #000;
  font-size: 0.88rem;
  line-height: 1.13rem;
  font-weight: 500;
  // margin-top: 7rem;
  text-align: center;
  border-radius: 0.75rem;
`;

const Button = styled.button`
  width: 100%;
  height: 3.44rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.grayScaleColor.black};
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-size: 1.13rem;
  line-height: 1.25rem;
  font-weight: 500;
  border: none;
  border-radius: 0.44rem;
  margin-top: 0.75rem;
`;

const Polygon = ({ style }) => {
  return (
    <svg
      style={style}
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.0359 18C9.5755 20.6667 13.4245 20.6667 14.9641 18L21.4593 6.75C22.9989 4.08333 21.0744 0.75 17.9952 0.75H5.00481C1.9256 0.75 0.00110669 4.08333 1.54071 6.75L8.0359 18Z"
        fill="#FFE199"
      />
    </svg>
  );
};

const Splash = () => {
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/itree/1");
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_SMART_TOOL_SERVER_BASE_URL}/itree`
      );
      if (response.data.ok) {
        setCount(response.data.count);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <Helmet>
        <title>아이트리</title>
        <meta name="theme-color" content="#ffc842" />
      </Helmet>
      <Wrapper>
        <Logo src={`${process.env.PUBLIC_URL}/assets/itree/itree_logo.png`} />
        <LogoText>design your mind</LogoText>

        <FlexColumn
          style={{
            alignItems: "center",
            position: "absolute",
            bottom: "8rem",
          }}
        >
          <TextBox>
            올해 온라인에서 {(889 + count).toLocaleString()} 그루의 나무를
            심었어요🌱
          </TextBox>
          <Polygon style={{ marginTop: -8 }} />
          <Button onClick={handleClick}>눌러서 시작하기</Button>
        </FlexColumn>
        <Footer>
          <FooterImage
            src={`${process.env.PUBLIC_URL}/assets/itree/itree_footer.png`}
          />
          <FooterCopyRight>&copy; BLOOM COMPANY</FooterCopyRight>
        </Footer>
      </Wrapper>
    </Container>
  );
};

export default Splash;
