import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "react-slideshow-image/dist/styles.css";
import { useEffect, useRef, useState } from "react";
import axios from "../api/axios";
import FlexRow from "../components/FlexRow";
import FlexColumn from "../components/FlexColumn";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import "react-slideshow-image/dist/styles.css";
import { MOBILE } from "../Constants";

import { isAuthEmpty } from "../utils/isAuthEmpty";
import { Helmet } from "react-helmet";
import validatePhone from "../utils/validatePhone";
import Modal from "./dashboard/components/Modal";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 5.75rem);
  background: ${({ theme }) => theme.grayScaleColor.white};
  // border: 1px solid black;
`;

const Heading1 = styled.h2`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 2rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Heading2 = styled.h2`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const Heading3 = styled.h3`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  margin-top: 2.56rem;
  margin-bottom: 1.25rem;
`;

const Heading4 = styled.h4`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const Section = styled.section`
  padding: 0 1.25rem;
  width: 100%;
`;
const GridContainer = styled.div`
  width: 100%;
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  padding: 0 1.25rem;
  gap: 1.81rem;
`;

const BadgeContainer = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 1.25rem;
  background: ${({ theme, isTrue }) =>
    isTrue ? theme.primary.light : theme.grayScaleColor.gray5};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BadgeText = styled.span`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & h2 {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 1.8rem */
    letter-spacing: -0.03125rem;
    color: #000;
    margin-bottom: 1.5rem;
  }
  & p {
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.3125rem */
    letter-spacing: -0.03125rem;
    color: ${({ theme }) => theme.grayScaleColor.gray70};
    margin-top: 1.5rem;
    margin-bottom: 1.2rem;
  }
  & button {
    width: 100%;
    border-radius: 0.75rem;
    background: ${({ theme }) => theme.primary.normal};
    padding: 0.75rem 0;
    border: none;
    color: ${({ theme }) => theme.grayScaleColor.white};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4375rem; /* 164.286% */
    letter-spacing: -0.035rem;
  }
`;

const BadgeItem = ({ icon, text, isTrue, onClick }) => {
  return (
    <FlexColumn style={{ gap: "1rem", alignItems: "center" }}>
      <BadgeContainer isTrue={isTrue} onClick={onClick}>
        {isTrue ? (
          <img src={icon} alt="badge icon" style={{ width: "2.5rem" }} />
        ) : (
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_6765_29726)">
              <path
                d="M31.1869 28.2814V4.59095C31.1869 2.06004 29.138 0 26.6208 0H4.56608C2.04888 0 0 2.06004 0 4.59095V29.488C0 32.0189 2.04888 34.0789 4.56608 34.0789H33.6163C34.8456 34.0789 35.4603 32.5781 34.5968 31.7099L31.1869 28.2814ZM15.6008 27.6487C10.9323 27.6487 7.09791 23.9848 6.77595 19.3791C6.74668 19.0407 7.03938 18.7611 7.36134 18.7611H9.27851C9.58584 18.7611 9.83464 19.0112 9.86391 19.3202C10.142 22.2484 12.6006 24.5586 15.6008 24.5586C18.6009 24.5586 21.0596 22.2631 21.3376 19.3202C21.3669 19.0112 21.6011 18.7611 21.923 18.7611H23.8402C24.1768 18.7611 24.4549 19.0407 24.4256 19.3791C24.1036 23.9995 20.2693 27.6487 15.6008 27.6487Z"
                fill="#EEEEEE"
              />
            </g>
            <defs>
              <clipPath id="clip0_6765_29726">
                <rect width="35" height="34.0789" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </BadgeContainer>
      <BadgeText>{text}</BadgeText>
    </FlexColumn>
  );
};

function MypageBadge() {
  const { auth, setAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [badges, setBadges] = useState([]);
  const [currentBadge, setCurrentBadge] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const onBadgeClick = (e, badge) => {
    setShow(true);
    setCurrentBadge(badge);
  };

  useEffect(() => {
    const fetch = async () => {
      const res = await axiosPrivate.get("/badge");

      setBadges(res.data.data.badges);
      setIsLoading(false);
    };

    if (isAuthEmpty(auth)) {
      return navigate("/login");
    }
    fetch();
  }, []);
  // console.log(surveyYearMonth, monthPeriod);
  if (isLoading) {
    return <div />;
  }

  return (
    <Container>
      <Helmet>
        <meta name="theme-color" content="#fff" />
      </Helmet>
      <Section style={{ paddingTop: "1.75rem" }}>
        <Heading1 style={{ marginBottom: "0.5rem" }}>맘핏 뱃지</Heading1>
        <Heading4>맘핏 활동을 통해 나만의 뱃지를 획득해 보세요.</Heading4>
        <div
          style={{
            width: "100%",
            height: "1px",
            background: "#D9D9D9",
            marginTop: "2.25rem",
            marginBottom: "2.25rem",
          }}
        />
      </Section>
      <Section
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "9rem",
        }}
      >
        <GridContainer>
          {badges.map((item, index) => (
            <BadgeItem
              key={item._id}
              onClick={(e) => onBadgeClick(e, item)}
              icon={item.iconUrl}
              text={item.title}
              isTrue={auth.user.badges.includes(item._id)}
            ></BadgeItem>
          ))}
        </GridContainer>
      </Section>
      <Modal showing={show} width={"18.75rem"} padding={"2.19rem 1.87rem"}>
        <ModalContainer>
          <h2>{currentBadge?.title}</h2>
          <BadgeContainer isTrue={auth.user.badges.includes(currentBadge?._id)}>
            {auth.user.badges.includes(currentBadge?._id) ? (
              <img
                src={currentBadge?.iconUrl}
                alt="badge icon"
                style={{ width: "2.5rem" }}
              />
            ) : (
              <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_6765_29726)">
                  <path
                    d="M31.1869 28.2814V4.59095C31.1869 2.06004 29.138 0 26.6208 0H4.56608C2.04888 0 0 2.06004 0 4.59095V29.488C0 32.0189 2.04888 34.0789 4.56608 34.0789H33.6163C34.8456 34.0789 35.4603 32.5781 34.5968 31.7099L31.1869 28.2814ZM15.6008 27.6487C10.9323 27.6487 7.09791 23.9848 6.77595 19.3791C6.74668 19.0407 7.03938 18.7611 7.36134 18.7611H9.27851C9.58584 18.7611 9.83464 19.0112 9.86391 19.3202C10.142 22.2484 12.6006 24.5586 15.6008 24.5586C18.6009 24.5586 21.0596 22.2631 21.3376 19.3202C21.3669 19.0112 21.6011 18.7611 21.923 18.7611H23.8402C24.1768 18.7611 24.4549 19.0407 24.4256 19.3791C24.1036 23.9995 20.2693 27.6487 15.6008 27.6487Z"
                    fill="#EEEEEE"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6765_29726">
                    <rect width="35" height="34.0789" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </BadgeContainer>
          <p>{currentBadge?.description}</p>
          <button onClick={() => setShow(false)}>확인 완료</button>
        </ModalContainer>
      </Modal>
    </Container>
  );
}

export default MypageBadge;
