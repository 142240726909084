import styled from "styled-components";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import Bargraph from "./components/Bargraph";

const Container = styled.div`
  line-height: 140%;
`;

const Title = styled.p`
  font-size: 0.875rem;
  font-weight: 500;

  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  margin-bottom: 1rem;
`;
const Data = styled.p`
  font-size: 1.375rem;

  font-weight: 600;

  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const Separator = styled.div`
  height: 100%;
  width: 1px;
  background: ${({ theme }) => theme.grayScaleColor.gray20};
  // margin: 0 1.5rem;
`;

const NumberText = styled.p`
  width: 1.625rem;
  height: 1.5625rem;
  padding: 0.25rem 0.5rem;
  color: ${({ theme, highlight }) =>
    highlight ? theme.primary.normal : theme.grayScaleColor.gray70};
  margin-right: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  background: ${({ theme, highlight }) =>
    highlight ? theme.primary.light : theme.grayScaleColor.gray5};
  border-radius: 0.4375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`;

const ItemTitle = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  flex: 1;
  margin-right: 0.5rem;
`;

const ItemValue = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  flex: 0.5;
  text-align: right;
`;

const Card5 = ({ data, style }) => {
  return (
    <Container>
      <FlexColumn style={{ gap: "2rem" }}>
        {data?.map((item, index) => (
          <FlexRow
            style={{
              height: "100%",
              //   width: "22.5rem",

              alignItems: "center",
            }}
            key={index}
          >
            <NumberText highlight={index < 3}>{index + 1}</NumberText>
            <ItemTitle>{item.word}</ItemTitle>
            <Bargraph
              value={item.percentage}
              style={{
                flex: 1.2,
                marginRight: "1rem",
              }}
            />
            <ItemValue>{item.percentage}%</ItemValue>
          </FlexRow>
        ))}
      </FlexColumn>
    </Container>
  );
};

export default Card5;
