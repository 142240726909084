import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { MOBILE, TYPE_MEMBER } from "../../Constants";
import useAuth from "../../hooks/useAuth";
import { isAuthEmpty } from "../../utils/isAuthEmpty";
import TopBar from "../TopBar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ isMobile }) => (isMobile ? "0 0" : "5.63rem 0")};
  background-color: ${({ theme, isMobile }) =>
    isMobile ? theme.grayScaleColor.white : theme.backgroundColor.darkgray20};
  width: 100%;
  // max-height: (100vh - 80px);
  // height: 100%;
  //   height: cal(100%);
  overflow: auto;
  // border: 1px solid black;
`;

const SignupLayout = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE})`,
  });
  useEffect(() => {
    if (isMobile) {
      document.body.style.background = "#FFF";
    } else {
      document.body.style.background = "#EDF3F3";
    }
    // document.body.style.marginTop = "100px";
  }, []);
  return (
    <Container isMobile={isMobile}>
      <Outlet />
    </Container>
  );
};

export default SignupLayout;
