import styled from "styled-components";

const Text = styled.h2`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.81rem;
`;

const QuestionHeading = ({ children, style }) => {
  return <Text style={style}>{children}</Text>;
};

export default QuestionHeading;
