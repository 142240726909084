import { useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 0.75rem 1rem;
  border-radius: 0.4375rem;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  background: white;
  position: relative;
`;

const Label = styled.label`
  font-family: Pretendard;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.125rem */
  letter-spacing: -0.03125rem;
  color: #b5b5b5;
  margin-bottom: 0.25rem;
`;

const SInput = styled.input`
  background: white;
  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  border: none;
  color: ${({ theme }) => theme.grayScaleColor.black};
  &::placeholder {
    color: #d9d9d9;
  }
  outline: none;
  padding: 0;
`;

const SClearButton = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  bottom: 0.75rem;
  right: 1rem;
`;

const ClearButton = ({ show, onMouseDown }) => {
  if (show) {
    return (
      <SClearButton
        src={`${process.env.PUBLIC_URL}/assets/icons/clear_button_icon.png`}
        onMouseDown={onMouseDown}
      />
    );
  }
};

const InputTwoLine = ({
  property,
  type,
  label,
  placeholder,
  onChange,
  value,
  setValue,
  style,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const ref = useRef();
  if (property === "Two-line") {
    const onFocus = () => {
      ref.current.style = "border: 1px solid #000;";
      setIsFocus(true);
    };
    const onBlur = () => {
      ref.current.style = "border: 1px solid #D9D9D9;";
      setIsFocus(false);
    };
    const handleClear = (e) => {
      e.stopPropagation();
      setValue("");
    };
    return (
      <Container ref={ref} style={style}>
        <Label>{label}</Label>
        <SInput
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          {...props}
        />
        <ClearButton
          show={value.length > 0 && isFocus}
          onMouseDown={handleClear}
        />
      </Container>
    );
  }

  return (
    <SInput
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  );
};

export default InputTwoLine;
