import { useRef } from "react";
import styled from "styled-components";
import useAuth from "../hooks/useAuth";

const Container = styled.div`
  width: 100%;
  padding-bottom: 8.31rem;
  scroll-behavior: smooth;
`;

const Description = styled.p`
  font-size: 1.25rem;
  line-height: 1.88rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 2.5rem;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6.25rem;
`;

const ImageButton = styled.div`
  width: 14.25rem;
  height: 22.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${({ theme, disabled }) =>
    !disabled ? theme.backgroundColor.darkgray10 : theme.grayScaleColor.gray70};
  border-radius: 0.75rem;
  ${({ disabled }) => disabled && "opacity: 0.6"};
  cursor: pointer;
  &:hover {
    ${({ disabled }) =>
      !disabled && "box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1)"};
    background: ${({ theme, disabled }) => !disabled && theme.primary.light};
  }
  transition: all 0.3s;
  // border: 1px solid black;
`;

const Image = styled.img`
  width: 11.63rem;
  height: 17.88rem;
  // border: 1px solid black;
`;

const DisabledText = styled.p`
  font-size: 1.31rem;
  font-weight: 400;
  line-height: 1.44rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  border: 2px solid ${({ theme }) => theme.grayScaleColor.white};
  padding: 0.63rem 1.56rem;
  border-radius: 6.25rem;
  position: absolute;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  margin-bottom: 4.69rem;
`;

const Section = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  // margin-top: 3.75rem;
  // border: 1px solid black;
`;

const Spacer = styled.div`
  width: 100%;
  height: 3.5rem;
  // border: 1px solid red;
  margin: 3.75rem 0;
`;

const SectionImage = styled.img`
  width: 100%;
  height: auto;
  // margin-bottom: 3.75rem;
`;

const SectionHeader = styled.h2`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const FloatingButton = styled.div`
  width: 3.13rem;
  height: 3.13rem;
  border-radius: 0.31rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
  background: ${({ theme }) => theme.primary.normal};
  position: fixed;
  bottom: 10%;
  left: calc(50% + 35.75rem);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Guide = () => {
  const { auth } = useAuth();
  const topRef = useRef();
  const sectionRefs = useRef([]);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  };

  const scrollTo = (index) => {
    sectionRefs.current[index].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  };
  return (
    <Container>
      <Description ref={topRef}>
        {auth.user.name}님의 건강한 마음 관리를 위해
        <br /> 다양한 맘핏 센터 활용 방법을 알려 드릴게요😊
      </Description>
      <ImageContainer>
        {[1, 2, 3, 4].map((item, index) => (
          <ImageButton
            key={index}
            disabled={item === 4}
            onClick={() => scrollTo(index)}
          >
            <Image
              src={`${process.env.PUBLIC_URL}/assets/guide/guide_${item}.png`}
            />
            {item === 4 && <DisabledText>오픈 예정</DisabledText>}
          </ImageButton>
        ))}
      </ImageContainer>
      <Divider />

      {[1, 2, 3].map((item, index) => (
        <Section
          key={item}
          ref={(element) => {
            sectionRefs.current[index] = element;
          }}
        >
          <SectionImage
            src={`${process.env.PUBLIC_URL}/assets/guide/section_${item}.png`}
          />
        </Section>
      ))}
      <FloatingButton onClick={goToTop}>
        <svg
          width="16"
          height="21"
          viewBox="0 0 16 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.70711 0.542893C8.31658 0.152369 7.68342 0.152369 7.29289 0.542893L0.928932 6.90685C0.538408 7.29738 0.538408 7.93054 0.928932 8.32107C1.31946 8.71159 1.95262 8.71159 2.34315 8.32107L8 2.66421L13.6569 8.32107C14.0474 8.71159 14.6805 8.71159 15.0711 8.32107C15.4616 7.93054 15.4616 7.29738 15.0711 6.90685L8.70711 0.542893ZM7 20C7 20.5523 7.44771 21 8 21C8.55228 21 9 20.5523 9 20L7 20ZM7 1.25L7 20L9 20L9 1.25L7 1.25Z"
            fill="white"
          />
        </svg>
      </FloatingButton>
    </Container>
  );
};

export default Guide;
