import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const DimContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.grayScaleColor.black};
  opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Popup = styled.div`
  position: relative;
  width: 23.31rem;
  height: 11.06rem;
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.p`
  line-height: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1.25rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 300;
  font-size: 1rem;
  padding: 0 3.6rem;
  letter-spacing: -0.04em;
  text-align: center;
`;

const Button = styled.button`
  width: 12.5rem;
  height: 2.81rem;
  border-radius: 0.31rem;
  border: none;
  background-color: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
  cursor: pointer;
  font-weight: 500;
  font-size: 0.94rem;
  //   &:hover {
  //     background-color: ${({ theme }) => theme.primary.hover};
  //   }
  //   transition: 0.3s;
`;

const ToastPopup = ({ text, buttonText, onClick }) => {
  return (
    <Container>
      <DimContainer></DimContainer>
      <Popup>
        <Text>
          {text.split("<br />").map((item, index) => {
            return (
              <span key={index}>
                {item}
                <br />
              </span>
            );
          })}
        </Text>
        <Button onClick={onClick}>{buttonText}</Button>
      </Popup>
    </Container>
  );
};

export default ToastPopup;
