import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { converDate } from "../../../utils/convertDate";
import CheckupEmotionChart from "./CheckupEmotionChart";
import CheckupRecommendContent from "./CheckupRecommendContent";
import { CheckupResultChart } from "./CheckupResultChart";
import Button from "./components/Button";

const Container = styled.div`
  width: 100%;
  max-width: 62.5rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4.06rem);
  @media (max-width: 425px) {
    width: 23.44rem;
    max-width: 23.44rem;
    padding: 0 1.25rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubTitle = styled.h3`
  font-size: 1.13rem;
  color: ${({ theme }) => theme.primary.dark};
  font-weight: 500;
  margin-top: 2.31rem;
  margin-bottom: 0.31rem;
  line-height: 1.63rem;
  @media (max-width: 425px) {
    font-size: 0.94rem;
    line-height: 1.44rem;
    margin-top: 2rem;
    margin-bottom: 0;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 700;
  margin-bottom: 2.81rem;
  line-height: 2rem;
  letter-spacing: -0.03em;
  @media (max-width: 425px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 1.38rem;
    font-weight: 500;
  }
`;

const ScoreBox = styled.div`
  padding: 1.94rem 2.38rem;
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 0.63rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 3.75rem;
  @media (max-width: 425px) {
    padding: 1.25rem;
    margin-bottom: 3.19rem;
  }
`;
const ScoreHeading2 = styled.span`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  @media (max-width: 425px) {
    font-size: 0.94rem;
    line-height: 1.44rem;
  }
`;

const ScoreHeading1 = styled.span`
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0.38rem 0;
  line-height: 2.88rem;
  @media (max-width: 425px) {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 0.13rem;
    margin-bottom: 0.38rem;
    line-height: 2rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 500;
  margin-bottom: 1.13rem;
  @media (max-width: 425px) {
    font-size: 1.13rem;
    line-height: 1.63rem;
    margin-bottom: 1.25rem;
  }
`;

const CheckupInfo = styled.div`
  width: 100%;
  font-size: 0.94rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 1.55rem;
  margin-top: 1.19rem;
`;

const CheckupInfoText = styled.span`
  @media (max-width: 425px) {
    font-size: 0.94rem;
    line-height: 1.44rem;
    font-weight: 300;
  }
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  margin: 3.75rem 0;
  @media (max-width: 425px) {
    margin-top: 2.5rem;
    margin-bottom: 2.31rem;
  }
`;

const CheckupResult = () => {
  const {
    state: { results, recommendContentType, score, name },
    search,
  } = useLocation();
  // console.log(results, recommendContentType);
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [lastCheckup, setLastCheckup] = useState({});
  const [content, setContent] = useState();
  const [checkupCount, setCheckupCount] = useState(0);
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });
  // console.log(auth);

  const handleClick = () => {
    window.open(
      'https://zep.us/play/8ZwAV4??customData={"blockLocation": "dashboard"}'
    );
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await axiosPrivate.get(`/checkup`);
      setLastCheckup({
        score: response.data.lastScore,
        answers: response.data.lastAnswers,
        date: response.data.lastCheckupDate,
      });
      setCheckupCount(response.data.checkupCount);
      const contentResponse = await axiosPrivate.get(
        `/content?type=${recommendContentType}`
      );
      setContent(contentResponse.data.content);
      setIsLoading(false);
    };

    fetch();
  }, []);

  if (isLoading) {
    return null;
  }
  return (
    <Container>
      <Wrapper>
        <SubTitle>마음 체크업 완료!</SubTitle>
        <Title>{name}님의 마음을 잘 기록하였습니다.</Title>
        <ScoreBox>
          <ScoreHeading2>{converDate(new Date(), 1)}</ScoreHeading2>
          <ScoreHeading1>마음 웰빙 지수 : {score}</ScoreHeading1>
          {lastCheckup.score ? (
            <ScoreHeading2>
              {score > lastCheckup.score
                ? `좋아지고 있어요. 지난 번 체크업 이후 +${
                    score - lastCheckup.score
                  } 올랐습니다 😃`
                : score < lastCheckup.score
                ? `요즘 많이 바쁘신가봐요. 지난 번 체크업 이후 -${
                    lastCheckup.score - score
                  } 내려갔습니다 😥`
                : `지난 번 체크업과 동일한 지수예요 ☺`}
            </ScoreHeading2>
          ) : (
            <ScoreHeading2>
              첫 마음 체크업이네요! 오늘부터 꾸준히 마음을 관리해 볼까요? ☺
            </ScoreHeading2>
          )}
        </ScoreBox>
        <SectionTitle>웰빙 만족도</SectionTitle>
        <CheckupResultChart
          todayResult={results.slice(0, 6)}
          lastResult={lastCheckup.answers && lastCheckup.answers.slice(0, 6)}
        />
        <SectionTitle>감정 지수</SectionTitle>
        <CheckupEmotionChart
          emotions={[
            {
              title: "오늘 감정 지수",
              negative: results[6]
                .filter((i) => i[1] < 0)
                .reduce((prev, cur) => prev + 4 * 1, 0),
              positive: results[6]
                .filter((i) => i[1] > 0)
                .reduce((prev, cur) => prev + 4 * 1, 0),
            },
            {
              title: "지난 감정 지수",
              tag: "lastWeek",
              negative:
                lastCheckup.answers &&
                lastCheckup.answers[6]
                  .filter((i) => i[1] < 0)
                  .reduce((prev, cur) => prev + 4 * 1, 0),
              positive:
                lastCheckup.answers &&
                lastCheckup.answers[6]
                  .filter((i) => i[1] > 0)
                  .reduce((prev, cur) => prev + 4 * 1, 0),
            },
          ]}
        />
        {!document.referrer.includes("zep") && (
          <Button
            text={"📈 내 최근 마음 그래프 살펴보기"}
            handler={handleClick}
          />
        )}
        <svg
          style={{ margin: "0 auto" }}
          width="40"
          height="39"
          viewBox="0 0 40 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1110_3259)">
            <path
              d="M35.6422 32.3653V5.25389C35.6422 2.35751 33.3006 0 30.4238 0H5.21838C2.34158 0 0 2.35751 0 5.25389V33.7461C0 36.6425 2.34158 39 5.21838 39H38.4186C39.8236 39 40.5261 37.2824 39.5392 36.2889L35.6422 32.3653V32.3653ZM17.8295 31.6412C12.494 31.6412 8.1119 27.4482 7.74394 22.1775C7.71049 21.7902 8.045 21.4702 8.41296 21.4702H10.604C10.9552 21.4702 11.2396 21.7565 11.273 22.1101C11.5908 25.4611 14.4007 28.1049 17.8295 28.1049C21.2582 28.1049 24.0681 25.478 24.3859 22.1101C24.4193 21.7565 24.6869 21.4702 25.0549 21.4702H27.246C27.6306 21.4702 27.9484 21.7902 27.915 22.1775C27.547 27.465 23.1649 31.6412 17.8295 31.6412V31.6412Z"
              fill="#F8FBFB"
            />
          </g>
          <defs>
            <clipPath id="clip0_1110_3259">
              <rect width="40" height="39" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <CheckupInfo>
          {isMobile ? (
            <>
              <CheckupInfoText>
                첫 마음 체크업 :{" "}
                {converDate(auth.user.firstCheckupDate || lastCheckup.date, 1)}
              </CheckupInfoText>
              <CheckupInfoText>
                최근 마음 체크업 :{converDate(lastCheckup.date, 1)}
              </CheckupInfoText>
              <CheckupInfoText>
                마음 체크업 회수 : 총 {checkupCount}회
              </CheckupInfoText>
            </>
          ) : (
            <>
              <CheckupInfoText>
                첫 마음 체크업 :{" "}
                {converDate(auth.user.firstCheckupDate || lastCheckup.date, 1)}{" "}
                ㅣ 최근 마음 체크업 :{converDate(lastCheckup.date, 1)}
              </CheckupInfoText>
              <CheckupInfoText>
                마음 체크업 회수 : 총 {checkupCount}회
              </CheckupInfoText>
            </>
          )}
        </CheckupInfo>
        <Divider />
        <CheckupRecommendContent content={content} />
      </Wrapper>
    </Container>
  );
};

export default CheckupResult;
