import styled from "styled-components";

const Text = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  text-decoration-line: underline;
`;

const TextLink = ({ children, style, onClick }) => {
  return (
    <Text style={style} onClick={onClick}>
      {children}
    </Text>
  );
};

export default TextLink;
