import styled from "styled-components";
import FlexRow from "../../../components/FlexRow";
import FlexColumn from "../../../components/FlexColumn";
import { isAuthEmpty } from "../../../utils/isAuthEmpty";
import lineBreaker from "../../../utils/lineBreaker";
import { getDate } from "../../../utils/date";

const Container = styled.div`
  justify-self: center;

  margin: 0 auto;
  & .sticky-note {
    width: 14.6875rem;
    min-height: 15.63825rem;
    padding: 0 1.5em;

    color: ${({ theme }) => theme.grayScaleColor.black};
    outline: none;
    position: relative;

    margin-top: 50px;
    margin-bottom: 10px;
    padding-top: 2rem;
    padding-bottom: 3rem;

    /* &::before {
      content: "";
      position: absolute;
      display: block;
    } */

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      border-width: 16px 16px 16px 16px;
      border-style: solid;
    }
    & .nickname {
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.03125rem;
      color: ${({ theme }) => theme.backgroundColor.darkgray40};
    }
    & .date {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: ${({ theme }) => theme.grayScaleColor.gray30};
    }
    & .comment {
      /* word-wrap: break-word;
      overflow-wrap: break-word; */
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 1.21875rem */
      letter-spacing: -0.03125rem;
    }
    &.sticky-note-one {
      background-color: #f4faf9;
      /* grid-area: sticker1; */
      /* box-shadow: 9px 0px 10px -5px rgba(0, 0, 0, 0.42); */
      & .tape {
        position: absolute;
        display: block;
        left: 50%;
        top: -15px;
        transform: translateX(-50%) rotate(1deg);
      }
      /* &::before {
        background-color: rgba(0, 219, 177, 0.8);
        width: 7.16431rem;
        height: 1.81956rem;
        left: 50%;
        top: -15px;
        transform: translateX(-50%) rotate(1deg);
      } */
      &::after {
        left: 0;
        border-top-color: #eaf4f4;
        border-right-color: #eaf4f4;
        border-bottom-color: white;
        border-left-color: white;
      }
    }
  }
`;

const ReplyItemPublicButton = styled.button`
  border-radius: 6.25rem;

  height: 1.44rem;
  border: 1px solid
    ${({ theme, isPublic }) =>
      isPublic ? theme.primary.normal : theme.grayScaleColor.gray30};
  color: ${({ theme, isPublic }) =>
    isPublic ? theme.primary.normal : theme.grayScaleColor.gray30};
  font-size: 0.63rem;
  line-height: 1.19rem;
  // padding: 0.13rem 0.63rem;
  background: none;
  letter-spacing: -0.04em;
  cursor: pointer;
`;

const CommentLikeButton = styled.button`
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 1rem;
  right: 1.4rem;
  border-radius: 3.13rem;
  background: ${({ theme, isPublic }) =>
    isPublic ? theme.grayScaleColor.white : theme.grayScaleColor.gray10};
  border: none;
  color: ${({ theme, likeComment }) =>
    likeComment ? theme.primary.normal : theme.grayScaleColor.gray30};
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

const CommentLikeCount = styled.span`
  margin-left: 0.38rem;
  font-size: 0.69rem;
  line-height: 0.84rem;
  font-weight: 400;
  font-family: Montserrat;
  margin-top: 0.13rem;
`;

const StickyNote = ({
  auth,
  comment,
  handlePublicClick,
  handleCommentLikeClick,
}) => {
  return (
    <Container className="note-container">
      <div className="sticky-note sticky-note-one">
        <svg
          className="tape"
          xmlns="http://www.w3.org/2000/svg"
          width="116"
          height="27"
          viewBox="0 0 116 27"
          fill="none"
        >
          <path
            opacity="0.8"
            d="M114.47 2.58933L1.61989 0.762081C1.35881 2.49352 0.882191 4.34526 0.803874 5.27038C0.267842 11.5513 0.219604 17.8753 0.631529 24.1678L115.205 26.0243C115.021 20.8001 114.859 15.5784 114.788 10.3535C114.755 8.00457 114.382 5.16831 114.473 2.59236L114.47 2.58933Z"
            fill="#00DBB1"
          />
        </svg>
        <FlexRow
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1.44rem",
          }}
        >
          <FlexColumn style={{ gap: "0.25rem" }}>
            <p className="nickname">{comment.userId.nickname}</p>
            <p className="date">{getDate(comment.createdAt)}</p>
          </FlexColumn>
          {!isAuthEmpty(auth) && comment.userId._id === auth.user.id && (
            <ReplyItemPublicButton
              onClick={() => handlePublicClick(!comment.isPublic, comment._id)}
              isPublic={comment?.isPublic}
            >
              {comment?.isPublic ? "마음 비공개" : "마음 공개"}
            </ReplyItemPublicButton>
          )}
        </FlexRow>
        <div className="comment">{lineBreaker(comment.comment)}</div>
        {!isAuthEmpty(auth) && (
          <CommentLikeButton
            onClick={() => handleCommentLikeClick(comment._id, comment.likeIds)}
            isPublic={comment?.isPublic}
            likeComment={comment?.likeIds.indexOf(auth?.user.id) !== -1}
            disabled={!comment?.isPublic}
          >
            <svg
              width="13"
              height="15"
              viewBox="0 0 13 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 14.3731C1.79429 14.3731 3.0339 14.3731 3.0339 14.3731V6.01489C3.0339 6.01489 1.79429 6.01489 1 6.01489"
                stroke={
                  comment?.likeIds.indexOf(auth?.user.id) !== -1
                    ? "#00DBB1"
                    : "#BBBBBB"
                }
                strokeWidth="0.8"
                strokeliterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M1 6V14.5"
                stroke={
                  comment?.likeIds.indexOf(auth?.user.id) !== -1
                    ? "#00DBB1"
                    : "#BBBBBB"
                }
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.32202 6.01489H10.3152H11.5966C12.0359 6.01489 12.3898 6.39101 12.3898 6.85907V7.26027C12.3898 7.72415 12.0359 8.10444 11.5966 8.10444H11.5763"
                stroke={
                  comment?.likeIds.indexOf(auth?.user.id) !== -1
                    ? "#00DBB1"
                    : "#BBBBBB"
                }
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.03387 6.85075C3.84743 6.38687 5.47455 5.1791 6.00743 4.02149C6.19862 3.60776 6.26371 2.55881 6.24337 1.80239C6.23116 1.36358 6.54032 1 6.92676 1H6.98777C7.94777 1 8.72879 1.89015 8.72879 2.98507V3.05612C8.72879 3.43224 8.32201 6.01493 8.32201 6.01493"
                stroke={
                  comment?.likeIds.indexOf(auth?.user.id) !== -1
                    ? "#00DBB1"
                    : "#BBBBBB"
                }
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.5762 8.10449H11.5966C12.0359 8.10449 12.3898 8.47225 12.3898 8.92778V9.37494C12.3898 9.83046 12.0359 10.1982 11.5966 10.1982H11.1694"
                stroke={
                  comment?.likeIds.indexOf(auth?.user.id) !== -1
                    ? "#00DBB1"
                    : "#BBBBBB"
                }
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.1695 10.194H11.1898C11.6291 10.194 11.983 10.5617 11.983 11.0173V11.4644C11.983 11.9199 11.6291 12.2877 11.1898 12.2877H10.7627"
                stroke={
                  comment?.likeIds.indexOf(auth?.user.id) !== -1
                    ? "#00DBB1"
                    : "#BBBBBB"
                }
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.783 12.2836C11.2223 12.2836 11.5762 12.6597 11.5762 13.1277V13.5289C11.5762 13.9928 11.2223 14.3731 10.783 14.3731H4.75858C4.17689 14.3731 3.6196 14.2394 3.11926 14.0054"
                stroke={
                  comment?.likeIds.indexOf(auth?.user.id) !== -1
                    ? "#00DBB1"
                    : "#BBBBBB"
                }
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <CommentLikeCount>{comment?.likeNums}</CommentLikeCount>
          </CommentLikeButton>
        )}
      </div>
    </Container>
  );
};

export default StickyNote;
