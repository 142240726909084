import { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 24.5rem;
  justify-content: space-between;
  padding: 1rem 1.25rem 1rem 2.06rem;
  background: ${({ theme, isClicked }) =>
    isClicked ? theme.primary.light : "#f3f3f3"};
  align-items: center;
  cursor: pointer;
  border-radius: 0.63rem;
  border: 1px solid
    ${({ theme, isClicked }) => (isClicked ? theme.primary.normal : "#f3f3f3")};
  &:hover {
    background: ${({ theme }) => theme.primary.light};
    border: 1px solid ${({ theme }) => theme.primary.normal};
  }
  transition: all 0.3s;
  @media (max-width: 425px) {
    padding: 0.88rem 1.56rem;
    width: 100%;
  }
`;

const Check = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${({ theme, isClicked }) =>
    isClicked ? theme.primary.normal : "#E4E4E4"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckIcon = () => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9091 0.806932C15.568 1.30899 15.6952 2.25019 15.1931 2.90914L8.33601 11.9091C8.07102 12.2569 7.66747 12.4718 7.23098 12.4975C6.79449 12.5232 6.36853 12.3571 6.06457 12.0428L0.921716 6.72464C0.345828 6.12911 0.361744 5.1795 0.957264 4.60361C1.55278 4.02772 2.5024 4.04364 3.07829 4.63916L7.00765 8.70248L12.8069 1.09101C13.3089 0.432058 14.2501 0.304869 14.9091 0.806932Z"
        fill="white"
      />
    </svg>
  );
};

const CheckButton = ({
  children,
  index,
  multipleChoices,
  setMultipleChoices,
  style,
}) => {
  const [isClicked, setIsClicked] = useState();
  const handleClick = () => {
    setMultipleChoices((cur) => ({
      ...cur,
      [index]: !cur[index],
    }));

    setIsClicked((cur) => !cur);
  };

  useEffect(() => {
    setIsClicked(multipleChoices[index]);
  }, [multipleChoices]);
  return (
    <Container isClicked={isClicked} onClick={handleClick} style={style}>
      {children}
      <Check isClicked={isClicked}>
        <CheckIcon />
      </Check>
    </Container>
  );
};

export default CheckButton;
