import styled from "styled-components";

const Text = styled.p`
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const NoDataText = ({ style }) => {
  return <Text style={style}>아직 데이터가 없어요</Text>;
};

export default NoDataText;
