import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FlexRow from "../../../components/FlexRow";
import { TABLET_LANDSCAPE } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Card1 from "../Card1";
import Card2 from "../Card2";
import Card3 from "../Card3";
import Card4 from "../Card4";
import Card5 from "../Card5";
import Card6 from "../Card6";
import CardLayout from "../CardLayout";
import { GradientChart } from "../components/GradientChart";
import Heading1 from "../Heading1";
import Heading2 from "../Heading2";

const Container = styled.div`
  margin-left: var(--sidebar-width);
  // height: calc(100% - 4.06rem);
  padding: 2.25rem 2.5rem;
  width: 100%;
  // border: 1px solid black;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    margin-left: var(--sidebar-tablet-landscape-width);
  }
`;

const Section = styled.section`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(4, 1fr);
  // grid-template-areas:
  //   "box1 box5 box6 box7"
  //   "box1 box5 box6 box7"
  //   "box2 box5 box6 box7"
  //   "box2 box5 box6 box7"
  //   "box3 box5 box6 box8"
  //   "box3 box5 box6 box8"
  //   "box3 box5 box6 box8";
  gap: 1rem;
  width: 100%;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(2, 1fr);
    // max-height: 2400px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.grayScaleColor.gray20};
  margin: 2.13rem 0;
`;

const LeaderDashboard = () => {
  const { auth } = useAuth();
  const CHART_TYPES = ["마음 웰빙 지수", "마음 문장"];
  const DEFAULT_SEARCH_START_DATE = new Date(
    new Date(auth.user.companyId.createdAt).getFullYear(),
    new Date(auth.user.companyId.createdAt).getMonth(),
    new Date(auth.user.companyId.createdAt).getDate()
  );
  const company = auth.user.companyId.name;
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const isTablet = useMediaQuery({ query: `(max-width: ${TABLET_LANDSCAPE})` });
  const [isLoading, setIsLoading] = useState(true);
  const [userCount, setUserCount] = useState(0);
  const [checkupCount, setCheckupCount] = useState(0);
  const [blockCount, setBlockCount] = useState(0);
  const [topParticipatedBlocks, setTopParticipatedBlocks] = useState({});
  const [wellBeingAverage, setWellBeingAverage] = useState(0);
  const [maumSentence, setMaumSentence] = useState({});
  const [wellBeingType, setWellBeingType] = useState({});
  const [wellBeingScores, setWellBeingScores] = useState([]);
  const [cash, setCash] = useState({
    totalCash: 0,
    usedCash: 0,
  });
  const [chartType, setChartType] = useState("");
  const [chartData1, setChartData1] = useState({});
  const [chartData2, setChartData2] = useState({});
  const [searchStartDate, setSearchStartDate] = useState(
    DEFAULT_SEARCH_START_DATE
  );
  const [searchEndDate, setSearchEndDate] = useState(new Date());
  const [blockPerWeek, setBlockPerWeek] = useState(0);
  const [checkupPercentagePerWeek, setCheckupPercentagePerWeek] = useState(0);

  const handleAnnouncementClick = () => {
    navigate("/announcement");
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await axiosPrivate.get(
        `/statistics/leader?searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`
      );

      if (!response.data.ok) {
      }
      setUserCount(response.data.userCount);
      setCheckupCount(response.data.checkupCount);
      setBlockCount(response.data.blockCount);
      setBlockPerWeek(response.data.blockPerWeek);
      setCheckupPercentagePerWeek(response.data.checkupPercentagePerWeek);
      setTopParticipatedBlocks(response.data.topParticipatedBlocks);
      setWellBeingAverage(response.data.wellBeingAverage);
      setMaumSentence({
        sentenceData: response.data.maumSentences,
        maxSentenceEmoji: response.data.maxSentenceEmoji,
        maxSentence: response.data.maxSentence,
        maxSentenceWord: response.data.maxSentenceWord,
        maxSentenceCount: response.data.maxSentenceCount,
      });
      setWellBeingType({
        maxWellBeing: response.data.maxWellBeing,
        minWellBeing: response.data.minWellBeing,
      });
      setWellBeingScores(response.data.wellBeingScores);
      setCash({
        totalCash: response.data.totalCash,
        usedCash: response.data.usedCash,
      });
      setChartType(CHART_TYPES[0]);
      // setChartData1({
      //   data: response.data.wellBeingScores.map((item) => ({
      //     date: item.date,
      //     wellBeingScore: item.wellBeingScore,
      //     permavacScore: item.permavac,
      //   })),
      //   tabs: [
      //     { type: "전체" },
      //     ...response.data.wellBeingScores[0].permavac.map((item) => ({
      //       type: item[0],
      //     })),
      //   ],
      // });
      // setChartData2({
      //   data: response.data.maumSentences.map((item) => ({
      //     date: item.date,
      //     score: item.sentences,
      //     sentences: item.sentences,
      //   })),
      //   tabs: response.data.maumSentences[0].sentences.map((item) => ({
      //     type: item.word,
      //   })),
      // });
      setIsLoading(false);
    };

    fetch();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <Heading1>리더 대시보드</Heading1>
      <Heading2>
        {company} 구성원들의 맘핏 활동 내역을 한눈에 확인해 보세요!
      </Heading2>
      <Section>
        <CardLayout style={{ gridArea: "1 / 1 / span 1  / span 1" }}>
          <Card1
            title={"전체 맘핏 참여자"}
            icon={`${process.env.PUBLIC_URL}/assets/icons/dashboard_users_icon.png`}
            data={`${userCount}명`}
            desc={"지난 30일간 변동 없음"}
          />
        </CardLayout>
        <CardLayout style={{ gridArea: "2 / 1 / span 1 / span 1" }}>
          <Card1
            title={"마음 체크업 참여"}
            icon={`${process.env.PUBLIC_URL}/assets/icons/report_check_icon.png`}
            data={`${checkupCount}회`}
            desc={`1주에 구성원 약 ${checkupPercentagePerWeek}% 참여`}
          />
        </CardLayout>
        <CardLayout style={{ gridArea: "3 / 1 / span 3 / span 1" }}>
          <Card1
            title={"마음 트레이닝 참여"}
            icon={`${process.env.PUBLIC_URL}/assets/icons/dashboard_training_icon.png`}
            data={`${blockCount}회`}
            desc={`1인당 1주에 약 ${blockPerWeek}회 참여`}
          />
          <Divider />
          <Card2 title={"마음 트레이닝 Top3"} data={topParticipatedBlocks} />
        </CardLayout>
        <CardLayout style={{ gridArea: "1 / 2 / span 5 / span 1" }}>
          <Card1
            title={"마음 웰빙 지수"}
            icon={`${process.env.PUBLIC_URL}/assets/icons/dashboard_wellBeing_icon.png`}
            data={`${wellBeingAverage}점`}
            desc={`구성원 마음 웰빙 지수 평균`}
          />
          <Divider />
          <Card3
            data={[
              {
                title: "높은 영역 (+)",
                value: wellBeingType.maxWellBeing,
                background: "#00DBB1",
              },
              {
                title: "낮은 영역 (-)",
                value: wellBeingType.minWellBeing,
                background: "#F11B1B",
              },
            ]}
          />
          <Divider />
          <Card6 title={"주간 변화 추이"} data={wellBeingScores} />
        </CardLayout>
        <CardLayout style={{ gridArea: "1 / 3 / span 5  / span 1" }}>
          <Card1
            title={"대표 마음 문장"}
            icon={`${process.env.PUBLIC_URL}/assets/icons/dashboard_sentence_icon.png`}
            data={
              maumSentence.maxSentenceEmoji &&
              `${maumSentence.maxSentenceEmoji} ${maumSentence.maxSentenceWord}`
            }
            desc={maumSentence.maxSentence && `${maumSentence.maxSentence}`}
          />
          <Divider />
          <Card5 data={maumSentence.sentenceData} />
        </CardLayout>
        <CardLayout
          style={{
            gridArea: isTablet
              ? "6 / 1 / span 2 / span 1"
              : "1 / 4 / span 3  / span 1",
          }}
        >
          <Card1
            title={"조직 구성원 보유 캐시"}
            titleIcon={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
            icon={`${process.env.PUBLIC_URL}/assets/icons/dashboard_cash_icon.png`}
            data={`${cash.totalCash.toLocaleString()}원`}
            desc={`1인당 약 ${Math.ceil(
              cash.totalCash / userCount
            ).toLocaleString()}원`}
          />
          <Divider />
          <Card3
            data={[
              {
                title: "총 누적 캐시",
                value: `${(cash.totalCash - cash.usedCash).toLocaleString()}원`,
                valueIcon: `${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`,
                background: "#00DBB1",
              },
              {
                title: "환급된 캐시",
                value: `${Math.abs(cash.usedCash).toLocaleString()}원`,
                valueIcon: `${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`,
                background: "#00DBB1",
              },
            ]}
          />
        </CardLayout>
        <CardLayout
          style={{
            gridArea: isTablet
              ? "6 / 2 / span 1 / span 1"
              : "4 / 4 / span 2  / span 1",
          }}
        >
          <FlexRow
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <p>맘핏 공지사항</p>
            <svg
              // onClick={handleAnnouncementClick}
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
            >
              <path
                d="M15.1012 13.4827L9.64062 8.01126L10.5579 7.09399L16.9358 13.4827L10.5579 19.8605L9.64062 18.9432L15.1012 13.4827Z"
                fill="#222222"
              />
            </svg>
          </FlexRow>
          <Divider style={{ marginTop: "1rem" }} />
        </CardLayout>
        {/* <Card3 title={"대표 마음 문장"} data={maumSentence} /> */}
        {/* <Card4
          style={{ gridArea: "2 / 2 / span 3 / span 3" }}
          data1={chartData1.data}
          data2={chartData2.data}
          tabs1={chartData1.tabs}
          tabs2={chartData2.tabs}
          types={CHART_TYPES}
          chartType={chartType}
          setChartType={setChartType}
        /> */}
      </Section>
    </Container>
  );
};

export default LeaderDashboard;
