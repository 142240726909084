import styled from "styled-components";

const Container = styled.button`
  padding: 1.06rem 0;
  width: 100%;
  background: ${({ theme }) => theme.primary.normal};
  border-radius: 0.31rem;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 1.13rem;
  font-weight: 600;
  color: white;
`;

const Button = ({ text, clickHandler, style }) => {
  return (
    <Container onClick={clickHandler} style={style}>
      {text}
    </Container>
  );
};

export default Button;
