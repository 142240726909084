import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import { useEffect, useRef, useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  zoomPlugin
);

// ChartJS.pluginService.register({
//   beforeInit: function (chart) {
//     console.log("hi", chart);
//     chart.legend.afterFit = function () {
//       chart.legend.options.labels.padding = 20;
//       chart.height += 30;
//     };
//   },
// });

const Container = styled.div`
  width: 100%;
  height: 21.75rem;
  border-radius: 0.63rem;
  padding: 20px;
  margin-bottom: 3.13rem;
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  @media (max-width: 425px) {
    width: 100%;
    height: 18.81rem;
  }
  // border: 1px solid black;
  font-family: Montserrat;
`;

export function CheckupResultChart({ todayResult, lastResult }) {
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });
  const labels = ["긍정정서", "몰입", "관계", "의미", "성취", "활력"];

  const countRef = useRef(0);
  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    layout: {
      // padding: 20,
    },
    plugins: {
      legend: {
        labels: {
          // padding: 10,
          boxHeight: 2,
          font: {
            size: isMobile ? 10 : 12,
          },
          color: "#111111",
        },
        display: true,
        position: "top",
        align: "end",
      },
      zoom: {
        pan: { enabled: true, mode: "x" },
      },
      title: {
        display: false,
        text: "웰빙 만족도",
      },
      datalabels: {
        display: false,
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderRadius: 4,
        color: "white",
        font: {
          weight: "bold",
        },
        // formatter: Math.round,
        padding: 6,
      },
      tooltip: {
        intersect: true,
        backgroundColor: (context) => {
          return context.tooltip.labelColors[0].borderColor;
        },
        label: (context) => {
          console.log(context);
          return;
        },
        xAlign: "center",
        yAlign: "bottom",
        // x: 100,
        // y: 500,
        padding: {
          top: 6,
          bottom: 6,
          left: 20,
          right: 20,
        },
        bodyFont: {
          size: 12,
          weight: "bold",
        },
        // title: "aaa",
        callbacks: {
          title: () => {
            return "";
          },

          label: (context) => {
            if (
              context.dataset.label === "오늘 체크업" &&
              lastResult[context.dataIndex] === context.raw
            ) {
              return "";
            }
            return context.raw;
          },
          labelTextColor: () => {
            return "white";
          },
        },
        displayColors: false,
      },
    },
    // elements: {
    //   line: {
    //     fill: true,
    //     tension: 0,
    //   },
    // },
    scales: {
      x: {
        offset: true, // x축 양끝 짤리는 거 옵션
        // min: labels.length - 7,
        // max: labels.length - 1,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          color: "#777777",
          padding: 10,
          font: {
            size: isMobile ? 10 : 15,
          },
        },
      },
      y: {
        offset: true, // y축 위/아래 짤리는 거 옵션
        min: 1,
        max: 5,
        ticks: {
          stepSize: 1,
          padding: 10,
          color: "#777777",
          font: {
            size: isMobile ? 10 : 15,
            family: "Montserrat",
          },
        },
        grid: {
          drawBorder: false,
          lineWidth: 1,
        },
      },
    },
  };

  const [data, setData] = useState();
  useEffect(() => {
    // console.log(result);
    setData({
      labels,
      datasets: [
        {
          label: "오늘 체크업",
          data: todayResult,
          // borderColor: color,
          // backgroundColor: color,
          borderColor: "#00DBB1",
          backgroundColor: "#00DBB1",
          borderWidth: 2,
          tension: 0.4,
          pointRadius: 3,
          pointHoverRadius: 10,
          pointHoverBorderWidth: 2,
          pointHoverBackgroundColor: "white",
          pointBackgroundColor: "white",
          pointBorderColor: "#00DBB1",
        },
        {
          label: "지난 체크업",
          data: lastResult,
          // borderColor: color,
          // backgroundColor: color,
          borderColor: "#D9D9D9",
          backgroundColor: "#D9D9D9",
          borderWidth: 2,
          tension: 0.4,
          pointRadius: 3,
          pointHoverRadius: 10,
          pointHoverBorderWidth: 2,
          pointHoverBackgroundColor: "white",
          pointBackgroundColor: "white",
          pointBorderColor: "#D9D9D9",
        },
      ],
    });
  }, [todayResult]);
  // useEffect(() => {
  //   if (countRef.current === 0) {
  //     if (result.length === 1) {
  //       const obj = {
  //         ...data,
  //         datasets: [data.datasets[1]],
  //       };
  //       console.log(obj);
  //       console.log(result[0].slice(1, 7));
  //       obj.datasets[0].data = result[0].slice(1, 7);
  //       console.log(obj);
  //       setData(obj);
  //     }
  //     countRef.current += 1;
  //   }
  //   // setData();
  // }, []);

  return (
    <Container>
      {data && (
        <Line options={options} data={data} height={isMobile ? 220 : 250} />
      )}
    </Container>
  );
}
