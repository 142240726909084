import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const Container = styled.div`
  border-radius: 1.25rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  box-shadow: 0px 10px 80px 0px rgba(0, 0, 0, 0.1);
  width: 25.25rem;
`;

const ModalTitle = styled.p`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 600;
  line-height: 140%;
  display: flex;
  align-items: center;
  gap: 0.38rem;
  margin: 0 auto;
`;

const InputLabel = styled.p`
  color: #000000;
  font-size: 0.9375rem;
  line-height: 140%;
`;
const SelectBox = styled.div`
  padding: 0.75rem;
  text-align: right;
  font-size: 0.875rem;
  border-radius: 0.4375rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  width: 14.0625rem;
  height: 2.75rem;
`;
const BaseInput = styled.input`
  padding: 0.75rem;
  width: 14.0625rem;
  text-align: right;
  font-size: 0.875rem;
  border-radius: 0.4375rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  &:focus {
    outline: none;
    border: 1px solid #222222;
  }
  &::placeholder {
    color: ${({ theme }) => theme.grayScaleColor.gray30};
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const CheckBox = styled(BaseInput).attrs({
  type: "checkbox",
})`
  width: auto;
  &:checked {
    accent-color: ${({ theme }) => theme.primary.normal};
  }
`;

const HighlighText = styled.p`
  font-size: 0.8125rem;
  line-height: 140%;
  color: ${({ theme }) => theme.primary.normal};
  margin-top: 0.69rem;
  margin-bottom: 2.31rem;
  text-align: right;
`;

const Heading2 = styled.p`
  color: #000;
  text-align: right;
  font-size: 0.9375rem;
  line-height: 140%;
`;
const Heading3 = styled.p`
  font-size: 0.8125rem;
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  margin-left: 0.62rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.78rem;
  background: ${({ theme, type }) =>
    type === "cancel" ? theme.grayScaleColor.gray10 : theme.primary.normal};
  border: none;
  border-radius: 0.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, type }) =>
    type === "cancel"
      ? theme.grayScaleColor.gray70
      : theme.grayScaleColor.white};
  font-weight: 600;
  line-height: 150%;
`;

const ConfirmButton = styled.button`
  width: 100%;
  padding: 0.78rem;
  background: ${({ theme }) => theme.primary.normal};
  border: none;
  border-radius: 0.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-weight: 600;
  line-height: 150%;
  &:disabled {
    opacity: 0.5;
  }
`;

const CloseSvg = ({ onClick, style }) => {
  return (
    <svg
      style={style}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.14786 0.21967C9.44075 -0.0732233 9.91563 -0.0732233 10.2085 0.21967C10.5014 0.512563 10.5014 0.987437 10.2085 1.28033L6.27486 5.21399L10.2087 9.14786C10.5016 9.44075 10.5016 9.91563 10.2087 10.2085C9.91584 10.5014 9.44096 10.5014 9.14807 10.2085L5.2142 6.27465L1.28033 10.2085C0.987437 10.5014 0.512563 10.5014 0.21967 10.2085C-0.0732234 9.91563 -0.0732234 9.44075 0.21967 9.14786L4.15354 5.21399L0.219877 1.28033C-0.073016 0.987437 -0.073016 0.512563 0.219877 0.21967C0.51277 -0.0732233 0.987644 -0.0732233 1.28054 0.21967L5.2142 4.15333L9.14786 0.21967Z"
        fill="#111111"
      />
    </svg>
  );
};

const ArrowDownSvg = ({ style }) => {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
    >
      <path d="M5 6L0.669872 0H9.33013L5 6Z" fill="#222222" />
    </svg>
  );
};

const Dropdown = ({ close, items, setItem }) => {
  const handleClick = (e, item) => {
    setItem(item);
    close(false);
  };
  return (
    <FlexColumn
      style={{
        position: "absolute",
        marginTop: "40px",
        width: "100%",
        border: "1px solid #222222",
        height: "11.1875rem",
        overflowY: "auto",
        background: "white",
        borderRadius: "0rem 0rem 0.4375rem 0.4375rem",
        padding: "0 1rem",
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "center",
            borderBottom: "1px solid #EEEEEE",
            padding: "0.56rem 0",
            fontSize: "0.875rem",
            lineHeight: "140%",
          }}
          onClick={(e) => handleClick(e, item)}
        >
          {item}
        </div>
      ))}
    </FlexColumn>
  );
};

const WithdrawModal = ({ closeClick }) => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const selectBoxRef = useRef();
  const accountNumberRef = useRef();
  const accountOwnerRef = useRef();
  const amountRef = useRef();
  const [bank, setBank] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountOwner, setAccountOwner] = useState("");
  const [amount, setAmount] = useState("");
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);

  const MIN_WITHDRAW_AMOUNT = auth.user.isFirstWithdraw ? 5000 : 10000;
  const AVAILABLE_AMOUNT =
    auth.user.point - (auth.user.point % MIN_WITHDRAW_AMOUNT);

  const validate = () => {
    if (
      bank.length === 0 ||
      accountNumber.length === 0 ||
      accountOwner.length === 0 ||
      amount.length === 0 ||
      Number(amount) > AVAILABLE_AMOUNT ||
      Number(amount) % MIN_WITHDRAW_AMOUNT !== 0 ||
      Number(amount) <= 0
    ) {
      return false;
    }
    return true;
  };

  const handleDropdownClick = () => {
    setOpenDropDown((prev) => !prev);
  };

  const handleConfirmClick = () => {
    setIsReadyToSubmit(true);
  };
  const handleEditClick = () => {
    setIsReadyToSubmit(false);
  };
  const handleSubmitClick = async () => {
    const response = await axiosPrivate.post(`/cash/withdraw`, {
      bankName: bank,
      accountNumber,
      accountOwner,
      amount,
    });

    setIsComplete(true);
  };

  useEffect(() => {
    if (bank.length > 0) {
      selectBoxRef.current.style = "border: 1px solid #222222;";
    } else {
    }
    if (accountNumberRef.current.value.length > 0) {
      accountNumberRef.current.style = "border: 1px solid #222222;";
    } else {
      accountNumberRef.current.style = "border: 1px solid #D9D9D9;";
    }
    if (accountOwnerRef.current.value.length > 0) {
      accountOwnerRef.current.style = "border: 1px solid #222222;";
    } else {
      accountOwnerRef.current.style = "border: 1px solid #D9D9D9;";
    }
    if (amountRef.current.value.length > 0) {
      amountRef.current.style = "border: 1px solid #222222;";
    } else {
      amountRef.current.style = "border: 1px solid #D9D9D9;";
    }
  }, [bank, accountNumber, accountOwner, amount]);

  useEffect(() => {
    if (!openDropDown) {
      selectBoxRef.current.style = "border: 1px solid #D9D9D9;";
    } else {
      selectBoxRef.current.style =
        "border: 1px solid #222222; border-bottom: none;";
    }
    if (bank.length > 0) {
      selectBoxRef.current.style = "border: 1px solid #222222;";
    }
  }, [openDropDown]);

  return (
    <Container>
      {isComplete ? (
        <FlexColumn style={{ padding: "2.12rem 3rem" }}>
          <ModalTitle style={{ marginBottom: "1.12rem" }}>
            🎉 환급 신청 완료
          </ModalTitle>
          <Heading3 style={{ textAlign: "center", marginBottom: "1.56rem" }}>
            입력하신 계좌로 월말 일괄 이체될 예정입니다.
            <br />
            이체 결과는 개인 알림창에서 확인할 수 있어요.
          </Heading3>
          <Button onClick={closeClick}>눌러서 완료하기</Button>
        </FlexColumn>
      ) : (
        <>
          <FlexRow
            style={{
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              position: "relative",
              padding: "1.44rem 1.88rem 1.25rem 1.88rem",
            }}
          >
            <ModalTitle>
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                style={{ width: "1.25rem", height: "1.25rem" }}
                alt={"cash icon"}
              />{" "}
              캐시 환급 신청
            </ModalTitle>
            <CloseSvg
              onClick={(e) => closeClick(e, "cancel")}
              style={{ position: "absolute", right: "1.87rem" }}
            />
          </FlexRow>
          <div
            style={{ width: "100%", height: "1px", background: "#D9D9D9" }}
          />
          <FlexColumn style={{ padding: "1.87rem" }}>
            {isReadyToSubmit ? (
              <>
                <FlexColumn style={{ gap: "1rem" }}>
                  <FlexRow
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <InputLabel style={{ color: "#777" }}>
                      입금은행 선택
                    </InputLabel>
                    <Heading2>{bank}</Heading2>
                  </FlexRow>
                  <FlexRow
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <InputLabel style={{ color: "#777" }}>계좌번호</InputLabel>
                    <Heading2>{accountNumber}</Heading2>
                  </FlexRow>
                  <FlexRow
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <InputLabel style={{ color: "#777" }}>
                      예금주 실명
                    </InputLabel>
                    <Heading2>{accountOwner}</Heading2>
                  </FlexRow>
                  <FlexRow
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <InputLabel style={{ color: "#777" }}>
                      환급 신청 캐시
                    </InputLabel>
                    <Heading2 style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                        style={{
                          width: "0.875rem",
                          height: "0.875rem",
                          marginRight: "0.5rem",
                        }}
                        alt={"cash icon"}
                      />
                      {Number(amount).toLocaleString()}원
                    </Heading2>
                  </FlexRow>
                </FlexColumn>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    background: "#D9D9D9",
                    marginTop: "2.25rem",
                    marginBottom: "1.5rem",
                  }}
                />
                <Heading3 style={{ textAlign: "center" }}>
                  해당 정보로 환급 신청이 진행될 예정입니다. <br />
                  입력하신 정보가 맞는지 다시 한 번 확인하시고,
                  <br />
                  아래 버튼을 눌러 신청을 완료해 주세요.
                </Heading3>
                <FlexRow style={{ gap: "0.63rem", marginTop: "1.63rem" }}>
                  <Button type={"cancel"} onClick={handleEditClick}>
                    정보 수정하기
                  </Button>
                  <Button onClick={handleSubmitClick}>신청하기</Button>
                </FlexRow>
              </>
            ) : (
              <>
                <FlexColumn style={{ gap: "1rem" }}>
                  <FlexRow
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <InputLabel>입금은행 선택</InputLabel>
                    <FlexColumn style={{ position: "relative" }}>
                      <SelectBox
                        ref={selectBoxRef}
                        onChange={(e) => setBank(e.target.value)}
                        value={bank}
                        onClick={handleDropdownClick}
                      >
                        {bank ? bank : "선택"}{" "}
                        <ArrowDownSvg style={{ marginLeft: "1rem" }} />
                      </SelectBox>
                      {openDropDown && (
                        <Dropdown
                          setItem={setBank}
                          close={setOpenDropDown}
                          items={[
                            "우리은행",
                            "국민은행",
                            "신한은행",
                            "하나은행",
                            "기업은행",
                            "농협은행",
                            "카카오뱅크",
                            "케이뱅크",
                            "토스뱅크",
                          ]}
                        />
                      )}
                    </FlexColumn>
                  </FlexRow>
                  <FlexRow
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <InputLabel>계좌번호</InputLabel>
                    <BaseInput
                      ref={accountNumberRef}
                      type={"text"}
                      placeholder={"계좌번호(숫자만) 입력"}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => setAccountNumber(e.target.value)}
                      value={accountNumber}
                    />
                  </FlexRow>
                  <FlexRow
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <InputLabel>예금주 실명</InputLabel>
                    <BaseInput
                      ref={accountOwnerRef}
                      type={"text"}
                      placeholder={"이름 입력"}
                      onChange={(e) => setAccountOwner(e.target.value)}
                      value={accountOwner}
                    />
                  </FlexRow>
                  <FlexRow
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <InputLabel>환급 신청 캐시</InputLabel>
                    <BaseInput
                      ref={amountRef}
                      type={"text"}
                      placeholder={"10,000원 단위로 입력"}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => setAmount(e.target.value)}
                      value={amount}
                    />
                  </FlexRow>
                </FlexColumn>
                <HighlighText>
                  전환 가능 캐시:{" "}
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                    style={{ width: "0.75rem", height: "0.75rem" }}
                    alt={"cash icon"}
                  />{" "}
                  {AVAILABLE_AMOUNT.toLocaleString()}원
                </HighlighText>
                {/* <FlexRow style={{ marginBottom: "1.31rem" }}>
                  <CheckBox type="checkbox" />
                  <Heading3>
                    맘핏 캐시 현금 전환을 위한 개인정보 수집/이용 동의 (필수)
                  </Heading3>
                </FlexRow> */}
                <ConfirmButton
                  disabled={!validate()}
                  onClick={handleConfirmClick}
                >
                  신청하기
                </ConfirmButton>
              </>
            )}
          </FlexColumn>
        </>
      )}
    </Container>
  );
};

export default WithdrawModal;
