import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CloseXButton from "../components/CloseXButton";
import ModalHeading2 from "../components/ModalHeading2";

const Heading = styled.p`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 2.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  text-align: center;
  margin-bottom: 1.81rem;
`;
const Text = styled.p`
  font-size: 0.9375rem;
  line-height: 140%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  color: #000;

  text-align: center;
`;

const ModalButton = styled.button`
  border-radius: 0.4375rem;
  padding: 0.78rem 0;
  flex: 1;
  border: none;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  cursor: pointer;
`;

const CancelButton = styled(ModalButton)`
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const ConfirmButton = styled(ModalButton)`
  background: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.grayScaleColor.white};
`;

const DeleteUserModal = ({ users, closeHandler }) => {
  const axiosPrivate = useAxiosPrivate();
  const onConfirmClick = async () => {
    const res = await axiosPrivate.delete("/users/bulks", {
      data: {
        users: users.map((item) => ({
          id: item._id,
          status: item.status,
          companyId: item.companyId._id,
          departmentId: item.departmentId._id,
        })),
      },
      withCredentials: true,
    });
    window.location.reload();
  };
  return (
    <>
      <Heading>
        {users.length === 1
          ? `${users[0].name} 님을`
          : ` ${users[0].name} 님 외 ${users.length - 1}명을`}
        <br />
        구성원에서 삭제하시겠어요?
      </Heading>
      <Text>
        삭제된 구성원은 다음 결제일부터
        <br />
        사용 인원에 합산되지 않습니다.
      </Text>
      <FlexRow
        style={{
          marginTop: "2.44rem",
          justifyContent: "space-between",
          gap: "0.69rem",
        }}
      >
        <CancelButton onClick={closeHandler}>돌아가기</CancelButton>
        <ConfirmButton onClick={onConfirmClick}>삭제하기</ConfirmButton>
      </FlexRow>
    </>
  );
};

export default DeleteUserModal;
