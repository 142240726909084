import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import SubmitButton from "../../components/SubmitButton";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import Card from "./components/Card";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LabelInput from "../../components/LabelInput";
import validatePassword from "../../utils/validatePassword";

const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  margin-top: 4.94rem;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const SelectBox = styled.div`
  width: 100%;
  border-radius: 0.3125rem;
  ${({ active }) =>
    active && "border-bottom-left-radius: 0; border-bottom-right-radius: 0;"}
  border: 1px solid
  ${({ theme, active }) =>
    active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  padding: 0.75rem;
  text-align: center;
  color: ${({ theme, active }) =>
    active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4375rem; /* 153.333% */
  letter-spacing: -0.0375rem;
  cursor: pointer;
`;

const Input = styled.div`
  width: 100%;
  border-radius: 0.3125rem;
  ${({ isOpen }) =>
    isOpen && "border-bottom-left-radius: 0; border-bottom-right-radius: 0;"}
  border: 1px solid
    ${({ theme, active }) =>
    active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  padding: 0.88rem 0.81rem;
  color: ${({ theme, active }) =>
    active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  font-size: 0.875rem;
  line-height: 1.4375rem; /* 164.286% */
  letter-spacing: -0.03125rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const SInput = styled.input`
  width: 100%;
  border-radius: 0.3125rem;
  border: 1px solid
    ${({ theme, active }) =>
      active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  padding: 0.88rem 0.81rem;
  color: ${({ theme, active }) => theme.grayScaleColor.black};
  font-size: 0.875rem;
  line-height: 1.4375rem; /* 164.286% */
  letter-spacing: -0.03125rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  outline: none;
  &:focus {
    border: 1px solid ${({ theme, active }) => theme.grayScaleColor.black};
  }
`;

const Label = styled.p`
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4375rem; /* 153.333% */
  letter-spacing: -0.0375rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const TextButton = styled.p`
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.4375rem; /* 164.286% */
  letter-spacing: -0.03125rem;
  text-decoration-line: underline;
  text-underline-offset: 0.1rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  cursor: pointer;
  margin-bottom: 2.5rem;
`;

const Item = styled.div`
  color: #222;
  &:hover {
    color: ${({ theme }) => theme.primary.normal};
  }
`;
const Verify = styled.img`
  width: 0.9375rem;
  height: 0.9375rem;
`;

const VerifyText = styled.p`
  font-size: 0.8125rem;
  line-height: 140%; /* 1.1375rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme, state }) =>
    state === "correct"
      ? theme.primary.normal
      : state === "error"
      ? theme.colors.error
      : theme.grayScaleColor.gray70};
`;

const SDropdown = ({ close, items, setItem, item }) => {
  const selectRef = useRef();

  const handleClick = (e, data) => {
    setItem(data);
    close(false);
  };
  let index = 0;
  if (item) {
    index = items.indexOf(item);
  }
  //   useEffect(() => {
  //     if (!item) {
  //       selectRef.current.scrollTop = 0;
  //     } else {
  //       selectRef.current.scrollTop += 35 * index;
  //     }
  //   }, []);
  return (
    <div
      ref={selectRef}
      style={{
        position: "absolute",
        left: "-1px",
        right: "-1px",
        top: "3.125rem",
        border: "1px solid #222222",
        height: "11.1875rem",
        overflowY: "auto",
        background: "white",
        borderRadius: "0rem 0rem 0.4375rem 0.4375rem",
        padding: "0.25rem 1rem",
        zIndex: 1,
        filter: "drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.20))",
      }}
    >
      {items.map((data, index) => (
        <Item
          key={index}
          style={{
            display: "flex",
            justifyContent: "center",
            // color: data === item ? "#00DBB1" : "#222",
            borderBottom: "1px solid #EEEEEE",
            padding: "0.56rem 0",
            fontSize: "0.875rem",
            lineHeight: "140%",
          }}
          onClick={(e) => handleClick(e, data)}
        >
          {data}
        </Item>
      ))}
    </div>
  );
};

const SetAdminProfile = () => {
  const navigate = useNavigate();

  const axiosPrivate = useAxiosPrivate();
  const { setAuth, setPersist } = useAuth();

  const [isMounted, setIsMounted] = useState(false);
  const [params] = useSearchParams();
  const [gender, setGender] = useState("");
  const years = [];

  const groupSizes = [
    "1~10명",
    "11~20명",
    "21~30명",
    "31~50명",
    "51~100명",
    "101~200명",
    "201~300명",
    "301명 이상",
  ];

  const [newPassword, setNewPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isPasswordLongerThanEight, setIsPasswordLongerThanEight] = useState();
  const [isPasswordValid, setIsPasswordValid] = useState();
  const [groupSize, setGroupSize] = useState();
  const [openGroupSize, setOpenGroupSize] = useState(false);
  const [formData, setFormData] = useState({});
  const [email, setEmail] = useState();

  const handlePasswordChange = (value) => {
    setNewPassword(value);
    if (value.length === 0) {
      setIsPasswordLongerThanEight();
      setIsPasswordValid();
      return;
    }
    if (value.length > 0 && value.length < 8) {
      setIsPasswordLongerThanEight(false);
    } else {
      setIsPasswordLongerThanEight(true);
    }
    if (validatePassword(value)) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  };
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
    if (e.target.id === "groupSize") {
      setOpenGroupSize((prev) => !prev);
    }
  };

  const handleSubmitClick = async () => {
    console.log(formData);
    console.log(newPassword);
    console.log(groupSize);
    const response = await axiosPrivate.post(
      `/manager/signup`,
      {
        email: params.get("email"),
        company: formData.company,
        department: formData.department,
        name: formData.name,
        phone: formData.phone,
        password: newPassword,
        groupSize,
      },
      {
        withCredentials: true,
      }
    );
    console.log(response.data.data);
    const accessToken = response.data.data.accessToken;
    setAuth({
      user: {
        email: params.get("email"),
        id: response.data.data.user._id,
        ...response.data.data.user,
      },
      accessToken,
    });
    localStorage.setItem("persist", true);
    setPersist(true);

    // if (!response.data.ok) {
    //   alert("문제가 발생했습니다.");
    // }
    // setAuth({
    //   user: {
    //     gender: response.data.user.gender,
    //     groupSize: response.data.user.groupSize,
    //     position: response.data.user.position,
    //     workExperience: response.data.user.workExperience,
    //   },
    // });
    navigate("/manager/dashboard");
  };

  const isDisable = () => {
    return (
      !isPasswordValid ||
      !formData["company"] ||
      !formData["department"] ||
      !formData["name"] ||
      !formData["phone"] ||
      !groupSize
    );
  };

  useEffect(() => {
    (async () => {
      const response = await axiosPrivate.get(
        `/signup/verify?verify=${params.get("verify")}`
      );
      if (!response.data.ok) {
        alert("해당 링크가 만료됐습니다. 다시 회원가입을 해주세요.");
        return;
      }
      console.log(response.data);
      setEmail(response.data.data.email);
      setIsMounted(true);
    })();

    return () => {
      setIsMounted(false);
    };
  }, []);

  if (!isMounted) {
    return null;
  }
  return (
    <Card>
      <FlexRow style={{ justifyContent: "center" }}></FlexRow>
      <Text>
        안녕하세요 관리자님😉
        <br />
        {params.get("email")} 계정에 대한
        <br />
        기본 설정을 진행할게요
      </Text>
      <LabelInput
        id="password"
        htmlFor={"비밀번호"}
        type={isShowPassword ? "text" : "password"}
        error={
          newPassword.length === 0 ? false : !validatePassword(newPassword)
        }
        onChange={handlePasswordChange}
        isShowPassword={isShowPassword}
        setIsShowPassword={setIsShowPassword}
      />
      <FlexColumn
        style={{
          gap: "0.5rem",
          marginTop: "1rem",
          marginBottom: "3.13rem",
        }}
      >
        <FlexRow style={{ alignItems: "center", gap: "0.62rem" }}>
          <Verify
            src={
              isPasswordLongerThanEight === undefined
                ? `${process.env.PUBLIC_URL}/svg/verify_default.svg`
                : isPasswordLongerThanEight
                ? `${process.env.PUBLIC_URL}/svg/verify_correct.svg`
                : `${process.env.PUBLIC_URL}/svg/verify_error.svg`
            }
          />
          <VerifyText
            state={
              isPasswordLongerThanEight === undefined
                ? "default"
                : isPasswordLongerThanEight
                ? "correct"
                : "error"
            }
          >
            최소 8자 이상
          </VerifyText>
        </FlexRow>
        <FlexRow style={{ alignItems: "center", gap: "0.62rem" }}>
          <Verify
            src={
              isPasswordValid === undefined
                ? `${process.env.PUBLIC_URL}/svg/verify_default.svg`
                : isPasswordValid
                ? `${process.env.PUBLIC_URL}/svg/verify_correct.svg`
                : `${process.env.PUBLIC_URL}/svg/verify_error.svg`
            }
          />
          <VerifyText
            state={
              isPasswordValid === undefined
                ? "default"
                : isPasswordValid
                ? "correct"
                : "error"
            }
          >
            영문, 숫자, 특수문자 조합
          </VerifyText>
        </FlexRow>
      </FlexColumn>
      <FlexColumn style={{ marginTop: "2rem", gap: "1.25rem" }}>
        <FlexColumn style={{ gap: "0.44rem" }}>
          <Label>회사명</Label>
          <SInput
            id="company"
            onChange={handleInputChange}
            active={formData["company"]}
          />
        </FlexColumn>
        <FlexColumn style={{ gap: "0.44rem" }}>
          <Label>그룹명</Label>
          <SInput
            id="department"
            onChange={handleInputChange}
            active={formData["department"]}
          />
        </FlexColumn>
        <FlexColumn style={{ gap: "0.44rem" }}>
          <Label>구성원 수</Label>
          <Input
            id="groupSize"
            onClick={handleInputClick}
            active={openGroupSize || groupSize}
            isOpen={openGroupSize}
          >
            {groupSize ? groupSize : "구성원 수를 선택해 주세요"}
            <img
              src={`${process.env.PUBLIC_URL}/svg/dropdown_triangle_down.svg`}
              style={{}}
              id="groupSize"
              onClick={handleInputClick}
            />
            {openGroupSize && (
              <SDropdown
                items={groupSizes}
                close={(toggle) => setOpenGroupSize(toggle)}
                setItem={setGroupSize}
                item={groupSize}
              />
            )}
          </Input>
        </FlexColumn>
        <FlexColumn style={{ gap: "0.44rem" }}>
          <Label>관리자 이름</Label>
          <SInput
            id="name"
            onChange={handleInputChange}
            active={formData["name"]}
          />
        </FlexColumn>
        <FlexColumn style={{ gap: "0.44rem" }}>
          <Label>관리자 연락처</Label>
          <SInput
            id="phone"
            onChange={handleInputChange}
            active={formData["phone"]}
          />
        </FlexColumn>
      </FlexColumn>

      <SubmitButton
        value="맘핏 시작하기"
        style={{ marginTop: "2.81rem", marginBottom: "3.44rem" }}
        disabled={isDisable()}
        onClick={handleSubmitClick}
      />
    </Card>
  );
};

export default SetAdminProfile;
