import styled from "styled-components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import BackgroundContainer from "../../components/BackgroundContainer";
import BackgroundSymbol from "../../components/BackgroundSymbol";
import Form from "../../components/Form";
import LabelInput from "../../components/LabelInput";
import SubmitButton from "../../components/SubmitButton";
import ErrorText from "../../components/ErrorText";
import ToastPopup from "../../components/ToastPopup";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import Card from "./components/Card";
import Checkbox from "../../components/Checkbox";
import { MOBILE } from "../../Constants";
import { useMediaQuery } from "react-responsive";
import { DotWave } from "@uiball/loaders";
import { Helmet } from "react-helmet";
import validatePhone from "../../utils/validatePhone";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // @media screen and (max-width: 480px) {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   width: 90%;
  // }
`;

const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  margin-top: ${({ isMobile }) => (isMobile ? "3.24rem" : "4.94rem")};
  margin-bottom: 0.75rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const DetailText = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  font-size: 0.875rem;
  text-align: center;
  line-height: 160%;
  letter-spacing: -0.5px;
  margin-bottom: 2rem;
`;

const CheckboxText = styled.p`
  font-size: 0.875rem;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const InputCheckbox = styled.input`
  accent-color: ${({ theme }) => theme.primary.normal};
`;

const Verify = styled.img`
  width: 0.9375rem;
  height: 0.9375rem;
`;

const VerifyText = styled.p`
  font-size: 0.8125rem;
  line-height: 140%; /* 1.1375rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme, state }) =>
    state === "correct"
      ? theme.primary.normal
      : state === "error"
      ? theme.colors.error
      : theme.grayScaleColor.gray70};
`;

const Button = styled.div`
  width: 20.56rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme, disabled }) =>
    disabled ? theme.grayScaleColor.gray30 : theme.grayScaleColor.white};
  font-size: 1.13rem;
  border-radius: 0.31rem;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.grayScaleColor.gray10 : theme.primary.normal};
  border: none;
  cursor: pointer;
  // &:hover {
  //   background-color: ${({ theme }) => theme.primary.hover};
  // }

  transition: all 0.3s;
  // To adapt css in iphone
  -webkit-appearance: none;
`;

// 모바일 컴포넌트

const MobileContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 1.88rem;
`;

const Logo = styled.img`
  width: 6.25rem;
  height: 1.13188rem;
`;

const SetPhoneNumber = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const params = new URLSearchParams(location.search);
  const { auth, setAuth, setPersist } = useAuth();
  const [isMounted, setIsMounted] = useState(false);
  const [phone, setPhone] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const [isPhoneValid, setIsPhoneValid] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE})`,
  });
  const axiosPrivate = useAxiosPrivate();

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (!phone) {
      console.log("hi");
      setError(
        "😢 비밀번호가 서로 일치하지 않아요. 다시 한 번 확인해 주시겠어요?"
      );
      return;
    }
    setIsLoading(true);

    const response = await axiosPrivate.post(`/phone/${auth.user._id}`, {
      phone: phone.replaceAll("-", ""),
    });

    if (!response.data.ok) {
      setIsLoading(false);
      setError(response.data.msg);
      return;
    }
    // alert(response.data.msg);

    setAuth({
      user: {
        ...auth.user,
        ...response.data.data.user,
      },
      accessToken: auth.accessToken,
    });
    setSuccess(true);
    setIsLoading(false);
    navigate("/set-profile");
  };
  const handlePasswordChange = (value) => {
    setPhone(value);
    if (value.length === 0) {
      setIsPhoneValid();
      return;
    }
    if (value.length > 0 && value.length < 14) {
      setDisabled(true);
    } else {
    }

    if (validatePhone(value)) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
  };

  const isDisable = () => {
    return !validatePhone(phone);
  };

  useEffect(() => {
    // if (phoneConfirm.length > 0 && phoneConfirm.length < 6) {
    //   console.log("hi");
    //   setDisabled(true);
    //   setError("최소 6자 이상 입력해주세요");
    // } else {
    //   setError("");
    // }
    setError("");
    if (phone.length > 0 && !passwordError) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [phone]);

  if (isMobile) {
    return (
      <MobileContainer>
        <Helmet>
          <meta name="theme-color" content="#FFFFFF" />
        </Helmet>
        <Logo
          src={`${process.env.PUBLIC_URL}/assets/logo/maumfit_logo.png`}
          alt="logo"
        />
        <Text isMobile={isMobile}>
          사용 중인 휴대폰 번호를
          <br />
          입력해 주세요
        </Text>
        <DetailText>체크업 및 트레이닝 알림을 보내 드려요</DetailText>
        <LabelInput
          htmlFor={"휴대폰 번호"}
          type={"number"}
          error={phone.length === 0 ? false : !validatePhone(phone)}
          onChange={handlePasswordChange}
          value={phone}
        />
        <FlexColumn
          style={{ gap: "0.5rem", marginTop: "1rem", marginBottom: "3.13rem" }}
        >
          <FlexRow style={{ alignItems: "center", gap: "0.62rem" }}>
            <Verify
              src={
                isPhoneValid === undefined
                  ? `${process.env.PUBLIC_URL}/svg/verify_default.svg`
                  : isPhoneValid
                  ? `${process.env.PUBLIC_URL}/svg/verify_correct.svg`
                  : `${process.env.PUBLIC_URL}/svg/verify_error.svg`
              }
            />
            <VerifyText
              state={
                isPhoneValid === undefined
                  ? "default"
                  : isPhoneValid
                  ? "correct"
                  : "error"
              }
            >
              형식에 맞게 입력했어요
            </VerifyText>
          </FlexRow>
        </FlexColumn>
        <Button
          style={{
            marginTop: "6rem",
            marginBottom: "3.12rem",
            width: "100%",
          }}
          disabled={isDisable() || isLoading}
          onClick={
            isLoading || isDisable()
              ? () => {
                  console.log("click");
                }
              : handleUpdatePassword
          }
        >
          {isLoading ? (
            <DotWave size={40} speed={1} color="#FFF" />
          ) : (
            "입력 완료"
          )}
        </Button>
        {/* value={
            isLoading ? (
              "회원가입 중..."
            ) : (
              <DotWave size={47} speed={1} color="#FF812C" />
            )
          }
          style={{
            marginTop: "3.12rem",
            marginBottom: "3.12rem",
            width: "100%",
          }}
          disabled={isDisable() || isLoading}
          onClick={handleUpdatePassword}
        /> */}
      </MobileContainer>
    );
  }

  return (
    // <BackgroundContainer>
    <Card>
      {/* <Form> */}
      <Text>
        사용 중인 휴대폰 번호를
        <br />
        입력해 주세요
      </Text>
      <DetailText>체크업 및 트레이닝 알림을 보내 드려요</DetailText>
      <LabelInput
        htmlFor={"휴대폰 번호"}
        type={"text"}
        error={phone.length === 0 ? false : !validatePhone(phone)}
        onChange={handlePasswordChange}
      />
      <FlexColumn
        style={{ gap: "0.5rem", marginTop: "1rem", marginBottom: "3.13rem" }}
      >
        <FlexRow style={{ alignItems: "center", gap: "0.62rem" }}>
          <Verify
            src={
              isPhoneValid === undefined
                ? `${process.env.PUBLIC_URL}/svg/verify_default.svg`
                : isPhoneValid
                ? `${process.env.PUBLIC_URL}/svg/verify_correct.svg`
                : `${process.env.PUBLIC_URL}/svg/verify_error.svg`
            }
          />
          <VerifyText
            state={
              isPhoneValid === undefined
                ? "default"
                : isPhoneValid
                ? "correct"
                : "error"
            }
          >
            형식에 맞게 입력했어요
          </VerifyText>
        </FlexRow>
      </FlexColumn>
      <Button
        style={{
          marginTop: "10rem",
          marginBottom: "3.12rem",
          width: "100%",
        }}
        disabled={isDisable() || isLoading}
        onClick={
          isLoading || isDisable()
            ? () => {
                console.log("click");
              }
            : handleUpdatePassword
        }
      >
        {isLoading ? <DotWave size={40} speed={1} color="#FFF" /> : "입력 완료"}
      </Button>
    </Card>
  );
};

export default SetPhoneNumber;
