import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 5.38rem;
  height: 14rem;
  border-radius: 0.63rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  z-index: 1;
  position: absolute;
  top: 1rem;
  left: 10.44rem;
  overflow-y: scroll;
`;

const Item = styled.div`
  width: 100%;
  height: 1.81rem;
  font-size: 0.75rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.black};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, selected }) => selected && theme.primary.light};
  &:hover {
    background: ${({ theme }) => theme.primary.light};
  }
  cursor: pointer;
`;

const Dropdown = ({ style, year, setYear, setFocusAt, setClick }) => {
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= currentYear - 100; i--) {
    years.push(i);
  }
  const [open, setOpen] = useState(true);
  const selectRef = useRef();
  const handleClick = (value) => {
    setYear(value);
    setOpen(false);
    setFocusAt(0);
    setClick(false);
    // selectRef.current.style = "display: none";
  };

  useEffect(() => {
    if (!year) {
      selectRef.current.scrollTop += (currentYear - 1991 - 4) * 30;
    } else {
      selectRef.current.scrollTop += (currentYear - year - 4) * 30;
    }
  }, []);

  if (!open) {
    return null;
  }
  return (
    <Container ref={selectRef} style={style}>
      {years.map((item) => (
        <Item
          key={item}
          selected={item === year}
          value={item}
          onClick={() => {
            handleClick(item);
          }}
        >
          {item}
        </Item>
      ))}
    </Container>
  );
};

export default Dropdown;
