import styled from "styled-components";

const Svg = styled.svg`
  cursor: pointer;
`;

const CloseXButton = ({ style, onClick }) => (
  <Svg
    style={style}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M1 1L14.9999 15"
      stroke="#BBBBBB"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M15 1L1.00008 15"
      stroke="#BBBBBB"
      stroke-width="2"
      stroke-linecap="round"
    />
  </Svg>
);

export default CloseXButton;
