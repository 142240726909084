import styled from "styled-components";

const Container = styled.div`
  padding: 2rem 2.5rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  border-radius: 0.94rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  font-weight: 400;
`;

const ShareCommentBox = ({ text, style }) => {
  return <Container style={style}>{text}</Container>;
};

export default ShareCommentBox;
