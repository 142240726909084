import styled from "styled-components";
import SubmitButton from "../SubmitButton";

const Card = styled.div`
  width: 28.13rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 2.81rem 3.13rem;
  position: relative;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 1.25rem;
  line-height: 1.88rem;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 500;
  margin-bottom: 1.13rem;
`;

const Text = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: -0.04em;
  margin-bottom: 1.63rem;
`;

const DeleteSuccessModal = ({ okHandler, closeModal }) => {
  return (
    <Card>
      <img
        onClick={closeModal}
        src={`${process.env.PUBLIC_URL}/svg/close_button.svg`}
        alt="close"
        style={{
          position: "absolute",
          right: "1.56rem",
          top: "1.56rem",
          cursor: "pointer",
        }}
      />
      <Title>삭제 요청이 완료되었습니다!</Title>
      <Text>
        해당 마음 기록은 즉시 숨김 처리 되었으며,
        <br /> 맘핏 관리자 확인 후 삭제 처리가 완료됩니다😊
      </Text>
      <SubmitButton
        value="눌러서 확인하기"
        onClick={okHandler}
        style={{
          width: "12.5rem",
          height: "2.81rem",
          fontSize: "0.94rem",
        }}
      />
    </Card>
  );
};

export default DeleteSuccessModal;
