import styled from "styled-components";
import Button from "./Button";

const Container = styled.div`
  width: 100%;
  max-width: 335px;
  height: 153px;
  padding: 30px;
  background: white;
  border-radius: 20px;
`;

const Title = styled.h3`
  color: #000;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.04em;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 22px;
  gap: 10px;
`;

const DeletePhotoDialog = ({ okHandler, cancelHandler }) => {
  return (
    <Container>
      <Title>찐-미소 인증샷을 삭제하시겠어요?</Title>
      <ButtonContainer>
        <Button
          text={"삭제하기"}
          style={{ background: "#EDF3F3", color: "#7C8585" }}
          clickHandler={okHandler}
        />
        <Button text={"돌아가기"} clickHandler={cancelHandler} />
      </ButtonContainer>
    </Container>
  );
};

export default DeletePhotoDialog;
