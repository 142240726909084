import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { TABLET_LANDSCAPE } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import LeaderReport from "../../surveys/checkup@2.0/LeaderReport";
import PersonalReport from "../../surveys/checkup@2.0/PersonalReport";

const Container = styled.div`
  margin-left: var(--sidebar-width);
  // height: calc(100% - 4.06rem);
  padding: 2.25rem 2.5rem;
  width: 100%;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    margin-left: var(--sidebar-tablet-landscape-width);
  }
`;

const BackButton = styled.div`
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
`;

const BackSvg = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM19 9C19.5523 9 20 8.55228 20 8C20 7.44772 19.5523 7 19 7V9ZM1 9H19V7H1V9Z"
        fill="#222222"
      />
    </svg>
  );
};

const PersonalCheckupReportMonth = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <Container>
      <BackButton onClick={handleBackClick}>
        <BackSvg /> 리스트로 돌아기기
      </BackButton>
      <PersonalReport />
      {/* <LeaderReport /> */}

      {/* <ModalBackground>
        <div
          style={{
            width: "80%",
            height: "80%",
            background: "#EDF3F3",
            overflow: "scroll",
          }}
        >
          <LeaderReport />
        </div>
      </ModalBackground> */}
    </Container>
  );
};

export default PersonalCheckupReportMonth;
