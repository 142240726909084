import styled from "styled-components";
import { MOBILE } from "../../../../Constants";

const Heading = styled.h3`
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.primary.normal};
  font-weight: 600;
  @media (max-width: ${MOBILE}) {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
`;

const Heading2 = ({ children, style }) => {
  return <Heading style={style}>{children}</Heading>;
};

export default Heading2;
