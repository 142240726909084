import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

* {
    box-sizing: border-box;
	font-family: 'Pretendard', 'Montserrat', sans-serif;
	/*-webkit-user-select: none;
	-ms-user-select: none; 
	user-select: none;*/
}
html {
	height: 100%;
	--navbar-height: 4.06rem;
	--sidebar-width: 16.3125rem;
	--sidebar-manager-width: 13.75rem;
	--sidebar-tablet-landscape-width: 4.125rem;
	--mobile-width: 425px;
}
body {
	line-height: 1;
	font-family: 'Pretendard', 'Montserrat', sans-serif;
	height: 100%;
}
body[data-aos-delay='3200'] [data-aos],
[data-aos][data-aos][data-aos-delay='3200'] {
  transition-delay: 3200ms;
}
body[data-aos-delay='3500'] [data-aos],
[data-aos][data-aos][data-aos-delay='3500'] {
  transition-delay: 3500ms;
}
body[data-aos-delay='4000'] [data-aos],
[data-aos][data-aos][data-aos-delay='4000'] {
  transition-delay: 4000ms;
}
body[data-aos-delay='4800'] [data-aos],
[data-aos][data-aos][data-aos-delay='4800'] {
  transition-delay: 4800ms;
}
body[data-aos-delay='5000'] [data-aos],
[data-aos][data-aos][data-aos-delay='5000'] {
  transition-delay: 5000ms;
}
body[data-aos-delay='5600'] [data-aos],
[data-aos][data-aos][data-aos-delay='5600'] {
  transition-delay: 5600ms;
}
body[data-aos-delay='6000'] [data-aos],
[data-aos][data-aos][data-aos-delay='6000'] {
  transition-delay: 6000ms;
}
body[data-aos-delay='6400'] [data-aos],
[data-aos][data-aos][data-aos-delay='6400'] {
  transition-delay: 6400ms;
}
body[data-aos-delay='7000'] [data-aos],
[data-aos][data-aos][data-aos-delay='7000'] {
  transition-delay: 7000ms;
}
body[data-aos-delay='8000'] [data-aos],
[data-aos][data-aos][data-aos-delay='8000'] {
  transition-delay: 8000ms;
}
body[data-aos-delay='9000'] [data-aos],
[data-aos][data-aos][data-aos-delay='9000'] {
  transition-delay: 9000ms;
}
body[data-aos-delay='10000'] [data-aos],
[data-aos][data-aos][data-aos-delay='10000'] {
  transition-delay: 10000ms;
}
body[data-aos-delay='11000'] [data-aos],
[data-aos][data-aos][data-aos-delay='11000'] {
  transition-delay: 11000ms;
}
body[data-aos-delay='12000'] [data-aos],
[data-aos][data-aos][data-aos-delay='12000'] {
  transition-delay: 12000ms;
}
body[data-aos-delay='13000'] [data-aos],
[data-aos][data-aos][data-aos-delay='13000'] {
  transition-delay: 13000ms;
}
body[data-aos-delay='14000'] [data-aos],
[data-aos][data-aos][data-aos-delay='14000'] {
  transition-delay: 14000ms;
}
body[data-aos-delay='15000'] [data-aos],
[data-aos][data-aos][data-aos-delay='15000'] {
  transition-delay: 15000ms;
}
body[data-aos-delay='16000'] [data-aos],
[data-aos][data-aos][data-aos-delay='16000'] {
  transition-delay: 16000ms;
}
body[data-aos-delay='17000'] [data-aos],
[data-aos][data-aos][data-aos-delay='17000'] {
  transition-delay: 17000ms;
}
body[data-aos-delay='18000'] [data-aos],
[data-aos][data-aos][data-aos-delay='18000'] {
  transition-delay: 18000ms;
}
body[data-aos-delay='19000'] [data-aos],
[data-aos][data-aos][data-aos-delay='19000'] {
  transition-delay: 19000ms;
}
body[data-aos-delay='20000'] [data-aos],
[data-aos][data-aos][data-aos-delay='20000'] {
  transition-delay: 20000ms;
}
body[data-aos-delay='21000'] [data-aos],
[data-aos][data-aos][data-aos-delay='21000'] {
  transition-delay: 21000ms;
}
body[data-aos-delay='22000'] [data-aos],
[data-aos][data-aos][data-aos-delay='22000'] {
  transition-delay: 22000ms;
}

div#root {
	height: 100%;
}

ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button {
	font-family: inherit;
}

/* 인덱스 페이지 배너 스타일 적용 */
ul.indicators {
	position: absolute;
	margin: 0 auto;
	left: 0;
	right: 0;
	bottom: 1.63rem;
}

`;

export default GlobalStyles;
