import styled from "styled-components";

const Li = styled.li`
  padding-left: 0.63rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
`;

const DotListItem = ({ children, style }) => {
  return <Li style={style}>{children}</Li>;
};

export default DotListItem;
