import styled from "styled-components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import BackgroundContainer from "../../components/BackgroundContainer";
import BackgroundSymbol from "../../components/BackgroundSymbol";
import Form from "../../components/Form";
import LabelInput from "../../components/LabelInput";
import SubmitButton from "../../components/SubmitButton";
import ErrorText from "../../components/ErrorText";
import ToastPopup from "../../components/ToastPopup";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import Card from "./components/Card";
import Checkbox from "../../components/Checkbox";
import validatePassword from "../../utils/validatePassword";
import { MOBILE } from "../../Constants";
import { useMediaQuery } from "react-responsive";
import { DotWave } from "@uiball/loaders";
import { Helmet } from "react-helmet";
import SetAgree from "./SetAgree";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // @media screen and (max-width: 480px) {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   width: 90%;
  // }
`;

const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  margin-top: ${({ isMobile }) => (isMobile ? "3.24rem" : "4.94rem")};
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const CheckboxText = styled.p`
  font-size: 0.875rem;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const InputCheckbox = styled.input`
  accent-color: ${({ theme }) => theme.primary.normal};
`;

const Verify = styled.img`
  width: 0.9375rem;
  height: 0.9375rem;
`;

const VerifyText = styled.p`
  font-size: 0.8125rem;
  line-height: 140%; /* 1.1375rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme, state }) =>
    state === "correct"
      ? theme.primary.normal
      : state === "error"
      ? theme.colors.error
      : theme.grayScaleColor.gray70};
`;

const Button = styled.div`
  width: 20.56rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme, disabled }) =>
    disabled ? theme.grayScaleColor.gray30 : theme.grayScaleColor.white};
  font-size: 1.13rem;
  border-radius: 0.31rem;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.grayScaleColor.gray10 : theme.primary.normal};
  border: none;
  cursor: pointer;
  // &:hover {
  //   background-color: ${({ theme }) => theme.primary.hover};
  // }

  transition: all 0.3s;
  // To adapt css in iphone
  -webkit-appearance: none;
`;

// 모바일 컴포넌트

const MobileContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 1.88rem;
`;

const Logo = styled.img`
  width: 6.25rem;
  height: 1.13188rem;
`;

const SetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const params = new URLSearchParams(location.search);
  const { auth, setAuth, setPersist } = useAuth();
  const [isMounted, setIsMounted] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isPasswordLongerThanEight, setIsPasswordLongerThanEight] = useState();
  const [isPasswordValid, setIsPasswordValid] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE})`,
  });

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (!newPassword) {
      console.log("hi");
      setError(
        "😢 비밀번호가 서로 일치하지 않아요. 다시 한 번 확인해 주시겠어요?"
      );
      return;
    }

    setShowPrivacy(true);
  };
  const handlePasswordChange = (value) => {
    setNewPassword(value);
    if (value.length === 0) {
      setIsPasswordLongerThanEight();
      setIsPasswordValid();
      return;
    }
    if (value.length > 0 && value.length < 8) {
      setDisabled(true);
      setIsPasswordLongerThanEight(false);
    } else {
      setIsPasswordLongerThanEight(true);
    }
    if (validatePassword(value)) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  };

  const isDisable = () => {
    return !validatePassword(newPassword);
  };

  useEffect(() => {
    // if (newPasswordConfirm.length > 0 && newPasswordConfirm.length < 6) {
    //   console.log("hi");
    //   setDisabled(true);
    //   setError("최소 6자 이상 입력해주세요");
    // } else {
    //   setError("");
    // }
    setError("");
    if (newPassword.length > 0 && !passwordError) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [newPassword]);

  useEffect(() => {
    if (auth?.user?.email) {
      return navigate(from, { replace: true });
    }
    const fetch = async () => {
      const res = await axios.get(
        `/users/verify?email=${params.get("email")}&token=${params.get(
          "token"
        )}`
      );

      if (!res.data.ok) {
        // alert(res.data.msg);
        // navigate("/login");
      }
    };

    fetch();

    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  if (!isMounted) {
    return null;
  }

  if (isMobile) {
    return (
      <>
        <MobileContainer>
          <Helmet>
            <meta name="theme-color" content="#FFFFFF" />
          </Helmet>
          <Logo
            src={`${process.env.PUBLIC_URL}/assets/logo/maumfit_logo.png`}
            alt="logo"
          />
          <Text isMobile={isMobile}>
            반갑습니다 {params.get("name")} 님 🙌
            <br />
            비밀번호 설정하고 맘핏을 시작해볼까요?
          </Text>
          <LabelInput
            htmlFor={"신규 비밀번호"}
            type={isShowPassword ? "text" : "password"}
            error={
              newPassword.length === 0 ? false : !validatePassword(newPassword)
            }
            onChange={handlePasswordChange}
            isShowPassword={isShowPassword}
            setIsShowPassword={setIsShowPassword}
          />
          <FlexColumn
            style={{
              gap: "0.5rem",
              marginTop: "1rem",
              marginBottom: "3.13rem",
            }}
          >
            <FlexRow style={{ alignItems: "center", gap: "0.62rem" }}>
              <Verify
                src={
                  isPasswordLongerThanEight === undefined
                    ? `${process.env.PUBLIC_URL}/svg/verify_default.svg`
                    : isPasswordLongerThanEight
                    ? `${process.env.PUBLIC_URL}/svg/verify_correct.svg`
                    : `${process.env.PUBLIC_URL}/svg/verify_error.svg`
                }
              />
              <VerifyText
                state={
                  isPasswordLongerThanEight === undefined
                    ? "default"
                    : isPasswordLongerThanEight
                    ? "correct"
                    : "error"
                }
              >
                최소 8자 이상
              </VerifyText>
            </FlexRow>
            <FlexRow style={{ alignItems: "center", gap: "0.62rem" }}>
              <Verify
                src={
                  isPasswordValid === undefined
                    ? `${process.env.PUBLIC_URL}/svg/verify_default.svg`
                    : isPasswordValid
                    ? `${process.env.PUBLIC_URL}/svg/verify_correct.svg`
                    : `${process.env.PUBLIC_URL}/svg/verify_error.svg`
                }
              />
              <VerifyText
                state={
                  isPasswordValid === undefined
                    ? "default"
                    : isPasswordValid
                    ? "correct"
                    : "error"
                }
              >
                영문, 숫자, 특수문자 조합
              </VerifyText>
            </FlexRow>
          </FlexColumn>

          <Button
            style={{
              marginTop: "3.12rem",
              marginBottom: "3.12rem",
              width: "100%",
            }}
            disabled={isDisable() || isLoading}
            onClick={
              isLoading || isDisable()
                ? () => {
                    console.log("click");
                  }
                : handleUpdatePassword
            }
          >
            {isLoading ? (
              <DotWave size={40} speed={1} color="#FFF" />
            ) : (
              "설정완료"
            )}
          </Button>
          {/* value={
            isLoading ? (
              "회원가입 중..."
            ) : (
              <DotWave size={47} speed={1} color="#FF812C" />
            )
          }
          style={{
            marginTop: "3.12rem",
            marginBottom: "3.12rem",
            width: "100%",
          }}
          disabled={isDisable() || isLoading}
          onClick={handleUpdatePassword}
        /> */}
        </MobileContainer>
        {showPrivacy && (
          <SetAgree
            password={newPassword}
            email={params.get("email")}
            setPasswordToken={params.get("token")}
          />
        )}
      </>
    );
  }

  return (
    // <BackgroundContainer>
    showPrivacy ? (
      <SetAgree
        password={newPassword}
        email={params.get("email")}
        setPasswordToken={params.get("token")}
      />
    ) : (
      <Card>
        {/* <Form> */}
        <Text>
          반갑습니다 {params.get("name")} 님 🙌
          <br />
          비밀번호 설정하고 맘핏을 시작해볼까요?
        </Text>
        <LabelInput
          htmlFor={"신규 비밀번호"}
          type={isShowPassword ? "text" : "password"}
          error={
            newPassword.length === 0 ? false : !validatePassword(newPassword)
          }
          onChange={handlePasswordChange}
          isShowPassword={isShowPassword}
          setIsShowPassword={setIsShowPassword}
        />
        <FlexColumn
          style={{ gap: "0.5rem", marginTop: "1rem", marginBottom: "3.13rem" }}
        >
          <FlexRow style={{ alignItems: "center", gap: "0.62rem" }}>
            <Verify
              src={
                isPasswordLongerThanEight === undefined
                  ? `${process.env.PUBLIC_URL}/svg/verify_default.svg`
                  : isPasswordLongerThanEight
                  ? `${process.env.PUBLIC_URL}/svg/verify_correct.svg`
                  : `${process.env.PUBLIC_URL}/svg/verify_error.svg`
              }
            />
            <VerifyText
              state={
                isPasswordLongerThanEight === undefined
                  ? "default"
                  : isPasswordLongerThanEight
                  ? "correct"
                  : "error"
              }
            >
              최소 8자 이상
            </VerifyText>
          </FlexRow>
          <FlexRow style={{ alignItems: "center", gap: "0.62rem" }}>
            <Verify
              src={
                isPasswordValid === undefined
                  ? `${process.env.PUBLIC_URL}/svg/verify_default.svg`
                  : isPasswordValid
                  ? `${process.env.PUBLIC_URL}/svg/verify_correct.svg`
                  : `${process.env.PUBLIC_URL}/svg/verify_error.svg`
              }
            />
            <VerifyText
              state={
                isPasswordValid === undefined
                  ? "default"
                  : isPasswordValid
                  ? "correct"
                  : "error"
              }
            >
              영문, 숫자, 특수문자 조합
            </VerifyText>
          </FlexRow>
        </FlexColumn>

        {/* {passwordError && (
        <ErrorText style={{ marginTop: "0.63rem" }}>{passwordError}</ErrorText>
      )} */}
        {/* <LabelInput
            htmlFor={"변경할 비밀번호 확인"}
            type="password"
            style={{ marginTop: "0.81rem" }}
            error={passwordConfirmError}
            onChange={handlePasswordConfirmChange}
          />
          {passwordConfirmError && (
            <ErrorText style={{ marginTop: "0.63rem" }}>
              {passwordConfirmError}
            </ErrorText>
          )}
          {error && (
            <ErrorText style={{ marginTop: "0.63rem" }}>{error}</ErrorText>
          )} */}

        <Button
          style={{
            marginTop: "3.12rem",
            marginBottom: "3.12rem",
            width: "100%",
          }}
          disabled={isDisable() || isLoading}
          onClick={
            isLoading || isDisable()
              ? () => {
                  console.log("click");
                }
              : handleUpdatePassword
          }
        >
          {isLoading ? (
            <DotWave size={40} speed={1} color="#FFF" />
          ) : (
            "설정완료"
          )}
        </Button>
        {/* </Form> */}
        {/* {success && (
        <ToastPopup
          text={`비밀번호를 성공적으로 변경했어요 🥳<br />맘핏 센터에서 로그인을 완료해 주세요!`}
          buttonText="로그인 화면으로 이동하기"
          onClick={handleCloseClick}
        />
      )} */}
      </Card>
    )
  );
};

export default SetPassword;
