import { useLocation, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth, setPersist } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const refresh = async () => {
    try {
      const response = await axios.get("/refresh", {
        withCredentials: true,
      });
      setAuth((prev) => {
        return {
          ...prev,
          user: {
            id: response.data.user._id,
            ...response.data.user,
            // companyId: response.data.user.companyId._id,
            // company: response.data.user.companyId.name,
            // departmentId: response.data.user.departmentId._id,
            // department: response.data.user.departmentId.name,
            // email: response.data.email,
            // name: response.data.name,
            // nickname: response.data.nickname,
            // gender: response.data.gender,
            // birthYear: response.data.birthYear,
            // interests: response.data.interests,
            // isSubscribe: response.data.isSubscribe,
            // company: response.data.company,
            // companyId: response.data.companyId,
            // department: response.data.department,
            // departmentId: response.data.departmentId,
            // firstCheckupDate: response.data.firstCheckupDate,
            // vibe: response.data.vibe,
            // point: response.data.point,
            // checkupInARow: response.data.checkupInARow,
            // isFirstCheckup: response.data.isFirstCheckup,
            // lastCheckupPoint: response.data.lastCheckupPoint,
            // role: response.data.role,
          },
          accessToken: response.data.accessToken,
        };
      });

      return response.data.accessToken;
    } catch (err) {
      setAuth({});
      localStorage.setItem("persist", false);
      setPersist(false);
      navigate(location.pathname, { replace: true });
    }
  };

  return refresh;
};

export default useRefreshToken;
