import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { forwardRef } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = forwardRef(({ labels, results }, ref) => {
  const arr = [[], [], []];
  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < results.length; j++) {
      arr[i][j] = results[j][i].score;
    }
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "end",
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
      datalabels: {
        display: false,
        color: ["#CBEAE4", "#80E5D2", "#00DBB1"],
        font: { size: 15, weight: "bold" },
        anchor: "end",
        offset: -25,
        align: "start",
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            return `${context[0].label.replaceAll(",", " ")}`;
          },
          label: (context) => {
            // console.log(context);
            let label = context.dataset.label || "";
            let count = 0;

            // switch (label) {
            //   case "1차수":
            //     count = results[0][0].total;
            //     break;
            //   case "2차수":
            //     count = results[0][1].total;
            //     break;
            //   case "3차수":
            //     count = results[0][2].total;
            //     break;
            //   default:
            //     break;
            // }
            if (label) {
              label += ": ";
              count = results[0][Number(label.slice(0, 1)) - 1].total;
            }
            if (context.parsed.y !== null) {
              label += Number(context.parsed.y.toFixed(1));
              label += ` (응답수: ${count})`;
            }
            return label;
          },
        },
      },
    },
    layout: {
      padding: 0,
    },
    categoryPercentage: 1,
    barPercentage: 0,
    scales: {
      x: {
        position: "bottom",
        offset: true,

        grid: { display: false, drawBorder: false, lineWidth: 1 },
        ticks: {
          color: "#000000",
          padding: 0,
        },
      },
      y: {
        offset: true, // y축 위/아래 짤리는 거 옵션
        min: 0,
        max: 7,
        ticks: {
          stepSize: 1,
          padding: 5,
          color: "#777777",
          font: {
            family: "Montserrat",
          },
        },
        grid: {
          drawBorder: false,
          lineWidth: 1,
        },
      },
    },
  };
  const colors = [
    "rgba(203, 234, 228, 0.7)",
    "rgba(128, 229, 210, 0.7)",
    "rgba(0, 219, 177, 0.7)",
  ];
  const data = {
    labels: labels.map((item, index) => [
      `Q${index + 1}.`,
      item.substring(0, 11),
      item.substring(11, 20),
      item.substring(20),
    ]),
    datasets: arr.map((result, index) => ({
      label: `${index + 1}차`,
      data: arr[index],
      backgroundColor: colors[index],
      borderRadius: 50,
      // barThickness: 24,
      // borderWidth: 10,
      // borderColor: "rgba(0,0,0,0)",
      categoryPercentage: 0.6,
      barPercentage: 0.7,
    })),
  };
  return <Bar ref={ref} options={options} data={data} />;
});

export default BarChart;
