import axios from "axios";

export default axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_SERVER_DOMAIN_DEV
      : process.env.REACT_APP_API_SERVER_DOMAIN_TEST,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosPrivate = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_SERVER_DOMAIN_DEV
      : process.env.REACT_APP_API_SERVER_DOMAIN_TEST,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
