import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FlexRow from "../../../components/FlexRow";
import Confetti from "./Confetti";
import FlexColumn from "../../../components/FlexColumn";
import useAuth from "../../../hooks/useAuth";
import convertPermavac from "../../../utils/convertPermavac";
import CashConfetti from "./CashConfetti";
import { useMediaQuery } from "react-responsive";

const Container = styled.div`
  border-radius: 1.25rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 2rem 2.25rem;
  position: relative;
  width: 22.125rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 50;
`;

const Heading1 = styled.p`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 600;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Heading2 = styled.p`
  text-align: center;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};

  line-height: 150%;
  margin-top: 1rem;
  margin-bottom: 1.56rem;
`;

const Heading3 = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.3rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.primary.normal};
`;

const Box = styled.div`
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  padding: 1rem 1.25rem;
  width: 100%;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

const Text1 = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const Button = styled.button`
  border-radius: 0.75rem;
  border: 1px solid
    ${({ theme, state }) =>
      state === "modified" ? theme.primary.dark : theme.primary.normal};
  background: ${({ theme, state }) =>
    state === "modified" ? theme.grayScaleColor.white : theme.primary.normal};
  padding: 0.69rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${({ theme, state }) =>
    state === "modified" ? theme.primary.dark : theme.grayScaleColor.white};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4375rem;
  cursor: pointer;
`;

const TextButton = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4375rem; /* 164.286% */
  letter-spacing: -0.035rem;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.primary.normal};
`;
const Modal = ({ close, block, type, cash }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });

  const handleClickClose = () => {
    if (isMobile) {
      if (type === 1) {
        navigate("/");
      } else {
        navigate("/mypage");
      }
    } else {
      close();
    }
  };
  const handleClickConfirm = (e, block) => {
    navigate("/blocks/recommend", { state: { type: block.permavac } });
  };

  const points = [];
  for (const key in block.permavacPoint) {
    const point = block.permavacPoint[key];
    if (point > 0) {
      points.push([convertPermavac(key), point]);
    }
  }
  return (
    <Container>
      <Heading3>오.운.완 💪</Heading3>
      <Heading1>
        <span>트레이닝을 완료했어요!</span>
      </Heading1>
      {type === 1 ? <CashConfetti /> : <Confetti />}

      {/* <Heading2>
        오늘의 마음 트레이닝을 완료하여
        <br /> <span style={{ fontWeight: 600 }}>캐시 100원</span>이
        지급되었습니다! (1일 1회)
      </Heading2> */}
      <Box style={{ marginTop: type === 1 && 0 }}>
        <FlexColumn style={{ gap: "0.5rem", alignItems: "flex-start" }}>
          {!isMobile && type === 1 && (
            <FlexRow style={{ gap: "1rem" }}>
              <Text1 style={{ fontWeight: 600 }}>획득 캐시</Text1>
              <Text1>{isMobile ? "?원" : `${cash}원`} </Text1>
            </FlexRow>
          )}
          {/* <FlexRow style={{ gap: "1rem" }}>
            <Text1 style={{ fontWeight: 600 }}>
              {type === 1 ? "누적" : "보유"} 캐시
            </Text1>
            <Text1>{auth.user.point?.toLocaleString()}원</Text1>
          </FlexRow> */}
          {points.length > 0 && (
            <FlexRow style={{ gap: "1rem" }}>
              <Text1 style={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                획득 에너지
              </Text1>
              <Text1>
                <FlexRow style={{ gap: "0.25rem", flexWrap: "wrap" }}>
                  {points.map((p, index) => (
                    <FlexRow key={index}>
                      <span>{p[0]}</span>
                      <span
                        style={{ color: "#00DBB1", marginLeft: "0.125rem" }}
                      >
                        +{p[1]}
                      </span>
                      {index === points.length - 1 ? "" : ","}
                    </FlexRow>
                  ))}
                </FlexRow>
              </Text1>
            </FlexRow>
          )}
        </FlexColumn>
      </Box>
      {isMobile && type === 1 ? (
        <FlexColumn
          style={{ width: "100%", gap: "0.81rem", alignItems: "center" }}
        >
          <Button onClick={handleClickClose}>
            {type === 1 ? "눌러서 보상 받기" : "마이 페이지"}
          </Button>
          <TextButton onClick={(e) => handleClickConfirm(e, block)}>
            트레이닝 계속 하기
          </TextButton>
        </FlexColumn>
      ) : isMobile && type === 2 ? (
        <FlexRow
          style={{ width: "100%", gap: "0.81rem", alignItems: "center" }}
        >
          <Button state={"modified"} onClick={handleClickClose}>
            {type === 1 ? "눌러서 보상 받기" : "마이 페이지"}
          </Button>
          <Button onClick={(e) => handleClickConfirm(e, block)}>
            트레이닝 더 하기
          </Button>
        </FlexRow>
      ) : (
        <FlexRow style={{ width: "100%", gap: "0.62rem" }}>
          <Button state={"modified"} onClick={handleClickClose}>
            {isMobile && type === 1
              ? "보상 받기"
              : isMobile && type !== 1
              ? "마이 페이지"
              : "닫기"}
          </Button>
          <Button onClick={(e) => handleClickConfirm(e, block)}>
            트레이닝 더 하기
          </Button>
        </FlexRow>
      )}
    </Container>
  );
};

export default Modal;
