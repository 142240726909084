import dayjs from "dayjs";
import "dayjs/locale/ko";

export const getToday = (format = "YYYY. M. D") => {
  return dayjs(new Date()).locale("ko").format(format);
};

export const getDate = (date, format = "YYYY.M.D") => {
  return dayjs(date).locale("ko").format(format);
};

export const getThisWeekMonday = (date = new Date(), format = "YYYY. M. D") => {
  return dayjs(date).locale("ko").day(1).format(format);
};

export const getThisWeekFriday = (date = new Date(), format = "YYYY. M. D") => {
  return dayjs(date).locale("ko").day(5).format(format);
};

export const getFirstDayOfMonth = (month, format = "M.D(dd)") => {
  return dayjs().month(month).locale("ko").startOf("month").format(format);
};
export const getLastDayOfMonth = (month, format = "M.D(dd)") => {
  return dayjs().month(month).locale("ko").endOf("month").format(format);
};

export const getWeekNumOfMonth = (date) => {
  const THURSDAY_NUM = 4; // 첫째주의 기준은 목요일(4)이다.

  const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const firstDayOfWeek = firstDate.getDay();

  let firstThursday = 1 + THURSDAY_NUM - firstDayOfWeek; // 첫째주 목요일
  if (firstThursday <= 0) {
    firstThursday = firstThursday + 7; // 한주는 7일
  }
  const untilDateOfFirstWeek = firstThursday - 7 + 3; // 월요일기준으로 계산 (월요일부터 한주의 시작)
  const weekNum = Math.ceil((date.getDate() - untilDateOfFirstWeek) / 7) - 1;

  if (weekNum < 0) {
    // 첫째주 이하일 경우 전월 마지막주 계산
    const lastDateOfMonth = new Date(date.getFullYear(), date.getMonth(), 0);
    const result = getWeekNumOfMonth(lastDateOfMonth);
    return result;
  }

  return [weekNum + 1, date.getMonth() + 1];
};

export const subtractDate = (date, unit, value) => {
  const from = dayjs(date).locale("ko");
  return from.subtract(value, unit)["$d"];
};

export const isLeapYear = (year) => {
  return year % 4 === 0 && (year % 400 === 0 || year % 100 !== 0);
};
export const lastMonthDay = (jsMonth, year) => {
  switch (jsMonth) {
    case 1:
      return isLeapYear(year) ? 29 : 28;
    case 3:
    case 5:
    case 8:
    case 10:
      return 30;
    default:
      return 31;
  }
};
