import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import CloseXButton from "../components/CloseXButton";
import ModalHeading2 from "../components/ModalHeading2";

const Box = styled.div`
  margin-top: 2.44rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 1.5rem 2rem;
`;

const Name = styled.p`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: -0.03125rem;
`;

const Others = styled.p`
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
`;
const Text = styled.p`
  font-size: 0.9375rem;
  line-height: 140%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  color: #000;
  margin-top: 2.44rem;
  text-align: center;
`;

const ModalButton = styled.button`
  border-radius: 0.4375rem;
  padding: 0.78rem 3.84rem;
  flex: 1;
  border: none;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  cursor: pointer;
`;

const CancelButton = styled(ModalButton)`
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const ConfirmButton = styled(ModalButton)`
  background: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.grayScaleColor.white};
`;

const DeleteManagerModal = ({ closeHandler }) => {
  return (
    <>
      <FlexRow
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <ModalHeading2 text="관리자 정보 삭제" />
        <CloseXButton onClick={closeHandler} />
      </FlexRow>
      <Box>
        <FlexColumn style={{ gap: "0.5rem" }}>
          <Name>박블룸</Name>
          <Others>연구개발2팀</Others>
          <Others>대리</Others>
          <Others>bloompark@bloomhappiness.com</Others>
        </FlexColumn>
      </Box>
      <Text>해당 관리자 정보를 삭제하시겠습니까?</Text>
      <FlexRow
        style={{
          marginTop: "2.44rem",
          justifyContent: "space-between",
          gap: "0.69rem",
        }}
      >
        <CancelButton onClick={closeHandler}>돌아가기</CancelButton>
        <ConfirmButton>수정 완료</ConfirmButton>
      </FlexRow>
    </>
  );
};

export default DeleteManagerModal;
