import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.backgroundColor.darkgray20};
  //   border: 1px solid black;
  z-index: 0;
`;

const BackgroundContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default BackgroundContainer;
