import styled from "styled-components";

const SubTitle = styled.h3`
  color: ${({ theme }) => theme.primary.normal};
  font-size: 1.25rem;
  margin: 0 auto;
  margin-top: 4.19rem;
  margin-bottom: 0.63rem;
  font-weight: 500;
  text-align: center;
`;

const SubHeading = ({ text, style }) => (
  <SubTitle style={style}>{text}</SubTitle>
);

export default SubHeading;
