import styled from "styled-components";
import FlexRow from "../../../components/FlexRow";

const Container = styled.div`
  padding: 1.5rem 2rem;
  border-radius: 0.94rem;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.grayScaleColor.white};
  width: 30rem;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.13rem;
  line-height: 1.38rem;
  font-weight: 600;
  margin-left: 0.5rem;
`;
const AnswerText = styled.span`
  color: ${({ theme }) => theme.primary.normal};
  font-size: 1.4rem;
  line-height: 1.81rem;
  font-weight: 600;
`;
const CardBox = ({ src, title, answer }) => {
  return (
    <Container>
      <FlexRow style={{ marginBottom: "0.75rem", alignItems: "center" }}>
        <img src={src} width={22} height={16.5} />
        <Title>{title}</Title>
      </FlexRow>
      <AnswerText>{answer}</AnswerText>
    </Container>
  );
};

export default CardBox;
