import axios, { axiosPrivate } from "../../../api/axios";

const getSmileAverage = async ({ auth, cid }) => {
  if (auth?.user) {
    const response = await axiosPrivate.get("/smile/average", {
      params: {
        cid: auth.user.companyId._id,
      },
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
      },
    });
    return response.data;
  } else {
    const response = await axios.get(`/smile/guests/average`, {
      params: { cid },
    });
    return response.data;
  }
};

export default getSmileAverage;
