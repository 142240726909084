import styled from "styled-components";

const Text = styled.h1`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.5rem;
`;

const Heading = ({ children, style }) => {
  return <Text style={style}>{children}</Text>;
};

export default Heading;
