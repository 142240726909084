import styled from "styled-components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import BackgroundContainer from "../../components/BackgroundContainer";
import BackgroundSymbol from "../../components/BackgroundSymbol";
import Form from "../../components/Form";
import Logo from "../../components/Logo";
import LabelInput from "../../components/LabelInput";
import SubmitButton from "../../components/SubmitButton";
import ErrorText from "../../components/ErrorText";
import ToastPopup from "../../components/ToastPopup";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // @media screen and (max-width: 480px) {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   width: 90%;
  // }
`;

const Text = styled.p`
  width: 100%;
  font-size: 0.94rem;
  text-align: center;
  line-height: 1.44rem;
  letter-spacing: -0.04em;
  font-weight: 300;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const params = new URLSearchParams(location.search);
  const { auth } = useAuth();
  const [isMounted, setIsMounted] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    console.log(newPassword, newPasswordConfirm);
    if (newPassword !== newPasswordConfirm) {
      console.log("hi");
      setError(
        "😢 비밀번호가 서로 일치하지 않아요. 다시 한 번 확인해 주시겠어요?"
      );
      return;
    }

    try {
      const response = await axios.patch("/reset-password", {
        email: params.get("email"),
        password: newPassword,
        resetToken: params.get("resetToken"),
      });

      // alert(response.data.msg);
      setSuccess(true);
      // navigate("/login");
    } catch (err) {
      // alert(err.response.data.msg);
      setError(err.response.data.msg);
    }
  };
  const handlePasswordChange = (value) => {
    console.log(value);

    setNewPassword(value);
    if (value.length > 0 && value.length < 6) {
      console.log("hi");
      setDisabled(true);
      setPasswordError("최소 6자 이상 입력해주세요");
    } else {
      setPasswordError("");
    }
  };

  const handlePasswordConfirmChange = (value) => {
    setNewPasswordConfirm(value);
    if (value.length > 0 && value.length < 6) {
      console.log("hi");
      setDisabled(true);
      setPasswordConfirmError("최소 6자 이상 입력해주세요");
    } else {
      setPasswordConfirmError("");
    }
  };

  const handleCloseClick = () => {
    setSuccess(false);
    navigate("/login");
  };

  useEffect(() => {
    // if (newPasswordConfirm.length > 0 && newPasswordConfirm.length < 6) {
    //   console.log("hi");
    //   setDisabled(true);
    //   setError("최소 6자 이상 입력해주세요");
    // } else {
    //   setError("");
    // }
    setError("");
    if (
      newPassword.length > 0 &&
      newPasswordConfirm.length > 0 &&
      !passwordConfirmError &&
      !passwordError
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [newPassword, newPasswordConfirm]);

  useEffect(() => {
    if (auth?.user?.email) {
      return navigate(from, { replace: true });
    }

    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  if (!isMounted) {
    return null;
  }
  return (
    <BackgroundContainer>
      <Wrapper>
        <Form>
          <Logo style={{ marginTop: "3.94rem", marginBottom: "2.5rem" }} />
          <Text>
            메일 계정 확인이 완료 되었습니다☺️ <br />
            새롭게 변경할 비밀번호를 입력해 주세요.
          </Text>
          <LabelInput
            htmlFor={"변경할 비밀번호"}
            type="password"
            error={passwordError.length > 0}
            onChange={handlePasswordChange}
          />
          {passwordError && (
            <ErrorText style={{ marginTop: "0.63rem" }}>
              {passwordError}
            </ErrorText>
          )}
          <LabelInput
            htmlFor={"변경할 비밀번호 확인"}
            type="password"
            style={{ marginTop: "0.81rem" }}
            error={passwordConfirmError}
            onChange={handlePasswordConfirmChange}
          />
          {passwordConfirmError && (
            <ErrorText style={{ marginTop: "0.63rem" }}>
              {passwordConfirmError}
            </ErrorText>
          )}
          {error && (
            <ErrorText style={{ marginTop: "0.63rem" }}>{error}</ErrorText>
          )}
          <SubmitButton
            value="변경 완료하기"
            style={{ marginTop: "4.56rem" }}
            disabled={disabled}
            onClick={handleUpdatePassword}
          />
        </Form>
        {success && (
          <ToastPopup
            text={`비밀번호를 성공적으로 변경했어요 🥳<br />맘핏 센터에서 로그인을 완료해 주세요!`}
            buttonText="로그인 화면으로 이동하기"
            onClick={handleCloseClick}
          />
        )}
      </Wrapper>
      {/* <BackgroundSymbol /> */}
    </BackgroundContainer>
  );
};

export default ResetPassword;
