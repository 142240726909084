import styled from "styled-components";
import { TABLET_LANDSCAPE } from "../../Constants";
import ShareList from "../surveys/checkup@2.0/ShareList";
import CardLayout from "./CardLayout";
import Heading1 from "./Heading1";
import Heading2 from "./Heading2";

const Container = styled.div`
  // height: calc(100% - 4.06rem);

  padding: 2.25rem 2.5rem;
  padding-left: calc(2.75rem + var(--sidebar-width));
  width: 100%;
  //   border: 1px solid black;
  display: flex;
  flex-direction: column;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    margin-left: var(--sidebar-tablet-landscape-width);
  }
`;

const CheckupShareListPage = () => {
  return (
    <Container>
      <Heading1>마음 나누기 답변</Heading1>
      <Heading2 style={{ marginBottom: "2.12rem" }}>
        동료들이 나눈 마음 리스트입니다
      </Heading2>
      <CardLayout style={{ height: "auto", padding: "2.5rem 3rem" }}>
        <ShareList style={{ height: "auto" }} />
      </CardLayout>
    </Container>
  );
};

export default CheckupShareListPage;
