import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
  Canvas,
  PDFDownloadLink,
  BlobProvider,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import styled from "styled-components";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import TablePdf from "./TablePdf";
import { LeaderChart } from "./components/LeaderChart";
import html2canvas from "html2canvas";
import BarChart from "./components/BarChart";

const ORANGE = "#FF812C";
const BOX_COLOR = "#F5F5F5";
const PRIMARY_NORMAL = "#00DBB1";
const BLACK = "#111111";

Font.register({
  family: "Pretendard",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff",
      fontWeight: 100,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff",
      fontWeight: 200,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff",
      fontWeight: 300,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff",
      fontWeight: 400,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff",
      fontWeight: 500,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff",
      fontWeight: 600,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff",
      fontWeight: 700,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff",
      fontWeight: 800,
    },
  ],
});

Font.registerEmojiSource({
  format: "png",
  url: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/",
});

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
    zIndex: 2,
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#EDF3F3",

    // paddingHorizontal: "30px",
    // paddingTop: "40px",
    fontFamily: "Pretendard",
    position: "relative",
    paddingBottom: 20,
  },
  chartPage: {
    flexDirection: "column",
    backgroundColor: "#EDF3F3",
    paddingVertical: 25,
    fontFamily: "Pretendard",
    position: "relative",
  },
  navbar: {
    width: "100%",
    height: "auto",
    // backgroundColor: "#E4E4E4",

    flexDirection: "row",
    // justifyContent: "center",
    // alignItems: "center",
  },
  wrapper: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  subHeading: {
    color: PRIMARY_NORMAL,
    fontSize: 10,
    fontWeight: 600,
    marginTop: 20,
  },
  heading1: {
    color: BLACK,
    fontSize: 14,
    fontWeight: 600,
    marginTop: 8,
  },
  heading2: {
    fontSize: 10,
    fontWeight: 500,
    marginTop: 8,
    marginBottom: "16px",
  },
  infoBox: {
    flexDirection: "row",
    //   justify-content: space-evenly;
    backgroundColor: "#F8FBFB",
    padding: "8px 20px",
    gap: 16,
    borderRadius: 6,
  },
  infoText: {
    fontSize: 8,
    color: "#777777",
  },
  calendarImage: {
    width: 11,
    marginRight: 4,
  },
  content: {
    width: "90%",
    flexDirection: "column",
    alignItems: "center",
  },
  cardContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginTop: 40,
    marginBottom: 20,
    gap: 20,
  },
  cardBox: {
    padding: "20px",
    borderRadius: 10,
    flexDirection: "column",
    backgroundColor: "white",
    flex: 1,
  },
  chartIcon: {
    width: 12,
    height: "auto",
  },
  cardBoxQuestionText: {
    fontSize: 10,
    color: "#111111",
    fontWeight: 600,
    marginLeft: 6,
  },
  cardBoxAnswerText: {
    fontSize: 12,
    fontWeight: 600,
    color: PRIMARY_NORMAL,
  },
  contentContainer: {
    width: "100%",
    backgroundColor: "white",
    padding: 20,
  },
  questionContainer: {
    marginVertical: 8,
  },
  questionHeading: {
    fontWeight: 600,
    color: BLACK,
    fontSize: 12,
    marginBottom: 8,
  },
  questionCallout: {
    backgroundColor: "#F7F7F7",
    padding: "10px 16px",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    borderRadius: 5,
    gap: 2,
  },
  questionCalloutText: {
    fontSize: 8,
  },
});

const Container = styled.div``;
const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: white;
  width: 100%;
  height: 500px;
`;

// Create Document Component
const ReactPdf = ({ name, id }) => {
  const canvasRef = React.createRef();
  const [isReady, setIsReady] = useState(false);
  const [strengthUrl, setStrengthUrl] = useState("");
  const [url, setUrl] = useState("");
  const [reportData, setReportData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const leaderName = location.state.leaderName;
  const courseName = location.state.courseName;
  const questions = location.state.questions;
  const results = location.state.results;
  const dates = location.state.dates;
  const high = location.state.high;
  const low = location.state.low;
  const [images, setImages] = useState([]);
  const [barImage, setBarImage] = useState();

  const chartRefs = useRef([]);
  const barRef = useRef();
  const pdfRef = useRef();

  //   useEffect(() => {
  //     setTimeout(() => {
  //       setIsReady(true);
  //     }, 3000);
  //   }, []);

  useEffect(() => {
    // console.log("barRef");
    // setBarImage(barRef.current.toBase64Image());
    // pdfRef.current.src = barRef.current.toBase64Image();
    const convertToImage = async () => {
      const barCanvas = await html2canvas(barRef.current, { scale: 2 });
      setBarImage(barCanvas.toDataURL("image/jpeg", 1));
      setIsReady(true);
    };
    setTimeout(() => {
      convertToImage();
    }, 1000);
  }, [barRef]);

  useEffect(() => {
    const convertToImage = async () => {
      const imageSrc = [];
      //   console.log(chartRefs.current[0]);
      for (let i = 0; i < chartRefs.current.length; i++) {
        await html2canvas(chartRefs.current[i], { scale: 2 }).then((canvas) => {
          const image = canvas.toDataURL("image/jpeg", 1);
          imageSrc.push(image);
        });
      }
      //   for await (const chart of chartRefs.current) {
      // console.log(chart);
      // const canvas = await html2canvas(chart, { scale: 2 });

      // // const canvas = chart.canvas;
      // const image = canvas.toDataURL("image/jpeg", 1);

      // imageSrc.push(chart.toBase64Image());
      // chart.style = "display: none";
      //   }
      setIsReady(true);
      setImages(imageSrc);
    };
    setTimeout(() => {
      convertToImage();
    }, 1000);
  }, [chartRefs]);

  return (
    <Container style={{ zIndex: 0, position: "relative" }}>
      {results.map((item, index) => (
        <div
          key={index}
          ref={(el) => (chartRefs.current[index] = el)}
          style={{
            position: "absolute",
            zIndex: -2,
            display: "block",
            width: "900px",
          }}
        >
          <LeaderChart
            // ref={(el) => (chartRefs.current[index] = el)}
            scale={7}
            results={results[index]}
            dates={dates.map((d, i) => [`${i + 1}차 체크업`, d])}
          />
        </div>
      ))}
      <div
        ref={barRef}
        style={{ position: "absolute", zIndex: -2, width: "900px" }}
      >
        <BarChart
          // ref={barRef}
          labels={questions}
          results={results}
          //   width={400}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          background: "white",
          zIndex: -1,
          width: "100%",
          height: "100vh",
        }}
      />
      {!isReady && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "2rem",
          }}
        >
          <p>PDF 변환중입니다</p>
        </div>
      )}
      {isReady && (
        <PDFViewer style={styles.viewer} file>
          <Document title="마음 리포트" subject="마음 리포트">
            <Page size="A4" style={styles.page} wrap={true}>
              <View style={styles.wrapper}>
                {/* <Image
                  style={styles.navbar}
                  src={`${process.env.PUBLIC_URL}/assets/smart-training-report-navbar.png`}
                ></Image> */}

                <Text
                  style={{
                    ...styles.subHeading,
                  }}
                >
                  마음 리포트
                </Text>
                <Text
                  style={{
                    ...styles.heading1,
                  }}
                >
                  {leaderName} 님, 안녕하세요 :)
                </Text>
                <Text style={styles.heading2}>
                  지난 한 달 간의 ‘{courseName}’ 교육생 분들의 마음 변화를
                  살펴보세요.
                </Text>
                <View style={styles.infoBox}>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image
                      src={`${process.env.PUBLIC_URL}/assets/icons/calendar_icon.png`}
                      alt="calendar_icon"
                      style={styles.calendarImage}
                    />
                    <Text style={styles.infoText}>1차 체크업: 워크숍 전</Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image
                      src={`${process.env.PUBLIC_URL}/assets/icons/calendar_icon.png`}
                      alt="calendar_icon"
                      style={styles.calendarImage}
                    />
                    <Text style={styles.infoText}>2차 체크업: 워크숍 직후</Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image
                      src={`${process.env.PUBLIC_URL}/assets/icons/calendar_icon.png`}
                      alt="calendar_icon"
                      style={styles.calendarImage}
                    />
                    <Text style={styles.infoText}>
                      3차 체크업 : 워크루틴 4주 진행 후, 현재
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <View style={styles.cardContainer}>
                    <View style={styles.cardBox}>
                      <View
                        style={{
                          flexDirection: "row",
                          marginBottom: 8,
                          alignItems: "center",
                        }}
                      >
                        <Image
                          style={styles.chartIcon}
                          src={`${process.env.PUBLIC_URL}/assets/icons/chart_up_icon.png`}
                        />
                        <Text style={styles.cardBoxQuestionText}>
                          가장 높은 점수를 기록한 문항
                        </Text>
                      </View>
                      <Text style={styles.cardBoxAnswerText}>{high}</Text>
                    </View>
                    <View style={styles.cardBox}>
                      <View
                        style={{
                          flexDirection: "row",
                          marginBottom: 8,
                          alignItems: "center",
                        }}
                      >
                        <Image
                          style={styles.chartIcon}
                          src={`${process.env.PUBLIC_URL}/assets/icons/chart_down_icon.png`}
                        />
                        <Text style={styles.cardBoxQuestionText}>
                          가장 낮은 점수를 기록한 문항
                        </Text>
                      </View>
                      <Text style={styles.cardBoxAnswerText}>{low}</Text>
                    </View>
                  </View>
                  <View style={styles.contentContainer}>
                    <View style={styles.questionContainer}>
                      <Text style={styles.questionHeading}>전체 개요</Text>
                      <Image src={barImage} />
                      <TablePdf results={results} />
                    </View>
                  </View>
                </View>
              </View>
            </Page>
            <Page Page size="A4" style={styles.chartPage} wrap={false}>
              <View style={styles.wrapper}>
                <View style={styles.content}>
                  <View style={styles.contentContainer}>
                    {questions.slice(0, 3).map((q, index) => (
                      <View
                        key={index}
                        style={{
                          ...styles.questionContainer,
                          marginBottom: 10,
                          marginTop: 10,
                        }}
                      >
                        <Text style={styles.questionHeading}>
                          Q{index + 1}. {q}
                        </Text>
                        <View style={styles.questionCallout}>
                          <Text style={styles.questionCalloutText}>
                            전체 평균 점수를 기준으로, 워크숍 진행 전에 비해
                            진행 후{" "}
                            <Text style={{ fontWeight: 700 }}>
                              {results[index][1].score -
                                results[index][0].score !==
                                0 &&
                                `${(
                                  results[index][1].score -
                                  results[index][0].score
                                ).toFixed(1)}점`}{" "}
                            </Text>
                            <Text style={{ fontWeight: 700 }}>
                              {results[index][1].score -
                                results[index][0].score >
                              0
                                ? `상승`
                                : (
                                    results[index][1].score -
                                    results[index][0].score
                                  ).toFixed(1) < 0
                                ? `하락`
                                : `동일`}
                            </Text>
                            하였으며, 현업에 돌아가 워크루틴 4주 실천 후의
                            변화는{" "}
                            <Text style={{ fontWeight: 700 }}>
                              {results[index][2].score -
                                results[index][0].score >
                              0
                                ? `+${(
                                    results[index][2].score -
                                    results[index][0].score
                                  ).toFixed(1)}`
                                : (
                                    results[index][2].score -
                                    results[index][0].score
                                  ).toFixed(1)}
                              점
                            </Text>{" "}
                            입니다.
                          </Text>
                        </View>
                        <Image src={images[index]} />
                      </View>
                    ))}
                  </View>
                </View>
              </View>
            </Page>
            <Page Page size="A4" style={styles.chartPage} wrap={true}>
              <View style={styles.wrapper}>
                <View style={styles.content}>
                  <View style={styles.contentContainer}>
                    {questions.slice(3).map((q, index) => (
                      <View
                        key={index}
                        style={{
                          ...styles.questionContainer,
                          marginBottom: 10,
                          marginTop: 10,
                        }}
                      >
                        <Text style={styles.questionHeading}>
                          Q{index + 4}. {q}
                        </Text>
                        <View style={styles.questionCallout}>
                          <Text style={styles.questionCalloutText}>
                            전체 평균 점수를 기준으로, 워크숍 진행 전에 비해
                            진행 후{" "}
                            <Text style={{ fontWeight: 700 }}>
                              {results[index + 3][1].score -
                                results[index + 3][0].score !==
                                0 &&
                                `${(
                                  results[index + 3][1].score -
                                  results[index + 3][0].score
                                ).toFixed(1)}점`}{" "}
                            </Text>
                            <Text style={{ fontWeight: 700 }}>
                              {results[index + 3][1].score -
                                results[index + 3][0].score >
                              0
                                ? `상승`
                                : results[index + 3][1].score -
                                    results[index + 3][0].score <
                                  0
                                ? `하락`
                                : `동일`}
                            </Text>
                            하였으며, 현업에 돌아가 워크루틴 4주 실천 후의
                            변화는{" "}
                            <Text style={{ fontWeight: 700 }}>
                              {results[index + 3][2].score -
                                results[index + 3][0].score >
                              0
                                ? `+${(
                                    results[index + 3][2].score -
                                    results[index + 3][0].score
                                  ).toFixed(1)}`
                                : (
                                    results[index + 3][2].score -
                                    results[index + 3][0].score
                                  ).toFixed(1)}
                              점
                            </Text>{" "}
                            입니다.
                          </Text>
                        </View>
                        <Image src={images[index + 3]} />
                      </View>
                    ))}
                  </View>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </Container>
  );
};

export default ReactPdf;
