import {
  USER_ADMIN,
  USER_LEADER,
  USER_MANAGER,
  USER_NORMAL,
  USER_PM,
  USER_SUPER_ADMIN,
} from "../Constants";

export const SIDEBAR_ITEMS_USER = [
  {
    src: "sidebar_home_default.svg",
    srcActive: "sidebar_home_active.svg",
    link: "/dashboard",
    title: "대시보드",
    role: [
      USER_SUPER_ADMIN,
      USER_LEADER,
      USER_MANAGER,
      USER_ADMIN,
      USER_NORMAL,
      USER_PM,
    ],
  },
  {
    src: "sidebar_checkup_default.svg",
    srcActive: "sidebar_checkup_active.svg",
    link: "/dashboard/checkup",
    title: "마음 체크업",
    children: [
      {
        link: "/dashboard/checkup/report",
        title: "마음 체크업 결과",
      },
      {
        link: "/dashboard/checkup/share",
        title: "마음 나누기 답변",
      },
    ],
    role: [
      USER_SUPER_ADMIN,
      USER_LEADER,
      USER_MANAGER,
      USER_ADMIN,
      USER_NORMAL,
      USER_PM,
    ],
  },
  {
    src: "sidebar_training_default.svg",
    srcActive: "sidebar_training_active.svg",
    link: "/dashboard/training",
    title: "마음 트레이닝",
    role: [
      USER_SUPER_ADMIN,
      USER_LEADER,
      USER_MANAGER,
      USER_ADMIN,
      USER_NORMAL,
      USER_PM,
    ],
  },
  {
    src: "sidebar_guide_default.svg",
    srcActive: "sidebar_guide_active.svg",
    link: "/dashboard/guide",
    title: "맘핏 둘러보기",
    role: [
      USER_SUPER_ADMIN,
      USER_LEADER,
      USER_MANAGER,
      USER_ADMIN,
      USER_NORMAL,
      USER_PM,
    ],
  },
  {
    src: "sidebar_mypage_default.svg",
    srcActive: "sidebar_mypage_active.svg",
    link: "/dashboard/mypage",
    title: "마이 페이지",
    role: [
      USER_SUPER_ADMIN,
      USER_LEADER,
      USER_MANAGER,
      USER_ADMIN,
      USER_NORMAL,
      USER_PM,
    ],
  },
  //   {
  //     src: "sidebar_logout_default.svg",
  //     srcActive: "sidebar_logout_active.svg",
  //     title: "로그아웃",
  //   },
];

export const SIDEBAR_ITEMS_PM = [
  {
    src: "sidebar_project_default.svg",
    srcActive: "sidebar_project_active.svg",
    link: "/manager/dashboard/projects",
    title: "프로젝트",
  },
];
export const SIDEBAR_ITEMS_MANAGER = [
  {
    src: "sidebar_home_default.svg",
    srcActive: "sidebar_home_active.svg",
    link: "/manager/dashboard",
    title: "홈 피드",
    role: [USER_SUPER_ADMIN, USER_ADMIN],
  },
  // {
  //   src: "sidebar_project_default.svg",
  //   srcActive: "sidebar_project_active.svg",
  //   link: "/manager/dashboard/projects",
  //   title: "프로젝트",
  // },
  {
    src: "sidebar_users_default.svg",
    srcActive: "sidebar_users_active.svg",
    link: "/manager/dashboard/users",
    title: "구성원 관리",
    children: [
      {
        link: "/manager/dashboard/users/groups",
        title: "그룹 관리",
      },
      {
        link: "/manager/dashboard/users",
        title: "구성원 관리",
      },
    ],
    role: [USER_SUPER_ADMIN, USER_ADMIN, USER_PM, USER_MANAGER],
  },
  {
    src: "sidebar_checkup_default.svg",
    srcActive: "sidebar_checkup_active.svg",
    link: "/manager/dashboard/checkup",
    title: "참여 현황",
    children: [
      {
        link: "/manager/dashboard/mission/result",
        title: "사전 미션 질문",
      },
      {
        link: "/manager/dashboard/checkup/result",
        title: "참여 현황",
      },
      {
        link: "/manager/dashboard/checkup/report",
        title: "그룹 마음 리포트",
      },
      {
        link: "/manager/dashboard/checkup/share",
        title: "마음 나누기 답변",
      },
      {
        link: "/manager/dashboard/checkup/users",
        title: "구성원별 참여 현황",
      },
    ],
    role: [USER_SUPER_ADMIN, USER_ADMIN, USER_PM, USER_MANAGER],
  },
  // {
  //   src: "sidebar_guide_default.svg",
  //   srcActive: "sidebar_guide_active.svg",
  //   link: "/dashboard/guide",
  //   title: "이용 관리",
  // },
  {
    src: "sidebar_withdraw_default.svg",
    srcActive: "sidebar_withdraw_active.svg",
    link: "/manager/dashboard/payment",
    title: "결제 관리",
    children: [
      {
        link: "/manager/dashboard/payment",
        title: "결제 관리",
      },
      {
        link: "/manager/dashboard/payment/history",
        title: "결제 이력",
      },
    ],
    role: [USER_SUPER_ADMIN, USER_ADMIN],
  },
  //   {
  //     src: "sidebar_logout_default.svg",
  //     srcActive: "sidebar_logout_active.svg",
  //     title: "로그아웃",
  //   },
];

export const SIDEBAR_ITEMS_LEADER = [
  {
    src: "sidebar_home_default.svg",
    srcActive: "sidebar_home_active.svg",
    link: "/leader/dashboard",
    title: "대시보드",
    role: [USER_SUPER_ADMIN, USER_LEADER, USER_MANAGER, USER_ADMIN],
  },
  // {
  //   src: "sidebar_users_default.svg",
  //   srcActive: "sidebar_users_active.svg",
  //   link: "/leader/dashboard/groups",
  //   title: "그룹 관리",
  // },
  {
    src: "sidebar_users_default.svg",
    srcActive: "sidebar_users_active.svg",
    link: "/leader/dashboard/users",
    title: "구성원 관리",
    role: [USER_SUPER_ADMIN, USER_LEADER, USER_MANAGER, USER_ADMIN],
  },
  {
    src: "sidebar_checkup_default.svg",
    srcActive: "sidebar_checkup_active.svg",
    link: "/leader/dashboard/checkup",
    title: "마음 체크업",
    children: [
      {
        link: "/leader/dashboard/checkup/report",
        title: "그룹 마음 리포트",
      },
      {
        link: "/leader/dashboard/checkup/share",
        title: "마음 나누기 답변",
      },
      // {
      //   link: "/leader/dashboard/checkup/share/questions",
      //   title: "마음 나누기 질문",
      // },
    ],
    role: [USER_SUPER_ADMIN, USER_LEADER, USER_MANAGER, USER_ADMIN],
  },
  //   {
  //     src: "sidebar_training_default.svg",
  //     srcActive: "sidebar_training_active.svg",
  //     link: "/leader/dashboard/training",
  //     title: "마음 트레이닝",
  //   },
  {
    src: "sidebar_withdraw_default.svg",
    srcActive: "sidebar_withdraw_active.svg",
    link: "/leader/dashboard/withdraw",
    title: "리워드 환급 현황",
    role: [USER_SUPER_ADMIN, USER_ADMIN],
  },
  //   {
  //     src: "sidebar_logout_default.svg",
  //     srcActive: "sidebar_logout_active.svg",
  //     title: "로그아웃",
  //   },
];
