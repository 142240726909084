import styled from "styled-components";

const Image = styled.img``;

const StampImage = ({ style }) => {
  return (
    <Image
      style={style}
      src={`${process.env.PUBLIC_URL}/assets/smile-stamp.png`}
    />
  );
};

export default StampImage;
