import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios, { axiosPrivate } from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const SubscribePaymentUpdate = () => {
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  console.log(auth.user);
  const [params] = useSearchParams();
  console.log(params.get("good_expr"));
  console.log(params.get("ordr_idxx"));
  console.log(params.get("enc_info"));
  console.log(params.get("tran_cd"));
  console.log(params.get("card_mask_no"));

  const customerKey = params.get("customerKey");
  const authKey = params.get("authKey");

  // useEffect(() => {
  //   (async () => {
  //     const response = await axiosPrivate.post(`/payments/subscribe/success`, {
  //       customerKey,
  //       authKey,
  //     });
  //     console.log(response.data);
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      const response = await axiosPrivate.post(`/payments/kcp/update/card`, {
        userId: auth.user.id,
        companyId: auth.user.companyId._id,
        site_cd: "BA001",
        site_name: "TEST+SITE",
        good_expr: params.get("good_expr"),
        pay_method: "AUTH:CARD",
        card_cert_type: "BATCH",
        batch_soc: "Y",
        module_type: "01",
        res_cd: "0000",
        res_msg: "성공",
        enc_info: params.get("enc_info"),
        enc_data: params.get("enc_data"),
        tran_cd: params.get("tran_cd"),
      });

      if (response.data.ok) {
        console.log(response.data.data);
        console.log(response.data.data.company);
        setAuth({
          accessToken: auth.accessToken,
          user: {
            ...auth.user,
            companyId: response.data.data.company,
          },
        });
        console.log("auth: ", {
          accessToken: auth.accessToken,
          user: {
            ...auth.user,
            companyId: response.data.data.company,
          },
        });
        navigate("/manager/dashboard");
      } else {
        alert("결제 에러가 발생했습니다");
      }
    })();
  });
};

export default SubscribePaymentUpdate;
