import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import "react-slideshow-image/dist/styles.css";
import { useEffect, useRef, useState } from "react";
import axios from "../api/axios";
import FlexRow from "../components/FlexRow";
import FlexColumn from "../components/FlexColumn";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import "react-slideshow-image/dist/styles.css";
import { MOBILE } from "../Constants";

import { isAuthEmpty } from "../utils/isAuthEmpty";
import { Helmet } from "react-helmet";
import validatePhone from "../utils/validatePhone";
import ModalBackground from "../components/ModalBackground";
import Modal from "./dashboard/components/Modal";
import CardLayout from "./dashboard/CardLayout";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 5.75rem);
  background: ${({ theme }) => theme.grayScaleColor.white};
  // border: 1px solid black;
`;

const Heading1 = styled.h2`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 2rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Heading2 = styled.h2`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const Heading3 = styled.h3`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  margin-top: 2.56rem;
  margin-bottom: 1.25rem;
`;

const Heading4 = styled.h4`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const Section = styled.section`
  padding: 1.75rem 1.25rem;
`;

const InputLabel = styled.p`
  color: #000000;
  font-size: 0.9375rem;
  line-height: 140%;
`;

const Description = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem 0.25rem;
  background: ${({ theme, type }) =>
    type === "cancel" ? theme.grayScaleColor.gray10 : theme.primary.normal};
  border: none;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, type }) =>
    type === "cancel"
      ? theme.grayScaleColor.gray70
      : theme.grayScaleColor.white};
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
`;

const ModalContainer = styled.div`
  border-radius: 1.875rem;
  box-shadow: 0px 10px 80px 0px rgba(0, 0, 0, 0.07);
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 2.19rem 1.88rem;
  width: 18.75rem;
  max-width: 18.75rem;
  width: 100%;
  text-align: center;
  & h1 {
    color: #000;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 1.8rem */
    letter-spacing: -0.03125rem;
  }

  & img {
    width: 4.25rem;
    margin: 0 auto;
  }

  & p {
    color: ${({ theme }) => theme.grayScaleColor.gray70};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.3125rem */
    letter-spacing: -0.03125rem;
  }
`;

function MypageWithdrawConfirm() {
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const { state } = useLocation();
  const bank = state.bank || 0;
  const accountNumber = state.accountNumber || 0;
  const accountOwner = state.accountOwner || "";
  const amount = state.amount || 0;

  const handleEditClick = () => {
    navigate("/mypage/reward/withdraw", {
      state: {
        bank,
        accountNumber,
        accountOwner,
        amount,
      },
    });
  };
  const handleSubmitClick = async () => {
    const response = await axiosPrivate.post(`/cash/withdraw`, {
      bankName: bank,
      accountNumber,
      accountOwner,
      amount,
    });
    if (!response.data.ok) {
      alert("오류가 발생했습니다.");
      return;
    }
    setAuth({
      accessToken: auth.accessToken,
      user: {
        ...auth.user,
        point: auth.user.point - response.data.data.amount,
      },
    });
    setShowModal(true);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      // console.log(response.data.surveys);
      setIsLoading(false);
    };

    if (isAuthEmpty(auth)) {
      return navigate("/login");
    }
    fetch();
  }, []);
  // console.log(surveyYearMonth, monthPeriod);
  if (isLoading) {
    return <div />;
  }

  return (
    <Container>
      <Helmet>
        <meta name="theme-color" content="#fff" />
      </Helmet>
      <Section>
        <Heading1 style={{ marginBottom: "0.5rem" }}>현금 이체</Heading1>
        <Heading4>보유한 캐시를 계좌로 환급 받아요.</Heading4>
        <div
          style={{
            width: "100%",
            height: "1px",
            background: "#D9D9D9",
            marginTop: "2.25rem",
            marginBottom: "1.69rem",
          }}
        />
        <FlexColumn style={{ marginTop: "2rem" }}>
          <FlexColumn style={{ gap: "1.25rem" }}>
            <FlexRow
              style={{
                // justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <InputLabel style={{ color: "#777", flex: 0.5 }}>
                입금은행
              </InputLabel>
              <Heading2 style={{ flex: 1 }}>{bank}</Heading2>
            </FlexRow>
            <FlexRow
              style={{
                alignItems: "center",
              }}
            >
              <InputLabel style={{ color: "#777", flex: 0.5 }}>
                계좌번호
              </InputLabel>
              <Heading2 style={{ flex: 1 }}>{accountNumber}</Heading2>
            </FlexRow>
            <FlexRow
              style={{
                alignItems: "center",
              }}
            >
              <InputLabel style={{ color: "#777", flex: 0.5 }}>
                예금주 실명
              </InputLabel>
              <Heading2 style={{ flex: 1 }}>{accountOwner}</Heading2>
            </FlexRow>
            <FlexRow
              style={{
                alignItems: "center",
              }}
            >
              <InputLabel style={{ color: "#777", flex: 0.5 }}>
                환급 신청 캐시
              </InputLabel>
              <Heading2
                style={{ display: "flex", alignItems: "center", flex: 1 }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                  style={{
                    width: "0.875rem",
                    height: "0.875rem",
                    marginRight: "0.5rem",
                  }}
                  alt={"cash icon"}
                />
                {Number(amount).toLocaleString()}원
              </Heading2>
            </FlexRow>
          </FlexColumn>
          <div
            style={{
              width: "100%",
              height: "1px",
              background: "#D9D9D9",
              marginTop: "1.69rem",
              marginBottom: "2.25rem",
            }}
          />
          <Description style={{ textAlign: "center" }}>
            해당 정보로 환급 신청이 진행될 예정입니다. <br />
            입력하신 정보가 맞는지 다시 한 번 확인하시고,
            <br />
            아래 버튼을 눌러 신청을 완료해 주세요.
          </Description>
          <FlexRow style={{ gap: "0.63rem", marginTop: "3.2rem" }}>
            <Button type={"cancel"} onClick={handleEditClick}>
              정보 수정하기
            </Button>
            <Button onClick={handleSubmitClick}>신청하기</Button>
          </FlexRow>
        </FlexColumn>
      </Section>
      {showModal && (
        <ModalBackground style={{ zIndex: 50 }}>
          <ModalContainer>
            <FlexColumn style={{ gap: "1.25rem" }}>
              <h1>환급 신청 완료</h1>
              <img
                src={`${process.env.PUBLIC_URL}/assets/mypage/wallet.png`}
                alt="wallet"
              />
              <p>
                입력하신 계좌로
                <br />
                월말 일괄 이체될 예정입니다.
              </p>

              <Button
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  padding: "0.75rem 1.75rem",
                  letterSpacing: "-0.035rem",
                }}
                onClick={() => navigate("/mypage")}
              >
                마이페이지로 이동
              </Button>
            </FlexColumn>
          </ModalContainer>
        </ModalBackground>
      )}
    </Container>
  );
}

export default MypageWithdrawConfirm;
