import axios, { axiosPrivate } from "../../../api/axios";

const getSmilePhotos = async ({ auth, cid, page, sort, isPublic }) => {
  // 회원인 경우
  if (auth?.user) {
    console.log("auth yes");
    const response = await axiosPrivate.get(`/smile/photos`, {
      params: {
        cid: auth.user.companyId._id,
        sort: sort,
        page: page,
        isPublic,
      },
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
      },
    });
    console.log(response.data);
    return response.data;
  }

  // 비회원인 경우
  try {
    const response = await axios.get(`/smile/guests/photos`, {
      params: { cid: cid, sort: sort, page: page, isPublic },
    });
    console.log(response.data);
    return response.data;
  } catch (err) {
    return null;
  }
};

export default getSmilePhotos;
