import styled from "styled-components";

const Background = styled.div`
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; Enable scroll if needed */
  overflow-y: scroll;
  background-color: rgb(17, 17, 17); /* Fallback color */
  background-color: rgba(17, 17, 17, 0.6); /* Black w/ opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 0 20px;
`;

const Container = styled.div`
  width: 100%;
  height: ${({ height }) => `${height}` || "100%"};
  border-radius: 0.75rem;
  padding: 42px 30px 32px 30px;
  background: white;
  position: relative;
`;

const CloseBtn = styled.img`
  position: absolute;
  width: 18px;
  height: 18px;
  top: 2.56rem;
  right: 2.56rem;
  cursor: pointer;
`;
const Modal = ({ children, style }) => {
  return (
    <Background style={style} onClick={(e) => e.stopPropagation()}>
      {children}
    </Background>
  );
};

export default Modal;
