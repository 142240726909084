import React, { useState, useEffect } from "react";

function useScrollCenter() {
  const [scrollCenter, setScrollCenter] = useState(
    window.scrollY + window.innerHeight / 2
  );

  useEffect(() => {
    function handleScroll() {
      setScrollCenter(window.scrollY + window.innerHeight / 2);
    }

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll); // Resize event to handle window height changes

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  return scrollCenter;
}

export default useScrollCenter;
