import { useEffect, useState } from "react";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import { MONTH_PRICE, PAYMENT_TYPE, TAX } from "../../../constants/payments";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getDate } from "../../../utils/date";
import CloseXButton from "../components/CloseXButton";
import Divider from "../components/Divider";
import ModalHeading2 from "../components/ModalHeading2";

const DescriptionText = styled.p`
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 170%; /* 1.59375rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 1.25rem;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  padding: 1rem 1.87rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 170%; /* 1.59375rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 1.81rem;
`;

const TipText = styled.p`
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 180%; /* 1.4625rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  margin-top: 1.19rem;
`;

const ModalButton = styled.button`
  border-radius: 0.4375rem;
  padding: 0.78rem 0;
  flex: 1;
  // max-width: 11rem;
  border: none;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem; /* 153.333% */
  letter-spacing: -0.0375rem;
  cursor: pointer;
`;

const Bold = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;
const CancelButton = styled(ModalButton)`
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const OkButton = styled(ModalButton)`
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
`;

const ChangeToMonthPlanModal = ({ numUsers, planType, closeHandler }) => {
  const axiosPrivate = useAxiosPrivate();

  const [extraPayAmount, setExtraPayAmount] = useState(0);
  const [periodMonth, setPeriodMonth] = useState(0);
  const [subscribeEndDate, setSubscribeEndDate] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const onChangePlanClick = async () => {
    const res = await axiosPrivate.patch("/payments/subscribe", {
      nextPlanType: "month",
      extraAmount: extraPayAmount,
    });

    window.location.reload();
  };

  useEffect(() => {
    (async () => {
      const subscribeRes = await axiosPrivate.get("/payments/subscribe");
      const orderRes = await axiosPrivate.get("/payments/history");

      console.log(subscribeRes.data.data);
      console.log(orderRes.data.data);

      if (subscribeRes.data.data.planType === PAYMENT_TYPE.YEAR) {
        let refund = 0;
        // 연 구독 시, N개월 동안 결제 했는지 확인
        // 최신 order정보는 연 구독일거라서,
        // 가장 최근의 월 구독 order를 찾으면 됨
        let count = 0;
        let index = 0;
        for (let i = 0; i < orderRes.data.data.length; i++) {
          if (orderRes.data.data[i].planType === PAYMENT_TYPE.MONTH) {
            index = i;
            break;
          }
          count++;
        }

        for (let i = 0; i < index; i++) {
          refund =
            refund +
            orderRes.data.data[i].users * MONTH_PRICE * (1 + TAX / 100) -
            orderRes.data.data[i].amount;
          console.log(refund);
        }
        setPeriodMonth(count);
        setSubscribeEndDate(subscribeRes.data.data.yearPlanEndDate);
        setExtraPayAmount(parseInt(refund));
        // setCash(cashRes.data.data.remainCash);
      }

      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <div />;
  }

  return (
    <>
      <FlexRow style={{ justifyContent: "center", alignItems: "center" }}>
        <ModalHeading2 style={{ textAlign: "center" }}>
          연간 구독을 월간 구독으로
          <br />
          변경하시겠어요?
        </ModalHeading2>
      </FlexRow>
      <DescriptionText>
        현재 이용 중인 플랜은 연간 구독으로, 25%의 할인이 적용되었습니다.
        <br />
        연간 구독 종료일({getDate(subscribeEndDate, "YYYY년 M월 D일")}) 이전에
        구독을 변경하실 경우,
        <br />
        아래 혜택이 사라지고 비용이 부과됩니다.
      </DescriptionText>
      <Box>
        <FlexRow style={{ justifyContent: "space-between" }}>
          <FlexRow style={{ gap: "0.5rem" }}>
            <span>{periodMonth}개월 간 할인 받은 금액</span>
            <Bold>{extraPayAmount.toLocaleString()}원</Bold>
          </FlexRow>
          <Bold style={{ color: "#D60606" }}>다음 결제일에 결제</Bold>
        </FlexRow>
      </Box>
      <TipText>
        ※ 연간 구독으로 할인 받은 금액은 등록하신 카드로 다음 결제일에 자동
        결제됩니다.
      </TipText>
      <FlexRow
        style={{
          marginTop: "2.44rem",
          justifyContent: "space-between",
          gap: "0.69rem",
        }}
      >
        <CancelButton onClick={onChangePlanClick}>변경하기</CancelButton>
        <OkButton onClick={closeHandler}>혜택 유지하기</OkButton>
      </FlexRow>
    </>
  );
};

export default ChangeToMonthPlanModal;
