import styled from "styled-components";

const Container = styled.a`
  width: 6.25rem;
  height: 2.19rem;
  padding: 0 0.88rem;
  border-radius: 0.44rem;
  border: none;
  background-color: ${({ theme, checked }) =>
    checked ? theme.primary.normal : theme.grayScaleColor.gray10};
  font-size: 1rem;
  font-weight: 300;
  color: ${({ theme, checked }) =>
    checked ? theme.grayScaleColor.white : theme.grayScaleColor.black};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RadioButton = ({ children, checked, handleClick, style }) => {
  return (
    <Container onClick={handleClick} checked={checked} style={style}>
      {children}
    </Container>
  );
};

export default RadioButton;
