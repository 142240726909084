import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import CardLayout from "../CardLayout";

import CloseXButton from "../components/CloseXButton";
import Divider from "../components/Divider";
import Heading1 from "../components/Heading1";
import Heading2 from "../components/Heading2";
import Modal from "../components/Modal";
import ModalHeading2 from "../components/ModalHeading2";
import Text1 from "../components/Text1";
import TextLink from "../components/TextLink";
import AddManagerModal from "../modals/AddManagerModal";
import DeleteManagerModal from "../modals/DeleteManagerModal";
import EditManagerModal from "../modals/EditManagerModal";
import DotListItem from "../components/DotListItem";
import NumberText from "../components/NumberText";
import GroupManageModal from "../modals/GroupManageModal";
import Checkbox from "../../../components/Checkbox";
import StatusText from "../components/StatusText";
import AddUserModal from "../modals/AddUserModal";
import AddUsersModal from "../modals/AddUsersModal";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import convertStatus from "../../../utils/convertStatus";
import convertRole from "../../../utils/convertRole";
import {
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_INVITE,
  STATUS_SIGNUP,
  USER_LEADER,
  USER_MANAGER,
} from "../../../Constants";
import DeleteUserModal from "../modals/DeleteUserModal";
import IndividualInviteModal from "../modals/IndividualInviteModal";
import EditUserModal from "../modals/EditUserModal";
import { useParams, useSearchParams } from "react-router-dom";
import SortButton from "../components/SortButtons";

const Container = styled.div`
  padding: 2.75rem 2.75rem;
  padding-left: calc(2.75rem + var(--sidebar-width));
  width: 100%;
  position: relative;
`;

const TableTitle = styled.h1`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: -0.03125rem;
`;
const Title = styled.p`
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 150%; /* 1.40625rem */
  letter-spacing: -0.03125rem;
`;

const SmallText = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
`;

const KeyText = styled.p`
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  flex: 1;
`;
const ValueText = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  flex: 4;
`;

const SubText = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
`;

const Bold = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Input = styled.input`
  border-radius: 0.3125rem;
  border: 1px solid black;
  padding: 0.38rem 0.5rem;
  outline: none;
`;

const SmallButton = styled.button`
  border-radius: 0.3125rem;
  background: ${({ theme }) => theme.primary.normal};
  border: 1px solid ${({ theme }) => theme.primary.normal};
  padding: 0.25rem 0.75rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
`;

const Ul = styled.ul`
  flex: 4;
`;

const HR = styled.div`
  display: flex;
  align-items: center;
  padding: 0.94rem 2rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
`;
const TR = styled.div`
  display: flex;
  align-items: center;
  padding: 0.94rem 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
`;

const HeaderItem = styled.p`
  flex: 1;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Item = styled.p`
  flex: 1;
  font-size: 0.9375rem;
  line-height: 150%; /* 1.40625rem */
  letter-spacing: -0.03125rem;
`;

const Badge = styled.span`
  padding: 0.25rem 0.5rem;
  border-radius: 0.3125rem;
  background: ${({ theme, role }) =>
    role === USER_MANAGER
      ? "#F0FAFF"
      : role === USER_LEADER
      ? theme.primary.light
      : "#F7F7F7"};
  color: ${({ theme, role }) =>
    role === USER_MANAGER
      ? theme.colors.accent
      : role === USER_LEADER
      ? theme.primary.normal
      : theme.grayScaleColor.gray70};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
`;

const Button = styled.button`
  border: none;
  border-radius: 0.3125rem;
  display: flex;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  cursor: pointer;
`;

const ButtonIcon = styled.img`
  margin-right: 0.25rem;
`;

const PrimaryButton = styled(Button)`
  background: ${({ theme }) => theme.primary.normal};
  color: white;
`;

const NormalButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  &:hover {
    color: ${({ theme }) => theme.grayScaleColor.black};
    border: 1px solid ${({ theme }) => theme.grayScaleColor.black};
  }
  transition: all 0.1s;
`;

const EditButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  color: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const DeleteButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.error};
`;

const AddButton = ({ style, onClick }) => (
  <svg
    style={style}
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <rect width="46" height="46" rx="10" fill="#F7F7F7" />
    <path
      d="M22.7997 30.7431V16H23.9185V30.7431H22.7997ZM16 23.9309V22.8122H30.7307V23.9309H16Z"
      fill="#BBBBBB"
    />
  </svg>
);

const ManagerUser = () => {
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [params] = useSearchParams();
  const companyId = params.get("id") || auth.user.companyId._id;

  const [showGroupManageModal, setShowGroupManageModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [isEditDomainClicked, setIsEditDomainClicked] = useState(false);
  const [company, setCompany] = useState();
  const [departments, setDepartments] = useState([]);
  const [departmentTabIndex, setDepartmentTabIndex] = useState(0);
  const [users, setUsers] = useState([]);
  const [isActiveList, setIsActiveList] = useState(true);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clickedUser, setClickedUser] = useState();
  const [deleteType, setDeleteType] = useState("");
  const [sortOrder, setSortOrder] = useState({
    name: {
      order: "asc",
    },
    status: {
      order: "asc",
    },
    role: {
      order: "asc",
    },
    department: {
      order: "asc",
    },
  });
  let managerAboveUsers, usingUsers, activeUsers, inactiveUsers;

  const onSortClick = (e, column) => {
    const sortArr = [...filteredUsers];

    if (sortOrder[column].order === "asc") {
      sortArr.sort((a, b) => {
        if (a[column] < b[column]) {
          return -1;
        }
        if (a[column] > b[column]) {
          return 1;
        }
        return 0;
      });

      setFilteredUsers(sortArr);
      setSortOrder((prev) => ({
        ...prev,
        [column]: {
          order: "desc",
        },
      }));
    } else {
      sortArr.sort((a, b) => {
        if (a[column] < b[column]) {
          return 1;
        }
        if (a[column] > b[column]) {
          return -1;
        }
        return 0;
      });

      setFilteredUsers(sortArr);
      setSortOrder((prev) => ({
        ...prev,
        [column]: {
          order: "asc",
        },
      }));
    }
  };

  const onCheckAllClick = (users) => {
    if (checkedUsers.length === users.length) {
      setCheckedUsers([]);
    } else {
      setCheckedUsers(users);
    }
  };

  const onCheckboxClick = (user) => {
    if (checkedUsers.some((item) => item.email === user.email)) {
      const index = checkedUsers.findIndex((val) => val.email === user.email);

      setCheckedUsers((prev) => {
        const temp = [...prev];
        temp.splice(index, 1);

        return temp;
      });
    } else {
      setCheckedUsers((prev) => {
        return [...prev, user];
      });
    }
  };

  const onInactiveClick = async (type) => {
    if (checkedUsers.length === 0) {
      return;
    }
    const res = await axiosPrivate.patch("/users/toggleInactive", {
      type,
      users: checkedUsers,
    });

    if (!res.data.ok) {
      return;
    }
    setCheckedUsers([]);
    setUsers(res.data.data.updatedUsers);

    if (departmentTabIndex === 0) {
      setFilteredUsers(
        res.data.data.updatedUsers.filter(
          (item) => item.status !== STATUS_INACTIVE
        )
      );
    } else if (departmentTabIndex === departmentTabs.length - 1) {
      setFilteredUsers(
        res.data.data.updatedUsers.filter(
          (item) => item.status === STATUS_INACTIVE
        )
      );
    } else {
      setFilteredUsers(
        res.data.data.updatedUsers.filter(
          (item) =>
            item.departmentId.name ===
              departmentTabs[departmentTabIndex].name &&
            item.status !== STATUS_INACTIVE
        )
      );
    }
    setDepartments(res.data.data.updatedDepartments);
    setAuth({
      accessToken: auth.accessToken,
      user: {
        ...auth.user,
        companyId: res.data.data.updatedCompany,
      },
    });
  };

  const handleEditUserClick = (user) => {
    setClickedUser(user);
    setShowEditUserModal(true);
  };

  const editModalCloseHandler = () => {
    setShowEditUserModal(false);
    setClickedUser();
  };

  const handleDeleteUserClick = (user, type) => {
    setDeleteType(type);
    setClickedUser(user);
    setShowDeleteModal(true);
  };

  const handleDeleteManyUsersClick = () => {
    setDeleteType("multiple");
    setShowDeleteModal(true);
  };

  const deleteModalCloseHandler = () => {
    setDeleteType("");
    setCheckedUsers([]);
    setClickedUser();
    setShowDeleteModal(false);
  };

  const onSendOneInviteClick = (e, user) => {
    setCheckedUsers([user]);
    setShowInviteModal(true);
  };
  const onSendInviteClick = async () => {
    setShowInviteModal(true);
  };

  const sendEmail = async () => {
    const res = await axiosPrivate.post("/users/invite", {
      users: checkedUsers,
    });
  };
  const onDepartmentTabClick = (e, index) => {
    const name = departmentTabs[index].name;
    setDepartmentTabIndex(index);
    if (name === "전체") {
      setFilteredUsers(users.filter((item) => item.status !== STATUS_INACTIVE));
      setIsActiveList(true);
    } else if (name === "비활성화") {
      setFilteredUsers(users.filter((item) => item.status === STATUS_INACTIVE));
      setIsActiveList(false);
    } else {
      setFilteredUsers(
        users.filter(
          (item) =>
            item.departmentId.name === name && item.status !== STATUS_INACTIVE
        )
      );
      setIsActiveList(true);
    }
  };

  useEffect(() => {
    (async () => {
      const companyResponse = await axiosPrivate.get(`/company/${companyId}`);
      const usersResponse = await axiosPrivate.get(
        `/users?company=${companyId}`
      );

      if (!companyResponse.data.ok) {
        return;
      }

      const usersData = usersResponse.data.users;
      setCompany(companyResponse.data.data.company);
      setUsers(usersData);
      setFilteredUsers(
        usersResponse.data.users.filter(
          (item) => item.status !== STATUS_INACTIVE
        )
      );
      setDepartments(companyResponse.data.data.departments);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (departmentTabIndex === 0) {
      setFilteredUsers(users.filter((item) => item.status !== STATUS_INACTIVE));
    } else if (departmentTabIndex === departmentTabs.length - 1) {
      setFilteredUsers(users.filter((item) => item.status === STATUS_INACTIVE));
    } else {
      setFilteredUsers(
        users.filter(
          (item) =>
            item.departmentId.name ===
              departmentTabs[departmentTabIndex].name &&
            item.status !== STATUS_INACTIVE
        )
      );
    }
  }, [users]);

  if (isLoading) return <div></div>;
  managerAboveUsers = users.filter((item) => item.role >= USER_MANAGER);
  usingUsers = users.filter((item) => item.status === STATUS_ACTIVE);
  activeUsers = users.filter((item) => item.status !== STATUS_INACTIVE);
  inactiveUsers = users.filter((item) => item.status === STATUS_INACTIVE);

  const departmentTabs = [
    {
      name: "전체",
      numUsers: activeUsers.length,
    },
    ...departments,
    {
      name: "비활성화",
      numUsers: inactiveUsers.length,
    },
  ];
  return (
    <Container>
      <Heading1 text="구성원 관리" />
      <Heading2
        text="사용권한을 설정하여 조직 내 구성원을 관리할 수 있습니다."
        style={{ marginBottom: "2.44rem" }}
      />
      <FlexRow style={{ gap: "1.88rem", height: "100%" }}>
        <CardLayout style={{ minWidth: "12rem", paddingRight: "1rem" }}>
          <FlexColumn
            style={{
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <FlexColumn style={{ gap: "1rem", marginTop: "0.87rem" }}>
              <Title>{company.name}</Title>
              {departmentTabs.map((item, index) => (
                <FlexRow
                  key={index}
                  style={{
                    alignItems: "center",
                    gap: "0.75rem",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    onDepartmentTabClick(e, index);
                  }}
                >
                  <DotListItem>{item.name}</DotListItem>
                  <NumberText>{item.numUsers}</NumberText>
                </FlexRow>
              ))}
            </FlexColumn>
            {/* <FlexColumn>
              <FlexRow style={{ justifyContent: "space-between" }}>
                <SmallText>활성화</SmallText>
                <FlexRow
                  style={{ cursor: "pointer" }}
                  onClick={() => onSwitchActiveTap(true)}
                >
                  <SmallText>
                    <Bold>{activeUsers.length}명</Bold>
                  </SmallText>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13.2914 11.9991L8.69141 7.39911L9.39911 6.69141L14.7068 11.9991L9.39911 17.3068L8.69141 16.5991L13.2914 11.9991Z"
                      fill="#BBBBBB"
                    />
                  </svg>
                </FlexRow>
              </FlexRow>
              <Divider style={{ margin: "0.5rem 0" }} />
              <FlexRow style={{ justifyContent: "space-between" }}>
                <SmallText>비활성화</SmallText>
                <FlexRow
                  style={{ cursor: "pointer" }}
                  onClick={() => onSwitchActiveTap(false)}
                >
                  <SmallText>
                    <Bold>{inactiveUsers.length}명</Bold>
                  </SmallText>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13.2914 11.9991L8.69141 7.39911L9.39911 6.69141L14.7068 11.9991L9.39911 17.3068L8.69141 16.5991L13.2914 11.9991Z"
                      fill="#BBBBBB"
                    />
                  </svg>
                </FlexRow>
              </FlexRow>
            </FlexColumn> */}
          </FlexColumn>
        </CardLayout>
        <CardLayout style={{ flex: 1 }}>
          <FlexRow
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <TableTitle>
              {departmentTabs[departmentTabIndex].name}{" "}
              <Bold style={{ color: "#00DBB1" }}> {filteredUsers.length}</Bold>
            </TableTitle>
            <FlexRow style={{ gap: "0.5rem" }}>
              {checkedUsers.length > 0 && (
                <>
                  {checkedUsers.filter((user) => user.status === STATUS_ACTIVE)
                    .length < 1 &&
                    isActiveList && (
                      <PrimaryButton onClick={onSendInviteClick}>
                        <ButtonIcon
                          src={`${process.env.PUBLIC_URL}/svg/dashboard/button_email.svg`}
                        />
                        초대하기
                      </PrimaryButton>
                    )}
                  <NormalButton
                    onClick={() =>
                      onInactiveClick(isActiveList ? "inactive" : "active")
                    }
                  >
                    <ButtonIcon
                      src={
                        isActiveList
                          ? `${process.env.PUBLIC_URL}/svg/dashboard/button_inactive.svg`
                          : `${process.env.PUBLIC_URL}/svg/dashboard/button_active.svg`
                      }
                    />
                    {isActiveList ? "비활성화 하기" : "활성화 하기"}
                  </NormalButton>
                  {checkedUsers.filter((user) => user.role >= USER_MANAGER)
                    .length > 0 && managerAboveUsers.length === 1 ? null : (
                    <NormalButton onClick={handleDeleteManyUsersClick}>
                      <ButtonIcon
                        src={`${process.env.PUBLIC_URL}/svg/dashboard/button_delete.svg`}
                      />
                      삭제하기
                    </NormalButton>
                  )}

                  <div
                    style={{
                      width: "1px",
                      height: "auto",
                      background: "#D9D9D9",
                      margin: "0 0.5rem",
                    }}
                  />
                </>
              )}

              <NormalButton>
                <a
                  href={`${process.env.PUBLIC_URL}/xlsx/maumfit_user_list.xlsx`}
                  download={`maumfit_user_list.xlsx`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <ButtonIcon
                    src={`${process.env.PUBLIC_URL}/svg/dashboard/button_download.svg`}
                  />
                  엑셀 양식 다운로드
                </a>
              </NormalButton>
              <NormalButton onClick={() => setShowAddUsersModal(true)}>
                <ButtonIcon
                  src={`${process.env.PUBLIC_URL}/svg/dashboard/button_upload.svg`}
                />
                엑셀 일괄 업로드
              </NormalButton>
              <NormalButton onClick={() => setShowAddUserModal(true)}>
                <ButtonIcon
                  src={`${process.env.PUBLIC_URL}/svg/dashboard/button_add.svg`}
                />
                구성원 추가하기
              </NormalButton>
            </FlexRow>
          </FlexRow>
          <FlexColumn style={{ marginTop: "1.5rem" }}>
            <HR>
              <HeaderItem style={{ flex: 0.5 }}>
                <Checkbox
                  active={
                    filteredUsers.length === checkedUsers.length &&
                    checkedUsers.length > 0
                  }
                  onClick={() => onCheckAllClick(filteredUsers)}
                />
              </HeaderItem>
              <HeaderItem onClick={(e) => onSortClick(e, "name")}>
                이름
                <SortButton />
              </HeaderItem>
              <HeaderItem onClick={(e) => onSortClick(e, "role")}>
                역할
                <SortButton />
              </HeaderItem>
              <HeaderItem style={{ flex: 3 }}>이메일</HeaderItem>
              <HeaderItem style={{ flex: 2 }}>그룹 (팀/차수)</HeaderItem>
              {/* <HeaderItem>직급</HeaderItem>
              <HeaderItem>성별</HeaderItem>
              <HeaderItem>생년</HeaderItem> */}
              <HeaderItem onClick={(e) => onSortClick(e, "status")}>
                상태
                <SortButton />
              </HeaderItem>
              <HeaderItem style={{ flex: 1.5 }}></HeaderItem>
            </HR>
            {filteredUsers.map((user, index) => (
              <TR key={index}>
                <Item style={{ flex: 0.5 }}>
                  <Checkbox
                    active={checkedUsers.some(
                      (item) => item["email"] === user.email
                    )}
                    onClick={(e) => onCheckboxClick(user)}
                  />
                </Item>
                <Item>{user.name}</Item>
                <Item>
                  <Badge role={user.role}>{convertRole(user.role)}</Badge>
                </Item>
                <Item style={{ flex: 3 }}>{user.email}</Item>
                <Item style={{ flex: 2 }}>{user.departmentId.name}</Item>
                {/* <Item>Manager</Item>
                <Item>{user.gender || "-"} </Item>
                <Item>{user.birthYear || "-"}</Item> */}
                <Item>
                  <StatusText status={convertStatus(user.status)} />
                </Item>
                <Item
                  style={{
                    flex: 1.5,
                  }}
                >
                  <FlexRow
                    style={{ gap: "0.5rem", justifyContent: "flex-end" }}
                  >
                    {(user.status === STATUS_SIGNUP ||
                      user.status === STATUS_INVITE) && (
                      <img
                        src={`${process.env.PUBLIC_URL}/svg/dashboard/mail_button.svg`}
                        alt="invite icon"
                        onClick={(e) => onSendOneInviteClick(e, user)}
                      />
                    )}
                    <img
                      src={`${process.env.PUBLIC_URL}/svg/dashboard/edit_button.svg`}
                      alt="edit icon"
                      onClick={() => handleEditUserClick(user)}
                    />
                    {user.role >= USER_MANAGER &&
                    managerAboveUsers.length > 1 ? (
                      <img
                        src={`${process.env.PUBLIC_URL}/svg/dashboard/delete_button.svg`}
                        alt="delete icon"
                        onClick={() => handleDeleteUserClick(user, "single")}
                      />
                    ) : user.role >= USER_MANAGER &&
                      managerAboveUsers.length <= 1 ? null : (
                      <img
                        src={`${process.env.PUBLIC_URL}/svg/dashboard/delete_button.svg`}
                        alt="delete icon"
                        onClick={() => handleDeleteUserClick(user, "single")}
                      />
                    )}
                  </FlexRow>
                </Item>
              </TR>
            ))}
          </FlexColumn>
        </CardLayout>
        <Modal
          showing={showAddUserModal}
          setShowing={setShowAddUserModal}
          width={"auto"}
          padding={"3.19rem 3.75rem"}
        >
          <AddUserModal
            domain={company.emailDomain}
            companyId={companyId}
            setDepartments={setDepartments}
            departments={departments}
            setUsers={setUsers}
            setFilteredUsers={setFilteredUsers}
            closeHandler={() => setShowAddUserModal(false)}
          />
        </Modal>
        <Modal
          showing={showAddUsersModal}
          setShowing={setShowAddUsersModal}
          width={"auto"}
          padding={"3.19rem 3.75rem"}
        >
          <AddUsersModal
            closeHandler={() => setShowAddUsersModal(false)}
            companyId={companyId}
          />
        </Modal>
        <Modal
          showing={showEditUserModal}
          setShowing={setShowEditUserModal}
          width={"auto"}
          padding={"3.19rem 3.75rem"}
        >
          <EditUserModal
            user={clickedUser}
            companyId={company._id}
            departments={departments}
            setDepartments={setDepartments}
            setUsers={setUsers}
            closeHandler={editModalCloseHandler}
          />
        </Modal>
        <Modal
          showing={showDeleteModal}
          setShowing={setShowDeleteModal}
          width={"28rem"}
          padding={"3.25rem 2.63rem"}
        >
          <DeleteUserModal
            users={deleteType === "single" ? [clickedUser] : checkedUsers}
            closeHandler={deleteModalCloseHandler}
          />
        </Modal>
        <Modal
          showing={showInviteModal}
          setShowing={setShowInviteModal}
          width={"28rem"}
          padding={"3.25rem 2.63rem"}
        >
          <IndividualInviteModal
            users={checkedUsers}
            confirmHandler={sendEmail}
            closeHandler={() => setShowInviteModal(false)}
          />
        </Modal>
      </FlexRow>
    </Container>
  );
};

export default ManagerUser;
