import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ROUTES } from "../../../Constants";
import includesPathname from "../../../utils/includesPathname";

const Container = styled.div`
  height: auto;
  width: 100%;
  background: ${({ theme }) => theme.primary.normal};
`;

const Logo = styled.img`
  width: 87px;
  margin-top: 1.25rem;
  margin-left: 1.88rem;
  margin-bottom: 1.13rem;
`;

const Section = styled.section`
  background: white;
  width: 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  height: 100%;
`;

const Wrapper = styled.div`
  padding: 0 1.88rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AiSmileMobileLayout = ({ children }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();

  const handleLogoClick = () => {
    if (state?.cid) {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE)
          ? `${ROUTES.GUEST_SMILE}/${state?.cid}`
          : ROUTES.SMILE
      );
    } else if (params?.cid) {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE)
          ? `${ROUTES.GUEST_SMILE}/${params?.cid}`
          : ROUTES.SMILE
      );
    } else {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE) ? ROUTES.GUEST_SMILE : ROUTES.SMILE
      );
    }
  };
  return (
    <Container>
      <Logo
        src={`${process.env.PUBLIC_URL}/assets/ai-smile/ai_smile_logo.png`}
        onClick={handleLogoClick}
      />
      <Section>
        <Wrapper>{children}</Wrapper>
      </Section>
    </Container>
  );
};

export default AiSmileMobileLayout;
