import axios, { axiosPrivate } from "../../../api/axios";

const updateSmilePhoto = async ({ auth, photo, likes, isLike }) => {
  if (auth?.user) {
    const response = await axiosPrivate.patch(
      `/smile/photo`,
      {
        id: photo._id,
        likes,
        userId: auth.user.id,
        isLike,
      },
      {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      }
    );
    return response.data;
  } else {
    try {
      const response = await axios.patch(`/smile/guests/photo`, {
        id: photo._id,
        likes,
      });
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
};

export default updateSmilePhoto;
