import { useState } from "react";
import styled from "styled-components";
import SubmitButton from "../SubmitButton";

const Card = styled.div`
  width: 28.13rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 2.81rem 3.13rem;
  position: relative;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.04em;
  margin-bottom: 2.19rem;
`;

const DropDown = styled.div`
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem 1.13rem;
  border-radius: 0.31rem;
  border: 1px solid
    ${({ theme, open, selected }) =>
      selected !== -1
        ? theme.grayScaleColor.black
        : open
        ? theme.grayScaleColor.black
        : theme.grayScaleColor.gray30};
  display: flex;
  align-items: center;
  color: ${({ theme, selected }) =>
    selected === -1 ? theme.grayScaleColor.gray30 : theme.grayScaleColor.black};
  font-size: 0.94rem;
  line-height: 1.44rem;
  letter-spacing: -0.04em;
  font-weight: 400;
  margin-bottom: 2.19rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const List = styled.ul`
  position: absolute;
  bottom: -150px;
  left: 0;
  background: ${({ theme }) => theme.grayScaleColor.white};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  border-radius: 0.31rem;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 0 1.13rem;
`;

const Item = styled.li`
  width: 100%;
  font-size: 0.94rem;
  color: ${({ theme, selected }) =>
    selected ? theme.primary.normal : theme.grayScaleColor.black};
  line-height: 1.44rem;
  letter-spacing: -0.04em;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  padding: 0.75rem 0.63rem;
  &:nth-child(3) {
    border-bottom: none;
  }
  &:hover {
    color: ${({ theme }) => theme.primary.normal};
  }
  cursor: pointer;
  transition: all 0.3s;
`;
const TriangleDown = () => {
  return (
    <svg
      width="11"
      height="6"
      viewBox="0 0 11 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.5 6L10.2631 0H0.73686L5.5 6Z" fill="#111111" />
    </svg>
  );
};

const TriangleUp = () => {
  return (
    <div>
      <svg
        width="11"
        height="6"
        viewBox="0 0 11 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.5 0L10.2631 6H0.73686L5.5 0Z" fill="#111111" />
      </svg>
    </div>
  );
};
const DeleteModal = ({ okHandler, closeModal }) => {
  const [open, setOpen] = useState(false);

  const [selected, setSelected] = useState(-1);
  const ITEMS = [
    "다시 쓰고 싶어요 (오타, 마음 수정 등)",
    "내용이 불쾌합니다. 신고하고 싶어요.",
    "기타",
  ];
  const handleClickTriangle = () => {
    setOpen((cur) => !cur);
  };
  const handleClickItems = (index) => {
    setSelected(index);
  };

  return (
    <Card>
      <img
        onClick={closeModal}
        src={`${process.env.PUBLIC_URL}/svg/close_button.svg`}
        alt="close"
        style={{
          position: "absolute",
          right: "1.56rem",
          top: "1.56rem",
          cursor: "pointer",
        }}
      />
      <Title>💬 삭제 요청 이유를 알려주세요</Title>
      <DropDown open={open} onClick={handleClickTriangle} selected={selected}>
        {selected === -1 ? "삭제 요청 이유를 말씀해 주세요" : ITEMS[selected]}
        {open ? <TriangleUp /> : <TriangleDown />}
        {open && (
          <List>
            {ITEMS.map((i, index) => (
              <Item
                key={index}
                selected={index === selected}
                onClick={() => {
                  handleClickItems(index);
                }}
              >
                {i}
              </Item>
            ))}
          </List>
        )}
      </DropDown>

      <SubmitButton
        value="삭제 요청하기"
        disabled={selected === -1}
        onClick={okHandler}
        style={{
          width: "12.5rem",
          padding: "0.69rem 3.81rem",
          height: "auto",
          fontSize: "0.94rem",
        }}
      />
    </Card>
  );
};

export default DeleteModal;
