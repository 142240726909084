import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "../../api/axios";
import BackgroundContainer from "../../components/BackgroundContainer";
import Logo from "../../components/Logo";
import Form from "../../components/Form";
import Loading from "../../components/Loading";
import useAuth from "../../hooks/useAuth";
import LabelInput from "../../components/LabelInput";
import SubmitButton from "../../components/SubmitButton";
import ErrorText from "../../components/ErrorText";
import BackgroundSymbol from "../../components/BackgroundSymbol";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // @media screen and (max-width: 480px) {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   width: 90%;
  // }
`;

const Text = styled.p`
  color: ${({ theme }) => theme.primary.normal};
  width: 15rem;
  margin-bottom: 39px;
`;

const TextButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const TextButton = styled.span`
  font-size: 0.75rem;
  margin-top: 20px;
  height: 1.19rem;
  cursor: pointer;

  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

function Login() {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location.state?.from?.pathname || "/";
  const search = location.state?.from?.search || "";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { auth, setAuth, setPersist } = useAuth();

  useEffect(() => {
    if (auth?.user?.email) {
      navigate(pathname, { replace: true });
    }
  }, []);

  useEffect(() => {
    // TODO: add email, password validation
    if (email.length > 0 && password.length > 0) {
      setDisabled(false);
      setError("");
    } else {
      setDisabled(true);
    }
  }, [email, password]);

  const handleLoginClick = async (e, email, password) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const response = await axios.post(
        `/login`,
        JSON.stringify({
          email,
          password,
        }),
        {
          withCredentials: true,
        }
      );
      const accessToken = response.data.accessToken;
      setAuth({
        user: {
          email,
          id: response.data.user._id,
          ...response.data.user,
          // companyId: response.data.user.companyId._id,
          // company: response.data.user.companyId.name,
          // departmentId: response.data.user.departmentId._id,
          // department: response.data.user.departmentId.name,
          // name: response.data.name,
          // nickname: response.data.nickname,
          // isSubscribe: response.data.isSubscribe,
          // companyId: response.data.companyId,
          // department: response.data.department,
          // departmentId: response.data.departmentId,
          // gender: response.data.gender,
          // birthYear: response.data.birthYear,
          // interests: response.data.interests,
          // firstCheckupDate: response.data.firstCheckupDate,
          // vibe: response.data.vibe,
          // point: response.data.point,
          // checkupInARow: response.data.checkupInARow,
          // isFirstCheckup: response.data.isFirstCheckup,
          // lastCheckupPoint: response.data.lastCheckupPoint,
          // role: response.data.role,
        },
        accessToken,
      });
      localStorage.setItem("persist", true);
      setPersist(true);
      setTimeout(() => {
        setIsLoading(false);
        navigate(`${pathname}${search}`, { replace: true });
        return;
      }, 1000);
    } catch (err) {
      setTimeout(() => {
        setIsLoading(false);
        setError(err.response.data.msg);
        return;
      }, 500);
    }
  };
  const handleFindPasswordClicked = () => {
    navigate("/find-password");
  };
  return (
    <BackgroundContainer>
      {/* <BackgroundSymbol /> */}
      <Wrapper>
        <Form>
          <Logo style={{ marginTop: "3.94rem", marginBottom: "2.5rem" }} />
          <Text>맘핏센터에 오신 여러분, 반갑습니다 :)</Text>
          <LabelInput
            htmlFor="회사 이메일"
            type="text"
            style={{ marginBottom: "13px" }}
            error={error}
            onChange={setEmail}
          />
          <LabelInput
            htmlFor="비밀번호"
            type="password"
            error={error}
            onChange={setPassword}
          />
          {error && (
            <ErrorText style={{ marginTop: "16px" }}>{error}</ErrorText>
          )}
          <SubmitButton
            value="입장하기"
            disabled={disabled}
            style={{ marginTop: "30px" }}
            onClick={(e) => handleLoginClick(e, email, password)}
          />
          <TextButtonContainer>
            <TextButton onClick={handleFindPasswordClicked}>
              비밀번호 찾기 &rarr;
            </TextButton>
          </TextButtonContainer>
          {/* <Link to="/signup-verify">회원가입</Link> */}
        </Form>
      </Wrapper>
      {isLoading && <Loading />}
    </BackgroundContainer>
  );
}

export default Login;
