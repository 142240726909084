import styled from "styled-components";

const Svg = styled.svg`
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(14rem, 23rem);
  z-index: -1;
`;

const BackgroundSymbol = () => {
  return (
    <Svg
      width="191"
      height="220"
      viewBox="0 0 191 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.0878 0C42.6278 0 0.0877686 42.1916 0.0877686 94.2218C0.0877686 142.884 37.2733 182.909 84.9988 187.899V220L147.632 172.693C150.831 170.583 153.874 168.249 156.791 165.776C160.821 162.352 164.583 158.634 167.993 154.595C181.774 138.244 190.088 117.197 190.088 94.2218C190.102 42.1916 147.562 0 95.0878 0ZM95.0878 146.671C67.8503 146.671 45.6855 124.688 45.6855 97.6737H59.8045C59.8045 116.974 75.6284 132.668 95.0878 132.668C114.547 132.668 130.371 116.974 130.371 97.6737H144.49C144.49 124.688 122.325 146.671 95.0878 146.671V146.671Z"
        fill="#00D2C1"
        fillOpacity="0.1"
      />
    </Svg>
  );
};

export default BackgroundSymbol;
