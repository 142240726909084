import styled from "styled-components";

const Svg = styled.svg`
  color: white;
`;

const Smile = ({ type, color }) => {
  switch (type) {
    case 0:
      return (
        <Svg
          width="32"
          height="31"
          viewBox="0 0 32 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1095_5675)">
            <path
              d="M29.7634 31H4.96056C2.22413 31 0 28.7804 0 26.0495V4.95052C0 2.21963 2.22413 0 4.96056 0H23.7907C26.5271 0 28.7513 2.21963 28.7513 4.95052V24.5905L31.3503 27.1842C31.9875 27.8202 32.1874 28.7804 31.8376 29.6158C31.4877 30.4513 30.6755 31 29.7634 31ZM4.96056 2.11987C3.39867 2.11987 2.12417 3.39179 2.12417 4.95052V26.0495C2.12417 27.6082 3.39867 28.8801 4.96056 28.8801H29.7634C29.7634 28.8801 29.8383 28.8801 29.8758 28.8053C29.9008 28.7305 29.8758 28.7055 29.8508 28.6806L26.6271 25.4634V4.95052C26.6271 3.39179 25.3526 2.11987 23.7907 2.11987H4.96056Z"
              fill={color}
            />
            <path
              d="M19.6423 24.0419H10.096C9.90862 24.0419 9.74619 23.8548 9.74619 23.6304V22.0966C9.74619 21.8721 9.89613 21.6851 10.096 21.6851H19.6423C19.8297 21.6851 19.9922 21.8721 19.9922 22.0966V23.6304C19.9922 23.8548 19.8422 24.0419 19.6423 24.0419Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_1095_5675">
              <rect width="32" height="31" fill="white" />
            </clipPath>
          </defs>
        </Svg>
      );
    case 1:
      return (
        <Svg
          width="32"
          height="31"
          viewBox="0 0 32 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1095_5264)">
            <path
              d="M29.7634 31H4.96056C2.22413 31 0 28.7804 0 26.0495V4.95052C0 2.21963 2.22413 0 4.96056 0H23.7907C26.5271 0 28.7513 2.21963 28.7513 4.95052V24.5905L31.3503 27.1842C31.9875 27.8202 32.1874 28.7804 31.8376 29.6158C31.4877 30.4513 30.6755 31 29.7634 31ZM4.96056 2.11987C3.39867 2.11987 2.12417 3.39179 2.12417 4.95052V26.0495C2.12417 27.6082 3.39867 28.8801 4.96056 28.8801H29.7634C29.7634 28.8801 29.8383 28.8801 29.8758 28.8053C29.9008 28.7305 29.8758 28.6931 29.8508 28.6806L26.6271 25.4634V4.95052C26.6271 3.39179 25.3526 2.11987 23.7907 2.11987H4.96056Z"
              fill={color}
            />
            <path
              d="M13.982 24.4908C12.0703 24.4908 10.2585 23.7676 8.84654 22.4333C8.67161 22.2712 8.68411 21.9719 8.84654 21.8098L10.0461 20.6751C10.2085 20.5254 10.4584 20.5254 10.6208 20.6751C11.558 21.523 12.72 21.9844 13.957 21.9844C15.1941 21.9844 16.3686 21.523 17.2932 20.6751C17.4557 20.5254 17.7056 20.5129 17.868 20.6751L19.0675 21.8098C19.2425 21.9719 19.255 22.2712 19.0675 22.4333C17.6556 23.7676 15.8563 24.4908 13.9321 24.4908H13.982Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_1095_5264">
              <rect width="32" height="31" fill="white" />
            </clipPath>
          </defs>
        </Svg>
      );
    case 2:
      return (
        <Svg
          width="32"
          height="31"
          viewBox="0 0 32 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1095_5040)">
            <path
              d="M29.7634 31H4.96056C2.22413 31 0 28.7804 0 26.0495V4.95052C0 2.21963 2.22413 0 4.96056 0H23.7907C26.5271 0 28.7513 2.21963 28.7513 4.95052V24.5905L31.3503 27.1842C31.9875 27.8202 32.1874 28.7804 31.8376 29.6158C31.4877 30.4513 30.6755 31 29.7634 31ZM4.96056 2.11987C3.39867 2.11987 2.12417 3.39179 2.12417 4.95052V26.0495C2.12417 27.6082 3.39867 28.8801 4.96056 28.8801H29.7634C29.7634 28.8801 29.8383 28.8801 29.8758 28.8053C29.9008 28.7305 29.8758 28.7055 29.8508 28.6806L26.6271 25.4634V4.95052C26.6271 3.39179 25.3526 2.11987 23.7907 2.11987H4.96056Z"
              fill={color}
            />
            <path
              d="M14.3694 24.4907C13.3323 24.4907 12.3077 24.2788 11.3581 23.8423C10.4459 23.4308 9.62124 22.8447 8.92151 22.109C8.37173 21.5354 7.9219 20.887 7.57204 20.1762C7.4096 19.852 7.57204 19.4529 7.9219 19.3158L9.05896 18.8669C9.37134 18.7422 9.7212 18.8918 9.87114 19.1911C10.1086 19.64 10.3959 20.039 10.7458 20.4006C11.2206 20.8994 11.7704 21.2985 12.3827 21.5728C13.6447 22.1339 15.0941 22.1339 16.3561 21.5728C16.9684 21.2985 17.5182 20.8994 17.993 20.4006C18.3428 20.039 18.6302 19.6275 18.8676 19.1911C19.0301 18.8918 19.3674 18.7422 19.6798 18.8669L20.8169 19.3158C21.1542 19.4529 21.3292 19.852 21.1667 20.1762C20.8169 20.887 20.3545 21.5354 19.8173 22.109C19.1175 22.8572 18.2929 23.4308 17.3807 23.8423C16.4311 24.2663 15.419 24.4907 14.3694 24.4907Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_1095_5040">
              <rect width="32" height="31" fill="white" />
            </clipPath>
          </defs>
        </Svg>
      );
    case 3:
      return (
        <svg
          width="32"
          height="31"
          viewBox="0 0 32 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1095_4851)">
            <path
              d="M29.7634 31H4.96056C2.22413 31 0 28.7804 0 26.0495V4.95052C0 2.21963 2.22413 0 4.96056 0H23.7907C26.5271 0 28.7513 2.21963 28.7513 4.95052V24.5905L31.3503 27.1842C31.9875 27.8202 32.1874 28.7804 31.8376 29.6158C31.4877 30.4513 30.6755 31 29.7634 31ZM4.96056 2.11987C3.39867 2.11987 2.12417 3.39179 2.12417 4.95052V26.0495C2.12417 27.6082 3.39867 28.8801 4.96056 28.8801H29.7634C29.7634 28.8801 29.8383 28.8801 29.8758 28.8053C29.9008 28.7305 29.8758 28.7055 29.8508 28.6806L26.6271 25.4634V4.95052C26.6271 3.39179 25.3526 2.11987 23.7907 2.11987H4.96056Z"
              fill={color}
            />
            <path
              d="M14.3694 24.4908C10.3834 24.4908 7.10972 21.3858 6.83483 17.4827C6.80984 17.1959 7.05974 16.959 7.33463 16.959H8.97149C9.23389 16.959 9.44631 17.171 9.4713 17.4328C9.70871 19.9143 11.8079 21.8721 14.3694 21.8721C16.9309 21.8721 19.0301 19.9268 19.2675 17.4328C19.2925 17.171 19.4924 16.959 19.7673 16.959H21.4041C21.6915 16.959 21.9289 17.1959 21.9039 17.4827C21.6291 21.3982 18.3553 24.4908 14.3694 24.4908Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_1095_4851">
              <rect width="32" height="31" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 4:
      return (
        <Svg
          width="32"
          height="31"
          viewBox="0 0 32 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1095_4440)">
            <path
              d="M29.7634 31H4.96056C2.22413 31 0 28.7804 0 26.0495V4.95052C0 2.21963 2.22413 0 4.96056 0H23.7907C26.5271 0 28.7513 2.21963 28.7513 4.95052V24.5905L31.3503 27.1842C31.9875 27.8202 32.1874 28.7804 31.8376 29.6158C31.4877 30.4513 30.6755 31 29.7634 31ZM4.96056 2.11987C3.39867 2.11987 2.12417 3.39179 2.12417 4.95052V26.0495C2.12417 27.6082 3.39867 28.8801 4.96056 28.8801H29.7634C29.7634 28.8801 29.8383 28.8801 29.8758 28.8053C29.9008 28.7305 29.8758 28.7055 29.8508 28.6806L26.6271 25.4634V4.95052C26.6271 3.39179 25.3526 2.11987 23.7907 2.11987H4.96056Z"
              fill={color}
            />
            <path
              d="M14.3694 24.5031C10.146 24.5031 6.69739 20.8994 6.69739 16.4726C6.69739 15.7867 7.25967 15.2256 7.9469 15.2256H20.7794C21.4666 15.2256 22.0289 15.7867 22.0289 16.4726C22.0289 20.8994 18.5927 24.5031 14.3569 24.5031H14.3694ZM9.33386 17.7196C9.85865 20.1761 11.9203 22.0092 14.3694 22.0092C16.8184 22.0092 18.8801 20.1761 19.4049 17.7196H9.33386Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_1095_4440">
              <rect width="32" height="31" fill="white" />
            </clipPath>
          </defs>
        </Svg>
      );
    default:
      return null;
  }
};

export default Smile;
