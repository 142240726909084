import { useEffect, useRef } from "react";
import styled from "styled-components";

const Textarea = styled.textarea`
  width: 100%;
  height: 5.63rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  border-radius: 0.63rem;
  padding: 1.25rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  font-size: 1rem;
  resize: none;
  outline: none;
  &:focus {
    background: ${({ theme }) => theme.backgroundColor.darkgray10};
    outline: none;
    border: 1px solid ${({ theme }) => theme.grayScaleColor.black};
  }
`;

const ShortTextQuestion = ({ type, placeholder, onChange, isActive }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (isActive) {
      ref.current.focus();
    } else {
      ref.current.blur();
    }
  }, [isActive]);
  return (
    <Textarea
      ref={ref}
      isActive={isActive}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
    ></Textarea>
  );
};

export default ShortTextQuestion;
