import styled from "styled-components";

const Text = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 150%; /* 1.875rem */
  letter-spacing: -0.03125rem;
`;

const ModalHeading2 = ({ text, children, style }) => (
  <Text style={style}>{text || children}</Text>
);

export default ModalHeading2;
