import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FlexColumn from "../../components/FlexColumn";

import FlexRow from "../../components/FlexRow";
import { TABLET_LANDSCAPE } from "../../Constants";
import { getDate } from "../../utils/date";
import NoDataText from "./components/NoDataText";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 500;
  line-height: 140%;
`;

const Text1 = styled.span`
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 140%;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const Text2 = styled.span`
  font-size: 0.8125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const Text3 = styled.span`
  font-size: 0.8125rem;
  color: ${({ theme, isPositive }) =>
    isPositive ? theme.primary.normal : theme.backgroundColor.darkgray40};
  line-height: 140%;
`;

const Button = styled.button`
  border-radius: 0.4375rem;
  padding: 0.88rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.grayScaleColor.white};
  width: 100%;
  //   position: absolute;
  bottom: 0;
  cursor: pointer;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    margin-top: 2rem;
  }
`;

const Card9 = ({ title, data, style, auth }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/dashboard/mypage");
  };
  const handleRequestWithdrawClick = () => {
    navigate("/dashboard/mypage/reward");
  };

  return (
    <Container style={style}>
      <FlexRow
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1.47rem",
        }}
      >
        <Title>{title}</Title>
        <svg
          style={{ cursor: "pointer" }}
          onClick={handleClick}
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
        >
          <path
            d="M15.1012 13.4827L9.64062 8.01126L10.5579 7.09399L16.9358 13.4827L10.5579 19.8605L9.64062 18.9432L15.1012 13.4827Z"
            fill="#222222"
          />
        </svg>
      </FlexRow>
      <FlexColumn
        style={{
          flexGrow: 1,
          justifyContent: "space-between",
        }}
      >
        {data.length > 0 ? (
          <FlexColumn style={{ gap: "1rem" }}>
            {data.map((item, index) => (
              <FlexRow key={index} style={{ justifyContent: "space-between" }}>
                <FlexRow style={{ alignItems: "center" }}>
                  <Text1 style={{ marginRight: "0.62rem" }}>
                    {getDate(item.createdAt, "YYYY-MM-DD")}
                  </Text1>
                  <Text2>{item.pointTypeInKr}</Text2>
                </FlexRow>
                <Text3 isPositive={item.point >= 0}>
                  {item.point >= 0
                    ? `+${item.point.toLocaleString()}원`
                    : `${item.point.toLocaleString()}원`}
                </Text3>
              </FlexRow>
            ))}
          </FlexColumn>
        ) : (
          <NoDataText />
        )}
        {auth.user.companyId._id !== "655edc229c47154313fe6a93" && (
          <Button onClick={handleRequestWithdrawClick}>
            <img
              style={{
                width: "1.25rem",
                height: "1.25rem",
                marginRight: "0.38rem",
              }}
              src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
              alt="cash icon"
            />
            <span>캐시 환급 신청하기</span>
          </Button>
        )}
      </FlexColumn>
    </Container>
  );
};

export default Card9;
