import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "react-slideshow-image/dist/styles.css";
import { useEffect, useRef, useState } from "react";
import axios from "../api/axios";
import FlexRow from "../components/FlexRow";
import FlexColumn from "../components/FlexColumn";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import "react-slideshow-image/dist/styles.css";
import { MOBILE } from "../Constants";

import { isAuthEmpty } from "../utils/isAuthEmpty";
import { Helmet } from "react-helmet";
import validatePhone from "../utils/validatePhone";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 5.75rem);
  background: ${({ theme }) => theme.grayScaleColor.white};
  // border: 1px solid black;
`;

const Heading1 = styled.h2`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 2rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Heading2 = styled.h2`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const Heading3 = styled.h3`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  margin-top: 2.56rem;
  margin-bottom: 1.25rem;
`;

const Heading4 = styled.h4`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const Section = styled.section`
  padding: 1.75rem 1.25rem;
`;

const Callout = styled.div`
  padding: 0.75rem 1.25rem;
  border-radius: 0.625rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .right {
    font-weight: 500;
    color: ${({ theme }) => theme.backgroundColor.darkgray40};
  }
`;

const LineButton = styled.p`
  text-align: center;
  font-family: Pretendard;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  text-decoration-line: underline;
  color: ${({ theme, state }) =>
    state ? theme.backgroundColor.darkgray40 : theme.primary.normal};
`;

const Label = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4rem; /* 153.333% */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
`;

const SelectBox = styled.div`
  width: 100%;
  border-radius: 0.625rem;

  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 0.75rem;
  text-align: center;
  color: ${({ theme, active }) =>
    active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4375rem; /* 153.333% */
  letter-spacing: -0.0375rem;
  cursor: pointer;
`;

const Input = styled.div`
  width: 100%;
  border-radius: 0.625rem;
  ${({ isOpen }) =>
    isOpen && "border-bottom-left-radius: 0; border-bottom-right-radius: 0;"}
  border: 1px solid
    ${({ theme, isOpen, disabled }) =>
    disabled
      ? theme.grayScaleColor.gray10
      : isOpen
      ? theme.primary.normal
      : theme.grayScaleColor.gray20};
  background: ${({ theme, disabled }) =>
    disabled && theme.grayScaleColor.gray5};
  padding: 0.75rem 1.25rem;
  color: ${({ theme, active }) =>
    active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  font-size: 0.875rem;
  line-height: 1.4rem; /* 164.286% */
  letter-spacing: -0.03125rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const SInput = styled.input`
  width: 100%;
  border-radius: 0.625rem;
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.4rem; /* 164.286% */
  letter-spacing: -0.03125rem;
  border: none;
  outline: ${({ theme }) => `1px solid ${theme.grayScaleColor.gray20}`};
  :focus {
    outline: ${({ theme }) => `1px solid ${theme.primary.normal}`};
  }
`;

const Button = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme, disabled }) =>
    disabled ? theme.grayScaleColor.gray30 : theme.grayScaleColor.white};
  font-size: 1.13rem;
  border-radius: 0.75rem;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.grayScaleColor.gray10 : theme.primary.normal};
  border: none;
  margin-top: 2.5rem;
  cursor: pointer;
  // &:hover {
  //   background-color: ${({ theme }) => theme.primary.hover};
  // }

  transition: all 0.3s;
  // To adapt css in iphone
  -webkit-appearance: none;
`;

const Item = styled.div`
  color: #222;
  &:hover {
    color: ${({ theme }) => theme.primary.normal};
  }
`;

const SDropdown = ({ close, items, setItem, item, type }) => {
  const selectRef = useRef();

  const handleClick = (e, data) => {
    setItem(data);
    close(false);
  };
  let index = 0;
  if (item) {
    index = items.indexOf(item);
  }
  useEffect(() => {
    if (type === "birthYear") {
      selectRef.current.scrollTop = 600;
    }
  }, []);
  return (
    <div
      ref={selectRef}
      style={{
        position: "absolute",
        left: "-1px",
        right: "-1px",
        top: "2.875rem",
        border: "1px solid #00DBB1",
        height: "11.1875rem",
        overflowY: "auto",
        background: "white",
        borderRadius: "0rem 0rem 0.625rem 0.625rem",
        padding: "0.25rem 1rem",
        zIndex: 1,
        filter: "drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.20))",
      }}
    >
      {items.map((data, index) => (
        <Item
          key={index}
          style={{
            display: "flex",
            justifyContent: "center",
            // color: data === item ? "#00DBB1" : "#222",
            borderBottom: "1px solid #EEEEEE",
            padding: "0.56rem 0",
            fontSize: "0.875rem",
            lineHeight: "140%",
          }}
          onClick={(e) => handleClick(e, data)}
        >
          {data}
        </Item>
      ))}
    </div>
  );
};

function MypageProfile() {
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(true);

  const years = [];
  const validYear = new Date().getFullYear() - 14;
  for (let i = validYear; i >= validYear - 100; i--) {
    years.push(i);
  }
  const workExperiences = [];
  for (let i = 1; i < 30; i++) {
    workExperiences.push(i);
  }
  workExperiences.push("30년 이상");
  const positions = [
    "기획/전략",
    "마케팅, 광고, 홍보, 리서치",
    "회계, 세무, 재무",
    "인사, 노무, HRD",
    "총무",
    "법무",
    "IT개발, 데이터",
    "PM, PO, 서비스기획",
    "디자인",
    "영업, 판매",
    "무역, 수입/수출",
    "고객상담, TM(텔레마케팅)",
    "구매, 자재, 물류",
    "상품기획, MD",
    "운전, 운송, 배송",
    "서비스",
    "생산",
    "건설, 건축",
    "의료, 보건",
    "연구, R&D",
    "교육",
    "기타",
  ];
  const [gender, setGender] = useState(auth.user.gender);
  const [nickname, setNickname] = useState(auth.user.nickname);
  const [phone, setPhone] = useState(auth.user.phone);
  const [birthYear, setBirthYear] = useState(auth.user.birthYear);
  const [workExperience, setWorkExperience] = useState(
    auth.user.workExperience
  );
  const [position, setPosition] = useState(auth.user.position);
  const [openBirthYear, setOpenBirthYear] = useState(false);
  const [openWorkExperience, setOpenWorkExperience] = useState(false);
  const [openPosition, setOpenPosition] = useState(false);

  const genderMaleRef = useRef();
  const genderFemaleRef = useRef();

  const onNicknameChange = (e) => {
    setNickname(e.target.value);
  };

  const onPhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
  };

  const handleGenderClick = (e) => {
    if (e.target.id === "male") {
      setGender("남자");
      genderMaleRef.current.style = "color: #111; ";
      genderFemaleRef.current.style = "color: #BBB; border-color:#D9D9D9";
    } else {
      setGender("여자");
      genderFemaleRef.current.style = "color: #111;";
      genderMaleRef.current.style = "color: #BBB; border-color:#D9D9D9";
    }
  };
  const handleInputClick = (e) => {
    e.stopPropagation();
    if (e.target.id === "birthYear") {
      setOpenBirthYear((prev) => !prev);
      setOpenWorkExperience(false);
      setOpenPosition(false);
    } else if (e.target.id === "workExperience") {
      setOpenWorkExperience((prev) => !prev);
      setOpenBirthYear(false);
      setOpenPosition(false);
    } else if (e.target.id === "position") {
      setOpenPosition((prev) => !prev);
      setOpenWorkExperience(false);
      setOpenBirthYear(false);
    }
  };

  const onSubmitClick = async () => {
    if (nickname.length === 0) {
      alert("닉네임을 입력하세요");
      return;
    }
    if (!validatePhone(phone)) {
      alert("휴대폰 번호가 올바르지 않습니다.");
      return;
    }

    try {
      const response = await axiosPrivate.patch(`/users/${auth.user.id}`, {
        nickname: auth.user.nickname === nickname ? null : nickname,
        gender,
        birthYear,
        phone: phone.replaceAll("-", ""),
        position,
        workExperience,
      });

      // console.log(interests);
      // console.log(response.data);
      setAuth({
        accessToken: auth.accessToken,
        user: {
          ...auth.user,
          nickname: response.data.nickname,
          gender: response.data.gender,
          birthYear: response.data.birthYear,
          phone: response.data.phone,
          position: response.data.position,
          workExperience: response.data.workExperience,
        },
      });
      navigate("/mypage");
    } catch (err) {
      alert(err.response.data.msg);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      // console.log(response.data.surveys);
      setIsLoading(false);
    };

    if (isAuthEmpty(auth)) {
      return navigate("/login");
    }
    fetch();
  }, []);
  // console.log(surveyYearMonth, monthPeriod);
  if (isLoading) {
    return <div />;
  }

  return (
    <Container>
      <Helmet>
        <meta name="theme-color" content="#fff" />
      </Helmet>
      <Section>
        <FlexColumn style={{ marginTop: "2rem", gap: "1.25rem" }}>
          <FlexColumn style={{ gap: "0.44rem" }}>
            <Label>
              닉네임 <span style={{ color: "#00DBB1" }}>*</span>
            </Label>
            <SInput
              id="nickname"
              value={nickname}
              onChange={onNicknameChange}
            />
          </FlexColumn>
          <FlexColumn style={{ gap: "0.44rem" }}>
            <Label>
              이름 <span style={{ color: "#00DBB1" }}>*</span>
            </Label>
            <Input id="name" disabled={true}>
              {auth.user.name}
            </Input>
          </FlexColumn>
          <FlexColumn style={{ gap: "0.44rem" }}>
            <Label>
              회사 <span style={{ color: "#00DBB1" }}>*</span>
            </Label>
            <Input id="company" disabled={true}>
              {auth.user.companyId.name}
            </Input>
          </FlexColumn>
          <FlexColumn style={{ gap: "0.44rem" }}>
            <Label>
              팀 <span style={{ color: "#00DBB1" }}>*</span>
            </Label>
            <Input id="department" disabled={true}>
              {auth.user.departmentId.name}
            </Input>
          </FlexColumn>
          <FlexColumn style={{ gap: "0.44rem" }}>
            <Label>
              휴대폰 번호 <span style={{ color: "#00DBB1" }}>*</span>
            </Label>
            <SInput id="phone" value={phone} onChange={onPhoneChange} />
          </FlexColumn>
          <FlexColumn style={{ gap: "0.44rem" }}>
            <Label>성별</Label>
            <FlexRow
              style={{ justifyContent: "space-between", gap: "1.25rem" }}
            >
              <SelectBox
                id="male"
                ref={genderMaleRef}
                active={gender === "남자"}
                onClick={handleGenderClick}
              >
                남
              </SelectBox>
              <SelectBox
                id="female"
                ref={genderFemaleRef}
                active={gender === "여자"}
                onClick={handleGenderClick}
              >
                여
              </SelectBox>
            </FlexRow>
          </FlexColumn>
          <FlexColumn style={{ gap: "0.44rem" }}>
            <Label>출생연도</Label>
            <Input
              id="birthYear"
              onClick={handleInputClick}
              active={openBirthYear || birthYear}
              isOpen={openBirthYear}
            >
              {birthYear ? birthYear : "출생연도를 선택해 주세요"}
              <img
                src={`${process.env.PUBLIC_URL}/svg/dropdown_triangle_down.svg`}
                style={{}}
                id="birthYear"
                alt={"arrow down"}
                onClick={handleInputClick}
              />
              {openBirthYear && (
                <SDropdown
                  items={years}
                  close={(toggle) => setOpenBirthYear(toggle)}
                  setItem={setBirthYear}
                  item={birthYear}
                  type={"birthYear"}
                />
              )}
            </Input>
          </FlexColumn>
          <FlexColumn style={{ gap: "0.44rem" }}>
            <Label>전체 경력</Label>
            <Input
              id="workExperience"
              onClick={handleInputClick}
              active={openWorkExperience || workExperience}
              isOpen={openWorkExperience}
            >
              {workExperience ? workExperience : "전체 경력을 선택해 주세요"}
              {/* <ArrowDownSvg style={{}} /> */}
              <img
                src={`${process.env.PUBLIC_URL}/svg/dropdown_triangle_down.svg`}
                style={{}}
                id="workExperience"
                alt={"arrow down"}
                onClick={handleInputClick}
              />
              {openWorkExperience && (
                <SDropdown
                  items={workExperiences}
                  close={(toggle) => setOpenWorkExperience(toggle)}
                  setItem={setWorkExperience}
                  item={workExperience}
                />
              )}
            </Input>
          </FlexColumn>
          <FlexColumn style={{ gap: "0.44rem" }}>
            <Label>직무</Label>
            <Input
              id="position"
              onClick={handleInputClick}
              active={openPosition || position}
              isOpen={openPosition}
            >
              {position ? position : "해당되는 직무를 선택해 주세요"}
              <img
                src={`${process.env.PUBLIC_URL}/svg/dropdown_triangle_down.svg`}
                style={{}}
                id="position"
                alt={"arrow down"}
                onClick={handleInputClick}
              />
              {openPosition && (
                <SDropdown
                  items={positions}
                  close={(toggle) => setOpenPosition(toggle)}
                  setItem={setPosition}
                  item={position}
                />
              )}
            </Input>
          </FlexColumn>
        </FlexColumn>
        <Button
          onClick={onSubmitClick}
          disabled={nickname.length === 0 || !validatePhone(phone)}
        >
          정보 수정 완료
        </Button>
      </Section>
    </Container>
  );
}

export default MypageProfile;
