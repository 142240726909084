import { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import FlexRow from "../../../components/FlexRow";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import BackgroundText from "./components/BackgroundText";
import Heading1 from "./components/Heading1";
import Heading2 from "./components/Heading2";
import Heading3 from "./components/Heading3";
import ShareCommentBox from "./components/ShareCommentBox";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
`;

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 7.75rem;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.25rem;
`;

const DropdownLabel = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 0.94rem;
  user-select: none;
  //   border: 1px solid black;
  float: right;
`;

const DropdownArrow = styled.img``;

const DropdownMenus = styled.ul`
  position: absolute;
  top: 30px;
  right: 0;
  width: 74px;
  // height: 92px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 2;
`;

const DropdownButton = styled.li`
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
  padding: 0.5rem 0;
  font-size: 0.75rem;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.primary.normal : theme.grayScaleColor.gray70};
  font-weight: ${({ isSelected }) => (isSelected ? 600 : 400)};
  line-height: 1.19rem;

  &:last-child {
    border: none;
  }
`;

const EmptyBox = styled.div`
  width: 100%;
  padding: 1rem 2.5rem;
  border-radius: 0.63rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const BackButton = styled.img`
  margin-top: 2.25rem;
  cursor: pointer;
`;

const Share = ({ companyId }) => {
  const { auth } = useAuth();
  const { id } = useParams();
  const DEPARTMENT = { name: auth.user.departmentId.name, value: "department" };

  const location = useLocation();
  const [serchParams, setSearchParams] = useSearchParams();

  const axiosPrivate = useAxiosPrivate();
  const [shares, setShares] = useState([]);
  const [company, setCompany] = useState({ name: "", value: "company" });
  const [filteredShares, setFilteredShares] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [filter, setFilter] = useState();
  const [period, setPeriod] = useState();
  const [question, setQuestion] = useState();
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(
      location.pathname.includes("/manager")
        ? -1
        : location.pathname.includes("/leader")
        ? "/leader/dashboard/checkup/share"
        : "/dashboard/checkup/share"
    );
  };
  const handleDropdownClick = () => {
    setShowDropdownMenu((cur) => !cur);
  };

  const handleClickSortButton = (e, item) => {
    setFilter(item);
    if (item.value === DEPARTMENT.value) {
      const filtered = shares.filter(
        (i) => i.departmentId === auth.user.departmentId._id
      );
      if (filtered.length >= 3) {
        setFilteredShares(
          shares.filter((i) => i.departmentId === auth.user.departmentId._id)
        );
      } else {
        setFilteredShares([]);
      }
    } else {
      setFilteredShares(shares);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const department = await axiosPrivate.get(
        `/department/report?companyId=${companyId}`
      );
      console.log(department);
      setCompany((prev) => ({
        name: department.data.data.company,
        ...prev,
      }));
      setFilter({
        name: department.data.data.company,
        value: "company",
      });
      const response = await axiosPrivate.get(
        `/checkup-v2/share?surveyId=${id}&filter=company&companyId=${companyId}`
      );
      if (!response.data.ok) {
      } else {
        setPeriod(response.data.period);
        setQuestion(response.data.question);
        setShares(response.data.shares);
        setFilteredShares(response.data.shares);
      }

      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <Wrapper>
        <BackButton
          src={`${process.env.PUBLIC_URL}/svg/page_back_button.svg`}
          onClick={handleBackButtonClick}
        />
        <HeadingContainer>
          <Heading2>마음 나누기</Heading2>
          <Heading1 style={{ marginTop: "0.5rem" }}>
            Q. {question?.question} {question?.subQuestion}
          </Heading1>
          <Heading3 style={{ marginTop: "1rem" }}>{period}</Heading3>
        </HeadingContainer>
        <FlexRow
          style={{
            marginTop: "2.5rem",
            marginBottom: "1.5rem",
            justifyContent: "space-between",
          }}
        >
          <BackgroundText style={{ marginLeft: "0" }}>
            이번 주 마음 나누기: {filteredShares.length}개
          </BackgroundText>
          {/* <DropdownLabel onClick={handleDropdownClick}>
            {filter.name}
            <DropdownArrow
              src={`${process.env.PUBLIC_URL}/svg/dropdown_arrow_button.svg`}
              style={{ marginLeft: 5 }}
            />
            {showDropdownMenu && (
              <DropdownMenus>
                {[company, DEPARTMENT].map((item, index) => (
                  <DropdownButton
                    key={index}
                    isSelected={item.name === filter.name}
                    onClick={(e) => handleClickSortButton(e, item)}
                  >
                    {item.name}
                  </DropdownButton>
                ))}
              </DropdownMenus>
            )}
          </DropdownLabel> */}
        </FlexRow>
        <div style={{ textAlign: "center" }}>
          {filteredShares.length < 3 ? (
            <EmptyBox>
              마음 나누기 답변이{" "}
              <span style={{ fontWeight: 600 }}>3개 이상일 때</span>만 볼 수
              있어요 😥
            </EmptyBox>
          ) : (
            <ResponsiveMasonry columnsCountBreakPoints={{ 750: 3 }}>
              <Masonry gutter="32px">
                {filteredShares.map((share, index) => (
                  <ShareCommentBox
                    key={index}
                    text={share.text}
                    style={{ textAlign: "left" }}
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          )}
        </div>
      </Wrapper>
    </Container>
  );
};

export default Share;
