import styled from "styled-components";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Description from "../components/Description";
import Heading from "../components/Heading";
import SubHeading from "../components/SubHeading";
import PhotoCard from "../components/PhotoCard";
import { useEffect, useState } from "react";
import { LIKE, RECENT, SCORE } from "../constants";
import getSmilePhotos from "../api/getSmilePhotos";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import includesPathname from "../../../utils/includesPathname";
import { ROUTES } from "../../../Constants";

const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.primary.normal};
  padding: 3.75rem 0;
  letter-spacing: -0.04em;
`;

const Wrapper = styled.div`
  max-width: 51.75rem;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  padding: 0 50px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  position: relative;
  background: ${({ theme }) => theme.grayScaleColor.white};
`;

const Logo = styled.img`
  width: 5.94rem;
  height: 1.06rem;
  position: absolute;
  top: 40px;
  left: 50px;
  cursor: pointer;
`;

const DropdownLabel = styled.div`
  margin-top: 0.94rem;
  margin-bottom: 1.88rem;
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 0.94rem;
  user-select: none;
  //   border: 1px solid black;
  float: right;
`;

const DropdownMenus = styled.ul`
  position: absolute;
  top: 30px;
  right: 0;
  width: 74px;
  height: 92px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 2;
`;

const DropdownButton = styled.li`
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
  padding: 6px 0;
  font-size: 0.75rem;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.primary.normal : theme.grayScaleColor.gray70};
  font-weight: 400;
  line-height: 1.19rem;

  &:last-child {
    border: none;
  }
`;

const PhotoContainer = styled.section`
  width: 100%;
  margin-bottom: 80px;
`;

const MorePhotoButton = styled.button`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  padding: 6px 24.5px;
  font-size: 0.94rem;
  font-weight: 400;
  line-height: 1.44rem;
  background: white;
  border-radius: 5px;
  display: block;
  margin: 0 auto;
  margin-bottom: 200px;
  cursor: pointer;
`;

const Card = styled.div`
  width: 216px;
  height: 319px;
  border: 1px solid black;
`;

const Champions = () => {
  const { cid } = useParams();
  const { auth } = useAuth();

  const [sort, setSort] = useState(RECENT);
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const handleClickLogo = () => {
    if (cid) {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE)
          ? `${ROUTES.GUEST_SMILE}/${cid}`
          : ROUTES.SMILE
      );
    } else {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE) ? ROUTES.GUEST_SMILE : ROUTES.SMILE
      );
    }
  };
  const handleDropdownClick = () => {
    setShowDropdownMenu((cur) => !cur);
  };

  const handleClickSortButton = async (e, item) => {
    setSort(item);

    // sorting 클릭시 sorting param 추가해서 이미지 가져오기
    const response = await getSmilePhotos({
      auth: includesPathname(ROUTES.GUEST_SMILE) ? null : auth,
      cid,
      sort: item.value,
      page: 0,
      isPublic: true,
    });
    if (response.ok) {
      setPhotos(response.photos);
      setPage(response.nextPage);
    }
  };

  const handleMorePhotoButtonClick = async () => {
    // sorting 클릭시 sorting param 추가해서 이미지 가져오기
    const response = await getSmilePhotos({
      auth: includesPathname(ROUTES.GUEST_SMILE) ? null : auth,
      cid,
      sort: sort.value,
      page,
      isPublic: true,
    });
    if (response.ok) {
      setPhotos((cur) => [...cur, ...response.photos]);
      setPage(response.nextPage);
    }
  };

  useEffect(() => {
    // 페이지 방문시 이미지 가져오기

    const fetchData = async () => {
      const response = await getSmilePhotos({
        auth: includesPathname(ROUTES.GUEST_SMILE) ? null : auth,
        cid,
        sort: sort.value,
        page,
        isPublic: true,
      });
      if (response.ok) {
        setPhotos(response.photos);
        setPage(response.nextPage);
      }
    };

    fetchData();
  }, []);
  return (
    <Container>
      <Wrapper>
        <Logo
          onClick={handleClickLogo}
          src={`${process.env.PUBLIC_URL}/assets/logo/ai_logo.png`}
        />
        <SubHeading text={"Duchenne Smile Champions"} />
        <Heading text={"찐-미소 챔피언즈 🏆"} />
        <Description
          text={
            "광대승천 찐-미소를 달성하기 위해 노력한\n명예의 맘핏러들을 소개합니다🎉"
          }
        />
        <DropdownLabel onClick={handleDropdownClick}>
          {sort.label}
          <span style={{ marginBottom: 5, marginLeft: 5 }}>&#8964;</span>
          {showDropdownMenu && (
            <DropdownMenus>
              {[RECENT, LIKE, SCORE].map((item, index) => (
                <DropdownButton
                  key={index}
                  isSelected={item.label === sort.label}
                  onClick={(e) => handleClickSortButton(e, item)}
                >
                  {item.label}
                </DropdownButton>
              ))}
            </DropdownMenus>
          )}
        </DropdownLabel>
        <PhotoContainer>
          <ResponsiveMasonry columnsCountBreakPoints={{ 750: 3 }}>
            <Masonry gutter="40px">
              {photos.map((item, index) => (
                <PhotoCard
                  key={item._id}
                  auth={includesPathname(ROUTES.GUEST_SMILE) ? null : auth}
                  setPhotos={setPhotos}
                  index={index}
                  photo={item}
                  style={{ width: 216 }}
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </PhotoContainer>
        <MorePhotoButton onClick={handleMorePhotoButtonClick}>
          + 더 많은 미소 보기
        </MorePhotoButton>
      </Wrapper>
    </Container>
  );
};

export default Champions;
