import styled from "styled-components";
import { MOBILE } from "../../../../Constants";

const Badge = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
  border-radius: 50px;
  color: ${({ theme, level }) =>
    level === "good"
      ? theme.primary.dark
      : level === "ok"
      ? theme.colors.success
      : "#F11B1B"};
  background: ${({ theme, level }) =>
    level === "good"
      ? theme.primary.light
      : level === "ok"
      ? "#EEF8FD"
      : "#FDEFEF"};
  @media (max-width: ${MOBILE}) {
    font-size: 0.56275rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    padding: 0.16081rem 0.48238rem;
  }
`;

const ReportBadge = ({ level, children, style }) => {
  return (
    <Badge style={style} level={level}>
      {children}{" "}
    </Badge>
  );
};

export default ReportBadge;
