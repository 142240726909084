export const happinessSurveyData = {
  title: "[마음 체크업] 행복도 진단",
  submitButtonTitle: "행복도 측정하기",
  body: [
    {
      title: "나는 현재 내 삶에 대해서 얼마나 만족하고 계신가요?",
      questions: [
        {
          title: "1. 나는 내 삶의 개인적 측면에 대해 만족한다.",
          choices: ["1점", "2점", "3점", "4점", "5점", "6점", "7점"],
        },
        {
          title: "2. 나는 내 삶의 관계적 측면에 대해 만족한다.",
          choices: ["1점", "2점", "3점", "4점", "5점", "6점", "7점"],
        },
        {
          title: "3. 나는 내가 속한 집단에 대해 만족한다.",
          choices: ["1점", "2점", "3점", "4점", "5점", "6점", "7점"],
        },
      ],
    },
    {
      title:
        "지난 한 달 동안 무엇을 하며 지냈는지, 어떤 경험을 했는지 잠시 생각해보세요. 나는 아래에 제시된 감정들을 얼마나 자주 느꼈는지 표시해보세요.",
      questions: [
        {
          title: "4. 즐거움",
          choices: ["1점", "2점", "3점", "4점", "5점", "6점", "7점"],
        },
        {
          title: "5. 행복함",
          choices: ["1점", "2점", "3점", "4점", "5점", "6점", "7점"],
        },
        {
          title: "6. 편안함",
          choices: ["1점", "2점", "3점", "4점", "5점", "6점", "7점"],
        },
        {
          title: "7. 짜증남",
          choices: ["1점", "2점", "3점", "4점", "5점", "6점", "7점"],
        },
        {
          title: "8. 부정적",
          choices: ["1점", "2점", "3점", "4점", "5점", "6점", "7점"],
        },
        {
          title: "9. 무기력",
          choices: ["1점", "2점", "3점", "4점", "5점", "6점", "7점"],
        },
      ],
    },
  ],
};

export const checkupSurvey = {
  title: "[마음 체크업] 일터 웰빙 측정",
  submitButtonTitle: "일터 웰빙 측정하기",
  body: {
    title: "오늘도 일하느라 수고했어요😊",
    description:
      "잠시 시간을 내 직장에서의 나를 돌아보세요. 이번 주 어떤 점이 좋았나요? 또 어떤 점이 아쉬웠나요? 간단한 질문들로 일터에서의 나의 웰빙을 점검하세요.",
    questions: [
      {
        title: "일터에서 기분 좋게 지냈나요?",
        type: "choice",
        choices: ["전혀", "약간", "적당히", "많이", "최고"],
      },
      {
        title: "일하면서 충분히 몰입하셨나요?",
        type: "choice",
        choices: ["전혀", "약간", "적당히", "많이", "최고"],
      },
      {
        title: "동료와 잘 지내셨나요?",
        type: "choice",
        choices: ["전혀", "약간", "적당히", "많이", "최고"],
      },
      {
        title: "팀이나 조직에 좋은 영향을 주었나요?",
        type: "choice",
        choices: ["전혀", "약간", "적당히", "많이", "최고"],
      },
      {
        title: "목표한 일은 만족스럽게 완수했나요?",
        type: "choice",
        choices: ["전혀", "약간", "적당히", "많이", "최고"],
      },
      {
        title: "몸 컨디션은 좋았나요?",
        type: "choice",
        choices: ["전혀", "약간", "적당히", "많이", "최고"],
      },
      {
        title: "이번 주 직장에서 느낀 감정 모두 선택해주세요.",
        type: "multipleChoice",
        multipleChoices: [
          ["감사함", 1],
          ["걱정", -1],
          ["기쁨", 1],
          ["두려움", -1],
          ["화", -1],
          ["짜증", -1],
          ["우울함", -1],
          ["즐거움", 1],
          ["행복", 1],
          ["안정감", 1],
          ["신남", 1],
          ["허탈함", -1],
          ["편안함", 1],
          ["자신감", 1],
          ["설레임", 1],
          ["난처함", -1],
          ["후회", -1],
          ["흥미", 1],
          ["외로움", -1],
          ["재미", 1],
          ["뿌듯함", 1],
          ["서운함", -1],
          ["지루함", -1],
          ["전혀 없음", -1],
        ],
      },
      {
        title: "(선택) 이번 주를 한 줄로 기록한다면?",
        type: "short text",
        placeholder: "일이 많지만 나름 뿌듯한 한 주",
      },
    ],
  },
};
