import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import { useEffect, useRef, useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  zoomPlugin
);

// ChartJS.pluginService.register({
//   beforeInit: function (chart) {
//     console.log("hi", chart);
//     chart.legend.afterFit = function () {
//       chart.legend.options.labels.padding = 20;
//       chart.height += 30;
//     };
//   },
// });

const Container = styled.div`
  width: 100%;
  height: 20.31rem;
  border-radius: 0.63rem;
  padding: 20px;
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  @media (max-width: 425px) {
    width: 100%;
    height: 225px;
    padding: 0;
    margin-top: 20px;
  }
  // border: 1px solid black;
  font-family: Montserrat;
`;

export function Chart({ scale, results, dates, color }) {
  console.log(results, dates);
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });
  let labels = [...dates];
  // let extraDates = 7 - dates.length;
  // let lastDates = labels[dates.length - 1];
  if (labels.length === 0) {
    labels.push(new Date());
  }
  //   if (labels.length < 7) {
  //     for (let i = 0; i < 7; i++) {
  //       if (labels[i] === undefined) {
  //         labels.push(null);
  //       }
  //     }
  //   }

  // if (dates.length < 7) {
  //   for (let i = extraDates; i > 0; i--) {
  //     lastDates = new Date(lastDates).setDate(
  //       new Date(lastDates).getDate() + 1
  //     );
  //     labels.push(lastDates);
  //   }
  // }
  labels = labels.map((item) => {
    return item ? item : null;
  });

  const countRef = useRef(0);
  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    layout: {
      // padding: 20,
    },
    plugins: {
      legend: {
        labels: {
          // padding: 10,
          boxHeight: 2,
        },
        display: true,
        position: "top",
        align: "end",
      },
      zoom: {
        pan: { enabled: true, mode: "x" },
      },
      title: {
        display: false,
        text: "2주차 마음체크업",
      },
      datalabels: {
        display: false,
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderRadius: 4,
        color: "white",
        font: {
          weight: "bold",
        },
        // formatter: Math.round,
        padding: 6,
      },
      tooltip: {
        backgroundColor: function (context) {
          let color = "#00DBB1";
          if (context.tooltipItems.length === 0) {
            return "white";
          }
          if (context.tooltipItems.length === 2) {
            color = "#017FC6";
            return color;
          }

          context.tooltipItems[0]?.dataset.label === "나"
            ? (color = "#00DBB1")
            : (color = "#017FC6");
          return color;
        },
        xAlign: "center",
        yAlign: "bottom",
        // x: 100,
        // y: 500,
        padding: {
          top: 6,
          bottom: 6,
          left: 20,
          right: 20,
        },
        bodyFont: {
          size: 12,
          weight: "bold",
        },
        // title: "aaa",
        callbacks: {
          title: () => {
            return "";
          },
          label: (context) => {
            if (context.dataset.label === "나") {
              //   context.raw;
              return null;
            } else {
              return `응답: ${results[1][context.dataIndex].total}명`;
            }
          },
          labelTextColor: () => {
            return "white";
          },
        },
        // custom: function (tooltipModel) {
        //   tooltipModel.opacity = 0;
        // },
        displayColors: false,
        enabled: (context) => {
          if (context.tooltipItems?.length > 0) {
            if (context.tooltipItems.length === 2) {
              return true;
            }
            if (context.tooltipItems[0]?.dataset.label === "나") {
              return false;
            }
            return true;
          }
          return true;
        },
      },
    },
    // elements: {
    //   line: {
    //     fill: true,
    //     tension: 0,
    //   },
    // },
    scales: {
      x: {
        offset: true, // x축 양끝 짤리는 거 옵션
        min: labels.length - 7,
        max: labels.length - 1,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          color: "#777777",
          padding: 10,
          font: {
            family: "Montserrat",
          },
        },
      },
      y: {
        offset: true, // y축 위/아래 짤리는 거 옵션
        min: 1,
        max: scale,
        ticks: {
          stepSize: 1,
          padding: 10,
          color: "#777777",
          font: {
            family: "Montserrat",
          },
        },
        grid: {
          drawBorder: false,
          lineWidth: 1,
        },
      },
    },
  };

  const [data, setData] = useState();
  useEffect(() => {
    // console.log(result);
    setData(
      results[1].length > 0
        ? {
            labels,
            datasets: [
              {
                label: "나",
                data: results[0],
                // borderColor: color,
                // backgroundColor: color,
                borderColor: "#00DBB1",
                backgroundColor: "#00DBB1",
                borderWidth: 2,
                tension: 0.4,
                pointRadius: 3,
                pointHoverRadius: 10,
                pointHoverBorderWidth: 2,
                pointHoverBackgroundColor: "white",
                pointBackgroundColor: "white",
                pointBorderColor: "#00DBB1",
              },
              {
                label: "교육생 전체",
                data: results[1].map((i) => i.score),
                // borderColor: color,
                // backgroundColor: color,
                borderColor: "#017FC6",
                backgroundColor: "#017FC6",
                borderWidth: 2,
                tension: 0.4,
                pointRadius: 3,
                pointHoverRadius: 10,
                pointHoverBorderWidth: 2,
                pointHoverBackgroundColor: "white",
                pointBackgroundColor: "white",
                pointBorderColor: "#017FC6",
              },
            ],
          }
        : {
            labels,
            datasets: [
              {
                label: "나",
                data: results[0],
                // borderColor: color,
                // backgroundColor: color,
                borderColor: "#00DBB1",
                backgroundColor: "#00DBB1",
                borderWidth: 2,
                tension: 0.4,
                pointRadius: 3,
                pointHoverRadius: 10,
                pointHoverBorderWidth: 2,
                pointHoverBackgroundColor: "white",
                pointBackgroundColor: "white",
                pointBorderColor: "#00DBB1",
              },
            ],
          }
    );
  }, [results]);
  // useEffect(() => {
  //   if (countRef.current === 0) {
  //     if (result.length === 1) {
  //       const obj = {
  //         ...data,
  //         datasets: [data.datasets[1]],
  //       };
  //       console.log(obj);
  //       console.log(result[0].slice(1, 7));
  //       obj.datasets[0].data = result[0].slice(1, 7);
  //       console.log(obj);
  //       setData(obj);
  //     }
  //     countRef.current += 1;
  //   }
  //   // setData();
  // }, []);

  return (
    <Container>
      {data && (
        <Line options={options} data={data} height={isMobile ? 220 : 325} />
      )}
    </Container>
  );
}
