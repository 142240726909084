const convertPermavac = (data) => {
  let value = "";
  switch (data) {
    case "engagement":
      value = "몰입";
      break;
    case "autonomy":
      value = "자율성";
      break;
    case "competence":
      value = "유능감";
      break;
    case "achievement":
      value = "성취";
      break;
    case "relationship":
      value = "관계";
      break;
    case "positive_emotions":
      value = "긍정정서";
      break;
    case "meaning":
      value = "의미";
      break;
    case "vitality":
      value = "활력";
      break;
    default:
      break;
  }
  return value;
};
export default convertPermavac;
