import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import FlexColumn from "../../components/FlexColumn";
import useAuth from "../../hooks/useAuth";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 1.88rem;
`;

const Logo = styled.img`
  width: 6.25rem;
  height: 1.13188rem;
  position: absolute;
`;

const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;

  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const Description = styled.p`
  text-align: center;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const fill = keyframes`
    0% {
      width: 0%;
    }
    
    100% {
      width: 100%;
    }
`;

const AnimatedText = styled.p`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  position: relative;
  color: ${({ theme }) => theme.grayScaleColor.gray20};
  &:before {
    content: attr(data-hover);
  }
  &:after {
    content: attr(data-hover);
    position: absolute;
    color: ${({ theme }) => theme.primary.normal};
    left: 0;
    width: 0;
    overflow: hidden;
    white-space: nowrap;
    animation: ${fill} 2s linear infinite;
  }
`;

const rotate = keyframes`
    0% {
      transform: rotate(0deg);
    }
    
    100% {
      transform: rotate(360deg);
    }
  `;

const grow = keyframes`
  0%, 100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
`;
const Svg = styled.svg``;

const Path = styled.path`
  transform-origin: center;
  transform-box: fill-box;
  animation: ${rotate} 10s linear infinite;
`;

const ConfettiPath = styled.path`
  transform-box: fill-box;
  animation: ${grow} 2.5s linear infinite;
  animation-delay: ${({ index }) =>
    index === 1 ? "1s" : index === 2 ? "0.5s" : "0.2s"};
`;
const Checkmark = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="89"
      viewBox="0 0 140 89"
      fill="none"
    >
      <Path
        d="M62.5104 3.36335C64.1015 1.57329 66.8985 1.57329 68.4896 3.36335L74.5314 10.1604C75.493 11.2422 76.964 11.7201 78.3777 11.4101L87.2608 9.46249C89.6003 8.94956 91.8631 10.5936 92.0982 12.977L92.9909 22.0272C93.133 23.4676 94.0421 24.7188 95.368 25.2991L103.699 28.9448C105.894 29.9049 106.758 32.5649 105.547 34.6314L100.95 42.4779C100.218 43.7267 100.218 45.2733 100.95 46.5221L105.547 54.3686C106.758 56.4351 105.894 59.0951 103.699 60.0552L95.368 63.7009C94.0421 64.2812 93.133 65.5324 92.9909 66.9728L92.0982 76.023C91.8631 78.4064 89.6003 80.0504 87.2608 79.5375L78.3777 77.5899C76.964 77.2799 75.493 77.7578 74.5314 78.8396L68.4896 85.6367C66.8985 87.4267 64.1015 87.4267 62.5104 85.6367L56.4686 78.8396C55.507 77.7578 54.036 77.2799 52.6223 77.5899L43.7392 79.5375C41.3997 80.0504 39.1369 78.4064 38.9018 76.023L38.0091 66.9728C37.867 65.5324 36.9579 64.2812 35.632 63.7009L27.3006 60.0552C25.1064 59.0951 24.2421 56.4351 25.4529 54.3686L30.0502 46.5221C30.7819 45.2733 30.7819 43.7267 30.0502 42.4779L25.4529 34.6314C24.2421 32.5649 25.1064 29.9049 27.3006 28.9448L35.632 25.2991C36.9579 24.7188 37.867 23.4676 38.0091 22.0272L38.9018 12.977C39.1369 10.5936 41.3997 8.94956 43.7392 9.46249L52.6223 11.4101C54.036 11.7201 55.507 11.2422 56.4686 10.1604L62.5104 3.36335Z"
        fill="#00DBB1"
      />
      <path
        d="M53 44.5L60.9365 53L78 36"
        stroke="white"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ConfettiPath
        d="M130.575 2.24986C130.915 1.42349 132.085 1.42349 132.425 2.24987L133.818 5.63771C133.919 5.88472 134.115 6.08085 134.362 6.18239L137.75 7.5751C138.577 7.91482 138.577 9.08518 137.75 9.4249L134.362 10.8176C134.115 10.9192 133.919 11.1153 133.818 11.3623L132.425 14.7501C132.085 15.5765 130.915 15.5765 130.575 14.7501L129.182 11.3623C129.081 11.1153 128.885 10.9192 128.638 10.8176L125.25 9.4249C124.423 9.08518 124.423 7.91482 125.25 7.5751L128.638 6.18239C128.885 6.08085 129.081 5.88472 129.182 5.63771L130.575 2.24986Z"
        fill="#00DBB1"
      />
      <ConfettiPath
        index={1}
        d="M5.22253 78.675C5.32445 78.427 5.67555 78.427 5.77747 78.675L7.0547 81.7819C7.08516 81.856 7.144 81.9148 7.2181 81.9453L10.325 83.2225C10.573 83.3244 10.573 83.6756 10.325 83.7775L7.2181 85.0547C7.144 85.0852 7.08516 85.144 7.0547 85.2181L5.77747 88.325C5.67555 88.573 5.32445 88.573 5.22253 88.325L3.9453 85.2181C3.91484 85.144 3.856 85.0852 3.7819 85.0547L0.674959 83.7775C0.427046 83.6756 0.427046 83.3244 0.67496 83.2225L3.7819 81.9453C3.856 81.9148 3.91484 81.856 3.9453 81.7819L5.22253 78.675Z"
        fill="#00DBB1"
      />
      <ConfettiPath
        index={2}
        d="M117.223 78.675C117.324 78.427 117.676 78.427 117.777 78.675L118.181 79.6559C118.211 79.73 118.27 79.7888 118.344 79.8193L119.325 80.2225C119.573 80.3244 119.573 80.6756 119.325 80.7775L118.344 81.1807C118.27 81.2112 118.211 81.27 118.181 81.3441L117.777 82.325C117.676 82.573 117.324 82.573 117.223 82.325L116.819 81.3441C116.789 81.27 116.73 81.2112 116.656 81.1807L115.675 80.7775C115.427 80.6756 115.427 80.3244 115.675 80.2225L116.656 79.8193C116.73 79.7888 116.789 79.73 116.819 79.6559L117.223 78.675Z"
        fill="#00DBB1"
      />
    </Svg>
  );
};

const Welcome = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate("/onboarding");
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <Container>
      <Helmet>
        <meta name="theme-color" content="#FFFFFF" />
      </Helmet>
      <Logo
        src={`${process.env.PUBLIC_URL}/assets/logo/maumfit_logo.png`}
        alt="logo"
      />
      <FlexColumn
        style={{
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          gap: "3.25rem",
        }}
      >
        <Text>
          {auth.user.name} 님<br />
          맘핏 멤버십 가입을 환영합니다!
        </Text>
        <FlexColumn style={{ alignItems: "center", gap: "0.88rem" }}>
          <Checkmark />
          <AnimatedText data-hover={"LOADING ···"}></AnimatedText>
        </FlexColumn>
        <Description>
          지금부터 맘핏에 대해
          <br />
          하나씩 알아가볼까요? 🙌
        </Description>
      </FlexColumn>
    </Container>
  );
};

export default Welcome;
