import styled from "styled-components";

const Container = styled.form`
  width: 30rem;
  height: 37.5rem;
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 1.88rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4.69rem;
`;

const Form = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Form;
