import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";

const Button = styled.button`
  width: 100%;
  background: #212121;
  text-align: center;
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.03125rem;
  padding: 1.38rem 0;
`;

const EventPage = () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const url =
    "https://s3.ap-northeast-2.amazonaws.com/maum.fit-assets/events/cash_event.png";

  useEffect(() => {
    const img = new Image();
    img.src = url;

    img.onload = () => setIsImageLoaded(true);
  }, []);

  if (!isImageLoaded) {
    return <div />;
  }
  return (
    <div>
      <img style={{ width }} src={url} />
      <Button onClick={() => navigate("/mypage/reward")}>
        지금 바로 환급 신청하기
      </Button>
    </div>
  );
};

export default EventPage;
