import styled from "styled-components";
import Heading2 from "./components/Heading2";
import Heading1 from "./components/Heading1";
import Heading3 from "./components/Heading3";
import Button from "./components/Button";
import useAuth from "../../../hooks/useAuth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  getDate,
  getThisWeekFriday,
  getThisWeekMonday,
} from "../../../utils/date";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useMemo, useState } from "react";
import useCountdown from "../../../hooks/useCountdown";
import Countdown from "./components/Countdown";
import { useMediaQuery } from "react-responsive";
import { MOBILE } from "../../../Constants";
import { isAuthEmpty } from "../../../utils/isAuthEmpty";
import axios from "../../../api/axios";
import { Helmet } from "react-helmet";

const Container = styled.div`
  height: calc(100vh - 4.05rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 425px) {
    height: auto;
  }
`;

const TopSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  @media (max-width: 425px) {
    padding: 0 1.56rem;
  }
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 400;
  margin-top: 0.56rem;
  @media (max-width: 425px) {
    text-align: center;
    line-height: 1.56rem;
    margin-top: 1rem;
  }
`;

const SurveyInfoContainer = styled.div`
  border-radius: 3.13rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  display: flex;
  font-size: 1rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  padding: 0.31rem 1.44rem;
  font-weight: 400;
  align-items: center;
  margin-top: 1.44rem;
  line-height: 2rem;
  @media (max-width: 425px) {
    margin-top: 1.38rem;
  }
`;
const SurveyInfoSeparator = styled.div`
  width: 1px;
  height: 1rem;
  background: ${({ theme }) => theme.grayScaleColor.gray30};
  margin: 0 0.75rem;
`;

const QuestionCountText = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.grayScaleColor.white};
  padding: 0.56rem 1.25rem;
  background: ${({ theme }) => theme.primary.normal};
  border-radius: 3.13rem;
  margin-top: 0.81rem;
`;

const BottomView = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.grayScaleColor.white};
  height: 14.69rem;
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: 1.88rem 1.88rem 0.88rem 1.88rem;
  @media (max-width: 425px) {
    height: 18.5rem;
    padding: 1.88rem 1.56rem;
  }
`;
const Image = styled.img`
  width: 34.5rem;
  height: auto;
  position: absolute;
  bottom: 11.8rem;
  z-index: 0;

  @media screen and (max-height: 700px) {
    width: 30rem;
    height: auto;
    display: none;
  }
  @media screen and (min-height: 780px) {
    width: 40rem;
    height: auto;
    bottom: 11.4rem;
  }
  @media screen and (min-height: 900px) {
    width: 45rem;
    height: auto;
    bottom: 11rem;
  }
  @media screen and (min-height: 1000px) {
    width: 50rem;
    bottom: 10.5rem;
    height: auto;
  }
  @media screen and (min-height: 1200px) {
    width: 60rem;
    bottom: 10rem;
    height: auto;
  }
  @media (max-width: 425px) {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    bottom: -2rem;
  }
`;

const Tips = styled.span`
  font-size: 1rem;
  line-height: 1.63rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  letter-spacing: -0.5px;
  padding: 0.38rem 1.25rem;
  background: ${({ theme }) => theme.primary.light};
  border-radius: 3.13rem;
  font-weight: 500;
  margin-top: 2.25rem;
  margin-bottom: 0.94rem;
`;

const Description = styled.div`
  font-size: 0.88rem;
  line-height: 1.25rem;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  text-align: center;
  margin-top: 1.88rem;
  border-top: 1px solid ${({ theme }) => theme.grayScaleColor.gray10};
  width: 100%;
  padding-top: 0.94rem;
  @media (max-width: 425px) {
    border-top: none;
    padding: 0;
    line-height: 1.38rem;
  }
`;

const HelpText = styled.span`
  font-size: 0.88rem;
  font-weight: 400;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  line-height: 1.31rem;
  letter-spacing: -0.5px;
  margin-bottom: 0.75rem;
`;
const MainForSale = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [survey, setSurvey] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [countDownDate, setCountDownDate] = useState(new Date().getTime());
  const [isSurveyStart, setIsSurveyStart] = useState();
  const [targetDate, setTargetDate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [weekStartDay, setWeekStartDay] = useState(
    getThisWeekMonday(new Date(), "M. D (ddd)")
  );
  const [weekEndDay, setWeekEndDay] = useState(
    getThisWeekFriday(new Date(), "M. D (ddd)")
  );
  const isMobile = useMediaQuery({
    maxWidth: MOBILE,
  });
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const navigate = useNavigate();

  const handleClick = () => {
    if (isComplete) {
      navigate("result", { state: { surveyId: survey._id } });
    } else {
      navigate("remind", { state: { id } });
    }
  };

  const VIBE = 100;
  const CASH = 1000;
  // auth.user.checkupInARow < 4 ? (auth.user.checkupInARow + 1) * 200 : 1000;

  const TITLES = isAuthEmpty(auth)
    ? [
        `쉼 없이 달려온 40시간, 1분 안에 점검해 볼까요?`,
        `이번 주도 정신없이 바쁘셨죠? 잠시 돌아보며 잘 마무리해봐요.`,
      ]
    : [
        `${auth.user.name} 님, 이번 주 직장에서 어땠나요?`,
        `${auth.user.name} 님, 이번 주를 잠깐 돌아볼까요?`,
        `쉼 없이 달려온 40시간, 1분 안에 점검해 볼까요?`,
        `이번 주도 정신없이 바쁘셨죠? 잠시 돌아보며 잘 마무리해봐요.`,
        `이번 주, ${auth.user.name} 님의 직장 생활 어떠셨나요?`,
        `${auth.user.name} 님, 벌써 한 주를 마무리 할 시간이에요!`,
        `정신없이 바쁜 와중에도 한 주를 돌아보는 나, 제법 멋지잖아?!😎`,
        // `${auth.user.name} 님, 드디어 금요일이에요! 1분 마음 체크업으로 마무리 잘 해볼까요?`,
      ];

  const getTitle = useMemo(
    () => TITLES[Math.floor(Math.random() * TITLES.length)],
    []
  );

  useEffect(() => {
    const fetchTestCheckup = async () => {
      const response = await axios.get(`/checkup-v2/survey/test?type=test`);
      setSurvey(response.data.survey);
      setTargetDate(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 1));
      setIsSurveyStart(true);
      setIsLoading(false);
    };
    const fetch = async () => {
      const response = await axiosPrivate.get(`/checkup-v2/survey`);
      // 체크업 기간인 경우
      if (response.data.ok) {
        if (!response.data.result) {
          // 체크업을 안한 경우
          setIsComplete(false);
        } else {
          // 체크업을 한 경우
          setIsComplete(true);
        }

        setTargetDate(response.data.survey.closeAt);
        setIsSurveyStart(true);
      } else {
        // 체크업 기간이 아닌 경우

        if (!response.data.result) {
          setDisableButton(true);
          setIsComplete(false);
        } else {
          setIsComplete(true);
        }
        // setTargetDate(
        //   new Date(
        //     new Date(response.data.survey.closeAt).setDate(
        //       new Date(response.data.survey.closeAt).getDate() + 6
        //     )
        //   )
        // );
        //
        // 금요일 오전 10시 ~ 12시 사이에 시도 시, 즉, 이번주 체크업이 생성됐을 떄.
        if (
          new Date().getTime() < new Date(response.data.survey.openAt).getTime()
        ) {
          setTargetDate(new Date(response.data.survey.openAt));
        }

        // 이번주 체크업이 아직 생성되지 않았을 때.
        if (
          new Date().getTime() >
          new Date(response.data.survey.closeAt).getTime()
        ) {
          setTargetDate(
            new Date(
              new Date(response.data.survey.closeAt).setDate(
                new Date(response.data.survey.closeAt).getDate() + 6
              )
            )
          );
        }

        setIsSurveyStart(false);
      }

      setSurvey(response.data.survey);
      setWeekStartDay(getDate(response.data.survey.weekStartAt, "M. D (ddd)"));
      setWeekEndDay(getDate(response.data.survey.weekEndAt, "M. D (ddd)"));
      setIsLoading(false);
      // if (!response.data.ok) {
      //   // 1. 체크업 기간이 아닐때
      //   // 2. 이미 체크업을 완료한 경우
      //   if (response.data.result) {
      //     setIsComplete(true);
      //     setIsSurveyStart(true);
      //   } else {
      //     setIsComplete(false);
      //     setIsSurveyStart(false);
      //   }
      //   if (
      //     new Date().getTime() >
      //     new Date(response.data.survey.closeAt).getTime()
      //   ) {
      //     // 시간이 지난 경우,
      //     // 다음 체크업이 생성되지 않았을 때.
      //     // 마지막 체크업 종료일 + 6일
      //     setTargetDate(
      //       new Date(
      //         new Date(response.data.survey.closeAt).setDate(
      //           new Date(response.data.survey.closeAt).getDate() + 6
      //         )
      //       )
      //     );
      //     setIsComplete(true);
      //     setIsSurveyStart(false);
      //   } else {
      //     // 시간이 지나지 않았고,
      //     // 이미 체크업을 한 경우
      //     if (response.data.result) {
      //       setIsComplete(true);
      //       setIsSurveyStart(true);
      //       setTargetDate(
      //         new Date(
      //           new Date(response.data.survey.closeAt).setDate(
      //             new Date(response.data.survey.closeAt).getDate() + 6
      //           )
      //         )
      //       );
      //     } else {
      //       // 다음 체크업이 생성되어 있는 경우.
      //       setTargetDate(response.data.survey.openAt);
      //       setIsSurveyStart(false);
      //     }
      //   }
      // } else {
      //   const result = await axiosPrivate.get(
      //     `/checkup-v2/result?surveyId=${response.data.survey._id}`
      //   );
      //   if (result.data.ok) {
      //     setIsComplete(true);
      //   }
      //   setTargetDate(response.data.survey.closeAt);
      //   setIsSurveyStart(true);
      // }
      // setSurvey(response.data.survey);
      // setIsLoading(false);
    };

    if (
      location.pathname.includes("demo") ||
      location.pathname.includes("test")
    ) {
      fetchTestCheckup();
    } else {
      fetch();
    }
  }, []);

  useEffect(() => {
    // console.log(isSurveyStart);
    const interval = setInterval(() => {
      setCountDownDate(new Date(targetDate).getTime() - new Date().getTime());
      if (countDownDate < 1000 && countDownDate > 0) {
        if (!isSurveyStart) {
          // 체크업이 시작되면 targetDate을 체크업 종료일로 설정
          setTargetDate(survey.closeAt);
        } else {
          // 체크업이 종료되면 targetDate을 체크업 종료일 + 6일로 설정
          setTargetDate(
            new Date(new Date().setDate(new Date(survey.closeAt).getDate() + 6))
          );
        }
        setIsSurveyStart((prev) => !prev);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countDownDate]);

  if (isLoading) {
    return null;
  }
  return (
    <Container>
      <Helmet>
        <title>맘핏 | 마음체크업 인트로</title>
      </Helmet>
      <TopSection>
        <Heading2 style={{ marginTop: "2.5rem" }}>마음 체크업</Heading2>
        <Heading1 style={{ marginTop: isMobile ? "0.63rem" : "0.44rem" }}>
          내 마음을 잠시 돌아볼까요?
        </Heading1>
        {/* <Heading3 style={{ marginTop: isMobile ? "0.63rem" : "0.44rem" }}>
          {weekStartDay} ~ {weekEndDay}
        </Heading3> */}
        {isMobile ? (
          <Text>
            최근 나의 일상을 돌아보며
            <br /> 나의 마음을 기록해보세요.
          </Text>
        ) : (
          <Text>최근 나의 일상을 돌아보며 나의 마음을 기록해보세요.</Text>
        )}

        <SurveyInfoContainer>
          11문항{" "}
          {/* <img
            src={`${process.env.PUBLIC_URL}/assets/icons/vibe_icon.png`}
            width={"12px"}
            height={"10px"}
            style={{ marginRight: "6px" }}
            alt={"vibe"}
          />{" "}
          {VIBE.toLocaleString()} <SurveyInfoSeparator />{" "} */}
          {/* <img
            src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
            width={"17px"}
            height={"17px"}
            style={{ marginRight: "6px" }}
            alt={"point"}
          />{" "}
          {!isAuthEmpty(auth) && auth.user.isFirstCheckup
            ? CASH.toLocaleString()
            : !isAuthEmpty(auth) && auth.user.lastCheckupPoint === 1000
            ? CASH.toLocaleString()
            : !isAuthEmpty(auth) && auth.user.checkupInARow + 1 < 5
            ? (auth.user.checkupInARow + 1) * 200
            : CASH.toLocaleString()} */}
        </SurveyInfoContainer>
        {/* <QuestionCountText>11문항</QuestionCountText> */}
      </TopSection>
      <Image
        src={`${process.env.PUBLIC_URL}/assets/checkup/index_motion.gif`}
      />
      <BottomView>
        {/* <Tips>안심하세요🙏</Tips> */}
        {/* {isComplete && isSurveyStart ? (
          <>
            <HelpText>
              ✅{" "}
              {`${new Date(survey.createdAt).getMonth() + 1}월 ${new Date(
                survey.createdAt
              ).getDate()}일`}
              에 체크업을 완료했어요
            </HelpText>
          </>
        ) : (
          <Countdown
            targetDate={targetDate}
            start={isSurveyStart}
            style={{ marginBottom: "1.5rem" }}
          />
        )} */}

        <Button
          disabled={disableButton}
          disabledColor={"#eeeeee"}
          onClick={handleClick}
          style={{ width: isMobile ? "100%" : "19.88rem" }}
        >
          {!isComplete
            ? "눌러서 시작하기"
            : isSurveyStart
            ? "눌러서 결과보기"
            : "지난 결과 확인하기"}
        </Button>
        {isMobile ? (
          <Description>
            마음 체크업 응답 내용은{" "}
            <span style={{ fontWeight: 700 }}>
              다른 사람에게 공유되지 않습니다.
            </span>
            <br />
            다만 응답 그룹의 평균값 및 마음 나누기 응답(선택 항목)은
            <br />
            익명으로 활용될 수 있습니다.
          </Description>
        ) : (
          <Description>
            마음 체크업 응답 내용은{" "}
            <span style={{ fontWeight: 700 }}>
              다른 사람에게 공유되지 않습니다.
            </span>
            <br />
            다만 응답 그룹의 평균값 및 마음 나누기 응답(선택 항목)은 익명으로
            활용될 수 있습니다.
          </Description>
        )}
      </BottomView>
    </Container>
  );
};

export default MainForSale;
