import styled from "styled-components";
import Button from "./Button";

const Container = styled.div`
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background: white;
  width: 100%;
  height: 369px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: space-evenly;
`;

const Title = styled.h3`
  font-size: 1.25rem;
  color: #111;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: center;
  margin-bottom: 20px;
`;

const CheckContainer = styled.ul`
  margin-bottom: 36px;
`;
const CheckItem = styled.li`
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.span`
  font-size: 1.13rem;
  font-weight: 500;
  color: ${({ theme, selected }) =>
    selected ? "#111" : theme.grayScaleColor.gray30};
  line-height: 26px;
  letter-spacing: -0.04em;
`;

const Circle = styled.div`
  width: 1.25rem;
  height: 1.25rem;

  border-radius: 50%;
  background: ${({ theme, selected }) =>
    selected ? theme.primary.normal : theme.grayScaleColor.gray20};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.grayScaleColor.gray20};
`;

const CheckMark = () => {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.26074 3.55272L4.94552 7.51045L10.268 1.36914"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const BottomSheet = ({ items, selected, setSelected, closeHandler }) => {
  const handleClick = (e, item) => {
    setSelected(item);
  };
  return (
    <Container>
      <Title>어떤 순서로 보고 싶으신가요?</Title>
      <CheckContainer>
        {items.map((item, index) => (
          <div key={index}>
            <CheckItem key={index} onClick={(e) => handleClick(e, item)}>
              <Text selected={item.label === selected.label}>{item.label}</Text>
              <Circle selected={item.label === selected.label}>
                <CheckMark />
              </Circle>
            </CheckItem>
            {index !== items.length - 1 && <Divider />}
          </div>
        ))}
      </CheckContainer>
      <Button text={"선택 완료"} clickHandler={closeHandler} />
    </Container>
  );
};

export default BottomSheet;
