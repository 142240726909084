import styled, { keyframes } from "styled-components";

const checkmark = keyframes`
0% {
    opacity: 0;
    transform: scale(0);
  }
  10%, 50%, 100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Container = styled.div`
  position: relative;
  padding: 30px;
  animation: ${checkmark} 5.6s cubic-bezier(0.42, 0, 0.275, 1.155) both;
  margin-top: 1.25rem;
`;

const grow = keyframes`
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
`;

const Cash = styled.svg`
  position: absolute;
  animation: ${grow} 1.4s cubic-bezier(0.42, 0, 0.275, 1.155) both infinite;

  &:nth-child(1) {
    width: 18px;
    height: 18px;
    left: 12px;
    top: 26px;
  }
  &:nth-child(2) {
    width: 16px;
    height: 16px;
    left: 108px;
    top: 84px;
  }
  &:nth-child(3) {
    width: 12px;
    height: 12px;
    left: 92px;
    top: 12px;
  }

  &:nth-child(1) {
    animation-delay: 0.7s;
  }
  &:nth-child(2) {
    animation-delay: 1.2s;
  }
  &:nth-child(3) {
    animation-delay: 1.8s;
  }
`;

const CashConfetti = () => {
  return (
    <Container class="checkmark">
      <Cash
        xmlns="http://www.w3.org/2000/svg"
        class="confetti"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
      >
        <path
          d="M6.5751 1.24986C6.91482 0.423486 8.08518 0.423488 8.4249 1.24987L9.81761 4.63771C9.91915 4.88472 10.1153 5.08085 10.3623 5.18239L13.7501 6.5751C14.5765 6.91482 14.5765 8.08518 13.7501 8.4249L10.3623 9.81761C10.1153 9.91915 9.91915 10.1153 9.81761 10.3623L8.4249 13.7501C8.08518 14.5765 6.91482 14.5765 6.5751 13.7501L5.18239 10.3623C5.08085 10.1153 4.88472 9.91915 4.63771 9.81761L1.24986 8.4249C0.423486 8.08518 0.423488 6.91482 1.24987 6.5751L4.63771 5.18239C4.88472 5.08085 5.08085 4.88472 5.18239 4.63771L6.5751 1.24986Z"
          fill="#FFE05D"
        />
      </Cash>
      <Cash
        xmlns="http://www.w3.org/2000/svg"
        class="confetti"
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
      >
        <path
          d="M3.53755 1.12493C3.70741 0.711745 4.29259 0.711744 4.46245 1.12493L5.08597 2.64169C5.13674 2.76519 5.23481 2.86326 5.35831 2.91403L6.87507 3.53755C7.28826 3.70741 7.28826 4.29259 6.87507 4.46245L5.35831 5.08597C5.23481 5.13674 5.13674 5.23481 5.08597 5.35831L4.46245 6.87507C4.29259 7.28826 3.70741 7.28826 3.53755 6.87507L2.91403 5.35831C2.86326 5.23481 2.76519 5.13674 2.64169 5.08597L1.12493 4.46245C0.711745 4.29259 0.711744 3.70741 1.12493 3.53755L2.64169 2.91403C2.76519 2.86326 2.86326 2.76519 2.91403 2.64169L3.53755 1.12493Z"
          fill="#FFE05D"
        />
      </Cash>
      <Cash
        xmlns="http://www.w3.org/2000/svg"
        class="confetti"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
      >
        <path
          d="M4.0751 1.24987C4.41482 0.423488 5.58518 0.423489 5.9249 1.24987L6.58929 2.86603C6.69083 3.11304 6.88696 3.30917 7.13397 3.41071L8.75013 4.0751C9.57651 4.41482 9.57651 5.58518 8.75013 5.9249L7.13397 6.58929C6.88696 6.69083 6.69083 6.88696 6.58929 7.13397L5.9249 8.75013C5.58518 9.57651 4.41482 9.57651 4.0751 8.75013L3.41071 7.13397C3.30917 6.88696 3.11304 6.69083 2.86603 6.58929L1.24987 5.9249C0.423488 5.58518 0.423489 4.41482 1.24987 4.0751L2.86603 3.41071C3.11304 3.30917 3.30917 3.11304 3.41071 2.86603L4.0751 1.24987Z"
          fill="#FFE05D"
        />
      </Cash>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="63"
        height="63"
        viewBox="0 0 63 63"
        fill="none"
      >
        <circle cx="32.6673" cy="30.3333" r="30.3333" fill="#FFE05D" />
        <path
          d="M33.1102 42C31.3882 42 29.7891 41.7099 28.3131 41.1298C26.8371 40.5282 25.5399 39.701 24.4217 38.6482C23.3259 37.5739 22.476 36.3278 21.8722 34.9097C21.2907 33.4917 21 31.9662 21 30.3333C21 28.7004 21.2907 27.1749 21.8722 25.7569C22.476 24.3388 23.3259 23.1034 24.4217 22.0506C25.5399 20.9978 26.8259 20.1706 28.2796 19.569C29.7556 18.9674 31.3658 18.6666 33.1102 18.6666C34.9888 18.6666 36.6438 18.9674 38.0751 19.569C39.5064 20.1491 40.77 20.9548 41.8658 21.9861L38.7796 24.9512C38.131 24.2636 37.3371 23.7265 36.3978 23.3397C35.4585 22.953 34.3626 22.7596 33.1102 22.7596C32.0144 22.7596 31.008 22.9423 30.0911 23.3075C29.1965 23.6513 28.4137 24.1669 27.7428 24.8545C27.0719 25.5205 26.5575 26.3262 26.1997 27.2716C25.8419 28.1955 25.6629 29.216 25.6629 30.3333C25.6629 31.472 25.8419 32.5033 26.1997 33.4272C26.5575 34.3511 27.0719 35.1568 27.7428 35.8443C28.4137 36.5104 29.1965 37.026 30.0911 37.3913C31.008 37.7566 32.0144 37.9392 33.1102 37.9392C34.4297 37.9392 35.5591 37.7458 36.4984 37.3591C37.4601 36.9723 38.2652 36.4244 38.9137 35.7154L42 38.6804C40.9042 39.7332 39.6294 40.5497 38.1757 41.1298C36.722 41.7099 35.0335 42 33.1102 42Z"
          fill="#FAB203"
        />
      </svg>
    </Container>
  );
};

export default CashConfetti;
