import {
  USER_ADMIN,
  USER_LEADER,
  USER_MANAGER,
  USER_NORMAL,
} from "../Constants";

const convertRole = (role) => {
  switch (role) {
    case USER_NORMAL:
      return "구성원";
    case USER_LEADER:
      return "리더";
    case USER_MANAGER:
      return "관리자";
    case USER_ADMIN:
      return "맘핏 관리자";
    default:
      return "구성원";
  }
};

export default convertRole;
