import styled from "styled-components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import BackgroundContainer from "../../components/BackgroundContainer";
import Form from "../../components/Form";
import Logo from "../../components/Logo";
import LabelInput from "../../components/LabelInput";
import SubmitButton from "../../components/SubmitButton";
import ErrorText from "../../components/ErrorText";
import BackgroundSymbol from "../../components/BackgroundSymbol";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  width: 100%;
  font-size: 0.94rem;
  text-align: center;
  line-height: 1.44rem;
  letter-spacing: -0.04em;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const Button = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
  width: 20.63rem;
  height: 3.75rem;
  border-radius: 0.31rem;
  font-size: 1.13rem;
  font-weight: 500;
  margin-top: 7.75rem;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.primary.hover};
  }
  transition: 0.3s;
`;

const FindPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const { auth } = useAuth();
  const [isMounted, setIsMounted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e, email) => {
    e.preventDefault();
    try {
      await axios.post(`/find-password`, {
        email,
      });
      setIsSubmitted(true);
    } catch (err) {
      // console.log(err.response.data.msg);
      setError(err.response.data.msg);
    }
  };

  const handleGoLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (email.length > 0) {
      setDisabled(false);
      setError("");
    } else {
      setDisabled(true);
    }
  }, [email]);

  useEffect(() => {
    if (auth?.user?.email) {
      return navigate(from, { replace: true });
    }

    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <BackgroundContainer>
      <Wrapper>
        <Form>
          <Logo style={{ marginTop: "3.94rem", marginBottom: "2.5rem" }} />
          {isSubmitted ? (
            <>
              <Text style={{ marginTop: "5.81rem" }}>
                새로운 비밀번호 설정을 위해
                <br /> 입력하신 메일 주소로 링크를 보내 드렸어요! 💌
              </Text>
              <Text style={{ marginTop: "2rem" }}>
                비밀번호 변경을 완료하셨다면
                <br /> 아래 버튼을 클릭해 로그인을 완료해 주세요.
              </Text>
              <Button onClick={handleGoLogin}>로그인 화면으로 이동하기</Button>
            </>
          ) : (
            <>
              <Text style={{ marginBottom: "2.5rem" }}>
                이런, 비밀번호를 잊어버리셨군요😢 <br />새 비밀번호를 설정하기
                위해 이메일 주소를 입력해 주세요.
              </Text>
              <LabelInput
                htmlFor={"회사 이메일"}
                type={"text"}
                error={error}
                onChange={setEmail}
              />
              {error && (
                <ErrorText style={{ marginTop: "0.63rem" }}>{error}</ErrorText>
              )}
              <SubmitButton
                value="확인 이메일 보내기"
                style={{ marginTop: "10.38rem" }}
                disabled={disabled}
                onClick={(e) => handleSubmit(e, email)}
              />
            </>
          )}
        </Form>
      </Wrapper>
      {/* <BackgroundSymbol /> */}
    </BackgroundContainer>
  );
};

export default FindPassword;
