import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios, { axiosPrivate } from "../../../api/axios";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import { MOBILE } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getDate, getToday } from "../../../utils/date";
import { getThisWeekFriday, getThisWeekMonday } from "../../../utils/date";
import { isAuthEmpty } from "../../../utils/isAuthEmpty";
import isEndWithConsonant from "../../../utils/isEndWithConsonant";
import lineBreaker from "../../../utils/lineBreaker";
import BarChart from "./BarChart";
import Box from "./components/Box";
import DoughnutChart from "./components/Doughnut";
import Heading1 from "./components/Heading1";
import Heading2 from "./components/Heading2";
import Heading3 from "./components/Heading3";
import Recommend from "./components/Recommend";
import ReportBadge from "./components/ReportBadge";
import ReportComment from "./components/ReportComment";
import ReportHeading2 from "./components/ReportHeading2";
import ReportHorizontalBarList from "./components/ReportHorizontalBarList";
import ReportRadarChart from "./components/ReportRadarChart";
import SectionHeading1 from "./components/SectionHeading1";
import SectionHeading2 from "./components/SectionHeading2";
import StampBox from "./components/StampBox";
import HelpModal from "./components/HelpModal";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 4.8rem;
  @media (max-width: 425px) {
    padding-bottom: 2.5rem;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 62.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 425px) {
    padding: 0 1.56rem;
  }
`;

const TipBox = styled.div`
  width: 100%;
  height: 3rem;
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 1.56rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.63rem;
  margin-top: 3.75rem;
  margin-bottom: 1.63rem;
`;

const TipText = styled.p`
  font-size: 0.94rem;
  line-height: 1.13rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  align-self: flex-start;
  margin-bottom: 3rem;
  @media (max-width: 425px) {
    margin-bottom: 0.25rem;
    line-height: 1.5rem;
    letter-spacing: -0.5px;
  }
`;

const InfoContainer = styled.div`
  align-self: flex-start;
  margin-top: 5.13rem;
  margin-bottom: 1.75rem;
  @media (max-width: 425px) {
    margin-top: 3.25rem;
    margin-bottom: 2rem;
  }
`;

const CalendarIcon = styled.img``;
const DateText = styled.span`
  font-size: 1.13rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  font-weight: 500;
  margin-left: 0.5rem;
  @media (max-width: 425px) {
    font-size: 0.94rem;
  }
`;

const TextBox = styled.span`
  font-weight: 600;
  font-size: 0.94rem;
  color: ${({ theme }) => theme.primary.normal};
  padding: 0.25rem 0.75rem;
  min-width: 2.88rem;
  background: ${({ theme }) => theme.primary.light};
  border-radius: 0.31rem;
  // margin: 0 0.31rem;
  text-align: center;
  margin-left: 1.25rem;
  display: flex;
  align-items: center;
`;

const ItemHeading = styled.div`
  font-size: 1.13rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 600;
  line-height: 1.63rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 0.94rem;
  // padding: 2.25rem 1.88rem;
  width: 11.25rem;
  height: 11.25rem;
`;
const ItemIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  @media (max-width: 425px) {
    margin-bottom: 0.5rem;
  }
`;

const ItemText = styled.span`
  font-size: 2rem;
  color: ${({ theme }) => theme.primary.normal};
  font-weight: 600;
  line-height: 2.38rem;
`;

const ItemCurrency = styled.span`
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.primary.normal};
`;

const ItemSeparator = styled.div`
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.grayScaleColor.gray20};
`;

const SectionContainer = styled.div`
  padding: 3.13rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 1.25rem;
  margin-bottom: 2.5rem;
  width: 100%;
  @media (max-width: 425px) {
    margin-top: 2.63rem;
    margin-bottom: 0;
    padding: 1.56rem 1.25rem;
  }
`;

const Divider = styled.div`
  width: 100%;
  border: 1px dashed #d9d9d9;
  margin-top: 1.56rem;
  margin-bottom: 3.13rem;
`;

const Chips = styled.span`
  font-size: 0.94rem;
  color: ${({ theme }) => theme.primary.normal};
  padding: 0.25rem 0.63rem;
  border: 1px solid ${({ theme }) => theme.primary.normal};
  border-radius: 3.13rem;
  margin-right: 0.63rem;
`;

const Chart = styled.div`
  width: 405px;
  height: 249px;
  //   border: 1px solid black;
  @media (max-width: 425px) {
    width: 100%;
  }
`;

const Title = styled.h3`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 1.81rem;
  letter-spacing: -0.5px;
  font-weight: 600;
  margin-bottom: 0.88rem;
`;

const Descripton = styled.p`
  font-size: 0.94rem;
  line-height: 1.5rem;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 400;
  margin-bottom: 2.38rem;
  word-break: keep-all;
  @media (max-width: 425px) {
    margin-bottom: 1.56rem;
  }
`;

const RandomText = styled.div`
  font-size: 0.94rem;
  font-weight: 400;
  line-height: 1.63rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  background: ${({ theme }) => theme.primary.light};
  width: 100%;
  padding: 0.75rem 0;
  border-radius: 0.44rem;
  display: flex;
  align-items: center;
  @media (max-width: 425px) {
    padding: 1rem 0.75rem;
  }
`;

const HighlightTitle = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 1rem;
`;

const HighlightCallout = styled.p`
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  padding: 1rem 1.5rem;
  border-radius: 0.625rem;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 160%;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const BlockTitle = styled.h3`
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.8125rem; /* 131.818% */
  letter-spacing: -0.03125rem;
  @media (max-width: ${MOBILE}) {
    font-size: 1.25rem;
    line-height: 1.8125rem; /* 145% */
    letter-spacing: -0.03125rem;
  }
`;

const BlockImage = styled.img`
  width: 24rem;
  height: 21rem;
  @media (max-width: ${MOBILE}) {
    width: 100%;
    height: 10.375rem;
    margin-bottom: 1.88rem;
    object-fit: cover;
    border-radius: 0.65894rem;
  }
`;

const BlockTime = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  padding: 0.375rem 0.75rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  border-radius: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${MOBILE}) {
    font-size: 0.8125rem;
  }
`;

const BlockDescription = styled.p`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 160% */
  letter-spacing: -0.03125rem;
  @media (max-width: ${MOBILE}) {
  }
`;

const BlockButton = styled.button`
  border-radius: 0.4375rem;
  background: ${({ theme }) => theme.primary.normal};
  width: auto;
  padding: 0.94rem 3.25rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  color: ${({ theme }) => theme.grayScaleColor.white};
  border: none;
  cursor: pointer;
  @media (max-width: ${MOBILE}) {
    margin-top: 1.7rem;
    width: 100%;
    font-size: 0.9375rem;
  }
`;

const Banner = styled.div`
  width: 100%;
  position: relative;

  @media (max-width: 425px) {
    margin-top: 1.88rem;
  }
`;

const BannerImage = styled.img`
  width: 100%;
`;

const BannerButton = styled.button`
  padding: 0.56rem 1.5rem;
  border-radius: 0.31rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  background: ${({ theme }) => theme.primary.normal};
  border: none;
  font-weight: 500;
  font-size: 0.94rem;
  cursor: pointer;
  @media (max-width: 425px) {
    width: ${({ width }) => width}px;
    padding: 0.56rem 0.45rem;
    line-height: 1.13rem;
    font-size: 0.88rem;
  }
`;

const Symbol = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 1.25rem;
  margin-right: 0.63rem;
  @media (max-width: 425px) {
    width: 1rem;
    height: 1rem;
    margin-left: 0;
    margin-right: 0.5rem;
  }
`;

const Circle = styled.div`
  background: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 2.25rem */
  letter-spacing: -0.03125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.75rem;
  height: 8.75rem;
  border-radius: 50%;
  @media (max-width: 425px) {
    width: 4.5rem;
    height: 4.5rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.3125rem */
    letter-spacing: -0.0175rem;
  }
`;

const Callout = styled.div`
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.03125rem;
  color: #666;
  padding: 1.25rem 4rem;
  border-radius: 0.94rem;
  @media (max-width: ${MOBILE}) {
    font-size: 0.9375rem;
    padding: 1.25rem;
    word-break: keep-all;
  }
`;

const Badge = styled.div`
  padding: 0.62rem 1rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  border-radius: 3.125rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03125rem;
  text-align: center;
  margin-top: 0.75rem;
  display: inline-flex;
`;

const ModalButton = styled.button`
  padding: 0.5rem 0.75rem;
  border-radius: 0.4375rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  background: ${({ theme }) => theme.grayScaleColor.white};
  margin-top: 1.62rem;
  cursor: pointer;
  @media (max-width: 425px) {
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
`;

const BlockTypeImage = styled.img`
  max-width: 43.75rem;
  margin-top: 2.69rem;
  @media (max-width: 425px) {
    width: 100%;
    height: auto;
    margin-top: 2rem;
  }
`;

const ShareComment = styled.div`
  padding: 1rem 1.5rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  border-radius: 0.625rem;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: -0.03125rem;
`;

const MoreButton = styled.button`
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 3.125rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 0.75rem 1.5rem;
  align-self: start;
  margin: 0 auto;
  margin-top: 2rem;
  cursor: pointer;
`;

const ChatGPTMessage = styled.div`
  width: 100%;
  border-radius: 1.25rem;
  background: ${({ theme }) => theme.primary.light};
  padding: 1.75rem 2.25rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 175% */
  letter-spacing: -0.03125rem;
  color: #282c2c;
  position: relative;
  @media (max-width: 425px) {
    font-size: 0.9375rem;
  }
`;

const FooterLogo = styled.img`
  width: 7.0625rem;
`;

const FooterText = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.grayScaleColor.gray20};
`;

const PERMAVAC = {
  engagement: "업무에 대한 집중",
  autonomy: "주도적인 업무 수행",
  competence: "일에 대한 자신감",
  achievement: "업무를 통한 성취",
  relationship: "동료와의 소통",
  positive_emotions: "직장에서의 좋은 감정",
  meaning: "업무를 통한 보람",
  vitality: "직장에서의 활력",
};

const emotions = {
  뿌듯함: { emotion: "😎뿌듯함", word: "뿌듯함", isPositive: true },
  정신없음: { emotion: "😵정신없음", word: "정신없음", isPositive: false },
  효능감: { emotion: "🤩효능감", word: "효능감", isPositive: true },
  "낮은 자신감": {
    emotion: "😥낮은 자신감",
    word: "낮은 자신감",
    isPositive: false,
  },
  재미: { emotion: "😉재미", word: "재미", isPositive: true },
  여유: { emotion: "😀여유", word: "여유", isPositive: true },
  "분노/화": { emotion: "😡분노/화", word: "분노/화", isPositive: false },
  스트레스: { emotion: "😱스트레스", word: "스트레스", isPositive: false },
  지루함: { emotion: "🥱지루함", word: "지루함", isPositive: false },
};

const ChatGPTPolygon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="34"
      viewBox="0 0 37 34"
      fill="none"
      style={{
        position: "absolute",
        bottom: "-1.5rem",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <path
        d="M14.1699 31.5C16.0944 34.8333 20.9056 34.8333 22.8301 31.5L36.2535 8.25001C38.178 4.91668 35.7724 0.75 31.9234 0.75H5.07662C1.22762 0.75 -1.17802 4.91667 0.746479 8.25L14.1699 31.5Z"
        fill="#E8FBF7"
      />
    </svg>
  );
};

const Portal = ({ children }) => {
  const el = document.getElementById("help-words__modal");

  return ReactDOM.createPortal(children, el);
};

const ResultForSale = () => {
  const DATE_FORMAT = "M. D (ddd)";
  const CASH = 1000;
  const { auth } = useAuth();

  const { state } = useLocation();
  const location = useLocation();
  const [result, setResult] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [wellBeing, setWellBeing] = useState(0);
  const [percent, setPercent] = useState(0);
  const [averageFeeling, setAverageFeeling] = useState([]);
  const [recommend, setRecommend] = useState("");
  const [low, setLow] = useState("");
  const [high, setHigh] = useState("");
  const [averageHigh, setAverageHigh] = useState("");
  const [block, setBlock] = useState();
  const [permavacScore, setPermavacScore] = useState();
  const [shareAnswers, setShareAnswers] = useState([]);

  const [today, setToday] = useState(getToday("YYYY년 M월 D일(ddd)"));
  const [period, setPeriod] = useState({
    monday: getThisWeekMonday(new Date(), DATE_FORMAT),
    friday: getThisWeekFriday(new Date(), DATE_FORMAT),
  });
  const [isHelpClick, setIsHelpClick] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: MOBILE });

  const name = isAuthEmpty(auth) ? "김블룸" : auth.user.name;
  const permavac_score = {
    engagement: "몰입",
    autonomy: "자율성",
    competence: "유능감",
    achievement: "성취",
    relationship: "관계",
    positive_emotions: "긍정정서",
    meaning: "의미",
    vitality: "활력",
    happiness: "행복",
  };

  const FIXED = 1;

  const navigate = useNavigate();

  const onHelpClick = () => {
    setIsHelpClick((prev) => !prev);
  };
  const handleRetryClick = () => {};

  const onStartTrainingClick = (e, to, action) => {
    ReactGA.event({ category: "button_click", action });

    navigate(to);
  };

  useEffect(() => {
    if (!state?.surveyId) {
      navigate("/checkup/v2");
    }
    const fetchGPT = async () => {
      const response = await axios.get(
        `checkup-v2/recommend/test?chatGPTPrompt=${location.state.chatGPTPrompt}&surveyId=${location.state.surveyId}&weekSentence=${location.state.weekSentence}`
      );
      setRecommend(response.data.recommend);
    };
    const fetchDemo = async () => {
      const score = Number(location.state.wellBeing.toFixed(FIXED));
      const response = await axios.get(
        `/checkup-v2/result/test?score=${score}&shareId=${location.state.shareId}`
      );
      const percentage =
        response.data.percentage <= 50
          ? `상위 ${Number(response.data.percentage.toFixed(FIXED))}%`
          : `하위 ${Number(101 - response.data.percentage).toFixed(FIXED)}%`;
      // const prev = Number(response.data.prevWellBeing.toFixed(FIXED));

      // const next =
      //   prev > score
      //     ? Number(Math.max(0, score - (prev - score)).toFixed(FIXED))
      //     : Number(Math.min(100, score + score - prev).toFixed(FIXED));
      setWellBeing(score);
      setPermavacScore(response.data.permavacScore);
      setAverageHigh(response.data.highPermavac);
      setAverageFeeling(response.data.feelings);
      setShareAnswers(response.data.shareQuestionAnswers);
      // setPrevWellBeing(prev);
      // setNextWellBeing(next);
      setLow(location.state.low);
      setHigh(location.state.high);
      setPercent(percentage);
      setResult(null);

      if (true) {
        setTitle(`😉 ${name} 님, 첫 마음 체크업을 환영합니다!`);
        setDescription(
          lineBreaker(
            `이번 주 마음 웰빙 지수는 ${score}점이네요. 개인적으로 ${
              PERMAVAC[location.state.high]
            }${
              isEndWithConsonant(PERMAVAC[location.state.high]) ? "은" : "는"
            } 높았지만, ${PERMAVAC[location.state.low]}${
              isEndWithConsonant(PERMAVAC[location.state.low]) ? "이" : "가"
            } 낮은 한 주였습니다.\n\n전체적으로는 ${percentage}에 속해 있어요. ${name} 님이 마음 트레이닝을 열심히 하신다면 이번 주 보다 더 올릴 수 있을 거라 기대됩니다. 꾸준히 올라갈 ${name} 님의 마음 건강을 응원해요🙌`
          )
        );
      }
      setIsLoading(false);
    };
    fetchGPT();
    fetchDemo();
  }, []);

  if (isLoading) {
    return null;
  }
  const arr = Object.entries(location.state.answers[1]);
  const companyArr = Object.entries(permavacScore);

  // 값에 따라 내림차순으로 정렬
  arr.sort((a, b) => b[1] - a[1]);
  companyArr.sort((a, b) => b[1] - a[1]);
  const sortedPermavac = arr.map((item) => ({
    type: permavac_score[item[0]],
    value: item[1],
  }));

  const sortedCompanyPermavacScore = [];
  for (let i = 0; i < arr.length; i++) {
    const index = companyArr.findIndex((item) => item[0] === arr[i][0]);
    sortedCompanyPermavacScore.push({
      type: companyArr[index][0],
      value: companyArr[index][1],
    });
  }

  const scores = [];
  const companyScores = [];
  for (const key in permavac_score) {
    scores.push(location.state.answers[1][key]);
    companyScores.push(permavacScore[key]);
  }

  return (
    <Container>
      <Helmet>
        <title>맘핏 | 마음체크업 결과</title>
      </Helmet>
      <Wrapper>
        <Heading2 style={{ marginTop: "3.13rem" }}>마음 체크업 완료</Heading2>
        {isMobile ? (
          <Heading1 style={{ marginTop: "0.62rem", fontSize: "1.5rem" }}>
            마음 체크업이 완료 되었습니다. 🥳
          </Heading1>
        ) : (
          <Heading1 style={{ marginTop: "0.75rem" }}>
            마음 체크업이 완료 되었습니다. 🥳
          </Heading1>
        )}
        <FlexRow
          style={{ marginTop: "0.94rem", gap: "0.44rem", alignItems: "center" }}
        >
          <CalendarIcon src={`${process.env.PUBLIC_URL}/svg/calendar.svg`} />
          <Heading3 style={{ fontSize: "1rem" }}>{today}</Heading3>
        </FlexRow>

        <SectionContainer style={{ marginTop: "3rem" }}>
          <SectionHeading2
            style={{ marginBottom: "0.56rem", textAlign: "center" }}
          >
            종합 마음 웰빙
          </SectionHeading2>
          <SectionHeading1 style={{ textAlign: "center" }}>
            나의 마음 웰빙 지수는{" "}
            <span style={{ fontWeight: 700 }}>{wellBeing}점으로,</span>
            <br />
            전체 응답자 중 <span style={{ fontWeight: 700 }}>{percent}</span>에
            속해 있어요.
          </SectionHeading1>
          <Callout style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            응답 결과, 이번 주 맘핏러들은{" "}
            <span style={{ fontWeight: 700 }}>“{PERMAVAC[averageHigh]}”</span>{" "}
            영역에서 점수가 가장 높았어요.
            <br />
            나는 <span style={{ fontWeight: 700 }}>“{PERMAVAC[high]}”</span>에서
            가장 높은 점수를 받았네요.
            <br />
            아래 그래프를 통해 내가 어떤 영역에서 높고 낮았는지 한눈에
            살펴보세요!
          </Callout>
          <FlexColumn>
            <ReportRadarChart
              me={scores}
              company={companyScores}
              legend={"나"}
              compare={"전체"}
            />
            {isMobile ? (
              <FlexColumn
                style={{
                  alignItems: "center",
                  marginTop: "1.94rem",
                  paddingTop: "1.94rem",
                  borderTop: "1px solid rgba(187, 187, 187, 0.3)",
                }}
              >
                <ReportHeading2 style={{ width: "100%", alignItems: "center" }}>
                  나의 마음 웰빙과 맘핏 전체 마음 웰빙 비교
                  <ReportComment
                    style={{
                      fontWeight: 400,
                      marginTop: isMobile ? "0.25rem" : "0.56rem",
                    }}
                  >
                    * 7점 척도 기준
                  </ReportComment>
                  <ModalButton onClick={onHelpClick}>
                    🔍 용어가 궁금해요
                  </ModalButton>
                </ReportHeading2>
                <ReportHorizontalBarList
                  rows={sortedPermavac}
                  diff={sortedCompanyPermavacScore}
                />
              </FlexColumn>
            ) : (
              <FlexRow
                style={{
                  gap: "55px",
                  marginTop: "4.25rem",
                  marginBottom: "1.5rem",
                }}
              >
                <ReportHeading2 style={{ width: "12.38rem" }}>
                  <p>
                    나의 마음 웰빙과
                    <br />
                    맘핏 전체 마음 웰빙 비교
                  </p>
                  <ReportComment
                    style={{ fontWeight: 400, marginTop: "0.56rem" }}
                  >
                    * 7점 척도 기준
                  </ReportComment>
                  <ModalButton onClick={onHelpClick}>
                    🔍 용어가 궁금해요
                  </ModalButton>
                </ReportHeading2>
                <ReportHorizontalBarList
                  rows={sortedPermavac}
                  diff={sortedCompanyPermavacScore}
                />
              </FlexRow>
            )}
            {isMobile ? (
              <ReportComment
                style={{
                  marginTop: "1rem",
                  marginBottom: isMobile ? "2rem" : "3.37rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  color: "#3A3F3F",
                  fontSize: "0.56rem",
                  gap: "0.26rem",
                }}
              >
                <p style={{ fontSize: "0.56rem" }}>
                  * 전체 평균과 내 점수의 차이
                </p>
                <FlexRow style={{ alignItems: "center" }}>
                  +20% 이상
                  <ReportBadge
                    style={{
                      fontSize: "0.4rem",
                      padding: "0.1815rem 0.5445rem",
                      marginLeft: "0.25rem",
                      marginRight: "0.5rem",
                      display: "flex",
                      height: "1.3rem",
                      alignItems: "center",
                    }}
                    level={"good"}
                  >
                    대단해요
                  </ReportBadge>{" "}
                  ±20% 범위 내{" "}
                  <ReportBadge
                    style={{
                      fontSize: "0.4rem",
                      marginLeft: "0.25rem",
                      marginRight: "0.5rem",
                      padding: "0.1815rem 0.5445rem",
                      display: "flex",
                      height: "1.3rem",
                      alignItems: "center",
                    }}
                    level={"ok"}
                  >
                    괜찮아요
                  </ReportBadge>
                  -20% 미만{" "}
                  <ReportBadge
                    style={{
                      fontSize: "0.4rem",
                      marginLeft: "0.25rem",
                      padding: "0.1815rem 0.5445rem",
                      display: "flex",
                      height: "1.3rem",
                      alignItems: "center",
                    }}
                    level={"bad"}
                  >
                    걱정돼요
                  </ReportBadge>
                </FlexRow>
              </ReportComment>
            ) : (
              <ReportComment
                style={{
                  marginBottom: "3.37rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  color: "#3A3F3F",
                }}
              >
                * 전체 평균과 내 점수의 차이: +20% 초과{" "}
                <ReportBadge
                  style={{
                    fontSize: "0.63rem",
                    padding: "0.1815rem 0.5445rem",
                    marginLeft: "0.25rem",
                    marginRight: "0.5rem",
                    display: "flex",
                    height: "1.3rem",
                    alignItems: "center",
                  }}
                  level={"good"}
                >
                  대단해요
                </ReportBadge>{" "}
                ±20% 범위 내{" "}
                <ReportBadge
                  style={{
                    fontSize: "0.63rem",
                    marginLeft: "0.25rem",
                    marginRight: "0.5rem",
                    padding: "0.1815rem 0.5445rem",
                    display: "flex",
                    height: "1.3rem",
                    alignItems: "center",
                  }}
                  level={"ok"}
                >
                  괜찮아요
                </ReportBadge>
                -20% 미만{" "}
                <ReportBadge
                  style={{
                    fontSize: "0.63rem",
                    marginLeft: "0.25rem",
                    padding: "0.1815rem 0.5445rem",
                    display: "flex",
                    height: "1.3rem",
                    alignItems: "center",
                  }}
                  level={"bad"}
                >
                  걱정돼요
                </ReportBadge>
              </ReportComment>
            )}
          </FlexColumn>
          <Divider style={{ marginTop: isMobile ? "1rem" : "3rem" }} />
          <SectionHeading2
            style={{ marginBottom: "0.56rem", textAlign: "center" }}
          >
            나의 마음 상태
          </SectionHeading2>
          {isMobile ? (
            <SectionHeading1 style={{ textAlign: "center" }}>
              <span style={{ fontWeight: 700 }}>
                "{location.state.answers[0].join(", ")}"
              </span>
              <br />
              감정을 주로 경험하셨네요.
            </SectionHeading1>
          ) : (
            <SectionHeading1 style={{ textAlign: "center" }}>
              <span style={{ fontWeight: 700 }}>
                "{location.state.answers[0].join(", ")}"
              </span>{" "}
              감정을 주로 경험하셨네요.
            </SectionHeading1>
          )}
          <FlexRow
            style={{
              marginTop: isMobile ? "1rem" : "2rem",
              justifyContent: "center",
            }}
          >
            {location.state.answers[0].slice(0, 4).map((item, index) => (
              <Circle
                key={index}
                bgColor={emotions[item].isPositive ? "#E8FBF7" : "#FFEDE0"}
                color={emotions[item].isPositive ? "#00DBB1" : "#FF7E21"}
              >
                {item}
              </Circle>
            ))}
          </FlexRow>
          <FlexRow
            style={{
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: isMobile ? "1rem" : "2rem",
              }}
            >
              {location.state.answers[0].slice(4).map((item, index) => (
                <Circle
                  key={index}
                  bgColor={emotions[item].isPositive ? "#E8FBF7" : "#FFEDE0"}
                  color={emotions[item].isPositive ? "#00DBB1" : "#FF7E21"}
                >
                  {item}
                </Circle>
              ))}
            </div>
          </FlexRow>
          {/* <FlexRow
            style={{
              marginBottom: "2rem",
            }}
          >
            {location.state.answers[0].slice(8).map((item, index) => (
              <Circle
                key={index}
                bgColor={emotions[item].isPositive ? "#E8FBF7" : "#FFEDE0"}
                color={emotions[item].isPositive ? "#00DBB1" : "#FF7E21"}
              >
                {item}
              </Circle>
            ))}
          </FlexRow> */}
          {/* </div> */}

          <Callout>
            이번 주 맘핏러들은 주로{" "}
            <span style={{ fontWeight: 700 }}>
              “
              {averageFeeling
                .slice(0, 3)
                .map((item) => item[2])
                .join(", ")}
              ”
            </span>{" "}
            감정을 경험했어요.
            <br />
            그래프로 한 번 비교해 볼까요?
          </Callout>
          <FlexRow
            style={{
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <DoughnutChart result={averageFeeling} />
          </FlexRow>
          <Divider />
          <SectionHeading2
            style={{ marginBottom: "0.56rem", textAlign: "center" }}
          >
            올려봐요 마음 웰빙
          </SectionHeading2>
          <SectionHeading1 style={{ textAlign: "center" }}>
            이번 주 조금 낮았던
            <br />
            <span style={{ fontWeight: 700 }}>“{PERMAVAC[low]}”</span>
            <br />
            지금 바로 높이러 가볼까요?
          </SectionHeading1>
          <FlexColumn style={{ alignItems: "center" }}>
            <Badge>소요시간 : 1분</Badge>

            <BlockTypeImage
              src={
                isMobile
                  ? `${process.env.PUBLIC_URL}/assets/checkup/result_${low}_mobile.png`
                  : `${process.env.PUBLIC_URL}/assets/checkup/result_${low}.png`
              }
              alt={"block img"}
            />

            <BlockButton
              style={{ marginTop: "2.75rem" }}
              onClick={(e) =>
                onStartTrainingClick(
                  e,
                  `/blocks/${location.state.block.number}`,
                  "demo_training_click"
                )
              }
            >
              트레이닝 시-작! 🚀
            </BlockButton>
          </FlexColumn>
          <Divider style={{ marginTop: isMobile ? "4rem" : "5.75rem" }} />
          <SectionHeading2
            style={{ marginBottom: "0.56rem", textAlign: "center" }}
          >
            동료들과 나눈 마음
          </SectionHeading2>
          <SectionHeading1 style={{ textAlign: "center" }}>
            <span style={{ fontWeight: 600 }}>
              Q. 최근 가장 기분 좋았던 순간은 무엇이었나요?
            </span>
          </SectionHeading1>
          <FlexColumn
            style={{
              marginTop: isMobile ? "2rem" : "3rem",
              marginBottom: "4.81rem",
              gap: "0.62rem",
            }}
          >
            {isMobile ? (
              shareAnswers.slice(0, 5).map((item, index) => (
                <ShareComment key={index} style={{ textAlign: "left" }}>
                  {item.text}
                </ShareComment>
              ))
            ) : (
              <ResponsiveMasonry columnsCountBreakPoints={{ 750: 3 }}>
                <Masonry gutter="16px">
                  {shareAnswers.slice(0, 15).map((item, index) => (
                    <ShareComment key={index} style={{ textAlign: "left" }}>
                      {item.text}
                    </ShareComment>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            )}
            <MoreButton
              onClick={() => {
                navigate("/dashboard/checkup/share");
              }}
            >
              답변 더 보기 👉
            </MoreButton>
          </FlexColumn>
          <ChatGPTMessage>
            {recommend ? recommend : "당신의 마음을 분석 중입니다."}
            <ChatGPTPolygon />
          </ChatGPTMessage>
          <FlexColumn
            style={{
              alignItems: "center",
              marginTop: "3.12rem",
              gap: "0.44rem",
            }}
          >
            <FooterLogo
              src={`${process.env.PUBLIC_URL}/assets/logo/maumfit_logo.png`}
              alt={"maumfit logo"}
            />
            <FooterText>cheer with ChatGPT</FooterText>
          </FlexColumn>
          {/* <Recommend
            surveyId={state.surveyId}
            name={name}
            weekSentence={
              result ? result.result.weekSentence : location.state.weekSentence
            }
            recommendQuestion={
              result
                ? result.result.recommendQuestion
                : location.state.recommendQuestion
            }
            chatGPTPrompt={
              result
                ? result.result.chatGPTPrompt
                : location.state.chatGPTPrompt
            }
            handleRetryClick={handleRetryClick}
            data={recommend}
          /> */}
        </SectionContainer>
      </Wrapper>
      {isHelpClick && (
        <Portal>
          <HelpModal isOpen={isHelpClick} onClose={onHelpClick} />
        </Portal>
      )}
    </Container>
  );
};

export default ResultForSale;
