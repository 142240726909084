import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 12rem;
  height: 14rem;
  border-radius: 0.63rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  z-index: 1;
  position: absolute;
  top: 1rem;
  left: 10.44rem;
  overflow-y: scroll;
`;

const Item = styled.div`
  width: 100%;
  height: 1.81rem;
  font-size: 0.75rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.black};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, selected }) => selected && theme.primary.light};
  &:hover {
    background: ${({ theme }) => theme.primary.light};
  }
  cursor: pointer;
`;

const PositionDropdown = ({ style, data, setData, setFocusAt, setClick }) => {
  const positions = [
    "기획/전략",
    "마케팅, 광고, 홍보, 리서치",
    "회계, 세무, 재무",
    "인사, 노무, HRD",
    "총무",
    "법무",
    "IT개발, 데이터",
    "PM, PO, 서비스기획",
    "디자인",
    "영업, 판매",
    "무역, 수입/수출",
    "고객상담, TM(텔레마케팅)",
    "구매, 자재, 물류",
    "상품기획, MD",
    "운전, 운송, 배송",
    "서비스",
    "생산",
    "건설, 건축",
    "의료, 보건",
    "연구, R&D",
    "교육",
    "기타",
  ];
  const [open, setOpen] = useState(true);
  const selectRef = useRef();
  const handleClick = (value) => {
    setData(value);
    setOpen(false);
    setFocusAt(0);
    setClick(false);
    // selectRef.current.style = "display: none";
  };

  useEffect(() => {}, []);

  if (!open) {
    return null;
  }
  return (
    <Container ref={selectRef} style={style}>
      {positions.map((item) => (
        <Item
          key={item}
          selected={item === data}
          value={item}
          onClick={() => {
            handleClick(item);
          }}
        >
          {item}
        </Item>
      ))}
    </Container>
  );
};

export default PositionDropdown;
