import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ style, result }) => {
  const plugins = [
    {
      beforeDatasetsDraw(chart, args, pluginOptions) {
        const { ctx, data } = chart;
        ctx.restore();
        const xCoor = chart.getDatasetMeta(0).data[0].x;
        const yCoor = chart.getDatasetMeta(0).data[0].y;
        ctx.font = "bold 18px Pretendard Variable";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        ctx.fillText(`${data.datasets[0].data[0]}%`, xCoor, yCoor);
        ctx.save();
      },
    },
  ];
  const options = {
    // responsive: true,
    // cutout: 100,
    // percentageInnerCutout: 100,
    // radius: "100%",
    rotation: 180,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  };
  const data = {
    labels: result.map((item) => item.word),
    datasets: [
      {
        // label: "# of Votes",
        data: result.map((item) => item.percentage),
        backgroundColor: ["#00DBB1", "#017FC6", "#FF7E21", "#EEEEEE"],
        borderColor: ["#00DBB1", "#017FC6", "#FF7E21", "#EEEEEE"],
        borderWidth: 1,
        // borderRadius: 10,
        cutout: "80%",
      },
    ],
  };
  return (
    <div style={{ width: "7rem", height: "7rem", ...style }}>
      <Doughnut options={options} data={data} plugins={plugins} />
    </div>
  );
};

export default DoughnutChart;
