import styled from "styled-components";

const List = styled.div`
  border-top: 1px solid #000;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Row = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  display: flex;
  width: 100%;
`;
const Item = styled.div`
  font-size: 0.625rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 600;
  line-height: 160%;
  flex: 1;
  flex-basis: 0px;
  padding: 0.56rem;
  min-width: 2rem;
  text-align: center;
  word-break: keep-all;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
`;

const SummaryList = ({ headers, rows }) => {
  console.log(headers);
  return (
    <List>
      <Row>
        {headers.map((header, index) => (
          <Item
            key={index}
            style={{
              background: index === headers.length - 1 ? "#E1EDEA" : "#EDF3F3",
            }}
          >
            {header.includes("~")
              ? header.split("~").map((item, i) => (
                  <>
                    <span>
                      {item}
                      {i === 0 && "~"}
                    </span>
                    <br />
                  </>
                ))
              : header}
          </Item>
        ))}
      </Row>
      {rows.map((row, index) => (
        <Row key={index} style={{ display: "flex", alignItems: "center" }}>
          {row.map((item, rowIndex) => (
            <Item
              key={rowIndex}
              style={{
                fontWeight:
                  rowIndex === 0
                    ? 600
                    : rowIndex === row.length - 1
                    ? 600
                    : 400,
                background:
                  rowIndex === 0
                    ? "#EDF3F3"
                    : rowIndex === row.length - 1
                    ? "#F6FAF9"
                    : "#FFF",
              }}
            >
              {rowIndex === row.length - 1 && item >= 0 ? `+${item}` : item}
            </Item>
          ))}
        </Row>
      ))}
    </List>
  );
};

export default SummaryList;
