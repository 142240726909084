import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import "react-slideshow-image/dist/styles.css";
import { useEffect, useRef, useState } from "react";
import axios from "../api/axios";
import FlexRow from "../components/FlexRow";
import FlexColumn from "../components/FlexColumn";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import "react-slideshow-image/dist/styles.css";
import { MOBILE } from "../Constants";

import { isAuthEmpty } from "../utils/isAuthEmpty";
import { Helmet } from "react-helmet";
import validatePhone from "../utils/validatePhone";
import Checkbox from "../components/Checkbox";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 5.75rem);
  background: ${({ theme }) => theme.grayScaleColor.white};
  // border: 1px solid black;
`;

const Heading1 = styled.h2`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 2rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Heading2 = styled.h2`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const Heading3 = styled.h3`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  margin-top: 2.56rem;
  margin-bottom: 1.25rem;
`;

const Heading4 = styled.h4`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const Section = styled.section`
  padding: 1.75rem 1.25rem;
`;

const Callout = styled.div`
  padding: 0.75rem 1.25rem;
  border-radius: 0.625rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .right {
    font-weight: 500;
    color: ${({ theme }) => theme.backgroundColor.darkgray40};
  }
`;

const LineButton = styled.p`
  text-align: center;
  font-family: Pretendard;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  text-decoration-line: underline;
  color: ${({ theme, state }) =>
    state ? theme.backgroundColor.darkgray40 : theme.primary.normal};
`;

const Label = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4rem; /* 153.333% */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
`;

const InputLabel = styled.p`
  color: #000000;
  font-size: 0.9375rem;
  line-height: 140%;
`;
const SelectBox = styled.div`
  padding: 0.75rem;

  font-size: 0.875rem;
  border-radius: 0.4375rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  width: 100%;
  height: 2.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BaseInput = styled.input`
  padding: 0.75rem 1.25rem;
  width: 100%;
  font-size: 0.875rem;
  border-radius: 0.625rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  &:focus {
    outline: none;
    border: 1px solid #222222;
  }
  &::placeholder {
    color: ${({ theme }) => theme.grayScaleColor.gray30};
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const AgreeText = styled.p`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const HighlighText = styled.p`
  font-size: 0.8125rem;
  line-height: 140%;
  color: ${({ theme }) => theme.primary.normal};
  text-align: right;
`;

const ConfirmButton = styled.button`
  width: 100%;
  padding: 1rem 0.25rem;
  background: ${({ theme }) => theme.primary.normal};
  border: none;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  &:disabled {
    opacity: 0.3;
  }
`;

const UL = styled.ul`
  list-style: disc;
  padding-left: 1.25rem;
  & li {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    letter-spacing: -0.03125rem;
    color: ${({ theme }) => theme.backgroundColor.darkgray40};
  }
`;

const ArrowDownSvg = ({ style }) => {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
    >
      <path d="M5 6L0.669872 0H9.33013L5 6Z" fill="#222222" />
    </svg>
  );
};

const Dropdown = ({ close, items, setItem }) => {
  const handleClick = (e, item) => {
    setItem(item);
    close(false);
  };
  return (
    <FlexColumn
      style={{
        position: "absolute",
        marginTop: "40px",
        width: "100%",
        border: "1px solid #D9D9D9",
        height: "11.1875rem",
        overflowY: "auto",
        background: "white",
        borderRadius: "0rem 0rem 0.4375rem 0.4375rem",
        padding: "0 1rem",
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "center",
            borderBottom: "1px solid #EEEEEE",
            padding: "0.56rem 0",
            fontSize: "0.875rem",
            lineHeight: "140%",
          }}
          onClick={(e) => handleClick(e, item)}
        >
          {item}
        </div>
      ))}
    </FlexColumn>
  );
};

function MypageWithdraw() {
  const { auth, setAuth } = useAuth();
  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const selectBoxRef = useRef();
  const accountNumberRef = useRef();
  const accountOwnerRef = useRef();
  const amountRef = useRef();
  const [bank, setBank] = useState(state?.bank || "");
  const [accountNumber, setAccountNumber] = useState(
    state?.accountNumber || ""
  );
  const [accountOwner, setAccountOwner] = useState(state?.accountOwner || "");
  const [amount, setAmount] = useState(state?.amount || "");

  const [openDropDown, setOpenDropDown] = useState(false);
  const [isUseAgreeCheck, setIsUseAgreeCheck] = useState(false);

  const MIN_WITHDRAW_AMOUNT = auth.user.isFirstWithdraw ? 5000 : 10000;
  const AVAILABLE_AMOUNT =
    auth.user.point - (auth.user.point % MIN_WITHDRAW_AMOUNT);

  const validate = () => {
    if (
      bank.length === 0 ||
      accountNumber.length === 0 ||
      accountOwner.length === 0 ||
      amount.length === 0 ||
      Number(amount) > AVAILABLE_AMOUNT ||
      Number(amount) % MIN_WITHDRAW_AMOUNT !== 0 ||
      Number(amount) <= 0 ||
      !isUseAgreeCheck
    ) {
      return false;
    }
    return true;
  };

  const handleDropdownClick = () => {
    setOpenDropDown((prev) => !prev);
  };

  const handleConfirmClick = () => {
    navigate("confirm", {
      state: { bank, accountNumber, accountOwner, amount },
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      // console.log(response.data.surveys);
      setIsLoading(false);
    };

    if (isAuthEmpty(auth)) {
      return navigate("/login");
    }
    fetch();
  }, []);
  // console.log(surveyYearMonth, monthPeriod);
  if (isLoading) {
    return <div />;
  }

  return (
    <Container>
      <Helmet>
        <meta name="theme-color" content="#fff" />
      </Helmet>
      <Section>
        <Heading1 style={{ marginBottom: "0.5rem" }}>현금 이체</Heading1>
        <Heading4>보유한 캐시를 계좌로 환급 받아요.</Heading4>
        <FlexColumn style={{ marginTop: "2rem", gap: "1.25rem" }}>
          <FlexColumn style={{ gap: "0.5rem" }}>
            <Label>
              입금은행 <span style={{ color: "#00DBB1" }}>*</span>
            </Label>

            <FlexColumn style={{ position: "relative" }}>
              <SelectBox
                ref={selectBoxRef}
                onChange={(e) => setBank(e.target.value)}
                value={bank}
                onClick={handleDropdownClick}
              >
                {bank ? bank : <p style={{ color: "#BBB" }}>입금 은행 선택</p>}{" "}
                <ArrowDownSvg style={{ marginLeft: "1rem" }} />
              </SelectBox>
              {openDropDown && (
                <Dropdown
                  setItem={setBank}
                  close={setOpenDropDown}
                  items={[
                    "우리은행",
                    "국민은행",
                    "신한은행",
                    "하나은행",
                    "기업은행",
                    "농협은행",
                    "카카오뱅크",
                    "케이뱅크",
                    "토스뱅크",
                  ]}
                />
              )}
            </FlexColumn>
          </FlexColumn>
          <FlexColumn style={{ gap: "0.5rem" }}>
            <Label>
              계좌번호 <span style={{ color: "#00DBB1" }}>*</span>
            </Label>
            <BaseInput
              ref={accountNumberRef}
              type={"text"}
              placeholder={"계좌번호(숫자만) 입력"}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => setAccountNumber(e.target.value)}
              value={accountNumber}
            />
          </FlexColumn>
          <FlexColumn style={{ gap: "0.5rem" }}>
            <Label>
              예금주 실명 <span style={{ color: "#00DBB1" }}>*</span>
            </Label>
            <FlexRow
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <BaseInput
                ref={accountOwnerRef}
                type={"text"}
                placeholder={"이름 입력"}
                onChange={(e) => setAccountOwner(e.target.value)}
                value={accountOwner}
              />
            </FlexRow>
          </FlexColumn>
          <FlexColumn style={{ gap: "0.5rem" }}>
            <Label>
              환급 신청 캐시 <span style={{ color: "#00DBB1" }}>*</span>
            </Label>
            <FlexRow
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <BaseInput
                ref={amountRef}
                type={"text"}
                placeholder={"10,000원 단위로 입력"}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
              />
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
        <HighlighText style={{ marginTop: "0.75rem" }}>
          전환 가능 캐시:{" "}
          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
            style={{ width: "0.75rem", height: "0.75rem" }}
            alt={"cash icon"}
          />{" "}
          {AVAILABLE_AMOUNT.toLocaleString()}원
        </HighlighText>
        <FlexRow
          style={{ alignItems: "center", gap: "0.62rem", margin: "1.75rem 0" }}
        >
          <Checkbox
            active={isUseAgreeCheck}
            onClick={(e) => {
              setIsUseAgreeCheck((prev) => !prev);
            }}
          />
          <AgreeText>
            캐시 현금 전환을 위한 개인정보 수집/이용 동의 (필수)
          </AgreeText>
        </FlexRow>
        <ConfirmButton disabled={!validate()} onClick={handleConfirmClick}>
          신청하기
        </ConfirmButton>
        <FlexColumn style={{ marginTop: "3rem", gap: "0.5rem" }}>
          <FlexRow style={{ alignItems: "center" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M7.36842 16H8.63158V11.1579H7.36842V16ZM8 9.71663C8.1927 9.71663 8.35425 9.65144 8.48463 9.52105C8.61502 9.39067 8.68021 9.22912 8.68021 9.03642C8.68021 8.84372 8.61502 8.68218 8.48463 8.55179C8.35425 8.42154 8.1927 8.35642 8 8.35642C7.8073 8.35642 7.64575 8.42154 7.51537 8.55179C7.38498 8.68218 7.31979 8.84372 7.31979 9.03642C7.31979 9.22912 7.38498 9.39067 7.51537 9.52105C7.64575 9.65144 7.8073 9.71663 8 9.71663ZM8.00147 20C6.89495 20 5.85488 19.79 4.88126 19.3701C3.90765 18.9502 3.06077 18.3803 2.34063 17.6604C1.62049 16.9406 1.05032 16.094 0.630105 15.1208C0.210035 14.1476 0 13.1079 0 12.0015C0 10.8949 0.209965 9.85488 0.629895 8.88126C1.04982 7.90765 1.61972 7.06077 2.33958 6.34063C3.05944 5.62049 3.90597 5.05032 4.87916 4.63011C5.85235 4.21003 6.89214 4 7.99853 4C9.10505 4 10.1451 4.20996 11.1187 4.62989C12.0924 5.04982 12.9392 5.61972 13.6594 6.33958C14.3795 7.05944 14.9497 7.90597 15.3699 8.87916C15.79 9.85235 16 10.8921 16 11.9985C16 13.1051 15.79 14.1451 15.3701 15.1187C14.9502 16.0924 14.3803 16.9392 13.6604 17.6594C12.9406 18.3795 12.094 18.9497 11.1208 19.3699C10.1476 19.79 9.10786 20 8.00147 20ZM8 18.7368C9.8807 18.7368 11.4737 18.0842 12.7789 16.7789C14.0842 15.4737 14.7368 13.8807 14.7368 12C14.7368 10.1193 14.0842 8.52632 12.7789 7.22105C11.4737 5.91579 9.8807 5.26316 8 5.26316C6.1193 5.26316 4.52632 5.91579 3.22105 7.22105C1.91579 8.52632 1.26316 10.1193 1.26316 12C1.26316 13.8807 1.91579 15.4737 3.22105 16.7789C4.52632 18.0842 6.1193 18.7368 8 18.7368Z"
                fill="#282C2C"
              />
            </svg>
            <p>유의사항</p>
          </FlexRow>
          <UL>
            <li>현금 이체는 10,000원 단위로 출금할 수 있습니다.</li>
            <li>월 1회만 신청 가능하며, 매월 말 일괄 이체됩니다.</li>
            <li>
              <span style={{ fontWeight: 700 }}>신청 가능 기간:</span> ~매월
              20일 (해당 월 지급)
            </li>
            <li>신청 전 계좌 정보를 꼭 확인하세요.</li>
            <li>잘못된 계좌로 이체 시, 그 책음은 본인에게 있습니다.</li>
          </UL>
        </FlexColumn>
      </Section>
    </Container>
  );
}

export default MypageWithdraw;
