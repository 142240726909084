import { motion, useScroll } from "framer-motion";
import html2canvas from "html2canvas";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import reactStringReplace from "react-string-replace";
import styled from "styled-components";
import { ROUTES } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import dataURLtoFile from "../../../utils/dataURLtoFile";
import includesPathname from "../../../utils/includesPathname";
import { isAuthEmpty } from "../../../utils/isAuthEmpty";
import lineBreaker from "../../../utils/lineBreaker";
import getSmile from "../api/getSmile";
import getSmileAverage from "../api/getSmileAverage";
import uploadSmilePhoto from "../api/uploadSmilePhoto";
import Modal from "../components/Modal";
import SavePhotoDialog from "../components/SavePhotoDialog";
import UploadPhotoDialog from "../components/UploadPhotoDialog";

const Container = styled.div`
  width: 100%;
  // height: 100%;
  background: ${({ theme }) => theme.primary.normal};
  display: flex;
  justify-content: center;
  padding: 3.75rem 0;
`;

const Wrapper = styled.div`
  width: 100%;
  // height: 100%;
  max-width: 51.75rem;
  border-radius: 1.88rem;
  padding: 0 5.38rem;
  padding-bottom: 4.13rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopWrapper = styled(Wrapper)`
  width: 100%;
  max-width: calc(30.56rem + 60px);
  // border: 1px solid black;
  padding: 0 30px;
  margin-top: 85px;
`;

const TopNav = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  padding: 2.31rem 2.94rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: 5.94rem;
  height: 1.06rem;
  // position: absolute;
  // top: 2.44rem;
  // left: 2.94rem;
  cursor: pointer;
`;

const TopNavText = styled.span`
  font-weight: 500;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.primary.normal};
`;

const Download = styled.div`
  // border: 1px solid black;
  width: calc(100% + 60px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  padding-bottom: 20px;
`;

const Tag = styled.span`
  margin-top: 2.94rem;
  margin-bottom: 0.81rem;
  padding: 0.13rem 1.06rem;
  border-radius: 1.88rem;
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
`;

const Heading = styled.h1`
  font-size: 2.25rem;
  line-height: 2.88rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
`;

const HeadingDescription = styled.p`
  font-size: 0.94rem;
  font-weight: 300;
  line-height: 1.44rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  margin-bottom: 2.5rem;
  text-align: center;
`;

const ImageContainer = styled.div`
  width: 489px;
  height: auto;
  position: relative;
  margin-bottom: 1.56rem;
  //   display: flex;
  //   justify-content: center;
`;
const Image = styled.img`
  width: 489px;
  height: auto;
  //   width: 390px;
  //   height: auto;
  //   width: 320px;
  //   height: 220px;
  border-radius: 1.25rem;
  object-fit: cover;
  //   border: 1px solid black;
`;

const Canvas = styled.canvas`
  width: 489px;
  height: 276px;
  border-radius: 1.25rem;
  margin-bottom: 1.56rem;
`;

const ResultText = styled.p`
  width: 100%;
  height: 4.05rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.63rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.25rem;
  line-height: 1.88rem;
  margin-bottom: 1.38rem;
`;

const ResultStrong = styled.span`
  color: ${({ theme }) => theme.primary.normal};
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.75rem;
  margin: 0 0.44rem;
`;

const TipContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 27px;
  width: 100%;
`;
const TipIcon = styled.img`
  width: 21px;
  margin-right: 12px;
`;
const TipText = styled.p`
  font-size: 0.88rem;
  font-weight: 400;
  color: ${({ theme }) => theme.primary.dark};
  line-height: 1.25rem;
  letter-spacing: -0.04em;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
  gap: 10px;
`;

const Button = styled.div`
  padding: 1.06rem 0;
  background: ${({ theme }) => theme.primary.normal};
  border-radius: 0.63rem;
  color: white;
  font-weight: 600;
  letter-spacing: -0.04em;
  /* margin-bottom: 1.44rem; */
  width: 16rem;
  text-align: center;
  cursor: pointer;
  /* margin: 0 7.5px; */
`;

const SecondaryButton = styled(Button)`
  background: ${({ theme }) => theme.backgroundColor.darkgray20};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const RetryButton = styled.button`
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  font-size: 0.94rem;
  line-height: 1.44rem;
  font-weight: 500;
  margin-bottom: 2.88rem;
  border: none;
  background: none;
  margin: 0 auto;
  margin-right: 0;
  display: flex;
  align-itemx: center;
  cursor: pointer;
`;

const Divider = styled.div`
  height: 1px;
  border-top: 1px dashed ${({ theme }) => theme.grayScaleColor.gray20};
  width: 100%;
  margin-top: 2.88rem;
  margin-bottom: 5.13rem;
`;

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3.75rem;
  position: relative;
`;

const GraphBar = styled(motion.div)`
  width: ${({ result }) => `${result}%`};
  height: 1.5rem;
  border-radius: 6.25rem;
  background: ${({ theme }) => theme.primary.normal};
  position: absolute;
  top: 0;
  left: 0;
`;

const Graph = styled.div`
  width: 100%;
  height: 1.5rem;
  border-radius: 6.25rem;
  background: linear-gradient(
    270deg,
    rgba(0, 210, 193, 0.2) 0%,
    rgba(214, 248, 245, 0.2) 100%
  );
`;

const BarContainer = styled.div`
  width: 100%;
  height: 1.5rem;
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  top: 0;
  left: 0;
  z-index: 1;
`;

const Bar = styled.div`
  width: 1px;
  height: 1.5rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
`;

const GraphInfoContainer = styled.div`
  display: flex;
  margin-top: 1.44rem;
  justify-content: space-between;
`;

const GraphInfoItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  padding-left: 0.63rem;
`;

const GraphInfoLevel = styled.span`
  font-size: 0.94rem;
  line-height: 1.44rem;
  font-weight: 500;
  margin-bottom: 0.31rem;
`;

const GraphInfoText = styled.span`
  font-size: 0.88rem;
  line-height: 1.31rem;
  font-weight: 500;
  margin-bottom: 0.56rem;
  letter-spacing: -0.04em;
`;

const GraphInfoRange = styled.span`
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  font-size: 0.75rem;
  line-height: 1.19rem;
`;

const ResultDescriptionContainer = styled.div`
  padding: 1.81rem 1.88rem;
  border-radius: 0.94rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
  width: 100%;
`;

const ResultDescriptionHeading = styled.h2`
  font-size: 1.25rem;
  line-height: 1.88rem;
  letter-spacing: -0.04em;
  font-weight: 700;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 1.31rem;
`;

const ResultDescriptionText = styled.p`
  font-size: 0.94rem;
  line-height: 1.44rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  font-weight: 300;
  letter-spacing: -0.04em;
  word-break: keep-all;
  margin-bottom: 1.25rem;
`;
const ResultDescriptionBold = styled.span`
  font-weight: 500;
`;

const IndicatorSvg = styled(motion.svg)`
  position: absolute;
  top: -2.5rem;
  left: calc(${({ result }) => `${result}%`} - 0.6rem);
`;

const WatermarkImage = styled.img`
  position: absolute;
  width: 3.69rem;
  height: 0.63rem;
  bottom: 1rem;
  right: 1.13rem;
`;

const StampImage = styled.img`
  position: absolute;
  width: 82px;
  height: 82px;
  top: 6.25rem;
  right: 1.25rem;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  letter-spacing: -0.04em;
  margin-top: 45px;
`;

const FooterText = styled.p`
  font-size: 0.75rem;
  line-height: 1.13rem;

  color: ${({ theme }) => theme.grayScaleColor.gray70};
`;

const FooterCopyright = styled.p`
  font-size: 0.69rem;
  line-height: 1.5rem;

  color: ${({ theme }) => theme.grayScaleColor.gray30};
`;

const Watermark = () => {
  return (
    <WatermarkImage
      src={`${process.env.PUBLIC_URL}/assets/logo/watermark_logo.png`}
    />
  );
};

const Indicator = ({ result }) => {
  return (
    <IndicatorSvg
      result={result}
      width="21"
      height="33"
      viewBox="0 0 21 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial={{ opacity: 0 }}
      transition={{ delay: 1 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
    >
      <path
        d="M20.375 10.1541C20.375 15.7621 10.1875 32.6 10.1875 32.6C10.1875 32.6 0 15.7621 0 10.1541C0 4.54614 4.5611 0 10.1875 0C15.8139 0 20.375 4.54614 20.375 10.1541Z"
        fill="#00DBB1"
      />
      <circle cx="10.1878" cy="11.5457" r="3.39583" fill="white" />
    </IndicatorSvg>
  );
};

const AIResult = () => {
  const { auth } = useAuth();
  const {
    state: { data, name, result, level, cid, fileName },
  } = useLocation();
  const navigate = useNavigate();
  const [smile, setSmile] = useState({});
  const [subTitles, setSubTitles] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const [statistic, setStatistic] = useState();
  const [showSavePhotoDialog, setShowSavePhotoDialog] = useState({
    open: false,
    msg: "",
  });

  const imageRef = useRef();
  const downloadRef = useRef();
  const canvasRef = useRef();
  const axiosPrivate = useAxiosPrivate();
  const { scrollYProgress } = useScroll();

  const handleClickLogo = () => {
    if (cid) {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE)
          ? `${ROUTES.GUEST_SMILE}/${cid}`
          : ROUTES.SMILE
      );
    } else {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE) ? ROUTES.GUEST_SMILE : ROUTES.SMILE
      );
    }
  };

  const exportAsImage = async (el, fileName) => {
    const canvas = await html2canvas(el, { scale: 2 });
    const image = canvas.toDataURL("image/png", 1.0);
    // downloadImage(image, fileName);
    return image;
  };

  const downloadImage = async () => {
    const image = await exportAsImage(downloadRef.current, "test");
    // console.log(image);
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = `smile-challenge.png`;

    fakeLink.href = image;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  const handleSavePhotoClick = async () => {
    const img = dataURLtoFile(data, fileName);
    const formData = new FormData();

    formData.append("name", auth.user.nickname);
    formData.append("cid", auth.user.companyId._id);
    formData.append("uid", auth.user.id);
    formData.append("file", img);
    formData.append("score", result);
    formData.append("isPublic", false);

    let msg = "";
    try {
      await uploadSmilePhoto({
        auth: includesPathname(ROUTES.GUEST_SMILE) ? null : auth,
        formData,
      });
      msg = `오늘의 미소 저장 완료!\nPC 대시보드에서 확인할 수 있어요😉`;
    } catch (err) {
      msg = `이미 사진을 저장했습니다!`;
    } finally {
      setShowSavePhotoDialog({ open: true, msg });
    }
  };

  const handleRetryButtonClicked = () => {
    const popup = window.open(
      includesPathname(ROUTES.GUEST_SMILE)
        ? ROUTES.GUEST_SMILE_POPUP
        : ROUTES.SMILE_POPUP,
      "미소",
      "width=700px,height=730px,scrollbars=yes"
    );
    popup.opener.sendImage = (image) => {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE)
          ? ROUTES.GUEST_SMILE_ANALYZE
          : ROUTES.SMILE_ANALYZE,
        {
          state: { name, imageData: image, cid },
        }
      );
      //   renderImage(image);
      return;
    };
  };

  const handleUploadOkClick = () => {
    if (cid) {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE)
          ? `${ROUTES.GUEST_SMILE_CHAMPIONS}/${cid}`
          : ROUTES.SMILE_CHAMPIONS
      );
    } else {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE)
          ? `${ROUTES.GUEST_SMILE_CHAMPIONS}`
          : ROUTES.SMILE_CHAMPIONS
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getSmile(level);
      const averageResponse = await getSmileAverage({
        auth: includesPathname(ROUTES.GUEST_SMILE) ? null : auth,
        cid,
      });

      let ratio =
        (averageResponse.result[`level${level}Count`] /
          averageResponse.result.totalCount) *
        100;
      setStatistic(ratio % 1 === 0 ? ratio : ratio.toFixed(2));

      let description = reactStringReplace(
        response.smile[level - 1].description,
        "{name}",
        (match, i) => name
      );

      description = reactStringReplace(description, "{level}", (match, i) => (
        <ResultDescriptionBold>레벨 {level}</ResultDescriptionBold>
      ));
      description = reactStringReplace(description, "{result}", (match, i) => (
        <ResultDescriptionBold>{result}%</ResultDescriptionBold>
      ));

      description = reactStringReplace(description, "{block1}", (match, i) => (
        <ResultDescriptionBold>
          {response.smile[level - 1].recommendBlockOne.title}
        </ResultDescriptionBold>
      ));
      description = reactStringReplace(description, "{block2}", (match, i) => (
        <ResultDescriptionBold>
          {response.smile[level - 1].recommendBlockTwo.title}
        </ResultDescriptionBold>
      ));
      description = reactStringReplace(description, "\n", (match, i) => <br />);
      response.smile[level - 1].description = description;

      setSmile(response.smile);
      setSubTitles(response.smile.map((i) => i.subTitle));
      //   imageRef.current.setAttribute("src", data);
    };
    fetchData();
    // const pixelRatio = window.devicePixelRatio || 1;
    // console.log(pixelRatio);
    // const context = canvasRef.current.getContext("2d");
    // const width = 489;
    // const height = 276;
    const width = 390;
    const height = 220;
    // const width = 320;
    // const height = 320 / (4 / 3);
    // canvasRef.current.width = width * pixelRatio;
    // canvasRef.current.height = height * pixelRatio;

    // context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

    // context.drawImage(imageRef.current, 0, 0, width, height);
    // imageRef.current.style = "display: none";
  }, []);
  //   if (!smile) {
  //     return <></>;
  //   }
  return (
    Object.keys(smile).length > 0 && (
      <Container>
        <Wrapper>
          <TopNav>
            <Logo
              onClick={handleClickLogo}
              src={`${process.env.PUBLIC_URL}/assets/logo/ai_logo.png`}
            />
            <TopNavText>Challenge Result</TopNavText>
          </TopNav>
          <TopWrapper>
            <Download ref={downloadRef}>
              <Tag>Lv. {level}</Tag>
              <Heading>
                {smile[level - 1].title}
                <br />
                {smile[level - 1].subTitle}
              </Heading>
              {level === 5 && (
                <StampImage
                  src={`${process.env.PUBLIC_URL}/assets/smile-stamp.png`}
                />
              )}
              <HeadingDescription>
                {lineBreaker(smile[level - 1].summary)}
              </HeadingDescription>
              <ImageContainer>
                <Image ref={imageRef} src={data} />
                <Watermark />
              </ImageContainer>
              {/* <Canvas id="canvas" ref={canvasRef}></Canvas> */}
              <ResultText>
                내 미소가 진짜로 보일 가능성은
                <ResultStrong>
                  {result}
                  <span style={{ fontSize: "1.5rem" }}>%</span>
                </ResultStrong>
                입니다.
              </ResultText>
            </Download>

            <TipContainer>
              <TipIcon
                src={`${process.env.PUBLIC_URL}/assets/ai-smile/ai_smile_explain_symbol.png`}
              />
              <TipText>
                행복 미소 챌린지는 AI 미소 엔진을 사용해 뒤센 미소와 사회적
                미소를 구별하고 있어요.{" "}
              </TipText>
            </TipContainer>
            <ButtonContainer>
              <Button
                onClick={
                  isAuthEmpty(auth) || includesPathname(ROUTES.GUEST_SMILE)
                    ? downloadImage
                    : handleSavePhotoClick
                }
              >
                내 미소 저장하기
              </Button>
              <SecondaryButton onClick={() => setUploadImage(true)}>
                찐-미소 챔피언즈 올리기
              </SecondaryButton>
            </ButtonContainer>
            <RetryButton onClick={handleRetryButtonClicked}>
              <svg
                style={{ marginRight: "0.25rem" }}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_538_2426)">
                  <path
                    d="M14.7086 5.29182C13.3502 3.93349 11.4252 3.15016 9.30855 3.36682C6.25022 3.67516 3.73355 6.15849 3.39189 9.21682C2.93355 13.2585 6.05855 16.6668 10.0002 16.6668C12.6586 16.6668 14.9419 15.1085 16.0086 12.8668C16.2752 12.3085 15.8752 11.6668 15.2586 11.6668C14.9502 11.6668 14.6586 11.8335 14.5252 12.1085C13.5836 14.1335 11.3252 15.4168 8.85855 14.8668C7.00855 14.4585 5.51689 12.9502 5.12522 11.1002C4.42522 7.86682 6.88355 5.00016 10.0002 5.00016C11.3836 5.00016 12.6169 5.57516 13.5169 6.48349L12.2586 7.74182C11.7336 8.26682 12.1002 9.16682 12.8419 9.16682H15.8336C16.2919 9.16682 16.6669 8.79182 16.6669 8.33349V5.34182C16.6669 4.60016 15.7669 4.22516 15.2419 4.75016L14.7086 5.29182Z"
                    fill="#777777"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_538_2426">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>{" "}
              다시 도전!
            </RetryButton>
          </TopWrapper>
          <Divider />
          <GraphContainer>
            <Indicator result={result} />
            <Graph />
            <BarContainer>
              <Bar />
              <Bar />
              <Bar />
              <Bar />
            </BarContainer>
            <GraphBar
              result={result}
              initial={{ opacity: 1, scaleX: 0, transformOrigin: 0 }}
              transition={{ duration: 1 }}
              whileInView={{ scaleX: 1, transformOrigin: 0 }}
              viewport={{ once: true }}
            />
            <GraphInfoContainer>
              {[1, 2, 3, 4, 5].map((item) => (
                <GraphInfoItem key={item}>
                  <GraphInfoLevel>Lv. {item}</GraphInfoLevel>
                  <GraphInfoText>
                    {smile[item - 1].title}
                    <br />
                    {subTitles[item - 1]}
                  </GraphInfoText>
                  <GraphInfoRange>
                    {(item - 1) * 20} ~ {20 * item}%
                  </GraphInfoRange>
                </GraphInfoItem>
              ))}
            </GraphInfoContainer>
          </GraphContainer>
          <ResultText style={{ fontSize: "1rem" }}>
            <ResultStrong style={{ fontSize: "1.63rem" }}>
              {statistic}
              <span style={{ fontSize: "1.25rem" }}>%</span>
            </ResultStrong>
            의 챌린저들이 나와 같은 미소를 짓고 있어요!
          </ResultText>
          <ResultDescriptionContainer>
            <ResultDescriptionHeading>
              내 미소는 레벨 {level}, {smile[level - 1].subTitle}
            </ResultDescriptionHeading>

            <ResultDescriptionText>
              {smile[level - 1].description}
            </ResultDescriptionText>
          </ResultDescriptionContainer>
          <FooterContainer>
            <FooterText>
              맘핏은 블룸컴퍼니에서 운영하고 있는 직장인을 위한 구독형 마음관리
              서비스입니다.
            </FooterText>
            <FooterCopyright>&copy; BLOOM COMPANY</FooterCopyright>
          </FooterContainer>
        </Wrapper>
        {uploadImage && (
          <Modal>
            <UploadPhotoDialog
              imageUrl={data}
              fileName={fileName}
              cid={cid}
              score={result}
              okHandler={handleUploadOkClick}
              closeHandler={() => setUploadImage(false)}
            />
          </Modal>
        )}
        {showSavePhotoDialog.open && (
          <Modal>
            <SavePhotoDialog
              text={showSavePhotoDialog.msg}
              okHandler={() => setShowSavePhotoDialog({ open: false, msg: "" })}
            />
          </Modal>
        )}
      </Container>
    )
  );
};

export default AIResult;
