import { motion, useAnimationControls } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import useAuth from "../../hooks/useAuth";
import lineBreaker from "../../utils/lineBreaker";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { MOBILE } from "../../Constants";
import { useMediaQuery } from "react-responsive";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #f8fbfb 0%, #e9f6f2 100%);
  padding: 1.88rem;
  max-width: ${MOBILE};
  margin: 0 auto;
`;

const Logo = styled.img`
  width: 6.25rem;
  height: 1.13188rem;
`;

const fadeIn = (fade) => keyframes`
  0% {
    opacity: ${fade ? "0" : "1"};
  }

  100%{
    opacity: 1;
  }
`;

const AnimatedBox = styled.div`
  opacity: 1;
  animation: ${(props) => fadeIn(props.fade)}
    ${({ fade }) => (fade ? "1s" : "0s")} linear;
`;
const Text = styled(motion.p)`
  width: 100%;
  text-align: center;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 150%; /* 2.625rem */
  letter-spacing: -0.03125rem;
  margin-top: 2.56rem;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  // animation: ${fadeIn} 0.5s linear;
`;

const DescriptionText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 160%; /* 1.6rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  margin-bottom: 2.75rem;
`;

const Image = styled.img`
  height: 12.3125rem;
`;

const Button = styled.button`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.primary.normal};
  border-radius: 0.3125rem;
  padding: 1.06rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.625rem; /* 144.444% */
  letter-spacing: -0.045rem;
  color: ${({ theme, state }) =>
    state === "end" ? theme.grayScaleColor.white : theme.primary.normal};
  background: ${({ theme, state }) =>
    state === "end" ? theme.primary.normal : "none"};
  margin-top: 3.5rem;
  transition: 0.3s all;
  ${({ theme, state }) =>
    state === "end" && "box-shadow: 0px 13px 60px 0px rgba(0, 183, 148, 0.20);"}
`;

const ActiveDot = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <circle cx="5" cy="5" r="5" fill="#00DBB1" />
    </svg>
  );
};

const Dot = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
    >
      <circle cx="4" cy="4" r="4" fill="#D9D9D9" />
    </svg>
  );
};

const Onboarding = () => {
  const data = [
    {
      title: "AI 미소 챌린지",
      description: `블룸 님의 미소는 어떤 미소일까요?\n미소 레벨과 찐미소 가능성을\nAI가 판별해 드립니다.`,
      src: `${process.env.PUBLIC_URL}/assets/tutorial/ai_tutorial_mobile.png`,
      button: "다음으로",
      index: 0,
    },
    {
      title: "마음 체크업",
      description: `매주 금요일 오후 1시,\n1분 만에 끝내는 간편한 마음 체크업이\n메일함으로 찾아갑니다.`,
      src: `${process.env.PUBLIC_URL}/assets/tutorial/checkup_tutorial_mobile.png`,
      button: "다음으로",
      index: 1,
    },
    {
      title: "마음 트레이닝",
      description: `매주 월요일 오전 8시,\n내 마음에 꼭 필요한 마음 트레이닝이\n메일함으로 찾아갑니다.`,
      src: `${process.env.PUBLIC_URL}/assets/tutorial/training_tutorial_mobile.png`,
      button: "다음으로",
      index: 2,
      width: "17.1875rem",
      height: "12.3125rem",
    },
    {
      title: "메타버스 맘핏 센터",
      description: `혼자서 할 수 있는 마음 트레이닝과\n함께 모여 할 수 있는 GX를\n맘핏 센터에서 만나보세요.`,
      src: `${process.env.PUBLIC_URL}/assets/tutorial/metaverse_tutorial_mobile.png`,
      button: "시작하기",
      index: 3,
      width: "14.8125rem",
      height: "12.9375rem",
    },
  ];
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [fade, setFade] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const controls = useAnimationControls();
  const ref = useRef();
  const swiperRef = useRef();
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE})`,
  });

  const moveTo = async (index) => {
    // controls.stop();
    // console.log("moveTo");
    setFade(false);
    setCurrentIndex(index);
    swiperRef.current.swiper.slideTo(index);
  };

  const onNextClick = () => {
    navigate(isMobile ? "/" : "/tutorial");
  };

  const onLoad = () => {
    // console.log("loaded");
  };

  useEffect(() => {
    // console.log("useEffect");
    const timeout = setTimeout(() => {}, [0]);
    setFade(true);

    // controls.start("visible");
    // controls.start({ opacity: 1 });
    return () => {
      // console.log("return useEffect");
      clearTimeout(timeout);
      // setFade(false);
    };
  }, [currentIndex]);

  return (
    <Container>
      <Helmet>
        <meta name="theme-color" content="#F8FBFB" />
      </Helmet>
      <Logo
        src={`${process.env.PUBLIC_URL}/assets/logo/maumfit_logo.png`}
        alt="logo"
      />
      <Swiper
        // slidesPerView={4}
        ref={swiperRef}
        onSlideChange={(index) => setCurrentIndex(index.activeIndex)}
      >
        <SwiperSlide>
          <AnimatedBox fade={fade}>
            <Text ref={ref}>{data[0].title}</Text>
            <DescriptionText>
              {lineBreaker(data[0].description)}
            </DescriptionText>
            <FlexRow style={{ justifyContent: "center" }}>
              <Image
                // style={{
                //   width: data[currentIndex].width,
                //   height: data[currentIndex].height,
                // }}
                src={data[0].src}
                onLoad={onLoad}
              />
            </FlexRow>
          </AnimatedBox>
        </SwiperSlide>
        <SwiperSlide>
          <AnimatedBox fade={fade}>
            <Text ref={ref}>{data[1].title}</Text>
            <DescriptionText>
              {lineBreaker(data[1].description)}
            </DescriptionText>
            <FlexRow style={{ justifyContent: "center" }}>
              <Image
                // style={{
                //   width: data[currentIndex].width,
                //   height: data[currentIndex].height,
                // }}
                src={data[1].src}
                onLoad={onLoad}
              />
            </FlexRow>
          </AnimatedBox>
        </SwiperSlide>
        <SwiperSlide>
          <AnimatedBox fade={fade}>
            <Text ref={ref}>{data[2].title}</Text>
            <DescriptionText>
              {lineBreaker(data[2].description)}
            </DescriptionText>
            <FlexRow style={{ justifyContent: "center" }}>
              <Image
                // style={{
                //   width: data[currentIndex].width,
                //   height: data[currentIndex].height,
                // }}
                src={data[2].src}
                onLoad={onLoad}
              />
            </FlexRow>
          </AnimatedBox>
        </SwiperSlide>
        <SwiperSlide>
          <AnimatedBox fade={fade}>
            <Text ref={ref}>{data[3].title}</Text>
            <DescriptionText>
              {lineBreaker(data[3].description)}
            </DescriptionText>
            <FlexRow style={{ justifyContent: "center" }}>
              <Image
                // style={{
                //   width: data[currentIndex].width,
                //   height: data[currentIndex].height,
                // }}
                src={data[3].src}
                onLoad={onLoad}
              />
            </FlexRow>
          </AnimatedBox>
        </SwiperSlide>
      </Swiper>
      <Button
        state={currentIndex === 3 ? "end" : ""}
        onClick={() =>
          currentIndex === 3 ? onNextClick() : moveTo(currentIndex + 1)
        }
      >
        {data[currentIndex].button}
      </Button>
      <FlexRow
        style={{
          alignItems: "center",
          justifyContent: "center",
          gap: "0.44rem",
          marginTop: "2rem",
        }}
      >
        {currentIndex === 0 ? <ActiveDot /> : <Dot onClick={() => moveTo(0)} />}
        {currentIndex === 1 ? <ActiveDot /> : <Dot onClick={() => moveTo(1)} />}
        {currentIndex === 2 ? <ActiveDot /> : <Dot onClick={() => moveTo(2)} />}
        {currentIndex === 3 ? <ActiveDot /> : <Dot onClick={() => moveTo(3)} />}
      </FlexRow>
    </Container>
  );
};

export default Onboarding;
