import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { TYPE_MEMBER } from "../../Constants";
import useAuth from "../../hooks/useAuth";
import Sidebar from "../../pages/dashboard/Sidebar";
import { isAuthEmpty } from "../../utils/isAuthEmpty";
import FlexRow from "../FlexRow";
import TopBar from "../TopBar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.backgroundColor.darkgray20};
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const DashboardLayout = ({ type = TYPE_MEMBER, items }) => {
  const { auth } = useAuth();
  return (
    <Container>
      <TopBar
        type={type}
        name={!isAuthEmpty(auth) ? auth.user.name : "김블룸"}
        company={!isAuthEmpty(auth) ? auth.user.companyId.name : "블룸컴퍼니"}
        sticky={true}
      />
      <FlexRow
        style={{
          marginTop: "4.06rem",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <Sidebar items={items} type={type} />
        <Outlet />
      </FlexRow>
    </Container>
  );
};

export default DashboardLayout;
