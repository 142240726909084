import styled from "styled-components";

const InterestButton = styled.div`
  background: ${({ theme, clicked }) =>
    clicked ? theme.primary.normal : theme.grayScaleColor.gray10};
  color: ${({ theme, clicked }) =>
    clicked ? theme.grayScaleColor.white : theme.grayScaleColor.black};
  width: 6.25rem;
  height: 2.19rem;
  font-weight: 300;
  border-radius: 0.44rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CheckboxButton = ({ value, checked, onClick }) => {
  return (
    <InterestButton clicked={checked} onClick={() => onClick(value)}>
      {value}
    </InterestButton>
  );
};

export default CheckboxButton;
