import { Outlet } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  max-width: 62.5rem;
  width: 100%;
  height: 5.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Navbar = styled.nav`
  //   max-width: 62.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.primary.normal};
`;

const Logo = styled.img`
  width: 6.44rem;
  height: 1.19rem;
  cursor: pointer;
`;

const Text = styled.span`
  font-size: 1.25srem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-weight: 800;
`;

const HomeLayout = ({}) => {
  const handleLogoClick = () => {
    window.open("https://zep.us/play/8ZwAV4", "_blank");
  };
  return (
    <Container>
      <Navbar>
        <Wrapper>
          <Logo
            onClick={handleLogoClick}
            src={`${process.env.PUBLIC_URL}/assets/logo/topbar_logo.png`}
          />
          <Text>직장인 마음 피트니스 맘핏</Text>
        </Wrapper>
      </Navbar>

      <Outlet />
    </Container>
  );
};

export default HomeLayout;
