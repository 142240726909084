import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ interestClicked }) => (interestClicked ? "auto" : "3.94rem")};
  border-radius: 0.63rem;
  padding: 0 2.5rem;
  margin-bottom: 0.63rem;
  position: relative;
  justify-content: space-between;
  background-color: ${({ theme, interestClicked, isFilled }) =>
    interestClicked
      ? theme.grayScaleColor.white
      : isFilled
      ? theme.grayScaleColor.gray5
      : theme.grayScaleColor.white};
  ${({ theme, isFilled }) =>
    !isFilled && `border: 1px solid ${theme.grayScaleColor.gray10}`};
  color: ${({ theme, isRequired, isFilled, interestClicked }) =>
    (!isRequired && isFilled) || interestClicked
      ? theme.grayScaleColor.black
      : theme.grayScaleColor.gray30};
  border: 1px solid
    ${({ theme, interestClicked, isFilled }) =>
      interestClicked
        ? theme.primary.normal
        : isFilled
        ? theme.grayScaleColor.gray5
        : theme.grayScaleColor.gray10};
  //   &:focus-within {
  //     color: ${({ theme }) => theme.grayScaleColor.black};
  //     border: 1px solid ${({ theme }) => theme.primary.normal};
  //     background-color: ${({ theme }) => theme.grayScaleColor.white};
  //   }

  transition: 0.3s;
`;

const DropdownDiv = ({ children, reference, interestClicked, isFilled }) => {
  return (
    <Container
      ref={reference}
      isFilled={isFilled}
      interestClicked={interestClicked}
    >
      {children}
    </Container>
  );
};

export default DropdownDiv;
