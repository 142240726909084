import axios, { axiosPrivate } from "../../../api/axios";

const deleteSmilePhoto = async ({ auth, photo, password }) => {
  if (auth?.user) {
    try {
      const response = await axiosPrivate.delete(`/smile/photo`, {
        params: {
          id: photo._id,
        },
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      return response.data;
    } catch (err) {
      console.error(err);
      return err.response.data;
    }
  }

  try {
    const response = await axios.delete(`/smile/guests/photo`, {
      params: {
        id: photo._id,
        name: photo.name,
        password,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return err.response.data;
  }
};

export default deleteSmilePhoto;
