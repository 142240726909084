import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios, { axiosPrivate } from "../../../api/axios";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import { MOBILE } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getDate, getToday } from "../../../utils/date";
import { getThisWeekFriday, getThisWeekMonday } from "../../../utils/date";
import { isAuthEmpty } from "../../../utils/isAuthEmpty";
import isEndWithConsonant from "../../../utils/isEndWithConsonant";
import lineBreaker from "../../../utils/lineBreaker";
import BarChart from "./BarChart";
import Box from "./components/Box";
import Heading1 from "./components/Heading1";
import Heading2 from "./components/Heading2";
import Heading3 from "./components/Heading3";
import Recommend from "./components/Recommend";
import SectionHeading1 from "./components/SectionHeading1";
import SectionHeading2 from "./components/SectionHeading2";
import StampBox from "./components/StampBox";
import convertBGColor from "../../../utils/convertBlockBgColor";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 8.25rem;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 62.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 425px) {
    padding: 0 1.56rem;
  }
`;

const TipBox = styled.div`
  width: 100%;
  height: 3rem;
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 1.56rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.63rem;
  margin-top: 3.75rem;
  margin-bottom: 1.63rem;
`;

const TipText = styled.p`
  font-size: 0.94rem;
  line-height: 1.13rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  align-self: flex-start;
  margin-bottom: 3rem;
  @media (max-width: 425px) {
    margin-bottom: 0.25rem;
    line-height: 1.5rem;
    letter-spacing: -0.5px;
  }
`;

const InfoContainer = styled.div`
  align-self: flex-start;
  margin-top: 5.13rem;
  margin-bottom: 1.75rem;
  @media (max-width: 425px) {
    margin-top: 3.25rem;
    margin-bottom: 2rem;
  }
`;

const CalendarIcon = styled.img``;
const DateText = styled.span`
  font-size: 1.13rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  font-weight: 500;
  margin-left: 0.5rem;
  @media (max-width: 425px) {
    font-size: 0.94rem;
  }
`;

const TextBox = styled.span`
  font-weight: 600;
  font-size: 0.94rem;
  color: ${({ theme }) => theme.primary.normal};
  padding: 0.25rem 0.75rem;
  min-width: 2.88rem;
  background: ${({ theme }) => theme.primary.light};
  border-radius: 0.31rem;
  // margin: 0 0.31rem;
  text-align: center;
  margin-left: 1.25rem;
  display: flex;
  align-items: center;
`;

const ItemHeading = styled.div`
  font-size: 1.13rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 600;
  line-height: 1.63rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 0.94rem;
  // padding: 2.25rem 1.88rem;
  width: 11.25rem;
  height: 11.25rem;
`;
const ItemIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  @media (max-width: 425px) {
    margin-bottom: 0.5rem;
  }
`;

const ItemText = styled.span`
  font-size: 2rem;
  color: ${({ theme }) => theme.primary.normal};
  font-weight: 600;
  line-height: 2.38rem;
`;

const ItemCurrency = styled.span`
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.primary.normal};
`;

const ItemSeparator = styled.div`
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.grayScaleColor.gray20};
`;

const SectionContainer = styled.div`
  padding: 3.13rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 1.25rem;
  margin-bottom: 2.5rem;
  width: 100%;
  @media (max-width: 425px) {
    margin-top: 2.63rem;
    margin-bottom: 0;
    padding: 1.56rem 1.25rem;
  }
`;

const Divider = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  margin-top: 1.56rem;
  margin-bottom: 3.13rem;
`;

const Chips = styled.span`
  font-size: 0.94rem;
  color: ${({ theme }) => theme.primary.normal};
  padding: 0.25rem 0.63rem;
  border: 1px solid ${({ theme }) => theme.primary.normal};
  border-radius: 3.13rem;
  margin-right: 0.63rem;
`;

const Chart = styled.div`
  width: 405px;
  height: 249px;
  //   border: 1px solid black;
  @media (max-width: 425px) {
    width: 100%;
  }
`;

const Title = styled.h3`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 1.81rem;
  letter-spacing: -0.5px;
  font-weight: 600;
  margin-bottom: 0.88rem;
`;

const Descripton = styled.p`
  font-size: 0.94rem;
  line-height: 1.5rem;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 400;
  margin-bottom: 2.38rem;
  word-break: keep-all;
  @media (max-width: 425px) {
    margin-bottom: 1.56rem;
  }
`;

const RandomText = styled.div`
  font-size: 0.94rem;
  font-weight: 400;
  line-height: 1.63rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  background: ${({ theme }) => theme.primary.light};
  width: 100%;
  padding: 0.75rem 0;
  border-radius: 0.44rem;
  display: flex;
  align-items: center;
  @media (max-width: 425px) {
    padding: 1rem 0.75rem;
  }
`;

const HighlightTitle = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 1rem;
`;

const HighlightCallout = styled.p`
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  padding: 1rem 1.5rem;
  border-radius: 0.625rem;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 160%;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const BlockTitle = styled.h3`
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.8125rem; /* 131.818% */
  letter-spacing: -0.03125rem;
  @media (max-width: ${MOBILE}) {
    font-size: 1.25rem;
    line-height: 1.8125rem; /* 145% */
    letter-spacing: -0.03125rem;
  }
`;

const BlockImage = styled.div`
  width: 24rem;
  height: 21rem;

  background: ${({ bgColor }) => bgColor};
  background-image: ${({ src }) => `url(${src})`};
  background-size: 17rem;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.75rem;

  @media (max-width: ${MOBILE}) {
    width: 100%;
    height: 10.375rem;
    background-size: 9rem;
    margin-bottom: 1.88rem;
    object-fit: cover;
    border-radius: 0.65894rem;
  }
`;

const BlockTime = styled.p`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  padding: 0.375rem 0.75rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  border-radius: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${MOBILE}) {
    font-size: 0.8125rem;
  }
`;

const BlockDescription = styled.p`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 160% */
  letter-spacing: -0.03125rem;
  @media (max-width: ${MOBILE}) {
  }
`;

const BlockButton = styled.button`
  border-radius: 0.4375rem;
  background: ${({ theme }) => theme.primary.normal};
  width: auto;
  height: 3.1875rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  color: ${({ theme }) => theme.grayScaleColor.white};
  border: none;
  cursor: pointer;
  @media (max-width: ${MOBILE}) {
    margin-top: 1.94rem;
    margin-bottom: 1rem;
    width: 100%;
    font-size: 0.9375rem;
  }
`;

const Banner = styled.div`
  width: 100%;
  position: relative;

  @media (max-width: 425px) {
    margin-top: 1.88rem;
  }
`;

const BannerImage = styled.img`
  width: 100%;
`;

const BannerButton = styled.button`
  padding: 0.56rem 1.5rem;
  border-radius: 0.31rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  background: ${({ theme }) => theme.primary.normal};
  border: none;
  font-weight: 500;
  font-size: 0.94rem;
  cursor: pointer;
  @media (max-width: 425px) {
    width: ${({ width }) => width}px;
    padding: 0.56rem 0.45rem;
    line-height: 1.13rem;
    font-size: 0.88rem;
  }
`;

const Symbol = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 1.25rem;
  margin-right: 0.63rem;
  @media (max-width: 425px) {
    width: 1rem;
    height: 1rem;
    margin-left: 0;
    margin-right: 0.5rem;
  }
`;

const PERMAVAC = {
  engagement: "업무에 대한 집중",
  autonomy: "주도적인 업무 수행",
  competence: "일에 대한 자신감",
  achievement: "업무를 통한 성취",
  relationship: "동료와의 소통",
  positive_emotions: "직장에서의 좋은 감정",
  meaning: "업무를 통한 보람",
  vitality: "직장에서의 활력",
};

const Result = () => {
  const DATE_FORMAT = "M. D (ddd)";
  const CASH = 1000;
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { state } = useLocation();
  const location = useLocation();
  const [result, setResult] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [nextWellBeing, setNextWellBeing] = useState(0);
  const [wellBeing, setWellBeing] = useState(0);
  const [prevWellBeing, setPrevWellBeing] = useState(0);
  const [percent, setPercent] = useState(0);
  const [recommend, setRecommend] = useState("");
  const [low, setLow] = useState("");
  const [block, setBlock] = useState();
  const [highlight, setHighlight] = useState("");
  const [today, setToday] = useState(getToday("YYYY년 M월 D일(ddd)"));
  const [period, setPeriod] = useState({
    monday: getThisWeekMonday(new Date(), DATE_FORMAT),
    friday: getThisWeekFriday(new Date(), DATE_FORMAT),
  });
  const isMobile = useMediaQuery({ maxWidth: MOBILE });

  const name = isAuthEmpty(auth) ? "김블룸" : auth.user.name;

  const isTest =
    location.pathname.includes("test") || location.pathname.includes("demo");

  const FIXED = 1;

  const navigate = useNavigate();

  const handleRetryClick = () => {};

  const onStartTrainingClick = (e, to, action) => {
    ReactGA.event({ category: "button_click", action });

    navigate(to);
  };

  const handleGoToAISmile = () => {
    window.open(
      isAuthEmpty(auth)
        ? "https://app.maum.fit/smile-challenge"
        : "https://app.maum.fit/ai-smile",
      // location.pathname.includes("test") ? `${process.env.REACT_APP}`
      "_blank"
    );
  };

  const handleGoToChampions = () => {
    window.open(
      isAuthEmpty(auth)
        ? "https://app.maum.fit/smile-challenge/champions"
        : "https://app.maum.fit/ai-smile/champions",
      "_blank"
    );
  };

  useEffect(() => {
    if (!state?.surveyId) {
      navigate("/checkup/v2");
    }
    const fetchDemo = async () => {
      const score = Number(location.state.wellBeing.toFixed(FIXED));
      const response = await axios.get(
        `/checkup-v2/result/test?score=${score}`
      );
      const percentage =
        response.data.percentage <= 50
          ? `상위 ${Number(response.data.percentage.toFixed(FIXED))}%`
          : `하위 ${Number(101 - response.data.percentage).toFixed(FIXED)}%`;
      // const prev = Number(response.data.prevWellBeing.toFixed(FIXED));

      // const next =
      //   prev > score
      //     ? Number(Math.max(0, score - (prev - score)).toFixed(FIXED))
      //     : Number(Math.min(100, score + score - prev).toFixed(FIXED));
      setWellBeing(score);
      // setPrevWellBeing(prev);
      // setNextWellBeing(next);
      setLow(location.state.low);
      setPercent(percentage);
      setResult(null);

      if (true) {
        setTitle(`😉 ${name} 님, 첫 마음 체크업을 환영합니다!`);
        setDescription(
          lineBreaker(
            `이번 주 마음 웰빙 지수는 ${score}점이네요. 개인적으로 ${
              PERMAVAC[location.state.high]
            }${
              isEndWithConsonant(PERMAVAC[location.state.high]) ? "은" : "는"
            } 높았지만, ${PERMAVAC[location.state.low]}${
              isEndWithConsonant(PERMAVAC[location.state.low]) ? "이" : "가"
            } 낮은 한 주였습니다.\n\n전체적으로는 ${percentage}에 속해 있어요. ${name} 님이 마음 트레이닝을 열심히 하신다면 이번 주 보다 더 올릴 수 있을 거라 기대됩니다. 꾸준히 올라갈 ${name} 님의 마음 건강을 응원해요🙌`
          )
        );
      }
      setIsLoading(false);
    };
    const fetch = async () => {
      const response = await axiosPrivate.get(
        `/checkup-v2/result?surveyId=${state.surveyId}`
      );
      if (!response.data.ok) {
        setIsLoading(false);
      }
      setBlock(response.data.result.blockId);
      setRecommend(response.data.result.chatGPTRecommend);
      const percentage =
        response.data.percentage <= 50
          ? `상위 ${Number(response.data.percentage.toFixed(FIXED))}%`
          : `하위 ${Number(101 - response.data.percentage).toFixed(FIXED)}%`;
      const prev = Number(response.data.prevWellBeing.toFixed(FIXED));

      const score = Number(response.data.result.wellBeingScore.toFixed(FIXED));

      const next =
        prev > score
          ? Number(Math.max(0, score - (prev - score)).toFixed(FIXED))
          : Number(Math.min(100, score + score - prev).toFixed(FIXED));
      setWellBeing(score);
      setPrevWellBeing(prev);
      setNextWellBeing(next);
      setPercent(percentage);
      setResult(response.data);
      setPeriod({
        monday: getDate(response.data.result.surveyId.weekStartAt, DATE_FORMAT),
        friday: getDate(response.data.result.surveyId.weekEndAt, DATE_FORMAT),
      });
      setToday(getDate(response.data.result.createdAt, "YYYY년 M월 D일(ddd)"));

      setLow(response.data.result.low);
      if (response.data.total === 1) {
        setTitle(`😉 ${name} 님, 첫 마음 체크업을 환영합니다!`);
        setDescription(
          lineBreaker(
            `이번 주 마음 웰빙 지수는 ${score}점이네요. 개인적으로 ${
              PERMAVAC[response.data.result.high]
            }${
              isEndWithConsonant(PERMAVAC[response.data.result.high])
                ? "은"
                : "는"
            } 높았지만, ${PERMAVAC[response.data.result.low]}${
              isEndWithConsonant(PERMAVAC[response.data.result.low])
                ? "이"
                : "가"
            } 낮은 한 주였습니다.\n\n전체적으로는 ${percentage}에 속해 있어요. ${name} 님이 마음 트레이닝을 열심히 하신다면 이번 주 보다 더 올릴 수 있을 거라 기대됩니다. 꾸준히 올라갈 ${name} 님의 마음 건강을 응원해요🙌`
          )
        );
      } else if (score > prev) {
        setTitle(`🥳 ${name} 님, 멋진 변화를 이뤄내고 있어요!`);
        setDescription(
          lineBreaker(
            `지난번 마음 체크업보다 마음 웰빙 지수가 조금 높아졌네요. 개인적으로 ${
              PERMAVAC[response.data.result.high]
            }${
              isEndWithConsonant(PERMAVAC[response.data.result.high])
                ? "은"
                : "는"
            } 높았지만, ${PERMAVAC[response.data.result.low]}${
              isEndWithConsonant(PERMAVAC[response.data.result.low])
                ? "이"
                : "가"
            } 낮은 한 주였습니다.\n\n전체적으로는 ${percentage}에 속해 있어요. 다음 번 나의 마음 웰빙 지수는 ${next}점이며 ${(
              score - prev
            ).toFixed(
              FIXED
            )}점 올라갈 것으로 예측됩니다. 꾸준한 마음 트레이닝을 통해 조금 더 상위권에 도달할 ${name} 님을 응원합니다!`
          )
        );
      } else if (score < prev) {
        setTitle(`😶 ${name} 님, 요즘 직장에서 잘 지내고 계신가요?`);
        setDescription(
          lineBreaker(
            `지난번 마음 체크업보다 마음 웰빙 지수가 아주 살짝 낮아졌네요. 개인적으로 ${
              PERMAVAC[response.data.result.high]
            }${
              isEndWithConsonant(PERMAVAC[response.data.result.high])
                ? "은"
                : "는"
            } 높았지만, ${PERMAVAC[response.data.result.low]}${
              isEndWithConsonant(PERMAVAC[response.data.result.low])
                ? "이"
                : "가"
            } 낮은 한 주였습니다.\n\n전체적으로는 ${percentage}에 속해 있어요. 다음번 나의 마음 웰빙 지수는 ${next}점이며 ${(
              prev - score
            ).toFixed(
              FIXED
            )}점 내려갈 것으로 예측되지만, 마음 트레이닝을 통해 충분히 변화시킬 수 있다는 걸 잊지 마세요!💪`
          )
        );
      } else if (score === prev) {
        setTitle(`😃 ${name} 님, 충분히 잘 하고 있어요!`);
        setDescription(
          lineBreaker(
            `지난번 마음 체크업과 마음 웰빙 지수가 동일하네요. 개인적으로 ${
              PERMAVAC[response.data.result.high]
            }${
              isEndWithConsonant(PERMAVAC[response.data.result.high])
                ? "은"
                : "는"
            } 높았지만, ${PERMAVAC[response.data.result.low]}${
              isEndWithConsonant(PERMAVAC[response.data.result.low])
                ? "이"
                : "가"
            } 낮은 한 주였습니다.\n\n전체적으로는 ${percentage}에 속해 있어요. 다음번 나의 마음 웰빙 지수는 ${next}점이며 이번과 동일할 것으로 예측됩니다. 마음을 유지한다는 것도 충분히 잘 하고 있다는 것을 보여주지만, 마음 트레이닝을 통해 다음번에는 조금 더 올라갈 내 마음 웰빙 지수를 기대해보는 것도 좋을 것 같아요!`
          )
        );
      } else {
        setTitle(`😶 ${name} 님, 요즘 직장에서 잘 지내고 계신가요?`);
        setDescription(
          lineBreaker(
            `이번 주 마음 웰빙 지수는 ${score}점이네요. 개인적으로 ${
              PERMAVAC[response.data.result.high]
            }${
              isEndWithConsonant(PERMAVAC[response.data.result.high])
                ? "은"
                : "는"
            } 높았지만, ${PERMAVAC[response.data.result.low]}${
              isEndWithConsonant(PERMAVAC[response.data.result.low])
                ? "이"
                : "가"
            } 낮은 한 주였습니다.\n\n전체적으로는 ${percentage}에 속해 있어요. 다음번 나의 마음 웰빙 지수는 몇 점일까요? ${name} 님이 마음 트레이닝을 열심히 하신다면 다음번 나의 마음 웰빙 지수는 ${next}점까지 올라갈 수 있을 거라 기대됩니다. 꾸준히 올라갈 ${name} 님의 마음 건강을 응원해요🙌`
          )
        );
      }

      setIsLoading(false);
    };
    if (
      location.pathname.includes("test") ||
      location.pathname.includes("demo")
    ) {
      fetchDemo();
    } else {
      fetch();
    }
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <Helmet>
        <title>맘핏 | 마음체크업 결과</title>
      </Helmet>
      <Wrapper>
        <Heading2 style={{ marginTop: "3.13rem" }}>마음 체크업 완료</Heading2>
        {isMobile ? (
          <Heading1 style={{ marginTop: "0.75rem" }}>
            {name} 님의{" "}
            {result?.result.checkupCount ? result.result.checkupCount : 1}번째
            마음을
            <br />잘 기록하였습니다
          </Heading1>
        ) : (
          <Heading1 style={{ marginTop: "0.75rem" }}>
            {name} 님의{" "}
            {result?.result.checkupCount ? result.result.checkupCount : 1}번째
            마음을 잘 기록하였습니다
          </Heading1>
        )}
        <Heading3 style={{ marginTop: "0.94rem" }}>
          {period.monday} ~ {period.friday}
        </Heading3>
        {/* <TipBox>
          💡 실력 있는 맘핏 전문가들이 주말 동안 고민하여 다음 주 월요일, {name}
          님에게 추천할 마음 트레이닝을 보내 드립니다.{" "}
        </TipBox> */}
        <InfoContainer>
          <FlexRow>
            <CalendarIcon src={`${process.env.PUBLIC_URL}/svg/calendar.svg`} />
            <DateText>{today}</DateText>
            <TextBox>
              연속 체크업:{" "}
              {result?.result.checkupInARow ? result.result.checkupInARow : 1}회
            </TextBox>
          </FlexRow>
        </InfoContainer>
        {isTest ? (
          <div />
        ) : isMobile ? (
          <FlexColumn
            style={{ width: "100%", gap: "22px", marginBottom: "1.75rem" }}
          >
            <FlexRow style={{ gap: "22px" }}>
              <ItemBox>
                <ItemIcon
                  src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                />{" "}
                <ItemHeading>오늘 획득 캐시</ItemHeading>
                <ItemText>
                  {!result
                    ? "1,000"
                    : result.result.todayEarnedCash
                    ? result.result.todayEarnedCash.toLocaleString()
                    : auth.user.isFirstCheckup
                    ? CASH.toLocaleString()
                    : auth.user.lastCheckupPoint === 1000
                    ? CASH.toLocaleString()
                    : result.result.userId.checkupInARow < 5
                    ? result.result.userId.checkupInARow * 200
                    : CASH.toLocaleString()}
                  {/* {result.result.userId.checkupInARow < 5
                    ? result.result.userId.checkupInARow * 200
                    : "1,000"} */}
                  <ItemCurrency>원</ItemCurrency>
                </ItemText>
              </ItemBox>
              <ItemBox>
                <ItemIcon
                  src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                />
                <ItemHeading>누적 캐시</ItemHeading>
                <ItemText>
                  {!result
                    ? "10,000"
                    : result.result.totalEarnedCash
                    ? result.result.totalEarnedCash.toLocaleString()
                    : result.result.userId.point.toLocaleString()}
                  <ItemCurrency>원</ItemCurrency>
                </ItemText>
              </ItemBox>
              {/* <ItemBox>
                <ItemIcon
                  src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                />
                <ItemHeading>다음 주 획득 캐시</ItemHeading>
                <ItemText>
                  {result.result.userId.checkupInARow + 1 < 5
                    ? (result.result.userId.checkupInARow + 1) * 200
                    : "1,000"}
                  <ItemCurrency>원</ItemCurrency>
                </ItemText>
              </ItemBox> */}
            </FlexRow>
            {/* <FlexRow style={{ gap: "22px" }}>
              <ItemBox>
                <ItemIcon
                  src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                />
                <ItemHeading>누적 캐시</ItemHeading>
                <ItemText>
                  {result.result.userId.point.toLocaleString()}
                  <ItemCurrency>원</ItemCurrency>
                </ItemText>
              </ItemBox>
              <ItemBox>
                <ItemIcon
                  src={`${process.env.PUBLIC_URL}/assets/icons/vibe_icon.png`}
                />
                <ItemHeading>누적 바이브</ItemHeading>
                <ItemText>
                  {result.result.userId.vibe.toLocaleString()}
                </ItemText>
              </ItemBox>
            </FlexRow> */}
          </FlexColumn>
        ) : (
          // <SectionContainer style={{ marginBottom: "1.5rem" }}>
          <FlexRow
            style={{
              justifyContent: "space-between",
              // border: "1px solid black",
              height: "100%",
              width: "100%",
              marginBottom: "2rem",
            }}
          >
            <FlexRow
              style={{
                alignItems: "center",

                padding: "1.81rem 7.63rem",
                background: "#FFFFFF",
                borderRadius: "0.94rem",
              }}
            >
              <ItemHeading style={{ margin: 0 }}>
                <ItemIcon
                  src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                />{" "}
                오늘 획득 캐시
              </ItemHeading>
              <ItemText style={{ fontSize: "1.75rem", marginLeft: "1rem" }}>
                {!result
                  ? "1,000"
                  : result.result.todayEarnedCash
                  ? result.result.todayEarnedCash.toLocaleString()
                  : auth.user.isFirstCheckup
                  ? CASH.toLocaleString()
                  : auth.user.lastCheckupPoint === 1000
                  ? CASH.toLocaleString()
                  : result.result.userId.checkupInARow < 5
                  ? result.result.userId.checkupInARow * 200
                  : CASH.toLocaleString()}
                <ItemCurrency>원</ItemCurrency>
              </ItemText>
            </FlexRow>
            {/* <ItemSeparator /> */}
            {/* <FlexColumn style={{ alignItems: "center" }}>
                <ItemHeading>
                  {" "}
                  <ItemIcon
                    src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                  />{" "}
                  다음 주 획득 캐시
                </ItemHeading>
                <ItemText>
                  {result.result.userId.checkupInARow + 1 < 5
                    ? (result.result.userId.checkupInARow + 1) * 200
                    : "1,000"}
                  <ItemCurrency>원</ItemCurrency>
                </ItemText>
              </FlexColumn> */}
            {/* <ItemSeparator /> */}
            <FlexRow
              style={{
                alignItems: "center",
                padding: "1.81rem 7.63rem",
                background: "#FFFFFF",
                borderRadius: "0.94rem",
              }}
            >
              <ItemHeading style={{ margin: 0 }}>
                {" "}
                <ItemIcon
                  src={`${process.env.PUBLIC_URL}/assets/icons/cash_icon.png`}
                />{" "}
                누적 캐시
              </ItemHeading>
              <ItemText style={{ fontSize: "1.75rem", marginLeft: "1rem" }}>
                {!result
                  ? "10,000"
                  : result.result.totalEarnedCash
                  ? result.result.totalEarnedCash.toLocaleString()
                  : result.result.userId.point.toLocaleString()}
                <ItemCurrency>원</ItemCurrency>
              </ItemText>
            </FlexRow>
            {/* <ItemSeparator />
              <FlexColumn style={{ alignItems: "center" }}>
                <ItemHeading>
                  {" "}
                  <ItemIcon
                    src={`${process.env.PUBLIC_URL}/assets/icons/vibe_icon.png`}
                  />{" "}
                  누적 바이브
                </ItemHeading>
                <ItemText>
                  {result.result.userId.vibe.toLocaleString()}
                </ItemText>
              </FlexColumn> */}
          </FlexRow>
          // </SectionContainer>
        )}
        {isTest ? (
          <div></div>
        ) : (
          <TipText>
            📢 매주 체크업 응답 시 리워드 캐시 1,000원을 지급해 드려요. 단, 연속
            체크업이 중단되면 200원으로 감소 후 단계적으로 회복됩니다.
          </TipText>
        )}
        <SectionContainer>
          <SectionHeading2 style={{ marginBottom: "0.56rem" }}>
            이번 주, 마음 기록
          </SectionHeading2>
          <SectionHeading1>
            마음 웰빙 지수는{" "}
            <span style={{ fontWeight: 700 }}>{wellBeing}점</span>
            <span style={{ color: "#7C8585" }}>({percent})</span>
            {prevWellBeing === 0 ? "입니다." : "으로,"}
            {prevWellBeing !== 0
              ? prevWellBeing > wellBeing
                ? ` 지난번과 비교해 ${Number(prevWellBeing - wellBeing).toFixed(
                    FIXED
                  )}점 내려갔습니다.`
                : prevWellBeing < wellBeing
                ? ` 지난번과 비교해 ${Number(wellBeing - prevWellBeing).toFixed(
                    FIXED
                  )}점 올라갔습니다.`
                : ` 지난번과 동일합니다.`
              : ""}
          </SectionHeading1>
          <Divider />
          {isMobile ? (
            <FlexColumn>
              <Chart>
                <BarChart
                  last={result?.total > 1 ? prevWellBeing : null}
                  me={wellBeing}
                  next={result?.total > 1 ? nextWellBeing : null}
                />
              </Chart>
              <Title>{title}</Title>
              <Descripton>{description}</Descripton>
              {isTest && location.state.block ? (
                <BlockButton
                  style={{ alignSelf: "start" }}
                  onClick={(e) => {
                    onStartTrainingClick(
                      e,
                      `/training/${location.state.block.number}`,
                      "checkup_training_a_click"
                    );
                  }}
                >
                  {PERMAVAC[result ? result.result.low : location.state.low]},
                  빠르게 높이러 가기 🚀
                </BlockButton>
              ) : !isTest && block ? (
                <BlockButton
                  style={{ alignSelf: "start", padding: "0 2rem" }}
                  onClick={(e) => {
                    onStartTrainingClick(
                      e,
                      `/blocks/${block.number}`,
                      "checkup_training_a_click"
                    );
                  }}
                >
                  {PERMAVAC[result ? result.result.low : location.state.low]},
                  빠르게 높이러 가기 🚀
                </BlockButton>
              ) : (
                <></>
              )}
              {/* <RandomText>
                <Symbol
                  src={`${process.env.PUBLIC_URL}/assets/symbol/symbol_sm.png`}
                />
                <p>
                  다음 주{" "}
                  {`${
                    PERMAVAC[result ? result.result.low : location.state.low]
                  }${
                    isEndWithConsonant(
                      PERMAVAC[result ? result.result.low : location.state.low]
                    )
                      ? "을"
                      : "를"
                  }`}{" "}
                  높여줄 행운의 소품 :
                  <span style={{ fontWeight: 700, marginLeft: "5px" }}>
                    {result ? result.result.randomItem.name : "3색 볼펜"}
                  </span>
                </p>
              </RandomText> */}
              <FlexColumn style={{ marginTop: "2.5rem" }}>
                <HighlightTitle>이번 주 하이라이트 ✍️</HighlightTitle>
                <HighlightCallout
                  style={{
                    color:
                      !result && state.weekHighlight
                        ? "#222"
                        : !result && !state.weekHighlight
                        ? "#BBB"
                        : result.result.weekHighlight
                        ? "#222"
                        : "#BBB",
                  }}
                >
                  {!result && state.weekHighlight
                    ? state.weekHighlight
                    : !result && !state.weekHighlight
                    ? "하이라이트 기록이 없어요"
                    : result.result.weekHighlight
                    ? result.result.weekHighlight
                    : "하이라이트 기록이 없어요"}
                </HighlightCallout>
              </FlexColumn>
            </FlexColumn>
          ) : (
            <FlexColumn>
              <FlexRow style={{ justifyContent: "space-between" }}>
                <Chart>
                  <BarChart
                    last={result?.total > 1 ? prevWellBeing : null}
                    me={wellBeing}
                    next={result?.total > 1 ? nextWellBeing : null}
                  />
                </Chart>
                <FlexColumn style={{ width: "26.94rem" }}>
                  <Title>{title}</Title>
                  <Descripton>{description}</Descripton>
                  {isTest && location.state.block ? (
                    <BlockButton
                      style={{ alignSelf: "start", padding: "0 2.26rem" }}
                      onClick={(e) => {
                        onStartTrainingClick(
                          e,
                          `/training/${location.state.block.number}`,
                          "checkup_training_a_click"
                        );
                      }}
                    >
                      {
                        PERMAVAC[
                          result ? result.result.low : location.state.low
                        ]
                      }
                      , 빠르게 높이러 가기 🚀
                    </BlockButton>
                  ) : !isTest && block ? (
                    <BlockButton
                      style={{ alignSelf: "start", padding: "0 2.26rem" }}
                      onClick={(e) => {
                        onStartTrainingClick(
                          e,
                          `/blocks/${block.number}`,
                          "checkup_training_a_click"
                        );
                      }}
                    >
                      {
                        PERMAVAC[
                          result ? result.result.low : location.state.low
                        ]
                      }
                      , 빠르게 높이러 가기 🚀
                    </BlockButton>
                  ) : (
                    <></>
                  )}
                  {/* <RandomText>
                    <Symbol
                      src={`${process.env.PUBLIC_URL}/assets/symbol/symbol_sm.png`}
                    />
                    다음 주{" "}
                    {`${
                      PERMAVAC[result ? result.result.low : location.state.low]
                    }${
                      isEndWithConsonant(
                        PERMAVAC[
                          result ? result.result.low : location.state.low
                        ]
                      )
                        ? "을"
                        : "를"
                    }`}{" "}
                    높여줄 행운의 소품 :
                    <span style={{ fontWeight: 700, marginLeft: "5px" }}>
                      {result ? result.result.randomItem.name : "3색 볼펜"}
                    </span>
                  </RandomText> */}
                </FlexColumn>
              </FlexRow>
              <FlexColumn style={{ marginTop: "4.5rem" }}>
                <HighlightTitle>이번 주 하이라이트 ✍️</HighlightTitle>
                <HighlightCallout
                  style={{
                    color:
                      !result && state.weekHighlight
                        ? "#222"
                        : !result && !state.weekHighlight
                        ? "#BBB"
                        : result.result.weekHighlight
                        ? "#222"
                        : "#BBB",
                  }}
                >
                  {!result && state.weekHighlight
                    ? state.weekHighlight
                    : !result && !state.weekHighlight
                    ? "하이라이트 기록이 없어요"
                    : result.result.weekHighlight
                    ? result.result.weekHighlight
                    : "하이라이트 기록이 없어요"}
                </HighlightCallout>
              </FlexColumn>
            </FlexColumn>
          )}
          {isTest ? (
            <FlexColumn style={{ marginTop: "4.69rem" }}>
              <SectionHeading2 style={{ marginBottom: "0.56rem" }}>
                마음 웰빙 지수를 높이는 트레이닝
              </SectionHeading2>
              <SectionHeading1>
                {name} 님,{" "}
                <span style={{ fontWeight: 600 }}>{PERMAVAC[low]}</span>
                {`${isEndWithConsonant(PERMAVAC[low]) ? "을" : "를"}`} 높일 수
                있는 마음 트레이닝 시작해 볼까요?
              </SectionHeading1>
              <Divider
                style={{
                  marginBottom: isMobile && "1.44rem",
                  marginTop: isMobile && "1.37rem",
                }}
              />
              {isMobile ? (
                <FlexColumn>
                  <BlockImage
                    src={location.state.block.reportThumbnail}
                    alt="training thumbnail"
                    // style={{ width: "24rem", height: "21rem" }}
                  />
                  <FlexColumn style={{ justifyContent: "space-between" }}>
                    <FlexColumn style={{ gap: "1.5rem" }}>
                      <FlexColumn style={{ gap: "0.5rem" }}>
                        <BlockTitle>{location.state.block.title}</BlockTitle>
                        <BlockTime style={{ alignSelf: "start" }}>
                          소요시간: {location.state.block.time}분
                        </BlockTime>
                      </FlexColumn>
                      <BlockDescription>
                        {lineBreaker(
                          location.state.block.recommendBlockDescription
                        )}
                      </BlockDescription>
                    </FlexColumn>
                    <BlockButton
                      onClick={(e) => {
                        onStartTrainingClick(
                          e,
                          `/training/${location.state.block.number}`,
                          "checkup_training_b_click"
                        );
                      }}
                    >
                      지금 바로 트레이닝 하기 💪
                    </BlockButton>
                  </FlexColumn>
                </FlexColumn>
              ) : (
                <FlexRow style={{ gap: "5rem" }}>
                  <BlockImage
                    src={location.state.block.reportThumbnail}
                    alt="training thumbnail"
                    // style={{ width: "24rem", height: "21rem" }}
                  />
                  <FlexColumn style={{ justifyContent: "space-between" }}>
                    <FlexColumn style={{ gap: "1.5rem" }}>
                      <FlexRow style={{ gap: "0.75rem" }}>
                        <BlockTitle>{location.state.block.title}</BlockTitle>
                        <BlockTime>
                          소요시간: {location.state.block.time}분
                        </BlockTime>
                      </FlexRow>
                      <BlockDescription>
                        {lineBreaker(
                          location.state.block.recommendBlockDescription
                        )}
                      </BlockDescription>
                    </FlexColumn>
                    <BlockButton
                      onClick={(e) => {
                        onStartTrainingClick(
                          e,
                          `/training/${location.state.block.number}`,
                          "checkup_training_b_click"
                        );
                      }}
                    >
                      지금 바로 트레이닝 하기 💪
                    </BlockButton>
                  </FlexColumn>
                </FlexRow>
              )}
            </FlexColumn>
          ) : block ? (
            <FlexColumn style={{ marginTop: "4.69rem" }}>
              <SectionHeading2 style={{ marginBottom: "0.56rem" }}>
                마음 웰빙 지수를 높이는 트레이닝
              </SectionHeading2>
              <SectionHeading1>
                {name} 님,{" "}
                <span style={{ fontWeight: 600 }}>{PERMAVAC[low]}</span>
                {`${isEndWithConsonant(PERMAVAC[low]) ? "을" : "를"}`} 높일 수
                있는 마음 트레이닝 시작해 볼까요?
              </SectionHeading1>
              <Divider
                style={{
                  marginBottom: isMobile && "1.44rem",
                  marginTop: isMobile && "1.37rem",
                }}
              />
              {isMobile ? (
                <FlexColumn>
                  <BlockImage
                    src={block.iconUrl}
                    bgColor={convertBGColor(block.theme)}
                    alt="training thumbnail"
                  />
                  <FlexColumn style={{ justifyContent: "space-between" }}>
                    <FlexColumn style={{ gap: "1.5rem" }}>
                      <FlexColumn style={{ gap: "0.5rem" }}>
                        <BlockTitle>{block.title}</BlockTitle>
                        <BlockTime style={{ alignSelf: "start" }}>
                          소요시간: {block.time}분
                        </BlockTime>
                      </FlexColumn>
                      <BlockDescription>
                        {lineBreaker(block.recommendBlockDescription)}
                      </BlockDescription>
                    </FlexColumn>
                    <BlockButton
                      onClick={(e) =>
                        onStartTrainingClick(
                          e,
                          `/blocks/${block.number}`,
                          "checkup_training_b_click"
                        )
                      }
                    >
                      지금 바로 트레이닝 하기 💪
                    </BlockButton>
                  </FlexColumn>
                </FlexColumn>
              ) : (
                <FlexRow style={{ gap: "5rem" }}>
                  <BlockImage
                    src={block.iconUrl}
                    bgColor={convertBGColor(block.theme)}
                    alt="training thumbnail"
                    style={{ width: "24rem", height: "21rem" }}
                  />
                  <FlexColumn style={{ justifyContent: "space-between" }}>
                    <FlexColumn style={{ gap: "1.5rem" }}>
                      <FlexRow style={{ gap: "0.75rem" }}>
                        <BlockTitle>{block.title}</BlockTitle>
                        <BlockTime>소요시간: {block.time}분</BlockTime>
                      </FlexRow>
                      <BlockDescription>
                        {lineBreaker(block.recommendBlockDescription)}
                      </BlockDescription>
                    </FlexColumn>
                    <BlockButton
                      onClick={(e) =>
                        onStartTrainingClick(
                          e,
                          `/blocks/${block.number}`,
                          "checkup_training_b_click"
                        )
                      }
                    >
                      지금 바로 트레이닝 하기 💪
                    </BlockButton>
                  </FlexColumn>
                </FlexRow>
              )}
            </FlexColumn>
          ) : (
            <div />
          )}
        </SectionContainer>
        {/* <SectionContainer>
          <SectionHeading1 style={{ marginBottom: "1.44rem" }}>
            {result.total}번째 체크업 리워드
          </SectionHeading1>
          {isMobile ? (
            <FlexColumn style={{ gap: "1.25rem" }}>
              <Box
                type="now"
                icon={`${process.env.PUBLIC_URL}/assets/icons/vibe_icon.png`}
                text="바이브"
                background={"#E8FBF7"}
                point={100}
                name={name}
              />
              <Box
                type="now"
                icon={`${process.env.PUBLIC_URL}/assets/icons/point_icon.png`}
                text="캐시"
                background={"#E8FBF7"}
                point={1000}
              />
              <Box
                type="all"
                icon={`${process.env.PUBLIC_URL}/assets/icons/vibe_icon.png`}
                text="누적 바이브"
                background={"#F7F7F7"}
                point={result.result.userId.vibe}
              />
              <Box
                type="all"
                icon={`${process.env.PUBLIC_URL}/assets/icons/point_icon.png`}
                text="누적 캐시"
                background={"#F7F7F7"}
                point={result.result.userId.point}
              />
            </FlexColumn>
          ) : (
            <>
              <FlexRow style={{ gap: "1.88rem", marginBottom: "1.06rem" }}>
                <Box
                  type="now"
                  icon={`${process.env.PUBLIC_URL}/assets/icons/vibe_icon.png`}
                  text="바이브"
                  background={"#E8FBF7"}
                  point={100}
                  name={name}
                />
                <Box
                  type="now"
                  icon={`${process.env.PUBLIC_URL}/assets/icons/point_icon.png`}
                  text="캐시"
                  background={"#E8FBF7"}
                  point={1000}
                />
              </FlexRow>
              <FlexRow style={{ gap: "1.88rem" }}>
                <Box
                  type="all"
                  icon={`${process.env.PUBLIC_URL}/assets/icons/vibe_icon.png`}
                  text="누적 바이브"
                  background={"#F7F7F7"}
                  point={result.result.userId.vibe}
                />
                <Box
                  type="all"
                  icon={`${process.env.PUBLIC_URL}/assets/icons/point_icon.png`}
                  text="누적 캐시"
                  background={"#F7F7F7"}
                  point={result.result.userId.point}
                />
              </FlexRow>
            </>
          )}
        </SectionContainer> */}
        {/* <SectionContainer>
          <FlexRow style={{ marginBottom: "1.88rem" }}>
            <Chips>Mission</Chips>
            <SectionHeading2 style={{ color: "#111" }}>
              마음 체크업 연속 5회 달성하기{" "}
            </SectionHeading2>
          </FlexRow>
          <FlexRow style={{ gap: "1.88rem" }}>
            {Array(5)
              .fill(0)
              .map((item, index) => {
                return <StampBox index={index} complete={index === 0} />;
              })}
          </FlexRow>
        </SectionContainer> */}
        <SectionContainer>
          <Recommend
            surveyId={state.surveyId}
            name={name}
            weekSentence={
              result ? result.result.weekSentence : location.state.weekSentence
            }
            recommendQuestion={
              result
                ? result.result.recommendQuestion
                : location.state.recommendQuestion
            }
            chatGPTPrompt={
              result
                ? result.result.chatGPTPrompt
                : location.state.chatGPTPrompt
            }
            handleRetryClick={handleRetryClick}
            data={recommend}
          />
        </SectionContainer>
        {isMobile ? (
          <Banner>
            <BannerImage
              src={`${process.env.PUBLIC_URL}/assets/checkup/result_banner_mobile.png`}
            />
            <FlexRow
              style={{
                gap: "10px",
                position: "absolute",
                top: "33%",
                padding: "0 1.56rem",
                right: 0,
                left: 0,
                margin: "0 auto",
                justifyContent: "space-evenly",
                // bottom: "2.88rem",
                // left: "3.13rem",
              }}
            >
              <BannerButton onClick={handleGoToAISmile}>
                챌린지 참여하기
              </BannerButton>
              <BannerButton
                onClick={handleGoToChampions}
                style={{ background: "#9FB4B0" }}
              >
                찐-미소 챔피언즈 구경하기
              </BannerButton>
            </FlexRow>
          </Banner>
        ) : (
          <Banner>
            <BannerImage
              src={`${process.env.PUBLIC_URL}/assets/checkup/result_banner.png`}
            />
            <FlexRow
              style={{
                gap: "10px",
                position: "absolute",
                bottom: "2.88rem",
                left: "3.13rem",
              }}
            >
              <BannerButton onClick={handleGoToAISmile}>
                챌린지 참여하기
              </BannerButton>
              <BannerButton
                onClick={handleGoToChampions}
                style={{ background: "#9FB4B0" }}
              >
                찐-미소 챔피언즈 구경하기
              </BannerButton>
            </FlexRow>
          </Banner>
        )}
      </Wrapper>
    </Container>
  );
};

export default Result;
