import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.38rem;
`;

const Wrapper = styled.div`
  max-width: 62.5rem;
  width: 100%;
`;

const Navbar = styled.nav`
  //   max-width: 62.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  margin-bottom: 2.5rem;
`;

const BackButtonSvg = styled.svg`
  cursor: pointer;
`;

const Header = styled.h1`
  font-size: 2.25rem;
  line-height: 2.88rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 700;
  margin-top: 2.5rem;
  margin-bottom: 1.06rem;
`;

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <BackButtonSvg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        navigate("/");
      }}
    >
      <path
        d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM19 9C19.5523 9 20 8.55228 20 8C20 7.44772 19.5523 7 19 7V9ZM1 9H19V7H1V9Z"
        fill="black"
      />
    </BackButtonSvg>
  );
};

const GuideLayout = ({ header }) => {
  return (
    <Container>
      <Wrapper>
        <Navbar>
          <BackButton />
          <Header>{header}</Header>
        </Navbar>
        <Outlet />
      </Wrapper>
    </Container>
  );
};
export default GuideLayout;
