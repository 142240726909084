import styled from "styled-components";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import CardLayout from "./CardLayout";
import NoDataText from "./components/NoDataText";

const Container = styled.div``;
const Circle = styled.div`
  width: 0.6875rem;
  height: 0.6875rem;
  border-radius: 50%;
`;

const Text1 = styled.p`
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
`;

const Text2 = styled.p`
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Card3 = ({ data, style }) => {
  return (
    <Container style={style}>
      <FlexColumn style={{ gap: "1.75rem" }}>
        {data?.map((item, index) => {
          return (
            <FlexRow
              key={index}
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <FlexRow style={{ alignItems: "center", gap: "0.5rem" }}>
                <Circle style={{ background: item.background }} />
                <Text1>{item.title}</Text1>
              </FlexRow>
              <FlexRow style={{ alignItems: "center", gap: "0.25rem" }}>
                {item.valueIcon && (
                  <img
                    style={{ width: "0.875rem", height: "0.875rem" }}
                    src={item.valueIcon}
                    alt="cash icon"
                  />
                )}
                {item.value ? <Text2>{item.value}</Text2> : <NoDataText />}
              </FlexRow>
            </FlexRow>
          );
        })}
      </FlexColumn>
    </Container>
  );
};

export default Card3;
