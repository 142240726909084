import { useEffect, useState } from "react";
import styled from "styled-components";
import FlexRow from "../../../components/FlexRow";

const Container = styled.div`
  position: relative;
`;

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.19rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  background: ${({ theme }) => theme.backgroundColor.darkgray20};
  border: none;
  border-radius: 0.31rem;
  gap: 0.25rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
`;

const FilterContainer = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  right: 0;
  width: 17.88rem;
  border-radius: 0.94rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 1.38rem 1.25rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
`;

const Category = styled.h3`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.19rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.black};
  padding: 0.5rem 0;
`;

const OptionContainer = styled.div`
  margin-top: 0.75rem;
  margin-bottom: 1.25rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;
const OptionButtonContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 0.25rem 0.56rem;
  border-radius: 3.13rem;
  font-size: 0.88rem;
  font-weight: 400;
  color: ${({ theme }) => theme.grayScaleColor.black};
  display: flex;
  align-items: center;
  max-width: fit-content;
  &:span {
    line-height: 1.19rem;
  }
  cursor: pointer;
`;

const OptionCheckMark = styled.div`
  width: 0.94rem;
  height: 0.94rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, checked }) =>
    checked ? theme.primary.normal : "#E4E4E4"};
  margin-right: 0.31rem;
`;

const Button = styled.button`
  background: ${({ theme }) => theme.primary.normal};
  color: white;
  padding: 0.75rem 0;
  border-radius: 0.31rem;
  border: none;
  font-weight: 600;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
`;

const ResetButton = styled(Button)`
  background: ${({ theme, enable }) =>
    enable ? theme.primary.normal : theme.grayScaleColor.gray10};
  color: ${({ theme, enable }) =>
    enable ? theme.grayScaleColor.white : theme.backgroundColor.darkgray40};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
`;

const CheckmarkSvg = () => {
  return (
    <svg
      width="8"
      height="7"
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.45453 0.903466C7.78401 1.1545 7.84761 1.62509 7.59658 1.95457L4.168 6.45457C4.03551 6.62847 3.83373 6.7359 3.61549 6.74875C3.39725 6.76159 3.18426 6.67857 3.03229 6.52141L0.460858 3.86232C0.172914 3.56456 0.180872 3.08975 0.478632 2.80181C0.776392 2.51386 1.2512 2.52182 1.53914 2.81958L3.50382 4.85124L6.40343 1.04551C6.65446 0.716029 7.12506 0.652435 7.45453 0.903466Z"
        fill="white"
      />
    </svg>
  );
};

const ResetSvg = ({ enable }) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.4705 14.3125C9.94322 14.3125 11.1972 13.795 12.2324 12.76C13.2676 11.725 13.7852 10.472 13.7852 9.00106C13.7852 7.5301 13.2676 6.27677 12.2324 5.24107C11.1972 4.20536 9.94394 3.6875 8.47267 3.6875C7.50554 3.6875 6.64873 3.89796 5.90226 4.31887C5.15578 4.73979 4.52782 5.31577 4.01837 6.04682V3.6875H3.2147V7.68139H7.20856V6.87771H4.41067C4.83657 6.1603 5.39552 5.58319 6.0875 5.14638C6.7795 4.70958 7.57455 4.49118 8.47267 4.49118C9.733 4.49118 10.7995 4.92749 11.6723 5.80011C12.5451 6.67272 12.9815 7.73908 12.9815 8.99919C12.9815 10.2593 12.5448 11.3259 11.6716 12.1991C10.7983 13.0722 9.73116 13.5088 8.47014 13.5088C7.51014 13.5088 6.63419 13.2341 5.84231 12.6847C5.05043 12.1353 4.49739 11.4092 4.18318 10.5066H3.35091C3.67965 11.6508 4.31693 12.5711 5.26277 13.2677C6.2086 13.9642 7.27784 14.3125 8.4705 14.3125Z"
        fill={enable ? "white" : "#7C8585"}
      />
    </svg>
  );
};

const OptionButton = ({ index, text, options, setOptions }) => {
  //   const [checked, setChecked] = useState(options[index] === 1);
  const handleClick = () => {
    // 체크안된 상태에서 클릭했을 때
    if (options[index] !== 1) {
      const arr = [...options];
      arr[index] = 1;
      setOptions(arr);
    } else {
      const arr = [...options];
      arr[index] = 0;
      setOptions(arr);
    }
    // setChecked((prev) => !prev);
  };
  return (
    <OptionButtonContainer onClick={handleClick}>
      <OptionCheckMark checked={options[index] === 1}>
        <CheckmarkSvg />
      </OptionCheckMark>
      <span>{text}</span>
    </OptionButtonContainer>
  );
};

const Filter = ({
  data,
  groupOptions,
  setGroupOptions,
  jobOptions,
  setJobOptions,
  genderOptions,
  setGenderOptions,
  ageOptions,
  setAgeOptions,
}) => {
  const jobs = [
    "경영관리/지원",
    "제조/생산",
    "유통/무역",
    "마케팅/광고",
    "디자인",
    "고객서비스",
    "IT/인터넷",
    "영업",
    "연구개발/설계",
    "기타",
  ];
  const [show, setShow] = useState(false);
  const [enable, setEnable] = useState(false);

  const checkEnable = () => {
    return (
      groupOptions.filter((i) => i === 1).length > 0 ||
      jobOptions.filter((i) => i === 1).length > 0 ||
      genderOptions.filter((i) => i === 1).length > 0 ||
      ageOptions.filter((i) => i === 1).length > 0
    );
  };

  const handleResetClick = () => {
    setGroupOptions([0, 0, 0]);
    setJobOptions(new Array(10).fill(0));
    setGenderOptions(new Array(2).fill(0));
    setAgeOptions(new Array(2).fill(0));
  };

  const handleFilterClick = () => {
    setShow((prev) => !prev);
  };
  const handleConfirmClick = () => {
    setShow(false);
  };

  return (
    <Container>
      <FilterButton onClick={handleFilterClick}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/filter_icon.png`}
          width={20}
          height={20}
          alt="filter icon"
        />{" "}
        필터
      </FilterButton>
      <FilterContainer show={show}>
        <Category>차수</Category>
        <OptionContainer>
          <OptionButton
            index={0}
            text="1차수"
            options={groupOptions}
            setOptions={setGroupOptions}
          />
          <OptionButton
            index={1}
            text="2차수"
            options={groupOptions}
            setOptions={setGroupOptions}
          />
          <OptionButton
            index={2}
            text="3차수"
            options={groupOptions}
            setOptions={setGroupOptions}
          />
        </OptionContainer>
        <Category>직무</Category>
        <OptionContainer>
          {jobs.map((item, index) => (
            <div key={index}>
              <OptionButton
                index={index}
                text={item}
                options={jobOptions}
                setOptions={setJobOptions}
              />
            </div>
          ))}
        </OptionContainer>
        <Category>성별</Category>
        <OptionContainer>
          <OptionButton
            index={0}
            text="남성"
            options={genderOptions}
            setOptions={setGenderOptions}
          />
          <OptionButton
            index={1}
            text="여성"
            options={genderOptions}
            setOptions={setGenderOptions}
          />
        </OptionContainer>
        <Category>연차</Category>
        <OptionContainer>
          <OptionButton
            index={0}
            text="0~3년 차"
            options={ageOptions}
            setOptions={setAgeOptions}
          />
          <OptionButton
            index={1}
            text="4~6년 차"
            options={ageOptions}
            setOptions={setAgeOptions}
          />
          <OptionButton
            index={2}
            text="7~9년 차"
            options={ageOptions}
            setOptions={setAgeOptions}
          />
          <OptionButton
            index={3}
            text="10년 차 이상"
            options={ageOptions}
            setOptions={setAgeOptions}
          />
        </OptionContainer>
        <FlexRow style={{ gap: "0.75rem" }}>
          <ResetButton onClick={handleResetClick} enable={checkEnable()}>
            <ResetSvg enable={checkEnable()} />
            <span>필터 초기화</span>
          </ResetButton>
          <Button onClick={handleConfirmClick}>적용하기</Button>
        </FlexRow>
      </FilterContainer>
    </Container>
  );
};

export default Filter;
