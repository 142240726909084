import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { TYPE_MEMBER } from "../../Constants";
import useAuth from "../../hooks/useAuth";
import ManagerDashboardSidebar from "../../pages/dashboard/ManagerDashboardSidebar";
import Sidebar from "../../pages/dashboard/Sidebar";
import { isAuthEmpty } from "../../utils/isAuthEmpty";
import FlexColumn from "../FlexColumn";
import FlexRow from "../FlexRow";
import ManagerDashboardTopBar from "../ManagerDashboardTopBar";
import TopBar from "../TopBar";
import ManagerIndexSidebar from "../../pages/dashboard/ManagerIndexSidebar";
import { SIDEBAR_ITEMS_PM } from "../../constants/sidebar";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const Container = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.darkgray20};
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ManagerDashboardLayout = ({ type = TYPE_MEMBER, items }) => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [params] = useSearchParams();
  const companyId = params.get("id") || auth.user.companyId._id;

  const { pathname } = useLocation();
  // const [isLoading, setIsLoading] = useState(true);
  const [company, setCompany] = useState("");

  useEffect(() => {
    const fetch = async () => {
      const response = await axiosPrivate.get(`/company/${companyId}`);
      if (response.data.ok) {
        setCompany(response.data.data.company);
      }
    };
    fetch();
  }, [companyId]);
  return (
    <Container>
      <FlexRow>
        {pathname === "/manager/dashboard/projects" ? (
          <ManagerIndexSidebar items={SIDEBAR_ITEMS_PM} />
        ) : (
          <ManagerDashboardSidebar
            items={items}
            type={type}
            companyId={companyId}
            hasMission={company.hasMission}
          />
        )}
        <FlexColumn style={{ width: "100%" }}>
          <ManagerDashboardTopBar
            type={type}
            name={!isAuthEmpty(auth) ? auth.user.name : "김블룸"}
            company={!isAuthEmpty(auth) ? company.name : "블룸컴퍼니"}
            sticky={true}
          />
          <Outlet />
        </FlexColumn>
      </FlexRow>
    </Container>
  );
};

export default ManagerDashboardLayout;
