import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Loading from "../../../components/Loading";
import { ROUTES } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import includesPathname from "../../../utils/includesPathname";
import { isAuthEmpty } from "../../../utils/isAuthEmpty";
import getSmileAverage from "../api/getSmileAverage";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.primary.normal};
`;

const Wrapper = styled.div`
  max-width: 51.75rem;
  width: 100%;
  height: 100%;
  max-height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: ${({ theme }) => theme.grayScaleColor.white};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
  /* width: 100%; */
`;

const Button = styled.div`
  padding: 1.06rem 0;
  background: ${({ theme }) => theme.primary.normal};
  border-radius: 0.63rem;
  color: white;
  font-weight: 600;
  letter-spacing: -0.04em;
  /* margin-bottom: 1.44rem; */
  width: 16rem;
  text-align: center;
  cursor: pointer;
  margin: 0 7.5px;
`;

const SecondaryButton = styled(Button)`
  background: ${({ theme }) => theme.backgroundColor.darkgray20};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 42.38rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 1.88rem;
  padding: 4.06rem 2.94rem;
  position: relative;
`;

const Logo = styled.img`
  width: 7.44rem;
  height: auto;
  position: absolute;
  top: 2.38rem;
  left: 2.94rem;
`;

const SubHeading = styled.h3`
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.primary.normal};
  font-weight: 500;
  margin-bottom: 0.63rem;
`;

const Heading = styled.h1`
  font-size: 2.25rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 700;
  line-height: 2.88rem;
  margin-bottom: 1.69rem;
  text-align: center;
`;

const Text = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 2.25rem;
`;

const Gif = styled.img`
  width: 7.38rem;
  height: 7.38rem;
  margin-bottom: 1.69rem;
`;

const StatusText = styled.p`
  font-size: 1.13rem;
  font-weight: 600;
  line-height: 1.69rem;
  letter-spacing: -0.03em;
  color: #111;
`;

function Smile() {
  console.log(window.location.pathname);

  const { auth } = useAuth();
  const navigate = useNavigate();
  const { cid } = useParams();

  const [urlChampions, setUrlChampions] = useState("");
  const [popupUrl, setPopupUrl] = useState("");
  const [analyzeUrl, setAnalyzeUrl] = useState("");
  const [counts, setCounts] = useState(0);

  const handleChampionsClick = () => {
    if (cid) {
      navigate(`${urlChampions}/${cid}`);
    } else {
      navigate(`${urlChampions}`);
    }
  };

  const handleNewWindowOpen = () => {
    const popup = window.open(
      popupUrl,
      "미소",
      "width=700px,height=730px,scrollbars=yes"
    );
    popup.opener.sendImage = (image) => {
      navigate(analyzeUrl, {
        state: {
          name:
            isAuthEmpty(auth) || includesPathname(ROUTES.GUEST_SMILE)
              ? "회원"
              : auth.user.name,
          imageData: image,
          cid,
        },
      });
      // renderImage(image);
      // console.log(image);
      return;
    };
  };

  useEffect(() => {
    if (includesPathname(ROUTES.GUEST_SMILE)) {
      setUrlChampions(ROUTES.GUEST_SMILE_CHAMPIONS);
      setPopupUrl(ROUTES.GUEST_SMILE_POPUP);
      setAnalyzeUrl(ROUTES.GUEST_SMILE_ANALYZE);
    } else {
      setUrlChampions(ROUTES.SMILE_CHAMPIONS);
      setPopupUrl(ROUTES.SMILE_POPUP);
      setAnalyzeUrl(ROUTES.SMILE_ANALYZE);
    }
    const fetchData = async () => {
      const response = await getSmileAverage({
        auth: includesPathname(ROUTES.GUEST_SMILE) ? null : auth,
        cid,
      });

      if (response.ok) {
        setCounts(response.result?.totalCount || 0);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      <Wrapper>
        <MediaContainer>
          <Logo src={`${process.env.PUBLIC_URL}/assets/logo/ai_logo.png`} />
          <SubHeading>AI행복 미소 챌린지</SubHeading>
          <Heading>
            {isAuthEmpty(auth) || includesPathname(ROUTES.GUEST_SMILE)
              ? "나"
              : `${auth.user.name}님`}
            의 미소는
            <br /> 어떤 미소일까요?
          </Heading>
          <Text>
            미소 레벨과 '찐-미소' 가능성을
            <br />
            AI가 판별해 드립니다.
          </Text>
          <Gif src={`${process.env.PUBLIC_URL}/assets/ai_bounce.gif`} />

          <ButtonContainer>
            <Button onClick={handleNewWindowOpen}>나의 미소 확인하기</Button>
            <SecondaryButton onClick={handleChampionsClick}>
              찐-미소 챔피언즈 구경하기
            </SecondaryButton>
          </ButtonContainer>
          <StatusText>
            📢 지금까지 총{" "}
            <span style={{ fontWeight: 700, color: "#00dbb1" }}>
              {counts.toLocaleString()}
            </span>
            명이 챌린지 참여 중이에요.
          </StatusText>
        </MediaContainer>
      </Wrapper>
    </Container>
  );
}

export default Smile;
