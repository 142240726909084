import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";

const Container = styled.div`
  border-radius: 1.25rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  position: relative;
  width: 25.25rem;
  max-width: 25.25rem;
`;

const Heading = styled.h1`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 600;
  line-height: 140%;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  text-align: center;
  padding: 1.44rem 0;
  width: 100%;
`;

const List = styled.ul`
  margin-top: 1.88rem;
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 0.5rem;
`;

const Item = styled.li`
  margin-bottom: 0.5rem;
  font-size: 0.9375rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  line-height: 140%;
`;

const CloseSvg = ({ onClick, style }) => {
  return (
    <svg
      style={style}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.14786 0.21967C9.44075 -0.0732233 9.91563 -0.0732233 10.2085 0.21967C10.5014 0.512563 10.5014 0.987437 10.2085 1.28033L6.27486 5.21399L10.2087 9.14786C10.5016 9.44075 10.5016 9.91563 10.2087 10.2085C9.91584 10.5014 9.44096 10.5014 9.14807 10.2085L5.2142 6.27465L1.28033 10.2085C0.987437 10.5014 0.512563 10.5014 0.21967 10.2085C-0.0732234 9.91563 -0.0732234 9.44075 0.21967 9.14786L4.15354 5.21399L0.219877 1.28033C-0.073016 0.987437 -0.073016 0.512563 0.219877 0.21967C0.51277 -0.0732233 0.987644 -0.0732233 1.28054 0.21967L5.2142 4.15333L9.14786 0.21967Z"
        fill="#111111"
      />
    </svg>
  );
};

const EventModal = ({ closeClick }) => {
  return (
    <Container>
      <FlexRow
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Heading>첫 캐시 환급 이벤트</Heading>
        <CloseSvg
          onClick={(e) => closeClick(e, "cancel")}
          style={{ position: "absolute", right: "1.87rem" }}
        />
      </FlexRow>
      <FlexColumn style={{ padding: "1.44rem 1.87rem" }}>
        <img
          src={
            "https://s3.ap-northeast-2.amazonaws.com/maum.fit-assets/events/event_banner_modal_1.png"
          }
        />
        <List>
          <Item>이벤트 기간 : 2023.8.1 - 2023.9.30</Item>
          <Item>
            대상 : <span style={{ fontWeight: 600 }}>캐시 환급이 처음인</span>{" "}
            맘핏 회원
          </Item>
          <Item>이벤트 상세 내용</Item>
          <ul>
            <Item
              style={{
                fontSize: "0.875rem",
                paddingLeft: "0.15rem",
              }}
            >
              - 현금 이체 시{" "}
              <span style={{ textDecoration: "line-through", color: "#777" }}>
                10,000원
              </span>{" "}
              → <span style={{ fontWeight: 600 }}>5,000원</span> 단위부터 환급
              가능
            </Item>
            <Item style={{ fontSize: "0.875rem", paddingLeft: "0.15rem" }}>
              - 해당 이벤트는 첫 캐시 환급 회원에 한해 적용됩니다. (1회)
            </Item>
            <Item style={{ fontSize: "0.875rem", paddingLeft: "0.15rem" }}>
              - 현금 이체는 매월 20일까지 접수 가능하며, 월말에 일괄 지급
              됩니다.{" "}
              <span style={{ color: "#777" }}>(20일 접수분까지 당월 이체)</span>
            </Item>
          </ul>
        </List>
      </FlexColumn>
    </Container>
  );
};

export default EventModal;
