import styled from "styled-components";
import FlexColumn from "../../../../components/FlexColumn";
import FlexRow from "../../../../components/FlexRow";
import { MOBILE } from "../../../../Constants";

const BarContainer = styled.div`
  position: relative;
  //   width: 300px;
  width: 80%;
  /* border: 1px solid red; */
  @media (max-width: ${MOBILE}) {
    height: 0.60294rem;
  }
`;

const BarGraph = styled.div`
  position: absolute;
  background: ${({ theme, type }) =>
    type === "me" ? theme.primary.normal : theme.grayScaleColor.gray20};
  top: 0;
  left: 0;
  width: ${({ value }) => value}%;
  height: 15px;
  z-index: 2;
  border-radius: 50px;
  @media (max-width: ${MOBILE}) {
    height: 0.60294rem;
  }
`;

const Bar = ({ type, value, checkupCount }) => {
  return (
    <BarContainer style={{ position: "relative" }}>
      <BarGraph
        type={type}
        value={
          value === 0 ? 1 : checkupCount ? (value / checkupCount) * 100 : value
        }
      />
    </BarContainer>
  );
};
const Legend = styled.div`
  font-size: 0.6875rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 160%;
`;
const Circle = styled.div`
  width: 0.6125rem;
  height: 0.6125rem;
  border-radius: 50%;
  background: ${({ theme, type }) =>
    type === "me" ? theme.primary.normal : theme.grayScaleColor.gray20};
`;
const Heading1 = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 160%;
`;
const Heading2 = styled.span`
  font-size: 0.75rem;
  line-height: 160%;
  color: ${({ theme, type }) =>
    type === "me" ? theme.primary.normal : theme.backgroundColor.darkgray40};
`;

const MaumSentenceHorizontalBarList = ({
  style,
  legends,
  labels,
  data,
  checkupCount,
}) => {
  return (
    <div>
      <FlexRow
        style={{
          gap: "0.87rem",
          marginBottom: "2rem",
          justifyContent: "flex-end",
        }}
      >
        {legends.map((legend, index) => (
          <FlexRow key={index} style={{ alignItems: "center", gap: "0.35rem" }}>
            <Circle type={index === 0 && "me"} />
            <Legend>{legend}</Legend>
          </FlexRow>
        ))}
      </FlexRow>
      <FlexColumn style={{ gap: "2rem" }}>
        {data[0].map((item, index) => (
          <FlexRow key={index} style={{ alignItems: "center" }}>
            <Heading1 style={{ flex: 1 }}>{labels[index]}</Heading1>
            <FlexColumn style={{ flex: 2.5 }}>
              <FlexRow style={{ alignItems: "center", gap: "0.6rem" }}>
                <Bar type="me" value={item} checkupCount={checkupCount} />
                <Heading2 type="me">
                  {item}
                  {!checkupCount && "%"}
                </Heading2>
              </FlexRow>
              <FlexRow style={{ alignItems: "center", gap: "0.6rem" }}>
                <Bar value={data[1][index]} checkupCount={checkupCount} />
                <Heading2>
                  {data[1][index]}
                  {!checkupCount && "%"}
                </Heading2>
              </FlexRow>
            </FlexColumn>
          </FlexRow>
        ))}
      </FlexColumn>
    </div>
  );
};

export default MaumSentenceHorizontalBarList;
