import styled from "styled-components";
import FlexRow from "../../../../components/FlexRow";
import { MOBILE } from "../../../../Constants";

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 0.75rem 2.25rem;
  background: ${({ theme }) => theme.primary.light};
  color: ${({ theme }) => theme.grayScaleColor.black};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 600;
  // border-radius: 3.13rem;
  @media (max-width: ${MOBILE}) {
    font-size: 0.875rem;
    line-height: 160%;
    padding: 0.75rem 1rem;
  }
`;

const ReportCallout = ({ title, value, diff, style }) => {
  return (
    <Container style={style}>
      <span>{title}</span>
      <div
        style={{
          marginLeft: "1rem",
          marginRight: "1rem",
          height: "1.125rem",
          width: "1px",
          background: "#7C8585",
        }}
      />
      <FlexRow>
        {value}
        {!diff ? null : diff > 0 ? (
          <FlexRow
            style={{
              color: "#00DBB1",
              alignItems: "center",
              marginLeft: "0.25rem",
            }}
          >
            (
            <span
              style={{
                fontSize: "0.5rem",
                color: "#00DBB1",
                marginRight: "0.25rem",
              }}
            >
              ▲
            </span>
            <span>{diff}</span>)
          </FlexRow>
        ) : (
          <FlexRow
            style={{
              color: "#7C8585",
              alignItems: "center",
              marginLeft: "0.25rem",
            }}
          >
            (
            <span
              style={{
                fontSize: "0.5rem",
                color: "#7C8585",
                marginRight: "0.25rem",
              }}
            >
              ▼
            </span>
            <span>{Math.abs(diff)}</span>)
          </FlexRow>
        )}{" "}
      </FlexRow>
    </Container>
  );
};

export default ReportCallout;
