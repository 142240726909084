import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Splash from "./Splash";
import StepOne from "./StepTwo";
import StepTwo from "./StepOne";

function ITree() {
  const [splash, setSplash] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [name, setName] = useState("");
  const [leafs, setLeafs] = useState(Array(8).fill(""));
  const navigate = useNavigate();

  const handleNext = (step) => {
    setCurrentStep(step);
  };

  const handlePrev = (step) => {
    setCurrentStep(step);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  // useEffect(() => {
  //   const splashTimeout = setTimeout(() => {
  //     setSplash(false);
  //     navigate("1");
  //   }, 800);

  //   return () => {
  //     clearTimeout(splashTimeout);
  //   };
  // }, []);

  return <Splash />;
}

export default ITree;
