import styled from "styled-components";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import NoDataText from "./components/NoDataText";

const Container = styled.div`
  line-height: 140%;
`;

const Title = styled.p`
  font-size: 0.875rem;
  font-weight: 500;

  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  margin-bottom: 1rem;
`;
const Data = styled.p`
  font-size: 1.375rem;

  font-weight: 600;

  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const Separator = styled.div`
  height: 100%;
  width: 1px;
  background: ${({ theme }) => theme.grayScaleColor.gray20};
  // margin: 0 1.5rem;
`;

const NumberText = styled.p`
  font-size: 0.875rem;
  font-weight: 600;

  color: ${({ theme }) => theme.primary.normal};
  margin-right: 1rem;
`;

const ItemTitle = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const ItemBadge = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 0.25rem 0.5rem;
  border-radius: 3.125rem;
  line-height: 140%;
`;

const Card2 = ({ title, data, style }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <FlexColumn style={{ gap: "1rem" }}>
        {data.length === 0 ? (
          <NoDataText />
        ) : (
          data?.map((item, index) => (
            <FlexRow
              style={{
                height: "100%",
                //   width: "22.5rem",

                alignItems: "center",
                justifyContent: "space-between",
              }}
              key={index}
            >
              <FlexRow>
                <NumberText>{index + 1}</NumberText>
                <ItemTitle>{item.title}</ItemTitle>
              </FlexRow>
              <FlexRow style={{ gap: "0.25rem" }}>
                {item.permavac?.slice(0, 2).map((c, cIndex) => (
                  <ItemBadge key={cIndex}>{c}</ItemBadge>
                ))}
              </FlexRow>
            </FlexRow>
          ))
        )}
      </FlexColumn>
    </Container>
  );
};

export default Card2;
