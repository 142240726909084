import styled from "styled-components";

const Container = styled.nav`
  width: 100%;
  height: 65px;
  background: ${({ theme }) => theme.primary.normal};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopNav = ({
  src = `${process.env.PUBLIC_URL}/assets/logo/topbar_logo.png`,
  width = 103,
  style,
}) => {
  const handleLogoClick = () => {
    window.open("https://zep.us/play/8ZwAV4", "_blank");
  };
  return (
    <Container style={style}>
      <img
        src={src}
        alt="logo"
        width={width}
        onClick={handleLogoClick}
        style={{ cursor: "pointer" }}
      />
    </Container>
  );
};

export default TopNav;
