import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import { useEffect, useRef, useState } from "react";
import getWeekNumber from "../../utils/getWeekNumber";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  zoomPlugin
);

const Container = styled.div`
  position: relative;
  width: 62.5rem;
  // max-width: 62.5rem;
  height: 21.75rem;
  // border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 0.63rem;
  margin-bottom: 3.88rem;
`;

export function AverageChart({ results, type }) {
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });
  // console.log(results);
  const tempDates = results.map((item) => item.createdAt);
  let labels = [...tempDates];
  // 그래프 x축 처음부터 표시하기 위한 작업

  // 1. [11/1, 11/2] 있으면 [11/1, 11/2, 11/3 , ... , 11/7] 로 채워준다.
  // let extraDates = 7 - results.length;
  // let lastDates = labels[results.length - 1];
  // if (results.length < 7) {
  //   for (let i = extraDates; i > 0; i--) {
  //     lastDates = new Date(lastDates).setDate(
  //       new Date(lastDates).getDate() + 1
  //     );
  //     labels.push(lastDates);
  //   }
  // }

  // 2. [11/1, 11/2] 있으면 [11/1, 11/2, null, ... , null] 나머지를 null로 채운다.
  if (labels.length === 0) {
    labels.push(new Date());
  }
  if (labels.length < 7) {
    for (let i = 0; i < 7; i++) {
      if (labels[i] === undefined) {
        labels.push(null);
      }
    }
  }

  labels = labels.map((item) => {
    const { month, weekNumber } = getWeekNumber(item);
    if (type === "report") {
      return item ? `${month}월 ${weekNumber}주` : null;
    } else {
      return item
        ? `${new Date(item).getMonth() + 1}/${new Date(item).getDate()}`
        : null;
    }
  });

  const chartRef = useRef();
  const config = {
    options: {
      //   responsive: true,
      maintainAspectRatio: false,
      layout: {
        // padding: {
        //   top: 10,
        // },
        // padding: 20,
      },
      plugins: {
        zoom: {
          pan: { enabled: true, mode: "x" },
        },
        legend: {
          labels: {
            padding: 10,
            boxHeight: 2,
          },
          display: false,
          position: "bottom",
        },
        title: {
          display: false,
          // text: "마음 체크업 점수",
        },
        datalabels: {
          display: false, // 값 표시
          backgroundColor: function (context) {
            return context.dataset.backgroundColor;
          },
          borderRadius: 4,
          color: "white",
          font: {
            weight: "bold",
          },
          formatter: (value, context) => {
            if (context.dataset.data.length - 1 === context.dataIndex) {
              return value;
            } else {
              return null;
            }
          },
          padding: 6,
        },
        tooltip: {
          backgroundColor: "#00DBB1",
          xAlign: "center",
          yAlign: "bottom",
          // x: 100,
          // y: 500,
          padding: {
            top: 6,
            bottom: 6,
            left: 20,
            right: 20,
          },
          bodyFont: {
            size: 12,
            weight: "bold",
          },
          // title: "aaa",
          callbacks: {
            title: () => {
              return "";
            },
            label: (context) => {
              return context.raw;
            },
            labelTextColor: () => {
              return "white";
            },
          },
          displayColors: false,
        },
      },
      scales: {
        x: {
          offset: true,
          min: labels.length - 7,
          max: labels.length - 1,
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            color: "#777777",
            padding: 2,
            // maxTicksLimit: 7,
            font: {
              family: "Montserrat",
            },
          },
        },
        y: {
          offset: true,
          min: 0,
          max: 100,
          ticks: {
            stepSize: 20,
            padding: 5,
            color: "#777777",
            font: {
              family: "Montserrat",
            },
          },
          grid: {
            color: "#EEEEEE",
            drawBorder: false,
            lineWidth: 1,
          },
        },
      },
    },

    data: {
      labels,
      //   labels: [
      //     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3,
      //     4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
      //   ],
      datasets: [
        {
          // label: "마음 체크업 점수",
          data: results.map((result) => result.score),
          //   data: [
          //     10, 10, 10, 20, 50, 19, 20, 69, 42, 45, 10, 10, 10, 10, 20, 50, 19,
          //     20, 69, 42, 45, 10, 10, 10, 10, 20, 50, 19, 20, 69, 42, 45, 10, 10,
          //     10, 10, 20, 50, 19, 20,
          //   ],
          borderColor: "#00DBB1",
          backgroundColor: "#00DBB1",
          borderWidth: 2,
          tension: 0.4,
          pointRadius: 3,
          pointHoverRadius: 10,
          pointHoverBorderWidth: 2,
          pointHoverBackgroundColor: "white",
          pointBackgroundColor: "white",
          pointBorderColor: "#00DBB1",
        },
      ],
    },
  };

  const handleScroll = (e) => {
    // console.log("scroll", e.deltaX, e.deltaY);
    // console.log(chartRef.current);
    // const labelsIndex = chartRef.current.config._config.data.labels.length - 1;
    // if (e.deltaY > 0) {
    //   if (chartRef.current.config._config.options.scales.x.max >= labelsIndex) {
    //     chartRef.current.config._config.options.scales.x.min = labelsIndex - 6;
    //     chartRef.current.config._config.options.scales.x.max = labelsIndex;
    //   } else {
    //     chartRef.current.config._config.options.scales.x.min += 1;
    //     chartRef.current.config._config.options.scales.x.max += 1;
    //   }
    // } else if (e.deltaY < 0) {
    //   if (chartRef.current.config._config.options.scales.x.min > 0) {
    //     chartRef.current.config._config.options.scales.x.min -= 1;
    //     chartRef.current.config._config.options.scales.x.max -= 1;
    //   }
    // }
    // chartRef.current.update();
  };

  //   console.log(config);
  return (
    <Container>
      {config && (
        <Line
          ref={chartRef}
          options={config.options}
          data={config.data}
          // style={{ width: "57.38rem", height: "16.5rem" }}
          // height={isMobile ? 200 : 450}
        />
      )}
    </Container>
  );
}
