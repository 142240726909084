import styled from "styled-components";
import FlexColumn from "../../../../components/FlexColumn";
import Button from "./Button";

const Container = styled.div`
  width: 100%;
  background: white;
  padding: 1.88rem;
  border-radius: 1.25rem;
`;

const Popup = ({ children, onClick }) => {
  return (
    <Container>
      <FlexColumn style={{ alignItems: "center" }}>
        {children}
        <Button
          onClick={onClick}
          style={{ alignSelf: "stretch", marginTop: "1.5rem" }}
        >
          확인했어요!
        </Button>
      </FlexColumn>
    </Container>
  );
};

export default Popup;
