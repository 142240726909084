import axios from "../../../api/axios";

const getSmile = async (level) => {
  try {
    const response = await axios.get(`/smile?level=${level}`);
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export default getSmile;
