import { useState } from "react";
import styled from "styled-components";
import axios from "../../../api/axios";
import FlexColumn from "../../../components/FlexColumn";
import InputTwoLine from "./InputTwoLine";

const Container = styled.div`
  padding: 3.19rem 1.88rem 3rem 1.88rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ItreeLogo = styled.img`
  width: 4.48269rem;
  height: 5.125rem;
  margin-bottom: 1.44rem;
`;

const Heading = styled.h3`
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  text-align: center;
  font-family: Pretendard Variable;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: -0.03125rem;
  margin-bottom: 1.81rem;
`;

const SButton = styled.button`
  width: 100%;
  height: 3.44rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.grayScaleColor.black};
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-size: 1.13rem;
  line-height: 1.25rem;
  font-weight: 500;
  border: none;
  border-radius: 0.4375rem;
  margin-top: 0.75rem;
  &:disabled {
    background: #d8d8d8;
  }
`;

const Padding = styled.div`
  padding: 0 1.87rem;
`;

const ItreeImage = styled.img`
  width: 19.75rem;
  height: 27.22444rem;
  margin: 0 auto;
  margin-bottom: 1.77rem;
  border-radius: 0.75rem;
  object-fit: cover;
  background: #fcfcfc;
`;

const SmallActionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 19.75rem;
  gap: 14px;
`;

const SmallActionList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const SmallActionItem = styled.p`
  background: #fff;
  border-radius: 0.44rem;
  padding: 0.88rem 1.13rem;
  font-size: 0.94rem;
  color: #111111;
`;

const Button = ({ children, style, disabled = false, onClick }) => {
  return (
    <SButton disabled={disabled} style={style} onClick={onClick}>
      {children}
    </SButton>
  );
};

const Itree = () => {
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [smallActions, setSmallActions] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [isReady, setIsReady] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handlePhoneNumberChange = (e) => {
    if (e.target.value.length <= 4) {
      setPhone(e.target.value);
    }
  };
  const handleBirthdayChange = (e) => {
    setBirthday(e.target.value);
  };

  const isValid = () => {
    return phone.length === 4;
  };

  const handleButtonClick = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SMART_TOOL_SERVER_BASE_URL}/itree/itree?phone=${phone}&name=${name}`
    );
    if (!response.data.ok) {
      return;
    }
    setSmallActions(response.data.data.smallActions);
    setImageUrl(response.data.data.url);
    setIsReady(true);
  };
  return (
    <Container>
      {isReady ? (
        <FlexColumn style={{ width: "100%", alignItems: "center" }}>
          <ItreeImage src={imageUrl} />

          <SmallActionContainer>
            <img
              src={`${process.env.PUBLIC_URL}/assets/itree/itree_small_action.png`}
              width={45}
              height={45}
              alt="small action logo"
            />
            <SmallActionList>
              {smallActions.map((item) => (
                <SmallActionItem>{item}</SmallActionItem>
              ))}
            </SmallActionList>
          </SmallActionContainer>
        </FlexColumn>
      ) : (
        <>
          <ItreeLogo
            src={`${process.env.PUBLIC_URL}/assets/itree/itree_sm.png`}
          />
          <Heading>
            나의 성장 방향과 실천 계획
            <br />
            다시 보기
          </Heading>
          <FlexColumn
            style={{ gap: "1rem", width: "100%", marginBottom: "1.75rem" }}
          >
            <InputTwoLine
              property={"Two-line"}
              label="이름"
              placeholder={"이름을 입력해주세요"}
              onChange={handleNameChange}
              setValue={setName}
              value={name}
            />
            <InputTwoLine
              property={"Two-line"}
              label={"휴대폰번호"}
              placeholder={"휴대전화 뒷 번호 4자리"}
              onChange={handlePhoneNumberChange}
              value={phone}
              setValue={setPhone}
              maxLength={4}
              inputmode="numeric"
              type={"number"}
            />
            {/* <InputTwoLine
              property={"Two-line"}
              label={"생년월일"}
              placeholder={"YYMMDD"}
              onChange={handleBirthdayChange}
              value={birthday}
              setValue={setBirthday}
              maxLength={6}
              type={"number"}
            /> */}
          </FlexColumn>
          <Button disabled={!isValid()} onClick={handleButtonClick}>
            눌러서 확인하기
          </Button>
        </>
      )}
    </Container>
  );
};

export default Itree;
