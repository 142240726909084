import "aos/dist/aos.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { useMediaQuery } from "react-responsive";
import FlexRow from "../../components/FlexRow";

import ModalBackground from "../../components/ModalBackground";

const Container = styled.div``;

const Section = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: ${({ theme, bgColor }) =>
    bgColor === "primary" ? theme.primary.normal : "#EDF3F3"};
  &:nth-child(3) {
    height: auto;
    padding-bottom: 100px;
    @media (max-width: 425px) {
      height: 100%;
      padding-bottom: 0;
    }
  }
  &:nth-child(3) > div {
    @media (max-width: 425px) {
      height: auto;
    }
  }
  &:nth-child(3) {
    ${({ forLeader }) => forLeader && "height: auto; padding-bottom: 50px;"}
  }
  &:nth-child(4) {
    height: 100%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 55.63rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 3.81rem 3.63rem;
  overflow-y: auto;
  // border: 1px solid black;
  @media (max-width: 425px) {
    padding: 3.81rem 1.88rem;
  }
`;

const ContentQuestion = styled.h3`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 3.19rem;
  letter-spacing: -1px;
  @media (max-width: 425px) {
    font-size: 1.25rem;
    line-height: 1.88rem;
    margin-bottom: 1.5rem;
  }
`;

const ContentQuestionHighlight = styled.span`
  color: ${({ theme }) => theme.primary.normal};
  margin-right: 0.63rem;
`;

const TextAreaContainer = styled.div`
  position: relative;
  margin-bottom: 0.5rem;
  width: 100%;
  ${({ isAuthEmpty }) => isAuthEmpty && "margin-top: 2.5em"};
  @media (max-width: 425px) {
    margin-top: 1rem;
  }
`;
const Textarea = styled.textarea`
  width: 100%;
  height: 7.25rem;
  background: ${({ theme }) => theme.primary.superLight};
  border-radius: 0.94rem;
  border: 2px solid ${({ theme }) => theme.primary.superLight};
  padding: 1.06rem 1.88rem;
  font-size: 1rem;
  line-height: 1.5rem;
  resize: none;
  // outline-color: ${({ theme }) => theme.primary.normal};
  &::placeholder {
    color: #888888;
  }
  &:focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.primary.normal};
  }
`;

const TextLengthCheck = styled.p`
  font-size: 0.75rem;
  line-height: 1.19rem;
  font-weight: 300;
  text-align: right;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  font-family: Montserrat;
  letter-spacing: -1px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0.19rem;
  margin-bottom: 2.31rem;
  // border: 1px solid black;
  position: relative;
`;

const SubmitButton = styled.button`
  border: none;
  background: ${({ theme }) => theme.primary.normal};
  margin-top: 0.5rem;
  padding: 0.38rem 2.19rem;
  font-size: 0.94rem;
  font-weight: 500;
  line-height: 1.44rem;
  border-radius: 0.31rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  cursor: pointer;
  &:disabled {
    background: ${({ theme }) => theme.grayScaleColor.gray10};
    color: ${({ theme }) => theme.grayScaleColor.gray30};
    cursor: not-allowed;
  }
  ${({ isAuthEmpty }) => isAuthEmpty && "margin-left: auto; margin-right: 0;"}
  @media (max-width: 425px) {
    padding: 0.75rem 2.19rem;
    ${({ isAuthEmpty }) => isAuthEmpty && "width: 100%;"}
  }
`;

const ModalContainer = styled.div`
  border-radius: 1.875rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 2rem;
  position: relative;
  width: 18.75rem;
  max-width: 18.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 50;
`;

const Heading1 = styled.p`
  font-size: 1.125rem;
  color: #000;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: -0.03125rem;
`;

const Text1 = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  text-align: center;
`;

const Button = styled.button`
  border-radius: 0.3125rem;
  border: 1px solid
    ${({ theme, state }) =>
      state === "modified" ? theme.primary.dark : theme.primary.normal};
  background: ${({ theme, state }) =>
    state === "modified" ? theme.grayScaleColor.white : theme.primary.normal};
  padding: 0.69rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${({ theme, state }) =>
    state === "modified" ? theme.primary.dark : theme.grayScaleColor.white};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4375rem;
  cursor: pointer;
`;
const Modal = ({ close, block, type, cash }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });

  const handleClickConfirm = (e, block) => {
    navigate("/");
  };

  return (
    <ModalContainer>
      <Heading1 style={{ marginBottom: "1.12rem" }}>사전 미션 완료</Heading1>
      <FlexRow style={{ justifyContent: "center" }}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/mission_icon.png`}
          style={{ width: "5.4375rem" }}
          alt="mission"
        />
      </FlexRow>
      <Text1
        style={{
          fontWeight: 600,
          whiteSpace: "nowrap",
          marginTop: "1.19rem",
          marginBottom: "1.19rem",
        }}
      >
        사전 미션이 완료되었어요. <br />
        워크숍에서 만나요 👋
      </Text1>

      <FlexRow style={{ width: "100%", gap: "0.62rem" }}>
        <Button onClick={(e) => handleClickConfirm(e, block)}>확인</Button>
      </FlexRow>
    </ModalContainer>
  );
};

const Mission = () => {
  const ANIMATION_DELAY = 1000;
  const [isLoading, setIsLoading] = useState(true);
  const [mission, setMission] = useState();
  const [comment, setComment] = useState("");
  const [commentFocus, setCommentFocus] = useState(false);
  const [nickname, setNickname] = useState("");
  const [nicknameFocus, setNicknameFocus] = useState(false);
  //   const [updatedComment, setUpdatedComment] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [isLike, setIsLike] = useState(false);
  const [isLikeClicked, setIsLikeClicked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [comments, setComments] = useState([]);
  const [commentLikes, setCommentLikes] = useState();
  const [currentY, setCurrentY] = useState(0);
  const [visible, setVisible] = useState(false);
  const [showStartModal, setShowStartModal] = useState(true);
  const [showConfettiModal, setShowConfettiModal] = useState(false);
  const [confettiType, setConfettiType] = useState(0);
  const [flip, setFlip] = useState(true);
  const [prevIndex, setPrevIndex] = useState(0);
  const [isFront, setIsFront] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cash, setCash] = useState(100);

  const axiosPrivate = useAxiosPrivate();
  const { auth, setAuth } = useAuth();
  const { id } = useParams();
  const [indicators, setIndicators] = useState([1, 0, 0]);
  const introMenuItemRef = useRef();
  const guideMenuItemRef = useRef();
  const actionMenuItemRef = useRef();
  const introRef = useRef();
  const guideRef = useRef();
  const actionRef = useRef();
  const imagesRef = useRef([]);
  const iframeRef = useRef();
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });
  const missionBlocks = [50, 51];
  const navigate = useNavigate();

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let response;
    response = await axiosPrivate.post("/mission/comment", {
      missionId: mission._id,
      comment: comment.replaceAll("<br>", "\r\n"),
    });

    setComments(response.data.comments);
    setComment("");
    setShowConfettiModal(true);
    // navigate("/");
  };

  const isAuthEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await axiosPrivate.get("/mission");
      setMission(res.data.data.mission);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (isLoading) {
    return null;
  }
  return (
    <Container>
      <Section
        id="action"
        ref={actionRef}
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <ContentWrapper>
          {/* <div> */}
          <ContentQuestion>
            <ContentQuestionHighlight>Q.</ContentQuestionHighlight>
            {mission.question}
          </ContentQuestion>

          <TextAreaContainer isAuthEmpty={isAuthEmpty(auth)}>
            <Textarea
              placeholder="예시: 커뮤니케이션 방법, 10분컷 회의록 작성, 폴더 즐겨찾기, 업무 캘린더와 to-do 어플 연결, 엑셀vlookup, AI 뤼튼, 챗GPT 영어 윤문, 노션 템플릿, 이메일 템플릿"
              value={comment}
              onChange={handleCommentChange}
              onFocus={() => setCommentFocus(true)}
              onBlur={() => setCommentFocus(false)}
            ></Textarea>
          </TextAreaContainer>
          <TextLengthCheck>{comment.length}자</TextLengthCheck>
          <ButtonContainer>
            <SubmitButton
              disabled={
                isAuthEmpty(auth)
                  ? comment.length === 0 || nickname.length === 0
                  : comment.length === 0
              }
              onClick={handleSubmit}
              isAuthEmpty={isAuthEmpty(auth)}
            >
              미션 완료 하기
            </SubmitButton>
          </ButtonContainer>
        </ContentWrapper>
      </Section>
      {showConfettiModal && (
        <ModalBackground style={{ zIndex: 50 }}>
          <Modal close={() => setShowConfettiModal(false)}></Modal>
        </ModalBackground>
      )}
    </Container>
  );
};

export default Mission;
