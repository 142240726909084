import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import CardLayout from "../CardLayout";

import CloseXButton from "../components/CloseXButton";
import Divider from "../components/Divider";
import Heading1 from "../components/Heading1";
import Heading2 from "../components/Heading2";
import Modal from "../components/Modal";
import ModalHeading2 from "../components/ModalHeading2";
import Text1 from "../components/Text1";
import TextLink from "../components/TextLink";
import AddManagerModal from "../modals/AddManagerModal";
import DeleteManagerModal from "../modals/DeleteManagerModal";
import EditManagerModal from "../modals/EditManagerModal";
import DotListItem from "../components/DotListItem";
import NumberText from "../components/NumberText";
import GroupManageModal from "../modals/GroupManageModal";
import Checkbox from "../../../components/Checkbox";
import StatusText from "../components/StatusText";
import AddUserModal from "../modals/AddUserModal";
import AddUsersModal from "../modals/AddUsersModal";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import convertStatus from "../../../utils/convertStatus";
import convertRole from "../../../utils/convertRole";
import {
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_INVITE,
  STATUS_SIGNUP,
  USER_LEADER,
  USER_MANAGER,
  USER_PM,
} from "../../../Constants";
import DeleteUserModal from "../modals/DeleteUserModal";
import IndividualInviteModal from "../modals/IndividualInviteModal";
import EditUserModal from "../modals/EditUserModal";
import { converDate } from "../../../utils/convertDate";
import { useNavigate } from "react-router-dom";
import SortButton from "../components/SortButtons";
import lineBreaker from "../../../utils/lineBreaker";

const Container = styled.div`
  padding: 2.75rem 2.75rem;
  padding-left: calc(2.75rem + var(--sidebar-width));
  width: 100%;
  position: relative;
`;

const TableTitle = styled.h1`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: -0.03125rem;
`;
const Title = styled.p`
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 150%; /* 1.40625rem */
  letter-spacing: -0.03125rem;
`;

const SmallText = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
`;

const KeyText = styled.p`
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  flex: 1;
`;
const ValueText = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  flex: 4;
`;

const SubText = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
`;

const Bold = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Input = styled.input`
  border-radius: 0.3125rem;
  border: 1px solid black;
  padding: 0.38rem 0.5rem;
  outline: none;
`;

const SmallButton = styled.button`
  border-radius: 0.3125rem;
  background: ${({ theme }) => theme.primary.normal};
  border: 1px solid ${({ theme }) => theme.primary.normal};
  padding: 0.25rem 0.75rem;
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
`;

const Ul = styled.ul`
  flex: 4;
`;

const HR = styled.div`
  display: flex;
  align-items: center;
  padding: 0.94rem 2rem;
  background: ${({ theme }) => theme.grayScaleColor.gray5};
`;
const TR = styled.div`
  display: flex;
  align-items: center;
  padding: 0.94rem 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  cursor: pointer;
`;

const HeaderItem = styled.p`
  flex: 1;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  text-align: left;
`;

const Item = styled.p`
  flex: 1;
  font-size: 0.9375rem;
  line-height: 150%; /* 1.40625rem */
  letter-spacing: -0.03125rem;
  text-align: left;
`;

const Badge = styled.span`
  padding: 0.25rem 0.75rem;
  border-radius: 3.125rem;
  background: ${({ theme, status }) =>
    status === "진행 예정"
      ? "#EEF8FD"
      : status === "진행 중"
      ? theme.primary.light
      : "#F7F7F7"};
  color: ${({ theme, status }) =>
    status === "진행 예정"
      ? theme.colors.accent
      : status === "진행 중"
      ? theme.primary.normal
      : theme.grayScaleColor.gray70};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
`;

const ManagerProject = () => {
  const PROJECT_STATUS = {
    "전체 보기": { count: 0 },
    "진행 예정": { count: 0 },
    "진행 중": { count: 0 },
    "진행 완료": { count: 0 },
  };
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const selectedStatus = useRef();
  const [projectStatus, setProjectStatus] = useState(PROJECT_STATUS);
  const [sortOrder, setSortOrder] = useState({
    name: {
      order: "asc",
    },
    status: {
      order: "asc",
    },
    projectPeriods: {
      order: "asc",
    },
    md: {
      order: "asc",
    },
  });
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const convertStatus = (start, end) => {
    const now = new Date();
    if (now.getTime() < new Date(start).getTime()) {
      return "진행 예정";
    } else if (
      now.getTime() > new Date(start).getTime() &&
      now.getTime() < new Date(end).getTime()
    ) {
      return "진행 중";
    } else {
      return "진행 완료";
    }
  };

  const onProjectClick = (e, companyId) => {
    navigate(`/manager/dashboard/checkup/result?id=${companyId}`, {
      state: { companyId },
    });
  };

  const onSortClick = (e, column, order) => {
    const sortArr = [...filteredCompanies];

    if (sortOrder[column].order === "asc") {
      sortArr.sort((a, b) => {
        if (a[column] < b[column]) {
          return -1;
        }
        if (a[column] > b[column]) {
          return 1;
        }
        return 0;
      });

      setFilteredCompanies(sortArr);
      setSortOrder((prev) => ({
        ...prev,
        [column]: {
          order: "desc",
        },
      }));
    } else {
      sortArr.sort((a, b) => {
        if (a[column] < b[column]) {
          return 1;
        }
        if (a[column] > b[column]) {
          return -1;
        }
        return 0;
      });

      setFilteredCompanies(sortArr);
      setSortOrder((prev) => ({
        ...prev,
        [column]: {
          order: "asc",
        },
      }));
    }
  };
  const onProjectStatusClick = (e, status) => {
    if (selectedStatus.current === status || status === "전체 보기") {
      setFilteredCompanies(companies);
      selectedStatus.current = status;
      return;
    }
    const projects = companies.filter((c) => c.pid);
    const filtered = projects.filter(
      (p) =>
        convertStatus(
          p.projectPeriods[0][0],
          p.projectPeriods[p.projectPeriods.length - 1][1]
        ) === status
    );
    selectedStatus.current = status;
    setFilteredCompanies(filtered);
  };

  useEffect(() => {
    (async () => {
      const companyResponse = await axiosPrivate.get(
        auth.user.role === USER_PM ? `/company?type=project` : `/company`
      );

      if (!companyResponse.data.ok) {
        return;
      }

      setCompanies(companyResponse.data.companies);
      setFilteredCompanies(companyResponse.data.companies);
      const projects = companyResponse.data.companies.filter((c) => c.pid);
      const obj = {};
      for (let i = 0; i < projects.length; i++) {
        const status = convertStatus(
          projects[i].projectPeriods[0][0],
          projects[i].projectPeriods[projects[i].projectPeriods.length - 1][1]
        );
        if (obj[status]) {
          obj[status].count += 1;
        } else {
          obj[status] = { count: 1 };
        }
      }

      setProjectStatus((prev) => ({
        ...prev,
        "전체 보기": { count: companyResponse.data.companies.length },
        ...obj,
      }));
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) return <div></div>;

  //   console.log(
  //     companies.map((company) =>
  // company.map((item) =>
  //   convertStatus(
  //     company.projectPeriods[0][0],
  //     company.projectPeriods[item.projectPeriods.length - 1][1]
  //   )
  // )
  //     )
  //   );

  return (
    <Container>
      <Heading1 text="프로젝트" />
      <Heading2
        text="현재 맘핏 진행 중인 프로젝트를 관리할 수 있습니다."
        style={{ marginBottom: "2.44rem" }}
      />
      <FlexRow style={{ gap: "1.88rem", height: "100%" }}>
        <CardLayout style={{ flex: 1 }}>
          <FlexColumn>
            <FlexRow
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <TableTitle>
                전체 프로젝트
                <Bold style={{ color: "#00DBB1", marginLeft: "0.5rem" }}>
                  {companies.length}
                </Bold>
              </TableTitle>
              <FlexRow style={{ gap: "0.5rem" }}>
                {Object.keys(projectStatus).map((item, index) => (
                  <Badge
                    key={index}
                    status={item}
                    onClick={(e) => onProjectStatusClick(e, item)}
                  >
                    {item} {projectStatus[item].count}
                  </Badge>
                ))}
              </FlexRow>
            </FlexRow>
            <FlexColumn style={{ marginTop: "1.5rem" }}>
              <HR>
                <HeaderItem
                  style={{ flex: 2 }}
                  onClick={(e) =>
                    onSortClick(e, "name", sortOrder["name"].order)
                  }
                >
                  고객사명
                  <SortButton order={sortOrder["name"].order} />
                </HeaderItem>
                <HeaderItem style={{ flex: 1.5 }}>프로젝트 번호</HeaderItem>
                <HeaderItem>상태</HeaderItem>
                <HeaderItem style={{ flex: 3 }}>프로젝트명</HeaderItem>
                {/* <HeaderItem>직급</HeaderItem>
              <HeaderItem>성별</HeaderItem>
              <HeaderItem>생년</HeaderItem> */}
                <HeaderItem>인원</HeaderItem>
                <HeaderItem style={{ flex: 1.5 }}>그룹 (팀/차수)</HeaderItem>
                <HeaderItem
                  style={{ flex: 2 }}
                  onClick={(e) =>
                    onSortClick(
                      e,
                      "projectPeriods",
                      sortOrder["projectPeriods"].order
                    )
                  }
                >
                  총 진행 기간
                  <SortButton order={sortOrder["projectPeriods"].order} />
                </HeaderItem>
                <HeaderItem>구독 개월 수</HeaderItem>
                <HeaderItem>담당 MD</HeaderItem>
              </HR>
              {filteredCompanies.map((company, index) => (
                <TR key={index} onClick={(e) => onProjectClick(e, company._id)}>
                  <Item style={{ flex: 2 }}>{company.name}</Item>
                  <Item style={{ flex: 1.5 }}>{company.pid}</Item>
                  {company.projectPeriods.length > 0 ? (
                    <Item>
                      <Badge
                        status={convertStatus(
                          company.projectPeriods[0][0],
                          company.projectPeriods[
                            company.projectPeriods.length - 1
                          ][1]
                        )}
                      >
                        {convertStatus(
                          company.projectPeriods[0][0],
                          company.projectPeriods[
                            company.projectPeriods.length - 1
                          ][1]
                        )}
                      </Badge>
                    </Item>
                  ) : (
                    <Item></Item>
                  )}
                  <Item style={{ flex: 3 }}>{company.project}</Item>
                  <Item>{company.numUsers}명</Item>
                  <Item style={{ flex: 1.5 }}>{company.numDepartments}</Item>
                  {/* <Item>Manager</Item>
                <Item>{user.gender || "-"} </Item>
                <Item>{user.birthYear || "-"}</Item> */}
                  <Item style={{ flex: 2 }}>
                    {company.projectPeriods?.map((p, index) => (
                      <span key={index}>
                        {converDate(p[0])}~{converDate(p[1])}
                      </span>
                    ))}
                  </Item>
                  <Item>{company.freeTrialPeriod}</Item>
                  <Item>{lineBreaker(company.mds.join("\n"))}</Item>
                </TR>
              ))}
            </FlexColumn>
          </FlexColumn>
        </CardLayout>
      </FlexRow>
    </Container>
  );
};

export default ManagerProject;
