import styled from "styled-components";
import { TABLET_LANDSCAPE } from "../../Constants";
import Share from "../surveys/checkup@2.0/Share";
import ShareList from "../surveys/checkup@2.0/ShareList";
import CardLayout from "./CardLayout";
import Heading1 from "./Heading1";
import Heading2 from "./Heading2";
import { useSearchParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { axiosPrivate } from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import FlexColumn from "../../components/FlexColumn";

const Container = styled.div`
  /* margin-left: var(--sidebar-width); */
  // height: calc(100% - 4.06rem);

  padding: 2.25rem 2.5rem;
  padding-left: calc(2.75rem + var(--sidebar-width));
  width: 100%;
  //   border: 1px solid black;
  display: flex;
  flex-direction: column;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    margin-left: var(--sidebar-tablet-landscape-width);
  }
`;

const Tabs = styled.div`
  display: flex;
  gap: 1.25rem;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  line-height: 140%;
  flex-wrap: wrap;
`;
const Tab = styled.div`
  cursor: pointer;
  ${({ theme, clicked }) =>
    clicked && `color: ${theme.grayScaleColor.black}; font-weight: 600;`}
`;

const MissionAnswerPage = () => {
  const ALL = "모든 답변";
  const { auth } = useAuth();
  const [params] = useSearchParams();
  const [comments, setComments] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredComments, setFilteredComments] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const axiosPrivate = useAxiosPrivate();

  const companyId = params.get("id") || auth.user.companyId._id;

  const handleTabClick = (e, index) => {
    setTabIndex(index);
    if (index === 0) {
      setFilteredComments(comments);
    } else {
      setFilteredComments(
        comments.filter(
          (item) =>
            item.departmentId?.toString() === departments[index]._id.toString()
        )
      );
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const res = await axiosPrivate.get(
        `/mission/comments?companyId=${companyId}`
      );
      const departmentsRes = await axiosPrivate.get(
        `/department?companyId=${companyId}`
      );
      setComments(res.data.data.comments);
      setFilteredComments(res.data.data.comments);
      setDepartments([{ name: ALL }, ...departmentsRes.data.data]);
    };
    fetch();
  }, []);
  return (
    <Container>
      <Heading1 style={{ marginBottom: "2.12rem" }}>
        사전 미션 질문 답변
      </Heading1>
      <Heading2
        style={{ color: "#111", fontSize: "1.25rem", marginBottom: "2.12rem" }}
      >
        {comments[0]?.missionId?.question}
      </Heading2>
      <CardLayout style={{ height: "auto", padding: "2.5rem 3rem" }}>
        <Tabs>
          {departments.map((item, index) => (
            <Tab
              key={index}
              onClick={(e) => handleTabClick(e, index)}
              clicked={index === tabIndex}
            >
              {item.name}
            </Tab>
          ))}
        </Tabs>
        <FlexColumn style={{ gap: "1.5rem" }}>
          {filteredComments?.map((item, index) => (
            <div key={item._id}>{item.comment}</div>
          ))}
        </FlexColumn>
        {/* <Share companyId={companyId} /> */}
      </CardLayout>
    </Container>
  );
};

export default MissionAnswerPage;
