import styled from "styled-components";

const Heading = styled.h1`
  font-size: 1.63rem;
  line-height: 2.5rem;
  letter-spacing: -1.5px;
  font-weight: 400;
  color: ${({ theme }) => theme.grayScaleColor.black};
  @media (max-width: 425px) {
    font-size: 1.25rem;
    line-height: 1.88rem;
    letter-spacing: -0.5px;
  }
`;

const SectionHeading1 = ({ style, children }) => (
  <Heading style={style}>{children}</Heading>
);

export default SectionHeading1;
