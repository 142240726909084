import { motion } from "framer-motion";
import styled from "styled-components";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import lineBreaker from "../utils/lineBreaker";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  width: 100%;
`;

const Header = styled.h3`
  font-size: 1.25rem;
  line-height: 1.88rem;
  font-weight: 500;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 4.06rem;
`;

const SlideContainer = styled.div`
  width: 100%;
  height: 12.5rem;
  margin-bottom: 8.13rem;
  //   border: 1px solid black;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.grayScaleColor.gray30};
  margin-bottom: 0.94rem;
`;

const IndicatorCircle = styled.div`
  width: 0.63rem;
  height: 0.63rem;
  border-radius: 50%;
  margin: 0 0.28rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray20};
  cursor: pointer;
  //   &:nth-child(${({ highlight }) => highlight}) {
  //     background: ${({ theme }) => theme.primary.normal};
  //   }
  &.active {
    width: 1.88rem;
    border-radius: 1.25rem;
    background: ${({ theme }) => theme.primary.normal};
  }
  transition: all 0.3s;
`;

const AccordionContainer = styled.div`
  margin-bottom: 3rem;
`;

const AccordionHeading = styled(AccordionItemHeading)`
  //   height: 3.94rem;
  margin: 0 1.56rem;
  //   border: 1px solid black;
  position: relative;
  cursor: pointer;
`;

const AccordionHeadingTitle = styled.h3`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme, highlight }) =>
    highlight ? theme.primary.normal : theme.grayScaleColor.black};
  font-weight: 500;
  margin-bottom: 0.13rem;
`;

const AccordionHeadingSubTitle = styled.h4`
  font-size: 0.81rem;
  line-height: 1.44rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.gray30};
  font-family: Montserrat;
`;

const Panel = styled(motion.div)`
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  padding: 2.25rem 1.88rem;
  font-size: 0.94rem;
  line-height: 1.44rem;
  border-radius: 0.75rem;
  font-weight: 300;
  margin: 0 1.56rem;
  margin-bottom: 2.06rem;
  margin-top: 0.63rem;
`;

const AccordionDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  margin-bottom: 0.94rem;
  margin-top: 0.88rem;
`;

const ArrowDownSvg = styled.svg`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
`;

const ArrowUpSvg = styled(ArrowDownSvg)``;

const PagingContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 9.56rem;
`;

const PageNumber = styled.div`
  width: 1.88rem;
  height: 1.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.19rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  font-size: 0.81rem;
  font-weight: 300;
  line-height: 1.44rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  margin: 0 0.31rem;
`;

const LeftButton = () => (
  <svg
    style={{ marginRight: "1.25rem" }}
    width="7"
    height="9"
    viewBox="0 0 7 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 0.601318L1 4.18905L6 7.77679"
      stroke="#BBBBBB"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const RightButton = () => (
  <svg
    style={{ marginLeft: "1.25rem" }}
    width="7"
    height="9"
    viewBox="0 0 7 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 0.601318L6 4.18905L1 7.77679"
      stroke="#BBBBBB"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const slideImages = [
  {
    url: `${process.env.PUBLIC_URL}/assets/announcement/banner_1.png`,
    caption: "Slide 1",
  },
  {
    url: `${process.env.PUBLIC_URL}/assets/announcement/banner_1.png`,
    caption: "Slide 2",
  },
  {
    url: `${process.env.PUBLIC_URL}/assets/announcement/banner_1.png`,
    caption: "Slide 3",
  },
];

const data = [
  {
    id: 1,
    title: "[새소식] 맘핏 센터에 새로운 마음 트레이닝이 들어왔어요.",
    date: "2022.12.09",
    description:
      "안녕하세요.\n이번 주, 맘핏 센터에 새로운 마음 트레이닝 9개가 업데이트 되었습니다.\n\n스트레스와 번아웃을 관리할 수 있는 '퇴근 후 기분 전환' 부터\n다시 한번 일할 수 있는 힘을 얻을 수 있는 '오늘도 하나 배움'까지\n\n에너지를 채우고, 집중력을 높이고, 자신감을 키우는 24가지 마음 트레이닝을 지금 바로 경험해보세요.",
  },
  {
    id: 0,
    title: "[공지] 맘핏 센터 베타 오픈",
    date: "2022.12.09",
    description:
      "안녕하세요. 맘핏러들!\n그동안 기대하셨던 메타버스 마음 피트니스 센터, 맘핏 센터가 오픈되었습니다.🥳\n\n맘핏 센터 접속해 언제 어디서든 내가 원할 때 마음 트레이닝을 할 수 있습니다.\n나의 마음 웰빙 지수를 체크하는 마음 체크업과 나의 진짜 미소를 찾는 AI 미소 챌린지까지 맘핏 센터에서 만나보세요.",
  },
];

const ArrowDown = () => (
  <ArrowDownSvg
    width="19"
    height="11"
    viewBox="0 0 19 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L9.5 10L18 1"
      stroke="#BBBBBB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </ArrowDownSvg>
);

const ArrowUp = () => (
  <ArrowUpSvg
    width="19"
    height="11"
    viewBox="0 0 19 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 10L9.5 1L18 10"
      stroke="#00DBB1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </ArrowUpSvg>
);

const Indicators = (index) => (
  <IndicatorCircle highlight={index + 1}></IndicatorCircle>
);

const Announcement = () => {
  const {
    state: { id },
  } = useLocation();
  const [accordionOpenAt, setAccordionOpenAt] = useState(id);

  const handleBannerClicked = (index) => {
    console.log(index);
    // setAccordionOpenAt(index);
  };
  const handleAccordionClicked = (index) => {
    if (index === accordionOpenAt) {
      setAccordionOpenAt(-1);
    } else {
      setAccordionOpenAt(index);
    }
  };
  return (
    <Container>
      <Header>
        📢
        <br />
        맘핏 센터의 공지사항과
        <br />
        새로운 소식들을 전해 드립니다
      </Header>
      {/* <SlideContainer>
        <Slide indicators={Indicators} arrows={false} transitionDuration={300}>
          {slideImages.map((slideImage, index) => {
            return (
              <div className="each-slide" key={index}>
                <div
                  onClick={() => handleBannerClicked(index)}
                  style={{
                    width: "62.5rem",
                    height: "12.5rem",
                    // backgroundColor: "red",
                    backgroundImage: `url(${slideImage.url})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                ></div>
              </div>
            );
          })}
        </Slide>
      </SlideContainer> */}
      <Divider />
      <AccordionContainer>
        <Accordion preExpanded={[accordionOpenAt]} allowZeroExpanded={true}>
          {data.map((item, index) => {
            return (
              <div key={index}>
                <AccordionItem uuid={item.id}>
                  <AccordionHeading
                    onClick={() => handleAccordionClicked(item.id)}
                  >
                    <AccordionItemButton>
                      <AccordionHeadingTitle
                        highlight={accordionOpenAt === item.id}
                      >
                        {item.title}
                      </AccordionHeadingTitle>
                      <AccordionHeadingSubTitle>
                        {item.date}
                      </AccordionHeadingSubTitle>
                      {accordionOpenAt === item.id ? (
                        <ArrowUp />
                      ) : (
                        <ArrowDown />
                      )}
                    </AccordionItemButton>
                  </AccordionHeading>
                  <AccordionItemPanel>
                    <Panel>{lineBreaker(item.description)}</Panel>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionDivider />
              </div>
            );
          })}
        </Accordion>
      </AccordionContainer>
      <PagingContainer>
        <LeftButton />
        <PageNumber>1</PageNumber>
        <RightButton />
      </PagingContainer>
    </Container>
  );
};

export default Announcement;
