import axios from "axios";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CheckupRadarChart } from "./CheckupRadarChart";
import useAuth from "../../../hooks/useAuth";
import { AverageChart } from "../../dashboard/AverageChart";
import CheckupEmotionChart from "./CheckupEmotionChart";
import Button from "./components/Button";

const Container = styled.div`
  width: 100%;
  max-width: 62.5rem;
  height: calc(100% - 4.06rem);
  display: flex;
  flex-direction: column;
  // border: 1px solid black;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubTitle = styled.h2`
  font-size: 1.13rem;
  font-weight: 500;
  line-height: 1.63rem;
  color: ${({ theme }) => theme.primary.normal};
  margin-top: 2.31rem;
  margin-bottom: 0.31rem;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 3.75rem;
`;

const SectionTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
  margin-bottom: 1.13rem;
`;

const LastWeekText = styled.div`
  width: 100%;
  padding: 1.5rem 2.19rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  border-radius: 0.63rem;
  margin-bottom: 3.44rem;
  font-weight: 300;
`;

function CheckupReport() {
  // const { search } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const lastMonday = new Date(Number(params.get("startAt")));

  const [isLoading, setIsLoading] = useState(true);
  const [dates, setDates] = useState([]);

  const [myPermav, setMyPermav] = useState([]);
  const [myWellBeing, setMyWellBeing] = useState([]);
  const [myEmotion, setMyEmotion] = useState();
  const [myMessage, setMyMessage] = useState("");

  const [departmentPermav, setDepartmentPermav] = useState([]);
  const [departmentEmotion, setDepartmentEmotion] = useState([]);

  const [allPermav, setAllPermav] = useState([]);
  const [allEmotion, setAllEmotion] = useState([]);

  const { auth } = useAuth();

  const extractDate = (result) => {
    const dest = [];
    // console.log(result);
    for (let i = 0; i < result.length; i++) {
      //   for (let j = 0; j < result[i].result; j++) {
      dest.push(result[i].createdAt);
      //   }
    }
    return dest;
  };

  const handleClick = () => {
    window.open(
      'https://zep.us/play/8ZwAV4??customData={"blockLocation": "dashboard"}'
    );
  };

  useEffect(() => {
    const fetchAllData = async () => {
      console.log("??");
      const response = await axios.get(
        `${
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_SERVER_DOMAIN_DEV
            : process.env.REACT_APP_API_SERVER_DOMAIN_TEST
        }/checkup/report?userId=${auth.user.id}&companyId=${
          auth?.user?.companyId
        }&departmentId=${auth?.user?.departmentId}`
      );
      console.log(response.data.me);
      setMyPermav(response.data.me.permav);
      console.log(response.data.me.weekAverage);
      // setMyWellBeing(response.data.me.weekAverage);
      setMyWellBeing(response.data.me.weekAverage.filter((i) => i));
      setMyEmotion({
        title: "지난 주, 나",
        negative: response.data.me.negative,
        positive: response.data.me.positive,
      });
      setMyMessage(
        response.data.me.wellBeing.filter((i) => i.message).slice(-1)[0]
          ?.message || ""
      );
      setDepartmentPermav(response.data.department.permav);
      setDepartmentEmotion({
        title: "지난 주, 회사",
        negative: response.data.department.negative,
        positive: response.data.department.positive,
      });
      setAllPermav(response.data.all.permav);
      setAllEmotion({
        title: "지난 주, 맘핏 회원",
        negative: response.data.all.negative,
        positive: response.data.all.positive,
      });

      setIsLoading(false);
    };
    fetchAllData();
  }, []);
  // console.log(dates);
  if (isLoading) {
    return null;
  }
  // console.log(myWellBeing);
  return (
    <Container>
      <Wrapper>
        <SubTitle>주간 마음 리포트</SubTitle>
        <Title>지난 주, {auth.user.name}님의 마음을 살펴볼까요?</Title>
        {/* <SectionTitleDivider /> */}
        <SectionTitle>웰빙 만족도</SectionTitle>
        <CheckupRadarChart
          maumfit={allPermav}
          department={departmentPermav}
          me={myPermav}
        />
        <SectionTitle>감정 지수</SectionTitle>
        <CheckupEmotionChart
          emotions={[myEmotion, departmentEmotion, allEmotion]}
        />
        <SectionTitle>지난 주, 한 줄 기록</SectionTitle>
        <LastWeekText>{myMessage}</LastWeekText>
        <SectionTitle>마음 웰빙 지수</SectionTitle>
        <AverageChart
          results={
            myWellBeing &&
            myWellBeing.map((i) => ({
              score: i.average,
              createdAt: new Date(new Date(i.date).getTime() - 1000),
            }))
          }
          type="report"
          dates={extractDate(myWellBeing)}
        />
        {!document.referrer.includes("zep") && (
          <Button
            text={"📈 내 최근 마음 그래프 살펴보기"}
            handler={handleClick}
          />
        )}
      </Wrapper>
    </Container>
  );
}

export default CheckupReport;
