import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import SubmitButton from "../../components/SubmitButton";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import Card from "./components/Card";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useMediaQuery } from "react-responsive";
import { MOBILE } from "../../Constants";
import { Helmet } from "react-helmet";

const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  margin-top: ${({ isMobile }) => (isMobile ? "3.24rem" : "4.94rem")};
  margin-bottom: ${({ isMobile }) => (isMobile ? "1.62rem" : "2.5rem")};
  color: ${({ theme }) => theme.grayScaleColor.gray80};
`;

const SelectBox = styled.div`
  width: 100%;
  border-radius: 0.3125rem;
  ${({ active }) =>
    active && "border-bottom-left-radius: 0; border-bottom-right-radius: 0;"}
  border: 1px solid
  ${({ theme, active }) =>
    active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  padding: 0.75rem;
  text-align: center;
  color: ${({ theme, active }) =>
    active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4375rem; /* 153.333% */
  letter-spacing: -0.0375rem;
  cursor: pointer;
`;

const Input = styled.div`
  width: 100%;
  border-radius: 0.3125rem;
  ${({ isOpen }) =>
    isOpen && "border-bottom-left-radius: 0; border-bottom-right-radius: 0;"}
  border: 1px solid
    ${({ theme, active }) =>
    active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  padding: 0.88rem 0.81rem;
  color: ${({ theme, active }) =>
    active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  font-size: 0.875rem;
  line-height: 1.4375rem; /* 164.286% */
  letter-spacing: -0.03125rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.p`
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4375rem; /* 153.333% */
  letter-spacing: -0.0375rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const TextButton = styled.p`
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.4375rem; /* 164.286% */
  letter-spacing: -0.03125rem;
  text-decoration-line: underline;
  text-underline-offset: 0.1rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  cursor: pointer;
  margin-bottom: 2.5rem;
`;

const Item = styled.div`
  color: #222;
  &:hover {
    color: ${({ theme }) => theme.primary.normal};
  }
`;

// 모바일 컴포넌트

const MobileContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 1.88rem;
`;

const Logo = styled.img`
  width: 6.25rem;
  height: 1.13188rem;
`;

const SDropdown = ({ close, items, setItem, item, type }) => {
  const selectRef = useRef();

  const handleClick = (e, data) => {
    setItem(data);
    close(false);
  };
  let index = 0;
  if (item) {
    index = items.indexOf(item);
  }
  useEffect(() => {
    if (type === "birthYear") {
      selectRef.current.scrollTop = 600;
    }
  }, []);
  return (
    <div
      ref={selectRef}
      style={{
        position: "absolute",
        left: "-1px",
        right: "-1px",
        top: "3.125rem",
        border: "1px solid #222222",
        height: "11.1875rem",
        overflowY: "auto",
        background: "white",
        borderRadius: "0rem 0rem 0.4375rem 0.4375rem",
        padding: "0.25rem 1rem",
        zIndex: 1,
        filter: "drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.20))",
      }}
    >
      {items.map((data, index) => (
        <Item
          key={index}
          style={{
            display: "flex",
            justifyContent: "center",
            // color: data === item ? "#00DBB1" : "#222",
            borderBottom: "1px solid #EEEEEE",
            padding: "0.56rem 0",
            fontSize: "0.875rem",
            lineHeight: "140%",
          }}
          onClick={(e) => handleClick(e, data)}
        >
          {data}
        </Item>
      ))}
    </div>
  );
};

const SetProfile = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE})`,
  });

  const axiosPrivate = useAxiosPrivate();
  const {
    auth: { accessToken, user },
    setAuth,
  } = useAuth();

  const [isMounted, setIsMounted] = useState(false);
  const [gender, setGender] = useState("");
  const years = [];
  const validYear = new Date().getFullYear() - 14;
  for (let i = validYear; i >= validYear - 100; i--) {
    years.push(i);
  }
  const workExperiences = [];
  for (let i = 1; i < 30; i++) {
    workExperiences.push(i);
  }
  workExperiences.push("30년 이상");
  const positions = [
    "기획/전략",
    "마케팅, 광고, 홍보, 리서치",
    "회계, 세무, 재무",
    "인사, 노무, HRD",
    "총무",
    "법무",
    "IT개발, 데이터",
    "PM, PO, 서비스기획",
    "디자인",
    "영업, 판매",
    "무역, 수입/수출",
    "고객상담, TM(텔레마케팅)",
    "구매, 자재, 물류",
    "상품기획, MD",
    "운전, 운송, 배송",
    "서비스",
    "생산",
    "건설, 건축",
    "의료, 보건",
    "연구, R&D",
    "교육",
    "기타",
  ];
  const [birthYear, setBirthYear] = useState();
  const [workExperience, setWorkExperience] = useState();
  const [position, setPosition] = useState();
  const [openBirthYear, setOpenBirthYear] = useState(false);
  const [openWorkExperience, setOpenWorkExperience] = useState(false);
  const [openPosition, setOpenPosition] = useState(false);
  const genderMaleRef = useRef();
  const genderFemaleRef = useRef();

  const handleGenderClick = (e) => {
    if (e.target.id === "male") {
      setGender("남자");
      genderMaleRef.current.style = "color: #111; border-color: #111";
      genderFemaleRef.current.style = "color: #BBB; border-color:#BBB";
    } else {
      setGender("여자");
      genderFemaleRef.current.style = "color: #111; border-color: #111";
      genderMaleRef.current.style = "color: #BBB; border-color:#BBB";
    }
  };
  const handleInputClick = (e) => {
    e.stopPropagation();
    if (e.target.id === "birthYear") {
      setOpenBirthYear((prev) => !prev);
      setOpenWorkExperience(false);
      setOpenPosition(false);
    } else if (e.target.id === "workExperience") {
      setOpenWorkExperience((prev) => !prev);
      setOpenBirthYear(false);
      setOpenPosition(false);
    } else if (e.target.id === "position") {
      setOpenPosition((prev) => !prev);
      setOpenWorkExperience(false);
      setOpenBirthYear(false);
    }
  };

  const handleSubmitClick = async () => {
    const response = await axiosPrivate.patch(`/users/${user.id}`, {
      gender,
      birthYear,
      workExperience,
      position,
    });

    if (!response.data.ok) {
      alert("문제가 발생했습니다.");
    }
    setAuth({
      user: {
        ...user,
        gender: response.data.user.gender,
        birthYear: response.data.user.birthYear,
        position: response.data.user.position,
        workExperience: response.data.user.workExperience,
      },
      accessToken,
    });
    if (isMobile) {
      navigate("/signup/success");
    } else {
      navigate("/dashboard/guide");
    }
  };

  const handleSkipClick = () => {
    if (isMobile) {
      navigate("/signup/success");
    } else {
      navigate("/dashboard/guide");
    }
  };

  const isDisable = () => {
    return !gender || !birthYear || !workExperience || !position;
  };

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  if (!isMounted) {
    return null;
  }

  if (isMobile) {
    return (
      <MobileContainer>
        <Helmet>
          <meta name="theme-color" content="#FFFFFF" />
        </Helmet>
        <Logo
          src={`${process.env.PUBLIC_URL}/assets/logo/maumfit_logo.png`}
          alt="logo"
        />
        <Text isMobile={isMobile}>
          아래 정보를 입력하면
          <br />좀 더 맞춤화 된 맘핏을 만나볼 수 있어요!
        </Text>
        <FlexColumn style={{ marginTop: "2rem", gap: "1.25rem" }}>
          <FlexColumn style={{ gap: "0.44rem" }}>
            <Label>성별</Label>
            <FlexRow
              style={{ justifyContent: "space-between", gap: "1.25rem" }}
            >
              <SelectBox
                id="male"
                ref={genderMaleRef}
                onClick={handleGenderClick}
              >
                남
              </SelectBox>
              <SelectBox
                id="female"
                ref={genderFemaleRef}
                onClick={handleGenderClick}
              >
                여
              </SelectBox>
            </FlexRow>
          </FlexColumn>
          <FlexColumn style={{ gap: "0.44rem" }}>
            <Label>출생연도</Label>
            <Input
              id="birthYear"
              onClick={handleInputClick}
              active={openBirthYear || birthYear}
              isOpen={openBirthYear}
            >
              {birthYear ? birthYear : "출생연도를 선택해 주세요"}
              <img
                src={`${process.env.PUBLIC_URL}/svg/dropdown_triangle_down.svg`}
                style={{}}
                id="birthYear"
                onClick={handleInputClick}
              />
              {openBirthYear && (
                <SDropdown
                  items={years}
                  close={(toggle) => setOpenBirthYear(toggle)}
                  setItem={setBirthYear}
                  item={birthYear}
                  type={"birthYear"}
                />
              )}
            </Input>
          </FlexColumn>
          <FlexColumn style={{ gap: "0.44rem" }}>
            <Label>전체 경력</Label>
            <Input
              id="workExperience"
              onClick={handleInputClick}
              active={openWorkExperience || workExperience}
              isOpen={openWorkExperience}
            >
              {workExperience ? workExperience : "전체 경력을 선택해 주세요"}
              {/* <ArrowDownSvg style={{}} /> */}
              <img
                src={`${process.env.PUBLIC_URL}/svg/dropdown_triangle_down.svg`}
                style={{}}
                id="workExperience"
                onClick={handleInputClick}
              />
              {openWorkExperience && (
                <SDropdown
                  items={workExperiences}
                  close={(toggle) => setOpenWorkExperience(toggle)}
                  setItem={setWorkExperience}
                  item={workExperience}
                />
              )}
            </Input>
          </FlexColumn>
          <FlexColumn style={{ gap: "0.44rem" }}>
            <Label>직무</Label>
            <Input
              id="position"
              onClick={handleInputClick}
              active={openPosition || position}
              isOpen={openPosition}
            >
              {position ? position : "해당되는 직무를 선택해 주세요"}
              <img
                src={`${process.env.PUBLIC_URL}/svg/dropdown_triangle_down.svg`}
                style={{}}
                id="position"
                onClick={handleInputClick}
              />
              {openPosition && (
                <SDropdown
                  items={positions}
                  close={(toggle) => setOpenPosition(toggle)}
                  setItem={setPosition}
                  item={position}
                />
              )}
            </Input>
          </FlexColumn>
        </FlexColumn>

        <SubmitButton
          value="완료하기"
          style={{
            marginTop: "2.81rem",
            marginBottom: "1.44rem",
            width: "100%",
          }}
          disabled={isDisable()}
          onClick={handleSubmitClick}
        />
        <TextButton onClick={handleSkipClick}>이 단계 건너뛰기</TextButton>
      </MobileContainer>
    );
  }
  return (
    <Card>
      <FlexRow style={{ justifyContent: "center" }}></FlexRow>
      <Text>
        아래 정보를 입력하면
        <br />좀 더 맞춤화 된 맘핏을 만나볼 수 있어요!
      </Text>
      <FlexColumn style={{ marginTop: "2rem", gap: "1.25rem" }}>
        <FlexColumn style={{ gap: "0.44rem" }}>
          <Label>성별</Label>
          <FlexRow style={{ justifyContent: "space-between", gap: "1.25rem" }}>
            <SelectBox
              id="male"
              ref={genderMaleRef}
              onClick={handleGenderClick}
            >
              남
            </SelectBox>
            <SelectBox
              id="female"
              ref={genderFemaleRef}
              onClick={handleGenderClick}
            >
              여
            </SelectBox>
          </FlexRow>
        </FlexColumn>
        <FlexColumn style={{ gap: "0.44rem" }}>
          <Label>출생연도</Label>
          <Input
            id="birthYear"
            onClick={handleInputClick}
            active={openBirthYear || birthYear}
            isOpen={openBirthYear}
          >
            {birthYear ? birthYear : "출생연도를 선택해 주세요"}
            <img
              src={`${process.env.PUBLIC_URL}/svg/dropdown_triangle_down.svg`}
              style={{}}
              id="birthYear"
              onClick={handleInputClick}
            />
            {openBirthYear && (
              <SDropdown
                items={years}
                close={(toggle) => setOpenBirthYear(toggle)}
                setItem={setBirthYear}
                item={birthYear}
                type={"birthYear"}
              />
            )}
          </Input>
        </FlexColumn>
        <FlexColumn style={{ gap: "0.44rem" }}>
          <Label>전체 경력</Label>
          <Input
            id="workExperience"
            onClick={handleInputClick}
            active={openWorkExperience || workExperience}
            isOpen={openWorkExperience}
          >
            {workExperience ? workExperience : "전체 경력을 선택해 주세요"}
            {/* <ArrowDownSvg style={{}} /> */}
            <img
              src={`${process.env.PUBLIC_URL}/svg/dropdown_triangle_down.svg`}
              style={{}}
              id="workExperience"
              onClick={handleInputClick}
            />
            {openWorkExperience && (
              <SDropdown
                items={workExperiences}
                close={(toggle) => setOpenWorkExperience(toggle)}
                setItem={setWorkExperience}
                item={workExperience}
              />
            )}
          </Input>
        </FlexColumn>
        <FlexColumn style={{ gap: "0.44rem" }}>
          <Label>직무</Label>
          <Input
            id="position"
            onClick={handleInputClick}
            active={openPosition || position}
            isOpen={openPosition}
          >
            {position ? position : "해당되는 직무를 선택해 주세요"}
            <img
              src={`${process.env.PUBLIC_URL}/svg/dropdown_triangle_down.svg`}
              style={{}}
              id="position"
              onClick={handleInputClick}
            />
            {openPosition && (
              <SDropdown
                items={positions}
                close={(toggle) => setOpenPosition(toggle)}
                setItem={setPosition}
                item={position}
              />
            )}
          </Input>
        </FlexColumn>
      </FlexColumn>

      <SubmitButton
        value="완료하기"
        style={{ marginTop: "2.81rem", marginBottom: "1.44rem" }}
        disabled={isDisable()}
        onClick={handleSubmitClick}
      />
      <TextButton onClick={handleSkipClick}>이 단계 건너뛰기</TextButton>
    </Card>
  );
};

export default SetProfile;
