import styled from "styled-components";

const BarContainer = styled.div`
  position: relative;
  //   width: 300px;
  height: 0.3125rem;
  width: 100%;
`;
const FullBar = styled.div`
  background: ${({ theme, type }) =>
    type === "me" ? theme.primary.normal : theme.grayScaleColor.gray10};
  opacity: ${({ type }) => (type === "me" ? 0.1 : 0.5)};
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 50px;
`;
const BarGraph = styled.div`
  position: absolute;
  background: ${({ theme, type }) => theme.primary.normal};
  top: 0;
  left: 0;
  width: ${({ value }) => value}%;
  height: 0.3125rem;
  z-index: 2;
  border-radius: 50px;
`;

const Bargraph = ({ style, value }) => {
  return (
    <BarContainer style={{ position: "relative", ...style }}>
      <FullBar></FullBar>
      <BarGraph value={value} />
    </BarContainer>
  );
};
export default Bargraph;
