import { useEffect, useState } from "react";
import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import {
  MONTH_PRICE,
  PAYMENT_TYPE,
  TAX,
  YEAR_PRICE,
} from "../../../constants/payments";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CloseXButton from "../components/CloseXButton";
import Divider from "../components/Divider";
import ModalHeading2 from "../components/ModalHeading2";

const DescriptionText = styled.p`
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 170%; /* 1.59375rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 1.25rem;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  padding: 1rem 1.87rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 170%; /* 1.59375rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  margin-top: 1.81rem;
`;

const ModalButton = styled.button`
  border-radius: 0.4375rem;
  padding: 0.78rem 0;
  flex: 1;
  // max-width: 11rem;
  border: none;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem; /* 153.333% */
  letter-spacing: -0.0375rem;
  cursor: pointer;
`;

const Bold = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;
const CancelButton = styled(ModalButton)`
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const OkButton = styled(ModalButton)`
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
`;

const ChangeToYearPlanModal = ({ numUsers, closeHandler }) => {
  const axiosPrivate = useAxiosPrivate();

  const [isLoading, setIsLoading] = useState(true);

  const onConfirmClick = async () => {
    const res = await axiosPrivate.patch("/payments/subscribe", {
      nextPlanType: "year",
      extraAmount: 0,
    });

    window.location.reload();
  };

  return (
    <>
      <FlexRow style={{ justifyContent: "center", alignItems: "center" }}>
        <ModalHeading2 style={{ textAlign: "center" }}>
          월간 구독을
          <br />
          연간 구독으로 변경하시면
        </ModalHeading2>
      </FlexRow>
      <DescriptionText>
        다음 월 결제일부터 25% 할인이 적용됩니다 🎉
        <br />
        할인 적용을 받게 될 금액은 아래와 같습니다.
      </DescriptionText>
      <Box>
        <FlexRow style={{ justifyContent: "space-between" }}>
          <span>기존 결제 금액</span>
          <Bold style={{ color: "#777" }}>
            {(numUsers * MONTH_PRICE * (1 + TAX / 100)).toLocaleString()}원
          </Bold>
        </FlexRow>
        <FlexRow style={{ justifyContent: "space-between" }}>
          <span>할인 금액</span>
          <Bold style={{ color: "#00DBB1" }}>
            {(
              numUsers * YEAR_PRICE * (1 + TAX / 100) -
              numUsers * MONTH_PRICE * (1 + TAX / 100)
            ).toLocaleString()}
            원
          </Bold>
        </FlexRow>
        <Divider style={{ margin: "1rem 0" }} />
        <FlexRow style={{ justifyContent: "space-between", gap: "0.5rem" }}>
          <span>다음 달(23.12.13) 예상 결제 금액 </span>
          <Bold>
            {(numUsers * YEAR_PRICE * (1 + TAX / 100)).toLocaleString()}원
          </Bold>
        </FlexRow>
      </Box>
      <FlexRow
        style={{
          marginTop: "2.44rem",
          justifyContent: "space-between",
          gap: "0.69rem",
        }}
      >
        <CancelButton onClick={closeHandler}>유지하기</CancelButton>
        <OkButton onClick={onConfirmClick}>혜택 받고 변경하기</OkButton>
      </FlexRow>
    </>
  );
};

export default ChangeToYearPlanModal;
