import styled from "styled-components";
import FlexColumn from "../../../components/FlexColumn";
import FlexRow from "../../../components/FlexRow";
import CloseXButton from "../components/CloseXButton";
import ModalHeading2 from "../components/ModalHeading2";
import Divider from "../components/Divider";
import DotListItem from "../components/DotListItem";
import NumberText from "../components/NumberText";
import { useState } from "react";
import DeleteGroupConfirmModal from "./DeleteGroupConfirmModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";

const Text = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 150%; /* 1.875rem */
  letter-spacing: -0.03125rem;
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  border-radius: 0.3125rem;
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  outline: none;
  &:placeholder {
    color: ${({ theme }) => theme.grayScaleColor.gray30};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.grayScaleColor.black};
  }
  flex: auto;
  margin-right: 0.75rem;
`;

const Button = styled.button`
  border-radius: 0.3125rem;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  padding: 0.25rem 0.75rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.6rem 1rem;
  &:hover {
    background: ${({ theme }) => theme.backgroundColor.darkgray10};
  }
`;

const SaveButton = styled.button``;

const ModalButton = styled.button`
  border-radius: 0.4375rem;
  padding: 0.78rem 3.7rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.03125rem;
  cursor: pointer;
`;

const CancelButton = styled(ModalButton)`
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
`;

const OkButton = styled(ModalButton)`
  background: ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.grayScaleColor.white};
  margin: 0 auto;
  margin-top: 3.69rem;
`;

const GroupManageModal = ({
  closeHandler,
  companyName,
  companyId,
  departments,
  setDepartments,
}) => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [addGroupClick, setAddGroupClick] = useState(false);
  const [editGroupClickAt, setEditGroupClickAt] = useState(-1);
  const [showDeleteConfirmModal, setShowDeletConfirmModal] = useState(false);
  const [selectIndex, setSelectIndex] = useState(0);
  const [list, setList] = useState(departments);
  const [newDepartment, setNewDepartment] = useState("");
  const [hoverAt, setHoverAt] = useState(-1);

  const onDeleteClick = (index) => {
    setShowDeletConfirmModal(true);
    setSelectIndex(index);
  };

  const onEditClick = (index) => {
    setEditGroupClickAt(index);
  };

  const onEditConfirmClick = async (index) => {
    const res = await axiosPrivate.patch("/department", {
      name: newDepartment,
      departmentId: departments[index],
    });
    setDepartments((prev) => {
      prev[index].name = newDepartment;
      return [...prev];
    });

    onEditClick(-1);
  };

  const onHover = (index) => {
    setHoverAt(index);
  };

  const onMouseLeave = () => {
    setHoverAt(-1);
  };

  const toggleAddGroup = () => {
    setAddGroupClick((prev) => !prev);
    setNewDepartment("");
  };

  const onNameChange = (e) => {
    setNewDepartment(e.target.value);
  };
  const createGroupClick = async () => {
    const res = await axiosPrivate.post("/department", {
      name: newDepartment,
      companyId,
    });
    setDepartments((prev) => [...prev, res.data.data]);
    toggleAddGroup();
  };

  const onSaveClick = async () => {
    closeHandler();
  };

  if (showDeleteConfirmModal) {
    return (
      <DeleteGroupConfirmModal
        closeHandler={() => setShowDeletConfirmModal(false)}
        department={departments[selectIndex]}
        setDepartments={setDepartments}
      />
    );
  }
  return (
    <>
      <FlexRow
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "2.5rem",
        }}
      >
        <ModalHeading2 text="조직도 설정" style={{ fontSize: "1.5rem" }} />
        <CloseXButton onClick={closeHandler} />
      </FlexRow>
      <FlexRow style={{ justifyContent: "space-between" }}>
        <Text>{companyName}</Text>
        <Button onClick={toggleAddGroup}>조직 추가</Button>
      </FlexRow>
      <Divider />
      <FlexColumn style={{ gap: "1rem" }}>
        {departments.map((item, index) => (
          <Row
            key={index}
            style={{ alignItems: "center", justifyContent: "space-between" }}
            onMouseEnter={() => onHover(index)}
            onMouseLeave={onMouseLeave}
          >
            {editGroupClickAt === index ? (
              <FlexRow style={{ alignItems: "center", gap: "0.75rem" }}>
                <DotListItem>
                  <Input
                    placeholder="그룹명을 입력해주세요"
                    onChange={onNameChange}
                  />
                  <Button
                    style={{ marginRight: "0.75rem" }}
                    onClick={() => setEditGroupClickAt(-1)}
                  >
                    취소
                  </Button>
                  <Button onClick={() => onEditConfirmClick(index)}>
                    완료
                  </Button>
                </DotListItem>
              </FlexRow>
            ) : (
              <>
                <FlexRow style={{ alignItems: "center", gap: "0.75rem" }}>
                  <DotListItem>{item.name}</DotListItem>
                  <NumberText>{item.numUsers}</NumberText>
                </FlexRow>
                {hoverAt === index && (
                  <FlexRow style={{ gap: "0.5rem" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/svg/dashboard/edit_solid_button.svg`}
                      alt="edit button"
                      onClick={() => onEditClick(index)}
                    />
                    {item.numUsers === 0 && (
                      <img
                        src={`${process.env.PUBLIC_URL}/svg/dashboard/delete_button.svg`}
                        alt="delete button"
                        onClick={() => onDeleteClick(index)}
                      />
                    )}
                  </FlexRow>
                )}
              </>
            )}
          </Row>
        ))}

        {/* <FlexRow style={{ alignItems: "center", gap: "0.75rem" }}>
          <DotListItem>FUM</DotListItem>
          <NumberText>1</NumberText>
        </FlexRow>
        <FlexRow style={{ alignItems: "center", gap: "0.75rem" }}>
          <DotListItem>POD</DotListItem>
          <NumberText>7</NumberText>
        </FlexRow>
        <FlexRow style={{ alignItems: "center", gap: "0.75rem" }}>
          <DotListItem>DI</DotListItem>
          <NumberText>3</NumberText>
        </FlexRow> */}
        {addGroupClick && (
          <FlexRow style={{ alignItems: "center", gap: "0.75rem" }}>
            <DotListItem>
              <Input
                placeholder="그룹명을 입력해주세요"
                onChange={onNameChange}
              />
              <Button
                style={{ marginRight: "0.75rem" }}
                onClick={toggleAddGroup}
              >
                취소
              </Button>
              <Button onClick={createGroupClick}>완료</Button>
            </DotListItem>
          </FlexRow>
        )}
      </FlexColumn>

      {/* <OkButton onClick={onSaveClick}>저장하기</OkButton> */}
    </>
  );
};

export default GroupManageModal;
