import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import axios from "../../../../api/axios";
// import getChatGPT from "../../../../api/checkup_v2/getChatGPT";
import FlexRow from "../../../../components/FlexRow";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import lineBreaker from "../../../../utils/lineBreaker";
import Wave from "../../../ai-smile/components/Wave";
import SectionHeading1 from "./SectionHeading1";
import SectionHeading2 from "./SectionHeading2";

const ChatContainer = styled.div`
  background: #f3f6f6;
  padding: 2.81rem;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 425px) {
    padding: 1.56rem 1.25rem;
  }
`;

const ChatSender = styled.p`
  font-size: 1.13rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray70};
  font-weight: 500;
  margin-bottom: 0.94rem;
  display: flex;
  align-items: center;
  ${({ me }) => !me && "margin-left: auto;"}
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

const ChatMessage = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.backgroundColor.darkgray80};
  line-height: 1.75rem;
  letter-spacing: -0.5px;
  background: ${({ theme, me }) =>
    me ? "#DBF3EF" : theme.grayScaleColor.white};
  border-radius: 0.75rem;
  padding: 1.13rem 1.44rem;
  margin-bottom: 0.94rem;
  align-self: flex-start;
  ${({ me }) => !me && "margin-left: auto;"}
  @media (max-width: 425px) {
    font-size: 0.94rem;
    line-height: 1.38rem;
    padding: 0.88rem 1rem;
  }
`;

const ChatRetryButton = styled.button`
  border: 1px solid ${({ theme }) => theme.primary.normal};
  color: ${({ theme }) => theme.primary.normal};
  padding: 0.63rem 1.06rem;
  border-radius: 3.13rem;
  font-size: 0.94rem;
  letter-spacing: -0.5px;
  line-height: 1.13rem;
  background: transparent;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const ChatSymbol = styled.img`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.63rem;
  @media (max-width: 425px) {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.25rem;
  }
`;

const Recommend = ({
  surveyId,
  name,
  weekSentence,
  recommendQuestion,
  chatGPTPrompt,
  handleRetryClick,
  data,
}) => {
  const recommends = [
    `돌아보니 자신감이 떨어졌던 한 주,\n다시 동기부여가 되어줄 콘텐츠를 추천해요.`,
    `정신 없게 지나갔던 한 주,\n내 마음을 들여다 볼 수 있는 콘텐츠를 추천해요.`,
    `스트레스가 많았던 한 주,\n기분 전환을 할 수 있는 콘텐츠를 추천해요.`,
    `마음의 여유가 생겨 감사했던 한 주,\n여유를 충분히 즐길 수 있는 콘텐츠를 추천해요.`,
    `다른 사람으로 인해 힘들었던 한 주,\n마음에 안정을 줄 수 있는 콘텐츠를 추천해요.`,
    `열심히 노력한 보람을 찾을 수 있었던 한 주,\n순간을 더욱 의미 있게 만들어줄 콘텐츠를 추천해요.`,
    `유독 모든 것이 지루했던 한 주,\n일상 속 작은 의미를 찾을 수 있는 콘텐츠를 추천해요.`,
    `왠지 뭐든 잘 될 것 같은 마음이 생겼던 한 주,\n새로운 일에 더 몰입할 수 있는 콘텐츠를 추천해요.`,
    `내 마음이 어땠는지 잘 몰랐던 한 주,\n감정을 돌아볼 수 있는 콘텐츠를 추천해요.`,
  ];

  const chats = [
    "이번 주에 유독 자신감이 많이 떨어졌어. 오늘 저녁에는 뭘 하면 좋을까?",
    "이번 주는 너무 정신없이 지나갔어. 오늘 저녁에 뭘 하면 좋을까?",
    "이번 주에 스트레스가 너무 많았어. 오늘 저녁에는 뭘 하면 좋을까?",
    "이번 주는 마음에 조금 여유가 있었어. 오늘 저녁에는 뭘 하면 좋을까?",
    "이번 주는 다른 사람으로 인해 조금 힘들었어. 오늘 저녁에는 뭘 하면 좋을까?",
    "이번 주는 계획한 대로 일이 잘 풀렸어. 오늘 저녁에는 뭘 하면 좋을까?",
    "이번 주는 유독 지루하고 느리게 갔어. 오늘 저녁에는 뭘 하면 좋을까?",
    "이번 주는 자신감이 조금 생겼어. 오늘 저녁에는 뭘 하면 좋을까?",
    "이번 주는 내 마음 상태를 잘 모르겠어. 오늘 저녁에는 뭘 하면 좋을까?",
  ];

  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(true);
  const [recommend, setRecommend] = useState("");
  const location = useLocation();

  useEffect(() => {
    const fetch = async () => {
      if (!data) {
        let response;
        if (location.pathname.includes("test")) {
          response = await axios.get(
            `checkup-v2/recommend/test?chatGPTPrompt=${chatGPTPrompt}&surveyId=${surveyId}&weekSentence=${weekSentence}`
          );
        } else {
          response = await axiosPrivate.get(
            `checkup-v2/recommend?chatGPTPrompt=${chatGPTPrompt}&surveyId=${surveyId}&weekSentence=${weekSentence}`
          );
        }
        // const response = await getChatGPT(weekSentence, surveyId);
        setRecommend(response.data.recommend);
      }
      setIsLoading(false);
    };
    fetch();
  }, []);

  return (
    <>
      <SectionHeading2 style={{ marginBottom: "0.63rem" }}>
        {name} 님을 응원하고 싶어요.
      </SectionHeading2>
      <SectionHeading1 style={{ marginBottom: "2.06rem" }}>
        {isNaN(weekSentence)
          ? lineBreaker(weekSentence)
          : lineBreaker(recommends[weekSentence])}
      </SectionHeading1>
      <ChatContainer>
        <ChatSender me={true}>{name}</ChatSender>
        <ChatMessage me={true}>
          {recommendQuestion ? recommendQuestion : chats[weekSentence]}
        </ChatMessage>
        <ChatSender me={false}>
          <ChatSymbol
            src={`${process.env.PUBLIC_URL}/assets/symbol/symbol_chat.png`}
          />{" "}
          ChatGPT
        </ChatSender>

        <ChatMessage me={false}>
          {isLoading ? (
            <FlexRow style={{ alignItems: "center" }}>
              <Wave size={23} color={"#00DBB1"} />{" "}
              <span style={{ marginLeft: "1.25rem", color: "#777777" }}>
                ChatGPT가 열심히 추천할 콘텐츠를 찾고 있어요. 조금만 기다려
                주세요!
              </span>
            </FlexRow>
          ) : (
            lineBreaker(data ? data : recommend)
          )}
        </ChatMessage>
        {/* <ChatRetryButton onClick={handleRetryClick}>
              <img
                src={`${process.env.PUBLIC_URL}/svg/retry_primary.svg`}
                style={{ marginRight: "0.44rem" }}
              />{" "}
              다시 추천해줘😶
            </ChatRetryButton> */}
      </ChatContainer>
    </>
  );
};

export default Recommend;
