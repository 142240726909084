import styled from "styled-components";

import FlexRow from "../../components/FlexRow";
import { GradientChart } from "./components/GradientChart";
import NoDataText from "./components/NoDataText";

const Container = styled.div``;

const Title = styled.p`
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 140%;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const Card6 = ({ title, data, style }) => {
  return (
    <Container style={style}>
      <FlexRow style={{ marginBottom: "2.5rem" }}>
        <Title>{title}</Title>
      </FlexRow>
      {data.length > 0 ? (
        <GradientChart
          result={data.map((item) => item.wellBeingScore)}
          dates={data.map((item) => item.date)}
        />
      ) : (
        <NoDataText />
      )}
    </Container>
  );
};

export default Card6;
