import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PERMAV } from "../../Constants";
import Tooltip from "../block/Tooltip";
import { AverageChart } from "./AverageChart";
import { Chart } from "./Chart";

const Container = styled.div`
  width: 100%;
  height: calc(100% - 4.06rem);
  //   max-width: 62.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //   width: 100%;
  max-width: 62.5rem;
  //   border: 1px solid black;
`;

const BackButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 3.5rem;
  margin-bottom: 3.1rem;
`;
const BackButton = styled.button`
  border: none;

  background-color: inherit;
  cursor: pointer;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2.19rem;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 1rem;
`;

const SubTitle = styled.h2`
  font-size: 1rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  line-height: 1.5rem;
`;

const ChartContainer = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 3.13rem;
  margin-bottom: 8.94rem;
`;

const ChartItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //   &:nth-child(even) {
  //     margin-left: 30px;
  //   }
  @media (max-width: 425px) {
    margin: 0 0 45px 0;
    &:nth-child(even) {
      margin-left: 0;
    }
  }
  // border: 1px solid black;
`;

const ChartTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.13rem;
  // margin-left: 6px;
  align-items: flex-end;
  width: 100%;
  // border: 1px solid black;
  @media (max-width: 425px) {
    margin-bottom: 18px;
  }
  position: relative;
`;
const ChartInfoIcon = styled.svg``;

const ChartSpan = styled.span`
  margin: 0 7px 0 0;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: -0.6px;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const ColorSpan = styled.span`
  font-size: 0.88rem;
  font-weight: 400;
  color: ${({ theme }) => theme.primary.normal};
`;

const MaumScoreDetail = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(-1);

  const handleGobackButtonClick = () => {
    navigate("/dashboard");
  };

  const extractColumn = (index, result) => {
    const dest = [];
    // console.log(result);
    for (let i = 0; i < result.length; i++) {
      //   for (let j = 0; j < result[i].result; j++) {
      dest.push(result[i].answers[index]);
      //   }
    }

    // console.log(index, dest);
    // let target = week === 4 ? 3 : week;
    // for (let i = 0; i <= target; i++) {
    //   dest.push(!result[i] ? null : result[i].answers[index]);
    // }

    return dest;
  };

  const getLabels = (result) => {
    const dest = [];
    // console.log(result);
    for (let i = 0; i < result.length; i++) {
      //   console.log(result[i].createdAt);
      dest.push(
        `${new Date(result[i].createdAt).getMonth() + 1}/${new Date(
          result[i].createdAt
        ).getDate()}`
      );
    }
    return dest;
  };

  return (
    <Container>
      <Wrapper>
        <BackButtonContainer>
          <BackButton onClick={handleGobackButtonClick}>
            <svg
              width="20"
              height="15"
              viewBox="0 0 20 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.292893 6.79289C-0.0976311 7.18342 -0.0976311 7.81658 0.292893 8.20711L6.65685 14.5711C7.04738 14.9616 7.68054 14.9616 8.07107 14.5711C8.46159 14.1805 8.46159 13.5474 8.07107 13.1569L2.41421 7.5L8.07107 1.84315C8.46159 1.45262 8.46159 0.819456 8.07107 0.428932C7.68054 0.0384079 7.04738 0.0384079 6.65685 0.428932L0.292893 6.79289ZM19 8.5C19.5523 8.5 20 8.05228 20 7.5C20 6.94772 19.5523 6.5 19 6.5V8.5ZM1 8.5H19V6.5H1V8.5Z"
                fill="black"
              />
            </svg>
          </BackButton>
        </BackButtonContainer>
        <TitleContainer>
          <Title>최근 마음 그래프</Title>
          <SubTitle>
            지난주보다 높은 영역은 무엇인가요? "내가 잘 챙기고 있네"라며
            스스로를 칭찬해주세요!
            <br />
            낮은 영역은 무엇인가요? 어떻게 하면 더 나아질지 의도적인 행동으로
            챙겨보세요.
          </SubTitle>
        </TitleContainer>
        <AverageChart results={state.result} />
        <ChartContainer>
          {PERMAV.map((item, index) => (
            <ChartItem key={index}>
              <ChartTitle>
                <div>
                  <ChartSpan>{item.name}</ChartSpan>
                  {/* <ColorSpan style={{ color: item.color }}> */}
                  <ColorSpan>{item.word}</ColorSpan>
                </div>
                <ChartInfoIcon
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onMouseEnter={() => setVisible(index)}
                  onMouseLeave={() => setVisible(-1)}
                >
                  <circle cx="9" cy="9" r="8.5" stroke="#7C8585" />
                  <path
                    d="M8.2 14V7.06761H9.78947V14H8.2ZM9.00526 5.84883C8.82281 5.84883 8.65439 5.80751 8.5 5.72488C8.34561 5.63881 8.22281 5.5252 8.13158 5.38404C8.04386 5.24288 8 5.08967 8 4.92441C8 4.75571 8.04386 4.60078 8.13158 4.45962C8.22281 4.31847 8.34561 4.20657 8.5 4.12394C8.65439 4.04131 8.82281 4 9.00526 4C9.1807 4 9.34561 4.04131 9.5 4.12394C9.65439 4.20657 9.77544 4.31847 9.86316 4.45962C9.95439 4.60078 10 4.75571 10 4.92441C10 5.08967 9.95439 5.24288 9.86316 5.38404C9.77544 5.5252 9.65439 5.63881 9.5 5.72488C9.34561 5.80751 9.1807 5.84883 9.00526 5.84883Z"
                    fill="#7C8585"
                  />
                </ChartInfoIcon>
                <Tooltip
                  text={item.tooltip}
                  visible={index === visible}
                  background="#7C8585;"
                  style={{
                    bottom: "2rem",
                    right: "-0.6rem",
                  }}
                />
              </ChartTitle>
              <Chart
                result={
                  state.result.length > 0 && extractColumn(index, state.result)
                }
                dates={state.result.length > 0 ? getLabels(state.result) : []}
                color={item.color}
              />
            </ChartItem>
          ))}
        </ChartContainer>
      </Wrapper>
    </Container>
  );
};

export default MaumScoreDetail;
