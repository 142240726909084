import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import {
  TABLET_LANDSCAPE,
  TYPE_LEADER,
  USER_ADMIN,
  USER_MANAGER,
  USER_PM,
} from "../../Constants";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import SwitchButton from "./SwitchButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: var(--sidebar-width);
  position: fixed;
  height: calc(100% - var(--navbar-height));
  overflow: auto;
  background: ${({ theme }) => theme.grayScaleColor.white};
  z-index: 3;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    width: var(--sidebar-tablet-landscape-width);
  }
  // transition: all 0.3s;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 6.75rem;
  padding: 1.5rem 2.5rem;
  background: #7c8585;
  color: ${({ theme }) => theme.grayScaleColor.white};
  font-size: 0.875rem;
  line-height: 150%;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    // width: var(--sidebar-tablet-landscape-width);
    padding: 2.62rem 1.12rem;
  }
`;

const Wrapper = styled.div`
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-bottom: 4.37rem;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    padding: 0 0.56rem;
    padding-bottom: 4.37rem;
  }
`;

const List = styled.ul`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const Item = styled.li`
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 0.625rem;
  // border: 1px solid black;
  font-size: 0.9375rem;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: ${({ theme, isMatch }) =>
    isMatch ? theme.primary.normal : theme.grayScaleColor.gray80};
  background: ${({ theme, isMatch }) =>
    isMatch ? theme.backgroundColor.darkgray10 : theme.grayScaleColor.white};
  &:hover {
    /* color: ${({ theme }) => theme.primary.normal}; */
    background: ${({ theme }) => theme.backgroundColor.darkgray10};
  }
  // transition: all 0.3s;
  cursor: pointer;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    padding: 0.75rem;
  }
`;

const SubItem = styled.li`
  font-weight: 500;
  font-size: 0.9375rem;
  color: ${({ theme, isMatch }) =>
    isMatch ? theme.grayScaleColor.gray80 : theme.grayScaleColor.gray70};
  cursor: pointer;
  line-height: normal;
`;

const SLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const LogoutContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  font-size: 0.875rem;
  align-items: center;
  color: #777;
  cursor: pointer;
  @media (max-width: ${TABLET_LANDSCAPE}) {
    ${({ expandSidebar }) => !expandSidebar && "justify-content: center;"}
  }
`;

const Sidebar = ({ items, type }) => {
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const logout = useLogout();

  const isTablet = useMediaQuery({
    query: `(max-width: ${TABLET_LANDSCAPE})`,
  });

  const [isHover, setIsHover] = useState(false);
  const [expandSidebar, setExpandeSidebar] = useState(true);

  const sidebarRef = useRef();
  const menuRef = useRef();
  const handleExpandItem = (e) => {
    e.preventDefault();
    if (menuRef.current.style.display === "none") {
      menuRef.current.style.display = "flex";
      e.currentTarget.querySelector(".arrow").src = "/svg/sidebar_arrow_up.svg";
    } else {
      menuRef.current.style.display = "none";
      e.currentTarget.querySelector(".arrow").src =
        "/svg/sidebar_arrow_down.svg";
    }
  };
  const handleSidebarEnter = () => {
    if (isTablet) {
      setIsHover(true);
      setExpandeSidebar(true);
      sidebarRef.current.style =
        "width: var(--sidebar-width); filter: drop-shadow(4px 0px 10px rgba(0, 0, 0, 0.06));";
    }
    // console.log("hover!");
  };
  const handleSidebarLeave = () => {
    if (isTablet) {
      setIsHover(false);
      setExpandeSidebar(false);
      sidebarRef.current.style =
        "width: var(--sidebar-tablet-landscape-width); filter: none; transition: all 0.05s;";
    }
    // console.log("leave!");
  };

  const handleSwitchClick = () => {
    if (type === TYPE_LEADER) {
      navigate("/dashboard");
    } else {
      if (auth.user.role === USER_PM) {
        navigate("/manager/dashboard/projects");
      } else if (auth.user.role === USER_MANAGER) {
        navigate("/manager/dashboard/users");
      } else if (auth.user.role >= USER_ADMIN) {
        navigate("/manager/dashboard");
      } else {
        navigate("/leader/dashboard");
      }
    }
  };

  const handleLogoutClick = async () => {
    await logout();
  };

  useEffect(() => {
    if (!isTablet) {
      setExpandeSidebar(true);
      sidebarRef.current.style = "width: var(--sidebar-width);";
    } else {
      setExpandeSidebar(false);
      sidebarRef.current.style =
        "width: var(--sidebar-tablet-landscape-width); filter: none; transition: all 0.05s;";
    }
  }, [isTablet]);

  return (
    <Container
      ref={sidebarRef}
      onMouseEnter={handleSidebarEnter}
      onMouseLeave={handleSidebarLeave}
    >
      <Header>
        {!expandSidebar ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <path
              d="M25.7947 15L20.5784 20.2163L19.5399 19.1779L23.7178 15L19.5399 10.8221L20.5784 9.78368L25.7947 15ZM10.5399 19.1779L9.50147 20.2163L4.28516 15L9.50147 9.78368L10.5399 10.8221L6.36203 15L10.5399 19.1779Z"
              fill="white"
            />
          </svg>
        ) : (
          <>
            <p
              style={{
                fontSize: "1rem",
                fontWeight: 600,
                marginBottom: "0.25rem",
              }}
            >
              {auth.user.companyId.name}
            </p>
            <p>{auth.user.departmentId?.name}</p>
          </>
        )}
      </Header>
      <Wrapper>
        <List>
          {items.map((item, index) => {
            let isMatch = location.pathname === item.link;
            if (!item.role.includes(auth.user.role)) {
              return;
            }
            if (item.children) {
              isMatch = location.pathname.includes(item.link);
              return (
                <FlexColumn key={index}>
                  <Item
                    key={index}
                    isMatch={isMatch}
                    onClick={handleExpandItem}
                  >
                    <FlexRow
                      style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <FlexRow style={{ alignItems: "center" }}>
                        {item.src && (
                          <img
                            style={{ marginRight: "0.75rem" }}
                            src={`${process.env.PUBLIC_URL}/svg/${
                              isMatch ? item.srcActive : item.src
                            }`}
                            alt=""
                          />
                        )}
                        {expandSidebar && item.title}
                      </FlexRow>
                      {expandSidebar && (
                        <img
                          className="arrow"
                          src={`${process.env.PUBLIC_URL}/svg/sidebar_arrow_down.svg`}
                          alt=""
                        />
                      )}
                    </FlexRow>
                  </Item>

                  {expandSidebar && (
                    <div
                      ref={menuRef}
                      style={{
                        display: "none",
                        flexDirection: "column",
                        gap: "1rem",
                        marginLeft: "3rem",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      {item.children.map((child, cidx) => {
                        isMatch = location.pathname === child.link;
                        return (
                          <SubItem key={cidx} isMatch={isMatch}>
                            <SLink to={child.link}>{child.title}</SLink>
                          </SubItem>
                        );
                      })}
                    </div>
                  )}
                </FlexColumn>
              );
            }
            return (
              <SLink key={index} to={item.link || ""}>
                <Item isMatch={isMatch}>
                  {item.src && (
                    <img
                      src={`${process.env.PUBLIC_URL}/svg/${
                        isMatch ? item.srcActive : item.src
                      }`}
                      alt=""
                    />
                  )}
                  {expandSidebar && item.title}
                </Item>
              </SLink>
            );
          })}
        </List>
        <FlexColumn style={{ gap: "1rem" }}>
          {auth.user.role > 1 && (
            <div>
              <SwitchButton
                expandSidebar={expandSidebar}
                type={type}
                style={{ marginTop: "auto" }}
                onClick={handleSwitchClick}
              >
                {expandSidebar &&
                  (type === TYPE_LEADER
                    ? "구성원 모드로 전환"
                    : "관리자 모드로 전환")}
              </SwitchButton>
            </div>
          )}
          <LogoutContainer
            expandSidebar={expandSidebar}
            onClick={handleLogoutClick}
            style={{}}
          >
            <img
              src={`${process.env.PUBLIC_URL}/svg/sidebar_logout_default.svg`}
              alt=""
            />
            {expandSidebar && <span>로그아웃</span>}
          </LogoutContainer>
        </FlexColumn>
      </Wrapper>
    </Container>
  );
};

export default Sidebar;
