import styled from "styled-components";
import lineBreaker from "../../../utils/lineBreaker";

const P = styled.p`
  font-size: 1.13rem;
  line-height: 1.75rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.gray80};
  text-align: center;
  letter-spacing: -0.04em;
`;

const Description = ({ text, style }) => (
  <P style={style}>{lineBreaker(text)}</P>
);

export default Description;
