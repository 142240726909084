import styled from "styled-components";

const Text = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: -0.03125rem;
`;

const Heading2 = ({ text, style }) => {
  return <Text style={style}>{text}</Text>;
};

export default Heading2;
