import styled from "styled-components";

const Container = styled.div`
  display: flex;
`;

const FlexRow = ({ children, style, onClick }) => {
  return (
    <Container style={style} onClick={onClick}>
      {children}
    </Container>
  );
};

export default FlexRow;
