import styled from "styled-components";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import lineBreaker from "../utils/lineBreaker";
import { useState } from "react";

const Container = styled.div``;

const Heading3 = styled.h3`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.88rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 3.13rem;
`;

const AccordionContainer = styled.div`
  margin-bottom: 3.88rem;
`;

const AccordionHeading = styled(AccordionItemHeading)`
  //   border: 1px solid black;
  //   height: 4.38rem;
  border-radius: 0.75rem;
  position: relative;

  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  margin-bottom: 0.63rem;
`;
const AccordionButton = styled(AccordionItemButton)`
  //   border: 1px solid black;
  padding: 1.38rem 1.94rem;
  cursor: pointer;
  display: flex;
`;

const AccordionHeadingTitle = styled.h3`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 500;
  margin-bottom: 0.13rem;
`;

const AccordionHeadingTitleHighlight = styled.span`
  font-size: 1.13rem;
  font-weight: 500;
  line-height: 1.63rem;
  color: ${({ theme }) => theme.primary.normal};
  margin-right: 0.63rem;
`;

const Panel = styled.div`
  background: ${({ theme }) => theme.grayScaleColor.white};
  padding: 1rem 1.94rem;
  font-size: 0.94rem;
  line-height: 1.44rem;
  font-weight: 300;
  letter-spacing: -0.04em;
  margin-bottom: 0.63rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
`;

const PagingContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 6.69rem;
`;

const PageNumber = styled.div`
  width: 1.88rem;
  height: 1.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.19rem;
  background: ${({ theme }) => theme.backgroundColor.darkgray10};
  font-size: 0.81rem;
  font-weight: 300;
  line-height: 1.44rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  margin: 0 0.31rem;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.grayScaleColor.gray10};
  margin-bottom: 3.5rem;
`;

const Heading2 = styled.h2`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

const HeadingSvg = styled.svg`
  margin-right: 0.69rem;
`;

const Text = styled.p`
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300
    color: ${({ theme }) => theme.grayScaleColor.black};
    letter-spacing: -0.04em;
    margin-bottom: 16rem;
`;

const TextHighlight = styled.a`
  font-weight: 500;
  color: ${({ theme }) => theme.primary.normal};
  text-decoration: none;
`;

const ArrowDownSvg = styled.svg`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1.94rem;
  margin: auto 0;
`;

const ArrowUpSvg = styled(ArrowDownSvg)``;

const ArrowDown = () => (
  <ArrowDownSvg
    width="19"
    height="11"
    viewBox="0 0 19 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L9.5 10L18 1"
      stroke="#BBBBBB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </ArrowDownSvg>
);

const ArrowUp = () => (
  <ArrowUpSvg
    width="19"
    height="11"
    viewBox="0 0 19 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 10L9.5 1L18 10"
      stroke="#00DBB1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </ArrowUpSvg>
);

const LeftButton = () => (
  <svg
    style={{ marginRight: "1.25rem" }}
    width="7"
    height="9"
    viewBox="0 0 7 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 0.601318L1 4.18905L6 7.77679"
      stroke="#BBBBBB"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const RightButton = () => (
  <svg
    style={{ marginLeft: "1.25rem" }}
    width="7"
    height="9"
    viewBox="0 0 7 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 0.601318L6 4.18905L1 7.77679"
      stroke="#BBBBBB"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const data = [
  {
    id: 0,
    title: "비밀번호를 잊어버렸어요.",
    description:
      "로그인 페이지 하단에 '비밀번호 찾기'를 누르면 비밀번호를 변경할 수 있습니다.\n\n비밀번호 재설정을 위해서는 우선 회사 이메일 계정을 인증해야 해요.\n회사 이메일로 비밀번호 변경을 위한 링크를 전송해 드립니다.\n\n비밀번호 변경 후, 다시 맘핏 센터에서 새로운 비밀번호로 로그인 할 수 있습니다.😊",
  },
  {
    id: 1,
    title: "맘핏을 이용할 때마다 로그인을 해야 하나요?",
    description:
      "맘핏은 기본적으로 자동 로그인 기능을 제공하고 있습니다.\n그러나 맘핏러들의 계정 보안을 위해 일정 시간이 지나면 자동 로그인이 해제됩니다.\n\n(💡Tip) 크롬의 '내 로그인 정보 기록' 기능을 사용하면, 매번 정보를 입력하지 않고도 입장이 가능합니다.",
  },
  {
    id: 2,
    title: "로그아웃은 어떻게 하나요?",
    description:
      "맘핏 센터 '데스크' > '나의 멤버십 정보' 페이지 가장 하단 로그아웃 버튼을 통해 로그아웃이 가능합니다.",
  },
  {
    id: 3,
    title: "AI 미소 챌린지에서 촬영한 사진을 다른 이용자들이 볼 수 있나요?",
    description:
      "아니요! 촬영된 사진은 절대 다른 맘핏러에게 공개되지 않으니 안심하세요🔒",
  },
  {
    id: 4,
    title: "내 마음 체크업 결과를 다른 이용자들이 볼 수 있나요?",
    description:
      "아니요! 내 마음 체크업 결과는 타인에게 공개되지 않으니 걱정마세요.🔒\n인바디로 나의 몸 상태를 기록하듯,\n마음 체크업으로 나의 마음 건강을 기록하고 나에게 꼭 맞는 트레이닝도 추천받을 수 있습니다. ",
  },
  //   {
  //     id: 5,
  //     title: "내가 쓴 마음 기록을 확인하고 싶어요.",
  //     description:
  //       "내가 쓴 마음 기록들은 현재 맘핏 센터 데이터 보관함에 차곡차곡 저장 중이에요.\n\n아직은 한눈에 확인할 수 없지만, 조만간 대시보드에서 나의 마음 트레이닝 기록 및 통계를 확인할 수 있을 거예요.\n지금은 각각의 마음 트레이닝에 들어가면 내 마음 기록을 확인할 수 있답니다.",
  //   },
  {
    id: 5,
    title: "맘핏 센터가 자꾸 버벅여요.",
    description:
      "맘핏 센터를 이용할 때는 다른 웹 페이지 또는 어플리케이션을 종료하고 이용하시는 걸 추천 드려요!\n\n문의하기를 통해 오류 상태를 전달해주세요.\n안정적인 맘핏 센터 이용을 위해 맘핏 운영진이 항상 노력 중입니다.🥲",
  },
];
const Faq = () => {
  const [accordionOpenAt, setAccordionOpenAt] = useState();

  const handleAccordionClicked = (index) => {
    console.log("clicked", index);
    if (index === accordionOpenAt) {
      console.log("close ", index);
      setAccordionOpenAt(-1);
    } else {
      console.log("open ", index);
      setAccordionOpenAt(index);
    }
  };
  return (
    <Container>
      <Heading3>맘핏러들은 이런 질문을 주로 해요 💬</Heading3>
      <AccordionContainer>
        <Accordion allowZeroExpanded={true}>
          {data.map((item, index) => {
            return (
              <AccordionItem key={index} uuid={item.id}>
                <AccordionHeading onClick={() => handleAccordionClicked(index)}>
                  <AccordionButton>
                    <AccordionHeadingTitleHighlight>
                      Q.
                    </AccordionHeadingTitleHighlight>
                    <AccordionHeadingTitle
                      highlight={accordionOpenAt === index}
                    >
                      {item.title}
                    </AccordionHeadingTitle>
                    {accordionOpenAt === index ? <ArrowUp /> : <ArrowDown />}
                  </AccordionButton>
                </AccordionHeading>
                <AccordionItemPanel>
                  <Panel>{lineBreaker(item.description)}</Panel>
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </AccordionContainer>
      <PagingContainer>
        <LeftButton />
        <PageNumber>1</PageNumber>
        <RightButton />
      </PagingContainer>
      <Divider />
      <Heading2>
        <HeadingSvg
          width="28"
          height="27"
          viewBox="0 0 28 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.7197 24.3574L24.0862 21.7239V3.52646C24.0862 1.58239 22.5038 0 20.5597 0H3.52646C1.58239 0 0 1.58239 0 3.52646V22.6507C0 24.5948 1.58239 26.1772 3.52646 26.1772H25.9624C26.9119 26.1772 27.3866 25.0243 26.7197 24.3574ZM12.0487 21.2379C8.44316 21.2379 5.48184 18.4235 5.23318 14.8857C5.21057 14.6258 5.43663 14.411 5.68529 14.411H7.16595C7.40331 14.411 7.59545 14.6032 7.61806 14.8405C7.83281 17.0898 9.73167 18.8643 12.0487 18.8643C14.3658 18.8643 16.2647 17.1011 16.4794 14.8405C16.502 14.6032 16.6829 14.411 16.9315 14.411H18.4122C18.6722 14.411 18.8869 14.6258 18.8643 14.8857C18.6156 18.4348 15.6543 21.2379 12.0487 21.2379Z"
            fill="#00DBB1"
          />
        </HeadingSvg>
        맘핏 센터에 문의하기
      </Heading2>
      <Text>
        혹시라도 궁금한 점이 아직 남아 있으시다면{" "}
        <TextHighlight href="mailto:hello@maum.fit" target="_blank">
          여기
        </TextHighlight>
        를 클릭하여 메일을 보내 주세요. 맘핏 센터 관리자가 확인 후 바로 회신
        드릴게요.
      </Text>
    </Container>
  );
};
export default Faq;
