import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BackButton from "../../components/BackButton";
import CheckboxButton from "../../components/CheckboxButton";
import Dropdown from "../../components/Dropdown";
import DropdownDiv from "../../components/DropdownDiv";
import Loading from "../../components/Loading";
import RadioButton from "../../components/RadioButton";
import RightArrowButton from "../../components/RightArrowButton";
import ToastPopup from "../../components/ToastPopup";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useLogout from "../../hooks/useLogout";
import WorkExperienceDropdown from "../../components/\bWorkExperienceDropdown";
import PositionDropdown from "../../components/PositionDropdown";
import validatePhone from "../../utils/validatePhone";

const Container = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Logo = styled.img`
  position: absolute;
  top: 1.69rem;
  right: 2.13rem;
  width: 5.94rem;
  height: 1.06rem;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.h1`
  font-size: 2.25rem;
  font-weight: 700;
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.grayScaleColor.gray30};
  margin-top: 1.19rem;
  margin-bottom: 2.5rem;
`;

const TopSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

const Header2 = styled.h2`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 700;
  line-height: 2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
`;
const Button = styled.button`
  // width: ${({ isEditMode }) => (isEditMode ? "8.75rem" : "8.75rem")};
  padding: 0.38rem 0.56rem;
  height: 2.19rem;
  border: 1px solid
    ${({ theme, isEditMode }) =>
      isEditMode ? theme.primary.dark : theme.primary.normal};
  border-radius: 0.31rem;
  background-color: ${({ theme, isEditMode }) =>
    isEditMode ? theme.grayScaleColor.white : theme.primary.normal};
  font-size: 0.94rem;
  color: ${({ theme, isEditMode }) =>
    isEditMode ? theme.primary.dark : theme.grayScaleColor.white};
  cursor: pointer;
  letter-spacing: -0.04em;
  &:hover {
    ${({ isEditMode, theme }) =>
      isEditMode &&
      `color: ${theme.grayScaleColor.white}; background-color: ${theme.primary.hover}; border: 1px solid ${theme.primary.hover}`};
  }

  transition: 0.3s;
`;

const MembershipCardSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ItemContainer = styled.div`
  width: 100%;
`;

const BaseRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.94rem;
  border-radius: 0.63rem;
  padding: 0 2.5rem;
  margin-bottom: 0.63rem;
`;
const Row = styled(BaseRow)`
  background-color: ${({ theme, isFilled }) =>
    isFilled ? theme.grayScaleColor.gray5 : theme.grayScaleColor.white};
  ${({ theme, isFilled }) =>
    !isFilled && `border: 1px solid ${theme.grayScaleColor.gray10}`};

  color: ${({ theme, isFilled }) =>
    isFilled ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
`;

const EditRow = styled(BaseRow)`
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme, isFilled, clicked }) =>
    clicked
      ? theme.grayScaleColor.white
      : isFilled
      ? theme.grayScaleColor.gray5
      : theme.grayScaleColor.white};
  border: 1px solid
    ${({ theme, isFilled, clicked, isError }) =>
      isError === true
        ? theme.colors.error
        : clicked
        ? theme.primary.normal
        : isFilled
        ? theme.grayScaleColor.gray5
        : theme.grayScaleColor.gray10};

  color: ${({ theme, isRequired, isFilled, clicked }) =>
    (!isRequired && isFilled) || clicked
      ? theme.grayScaleColor.black
      : theme.grayScaleColor.gray30};
  // &:focus-within {
  //   color: ${({ theme }) => theme.grayScaleColor.black};
  //   border: 1px solid ${({ theme }) => theme.primary.normal};
  //   background-color: ${({ theme }) => theme.grayScaleColor.white};
  //   &:nth-child(6) {
  //     height: auto;
  //   }
  // }

  transition: 0.1s;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  // border: 1px solid black;
`;

const Separator = styled.div`
  height: 2.19rem;
  border-left: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  margin-left: 3.75rem;
  margin-right: 2.5rem;
`;

const Category = styled.span`
  width: 4.56rem;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.04em;
`;

const Text = styled.span`
  font-size: 1rem;

  font-weight: 300;
`;

const LogoutContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
  // margin-bottom: 2.44rem;
  margin-bottom: 10.44rem;
`;

const TextButton = styled.button`
  width: 4.5rem;
  font-size: 0.75rem;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  border: none;
  background-color: ${({ theme }) => theme.grayScaleColor.white};
  cursor: pointer;
`;

const InterestContainer = styled.div`
  display: flex;
  width: 80%;
  // border: 1px solid black;
  flex-wrap: wrap;
  gap: 0.63rem;
  margin: 0.88rem 0;
`;

const PhoneInput = styled.input`
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: 300;
`;

const NicknameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  // border: 1px solid black;

  font-size: 1rem;
`;

const NicknameInput = styled.input`
  outline: none;
  border: none;
  font-size: 1rem;
  width: 80%;
`;

const NicknameMessage = styled.p`
  font-size: 0.75rem;
  color: ${({ theme, isError }) =>
    isError === true ? theme.colors.error : theme.primary.normal};
  line-height: 1.19rem;
  margin-bottom: 1.31rem;
`;

const TopBackButton = styled.svg`
  margin-bottom: 2.5rem;
  cursor: pointer;
`;

const INTERESTS = [
  "마음챙김",
  "멘탈케어",
  "스트레스 관리",
  "마인드셋",
  "긍정정서",
  "동기부여",
  "리텐션",
  "강점발휘",
  "마음케어",
  "성장",
  "행복",
  "감정스캐닝",
  "감사하기",
  "명상",
  "회복탄력성",
  "내재동기",
];

function MyPage({ update, setUpdate, setIsEditMode }) {
  const axiosPrivate = useAxiosPrivate();
  const [isChecked, setIsChecked] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [updateFinished, setUpdateFinished] = useState(false);
  const { auth, setAuth, setPersist } = useAuth();
  const [focusAt, setFocusAt] = useState();
  const [nickname, setNickname] = useState(auth.user.nickname);
  const [gender, setGender] = useState(auth.user.gender);
  const [phone, setPhone] = useState(auth.user.phone);
  const [workExperience, setWorkExperience] = useState(
    auth.user.workExperience
  );
  const [position, setPosition] = useState(auth.user.position);
  const [birthYear, setBirthYear] = useState();
  const [interests, setInterests] = useState([]);
  const [nicknameClicked, setNicknameClicked] = useState(false);
  const [phoneClicked, setPhoneClicked] = useState(false);
  const [genderClicked, setGenderClicked] = useState(false);
  const [yearClicked, setYearClicked] = useState(false);
  const [interestClicked, setInterestClicked] = useState(false);
  const [workExperienceClicked, setWorkExperienceClicked] = useState(false);
  const [positionClicked, setPositionClicked] = useState(false);
  const [nicknameOk, setNicknameOk] = useState(true);
  const [isNicknameDuplicate, setIsNicknameDuplicate] = useState(true);
  const [nicknameMsg, setNicknameMsg] = useState("");
  const logout = useLogout();
  const nicknameRef = useRef();
  const phoneRef = useRef();
  const genderRef = useRef();
  const birthYearRef = useRef();
  const interestsRef = useRef();
  const workExperienceRef = useRef();
  const positionRef = useRef();

  const navigate = useNavigate();
  // console.log(auth);

  const handleNicknameChange = (e) => {
    setNickname(e.target.value);
    // console.log(e.target.value);
  };
  useEffect(() => {
    // console.log("use Effect!", genderRef.current.children);

    const handleClickOutside = (e) => {
      if (
        nicknameClicked &&
        typeof e.target.className === "string" &&
        !e.target.className.includes("nickname") &&
        !nicknameRef.current.contains(e.target)
      ) {
        setFocusAt(0);
        setNicknameClicked(false);
      }
      if (
        phoneClicked &&
        typeof e.target.className === "string" &&
        !e.target.className.includes("phone") &&
        !phoneRef.current.contains(e.target)
      ) {
        setFocusAt(0);
        setPhoneClicked(false);
      }
      if (
        genderClicked &&
        typeof e.target.className === "string" &&
        !e.target.className.includes("gender") &&
        !genderRef.current.contains(e.target)
      ) {
        setFocusAt(0);
        setGenderClicked(false);
      }

      if (
        yearClicked &&
        typeof e.target.className === "string" &&
        !e.target.className.includes("birthYear") &&
        !birthYearRef.current.contains(e.target)
      ) {
        setFocusAt(0);
        setYearClicked(false);
      }

      if (
        workExperienceClicked &&
        typeof e.target.className === "string" &&
        !e.target.className.includes("workExperience") &&
        !workExperienceRef.current.contains(e.target)
      ) {
        setFocusAt(0);
        setWorkExperienceClicked(false);
      }
      if (
        positionClicked &&
        typeof e.target.className === "string" &&
        !e.target.className.includes("position") &&
        !positionRef.current.contains(e.target)
      ) {
        setFocusAt(0);
        setPositionClicked(false);
      }
      if (
        interestClicked &&
        typeof e.target.className === "string" &&
        !e.target.className.includes("interests") &&
        !interestsRef.current.contains(e.target)
      ) {
        setFocusAt(0);
        setInterestClicked(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [
    nicknameClicked,
    genderClicked,
    yearClicked,
    interestClicked,
    phoneClicked,
    positionClicked,
    workExperienceClicked,
  ]);

  useEffect(() => {
    const updateUserInfo = async () => {
      await handleSaveButtonClick();
    };
    if (update) {
      updateUserInfo();
    }
  }, [update]);

  useEffect(() => {
    setGender(auth.user.gender);
    setBirthYear(auth.user.birthYear);
    setInterests(auth.user.interests);
    if (auth.user.isSubscribe) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, []);

  const handleDeleteButtonClick = async () => {
    if (window.confirm("정말 탈퇴하시겠습니까?")) {
      // delete
      console.log("delete");
      try {
        await axiosPrivate.delete("/users", {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
          withCredentials: true,
        });

        setAuth({});
        localStorage.setItem("persist", false);
        setPersist(false);
        navigate("/");
      } catch (err) {}
    } else {
      // nothing
      console.log("delete cancel");
    }

    setIsDeleteClicked(true);
  };

  const handleEditButtonClick = () => {
    setIsEditMode(true);
    setNickname(auth.user.nickname);
  };

  const handleEditCancelButtonClick = () => {
    setIsEditMode(false);
  };

  const handleUpdatePasswordClick = () => {
    navigate("/password");
  };

  const handleRightButtonClick = (index) => {
    setFocusAt(index);
    if (index === 2) {
      setNicknameClicked(true);
      setPhoneClicked(false);
      setGenderClicked(false);
      setYearClicked(false);
      setInterestClicked(false);
      setWorkExperienceClicked(false);
      setPositionClicked(false);
    } else if (index === 3) {
      setNicknameClicked(false);
      setPhoneClicked(true);
      setGenderClicked(false);
      setYearClicked(false);
      setInterestClicked(false);
      setWorkExperienceClicked(false);
      setPositionClicked(false);
    } else if (index === 4) {
      setNicknameClicked(false);
      setPhoneClicked(false);
      setGenderClicked(true);
      setYearClicked(false);
      setInterestClicked(false);
      setWorkExperienceClicked(false);
      setPositionClicked(false);
    } else if (index === 5) {
      setNicknameClicked(false);
      setPhoneClicked(false);
      setGenderClicked(false);
      setYearClicked(true);
      setInterestClicked(false);
      setWorkExperienceClicked(false);
      setPositionClicked(false);
    } else if (index === 6) {
      setNicknameClicked(false);
      setPhoneClicked(false);
      setGenderClicked(false);
      setYearClicked(false);
      setInterestClicked(false);
      setWorkExperienceClicked(true);
      setPositionClicked(false);
    } else if (index === 7) {
      setNicknameClicked(false);
      setPhoneClicked(false);
      setGenderClicked(false);
      setYearClicked(false);
      setInterestClicked(false);
      setWorkExperienceClicked(false);
      setPositionClicked(true);
    }
  };
  const handleSaveButtonClick = async () => {
    if (nickname !== auth.user.nickname && isNicknameDuplicate) {
      setNicknameOk(false);
      setNicknameMsg("닉네임 중복 확인을 해주세요.");
      return;
    }
    if (nicknameOk === false && isNicknameDuplicate) {
      return;
    }

    if (phone !== auth.user.phone) {
      if (!validatePhone(phone)) {
        alert("휴대폰 번호 형식이 올바르지 않습니다");
        return;
      }
    }
    setIsLoading(true);
    try {
      const response = await axiosPrivate.patch(`/users/${auth.user.id}`, {
        nickname: auth.user.nickname === nickname ? null : nickname,
        gender,
        birthYear,
        interests,
        phone: phone.replaceAll("-", ""),
        position,
        workExperience,
      });
      // console.log(interests);
      // console.log(response.data);
      setAuth({
        accessToken: auth.accessToken,
        user: {
          ...auth.user,
          nickname: response.data.nickname,
          gender: response.data.gender,
          birthYear: response.data.birthYear,
          interests: response.data.interests,
          phone: response.data.phone,
          position: response.data.position,
          workExperience: response.data.workExperience,
        },
      });
      setUpdateFinished(true);
      setNicknameOk(true);
      setIsNicknameDuplicate(true);
      setNicknameMsg("");
      setNickname(response.data.nickname || auth.user.nickname);
    } catch (err) {
      console.log(err.response);
    } finally {
      setIsLoading(false);
    }
    // window.location.reload();
  };

  const handleCancelButtonClick = () => {
    setIsEditMode(false);
    setIsChecked(auth.user.isSubscribe);
    setNicknameOk(true);
    setIsNicknameDuplicate(true);
    setNicknameMsg("");
    setNickname(nickname);
  };

  const handleClickChange = (e) => {
    setIsChecked((prev) => !prev);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleGenderChange = (value) => {
    setGender(value);
    setGenderClicked(false);
    setFocusAt(0);
  };

  const handleInterestClick = (value) => {
    if (!interests.includes(value)) {
      setInterests((prev) => [...prev, value]);
    } else {
      setInterests((prev) => prev.filter((i) => i !== value));
    }
  };

  const handleDuplicateNicknameCheckClick = async () => {
    if (nickname[0] === " " || nickname.trim().length === 0) {
      setNicknameOk(false);
      setNicknameMsg("첫 문자는 공백을 제외한 문자를 입력해주세요.");
      setIsNicknameDuplicate(true);
      return;
    }
    try {
      const response = await axiosPrivate.get(
        `/users/nickname?nickname=${nickname.trimEnd()}`
      );
      setNicknameOk(true);
      setNicknameMsg(response.data.msg);
      setIsNicknameDuplicate(false);
    } catch (err) {
      setNicknameOk(false);
      setNicknameMsg(err.response.data.msg);
      setIsNicknameDuplicate(true);
    }
  };

  const handleLogoutClick = async () => {
    await logout();
  };

  return (
    <Container>
      <Wrapper>
        <TopSection></TopSection>
        <MembershipCardSection>
          <ItemContainer>
            <EditRow isRequired={true} isFilled={auth.user.name ? true : false}>
              <InfoContainer>
                <Category>이름</Category>
                <Separator />
                <Text>{auth.user.name}</Text>
              </InfoContainer>
            </EditRow>
            <EditRow
              isRequired={true}
              isFilled={auth.user.companyId.name ? true : false}
            >
              <InfoContainer>
                <Category>회사</Category>
                <Separator />
                <Text>{auth.user.companyId.name}</Text>
              </InfoContainer>
            </EditRow>
            <EditRow
              isRequired={true}
              isFilled={auth.user.departmentId.name ? true : false}
            >
              <InfoContainer>
                <Category>팀</Category>
                <Separator />
                <Text>{auth.user.departmentId.name}</Text>
              </InfoContainer>
            </EditRow>
            <EditRow
              ref={nicknameRef}
              isRequired={false}
              isFilled={auth.user.nickname ? true : false}
              clicked={nicknameClicked}
              isError={!nicknameOk}
            >
              <InfoContainer>
                <Category>닉네임</Category>
                <Separator />

                {focusAt === 2 ? (
                  <NicknameContainer>
                    <NicknameInput
                      type="text"
                      value={nickname}
                      placeholder={auth.user.nickname || "닉네임을 입력하세요"}
                      onChange={handleNicknameChange}
                    />
                    <Button
                      // style={{
                      //   position: "absolute",
                      //   top: "calc(50% - 18px)",
                      //   right: "1.25rem",
                      // }}
                      onClick={handleDuplicateNicknameCheckClick}
                    >
                      중복 확인
                    </Button>
                  </NicknameContainer>
                ) : (
                  <Text>{nickname || auth.user.nickname}</Text>
                )}
              </InfoContainer>
              {!nicknameClicked && (
                <RightArrowButton
                  className="nickname"
                  onClick={() => handleRightButtonClick(2)}
                />
              )}
            </EditRow>

            {nicknameMsg && (
              <NicknameMessage isError={!nicknameOk}>
                {nicknameMsg}
              </NicknameMessage>
            )}
            <EditRow
              ref={phoneRef}
              isRequired={false}
              clicked={phoneClicked}
              isFilled={auth.user.phone || phone ? true : false}
            >
              <InfoContainer>
                <Category>휴대폰 번호</Category>
                <Separator />
                {focusAt === 3 ? (
                  <>
                    <PhoneInput
                      type="text"
                      placeholder={phone || "핸드폰 번호를 입력해 주세요."}
                      onChange={handlePhoneChange}
                    />
                  </>
                ) : (
                  <Text isFilled={phone ? true : false}>
                    {phone || auth.user.phone}
                  </Text>
                )}
              </InfoContainer>
              {!phoneClicked && (
                <RightArrowButton
                  className="phone"
                  onClick={() => handleRightButtonClick(3)}
                />
              )}
            </EditRow>

            <EditRow
              ref={genderRef}
              isRequired={false}
              clicked={genderClicked}
              isFilled={auth.user.gender || gender ? true : false}
            >
              <InfoContainer>
                <Category>성별</Category>
                <Separator />
                {focusAt === 4 ? (
                  <>
                    <RadioButton
                      // value="남자"
                      // name="gender"
                      checked={!gender || gender === "남자"}
                      handleClick={() => handleGenderChange("남자")}
                      style={{ marginRight: "0.63rem" }}
                    >
                      남자
                    </RadioButton>
                    <RadioButton
                      // value="남자"
                      // name="gender"
                      checked={gender === "여자"}
                      handleClick={() => handleGenderChange("여자")}
                    >
                      여자
                    </RadioButton>
                    {/* <input
                        type="radio"
                        value="여자"
                        name="gender"
                        checked={gender === "여자"}
                        onChange={handleGenderChange}
                      />
                      여자 */}
                  </>
                ) : (
                  <Text isFilled={gender ? true : false}>
                    {gender || "성별 정보를 추가해 주세요"}
                  </Text>
                )}
              </InfoContainer>
              {!genderClicked && (
                <RightArrowButton
                  className="gender"
                  onClick={() => handleRightButtonClick(4)}
                />
              )}
            </EditRow>
            <EditRow
              ref={birthYearRef}
              isRequired={false}
              isFilled={auth.user.birthYear || birthYear ? true : false}
              clicked={yearClicked}
            >
              <InfoContainer>
                <Category>출생 연도</Category>
                <Separator />
                {focusAt === 5 ? (
                  <Dropdown
                    style={{ height: "10rem" }}
                    year={birthYear}
                    setYear={setBirthYear}
                    setFocusAt={setFocusAt}
                    setClick={setYearClicked}
                  />
                ) : (
                  <Text>
                    {birthYear ? birthYear + "년" : "연령 정보를 추가해 주세요"}
                  </Text>
                )}
              </InfoContainer>
              {!yearClicked && (
                <RightArrowButton
                  className="birthYear"
                  onClick={() => handleRightButtonClick(5)}
                />
              )}
            </EditRow>
            <EditRow
              ref={workExperienceRef}
              isRequired={false}
              isFilled={
                auth.user.workExperience || workExperience ? true : false
              }
              clicked={workExperienceClicked}
            >
              <InfoContainer>
                <Category>전체 경력</Category>
                <Separator />
                {focusAt === 6 ? (
                  <WorkExperienceDropdown
                    style={{ top: "-2rem", height: "10rem" }}
                    data={workExperience}
                    setData={setWorkExperience}
                    setFocusAt={setFocusAt}
                    setClick={setWorkExperienceClicked}
                  />
                ) : (
                  <Text>
                    {workExperience
                      ? workExperience !== "30년 이상"
                        ? workExperience + "년"
                        : workExperience
                      : "전체 경력 정보를 추가해 주세요"}
                  </Text>
                )}
              </InfoContainer>
              {!workExperienceClicked && (
                <RightArrowButton
                  className="workExperience"
                  onClick={() => handleRightButtonClick(6)}
                />
              )}
            </EditRow>
            <EditRow
              ref={positionRef}
              isRequired={false}
              isFilled={auth.user.position || position ? true : false}
              clicked={positionClicked}
            >
              <InfoContainer>
                <Category>직무</Category>
                <Separator />
                {focusAt === 7 ? (
                  <PositionDropdown
                    style={{ top: "-6rem", height: "10rem" }}
                    data={position}
                    setData={setPosition}
                    setFocusAt={setFocusAt}
                    setClick={setPositionClicked}
                  />
                ) : (
                  <Text>{position || "직무를 선택해 주세요"}</Text>
                )}
              </InfoContainer>
              {!positionClicked && (
                <RightArrowButton
                  className="position"
                  onClick={() => handleRightButtonClick(7)}
                />
              )}
            </EditRow>
            {/* <DropdownDiv
              reference={interestsRef}
              isRequired={false}
              isFilled={interests.length > 0 ? true : false}
              interestClicked={interestClicked}
            >
              <InfoContainer>
                <Category>관심 분야</Category>
                <Separator />
                {focusAt === 6 ? (
                  <InterestContainer>
                    {INTERESTS.map((item, index) => (
                      <CheckboxButton
                        key={index}
                        value={item}
                        checked={interests.includes(item)}
                        onClick={handleInterestClick}
                      >
                        {item}
                      </CheckboxButton>
                    ))}
                  </InterestContainer>
                ) : (
                  <Text>
                    {interests.length > 0
                      ? interests.map((i) => `#${i} `)
                      : "마음건강과 관련된 관심 있는 키워드를 추가해 주세요"}
                  </Text>
                )}
              </InfoContainer>
              {!interestClicked && (
                <RightArrowButton
                  className="interests"
                  onClick={() => handleRightButtonClick(6)}
                />
              )}
            </DropdownDiv> */}
          </ItemContainer>
        </MembershipCardSection>
      </Wrapper>
      {isLoading && <Loading />}
      {updateFinished && (
        <ToastPopup
          text="멤버십 정보를<br />성공적으로 변경했어요 🥳"
          buttonText={"확인"}
          onClick={() => {
            setUpdateFinished(false);
            setIsEditMode(false);
            setUpdate(false);
          }}
        />
      )}
    </Container>
  );
}

export default MyPage;
