import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ROUTES } from "../../../Constants";
import useAuth from "../../../hooks/useAuth";
import includesPathname from "../../../utils/includesPathname";
import Button from "../components/Button";
import Description from "../components/Description";
import Heading from "../components/Heading";
import SubHeading from "../components/SubHeading";

const ThumbnailImage = styled.img`
  width: 19.69rem;
  height: 13.75rem;
  margin-top: 2.94rem;
  margin-bottom: 4.13rem;
`;

const Guide = styled.p`
  border-radius: 0.63rem;
  color: ${({ theme }) => theme.backgroundColor.darkgray40};
  font-weight: 400;
  font-size: 0.81rem;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  padding: 11px 18px;
  margin-top: 22px;
  line-height: 1.25rem;
  letter-spacing: -0.08em;
  text-align: center;
`;

const FileInput = styled.input`
  padding: 20px;
  background: #ffc842;
  border: 1px solid black;
  width: 200px;
  color: white;
`;

const PopupMobile = () => {
  const fileInputRef = useRef();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { state } = useLocation();

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    // Encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      navigate(
        includesPathname(ROUTES.GUEST_SMILE)
          ? ROUTES.GUEST_SMILE_ANALYZE
          : ROUTES.SMILE_ANALYZE,
        {
          state: {
            name: !includesPathname(ROUTES.GUEST_SMILE)
              ? auth.user.name
              : "회원",
            imageData: reader.result,
            cid: state.cid,
          },
        }
      );
      // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
      // window.opener.sendImage(reader.result);
      // window.close();
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <SubHeading text="Challenge Start" />
      <Heading text={"지금부터\n미소를 촬영할게요"} />
      <Description
        text={
          "눈코입이 모두 나오도록 화면을 바라보고,\n자연스럽게 미소를 지으며 촬영해 주세요 😊"
        }
      />
      <Guide>
        마음 편하게 촬영하고 확인하세요. 사진은 AI 판별 후,
        <br />
        저장이나 올리기 버튼을 누리지 않으면 자동으로 사라집니다.
      </Guide>
      <ThumbnailImage
        src={`${process.env.PUBLIC_URL}/assets/ai-smile/ai_smile_thumbnail.png`}
      />
      <FileInput
        type="file"
        accept="image/*"
        capture="user"
        ref={fileInputRef}
        color="white"
        onChange={handleFileUpload}
        style={{ display: "none" }}
      ></FileInput>
      <Button
        text={"미소 촬영하기"}
        clickHandler={handleFileUploadClick}
        style={{ marginBottom: "6.25rem" }}
      />
    </>
  );
};

export default PopupMobile;
