import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import CloseXButton from "./CloseXButton";

const ModalContainer = styled(motion.div)`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Box = styled(motion.div)`
  width: ${({ width }) => (width ? width : "500px")};
  z-index: 25;
  background: white;
  max-height: 48rem;
  padding: ${({ padding }) => (padding ? padding : "2.5rem")};
  opacity: 1;
  border-radius: 1.875rem;
  overflow: auto;
  top: 0;
  left: 0;
  position: relative;
  //   transform: translateX(-50%);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.06);
`;

const BackgroundVariants = {
  initial: {
    opacity: 0,
    transition: {
      duration: 0.03,
    },
  },
  visible: {
    opacity: 1,
  },
  leaving: {
    opacity: 0,
    transition: {
      duration: 0.05,
    },
  },
};

const boxVariants = {
  initial: {
    opacity: 0,
    // scale: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.1,
    },
    // scale: 1,
  },
  leaving: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
    // scale: 0,
    // transition: {
    //   duration: 0.1,
    // },
  },
};

const Modal = ({
  children,
  showing,
  setShowing,
  width,
  padding,
  companyId,
  departments,
}) => {
  const closeModal = () => setShowing(false);

  return (
    <>
      <AnimatePresence>
        {showing ? (
          <ModalContainer
            variants={BackgroundVariants}
            initial="initial"
            animate="visible"
            exit="leaving"
          >
            <Box
              variants={boxVariants}
              initial="initial"
              animate="visible"
              exit="leaving"
              width={width}
              padding={padding}
            >
              {children}
            </Box>
          </ModalContainer>
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default Modal;
