import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import isEndWithConsonant from "../../../utils/isEndWithConsonant";

const Container = styled.div`
  width: 100%;
  height: 23.88rem;
  border-radius: 0.63rem;
  padding: 2.94rem 3.5rem;
  margin-bottom: 2.81rem;
  background: ${({ theme }) => theme.grayScaleColor.white};
  @media (max-width: 425px) {
    height: auto;

    padding: 0 1.13rem;
    padding-top: 3.06rem;
    padding-bottom: 2.31rem;
  }
  // border: 1px solid black;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 2.63rem;
  @media (max-width: 450px) {
    margin-bottom: 2rem;
  }
`;
const Thumbnail = styled.img`
  width: 8.75rem;
  height: 9.38rem;
  margin-right: 0.94rem;
`;

const ContentInfoContainer = styled.div`
  width: 100%;
`;

const ContentSubHeading = styled.h3`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ theme }) => theme.grayScaleColor.black};
  line-height: 1.75rem;
  @media (max-width: 425px) {
    font-size: 0.94rem;
    line-height: 1.44rem;
  }
`;

const ContentHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 0.44rem;
  line-height: 2rem;
  @media (max-width: 425px) {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 0.25rem;
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.grayScaleColor.gray20};
  margin-top: 1.06rem;
  margin-bottom: 2rem;
  @media (max-width: 450px) {
    margin-bottom: 1.69rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const ContentTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 1rem;
  line-height: 1.75rem;
  @media (max-width: 425px) {
    font-size: 1 rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;

const ContentDescription = styled.p`
  font-size: 0.94rem;
  font-weight: 300;
  color: ${({ theme }) => theme.grayScaleColor.gray70};
  line-height: 1.44rem;
  margin-bottom: 1.19rem;
  word-break: keep-all;
  @media (max-width: 425px) {
    font-size: 0.94rem;
    line-height: 1.44rem;
    margin-bottom: 1.19rem;
  }
`;

const LinkButton = styled.a`
  color: ${({ theme }) => theme.grayScaleColor.white};
  background: ${({ theme }) => theme.primary.normal};
  padding: 0.69rem 3.31rem;
  border-radius: 0.31rem;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1.44rem;
  font-size: 0.94rem;
  @media (max-width: 425px) {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.69rem 5.88rem;
  }
`;

const BookMark = styled.svg`
  position: absolute;
  top: 0;
  right: 3.5rem;
  @media (max-width: 425px) {
    right: 2rem;
  }
`;
const CheckupRecommendContent = ({ content }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });
  return (
    <Container>
      {/* {isMobile && (
        <>
          <ContentSubHeading>
            <span style={{ color: "#00D2C1" }}>[MAUMPICK]</span> 오늘 저녁에
            뭐하지?
          </ContentSubHeading>
          <ContentHeading>
            긍정정서를 높이는 콘텐츠를 추천합니다!
          </ContentHeading>
          <Divider />
        </>
      )} */}

      <ContentSubHeading>
        <span style={{ color: "#00DBB1" }}>[MAUMPICK]</span> 오늘 저녁에 뭐하지?
      </ContentSubHeading>
      <ContentHeading>
        {content.type}
        {isEndWithConsonant(content.type) ? "을" : "를"} 높이는 콘텐츠를
        추천합니다!
      </ContentHeading>
      <Divider />

      <ContentContainer>
        <ThumbnailContainer>
          <Thumbnail src={content.typeThumbnail} />
          <Thumbnail src={content.genreThumbnail} />
        </ThumbnailContainer>

        <ContentInfoContainer>
          <ContentTitle>{content.title}</ContentTitle>
          <ContentDescription>{content.description}</ContentDescription>
          <LinkButton
            href={content.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            맛보기 😋
          </LinkButton>
        </ContentInfoContainer>
      </ContentContainer>
      <BookMark
        width="39"
        height="49"
        viewBox="0 0 39 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_382_2640)">
          <path
            d="M0 0V46.5424C0 47.4109 0.452161 48.2147 1.18749 48.6581C1.92282 49.1016 2.83628 49.1201 3.58988 48.7043L19.4931 39.9502L35.3964 48.7043C35.7572 48.903 36.1592 49.0046 36.5565 49.0046C36.9858 49.0046 37.4152 48.8891 37.7988 48.6581C38.5341 48.2147 38.9863 47.4109 38.9863 46.5424V0H0Z"
            fill="#00DBB1"
          />
        </g>
        <defs>
          <clipPath id="clip0_382_2640">
            <rect width="39" height="49" fill="white" />
          </clipPath>
        </defs>
      </BookMark>
    </Container>
  );
};

export default CheckupRecommendContent;
