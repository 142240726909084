import styled from "styled-components";

const Text = styled.p`
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 0.38rem;
`;

const Heading1 = ({ style, children }) => {
  return <Text style={style}>{children}</Text>;
};

export default Heading1;
