import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import postSmileStatistics from "../api/postSmileStatistics";
import useAuth from "../../../hooks/useAuth";
import includesPathname from "../../../utils/includesPathname";
import { ROUTES } from "../../../Constants";

function getRandomValue() {
  // 0~20 또는 80~100 사이에서 값을 선택할지 결정 (50% 확률)
  const rangeSelector = Math.random() < 0.5;

  if (rangeSelector) {
    // 0~20 사이의 랜덤 값 반환
    return Math.random() * 21; // 0부터 20까지의 값
  } else {
    // 80~100 사이의 랜덤 값 반환
    return Math.random() * 21 + 80; // 80부터 100까지의 값
  }
}
const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.grayScaleColor.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14.06rem 0;
`;

const SubHeading = styled.h3`
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.primary.normal};
  font-weight: 500;
  margin-bottom: 0.63rem;
`;

const Heading = styled.h1`
  font-size: 2.25rem;
  line-height: 2.88rem;
  font-weight: 700;
  color: ${({ theme }) => theme.grayScaleColor.black};
  margin-bottom: 1.88rem;
  text-align: center;
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.grayScaleColor.black};
  font-weight: 300;
  text-align: center;
  margin-bottom: 1rem;
`;

const Gif = styled.img`
  width: 12.88rem;
  height: 12.88rem;
`;

const Analyze = () => {
  const {
    state: { name, imageData, cid },
  } = useLocation();
  const { auth } = useAuth();

  const navigate = useNavigate();
  const fileName = new Date().getTime();

  const getDuchenneSmile = async (form) => {
    return await axios.post(process.env.REACT_APP_DUCHENNE_DOMAIN, form, {
      headers: {
        Authorization: `Token ${process.env.REACT_APP_DUCHENNE_SMILE_KEY}`,
      },
    });
  };

  const getSmileClassification = async (form) => {
    return await axios.post(process.env.REACT_APP_SMILE_DOMAIN, form, {
      headers: {
        Authorization: `Token ${process.env.REACT_APP_DUCHENNE_SMILE_KEY}`,
      },
    });
  };

  const dataURLtoFile = (dataurl, fileName) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };

  useEffect(() => {
    let loading;
    const file = dataURLtoFile(imageData, `${fileName}.png`);
    const form = new FormData();
    // console.log(imageData);
    form.append("image", file);

    const fetchData = async () => {
      // TODO: 지워야 함
      let duchenneSmileResult = getRandomValue();

      let level = 1;
      if (duchenneSmileResult > 80) {
        level = 5;
      } else if (duchenneSmileResult > 60) {
        level = 4;
      } else if (duchenneSmileResult > 40) {
        level = 3;
      } else if (duchenneSmileResult > 20) {
        level = 2;
      } else {
        level = 1;
      }

      try {
        // await axios
        //   .all([getDuchenneSmile(form), getSmileClassification(form)])
        //   .then(
        //     axios.spread((duchenneRes, smileRes) => {
        //       if (duchenneRes.status === 200) {
        //         // setDuchenneSmileResult(duchenneRes.data.result);
        //         console.log(duchenneRes.data.result["duchenne_smile"]);
        //         duchenneSmileResult =
        //           Number(duchenneRes.data.result["duchenne_smile"]) * 100;
        //       }
        //       if (smileRes.status === 200) {
        //         // setSmileClassification(smileRes.data.result);
        //         smileClassification = smileRes.data.result;
        //       }
        //       // setIsLoading(false);
        //     })
        //   );
        await axios.all([getDuchenneSmile(form)]).then(
          axios.spread((duchenneRes) => {
            if (duchenneRes.status === 200) {
              // setDuchenneSmileResult(duchenneRes.data.result);
              console.log(duchenneRes.data.result["duchenne_smile"]);
              duchenneSmileResult =
                Number(duchenneRes.data.result["duchenne_smile"]) * 100;
            }
            // setIsLoading(false);
          })
        );
        if (duchenneSmileResult > 80) {
          level = 5;
        } else if (duchenneSmileResult > 60) {
          level = 4;
        } else if (duchenneSmileResult > 40) {
          level = 3;
        } else if (duchenneSmileResult > 20) {
          level = 2;
        } else {
          level = 1;
        }

        await postSmileStatistics({
          auth: includesPathname(ROUTES.GUEST_SMILE) ? null : auth,
          level,
          cid,
        });
      } catch (err) {
        console.log(err);
        // setIsLoading(false);
      } finally {
        // TODO: 결과 state로 보내기

        loading = setTimeout(() => {
          navigate(
            includesPathname(ROUTES.GUEST_SMILE)
              ? ROUTES.GUEST_SMILE_RESULT
              : ROUTES.SMILE_RESULT,
            {
              state: {
                data: imageData,
                fileName,
                result: duchenneSmileResult?.toFixed(2),
                // result: 90.67,
                name,
                level,
                cid,
              },
            }
          );
        }, 500);
      }
    };

    fetchData();

    return () => {
      clearTimeout(loading);
    };
  }, []);
  return (
    <Container>
      <SubHeading>Challenge Processing</SubHeading>
      <Heading>
        내 미소를
        <br />
        분석하고 있어요
      </Heading>
      <Text>
        잠시만 기다려 주세요.
        <br />
        AI 엔진이 열심히 판별 중이랍니다🙏
      </Text>
      <Gif src={`${process.env.PUBLIC_URL}/assets/ai_bounce.gif`} />
    </Container>
  );
};

export default Analyze;
