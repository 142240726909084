import React, { useState, useEffect } from "react";

function useDocumentHeight() {
  const [documentHeight, setDocumentHeight] = useState(
    document.documentElement.scrollHeight || document.body.scrollHeight
  );

  useEffect(() => {
    function handleResize() {
      setDocumentHeight(
        document.documentElement.scrollHeight || document.body.scrollHeight
      );
    }

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return documentHeight;
}

export default useDocumentHeight;
