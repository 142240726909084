import { useState } from "react";
import styled from "styled-components";
import axios from "../../api/axios";
import LabelInput from "../../components/LabelInput";
import SubmitButton from "../../components/SubmitButton";
import Card from "./components/Card";

const Heading1 = styled.h1`
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.8rem */
  letter-spacing: -0.03125rem;
  text-align: center;
  margin-bottom: 2.37rem;
  margin-top: 4.37rem;
`;

const Input = styled.div`
  width: 100%;
  border-radius: 0.3125rem;
  ${({ isOpen }) =>
    isOpen && "border-bottom-left-radius: 0; border-bottom-right-radius: 0;"}
  border: 1px solid
    ${({ theme, active }) =>
    active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  padding: 0.88rem 0.81rem;
  color: ${({ theme, active }) =>
    active ? theme.grayScaleColor.black : theme.grayScaleColor.gray30};
  font-size: 0.875rem;
  line-height: 1.4375rem; /* 164.286% */
  letter-spacing: -0.03125rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const SignupVerify = () => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [link, setLink] = useState("");

  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOnClick = async () => {
    const response = await axios.post("/signup-verify", { email });

    if (!response.data.ok) {
      return alert(response.data.msg);
    }
    alert(`${response.data.data.link}에서 회원가입을 진행하세요`);
    setLink(response.data.data.link);
    setEmailSent(true);
  };
  return (
    <Card>
      <Heading1>
        반갑습니다 관리자님 🙌 <br />
        아이디로 활용하실 회사 이메일을 입력해 주세요
      </Heading1>
      <Input type="text" placeholder="bloom@maum.fit" />
      <SubmitButton value={"메일 인증 요청하기"} onChange={handleOnChange} />
    </Card>
  );
};

export default SignupVerify;
